import { Chart } from '@adtech/charts';
import ChartWithLegend from '@components/ChartWithLegend';
import { ChartFormatterValues, ChartTypes } from '@configs/graph';
import { GroupBy } from '@configs/group';
import graphColorsCnf from '@configs/widgets';
import { DateType } from '@typings/date';
import { Dimensions } from '@typings/dimensions';
import { Metrics } from '@typings/metrics';
import { measuresUtils, graphUtils, dateUtils } from '@utils/index';
import React from 'react';

import s from './Widget.pcss';

interface IProps {
    graphType?: ChartTypes;
    title?: string;
    data?: any[];
    dataTotals?: [string, number][];
    dataKeys?: string[];
    metrics?: Metrics[];
    dimensions?: Dimensions[];
    defaultMetric?: Metrics;
    period?: DateType[];
    hideLegend?: boolean;
    groupBy?: GroupBy;
    selected: Metrics | Dimensions;
    heightChart?: number;
    name?: string;
    reqError?: boolean;
    titleAliases?: Record<string, string>;
    formatterValues?: ChartFormatterValues;
}

const Graph: React.FC<IProps> = ({
    graphType = null,
    title = '',
    data,
    dataTotals,
    dataKeys,
    metrics,
    dimensions,
    defaultMetric,
    period = [],
    hideLegend = false,
    groupBy = GroupBy.day,
    selected = null,
    heightChart = 205,
    name = '',
    reqError = false, // ошибка запроса данных виджета
    titleAliases,
    formatterValues,
}) => {
    const getChartData = () => {
        const dataTransformed = titleAliases ? graphUtils.applyLabelsAliases(data, titleAliases) : data;

        return graphUtils.getData(graphType, {
            data: dataTransformed,
            dataTotals,
            measuresForGraphs: metrics || dimensions,
            activeMeasure: selected,
            title,
        });
    };

    const getMinValue = () => {
        if (graphType === 'barSpecial') return 0;

        return measuresUtils.getParamFromConfig(selected, 'minValue');
    };

    // !reqError - первичный рендер,
    // так как selected не определён при первичном рендере и ошибке запроса данных
    if (!selected && !reqError) return null;

    const chartData = getChartData();

    const legendData = graphUtils.getColors(chartData, graphColorsCnf[name], dataKeys);
    const colors = legendData.map((item) => item.color);

    // У некоторых виджетов медиа выбранным является dimension,
    // а обсчёт значений всегда идет по метрике
    const metric = defaultMetric || selected;

    const graphPeriod = dateUtils.transformAnyDateToISO(period);
    const graphData = graphUtils.prepareChartData(chartData, graphPeriod, groupBy);
    const verticalGrid = !(graphType === ChartTypes.bar || graphType === ChartTypes.barSpecial);
    const hideXAxis = graphType === ChartTypes.bar;

    const graph = (
        <Chart
            type={graphType}
            group={groupBy}
            colors={colors}
            metricsName={measuresUtils.getTitle(metric)}
            data={graphData}
            dataKey="date"
            minValue={getMinValue()}
            period={graphPeriod}
            sizes={[null, heightChart]}
            formatterValues={formatterValues || (measuresUtils.getFormat(selected) as ChartFormatterValues)}
            verticalGrid={verticalGrid}
            withoutXAxis={hideXAxis}
            tooltipLargeData
            pieLabels
        />
    );

    if (hideLegend) return graph;

    return (
        <ChartWithLegend rootClassName={s.graph} data={legendData} graphTypes={[graphType]}>
            {graph}
        </ChartWithLegend>
    );
};

export default Graph;
