import { OrdersType } from '@constants/sort';
import { Metrics } from '@typings/metrics';
import { ReportName } from '@typings/reports';
import { IOrderBy } from '@typings/table';
import cn from 'classnames';
import React from 'react';

import s from '../../ReportTable.pcss';
import DropdownIndicators from './DropdownIndicators';
import IndicatorTitle from './IndicatorTitle';

interface IProps {
    item: Metrics;
    index: number;
    array: Metrics[];
    metricsLimit: number | null;
    onIndicatorClick: (indicator: Metrics, order?: OrdersType) => void;
    onNewMetricSelect: (metrics: Metrics[]) => void;
    reportName?: ReportName;
    metrics: Metrics[];
    allMetrics: Metrics[];
    orderBy: IOrderBy;
    utils: TAPI;
    isHideMetricsDropdown?: boolean;
    forceDisableSort?: boolean;
}

const Cell: React.FC<IProps> = ({
    item,
    index,
    array,
    onIndicatorClick,
    onNewMetricSelect,
    metricsLimit,
    reportName,
    metrics,
    allMetrics,
    orderBy,
    utils,
    isHideMetricsDropdown,
    forceDisableSort,
}) => {
    let insertButton = null;

    if (utils.canHideIndicator(reportName, item)) {
        return null;
    }

    if (index + 1 === array.length && !isHideMetricsDropdown) {
        insertButton = (
            <DropdownIndicators
                reportName={reportName}
                metrics={metrics}
                allMetrics={allMetrics}
                metricsLimit={metricsLimit}
                onNewMetricSelect={onNewMetricSelect}
                utils={utils}
            />
        );
    }

    const cellHeadStyles = cn(s.tableCell, s.tableCellHead);

    return (
        <th className={cellHeadStyles} key={index}>
            <IndicatorTitle
                indicator={item}
                orderBy={orderBy}
                onIndicatorClick={onIndicatorClick}
                utils={utils}
                reportName={reportName}
                forceDisableSort={forceDisableSort}
            />
            {insertButton}
        </th>
    );
};

export default Cell;
