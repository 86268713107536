import React from 'react';

export const IconLess = () => (
    <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
        <g id="icon less 20*20">
            <path
                id="Union"
                d="M13.8415 5.45544C13.5406 4.99227 12.9213 4.86065 12.4581 5.16147L6.546 9.00123C5.8167 9.47488 5.81766 10.5428 6.54779 11.0151L12.4596 14.8397C12.9233 15.1397 13.5424 15.007 13.8424 14.5433C14.1424 14.0796 14.0097 13.4605 13.546 13.1605L8.67039 10.0063L13.5475 6.83876C14.0107 6.53795 14.1423 5.91861 13.8415 5.45544Z"
                fill="#83868D"
            />
        </g>
    </svg>
);
