import { Button, Checkbox, Input } from '@adtech/ui';
import Form from '@components/Form';
import { Hint } from '@components/Hint';
import { EntityAccess } from '@configs/entity';
import { GoalFormConditionTypes, GoalFormType, goalFormTypesConfig } from '@configs/goals/goalForm';
import useGoals from '@hooks/useGoals';
import { IFilter, IGlobalFiltersSelectedOptions } from '@typings/filters';
import { IFieldStatus } from '@typings/form';
import { IConditionFilter, IGoalsConditionData } from '@typings/goals';
import { IRootSlice } from '@typings/rootSlice';
import { formUtils } from '@utils/index';
import React, { ChangeEvent, FC, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';

import s from './GoalForm.pcss';
import GoalFormCondition from './components/GoalFormCondition';

interface IETarget {
    target: {
        checked: boolean | ((prevState: boolean) => boolean);
    };
}

interface IProps {
    type: GoalFormType;
    closePopup: () => void;
    title?: string; // название цели
    accessProp?: EntityAccess; // доступ к цели
    protectedProp?: boolean; // запрет на редактирование
    sendingTDProp?: boolean; // использовать в рекламной кампании ТД
    conditionData?: IGoalsConditionData; // условие цели
    goalId?: number;
    customButtonsRender?: (submitHandler: (reqParams) => void) => React.ReactNode;
}

const GoalForm: FC<IProps> = ({
    type,
    title = '',
    accessProp,
    protectedProp,
    sendingTDProp,
    conditionData = { type: GoalFormConditionTypes.event, filters: [] },
    closePopup,
    goalId,
    customButtonsRender,
}: IProps) => {
    const storeForms = useSelector((state: IRootSlice) => state.formsSlice.forms);

    const [goalName, setGoalName] = useState(title);
    const [access, setAccess] = useState(accessProp || EntityAccess.own);
    const [isProtected, setProtected] = useState(protectedProp || false);
    const [isSendingTD, setSendingTD] = useState(sendingTDProp || false);
    const [condition, setCondition] = useState({ ...conditionData });
    const [goalNameStatus, setGoalNameStatus] = useState<IFieldStatus>({
        isValid: true,
        message: null,
    });
    const [conditionStatus, setConditionStatus] = useState<IFieldStatus>({
        isValid: true,
        message: null,
    });
    const [isShowErrors, setShowErrors] = useState(false);

    const { getEventsList, createGoal, updateGoal, transformGoalDataForRequest, getParameterFilters, isDemoProject } =
        useGoals();

    useEffect(() => {
        getEventsList();
    }, []);

    // Изменить условие (при смене сбрасывается фильтр)
    const handleChangeType = (value: GoalFormConditionTypes) => {
        setCondition({ type: value, filters: [] });
    };

    const changeGoalTitle = (e: ChangeEvent<HTMLInputElement>) => {
        const value = e.target.value;
        setGoalName(value);
    };

    const changeAccessHandler = (e: IETarget) => {
        const { checked } = e?.target || {};
        setAccess(checked ? EntityAccess.common : EntityAccess.own);

        if (!checked) setSendingTD(false);
    };

    const changeProtectedHandler = (e: IETarget) => {
        const { checked } = e?.target || {};
        setProtected(checked);

        if (!checked) setSendingTD(false);
    };

    const changeIsSendingTDHandler = (e: IETarget) => {
        const { checked } = e?.target || {};
        setSendingTD(checked);

        if (checked) {
            setAccess(EntityAccess.common);
            setProtected(true);
        }
    };

    const validateGoalName = (value: string) => {
        let status = {
            isValid: true,
            message: null,
        };

        const trimValue = value.trim();
        const validRequired = formUtils.defaultRules.required(trimValue);

        if (validRequired !== true) {
            status = {
                isValid: false,
                message: validRequired,
            };
        } else {
            const validMaxLength = formUtils.defaultRules.maxLength(255)(trimValue);

            if (validMaxLength !== true) {
                status = {
                    isValid: false,
                    message: validMaxLength,
                };
            }

            const validMinLength = formUtils.defaultRules.minLength(3)(trimValue);

            if (validMinLength !== true) {
                status = {
                    isValid: false,
                    message: validMinLength,
                };
            }
        }

        setGoalNameStatus(status);
        return status.isValid;
    };

    const validateCondition = (filtersArr: IFilter[]): boolean => {
        let status = {
            isValid: true,
            message: null,
        };
        const errorStatus = {
            isValid: false,
            message: 'Не все поля в условии заполнены',
        };

        if (!filtersArr.length) status = errorStatus;

        filtersArr.forEach(({ key, op, value }) => {
            if (!key || !op || value === undefined || value === '') {
                status = errorStatus;
            }
        });

        setConditionStatus(status);
        return status.isValid;
    };

    const validateForm = (newCondition?: IGoalsConditionData): boolean => {
        const isValidName = validateGoalName(goalName);
        const isValidCondition = validateCondition(newCondition?.filters || condition?.filters);
        const isValidForm = isValidName && isValidCondition;

        setShowErrors(!isValidForm);
        return isValidForm;
    };

    const updateCondition = (
        conditionType: GoalFormConditionTypes,
        filters: IConditionFilter[] = [],
        isCustomName?: boolean, // костыль, чтобы понять, что это кастом название события
        globalFiltersSelectedData?: Record<string, IGlobalFiltersSelectedOptions>,
    ): IGoalsConditionData => {
        const newCondition = {
            type: conditionType,
            filters,
            globalFiltersSelectedData,
            ...(isCustomName ? { custom: true } : {}),
        };

        setCondition(newCondition);

        return newCondition;
    };

    const resetCondition = (conditionType: GoalFormConditionTypes) => {
        updateCondition(conditionType, []);
    };

    const addParameterFiltersConditions = (): IGoalsConditionData => {
        const formValues = storeForms?.goalFilters?.initialValues;

        if (!formValues || !Object.keys(formValues).length) return null;

        const { parameterFilters, globalFiltersSelectedData } = getParameterFilters(formValues) || {};

        return updateCondition(GoalFormConditionTypes.parameter, parameterFilters, false, globalFiltersSelectedData);
    };

    const createGoalHandler = (reqParams?: { showCommonGoals?: boolean; limit?: number }) => {
        const newCondition = addParameterFiltersConditions();
        const isValidForm = validateForm(newCondition);

        if (!isValidForm) return;

        createGoal(
            transformGoalDataForRequest({
                title: goalName,
                access,
                is_protected: isProtected,
                is_td_sending: isSendingTD,
                conditions: newCondition || condition,
            }),
            reqParams,
        );
        closePopup();
    };

    const editGoalHandler = () => {
        const newCondition = addParameterFiltersConditions();
        const isValidForm = validateForm(newCondition);

        if (!isValidForm) return;

        updateGoal(
            goalId,
            transformGoalDataForRequest({
                title: goalName,
                access,
                is_protected: isProtected,
                is_td_sending: isSendingTD,
                conditions: newCondition || condition,
            }),
        );
        closePopup();
    };

    return (
        <Form onSubmit={() => {}} className={s.addGoalForm}>
            <Input
                value={goalName}
                name="name"
                onChange={changeGoalTitle}
                placeholder="Введите название целевого события"
                type="text"
                status={!isShowErrors || goalNameStatus.isValid ? null : 'error'}
                errorText={isShowErrors ? goalNameStatus.message : ''}
                onBlur={() => {
                    validateGoalName(goalName);
                }}
                data-testid="title-input"
            />
            <div className={s.checkboxWrap} data-testid="checkbox-container">
                <Checkbox name="access" checked={access === EntityAccess.common} onChange={changeAccessHandler}>
                    Сделать цель публичной
                </Checkbox>
                <Hint className={s.checkboxWrapHint}>
                    Цель будут видеть все, у&nbsp;кого есть доступ к&nbsp;счётчику
                </Hint>
            </div>
            <div className={s.checkboxWrap} data-testid="checkbox-container">
                <Checkbox name="protected" checked={isProtected} onChange={changeProtectedHandler}>
                    Запретить редактирование цели
                </Checkbox>
                <Hint className={s.checkboxWrapHint}>
                    Дальнейшее редактирование цели будет запрещено, ее можно будет только удалить
                </Hint>
            </div>
            <div className={s.checkboxWrap} data-testid="checkbox-container">
                <Checkbox name="isSendingTD" checked={isSendingTD} onChange={changeIsSendingTDHandler}>
                    Использовать в рекламной кампании
                </Checkbox>
                <Hint className={s.checkboxWrapHint}>
                    Цель для использования в&nbsp;рекламной кампании SberAds, редактирование будет запрещено
                </Hint>
            </div>
            <GoalFormCondition
                data={condition}
                onChangeType={handleChangeType}
                updateCondition={updateCondition}
                resetCondition={resetCondition}
                isShowError={isShowErrors}
                conditionStatus={conditionStatus}
            />
            {customButtonsRender ? (
                customButtonsRender(createGoalHandler)
            ) : (
                <div className={s.btnWrapper}>
                    <Button
                        className={s.btn}
                        size="middle"
                        disabled={isDemoProject()}
                        data-testid="ок-button"
                        onClick={type === GoalFormType.edit ? editGoalHandler : () => createGoalHandler()}
                    >
                        {goalFormTypesConfig[type].buttonTitle}
                    </Button>
                    <Button type="dashed" size="middle" data-testid="cancel-button" onClick={closePopup}>
                        Отмена
                    </Button>
                </div>
            )}
        </Form>
    );
};

export default GoalForm;
