import { dimensionsDict } from '@configs/dimensions';

const {
    event_class,
    event_name,
    date,
    session_number,
    split,
    geo_country,
    geo_area,
    geo_city,
    gender,
    age_tns,
    category_1,
    traffic_type,
    ad_campaign,
    ad_system,
    search_phrase,
    search_system,
    utm_campaign,
    utm_content,
    utm_medium,
    utm_source,
    utm_term,
    social_network,
    url,
    url_no_args,
    url_args_key,
    entry_page,
    exit_page,
    external_url,
    title,
    referer,
    referer_domain,
    referer_path,
    openstat_ad,
    openstat_campaign,
    openstat_service,
    openstat_source,
    exchange_system,
    recom_system,
    message_system,
    qr_system,
    native_mobile,
    aggrnews_system,
    counter_type_eventbase,
    screen_size,
    screen_width,
    screen_height,
    browser_family,
    browser_version_major,
    browser_version_minor,
    device_brand,
    device_family,
    device_model,
    device_type,
    os_family,
    os_version_major,
    os_version_minor,
    view_depth,
    // TODO: Скрыты пока не будут подключены со стороны бэка
    // media_title,
    // published_datetime,
    // update_time,
    // media_type,
    // author_name,
    // fullread_percent,
    // fullscroll_percent,
    ub_block_list,
    customer_params_key,
    auth_rambler,
    auth_top100,
    topic,
    app_version,
} = dimensionsDict;

// Важно! При изменении уже имеющихся позиций в конфиге нужно также
// проверить и поправить по необходимости configs/userRestrictAccessSettings
const dimensionsList = {
    title: 'Параметры',
    name: 'dimensions',
    main: true,
    children: [
        date,
        {
            title: 'События',
            name: 'events',
            children: [
                event_class,
                event_name,
                {
                    title: 'Параметры события',
                    name: 'events_params',
                    children: [
                        ub_block_list,
                        customer_params_key,
                    ],
                },
            ],
        },
        {
            title: 'Аудитория',
            name: 'audience',
            children: [
                gender,
                age_tns,
                category_1,
                topic,
                session_number,
                view_depth,
                {
                    title: 'География',
                    name: 'geo',
                    children: [
                        geo_country,
                        geo_area,
                        geo_city,
                    ],
                },
            ],
        },
        {
            title: 'Технологии',
            name: 'technology',
            children: [
                counter_type_eventbase,
                app_version,
                {
                    title: 'Разрешение экрана',
                    name: 'screen_size',
                    children: [
                        screen_size,
                        screen_width,
                        screen_height,
                    ],
                },
                {
                    title: 'Устройство',
                    name: 'device',
                    children: [
                        device_type,
                        device_brand,
                        device_family,
                        device_model,
                    ],
                },
                {
                    title: 'ОС',
                    name: 'os',
                    children: [
                        os_family,
                        os_version_major,
                        os_version_minor,
                    ],
                },
                {
                    title: 'Браузер',
                    name: 'browser',
                    children: [
                        browser_family,
                        browser_version_major,
                        browser_version_minor,
                    ],
                },
            ],
        },
        {
            title: 'Источники',
            name: 'sources',
            children: [
                {
                    title: 'UTM Метки',
                    name: 'utms',
                    children: [
                        utm_campaign,
                        utm_content,
                        utm_medium,
                        utm_source,
                        utm_term,
                    ],
                },
                {
                    title: 'Метки Openstat',
                    name: 'openstat',
                    children: [
                        openstat_ad,
                        openstat_campaign,
                        openstat_service,
                        openstat_source,
                    ],
                },
                {
                    title: 'Сайты / Referer',
                    name: 'referer',
                    children: [
                        referer,
                        referer_domain,
                        referer_path,
                    ],
                },
                traffic_type,
                ad_system,
                ad_campaign,
                search_system,
                search_phrase,
                aggrnews_system,
                exchange_system,
                recom_system,
                social_network,
                message_system,
                qr_system,
                native_mobile,
            ],
        },
        {
            title: 'Содержание',
            name: 'content',
            children: [
                url,
                url_no_args,
                url_args_key,
                entry_page,
                exit_page,
                external_url,
                title,
            ],
        },
        // TODO: Скрыты пока не будут подключены со стороны бэка
        // {
        //     title: 'Медиа',
        //     name: 'media',
        //     children: [
        //         media_title,
        //         published_datetime,
        //         update_time,
        //         media_type,
        //         author_name,
        //         fullread_percent,
        //         fullscroll_percent,
        //     ],
        // },
        {
            title: 'Тип авторизации',
            name: 'auth',
            children: [
                auth_top100,
                auth_rambler,
            ],
        },
        {
            title: 'Эксперименты',
            name: 'experiments',
            children: [
                split,
            ],
        },
    ],
};

export default dimensionsList;
