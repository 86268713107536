import authTypes from '@constants/authTypes';
import { userActions } from '@redux/slices/users';
import { IRootSlice } from '@typings/rootSlice';
import { useDispatch, useSelector } from 'react-redux';

const useUser = () => {
    const dispatch = useDispatch();
    const authType = useSelector((state: IRootSlice) => state.userSlice.authType) || authTypes.KEYCLOAK;
    const actions = {
        [authTypes.RSID]: 'logoutId',
        [authTypes.KEYCLOAK]: 'logoutKeycloak',
    };
    const action = actions[authType];

    return {
        logout: (): void => {
            if (userActions[action]) dispatch(userActions[action]());
        },
    };
};

export default useUser;
