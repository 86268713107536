import { Button } from '@adtech/ui';
import Dropdown from '@components/Dropdown';
import SavedFiltersList from '@components/SavedFilters/components/SavedFiltersList';
import attributesData from '@configs/attributesData';
import useSavedFilters from '@hooks/useSavedFilters';
import { IGlobalFilters, IStaticFilter } from '@typings/filters';
import { IUpdateMediaParams } from '@typings/media';
import { IUpdateReportOptions, IUpdateReportParams, ReportName } from '@typings/reports';
import { IRootSlice } from '@typings/rootSlice';
import { getSavedFiltersForReport } from '@utils/filtersUtils';
import React, { useEffect, useState, useRef, FC } from 'react';
import { useSelector } from 'react-redux';
import s from './SavedFilters.pcss';
import CreateOrEditSavedFiltersModal from './modals/CreateOrEditSavedFiltersModal';
import DeleteSavedFiltersModal from './modals/DeleteSavedFiltersModal';

export enum EFilterTemplateActions {
    delete = 'delete',
    save = 'save',
    create = 'create',
    edit = 'edit',
}

interface IProps {
    staticFilters: IStaticFilter[];
    accessLevel: boolean;
    reportName: ReportName;
    updateReport?: (
        type: string,
        options: IUpdateReportOptions<IUpdateReportParams | IUpdateMediaParams, ReportName>,
    ) => void;
}

const SavedFilters: FC<IProps> = ({ staticFilters, accessLevel, reportName, updateReport }) => {
    const [modalCreateOrEditData, setModalCreateOrEditData] = useState(null);
    const [modalDeleteData, setModalDeleteData] = useState(null);

    const globalFilters = useSelector((state: IRootSlice) => state.globalSlice.globalFilters);
    const savedFiltersRequest = useSelector((state: IRootSlice) => state.globalSlice.savedFiltersRequest);
    const savedFiltersData = useSelector((state: IRootSlice) => state.globalSlice.savedFiltersData);
    const savedFilter = useSelector((state: IRootSlice) => state.globalSlice.savedFilter);

    // Сохранение недоступно, если шаблон не существует,
    // или если он сохранен, но не изменен
    const isFilterExist = !!globalFilters.filtersRequest?.length;
    const isSaved = !!globalFilters.savedFilterOptions?.isSaved;
    const isEdited = !!globalFilters.savedFilterOptions?.isEdited;
    const isDisabled = !isFilterExist || (isSaved && !isEdited);

    const dataAttrName = attributesData.filters.attrName;
    const dataAttr = attributesData.filters.saveFilter;

    const selectedIdRef = useRef(null);
    const [savedFiltersList, setSavedFiltersList] = useState([]); // id, type, title
    const isInited = useRef<boolean>(false); // шаблоны фильтров были запрошены
    const api = useSavedFilters();

    // Формируем список из id, title и type
    const getSavedFiltersList = () => {
        // Получаем сохраненные фильтры, которые соответствуют отчету
        const dataForReport = getSavedFiltersForReport(savedFiltersData, staticFilters);
        const list = dataForReport.map(({ id, title, type }) => ({ id, title, type }));
        return list;
    };

    // Применить шаблон
    const applyTemplate = (id: number = null, data) => {
        selectedIdRef.current = id;

        updateReport('all', {
            updateParams: { globalFilters: data },
            options: { withoutCompare: true },
        });

        // Обновляем значения в форме
        api.updateValuesInForm(data.selectedData);
    };

    useEffect(() => {
        // Если были очищены фильтры, сбрасываем selectedId
        if (!globalFilters?.filtersRequest && !globalFilters?.id) {
            selectedIdRef.current = null;
        }
    }, [globalFilters]);

    // Если меняется savedFiltersData и был запрос за списком сохраненных фильтров или удаление/создание нового/редактирование старого
    useEffect(() => {
        if (!isInited.current) return;

        const selectedId = selectedIdRef.current;
        const list = savedFiltersData.length > 0 ? getSavedFiltersList() : [];

        if (list.length > 0 && list.length >= savedFiltersList.length) {
            // Если cоздали новый шаблон или отредактировали старый, применяем
            applyTemplate(savedFilter.id, savedFilter);
        } else if (selectedId !== null && !list.filter((item) => item.id === selectedId).length) {
            // Если удалили выбранный шаблон, сбрасываем
            applyTemplate(null, {});
        }

        setSavedFiltersList(list);
    }, [savedFiltersData]);

    useEffect(() => {
        // При изменении reportName, если ранее был запрошен список шаблонов, фильтруем список,
        // чтобы он соответстовал конфигу текущего отчёта
        if (isInited.current) isInited.current = false;
    }, [reportName]);

    // При открытии списка шаблонов
    const handleOpenTemplateList = () => {
        // Запрашиваем список шаблонов фильтров, если запроса еще не было
        if (!isInited.current && !savedFiltersRequest) {
            api.getSavedFilters();
            isInited.current = true;
        }
    };

    const handleApplyTemplate = (id: number) => {
        selectedIdRef.current = id;
        const data = savedFiltersData.find((item) => item.id === id);
        applyTemplate(id, data);
    };

    const handleClickAction = (actionName, data: IGlobalFilters = {}) => {
        switch (actionName) {
            case EFilterTemplateActions.save:
                api.applyAction(actionName, data);
                break;
            case EFilterTemplateActions.edit:
            case EFilterTemplateActions.create:
                // Открываем модальное окно с задержкой,
                // чтобы анимация открытия не пересекалась с анимацией закрытия выпадающих списков (удаление, редактирование и пр.)
                setTimeout(() => setModalCreateOrEditData({ actionName, data }), 200);

                break;
            case EFilterTemplateActions.delete:
                setTimeout(() => setModalDeleteData({ actionName, data }), 200);
                break;
            default:
                break;
        }
    };

    const onOkHandler = (actionName, data: IGlobalFilters = {}) => {
        switch (actionName) {
            case EFilterTemplateActions.edit:
            case EFilterTemplateActions.create:
                // Запрашиваем список шаблонов фильтров, если запроса еще не было
                if (!isInited.current && !savedFiltersRequest) {
                    api.getSavedFilters();
                    isInited.current = true;
                }
                setModalCreateOrEditData(null);
                break;
            case EFilterTemplateActions.delete:
                setModalDeleteData(null);
                break;
            default:
                break;
        }
        api.applyAction(actionName, data);
    };

    return (
        <div className={s.root}>
            <SavedFiltersList
                onOpen={handleOpenTemplateList}
                onSelect={handleApplyTemplate}
                onClickAction={handleClickAction}
                reportName={reportName}
                isLoading={savedFiltersRequest}
                isEdited={isEdited}
                list={savedFiltersList}
                selectedId={selectedIdRef.current}
            />
            {!isSaved ? (
                <Button
                    onClick={() => handleClickAction(EFilterTemplateActions.create)}
                    disabled={isDisabled}
                    className={s.saveButton}
                    size="small"
                    {...(dataAttrName && dataAttr ? { [`data-${dataAttrName}`]: `${dataAttr}::${reportName}` } : {})}
                >
                    Сохранить шаблон
                </Button>
            ) : (
                <Dropdown
                    type="listCustom"
                    anchorText="Сохранить шаблон"
                    dataAttrName={dataAttrName}
                    dataAttrAnchor={dataAttr}
                    reportName={reportName}
                    data={[
                        {
                            title: 'Сохранить текущий',
                            value: EFilterTemplateActions.save,
                            onClick: () =>
                                handleClickAction(EFilterTemplateActions.save, {
                                    id: globalFilters?.id,
                                }),
                        },
                        {
                            title: 'Сохранить как новый',
                            value: EFilterTemplateActions.create,
                            onClick: () => handleClickAction(EFilterTemplateActions.create),
                        },
                    ]}
                    onApply={(actionName) => handleClickAction(actionName)}
                    anchorSize="small"
                    anchorButtonType="default"
                    isDisabled={isDisabled}
                    anchorClassName={s.saveActionsAnchor}
                />
            )}
            <CreateOrEditSavedFiltersModal
                savedFiltersTitles={savedFiltersList.map((item) => item.title)}
                accessLevel={accessLevel}
                modalData={modalCreateOrEditData}
                isOpen={!!modalCreateOrEditData}
                onOkHandler={onOkHandler}
                onCloseHandler={() => setModalCreateOrEditData(null)}
            />
            <DeleteSavedFiltersModal
                accessLevel={accessLevel}
                modalData={modalDeleteData}
                isOpen={!!modalDeleteData}
                onOkHandler={onOkHandler}
                onCloseHandler={() => setModalDeleteData(null)}
            />
        </div>
    );
};

export default SavedFilters;
