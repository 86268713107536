import { sortOrders } from '@constants/sort';
import IconSimpleArrow from '@images/svg/icons/icon-simple-arrow.svg';
import classNames from 'classnames';
import * as React from 'react';
import s from './Table.pcss';

type Sort = {
    order: string;
    name: string;
};

interface IProps {
    className: string;
    right: boolean;
    narrow: boolean;
    superNarrow: boolean;
    center: boolean;
    bold: boolean;
    smallWidgetHead: boolean;
    fixedWidth: boolean;
    sort: Sort | boolean;
    children: React.ReactNode | boolean;
    onClick;
}

interface IState {
    sortByAsc: boolean;
}

export default class Cell extends React.Component<IProps, IState> {
    static defaultProps = {
        className: '',
        right: false,
        narrow: false,
        smallWidgetHead: false,
        superNarrow: false,
        center: false,
        bold: false,
        fixedWidth: false,
        sort: false,
        onClick: false,
    };

    node = null; // eslint-disable-line

    onClick = () => {
        const { onClick } = this.props;

        if (typeof onClick === 'function') {
            onClick();
        }
    };

    renderSort = () => {
        const { sort } = this.props;

        const upClass = classNames(s.arrow, s.up, {
            [s.sortActive]: typeof sort !== 'boolean' && sort.order === sortOrders.ASC,
        });

        const downClass = classNames(s.arrow, s.down, {
            [s.sortActive]: typeof sort !== 'boolean' && sort.order === sortOrders.DESC,
        });

        return (
            <div className={s.sort}>
                <IconSimpleArrow className={upClass} />
                <IconSimpleArrow className={downClass} />
            </div>
        );
    };

    render() {
        const {
            narrow,
            superNarrow,
            center,
            right,
            bold,
            sort,
            onClick,
            className,
            children,
            fixedWidth,
            smallWidgetHead,
        } = this.props;

        const style = classNames(className, s.cell, {
            [s.pointer]: onClick || sort,
            [s.narrow]: narrow,
            [s.superNarrow]: superNarrow,
            [s.bold]: bold || sort,
            [s.center]: center,
            [s.right]: right,
            [s.fixedWidth]: fixedWidth,
            [s.smallWidgetHead]: smallWidgetHead,
        });

        const cellInnerStyle = classNames({
            [s.pointerInner]: onClick || sort,
        });

        return (
            <div
                className={style}
                onClick={this.onClick}
                ref={(node) => {
                    this.node = node;
                }}
            >
                <div className={cellInnerStyle}>{children}</div>
                {sort && this.renderSort()}
            </div>
        );
    }
}
