import { createSlice } from '@reduxjs/toolkit';
import { IFieldParams, IFormStructure } from '@typings/form';
import { IFormsSlice } from '@typings/rootSlice';
import { isArray, isObject, isString } from '@utils/typesChecks';

// prettier-ignore
const dictTranslateWords = {
    'Can\'t resolve hostname': 'Сайт не найден',
};

const emptyForm: IFormStructure = {
    fields: {},
    initialValues: {},
    serverError: '',
};

const emptyField: IFieldParams = {
    mod: 'default',
    type: 'string',
    value: '',
    label: '',
    errorText: '',
    errorType: '',
    touched: false,
    focused: false,
    invalid: false,
    valid: false,
    hidden: false,
    asyncValidating: false,
};

/**
 * Initial state
 */
export const initialState: IFormsSlice = {
    forms: {},
};

/**
 * Получаем дефолтное значение по типу
 */
export const getEmptyType = (type: string) => {
    let value: any = '';

    switch (type) {
        case 'string':
            value = '';
            break;
        case 'boolean':
            value = false;
            break;
        case 'array':
            value = [];
            break;
        case 'object':
            value = {};
            break;
        default:
            value = '';
    }

    return value;
};

/**
 * Формируем информацию о поле
 */
const getFieldData = (params: any = {}, mod: string) => {
    const resultEmptyField = { ...emptyField };

    resultEmptyField.mod = mod;
    resultEmptyField.value = params.value;
    resultEmptyField.label = params.label;

    if (params.hidden) {
        resultEmptyField.hidden = params.hidden;
    }

    if (params.type) {
        if (mod === 'array' && params.value && params.value.length > 0) {
            resultEmptyField.value = params.value.map((item) => {
                const copyItem = { ...item };
                copyItem.type = params.type;

                if (params.type && !resultEmptyField.value) {
                    copyItem.value = getEmptyType(params.type);
                }

                return copyItem;
            });

            return { ...resultEmptyField, ...params.meta };
        }

        if (!resultEmptyField.value) {
            resultEmptyField.type = params.type;
            resultEmptyField.value = getEmptyType(params.type);
        }
    }

    return { ...resultEmptyField, ...params.meta };
};

/**
 * Получаем измененный кусок fields
 */
export const getDataInFields = ({
    state,
    formName,
    parentFieldName,
    fieldName,
    fieldData,
    index,
    isPushValue = false,
    isRemoveValue = false,
}: any) => {
    const currentForm = state.forms[formName];

    if (!currentForm) return {};

    const currentFields = { ...currentForm.fields };

    // Рекурсия проверки valid и asyncValidating
    const recursion = (item: any) => {
        let valid = false;
        let asyncValidating = false;

        if (isObject(item.value) && !isArray(item.value)) {
            const keys = Object.keys(item.value);

            const result = keys.map((subKey) => {
                const data = item.value[subKey];
                if (!isString(data.value)) {
                    return recursion(data.value);
                }
                return { valid: data.valid, asyncValidating: data.asyncValidating };
            });

            valid = result.filter((i) => !i.valid).length === 0;
            asyncValidating = result.filter((i) => i.asyncValidating).length > 0;
        } else if (isArray(item.value)) {
            const result = item.value.map((subItem) => recursion(subItem));

            valid = result.filter((i) => !i.valid).length === 0;
            asyncValidating = result.filter((i) => i.asyncValidating).length > 0;
        } else {
            valid = item.valid;
            asyncValidating = item.asyncValidating;
        }

        return { valid, asyncValidating };
    };

    // Сначала спускаемся вниз по дереву и применяем данные
    if (parentFieldName.length) {
        let currentField = currentFields;
        const structure = {};

        parentFieldName.forEach((key, i) => {
            if (typeof key === 'string') {
                currentField = !currentField[key] && currentField.value ? currentField.value[key] : currentField[key];
            } else if (typeof key === 'number') {
                currentField = currentField.value[key];
            }

            if (!currentField) {
                currentField = {
                    value: {},
                };
            }

            structure[i] = currentField;

            if (parentFieldName.length === i + 1) {
                if (isRemoveValue) {
                    const value = [...currentField.value[fieldName].value].filter(
                        (item, curIndex) => curIndex !== index,
                    );

                    currentField.value = {
                        ...currentField.value,
                        [fieldName]: {
                            ...currentField.value[fieldName],
                            value,
                        },
                    };
                }

                if (!isPushValue) {
                    currentField.value = {
                        ...currentField.value,
                        [fieldName]: {
                            ...currentField.value[fieldName],
                            ...fieldData,
                        },
                    };
                } else {
                    currentField.value = {
                        ...currentField.value,
                        [fieldName]: {
                            ...currentField.value[fieldName],
                            value: [...currentField.value[fieldName].value, fieldData],
                        },
                    };
                }
            }
        });

        // затем идем вверх по дереву и меняем у всех родителей мета данные
        for (let i = parentFieldName.length - 1; i >= 0; i -= 1) {
            const res = recursion(structure[i]);

            structure[i].valid = res.valid;
            structure[i].invalid = !res.valid;
            structure[i].asyncValidating = res.asyncValidating;
        }

        return currentFields;
    }

    if (isRemoveValue) {
        const value = [...currentFields[fieldName].value].filter((item, curIndex) => curIndex !== index);

        return {
            ...currentFields,
            [fieldName]: {
                ...currentFields[fieldName],
                value,
            },
        };
    }

    if (isPushValue) {
        return {
            ...currentFields,
            [fieldName]: {
                ...currentFields[fieldName],
                value: [...currentFields[fieldName].value, fieldData],
            },
        };
    }

    return {
        ...currentFields,
        [fieldName]: {
            ...currentFields[fieldName],
            ...fieldData,
        },
    };
};

/**
 * Создаем форму
 */
export const createFormFunc = (state: IFormsSlice, action) => {
    const { formName, initialValues } = action.payload;

    if (state.forms[formName]) return state;

    state.forms[formName] = {
        ...emptyForm,
        initialValues: initialValues || {},
    };
};

/**
 * Добавляем начальные данные
 */
export const initializeValuesFunc = (state: IFormsSlice, action) => {
    const { formName, values, isForceUpdate } = action.payload;

    if (!state.forms[formName]) return state;

    state.forms[formName].initialValues = {
        ...(!isForceUpdate ? state.forms[formName].initialValues : {}),
        ...values,
    };
};

/**
 * Удаляем форму
 */
export const removeFormFunc = (state: IFormsSlice, action) => {
    const newForms = { ...state.forms };

    // если массив, пробегаемся по нему и удаляем из объекта ненужные формы
    if (isArray(action.payload)) {
        const forms = action.payload;
        forms.forEach((formName) => {
            if (state.forms[formName]) {
                delete newForms[formName];
            }
        });
        // если строка, удаляем из объекта форму
    } else {
        const formName = action.payload;
        if (state.forms[formName]) {
            delete newForms[formName];
        }
    }

    state.forms = { ...newForms };
};

/**
 * Сбрасываем значения формы
 */
export const resetFormFunc = (state: IFormsSlice, action) => {
    const { formName } = action.payload;

    state.forms[formName] = {
        fields: {},
        initialValues: {},
    };
};

/**
 * Заполняем данными поле внутри формы
 */
export const createFieldFunc = (state: IFormsSlice, action) => {
    const { formName, fieldName, parentFieldName, mod, params } = action.payload;

    if (!state.forms[formName]) return state;

    const fieldData = getFieldData(params, mod);

    state.forms[formName].fields = getDataInFields({
        state,
        formName,
        fieldName,
        parentFieldName,
        fieldData,
        mod,
    });
};

/**
 * Изменяем поле внутри формы
 */
export const changeFieldFunc = (state: IFormsSlice, action) => {
    const { formName, fieldName, fieldData, parentFieldName, mod, isPushValue } = action.payload;

    state.forms[formName].fields = getDataInFields({
        state,
        formName,
        fieldName,
        parentFieldName,
        fieldData,
        mod,
        isPushValue,
    });
    state.forms[formName].serverError = '';
};

/**
 * Удаляем поле из формы
 */
export const removeFieldFunc = (state: IFormsSlice, action) => {
    const { formName, fieldName } = action.payload;

    if (!state.forms[formName]) return state;

    if (!state.forms[formName].fields[fieldName]) return state;

    delete state.forms[formName].fields[fieldName];
};

/**
 * Удаляем строку из поля
 */
export const removeFieldValueFunc = (state: IFormsSlice, action) => {
    const { formName, parentFieldName, fieldName, index, mod } = action.payload;

    if (mod !== 'array') return state;

    state.forms[formName].fields = getDataInFields({
        state,
        formName,
        fieldName,
        parentFieldName,
        mod,
        index,
        isRemoveValue: true,
    });
};

/**
 * Присваиваем ошибки полям формы
 */
export const setFieldErrorsFunc = (state: IFormsSlice, action) => {
    const { formName, errors } = action.payload;

    if (!state.forms[formName]) return state;

    const englishWords = Object.keys(dictTranslateWords);

    Object.keys(errors).forEach((key) => {
        let errorText = errors[key];

        englishWords.forEach((word) => {
            errorText = errorText.replace(word, dictTranslateWords[word]);
        });

        if (state.forms[formName].fields[key]) {
            state.forms[formName].fields[key].invalid = true;
            state.forms[formName].fields[key].valid = false;
            state.forms[formName].fields[key].errorText = errorText;
            state.forms[formName].fields[key].errorType = 'system';
        }
    });
};

/**
 * Присваиваем общую ошибку с сервера
 */
export const setServerErrorFunc = (state: IFormsSlice, action) => {
    const { formName, serverError } = action.payload;

    if (!state.forms[formName]) return state;

    state.forms[formName].serverError = serverError;
};

const formsSlice = createSlice({
    name: 'formsSlice',
    initialState,
    reducers: {
        createForm: (state, action) => createFormFunc(state, action),
        removeForm: (state, action) => removeFormFunc(state, action),
        resetForm: (state, action) => resetFormFunc(state, action),
        initializeValues: (state, action) => initializeValuesFunc(state, action),
        createField: (state, action) => createFieldFunc(state, action),
        resetField: (state, action) => createFieldFunc(state, action),
        changeField: (state, action) => changeFieldFunc(state, action),
        removeField: (state, action) => removeFieldFunc(state, action),
        setFieldErrors: (state, action) => setFieldErrorsFunc(state, action),
        setServerError: (state, action) => setServerErrorFunc(state, action),
        removeFieldValue: (state, action) => removeFieldValueFunc(state, action),
    },
});

export const {
    createForm,
    removeForm,
    resetForm,
    initializeValues,
    createField,
    resetField,
    changeField,
    removeField,
    setFieldErrors,
    setServerError,
    removeFieldValue,
} = formsSlice.actions;

export const formsActions = {
    createForm,
    removeForm,
    resetForm,
    initializeValues,
    createField,
    resetField,
    changeField,
    removeField,
    setFieldErrors,
    setServerError,
    removeFieldValue,
};

export default formsSlice.reducer;
