export const ChartColors = [
    'rgba(24, 78, 161, 1)',
    'rgba(86, 69, 225, 1)',
    'rgba(0, 135, 205, 1)',
    'rgba(66, 227, 180, 1)',
    'rgba(69, 230, 235, 1)',
    'rgba(137, 239, 70, 1)',
    'rgba(245, 186, 34, 1)',
    'rgba(170, 33, 151, 1)',
    'rgba(234, 43, 81, 1)',
    'rgba(241, 114, 163, 1)',
    'rgba(171, 176, 185, 1)',
    'rgba(131, 134, 141, 1)',
];

export const ChartColorsExt = [
    'rgba(250, 255, 0, 1)',
    'rgba(51, 63, 72, 1)',
];

export const ChartColorsMin = [
    'rgba(24, 78, 161, 1)',
    'rgba(66, 227, 180, 1)',
    'rgba(137, 239, 70, 1)',
    'rgba(245, 186, 34, 1)',
    'rgba(170, 33, 151, 1)',
    'rgba(234, 43, 81, 1)',
];

export const ChartGrayColorLight = 'rgba(171, 176, 185, 1)';

export { ChartTypes, ChartFormatterValues } from '@adtech/charts/dist/config';
