import * as Sentry from '@sentry/react';
import dayjs from 'dayjs';
import ru from 'dayjs/locale/ru';
import customParseFormat from 'dayjs/plugin/customParseFormat';
import React from 'react';
import ReactDOM from 'react-dom/client';

import '_sources/images/favicon-16x16.png';
import '_sources/images/favicon-32x32.png';

// TODO: разобраться со стилями antd
import './styles/antd.css';

import App from './App';

import './styles/root.pcss';

const app = (): void => {
    /**
     * Включаем возможность парсить кастомные форматы дат
     */
    dayjs.extend(customParseFormat);
    /**
     * Устанавливаем начало недели с понедельника
     * и устанавливаем русскую локализацию
     */
    dayjs.locale({
        ...ru,
        weekStart: 1,
    });

    /**
     * Render application
     */
    const root = ReactDOM.createRoot(document.getElementById('app') as HTMLElement);
    root.render(<App />);
};

if ((module as any).hot && __DEV__) {
    module.hot.accept('./App', app);
}

if (!__DEV__) {
    const sentryDsn = __SENTRY_DSN__ || 'https://f2966c87402e4b1aaf11a7a796801ebf@sentry.sca.ad-tech.ru/17';

    Sentry.init({
        dsn: sentryDsn,
        release: __RELEASE__,
        environment: 'production',
        integrations: [Sentry.browserTracingIntegration()],
        tracesSampleRate: 1.0,
        autoSessionTracking: false,
    });
}

try {
    app();
} catch (err) {
    Sentry.captureException(err);
}
