import { IconAlertWarning, IconCheckMark, SystemAlert } from '@adtech/ui';
import snackbarTypes from '@constants/snackbarTypes';
import { ISnackbar } from '@typings/snackbar';
import React, { useEffect } from 'react';

interface IProps {
    snackbar: ISnackbar | null;
    clearSnackbar: () => void;
}

export const SnackbarNotification = (props: IProps) => {
    let snackbarTimeout: null | ReturnType<typeof setTimeout> = null;
    const {
        snackbar: { message, type },
        clearSnackbar,
    } = props;

    const content = <span dangerouslySetInnerHTML={{ __html: message }} />;

    const renderSnackbar = () => {
        switch (type) {
            case snackbarTypes.SUCCESS:
                SystemAlert.success({
                    content,
                    duration: 3,
                    className: 'SBA-message green',
                    icon: <IconCheckMark />,
                });
                break;
            case snackbarTypes.ERROR:
                SystemAlert.error({
                    content,
                    duration: 3,
                    icon: <IconAlertWarning />,
                    className: 'SBA-message red',
                });
                break;
            default:
                SystemAlert.warning({
                    content,
                    duration: 3,
                    icon: <IconAlertWarning />,
                    className: 'SBA-message black',
                });
        }
    };

    useEffect(() => {
        if (message) {
            if (typeof snackbarTimeout !== 'undefined') {
                clearTimeout(snackbarTimeout);
            }
            snackbarTimeout = setTimeout(() => {
                clearSnackbar();
            }, 3000);

            renderSnackbar();
        }

        return () => {
            clearTimeout(snackbarTimeout);
        };
    }, [message, clearSnackbar]);

    return null;
};
