import cn from 'classnames';
import React, { SyntheticEvent, useEffect, useState } from 'react';

import s from './DraggableList.pcss';

export interface IDraggableListItem {
    id: string;
    order: number;
}

enum Direction {
    up = 'up',
    down = 'down',
}

export enum OverType {
    border = 'border',
    background = 'background',
}

interface IProps {
    list: string[];
    stylesInstance?: Record<string, string>; // кастомные стили, инстанс
    listItemClassName?: string;
    renderItemHandler?: (item: IDraggableListItem) => JSX.Element;
    onDragStart?: (e: SyntheticEvent, item: IDraggableListItem) => void;
    onDragEnd?: (e: SyntheticEvent, item: IDraggableListItem) => void;
    onDragOver?: (e: SyntheticEvent, item: IDraggableListItem) => void;
    onDragLeave?: (e: SyntheticEvent, item: IDraggableListItem) => void;
    onDrop?: (e: SyntheticEvent, item: IDraggableListItem, list: string[]) => void;
    overType?: OverType;
}

const DraggableList: React.FC<IProps> = ({
    list,
    stylesInstance,
    listItemClassName = '',
    renderItemHandler,
    onDragStart,
    onDragEnd,
    onDragOver,
    onDragLeave,
    onDrop,
    overType = OverType.border,
}) => {
    const transformList = (listEl: string[]) => listEl.map((item, i) => ({ id: item, order: i }));

    const [draggableList, setDraggableList] = useState(transformList(list));
    const [draggable, setDraggable] = useState(null);
    const [elOver, setElOver] = useState(null); // элемент, над которым находится перетаскиваемый
    const [direction, setDirection] = useState(null); // направление перетаскивания

    useEffect(() => {
        setDraggableList(transformList(list));
    }, [list]);

    const dragStartHandler = (e: SyntheticEvent, item: IDraggableListItem) => {
        setDraggable(item);
        if (onDragStart) onDragStart(e, item);
    };

    const dragEndHandler = (e: SyntheticEvent, item: IDraggableListItem) => {
        setDraggable(null);
        if (onDragEnd) onDragEnd(e, item);
    };

    const dragOverHandler = (e: SyntheticEvent, item: IDraggableListItem) => {
        e.preventDefault();

        if (elOver !== item.id) {
            setElOver(item.id);

            if (draggable?.order > item.order) {
                setDirection(Direction.up);
            } else {
                setDirection(Direction.down);
            }
        }

        if (onDragOver) onDragOver(e, item);
    };

    const dragLeaveHandler = (e: SyntheticEvent, item: IDraggableListItem) => {
        if (elOver) setElOver(null);

        if (onDragLeave) onDragLeave(e, item);
    };

    const dropHandler = (e: SyntheticEvent, item: IDraggableListItem) => {
        e.preventDefault();
        let dragDirection;

        if (draggable) setDraggable(null);
        if (elOver) setElOver(null);

        if (draggable.id === item.id) return;

        if (draggable.order > item.order) {
            dragDirection = Direction.up;
        } else {
            dragDirection = Direction.down;
        }

        const newList = draggableList
            .map((el) => {
                if (dragDirection === Direction.up && el.order >= item.order && el.order < draggable.order) {
                    return { ...el, order: el.order + 1 };
                }

                if (dragDirection === Direction.down && el.order > draggable.order && el.order <= item.order) {
                    return { ...el, order: el.order - 1 };
                }

                if (el.id === item.id) {
                    return { ...el, order: draggable.order };
                }

                if (el.id === draggable.id) {
                    return { ...el, order: item.order };
                }

                return el;
            })
            .sort((a, b) => a.order - b.order);

        setDraggableList(newList);

        if (onDrop)
            onDrop(
                e,
                item,
                newList.map((el) => el.id),
            );
    };

    const combineClasses = (stylesInst: Record<string, string>, className: string, id: string) => {
        const { border, background } = OverType;

        return cn(stylesInst[className], {
            [stylesInst[`${className}Draggable`]]: draggable?.id === id,
            [stylesInst[`${className}Over`]]: elOver === id && overType === background,
            [stylesInst[`${className}OverUp`]]: elOver === id && direction === Direction.up && overType === border,
            [stylesInst[`${className}OverDown`]]: elOver === id && direction === Direction.down && overType === border,
        });
    };

    const renderItem = (item: IDraggableListItem) => {
        const { id } = item;
        let itemClass = '';

        if (stylesInstance) {
            itemClass = cn(
                combineClasses(s, 'draggableListItem', id),
                combineClasses(stylesInstance, listItemClassName, id),
            );
        } else {
            itemClass = combineClasses(s, 'draggableListItem', id);
        }

        return (
            <div
                className={itemClass}
                key={id}
                draggable
                onDragStart={(e) => dragStartHandler(e, item)}
                onDragEnd={(e) => dragEndHandler(e, item)}
                onDragOver={(e) => dragOverHandler(e, item)}
                onDragLeave={(e) => dragLeaveHandler(e, item)}
                onDrop={(e) => dropHandler(e, item)}
            >
                {renderItemHandler ? renderItemHandler(item) : <div className={s.draggableListItemTitle}>{id}</div>}
            </div>
        );
    };

    return <>{draggableList.map((item) => renderItem(item))}</>;
};

export default DraggableList;
