import { EntityActions, IEntityAction } from '@configs/entity';

export const goalTableActionsConfig: IEntityAction[] = [
    {
        id: EntityActions.add,
        title: 'Добавить конверсию',
    },
    {
        id: EntityActions.addToCommon,
        title: 'Сделать целевое событие публичным',
    },
    {
        id: EntityActions.edit,
        title: 'Редактировать',
    },
    {
        id: EntityActions.copy,
        title: 'Копировать',
    },
    {
        id: EntityActions.delete,
        title: 'Удалить',
    },
];
