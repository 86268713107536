import { ChartTypes } from '@configs/graph';
import { sortOrders } from '@constants/sort';
import { IReportConfig } from '@typings/reports';

const reportConfig: IReportConfig = {
    reportTitle: 'Социальные сети',
    tableDataType: 'dynamic',
    defaultSort: {
        name: 'visits',
        order: sortOrders.DESC,
    },
    numberOfGraphs: 'two',
    defaultGraphs: [
        ChartTypes.pie,
        ChartTypes.bar,
    ],
    graphDefaultMetrics: [
        'visits',
        'page_views',
    ],
    tableDefaultMetrics: [
        'visits',
        'page_views',
        'avg_view_depth',
    ],
    availableMetrics: [
        'visits',
        'avg_view_depth',
        'page_views',
        'visitors',
        'uid',
        'rambler_id',
        // 'bounces',
        // 'avg_industry_duration',
    ],
    isTableTitleSearch: true,
    isShowTotals: true,
    availableTools: {
        numberOfGraphs: true,
        periodPicker: true,
        sample: true,
        activeSample: true,
        filters: true,
        savedFilters: true,
        detail: true,
    },
    reportHint: {
        message: '<p>Данные, которые агрегируются из информации, переданной в UTM и Openstat метках, '
            + 'а также из реферера (если он принадлежит социальной сети).</p>',
        link: 'https://ads.sber.ru/help/istochniki',
    },
};

export default reportConfig;
