import { Tooltip } from '@adtech/ui';
import IconTablePercent from '@images/svg/icons/icon-table-percent.svg';
import { Metrics } from '@typings/metrics';
import { arrayUtils } from '@utils/index';
import * as React from 'react';

interface IProps {
    className: string;
    iconClassName: string;
    metric: Metrics;
    isActive: boolean;
    onPercentSelect: () => void;
    onClick: () => void;
    dataAttrName?: string;
    dataAttr?: string | string[];
    reportName: string;
}

interface IState {
    filterValue: string;
    filterType: string;
}

export default class ReportPercentButton extends React.Component<IProps, IState> {
    static defaultProps = {
        onClick: () => {},
        dataAttrName: '',
        dataAttr: '',
    };

    handleButtonClick = () => {
        const { onPercentSelect, onClick } = this.props;

        onPercentSelect();

        if (typeof onClick === 'function') {
            onClick();
        }
    };

    render() {
        const { className, iconClassName, dataAttrName, dataAttr, reportName } = this.props;

        let buttonAttr = arrayUtils.convertToStringWithSeparator(dataAttr, '::');

        if (reportName) {
            buttonAttr = `${buttonAttr}::${reportName}`;
        }

        const content = (
            <div
                className={iconClassName}
                onClick={this.handleButtonClick}
                {...(dataAttrName && dataAttr.length
                    ? {
                          [`data-${dataAttrName}`]: buttonAttr,
                      }
                    : {})}
            >
                <IconTablePercent />
            </div>
        );

        return (
            <div className={className}>
                <Tooltip title="Относительные/абсолютные значения" placement="bottom">
                    {content}
                </Tooltip>
            </div>
        );
    }
}
