import { Button } from '@adtech/ui';
import React, { FC } from 'react';

import s from './TemplateForms.pcss';

interface IProps {
    saveNewTemplate: () => void;
    saveTemplate: () => void;
    closePopup: () => void;
}

const SaveOrEdit: FC<IProps> = ({ saveNewTemplate, saveTemplate, closePopup }: IProps) => {
    // Сохранение нового шаблона
    const handleSaveNewTemplate = () => {
        closePopup();
        saveNewTemplate();
    };

    // Сохранение шаблона
    const handleSaveTemplate = () => {
        closePopup();
        saveTemplate();
    };

    return (
        <div className={s.saveOrEdit}>
            <div className={s.content}>
                <div className={s.question}>Вы хотите сохранить изменения в существующем отчёте или создать новый?</div>
            </div>
            <div className={s.buttons}>
                <Button className={s.btnUpdate} size="small" onClick={handleSaveTemplate}>
                    Сохранить
                </Button>
                <Button className={s.btnSaveNew} type="dashed" size="small" onClick={handleSaveNewTemplate}>
                    Сохранить как новый
                </Button>
            </div>
        </div>
    );
};

export default SaveOrEdit;
