import { Button } from '@adtech/ui';
import ContentWithSidebar from '@components/ContentWithSidebar';
import PageHead from '@components/PageHead';
import { projectsStatus } from '@constants/projects';
import { globalActions } from '@redux/slices/global';
import { projectsActions } from '@redux/slices/projects';
import store from '@redux/store';
import { IRootSlice } from '@typings/rootSlice';

import React, { useEffect } from 'react';
import DocumentTitle from 'react-document-title';
import { useDispatch, useSelector } from 'react-redux';
import { useParams, useLocation } from 'react-router-dom';
import AccessForm from './AccessForm';
import { CounterForm } from './CounterForm';
import { RatingForm } from './RatingForm';

import s from './SettingsCounter.pcss';

const SettingsCounter: React.FC = () => {
    const dispatch = useDispatch();
    const params = useParams();
    const location = useLocation();

    const permissions = useSelector((state: IRootSlice) => state.projectsSlice.current.permissions);
    const rolePermissions = useSelector((state: IRootSlice) => state.userSlice.rolePermissions);

    const setNoAccessError = (level: boolean): void => {
        if (level) return;

        dispatch(globalActions.setError({ code: 403 }));
    };

    useEffect(() => {
        setNoAccessError(permissions.can_edit_project || rolePermissions.can_edit_project);
    }, [permissions.can_edit_project, rolePermissions.can_edit_project]);

    const pages = {
        counter: { title: 'Настройка счётчика', component: CounterForm },
        rating: { title: 'Рейтинг Топ-100', component: RatingForm },
        access: { title: 'Настройка доступа', component: AccessForm },
    };

    const getPath = () => {
        const split = location.pathname.split('/');
        return split[split.length - 1];
    };

    const getPageTitle = () => {
        const { projectId } = params;
        const path = getPath();

        const type = pages[path] ? pages[path].title : 'Настройка';

        return `${type} — Статистика от SberAds / ${projectId}`;
    };

    const changeStatus = (status: string) => {
        store.dispatch(projectsActions.changeStatus(status));
    };

    const path = getPath();
    const Component = pages[path] ? pages[path].component : false;

    if (!Component) return null;

    return (
        <DocumentTitle title={getPageTitle()}>
            <div className={s.root}>
                <PageHead title="Настройка" />
                <ContentWithSidebar navigationType="settings">
                    {__DEV__ && (
                        <div className={s.changeStatusWrapper}>
                            {Object.keys(projectsStatus).map((item) => (
                                <Button
                                    key={item}
                                    className={s.changeStatusButton}
                                    type="primary"
                                    onClick={() => changeStatus(projectsStatus[item])}
                                >
                                    {item}
                                </Button>
                            ))}
                        </div>
                    )}
                    <div className={s.content}>
                        <Component />
                    </div>
                </ContentWithSidebar>
            </div>
        </DocumentTitle>
    );
};

export default SettingsCounter;
