import captсhaConfig from '@configs/captcha';
import serverErrorsConstants from '@constants/serverErrors';
import { formDecorator } from '@decorators/index';
import { globalActions } from '@redux/slices/global';
import { projectsActions } from '@redux/slices/projects';
import { AppDispatch } from '@redux/store';
import * as Sentry from '@sentry/react';
import { formUtils, top100Counter } from '@utils/index';
import debounce from 'lodash/debounce';

import FirstStep from './FirstStep';

const mapStateToProps = ({ projectsSlice }) => ({
    needCaptcha: projectsSlice.needCaptcha,
    captchaLoading: projectsSlice.captchaRequest,
    defaultTitle: projectsSlice.defaultTitle,
    createCounterRequest: projectsSlice.createCounterRequest,
    projectsWithSameUrlRequest: projectsSlice.projectsWithSameUrlRequest,
});

const mapDispatchToProps = (dispatch: AppDispatch) => ({
    getProject: (projectId) => dispatch(projectsActions.getProject({ projectId })),

    getCaptcha: () => dispatch(projectsActions.getCaptcha()),

    getDefaultTitle: debounce((url: string) => {
        dispatch(projectsActions.getMetadata(url));
    }, 1000),

    resetDefaultTitle: () => dispatch(projectsActions.resetParam('defaultTitle')),

    createCounter: (data: Record<string, any>) => dispatch(projectsActions.create(data)),

    getProjectsWithSameUrl: (params: { url: string; limit: number; offset: number }) =>
        dispatch(projectsActions.getProjectsWithSameUrl(params)),

    clearProjectsWithSameUrl: () => dispatch(projectsActions.clearProjectsWithSameUrl()),

    openModalPopup(modalPopupId, modalPopupParams = {}) {
        dispatch(globalActions.openModalPopup({ modalPopupId, modalPopupParams }));
    },

    onSubmit() {
        const {
            values,
            invalid,
            nextStep,
            goToRestoreCounter,
            createCounter,
            getProject,
            getProjectsWithSameUrl,
            clearProjectsWithSameUrl,
            openModalPopup,
            setFieldErrors,
            needCaptcha,
            projectsWithSameUrlExist,
        } = this;

        if (invalid) return;

        if (!values.captcha_response && needCaptcha) {
            setFieldErrors({
                captcha_response: captсhaConfig.notValid,
            });
            return;
        }

        /**
         * Создаем счётчик и проверяем ответ.
         * Валидируем и подсвечиваем ошибки из ответа.
         */
        const createAndCheckCounter = () => {
            top100Counter.trackEvent('create_counter', { send: true });

            createCounter(values)
                .then(({ payload }) => {
                    top100Counter.trackEvent('create_counter', { status: payload.responseStatus });

                    if (payload.responseStatus === 200) {
                        getProject(payload.project_id).then(() => {
                            nextStep();
                            clearProjectsWithSameUrl();
                        });
                    } else if (payload && payload.errors) {
                        Sentry.captureException({
                            response: payload.errors,
                            request: values,
                        });
                        Sentry.captureMessage(payload.errors.message);

                        if (payload.errors.message === serverErrorsConstants.NOT_VALID_CAPTCHA) {
                            setFieldErrors({
                                captcha_response: captсhaConfig.notValid,
                            });
                        } else if (payload.errors.message === serverErrorsConstants.REQUEST_VALIDATION_ERRORS) {
                            const errors = formUtils.decomposeServerValidationErrors(payload.errors);
                            setFieldErrors(errors);
                        }
                    }
                })
                .catch((err) => {
                    Sentry.captureException(err);
                });
        };

        /**
         * Проверяем существуют ли счётчики с похожим url.
         * Если существуют - показываем попап о восстановлении счётчика.
         */
        if (!projectsWithSameUrlExist) {
            getProjectsWithSameUrl({
                url: values.url,
                limit: 15,
                offset: 0,
            }).then(({ payload }) => {
                if (payload.responseStatus === 200) {
                    if (payload.total) {
                        openModalPopup('RestoreAccessCounter', {
                            goToRestoreCounter,
                            createCounter: createAndCheckCounter,
                        });
                    } else {
                        createAndCheckCounter();
                    }
                }
            });
        } else {
            createAndCheckCounter();
        }
    },
});

export default formDecorator({
    formName: 'counterFirstStep',
    isRemoveForm: false,
    mapStateToProps,
    mapDispatchToProps,
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
})(FirstStep);
