import React from 'react';

export const IconNoFavicon = () => (
    <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M3 2.00008C2.44772 2.00008 2 2.4478 2 3.00008V14.0001H1C0.447715 14.0001 0 14.4478 0
            15.0001V16.0001C0 17.88 1.5635 20.0001 3.46154 20.0001H16.5385C18.4365 20.0001 20 18.4515
            20 16.5715V3.00008C20 2.44779 19.5523 2.00008 19 2.00008H3ZM4 4.00008H18V16.5715C18 17.2173
            17.5769 18.0001 16.5385 18.0001C15.5 18.0001 15 17.1458 15 16.5001C15 15.8543 15 15.4287
            15 15.4287V15.0001C15 14.4478 14.5523 14.0001 14 14.0001H4V4.00008ZM2 16.0001H13C13 16.0001
            13 17.5001 13.5 18.0001H3C2 17.5001 2 16.6458 2 16.0001Z" fill="#83868D" />
        <path d="
            M6.5 12.0001L8.5 9.50008L11.5 11.5001L15.5 6.50008"
        stroke="#83868D" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
    </svg>
);
