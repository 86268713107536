export enum ConversionFormType {
    add = 'add',
    edit = 'edit',
}

export interface ITypeConfig {
    title: string;
    buttonTitle: string;
}

export const conversionFormTypesConfig: Record<ConversionFormType, ITypeConfig> = {
    add: {
        title: 'Добавить конверсию',
        buttonTitle: 'Добавить',
    },
    edit: {
        title: 'Редактировать конверсию',
        buttonTitle: 'Сохранить',
    },
};

export const conversionMetricsList = [
    { name: 'visits', title: 'Визиты' },
    { name: 'visitors', title: 'Посетители' },
    { name: 'events_count', title: 'Количество событий' },
];
