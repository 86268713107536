import * as React from 'react';

import CheckboxType from './Types/CheckboxType';
import ListType from './Types/ListType';
import { IProps as IParentProps } from './index';

interface IProps extends IParentProps {
    getListParentId: (...rest) => any;
    getListChildId: (...rest) => any;
}

export default (props: IProps) => (
    <div data-testid="global-filters-left-column">
        {props.leftType === 'list' && (
            <ListType // TO-DO: прописать явно пропсы и сделать их обязательными
                {...props}
                filterData={(item) => item.nesting === 0}
            />
        )}
        {props.leftType === 'checkbox' && (
            <CheckboxType getDynamicData={props.getDynamicData} dynamicData={props.dynamicData} data={props.data} />
        )}
    </div>
);
