import { Tooltip } from '@adtech/ui';
import { Metrics } from '@typings/metrics';
import { IOrderBy } from '@typings/table';
import { measuresUtils, numbersUtils } from '@utils/index';
import classnames from 'classnames';
import React from 'react';

import s from '../../ReportTable.pcss';

interface IProps {
    metrics: Metrics[];
    data: number[];
    orderBy: IOrderBy;
}

export default class TableTotal extends React.Component<IProps> {
    prepareValue = (metric: Metrics, value: number) => {
        const config = measuresUtils.getConfig(metric);

        let originValue = value ? numbersUtils.numberWithSpaces(value) : 0;
        const prepareValue = measuresUtils.prepareValue(metric, value);

        if (config && config.format === 'time') {
            originValue = prepareValue;
        }

        return {
            originValue,
            prepareValue,
        };
    };

    renderCell = (metric: Metrics, index: number) => {
        const { orderBy, data } = this.props;
        const { prepareValue, originValue } = this.prepareValue(metric, data[index]);

        const className = classnames(s.tableCell, {
            [s.tableCellOrdered]: measuresUtils.isOrderingBy(metric, orderBy),
        });

        return (
            <td className={className} key={index}>
                <Tooltip className={s.tableName} title={originValue} placement="bottom">
                    {prepareValue}
                </Tooltip>
            </td>
        );
    };

    render() {
        const { data, metrics } = this.props;

        if (!Array.isArray(data) || !data.length || !(metrics && metrics.length)) return null;

        return (
            <tr className={classnames(s.tableRow, s.tableRowAverage)}>
                <td className={classnames(s.tableCell, s.tableCellName)}>
                    <div className={s.tableName}>Итого и среднее</div>
                </td>
                {metrics.map(this.renderCell)}
            </tr>
        );
    }
}
