import React from 'react';

export const IconUnEqual = () => (
    <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
        <g id="icon inequality 20*20">
            <path
                id="Union"
                d="M12.8946 6.44743C13.1416 5.95345 12.9414 5.35278 12.4474 5.10579C11.9535 4.8588 11.3528 5.05903 11.1058 5.55301L10.3823 7H6C5.44772 7 5 7.44772 5 8C5 8.55228 5.44772 9 6 9H9.38229L8.38229 11H6C5.44772 11 5 11.4477 5 12C5 12.5523 5.44772 13 6 13H7.38229L7.10579 13.553C6.8588 14.047 7.05903 14.6477 7.55301 14.8946C8.04698 15.1416 8.64766 14.9414 8.89465 14.4474L9.61836 13H14C14.5523 13 15 12.5523 15 12C15 11.4477 14.5523 11 14 11H10.6184L11.6184 9H14C14.5523 9 15 8.55228 15 8C15 7.44772 14.5523 7 14 7H12.6184L12.8946 6.44743Z"
                fill="#83868D"
            />
        </g>
    </svg>
);
