import FormCreateCounter from '@components/FormCreateCounter';
import React from 'react';
import s from '../CreateCounter.pcss';

interface IProps {
    getCaptcha: () => void;
    getDefaultTitle: () => void;
    onSubmit: () => void;
    resetDefaultTitle: () => void;
    defaultTitle: string;
    needCaptcha: boolean;
    createCounterRequest: boolean;
    projectsWithSameUrlRequest: boolean;
    invalid: boolean;
    captchaLoading: boolean;
}

export default class FirstStep extends React.PureComponent<IProps> {
    componentDidMount() {
        const { getCaptcha, resetDefaultTitle } = this.props;

        getCaptcha();
        resetDefaultTitle();
    }

    handleSubmit = () => {
        this.props.onSubmit();
    };

    render() {
        const {
            getDefaultTitle,
            defaultTitle,
            createCounterRequest,
            projectsWithSameUrlRequest,
            invalid,
            needCaptcha,
            captchaLoading,
        } = this.props;

        return (
            <div className={s.firstStep}>
                <FormCreateCounter
                    onSubmit={this.handleSubmit}
                    loading={createCounterRequest || projectsWithSameUrlRequest}
                    defaultTitle={defaultTitle ?? ''}
                    getDefaultTitle={getDefaultTitle}
                    captchaLoading={captchaLoading}
                    needCaptcha={needCaptcha}
                    invalid={invalid}
                />
            </div>
        );
    }
}
