import { ChartTypes } from '@configs/graph';
import { sortOrders } from '@constants/sort';
import { IMediaConfig } from '@typings/media';

const config: IMediaConfig = {
    reportTitle: 'Авторы',
    tableDataType: 'dynamic',
    apiExtraPath: 'author/',
    mediaIdParamReq: 'author_id',
    tableTitleDimension: 'author_name',
    tableIdDimension: 'author_id',
    relativeLinkToReport: 'author',
    isTableTitleSearch: true,
    isNotAutodetectTitleLink: true,
    isShowTotals: false,
    availableTools: {
        periodPicker: true,
        activeSample: true,
        sample: true,
        detail: true,
        filters: true,
        savedFilters: true,
        numberOfGraphs: true,
    },
    numberOfGraphs: 'two',
    defaultGraphs: [
        ChartTypes.line,
        ChartTypes.bar,
    ],
    graphDefaultMetrics: [
        'page_views',
        'visitors',
    ],
    tableDefaultMetrics: [
        'page_views',
        'visitors',
        'materials',
        'avg_view_duration',
        'avg_fullread_percent',
    ],
    availableMetrics: [
        'page_views',
        'visitors',
        'visits',
        'materials',
        'avg_view_duration',
        'avg_recirculation',
        'avg_fullread_percent',
        'avg_fullscroll_percent',
        'recirculation_count',
        'uid',
        'rambler_id',
    ],
    availableDimensions: [
        'traffic_type',
        'author_name',
    ],
    tableFixedDimensions: ['traffic_type'],
    tableLimit: 30,
    defaultSort: {
        name: 'page_views',
        order: sortOrders.DESC,
    },
    reportHint: {
        message: 'Статистика по всем авторам сайта за выбранный период. <br />'
            + 'Доступные показатели: автор, источники, просмотры, посетители, количество материалов, среднее время на материал, рециркуляция, доскроллы и дочтения.',
    },
    dataRequestFunction: 'getAuthorsSummaryRequest',
};

export default config;
