import listDataConstants from '@constants/listData';

import fieldDecorator from '@decorators/fieldDecorator';
import { GetFiltersFunction } from '@typings/filters';
import cn from 'classnames';
import * as React from 'react';

import s from '../Dropdown.pcss';

import { CheckboxType, TextareaType, PeriodType, DateType, ConditionType, ParamsArrayType } from './Types';

interface IProps {
    isDynamic: boolean;
    getDynamicData: GetFiltersFunction;
    dynamicData: Record<string, any>;
    activeItem: Record<string, any>;
    value: Record<string, any>;
    rightType: string;
    className: string;
}

const map = {
    checkbox: CheckboxType,
    textarea: TextareaType,
    period: PeriodType,
    dateTime: DateType,
    date: DateType,
    time: DateType,
    paramsArray: ParamsArrayType,
};

export const RightColumn = ({ activeItem, value, rightType, dynamicData, getDynamicData, className = '' }: IProps) => {
    const props = {
        name: activeItem.name,
        options: activeItem.options || {},
        activeItem,
        value,
        dynamicData,
        getDynamicData,
        data: activeItem.data || [],
        type: activeItem.type,
    };

    const Component = map[activeItem.type];

    const conditionInitialValue = listDataConstants.defaultValues[activeItem.condition] || '';

    // Если нет данных у динамического фильтра - выводим сообщение
    if (
        activeItem.options &&
        activeItem.options.isDynamic &&
        (!dynamicData || (dynamicData && !dynamicData.data.length))
    ) {
        return <div className={s.listEmpty}>Нет данных</div>;
    }

    // Проверяем отсутствие данных в сплитах и мультисплитах под их разный формат
    if (
        activeItem.options &&
        ((activeItem.options.content === 'multisplit' && activeItem.data && !activeItem.data.length) ||
            (activeItem.options.content === 'split' && activeItem.data.data && !activeItem.data.data.length))
    ) {
        return <div className={cn(s.listEmpty, s.listEmptySplit)}>За выбранный период сплиты отсутствуют</div>;
    }

    const listClassName = cn(s.rightList, className);

    return (
        <div className={listClassName}>
            {activeItem.condition && <ConditionType activeItem={activeItem} initialValue={conditionInitialValue} />}
            {rightType === 'custom' && Component && <Component {...props} />}
        </div>
    );
};

export default fieldDecorator({
    ignoreHidden: true,
    mod: 'object',
    type: 'object',
})(RightColumn);
