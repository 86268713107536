import attributesData from '@configs/attributesData';
import notifications from '@constants/notifications';
import { INotification } from '@typings/notification';
import { ReportName } from '@typings/reports';
import React, { FC } from 'react';

import s from './ReportHint.pcss';

interface IProps {
    title: string;
    message: string;
    isMore?: boolean;
    reportName: ReportName;
    modalContent?: FC;
    openModalPopup: (modalPopupId: string, modalPopupParams?: Record<string, unknown>) => void;
    setNotification: (notification: INotification) => void;
    isShowAlways: boolean;
    handleHiddenHint: () => void;
    handleHiddenAllHints: () => void;
}

const ReportHint: FC<IProps> = ({
    title,
    message = '',
    reportName,
    modalContent,
    isMore,
    openModalPopup,
    setNotification,
    isShowAlways,
    handleHiddenHint,
    handleHiddenAllHints,
}) => {
    const reportAttr = attributesData.newreport;

    const handleHiddenAll = () => {
        if (typeof handleHiddenAllHints === 'function') {
            setNotification(notifications.closeAllHints);

            handleHiddenAllHints();
        }
    };

    const onClickHiddenHint = () => {
        if (typeof handleHiddenHint === 'function') {
            handleHiddenHint();
        }
    };

    const setModalPopup = () => {
        openModalPopup('HintPopup', {
            modalContent,
            cancelButtonHandler: handleHiddenHint,
            title,
            message,
        });
    };

    return (
        <div className={s.root}>
            <div className={s.text} dangerouslySetInnerHTML={{ __html: message }} />
            {(isMore || !isShowAlways) && (
                <div className={s.actions}>
                    <div>
                        {isMore && (
                            <button
                                type="button"
                                className={s.actionsMore}
                                onClick={setModalPopup}
                                data-newreport={`${reportAttr.showMoreHint}::${reportName}`}
                            >
                                Узнать подробнее
                            </button>
                        )}
                        {!isShowAlways && (
                            <button
                                type="button"
                                className={s.actionsClose}
                                onClick={onClickHiddenHint}
                                data-newreport={`${reportAttr.closeHint}::${reportName}`}
                            >
                                Закрыть
                            </button>
                        )}
                    </div>
                    {!isShowAlways && (
                        <button
                            type="button"
                            className={s.actionsCloseAll}
                            onClick={handleHiddenAll}
                            data-newreport={`${reportAttr.closeAllHint}::${reportName}`}
                        >
                            Закрыть все подсказки
                        </button>
                    )}
                </div>
            )}
        </div>
    );
};

export default ReportHint;
