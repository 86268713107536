import {ChartColors, ChartColorsExt} from '@configs/graph';

export default {
    adv: {
        title: 'Рекламные системы',
        color: ChartColors[9],
    },
    aggrnews_system: {
        title: 'Новостные агрегаторы',
        color: ChartColors[3],
    },
    aggrnews: {
        title: 'Новостные агрегаторы',
        color: ChartColors[3],
    },
    email: {
        title: 'Почтовые рассылки',
        color: ChartColors[7],
    },
    internal: {
        title: 'Внутренние переходы',
        color: ChartColors[6],
    },
    local_page: {
        title: 'Сохраненные страницы',
        color: ChartColors[10],
    },
    recsystems: {
        title: 'Рекомендательные системы',
        color: ChartColors[5],
    },
    search: {
        title: 'Поисковые системы',
        color: ChartColors[1],
    },
    site: {
        title: 'По ссылкам на сайтах',
        color: ChartColors[2],
    },
    social: {
        title: 'Социальные сети',
        color: ChartColors[8],
    },
    straight: {
        title: 'Прямые заходы',
        color: ChartColors[0],
    },
    zen: {
        title: 'Яндекс.Дзен',
        color: ChartColors[4],
    },
    // FIXME цветов не хватает, используются в медиа, но там нет данных источников
    recom: {
        title: 'Рекомендательные системы',
        color: ChartColors[5],
    },
    exchange: {
        title: 'Обменные сети',
        color: ChartColors[11],
    },
    messenger: {
        title: 'Мессенджеры',
        color: ChartColorsExt[0],
    },
    qr: {
        title: 'QR-системы',
        color: ChartColorsExt[1],
    },
    native_mobile: {
        title: 'Мобильные приложения',
        color: ChartColorsExt[1],
    },
};
