import { dimensionsDict } from '@configs/dimensions';
import { ViewType } from '@constants/viewType';
import { useGraph } from '@hooks/useGraph';
import { useTable } from '@hooks/useTable';
import { Dimensions } from '@typings/dimensions';
import { Metrics } from '@typings/metrics';
import React, { ReactElement, useEffect } from 'react';

import s from './ConstructorView.pcss';

interface IProps {
    metrics: Metrics[];
    dimensions: Dimensions[];
    renderTable: (
        title?: string,
        isHideMetricsDropdown?: boolean,
        isForceEmpty?: boolean,
        isStickyTableHead?: boolean,
        isSmallWidthTable?: boolean,
        forceDisableSort?: boolean,
    ) => ReactElement;
    renderChart: (isForceEmpty?: boolean) => ReactElement;
    viewType: ViewType;
}

export const ConstructorView: React.FC<IProps> = (props) => {
    const { metrics, dimensions, renderTable, renderChart, viewType } = props;

    const forceDisableSort = dimensions[0] === 'date';
    const isHideTable = !metrics.length || !dimensions.length;

    const {
        api: { resetTable },
    } = useTable();
    const {
        api: { resetGraph },
    } = useGraph();

    useEffect(
        () => () => {
            resetTable();
            resetGraph();
        },
        [],
    );

    const getTableTitle = (dimensionsArr: Dimensions[]): string =>
        dimensionsArr
            .map((dimension) =>
                dimensionsDict[dimension]?.parentTitle
                    ? `${dimensionsDict[dimension]?.parentTitle}: ${dimensionsDict[dimension]?.title}`
                    : dimensionsDict[dimension]?.title || '',
            )
            .join(', ');

    return (
        <div className={s.root}>
            <div className={s.view}>
                {viewType === ViewType.table && (
                    <div className={s.table}>
                        {renderTable(getTableTitle(dimensions), true, isHideTable, true, true, forceDisableSort)}
                    </div>
                )}
                {viewType === ViewType.graph && <div className={s.graph}>{renderChart(isHideTable)}</div>}
            </div>
        </div>
    );
};

export default ConstructorView;
