import Grid from '@components/Grid';
import KeyMetrics from '@components/KeyMetrics';
import ReportTable from '@components/ReportTable';
import Subheader from '@components/Subheader';
import Widget from '@components/Widgets';
import { widgetsMediaConfig } from '@configs/media/widgets';
import { WidgetTypes } from '@configs/widget';
import mediaConstants from '@constants/media';
import useMedia from '@hooks/useMedia';
import useModalPopup from '@hooks/useModalPopup';
import useNotification from '@hooks/useNotification';
import { useTableMedia } from '@hooks/useTable';
import { IMediaContainer, ReportNameMedia, TRenderReportTools } from '@typings/media';
import { IRootSlice } from '@typings/rootSlice';
import React from 'react';
import { useSelector } from 'react-redux';

interface IProps {
    reportName: ReportNameMedia;
    projectId: number;
    userId: number;
    renderReportTools: TRenderReportTools;
}

const MediaAuthor: React.FC<IMediaContainer> = ({ reportName, projectId, userId, renderReportTools }: IProps) => {
    const { api: apiMedia, reportsUtils } = useMedia();

    const { api: apiTable, tableUtils } = useTableMedia();

    const api = { ...apiMedia, ...apiTable };
    const utils = { ...reportsUtils, ...tableUtils };

    const { getParamFromConfig } = reportsUtils;

    const totalsRequest = useSelector((state: IRootSlice) => state.mediaSlice.totalsRequest);
    const totals = useSelector((state: IRootSlice) => state.mediaSlice.totals);
    const trafficSources = useSelector((state: IRootSlice) => state.mediaSlice.trafficSources);
    const audience = useSelector((state: IRootSlice) => state.mediaSlice.audience);
    const fullReadScrollMedia = useSelector((state: IRootSlice) => state.mediaSlice.fullReadScrollMedia);

    const tableData = useSelector((state: IRootSlice) => state.tableSlice.tableData);
    const allMetrics = useSelector((state: IRootSlice) => state.tableSlice.allMetrics);
    const metrics = useSelector((state: IRootSlice) => state.tableSlice.metrics);
    const metricsWithStates = useSelector((state: IRootSlice) => state.tableSlice.metricsWithStates);
    const tableFilters = useSelector((state: IRootSlice) => state.tableSlice.tableFilters);
    const totalRows = useSelector((state: IRootSlice) => state.tableSlice.totalRows);
    const orderBy = useSelector((state: IRootSlice) => state.tableSlice.orderBy);
    const tableRequest = useSelector((state: IRootSlice) => state.tableSlice.tableRequest);
    const trafficLabels = useSelector((state: IRootSlice) => state.tableSlice.trafficLabels);

    const groupBy = useSelector((state: IRootSlice) => state.globalSlice.groupBy);
    const dateStart = useSelector((state: IRootSlice) => state.globalSlice.dateStart);
    const dateEnd = useSelector((state: IRootSlice) => state.globalSlice.dateEnd);

    const tableFixedDimensions = getParamFromConfig(reportName, 'tableFixedDimensions');
    const tableHintContent = getParamFromConfig(reportName, 'tableHint');
    const keyMetricsHint = getParamFromConfig(reportName, 'keyMetricsHint');
    const showTotals = getParamFromConfig(reportName, 'isShowTotals', true);

    const { setNotification } = useNotification();
    const { openModalPopup } = useModalPopup();

    const {
        trafficSources: trafficSourcesDefaults,
        audience: audienceDefaults,
        fullReadScroll: fullReadScrollDefaults,
    } = widgetsMediaConfig;

    return (
        <>
            {renderReportTools()}
            <KeyMetrics
                data={totals}
                hintTitle={keyMetricsHint}
                totalsRequest={totalsRequest}
                reportName={reportName}
            />
            <Grid>
                <Widget
                    reportName={reportName}
                    period={[dateStart, dateEnd]}
                    groupBy={groupBy}
                    type={WidgetTypes.graph}
                    title="Источники переходов"
                    name="trafficSources"
                    size={2}
                    isShowChartSwitcher
                    onUpdate={api.updateTrafficSources}
                    handleGraphSelect={api.updateWidgetParams}
                    {...trafficSourcesDefaults}
                    {...trafficSources}
                />
                <Widget
                    reportName={reportName}
                    period={[dateStart, dateEnd]}
                    groupBy={groupBy}
                    type={WidgetTypes.graph}
                    title="Аудитория"
                    name="audience"
                    size={2}
                    isShowChartSwitcher
                    onUpdate={api.updateAudience}
                    handleGraphSelect={api.updateWidgetParams}
                    {...audienceDefaults}
                    {...audience}
                />
            </Grid>
            <Grid>
                <Widget
                    reportName={reportName}
                    period={[dateStart, dateEnd]}
                    groupBy={groupBy}
                    type={WidgetTypes.graph}
                    title="Доскроллы и дочтения"
                    name="fullReadScroll"
                    size={2}
                    isDisabledSettings
                    onUpdate={api.updateFullReadScroll}
                    {...fullReadScrollDefaults}
                    {...fullReadScrollMedia}
                />
            </Grid>
            <Subheader
                reportName={reportName}
                reportTitle="Рейтинг материалов автора"
                projectId={projectId}
                userId={userId}
                hintContent={tableHintContent}
                openModalPopup={openModalPopup}
                setNotification={setNotification}
            />
            {tableData && (
                <ReportTable
                    reportName={reportName}
                    api={api}
                    utils={utils}
                    metrics={metrics}
                    allMetrics={allMetrics}
                    metricsWithStates={metricsWithStates}
                    tableTitle="Название материала, URL"
                    orderBy={orderBy}
                    totals={[]}
                    tableData={tableData}
                    titleFilter=""
                    tableFilters={tableFilters}
                    totalRows={totalRows}
                    tableRequest={tableRequest}
                    showTotals={showTotals}
                    labels={trafficLabels}
                    useSelectedLines={false}
                    constants={mediaConstants}
                    dimensions={tableFixedDimensions}
                />
            )}
        </>
    );
};

export default MediaAuthor;
