import Dropdown from '@components/Dropdown';
// import attributesData from '@configs/attributesData'; FIXME добавить разметку аналитикой блоков
import { ReportName } from '@typings/reports';
import React from 'react';

export const attributionWindowData = [
    {
        title: '7 дней',
        value: '7',
    },
    {
        title: '14 дней',
        value: '14',
    },
    {
        title: '21 день',
        value: '21',
    },
    {
        title: '28 дней',
        value: '28',
    },
];

interface IProps {
    className?: string;
    onChangeAttributionWindow: (val: string) => void;
    selected: string;
    isDisabled?: boolean;
    reportName: ReportName;
}

const AttributionWindow: React.FC<IProps> = ({
    className = '',
    onChangeAttributionWindow,
    selected,
    isDisabled,
    reportName,
}) => (
    <Dropdown
        type="selectSingle"
        anchorText="Окно атрибуции"
        anchorTextOutside
        data={attributionWindowData}
        anchorClassName={className}
        dataInitialSelected={selected || attributionWindowData[0].value}
        isDisabled={isDisabled}
        onApply={onChangeAttributionWindow}
        reportName={reportName}
    />
);

export default AttributionWindow;
