import Dropdown from '@components/Dropdown';
import { colors } from '@configs/counterBanner';

import fieldDecorator from '@decorators/fieldDecorator';
import IconTop100Banner from '@images/svg/icons/top100-banner.svg';
import { IField } from '@typings/form';
import cn from 'classnames';
import React from 'react';

import s from './LogoColorChooser.pcss';

interface IProps {
    field: IField;
}

export class LogoColorChooser extends React.Component<IProps> {
    handleColorChange = (newColor: string) => {
        this.props.field.onChange(null, newColor);
    };

    renderColorPicker = () => {
        const { field } = this.props;

        return (
            <div className={s.colorPicker}>
                {colors.map((color) => (
                    <div
                        onClick={() => this.handleColorChange(color)}
                        key={color}
                        className={cn(s.colorBlock, { [s.active]: color.toUpperCase() === field.value.toUpperCase() })}
                        style={{ backgroundColor: color }}
                    />
                ))}
            </div>
        );
    };

    renderImageGenerator() {
        const colorInlineStyle = {
            backgroundColor: this.props.field.value,
        };

        return (
            <div className={s.banner} style={colorInlineStyle}>
                <div className={s.bannerLogo}>
                    <IconTop100Banner />
                </div>
            </div>
        );
    }

    render() {
        return (
            <div className={s.root}>
                {this.renderImageGenerator()}
                <Dropdown
                    type="customContent"
                    anchorText="Выбрать цвет виджета"
                    customContent={this.renderColorPicker()}
                    contentClassName={s.colorDropdownContent}
                />
            </div>
        );
    }
}

export default fieldDecorator({
    name: 'color',
})(LogoColorChooser);
