import { ChartTypes } from '@configs/graph';
import { sortOrders } from '@constants/sort';
import { IReportConfig } from '@typings/reports';

const reportConfig: IReportConfig = {
    reportTitle: 'Рамблер/лайки',
    tableDataType: 'dynamic',
    tableTitle: 'Атрибут, домен, URL, соцсеть',
    defaultSort: {
        name: 'title',
        order: sortOrders.DESC,
    },
    numberOfGraphs: 'two',
    defaultGraphs: [
        ChartTypes.line,
        ChartTypes.bar,
    ],
    graphDefaultMetrics: [
        'usability_views',
        'usability_ctr',
    ],
    tableDefaultMetrics: [
        'usability_views',
        'usability_clicks',
        'usability_ctr',
    ],
    availableMetrics: [
        'usability_views',
        'usability_clicks',
        'usability_ctr',
        'uid',
        'rambler_id',
    ],
    sampleDefault: 1,
    isShowTotals: true,
    availableTools: {
        numberOfGraphs: true,
        periodPicker: true,
        sample: true,
        activeSample: true,
        detail: true,
    },
    reportHint: {
        message: 'Отчёт позволяет отслеживать статистику по установленным кнопкам шаринга в соцсетях, '
            + 'предоставляемым сервисом Рамблер/лайки. '
            + 'Данные собираются на всех страницах, где установлен счётчик Статистики от SberAds и кнопки шаринга. ',
    },
};

export default reportConfig;

