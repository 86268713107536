import { Button } from '@adtech/ui';
import ReportCardIcon from '@components/ReportCards/ReportCardIcon';
import { ITemplate, creatingTemplate } from '@configs/constructor/templates';
import { useRestrictAccessByEmail } from '@hooks/useRestrictAccessByEmail';
import IconCreate from '@images/svg/icons/icon-create.svg';
import { ImagesReportCards } from '@images/svg/report-cards';
import cn from 'classnames';
import React, { ReactNode, useState } from 'react';
import { Link } from 'react-router-dom';
import s from './ReportCards.pcss';

interface IProps {
    data: ITemplate[];
    createLink: string;
}

const ReportCards: React.FC<IProps> = ({ data, createLink }) => {
    const defaultIcon = 'site';
    const [isAllCardsVisible, setIsAllCardsVisible] = useState(false);
    const classNameList = cn(s.list, { [s.listNotVisebleAll]: !isAllCardsVisible });
    const minNumberOfVisibleCards = 3;

    const handleClickButton = () => setIsAllCardsVisible(true);

    const { isAccessRestricted, reports } = useRestrictAccessByEmail();

    const renderCardNavItem = (item, cardName) => {
        // Ограничение доступа к отчетам по email на основе конфига
        if (isAccessRestricted && reports?.includes(item.name)) return null;

        const iconName = item.icon || defaultIcon;

        return (
            <Link to={item.link} className={s.navItem} key={`${cardName}-${item.name}`}>
                <ReportCardIcon name={iconName} className={s.navItemIcon} />
                {item.title}
            </Link>
        );
    };

    const renderCard = (item: ITemplate): ReactNode => {
        const cardName = item.name;
        const cardTitle = item.title;

        return (
            <div className={cn(s.card, s.cardReport)} key={cardName}>
                <div className={s.cardInner}>
                    <div className={s.title}>{cardTitle}</div>
                    <div className={s.image}>
                        <ImagesReportCards type={cardName} />
                    </div>
                    <div className={s.description}>{item.description}</div>
                    <div className={s.nav}>{item.children.map((navItem) => renderCardNavItem(navItem, cardName))}</div>
                </div>
            </div>
        );
    };

    const renderListCards = () => {
        const visibleData = !isAllCardsVisible ? data.slice(0, minNumberOfVisibleCards) : data;

        return visibleData.map((item) => renderCard(item));
    };

    return (
        <>
            <div className={classNameList}>
                <Link className={cn(s.card, s.cardCreate)} key="create" to={createLink}>
                    <div className={s.cardInner}>
                        <div className={s.title}>{creatingTemplate.title}</div>
                        <div className={s.image}>
                            <IconCreate className={s.createIcon} />
                        </div>
                        <div className={s.description}>{creatingTemplate.description}</div>
                    </div>
                </Link>
                {renderListCards()}
            </div>
            {data.length >= minNumberOfVisibleCards && !isAllCardsVisible ? (
                <Button type="dashed" onClick={handleClickButton} className={s.button}>
                    Показать все шаблоны
                </Button>
            ) : null}
        </>
    );
};

export default ReportCards;
