import Dropdown from '@components/Dropdown';
import attributesData from '@configs/attributesData';
import { ChartTypes } from '@configs/graph';
import { graphTypes } from '@constants/graph';
import IconChartArea from '@images/svg/icons/icon-chart-area.svg';
import IconChartBar from '@images/svg/icons/icon-chart-bar.svg';
import IconChartLine from '@images/svg/icons/icon-chart-line.svg';
import IconChartPie from '@images/svg/icons/icon-chart-pie.svg';
import { Dimensions } from '@typings/dimensions';
import { GraphsDict } from '@typings/graph';
import { Metrics } from '@typings/metrics';
import { ReportName } from '@typings/reports';
import { measuresUtils } from '@utils/index';
import cn from 'classnames';
import React, { FC, useState, useEffect } from 'react';

import s from './ChartSwitcher.pcss';

export enum ChartIconName {
    line = 'line',
    bar = 'bar',
    pie = 'pie',
    area = 'area',
}

interface IProps {
    reportName: ReportName;
    selectedGraph: ChartTypes;
    activeMeasure: Metrics | Dimensions;
    disabledGraphs?: GraphsDict;
    enableAllGraphTypes?: boolean;
    onSelectGraph: (id: string) => void;
}

const ChartSwitcher: FC<IProps> = ({
    reportName,
    selectedGraph,
    activeMeasure,
    disabledGraphs = {},
    enableAllGraphTypes,
    onSelectGraph,
}) => {
    const [isDropdownHidden, setIsDropdownHidden] = useState(false);
    const currentGraphs = enableAllGraphTypes
        ? graphTypes
        : measuresUtils.getAvailableGraphs(activeMeasure, disabledGraphs, reportName);

    useEffect(() => {
        setIsDropdownHidden(false);
    });

    const handleSelectGraph = (id: string) => {
        onSelectGraph(id);
        setIsDropdownHidden(true);
    };

    // Костылик для реалтайма с его barSpecial графиком
    const selected = selectedGraph === 'barSpecial' ? 'bar' : selectedGraph;
    const getSelectedItem = (id: string) => selected === id;

    const ChartIcon = ({ name, className = '' }: { name: ChartIconName; className?: string }) => {
        switch (name) {
            case ChartIconName.area:
                return <IconChartArea className={className} />;
            case ChartIconName.bar:
                return <IconChartBar className={className} />;
            case ChartIconName.pie:
                return <IconChartPie className={className} />;
            default:
                return <IconChartLine className={className} />;
        }
    };

    const renderChartSwitcher = (
        <ul className={s.selectGraph}>
            {currentGraphs.map((id) => {
                const btnClassName = cn(s.selectGraphButton, {
                    [s.selectGraphButtonActive]: getSelectedItem(id),
                });
                const graphAttr = attributesData.newreport.graph;
                const graphTools = graphAttr.tools;

                if (!id && !ChartIconName[id]) return null;

                return (
                    <li key={id} className={s.selectGraphItem}>
                        <button
                            className={btnClassName}
                            onClick={() => handleSelectGraph(id)}
                            data-testid={`${id}-item`}
                            data-newreport={`${graphAttr.attrName}::${graphTools.attrName}::${graphTools.graphType}::${id}::${reportName}`} // eslint-disable-line
                            type="button"
                        >
                            <ChartIcon name={ChartIconName[id]} />
                        </button>
                    </li>
                );
            })}
        </ul>
    );

    if (currentGraphs.length === 1 || (!selected && !ChartIconName[selected])) return null;

    return (
        <Dropdown
            btnDataTestId="graph-button"
            contentClassName={s.selectGraphDropdown}
            type="customContent"
            anchorPrefixIcon={<ChartIcon className={s.anchorIcon} name={ChartIconName[selected]} />}
            anchorClassName={s.anchor}
            isShowArrow={false}
            customContent={renderChartSwitcher}
            isAnchorButton
            anchorSize="small"
            isHidden={isDropdownHidden}
        />
    );
};

export default ChartSwitcher;
