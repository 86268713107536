import { Button, IconTrash, MaskedInput, Select } from '@adtech/ui';
import * as React from 'react';

import { IconEqually, IconGreater, IconGreaterOrEq, IconLess, IconLessOrEq, IconUnEqual } from '../icons/index';
import {
    EFilterRuleOperation,
    IFilterRuleFormValue,
    IFiltersCnfItem,
    TAllFormValues,
    TFilterRuleOperation,
} from '../types/filters';
import s from './Period.pcss';

interface IProps {
    filterCnf: IFiltersCnfItem;
    currentKey: string;
    allFormsValues: TAllFormValues;
    setAllFormsValues: (formsValues: TAllFormValues) => void;
    ruleFormValue: IFilterRuleFormValue;
    curFilterFormsValues: IFilterRuleFormValue[];
    ruleIndex: number;
    deleteRuleHandler: (index: number) => void;
}

const PeriodFilter: React.FC<IProps> = ({
    filterCnf,
    currentKey,
    allFormsValues,
    setAllFormsValues,
    ruleFormValue,
    curFilterFormsValues,
    ruleIndex,
    deleteRuleHandler,
}) => {
    const renderLabel = (label: string, icon: React.ReactNode) => (
        <span className={s.periodFilterSelectIcon}>
            {icon}
            {label}
        </span>
    );
    const PERIOD_VALUES = [
        {
            label: renderLabel('Больше или равно', <IconGreaterOrEq />),
            value: EFilterRuleOperation.GTOE,
        },
        {
            label: renderLabel('Меньше или равно', <IconLessOrEq />),
            value: EFilterRuleOperation.LTOE,
        },
        {
            label: renderLabel('Больше', <IconGreater />),
            value: EFilterRuleOperation.GT,
        },
        {
            label: renderLabel('Меньше', <IconLess />),
            value: EFilterRuleOperation.LT,
        },
        {
            label: renderLabel('Не равно', <IconUnEqual />),
            value: EFilterRuleOperation.NOTEQ,
        },
        {
            label: renderLabel('Равно', <IconEqually />),
            value: EFilterRuleOperation.EQ,
        },
    ];
    const DEFAULT_MASK = { mask: Number, min: 0, scale: 0 };
    const selectValue = ruleFormValue.op !== EFilterRuleOperation.AND ? ruleFormValue.op : null;

    const changeSelectHandler = (value: string) => {
        const newFormVal = [...curFilterFormsValues];
        newFormVal[ruleIndex] = { ...newFormVal[ruleIndex], op: value as TFilterRuleOperation };
        setAllFormsValues({ ...allFormsValues, [currentKey]: newFormVal });
    };

    const changeInputHandler = (value: string) => {
        const newFormVal = [...curFilterFormsValues];
        newFormVal[ruleIndex] = { ...newFormVal[ruleIndex], value };
        setAllFormsValues({ ...allFormsValues, [currentKey]: newFormVal });
    };

    // У периодов можно задавать произвольную маску и placeholder (см. react-imask)
    return (
        <div className={s.periodFilter}>
            <div className={s.periodFilterCol}>
                <Select
                    placeholder="Условие"
                    options={PERIOD_VALUES}
                    value={selectValue}
                    onChange={changeSelectHandler}
                />
            </div>
            <div className={s.periodFilterCol}>
                <MaskedInput
                    value={ruleFormValue.value as string}
                    onChange={changeInputHandler}
                    maskOptions={filterCnf?.inputMaskOptions || DEFAULT_MASK}
                    placeholder={filterCnf?.inputPlaceholder || 'Значение'}
                />
            </div>
            {curFilterFormsValues?.length > 1 ? (
                <Button icon={<IconTrash />} type="dashed" onClick={() => deleteRuleHandler(ruleIndex)} />
            ) : null}
        </div>
    );
};

export default PeriodFilter;
