import { ConversionFormType } from '@configs/conversions/conversionForm';
import { EntityActions, EntityAccess, IEntityAction } from '@configs/entity';
import { popularityFilters, staticFilters } from '@configs/filters/filtersConfigGoals';
import { GoalFormType } from '@configs/goals/goalForm';
import { GroupBy } from '@configs/group';
import dateConstants from '@constants/date';
import { ESpecialEventsClasses } from '@constants/events';
import reportsConstants from '@constants/reports';
import SnackbarTypes from '@constants/snackbarTypes';
import userRoles from '@constants/userRoles';
import useModalPopup from '@hooks/useModalPopup';

import useSnackbar from '@hooks/useSnackbar';
import { useAppDispatch } from '@hooks/useStore';
import { useTable } from '@hooks/useTable';
import { formsActions } from '@redux/slices/forms';
import { globalActions } from '@redux/slices/global';
import { goalsActions } from '@redux/slices/goals';

import { IFilter, IGlobalFiltersSelectedOptions } from '@typings/filters';
import { IGoalData, IGoalsTableData } from '@typings/goals';
import { IReportRequestParams, IUpdateReportParams } from '@typings/reports';
import { IRootSlice } from '@typings/rootSlice';
import DateUtils from '@utils/date';
import { listDataUtils } from '@utils/index';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';

const useGoals = () => {
    const params: IUrlParams = useParams();
    const { projectId } = params;
    const dispatch = useAppDispatch();

    const { openModalPopup, closeModalPopup } = useModalPopup();
    const { setSnackbar } = useSnackbar();
    const {
        api: { updateTableParams },
    } = useTable();

    const showCommonGoals = useSelector((state: IRootSlice) => state.goalsSlice.showCommonGoals);
    const searchWord = useSelector((state: IRootSlice) => state.tableSlice.titleFilter);
    const userEmail = useSelector((state: IRootSlice) => state.userSlice.email);
    const userRole = useSelector((state: IRootSlice) => state.userSlice.roleId);

    const api = {
        init(reqParams: IUpdateReportParams & { showCommonGoals?: boolean }): void {
            api.updateParams({ showCommonGoals: !!reqParams?.showCommonGoals });
            api.getTable(reqParams);
        },

        getTable(
            reqParams?: IUpdateReportParams & {
                showCommonGoals?: boolean;
                showDeletedGoals?: boolean;
            },
            limit?: number,
            offset?: number,
        ): void {
            dispatch(
                goalsActions.getTable({
                    projectId,
                    params: {
                        limit,
                        offset,
                        titleFilter: reqParams?.titleFilter || searchWord,
                        showCommonGoals,
                        ...reqParams,
                    },
                }),
            );
        },

        getTableOffset(
            offset: number = 0,
            reqParams?: {
                limit?: number;
                showCommonGoals?: boolean;
                showDeletedGoals?: boolean;
            },
        ): void {
            dispatch(
                goalsActions.updateTable({
                    projectId,
                    offset,
                    searchWord,
                    showCommonGoals,
                    ...reqParams,
                }),
            );
        },

        // Обновление таблицы целей, например, при поиске
        updateTable(updateParams: IUpdateReportParams & { showCommonGoals?: boolean }): void {
            updateTableParams(updateParams);
            api.getTable(updateParams);
        },

        // Получение целей по заданному списку id
        getGoalsByIds(ids: number[]): Promise<any> {
            return dispatch(goalsActions.getGoalsByIds(ids)).unwrap();
        },

        transformGoalDataForRequest(data: Partial<IGoalsTableData>): IGoalData {
            const { conditions, is_protected, access, is_td_sending } = data || {};
            // let metricFilters = [];

            if (!conditions) return null;

            // Чистим фильтры от valuesTitles, которые нужны только в conditions
            const dimensionFilters = conditions.filters.map(({ op, key, value }) => ({ op, key, value }));
            const body = {
                ...(dimensionFilters.length ? { dimension_filters: dimensionFilters } : {}),
                // ...(metricFilters.length ? { metric_filters: metricFilters } : {}),
            };

            return {
                title: data?.title || '',
                conditions,
                body,
                project_id: Number(projectId),
                access,
                is_protected,
                is_td_sending,
            };
        },

        // reqParams нужны для того, чтобы корректно обновлять таблицу после запроса
        createGoal(data: IGoalData, reqParams: { showCommonGoals?: boolean; limit?: number }): void {
            dispatch(goalsActions.createGoal(data))
                .unwrap()
                .then((res) => {
                    if (res?.responseStatus === 400) {
                        if (res?.errors?.message === 'Rate limit exceeded') {
                            setSnackbar(
                                'Превышен лимит создания целей в минуту. Пожалуйста, подождите минуту и попробуйте снова',
                                SnackbarTypes.ERROR,
                            );
                            return;
                        }

                        dispatch(globalActions.setError({ code: 400 }));
                    }

                    if (res?.responseStatus !== 200) return;
                    api.getTable(reqParams);
                    setSnackbar('Целевое событие создано');
                });
        },

        updateGoal(id: number, data: Partial<IGoalData>): void {
            dispatch(goalsActions.updateGoal({ id, data }))
                .unwrap()
                .then((res) => {
                    if (res?.responseStatus !== 200) return;
                    api.getTable();
                    setSnackbar('Целевое событие изменено');
                });
        },

        deleteGoal(id: number): void {
            dispatch(goalsActions.deleteGoal(id))
                .unwrap()
                .then((res) => {
                    if (res?.responseStatus !== 200) return;
                    setSnackbar('Целевое событие удалено');
                });
        },

        updateParams(newParams: Record<string, any>): void {
            dispatch(goalsActions.updateParams(newParams));
        },

        getDataForGlobalFiltersList(
            filters?: IFilter[],
            globalFiltersSelectedData?: Record<string, IGlobalFiltersSelectedOptions>,
        ) {
            const filtersConfig = {
                staticFilters,
                popularityFilters,
            };

            const filtersGlobal = {
                filtersRequest: filters,
                selectedData: globalFiltersSelectedData,
            };

            return listDataUtils.getFiltersData(filtersConfig, filtersGlobal);
        },

        // Получение фильтров для типа условия "Параметр",
        // идет отдельно поскольку работаем со сложным компонентом GlobalFiltersList
        // и вытаскиваем значения формы из стора
        getParameterFilters(formValues?: Record<string, any>): {
            parameterFilters: IFilter<string>[];
            globalFiltersSelectedData: Record<string, IGlobalFiltersSelectedOptions>;
        } {
            if (!formValues) return null;

            const notEmptyValues = listDataUtils.removeEmptyData(formValues);
            const { listData } = api.getDataForGlobalFiltersList();

            // Конвертируем в массив, добавляя значения из конфига фильтров
            const filtersValues = listDataUtils.convertValuesToArray(notEmptyValues, listData.staticFilters.data);

            // prettier-ignore
            const globalFiltersData = Object.keys(notEmptyValues).length ? {
                filtersRequest: listDataUtils.filteredValues(filtersValues, true, true),
                selectedData: notEmptyValues,
            } : {};

            return {
                parameterFilters: globalFiltersData.filtersRequest as unknown as IFilter<string>[],
                globalFiltersSelectedData: globalFiltersData.selectedData,
            };
        },

        // Сброс состояний полей фильтров по условию
        resetFormState(): void {
            dispatch(formsActions.resetForm({ formName: 'goalFilters' }));
        },

        // FIXME: есть аналогичная ручка на дашборде, но с другим форматом, возможно стоит объединить
        getEventsList(): void {
            const { DATE_FORMAT } = dateConstants;

            const dateStart = DateUtils.someDaysAgo(30).format(DATE_FORMAT);

            const requestParams = {
                date_start: dateStart,
                date_end: 'today',
                group: GroupBy.day,
                metrics: ['events_count'],
                dimensions: ['event_class', 'event_name'],
                dimension_filters: [{ key: 'event_class', op: '!=', value: ESpecialEventsClasses.tech }],
                sort: { name: 'events_count', order: 'desc' },
                limit: 50,
                sample: 1,
            } as IReportRequestParams;

            dispatch(
                goalsActions.getEventsList({
                    projectId,
                    params: requestParams,
                }),
            );
        },

        openEditOrAddGoalPopup(id: number, tableData: IGoalsTableData[], isAdd?: boolean): void {
            const goalData = tableData.find((item) => item.id === id);

            const closeHandler = (): void => {
                dispatch(globalActions.clearModalPopup());
                api.resetFormState();
            };

            openModalPopup('GoalForm', {
                type: isAdd ? GoalFormType.add : GoalFormType.edit,
                title: goalData.title,
                conditionData: goalData.conditions,
                access: goalData?.access,
                isProtected: goalData?.is_protected,
                isSendingTD: goalData?.is_td_sending,
                closePopup: closeHandler, // обработка закрытия через собственные кнопки формы
                goalId: goalData.id,
                onClose: closeHandler, // обработка закрытия через крестик
            });
        },

        openAddConversionPopup(id: number, conversionsAPI: TAPI): void {
            openModalPopup('ConversionForm', {
                type: ConversionFormType.add,
                closePopup: closeModalPopup,
                data: { numerator_goal_id: id },
                api: conversionsAPI,
                isCalledFromGoalsReport: true,
            });
        },

        // Получаем список действий для шестеренки таблицы целей
        getGoalsTableActionsList(
            actions: IEntityAction[],
            tableData: IGoalsTableData[],
            goalId: number,
            isProtected: boolean,
            access: EntityAccess,
            ownerEmail: string,
            conversionsAPI: TAPI,
        ): IEntityAction[] {
            const newActions = actions.filter((action) => {
                const { id } = action;

                if (isProtected && id === EntityActions.edit) return false;

                if ((isProtected || access === EntityAccess.common) && id === EntityActions.addToCommon) return false;

                if (userEmail !== ownerEmail && id === EntityActions.delete) return false;

                return true;
            });

            return newActions.map((item) => {
                const result = { ...item };

                switch (item.id) {
                    case EntityActions.add:
                        result.onClick = () => api.openAddConversionPopup(goalId, conversionsAPI);
                        break;
                    case EntityActions.edit:
                        result.onClick = () => api.openEditOrAddGoalPopup(goalId, tableData);
                        break;
                    case EntityActions.copy:
                        result.onClick = () => api.openEditOrAddGoalPopup(goalId, tableData, true);
                        break;
                    case EntityActions.delete:
                        result.onClick = () => api.deleteGoal(goalId);
                        break;
                    case EntityActions.addToCommon:
                        result.onClick = () =>
                            api.updateGoal(goalId, {
                                access: EntityAccess.common,
                            });
                        break;
                    default:
                        break;
                }

                return result;
            });
        },

        isDemoProject(): boolean {
            return Number(projectId) === reportsConstants.DEMO_PROJECT_ID && userRole !== userRoles.ADMIN;
        },
    };

    return api;
};

export default useGoals;
