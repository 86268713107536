import { Modal, Alert, DoubleDatepicker, Button, IconArrowRight } from '@adtech/ui';
import { InputMask } from '@components/Input';
import { rangePresets } from '@configs/dateRangePresets';
import dateConstants from '@constants/date';
import useDateModal from '@hooks/useDateModal';
import useReport from '@hooks/useReport';
import { DateType, DateLiteral, IDateRange, DateRangeType } from '@typings/date';
import DateUtils from '@utils/date';
import moment from 'moment';
import React, { useState, useEffect, FC, ReactNode } from 'react';

import s from './DateModal.pcss';
import { getDateRangeConfig } from './utils/dateRangeUtils';

export enum DateRange {
    dateStart = 'dateStart',
    dateEnd = 'dateEnd',
}

interface IProps {
    isOpened: boolean;
    defaultDate: IDateRange;
    serverTime: number;
    minDate: DateType;
    maxDate: DateType;
    nowDateDiff?: number; // разница с текущей датой (напр., для рейтинга: '-1' - отнимает сутки)
    isExcludedLatency: boolean;
    onChange?: (date: DateType[], relativeDate: DateLiteral) => void;
    onClose: () => void;
    getLatency: () => void;
}

const DateModal: FC<IProps> = ({
    isOpened = true,
    defaultDate,
    serverTime,
    minDate,
    maxDate,
    nowDateDiff = 0,
    isExcludedLatency,
    onChange,
    onClose = () => {},
    getLatency = () => {},
}) => {
    const { ALERT_MESSAGES, DATE_VIEW, DATE_FORMAT, DATE_VIEW_REGEXP, DATE_SIMPLE } = dateConstants;

    const timezone = 'Московское время (GMT+3)';

    const RANGE_PRESETS: Record<string, [moment.Moment, moment.Moment]> =
        nowDateDiff !== 0 ? rangePresets.nowDateDiff : rangePresets.default;

    const dateRangeConfig = getDateRangeConfig(nowDateDiff);
    const intervalList = Object.keys(dateRangeConfig);

    const [isLongPeriod, setIsLongPeriod] = useState<boolean>(false); // более 90 дней
    const [selectedInterval, setSelectedInterval] = useState<DateRangeType>(null);
    const [isValidDateRange, setIsValidDateRange] = useState<boolean>(isExcludedLatency !== true);
    const [dateRange, setDateRange] = useState<IDateRange>(null);

    const { api } = useDateModal();
    const {
        api: { getServerTime },
    } = useReport();

    // Если текущая дата не совпадает с датой из api,
    // запрашиваем заново serverTime и latency.
    // Не используем DateUtils для today для того, чтобы дата была относительно клиентского времени
    useEffect(() => {
        if (!isOpened) return;

        const serverTimeDay = DateUtils.getDate(serverTime).date();
        const today = new Date().getDate();

        if (serverTimeDay !== today) {
            getLatency();
            getServerTime();
        }
    }, [isOpened]);

    // Если пришли новые параметры (defaultDate, minDate, maxDate),
    // пересчитываем показатели
    useEffect(() => {
        if (defaultDate) setDateRange(defaultDate);
    }, [defaultDate, minDate, maxDate]);

    // При изменении диапозона дат,
    // пересчитываются показатели
    useEffect(() => {
        if (dateRange) {
            const interval = intervalList.find(
                (key) =>
                    api.checkInterval(dateRange, dateRangeConfig[key].startDate(), dateRangeConfig[key].endDate()) ||
                    null,
            ) as DateRangeType;

            setSelectedInterval(interval);
            setIsLongPeriod(DateUtils.isLongPeriod(dateRange, 'day'));
            setIsValidDateRange(api.validationDateRange(dateRange, minDate, maxDate));
        }
    }, [dateRange]);

    // Отменить
    const handleCancel = async () => {
        // Перед отменой возвращаем исходную дату
        await setDateRange(defaultDate);
        onClose();
    };

    // Применить
    const handleApply = (): void => {
        if (!isValidDateRange) return;

        const newDateRange = DateUtils.validAndRevertInterval([dateRange.dateStart, dateRange.dateEnd]);

        if (typeof onChange === 'function') {
            const relativeDate = dateRangeConfig[selectedInterval]?.literal || null;
            onChange(newDateRange, relativeDate);
        }

        onClose();
    };

    if (!defaultDate || !dateRange) return null;

    const handleChangeDate = ([dateStart, dateEnd]: moment.Moment[]): void => {
        const newDateStart = DateUtils.getDate(+dateStart).format(DATE_FORMAT);
        const newDateEnd = DateUtils.getDate(+dateEnd).format(DATE_FORMAT);

        const newDateRange = {
            dateStart: newDateStart,
            dateEnd: dateEnd ? newDateEnd : newDateStart,
        };
        setDateRange(newDateRange);
    };

    const disabledDate = (current: moment.Moment) =>
        current &&
        (current > moment(DateUtils.getDate(maxDate).toDate()) ||
            current < moment(DateUtils.getDate(minDate).toDate()));

    // Рендер предупреждения
    const renderAlert = (message: string): ReactNode => <Alert type="warning" message={message} showIcon />;

    // При изменении в текстовом поле
    const handleChangeInput = (e, name: string): void => {
        const { value } = e.target;
        const newDateRange = { ...dateRange };
        // Валидирование даты происходит, если все числа у даты введены
        if (!DATE_VIEW_REGEXP.test(value)) {
            if (DATE_SIMPLE.test(value)) setIsValidDateRange(DateUtils.isValidInputDate(value));
            return;
        }
        newDateRange[name] = DateUtils.getCustomDate(value, DATE_VIEW).format(DATE_FORMAT);

        setDateRange(newDateRange);
    };

    // Получение значения для текстового поля
    const getInputValue = (name: DateRange): string => DateUtils.getDateWithFormat(dateRange[name], DATE_VIEW);

    // Рендер поля для ввода даты
    const renderInput = (name: DateRange, isStart?: boolean): ReactNode => (
        <InputMask
            mask="11.11.1111"
            value={getInputValue(name)}
            onChange={(e) => handleChangeInput(e, name)}
            placeholder="ДД.ММ.ГГГГ"
            className={s.inputsItem}
            dataTestId={isStart ? 'dateStart-input' : 'dateEnd-input'}
        />
    );

    // подтягиваем сам календарь в дереве к родителю, иначе рендерится на верхнем уровне DOM
    const getPopupContainer = (trigger) => trigger.parentNode;

    return (
        <Modal
            size={617}
            onClose={handleCancel}
            closeOnEsc={false}
            closeOnClickOutside={false}
            isOpened={isOpened}
            customFooter={false}
            footerCustomElement={false}
            className={s.wrapper}
        >
            <div className={s.popupInner} data-testid="date-modal">
                <div className={s.alert}>
                    {isExcludedLatency && !isValidDateRange && renderAlert(ALERT_MESSAGES.excludedLatency)}
                    {!isValidDateRange && renderAlert(ALERT_MESSAGES.notValidDateRange)}
                    {isLongPeriod && renderAlert(ALERT_MESSAGES.longPeriod)}
                </div>

                <div className={s.calendar}>
                    <div className={s.inputs} data-testid="period-container">
                        {renderInput(DateRange.dateStart, true)}
                        <IconArrowRight />
                        {renderInput(DateRange.dateEnd)}
                    </div>
                    <DoubleDatepicker
                        allowClear={false}
                        popupClassName="datePickerPopup"
                        getPopupContainer={getPopupContainer}
                        value={[
                            moment(api.getCheckedDatesForCalendar(dateRange)[0]),
                            moment(api.getCheckedDatesForCalendar(dateRange)[1]),
                        ]}
                        defaultValue={[
                            moment(DateUtils.getDate(minDate).toDate()),
                            moment(DateUtils.getDate(maxDate).toDate()),
                        ]}
                        disabledDate={disabledDate}
                        onCalendarChange={handleChangeDate}
                        open={isOpened}
                        ranges={RANGE_PRESETS}
                        renderExtraFooter={() => (
                            <div className={s.footer}>
                                <div className={s.currentDate} data-testid="choice-container">
                                    <div className={s.dateRange}>
                                        {DateUtils.getDateWithFormat(dateRange.dateStart, DATE_VIEW)} -
                                        {DateUtils.getDateWithFormat(dateRange.dateEnd, DATE_VIEW)}
                                    </div>
                                    <div className={s.timezone}>{timezone}</div>
                                </div>
                                <div className={s.buttons}>
                                    <Button
                                        className={s.btnCancel}
                                        type="dashed"
                                        onClick={handleCancel}
                                        data-testid="cancel-button"
                                    >
                                        Отменить
                                    </Button>
                                    <Button
                                        disabled={!isValidDateRange}
                                        className={s.btnApply}
                                        type="default"
                                        onClick={handleApply}
                                        data-testid="ок-button"
                                    >
                                        Применить
                                    </Button>
                                </div>
                            </div>
                        )}
                    />
                </div>
            </div>
        </Modal>
    );
};

export default DateModal;
