import { TChartProps } from '@adtech/charts/dist/types';
import { ChartGrayColorLight } from '@configs/graph';
import React from 'react';

const Chart = ({ graphTypes, data, children, customGraphColors, getCustomColors, isTransparent }) => {
    if (children[0] === null) return null;

    return React.Children.map(children, (child, i) => {
        // HYDRA-6623
        // this.state.customGraphColors может оказаться пустым после переключения
        // на один график с круговой диаграмой
        // в этом случае берем количество цветов из props.data
        let colors = customGraphColors.length ? customGraphColors : getCustomColors(data);

        const graphType = graphTypes ? graphTypes[i] : null;

        if (graphType === 'pie') {
            const grayColor = isTransparent ? `${ChartGrayColorLight.slice(0, -2)}0.25)` : ChartGrayColorLight;

            colors = [...colors, grayColor];
        }

        const chartProps: Partial<TChartProps> = { colors };

        return React.cloneElement(child, { ...chartProps });
    });
};

export default Chart;
