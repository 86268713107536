import { Tooltip } from '@adtech/ui';
import cn from 'classnames';
import React, { useState } from 'react';
import s from './ChartWithLegend.pcss';

const Legend = ({
    legendClassName,
    classNameItem,
    data,
    customGraphColors,
    setCustomGraphColors,
    setIsTransparent,
    resetColors,
}) => {
    const [itemsWithOpacity, setItemsWithOpacity] = useState([]);

    const legendClassNames = cn(s.legend, legendClassName);

    const trimColors = (colors: string[]): string[] =>
        colors.map((item) => item.slice(5, -1).split(', ').slice(0, -1).join(', '));

    const convertIntoRgba = (rgb: string, alpha: string) => `rgba(${rgb}, ${alpha})`;

    const handleMouseEnter = (id: string) => {
        const newItemsWithOpacity = data.filter((item) => item.id !== id);

        setItemsWithOpacity(newItemsWithOpacity);

        const trimmedColors = trimColors(customGraphColors);
        const trimmedId = trimColors([id])[0];

        const newColors = trimmedColors.map((item) => {
            if (item !== trimmedId) {
                return item.indexOf('0.25') > -1 ? convertIntoRgba(item, '1') : convertIntoRgba(item, '0.25');
            }

            return convertIntoRgba(item, '1');
        });

        setCustomGraphColors(newColors);
        setIsTransparent(true);
    };

    const handleMouseLeave = () => {
        setCustomGraphColors(resetColors);
        setIsTransparent(false);
        setItemsWithOpacity([]);
    };

    const checkOpacity = (id: string) => {
        if (!itemsWithOpacity.length) return false;

        let hasOpacity = false;

        itemsWithOpacity.map((item) => {
            if (item.id === id) {
                hasOpacity = true;
            }
            return item;
        });

        return hasOpacity;
    };

    return (
        <div className={legendClassNames} data-testid="legend-container">
            {data.map((item, i) => (
                <div
                    className={cn(s.legendItem, classNameItem, {
                        [s.legendItemOpacity]: checkOpacity(item.id),
                    })}
                    id={item.id}
                    onMouseEnter={() => handleMouseEnter(item.id)}
                    onMouseLeave={handleMouseLeave}
                    key={`${item.id}${i + 1}`}
                >
                    <Tooltip title={item.title}>
                        <div className={s.legendItemInner} data-testid="legend-item">
                            <span
                                className={s.legendColor}
                                style={{
                                    backgroundColor: item.color,
                                }}
                            />
                            <span className={s.legendTitle}>{item.title}</span>
                        </div>
                    </Tooltip>
                </div>
            ))}
        </div>
    );
};

export default Legend;
