import { Hint } from '@components/Hint';
import cn from 'classnames';
import React, { FC, ReactNode } from 'react';

import s from './Widget.pcss';

interface IProps {
    title: string;
    hintTitle: string;
    children: ReactNode;
    titleClickHandler?: () => void;
}

const Header: FC<IProps> = ({ title, children, hintTitle = '', titleClickHandler }: IProps) => {
    const headerClassName = cn(s.headerTitle, {
        [s.headerTitleClickable]: !!titleClickHandler,
    });

    return (
        <div className={s.header}>
            <div className={headerClassName} onClick={titleClickHandler} data-testid="title-text">
                {title}
                {hintTitle && <Hint>{hintTitle}</Hint>}
            </div>
            {children || null}
        </div>
    );
};

export default Header;
