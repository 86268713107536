import { IMediaConfig } from '@typings/media';

const config: IMediaConfig = {
    reportTitle: 'Отчёт по материалу',
    tableDataType: 'dynamic',
    isShowTotals: false,
    availableTools: {
        periodPicker: true,
        activeSample: true,
        sample: true,
        detail: true,
        filters: true,
        savedFilters: true,
    },
    tableDefaultMetrics: [],
    availableMetrics: [],
    defaultSort: null,
    isDisabledCheckbox: true,
    reportHint: {
        message: 'Подробный отчёт по определённому материалу. Доступные показатели: источники переходов на материал, на какие материалы перешли пользователи далее, статистика по аудитории материала, доскроллы и дочтение',
    },
    keyMetricsHint: 'Основной раздел отчёта, демонстрирующий целевые метрики по материалу за выбранный период (по умолчанию показаны метрики за сегодня)',
    isShowLinkWithTitle: true,
    dataRequestFunction: 'getMaterialRequest',
    // Порядок вывода на страницу в компоненте materialInfo соответствует этому
    materialInfoDimensions: [
        'media_title',
        'media_url',
        'published_datetime',
        'published_date',
        'published_time',
        // Временно скрываем update_time и media_type
        // 'update_time',
        // 'media_type',
        'author_name',
    ],
};

export default config;
