import { useAppDispatch } from '@hooks/useStore';
import { globalActions } from '@redux/slices/global';
import { INotification } from '@typings/notification';
import { IRootSlice } from '@typings/rootSlice';
import { storagesUtils } from '@utils/index';
import { useSelector } from 'react-redux';

const useNotification = () => {
    const dispatch = useAppDispatch();
    const userId = useSelector((state: IRootSlice) => state.userSlice.id);

    const setNotification = (notification: INotification): void => {
        const { storageKey } = notification || {};
        // Если указан параметр-ключ в localStorage, то используем его для определения видимости
        if (storageKey && storagesUtils.get(storageKey, userId)) return;

        dispatch(globalActions.setNotification(notification));
    };

    const clearNotification = (): void => {
        dispatch(globalActions.clearNotification());
    };

    return {
        setNotification,
        clearNotification,
    };
};

export default useNotification;
