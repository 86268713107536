import { TSizeRow } from '@typings/table';
import classNames from 'classnames';
import * as React from 'react';

import s from './Table.pcss';

interface IProps {
    className: string;
    center?: boolean;
    size?: TSizeRow;
    header?: boolean;
    colored?: boolean;
    responsiveSize?: boolean;
    extraNarrow?: boolean;
    children: React.ReactNode;
}

export const Row: React.FC<IProps> = (props) => {
    const {
        className = '',
        center = false,
        size = '',
        header = false,
        colored = false,
        responsiveSize = false,
        children,
    } = props;

    const style = classNames(s.row, className, {
        [s.center]: center,
        [s[`row-size-${size}`]]: !!size,
        [s[`row-size-${size}-header`]]: header && !!size,
        [s['responsive-size']]: !!responsiveSize,
        [s.header]: header,
        [s.colored]: colored,
    });
    return <div className={style}>{children}</div>;
};

export default Row;
