import { Button, ContextAlertMark, Input } from '@adtech/ui';
import fieldDecorator from '@decorators/fieldDecorator';
import { IField, IMetaField } from '@typings/form';
import React from 'react';

import s from './MultiInputData.pcss';

interface IProps {
    field: IField;
    meta: IMetaField;
    title: string;
    hint: string;
    button: string;
    placeholder: string;
    reset;
    onPressKey;
    onButtonClick;
    ignoreShowErrors: string[];
}

export const InputField = ({
    field,
    meta,
    reset,
    button,
    placeholder,
    onPressKey,
    onButtonClick,
    ignoreShowErrors,
}: IProps) => {
    const isIgnoreError = meta.invalid && ignoreShowErrors.includes(meta.errorType);
    const isError = meta.invalid && meta.touched && !meta.focused && !isIgnoreError;
    const value = field?.value as string;
    const { onBlur, onChange, onClick, onFocus } = field;

    return (
        <>
            <div className={s.inlineGroup}>
                <Input
                    value={value}
                    onBlur={onBlur}
                    onChange={onChange}
                    onClick={onClick}
                    onFocus={onFocus}
                    autoComplete="off"
                    className={s.input}
                    name="title"
                    type="text"
                    placeholder={placeholder}
                    onKeyPress={(e) => onPressKey(e, field, meta, reset)}
                    status={isError ? 'error' : null}
                />
                <Button onClick={() => onButtonClick(field, meta, reset)} disabled={meta.invalid} className={s.button}>
                    {button}
                </Button>
            </div>
            {isError && <ContextAlertMark status="error" message={meta.errorText || ''} />}
        </>
    );
};

export default fieldDecorator({})(InputField);
