import { Button, IconEdit, IconPlus, IconCopy, IconTrash, Select, Loader, DropDownList } from '@adtech/ui';
import FunnelEmptyPlaceholder from '@components/Funnel/components/FunnelEmptyPlaceholder';
import FunnelGraph from '@components/Funnel/components/FunnelGraph';
import FunnelTable from '@components/Funnel/components/FunnelTable';
import CreateOrEditFunnelModal from '@components/Funnel/modals/CreateOrEditFunnelModal';
import DeleteFunnelModal from '@components/Funnel/modals/DeleteFunnelModal';
import { columnsKeys, defaultTableColumns } from '@configs/funnels/defaultTableColumns';
import { metricsDict } from '@configs/metrics';
import useFunnels from '@hooks/useFunnels';
import IconDoc from '@images/svg/icons/icon-document.svg';
import IconExport from '@images/svg/icons/icon-export.svg';
import { IFunnelDTO } from '@typings/funnels';
import { IRootSlice } from '@typings/rootSlice';
import { arrayToCsvString, exportToCSV, exportToPNG } from '@utils/export';
import { storagesUtils, top100Counter } from '@utils/index';
import { isNumber } from '@utils/typesChecks';
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';

import s from './Funnel.pcss';

export enum EModalAction {
    create = 'create',
    edit = 'edit',
    copy = 'copy',
}

const Funnel: React.FC = () => {
    const params: IUrlParams = useParams();
    const projectId = Number(params.projectId);

    const storageKey = 'funnelLastOpened';

    const funnelList = useSelector((state: IRootSlice) => state.funnelsSlice.funnelList);
    const funnelData = useSelector((state: IRootSlice) => state.funnelsSlice.funnel);
    const funnelsListRequest = useSelector((state: IRootSlice) => state.funnelsSlice.funnelsListRequest);
    const funnelRequest = useSelector((state: IRootSlice) => state.funnelsSlice.funnelRequest);
    const tableData = useSelector((state: IRootSlice) => state.funnelsSlice.tableData);
    const metrics = useSelector((state: IRootSlice) => state.funnelsSlice.metrics);
    const allMetrics = useSelector((state: IRootSlice) => state.funnelsSlice.allMetrics);

    const [funnelValue, setFunnelValue] = useState<number>(
        parseInt(storagesUtils.get(storageKey), 10) || funnelList[0]?.id || null,
    );
    const [action, setAction] = useState(EModalAction.create);
    const [isCreateModalOpen, setIsCreateModalOpen] = useState(false);
    const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
    const [createModalData, setCreateModalData] = useState<IFunnelDTO>(null);
    const [isLegendActive, setLegendActive] = useState(false);

    const selectOptions = funnelList?.map((funnel) => ({ label: funnel.title, value: funnel.id })) || [];

    const { api } = useFunnels();
    const isDemoProject = api.isDemoProject();

    const openCreateModal = (actionParam: EModalAction = EModalAction.create) => {
        setAction(actionParam);
        setIsCreateModalOpen(true);

        switch (actionParam) {
            case EModalAction.edit:
                setCreateModalData({ ...funnelData });
                top100Counter.trackEvent('stat_funnel_edit_funnel_btn');
                break;
            case EModalAction.copy:
                setCreateModalData({ ...funnelData, title: `Копия_ ${funnelData.title}` });
                top100Counter.trackEvent('stat_funnel_copy_funnel_btn');
                break;
            default:
                setCreateModalData(null);
                top100Counter.trackEvent('stat_funnel_create_funnel_btn');
        }
    };
    const openDeleteModal = () => {
        setIsDeleteModalOpen(true);
        top100Counter.trackEvent('stat_funnel_open_delete_menu_btn');
    };
    const closeDeleteModal = () => {
        setIsDeleteModalOpen(false);
        top100Counter.trackEvent('stat_funnel_delete_menu_cancel_btn');
    };

    const createFunnelHandler = (data: IFunnelDTO) => {
        api.createFunnel(data);
        setIsCreateModalOpen(false);
    };
    const editFunnelHandler = (data: IFunnelDTO) => {
        api.editFunnel(data);
        setIsCreateModalOpen(false);
    };
    const deleteFunnelHandler = () => {
        if (isNumber(funnelValue)) api.deleteFunnel(funnelValue);
        setFunnelValue(null);
        setIsDeleteModalOpen(false);

        top100Counter.trackEvent('stat_funnel_delete_menu_submit_btn');
    };

    const exportPNGHandler = () => {
        const ignoreElements = (element: HTMLElement) =>
            element?.className === 'global-tint visible' ||
            element?.className.toString().includes('PageHead') ||
            element?.className.toString().includes('ContentWithSidebar__sidebar');

        exportToPNG(document.querySelector('*[class^="Layout__content"]'), `Воронка ${funnelData?.title || ''}`, {
            backgroundColor: '#e8ecf2',
            ignoreElements,
        });

        top100Counter.trackEvent('stat_funnel_download_png_btn');
    };
    const exportCSVHandler = () => {
        const csvTableHead = [
            defaultTableColumns[columnsKeys[0]],
            defaultTableColumns[columnsKeys[1]],
            ...metrics.map((value) => metricsDict[value]?.title || ''),
        ];
        const csvTableRows = tableData.map((row) => [
            row.stepNumber,
            row.stepTitle,
            ...(isLegendActive ? row.metricsWithNotPrevStep : row.metrics),
        ]);
        const csvStr = arrayToCsvString([csvTableHead, ...csvTableRows]);

        exportToCSV(csvStr, `Воронка ${funnelData?.title || ''}`);

        top100Counter.trackEvent('stat_funnel_download_csv_btn');
    };

    // Настройки поиска по label
    const filterOption = (input: string, option?: { label: string; value: string }) =>
        (option?.label ?? '').toLowerCase().includes(input.toLowerCase());

    const selectFunnel = (value: number | null) => {
        if (value === null || value === funnelValue) return;

        setFunnelValue(value);
        api.updateParams({ currentFunnelId: value });
        api.getFunnel(value);
        api.getFunnelTable(value);
        storagesUtils.set(storageKey, value);
    };
    const changeFunnelHandler = (value: number | null) => {
        selectFunnel(value);
        top100Counter.trackEvent('stat_funnel_chose_funnel_item');
    };

    // При переключении проекта сбрасываем текущие сохраненные значения воронок
    useEffect(() => {
        setFunnelValue(null);
        api.updateParams({ funnelList: [], currentFunnelId: null });
    }, [projectId]);

    // Загружаем последнюю открытую воронку, если таковой не было - последнюю созданную/редактир.
    useEffect(() => {
        if (!funnelList?.length) {
            setFunnelValue(null);
            return;
        }

        const storageValue = parseInt(storagesUtils.get(storageKey), 10);
        const isStorageValueExists = funnelList.find((item) => item.id === storageValue);
        selectFunnel((isStorageValueExists && storageValue) || funnelList[0]?.id || null);
    }, [JSON.stringify(funnelList)]);

    return (
        <div>
            <Loader loading={funnelsListRequest}>
                <div className={s.funnels}>
                    <Loader loading={funnelRequest}>
                        <div className={s.funnelsTools}>
                            <Button
                                prefixIcon={<IconPlus />}
                                onClick={() => openCreateModal()}
                                disabled={isDemoProject}
                                data-testid="add-button"
                            >
                                Создать воронку
                            </Button>
                            <Select
                                wrapperClassName={s.funnelsSelect}
                                value={funnelValue}
                                options={selectOptions}
                                size="middle"
                                onChange={changeFunnelHandler}
                                filterOption={filterOption}
                                placeholder="Выберите воронку из списка"
                                showSearch
                                onDropdownVisibleChange={(open) =>
                                    open && top100Counter.trackEvent('stat_funnel_open_funnels_list_btn')
                                }
                                data-testid="search-container"
                            />
                            <Button
                                prefixIcon={<IconEdit color="currentColor" />}
                                type="dashed"
                                onClick={() => openCreateModal(EModalAction.edit)}
                                disabled={!funnelValue || isDemoProject}
                                data-testid="edit-button"
                            >
                                Редактировать
                            </Button>
                            <Button
                                icon={<IconCopy color="currentColor" />}
                                type="dashed"
                                onClick={() => openCreateModal(EModalAction.copy)}
                                disabled={!funnelValue || isDemoProject}
                                data-testid="copy-button"
                            />
                            <DropDownList
                                menuItems={[
                                    {
                                        dropDownItemProps: {
                                            icon: <IconDoc />,
                                            text: 'Скачать отчёт в PNG',
                                            onClick: exportPNGHandler,
                                        },
                                    },
                                    {
                                        dropDownItemProps: {
                                            icon: <IconDoc />,
                                            text: 'Скачать отчёт в CSV',
                                            onClick: exportCSVHandler,
                                        },
                                    },
                                ]}
                                customButton={
                                    <Button icon={<IconExport />} type="dashed" data-testid="download-button" />
                                }
                                disabled={!funnelValue}
                                onOpenChange={(open) =>
                                    open && top100Counter.trackEvent('stat_funnel_download_all_btn')
                                }
                            />
                            <Button
                                icon={<IconTrash />}
                                type="dashed"
                                onClick={openDeleteModal}
                                disabled={!funnelValue || isDemoProject}
                                data-testid="delete-button"
                            />
                        </div>
                    </Loader>
                    <div className={s.funnelsContent}>
                        {funnelValue ? (
                            <>
                                <FunnelGraph
                                    data={tableData}
                                    isLegendActive={isLegendActive}
                                    setLegendActive={setLegendActive}
                                />
                                <FunnelTable
                                    data={tableData}
                                    metrics={metrics}
                                    allMetrics={allMetrics}
                                    isLegendActive={isLegendActive}
                                />
                            </>
                        ) : (
                            <FunnelEmptyPlaceholder
                                isFunnelsExist={!!funnelList?.length}
                                onBtnClick={isDemoProject ? () => {} : () => openCreateModal()}
                                btnText="Создать воронку"
                                isCreateModal
                            />
                        )}
                    </div>
                    <CreateOrEditFunnelModal
                        data={createModalData}
                        isOpen={isCreateModalOpen}
                        action={action}
                        onOkHandler={action === EModalAction.edit ? editFunnelHandler : createFunnelHandler}
                        onCloseHandler={() => setIsCreateModalOpen(false)}
                    />
                    <DeleteFunnelModal
                        isOpen={isDeleteModalOpen}
                        onOkHandler={deleteFunnelHandler}
                        onCloseHandler={closeDeleteModal}
                    />
                </div>
            </Loader>
        </div>
    );
};

export default Funnel;
