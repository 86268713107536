import { createAsyncThunk } from '@reduxjs/toolkit';
import { ISaveFilterRequestParams } from '@typings/filters';
import fetchApi from '@utils/fetchApi';

export const getServerTime = createAsyncThunk(
    'globalSlice/getServerTime',
    async () =>
        // eslint-disable-next-line no-return-await
        await fetchApi({ url: '/api/report/v1.0/service/time' }),
);

export const getRegionsConfig = createAsyncThunk(
    'globalSlice/getRegionsConfig',
    async () =>
        // eslint-disable-next-line no-return-await
        await fetchApi({ url: '/api/v1.0/region' }),
);

export const getTypesConfig = createAsyncThunk(
    'globalSlice/getTypesConfig',
    async () =>
        // eslint-disable-next-line no-return-await
        await fetchApi({ url: '/api/v1.0/type' }),
);

export const getThemesConfig = createAsyncThunk(
    'globalSlice/getThemesConfig',
    async () =>
        // eslint-disable-next-line no-return-await
        await fetchApi({ url: '/api/v1.0/category' }),
);

// Получение шаблонов сохраненных фильтров
export const getSavedFilters = createAsyncThunk(
    'globalSlice/getSavedFilters',
    async (projectId: string) =>
        // eslint-disable-next-line no-return-await
        await fetchApi({
            url: `/api/v1.0/project/${projectId}/saved_filters`,
            metaInfo: { projectId },
        }),
);

// Сохранение шаблона фильтров
export const saveFilter = createAsyncThunk(
    'globalSlice/saveFilter',
    async ({ projectId, filterParams }: { projectId: string; filterParams: ISaveFilterRequestParams }) =>
        // eslint-disable-next-line no-return-await
        await fetchApi({
            url: `/api/v1.0/project/${projectId}/saved_filters`,
            method: 'POST',
            body: filterParams,
            metaInfo: {
                ...filterParams,
                projectId,
            },
        }),
);

// Удаление сохраненного шаблона фильтров
export const deleteSavedFilter = createAsyncThunk(
    'globalSlice/deleteSavedFilter',
    async ({ projectId, id }: { projectId: string; id: number }) =>
        // eslint-disable-next-line no-return-await
        await fetchApi({
            url: `/api/v1.0/project/${projectId}/saved_filters/${id}`,
            method: 'DELETE',
            metaInfo: {
                projectId,
                id,
            },
        }),
);

// Обновление сохраненного шаблона фильтров
export const updateSavedFilter = createAsyncThunk(
    'globalSlice/updateSavedFilter',
    async ({
        projectId,
        id,
        filterParams,
    }: {
        projectId: string;
        id: number;
        filterParams: ISaveFilterRequestParams;
    }) =>
        // eslint-disable-next-line no-return-await
        await fetchApi({
            url: `/api/v1.0/project/${projectId}/saved_filters/${id}`,
            method: 'PUT',
            body: filterParams,
            metaInfo: {
                projectId,
                id,
            },
        }),
);

// Получение данных для динамических фильтров
export const getDynamicFilters = createAsyncThunk(
    'globalSlice/getDynamicFilters',
    async ({ projectId, filterName, params }: { projectId: number | string; filterName: string; params: any }) =>
        // eslint-disable-next-line no-return-await
        await fetchApi({
            url: `/api/report/v1.0/eventbase/${projectId}/filter/${filterName}`,
            method: 'POST',
            body: { ...params },
            metaInfo: {
                filterName,
                params,
            },
        }),
);
