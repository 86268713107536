import React from 'react';
import s from '../ModalPopup.pcss';
import { PopupContent } from '../components/PopupContent';

export default {
    getContent(modalPopupParams: Record<string, () => void>) {
        const popupContent = (
            <PopupContent
                className={s.content}
                content="После удаления счётчика статистика будет недоступна.
                    Счётчик можно восстановить из раздела «Удаленные»"
            />
        );

        const okButton = {
            type: 'default',
            children: 'Удалить',
            onClick: modalPopupParams.okButtonHandler,
            closePopupOnClick: true,
        };

        const cancelButton = {
            type: 'dashed',
            children: 'Отмена',
            closePopupOnClick: true,
        };

        return {
            popupWidth: 'md',
            title: 'Удалить счётчик',
            content: popupContent,
            buttonContainerClassName: s.buttons,
            okButton,
            cancelButton,
        };
    },
};
