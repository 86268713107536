import React from 'react';
import s from './AccessUsers.pcss';
import { AddedUsersField } from './AddedUsersField';
import { NewUsersField } from './NewUsersField';

interface IProps {
    getUserExists: (email: string) => Promise<{ payload: { exists: boolean } }>;
    currentEmail: string;
}

const AccessUsers: React.FC<IProps> = ({ getUserExists, currentEmail }) => (
    <>
        <section className={s.section}>
            <div className={s.sectionTitle}>Разрешить доступ пользователям</div>
            <NewUsersField getUserExists={getUserExists} />
        </section>
        <section className={s.section}>
            <div className={s.sectionTitle}>Добавленные пользователи</div>
            <AddedUsersField currentEmail={currentEmail} />
        </section>
    </>
);

export default AccessUsers;
