export const isBoolean = (val: unknown): val is boolean => typeof val === 'boolean';

export const isFunction = (val: unknown): val is TCallback => typeof val === 'function';

export const isString = (val: unknown): val is string => typeof val === 'string';

export const isNumber = (val: unknown): val is number => typeof val === 'number';

export const isObject = (val: unknown): val is TDict => {
    const type = typeof val;
    return (type === 'object' && val !== null) || type === 'function';
};

export const isArray = (val: unknown): val is Array<unknown> => Array.isArray(val);

export const isArrayLike = (val: unknown): val is ArrayLike<unknown> =>
    isArray(val) || (isObject(val) && isNumber(val.length));

export const isNotExist = (val: unknown): val is undefined => typeof val === 'undefined';

export const isExist = (val: unknown) => !isNotExist(val);
