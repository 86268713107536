import { IconTrash, IconDragItem, Input, IconCirclePlus, Button, Chip, Alert } from '@adtech/ui';
import { useSortable } from '@dnd-kit/sortable';
import { CSS } from '@dnd-kit/utilities';
import { IFunnelStep } from '@typings/funnels';
import React, { ChangeEventHandler, useEffect, useState } from 'react';

import s from './FunnelStep.pcss';

interface IProps {
    data: IFunnelStep;
    id: number;
    index: number;
    deleteStepHandler: (index: number) => void;
    setStepTitle: (title: string, index: number) => void;
    chipCloseHandler: (chipIndex: number, stepIndex: number) => void;
    conditionsClickHandler: (index: number) => void;
    validationError: string;
}

const FunnelStep: React.FC<IProps> = ({
    data,
    id,
    index,
    deleteStepHandler,
    setStepTitle,
    chipCloseHandler,
    conditionsClickHandler,
    validationError,
}) => {
    const [inputTitle, setInputTitle] = useState(data?.step_title || '');
    const [titleStatus, setTitleStatus] = useState('');
    const [goalsStatus, setGoalsStatus] = useState(null);

    const { attributes, listeners, setNodeRef, transform, transition, isDragging } = useSortable({ id });

    // Стили сортируемых элементов
    const style = {
        transform: CSS.Transform.toString(transform),
        transition,
        zIndex: isDragging ? 101 : 100,
    };

    useEffect(() => {
        setInputTitle(data?.step_title || '');
    }, [data?.step_title]);

    useEffect(() => {
        if (validationError) {
            setTitleStatus(data?.step_title ? '' : 'error');
            setGoalsStatus(data?.goals?.length ? null : 'error');
        } else {
            setTitleStatus('');
            setGoalsStatus(null);
        }
    }, [validationError]);

    const titleChangeHandler: ChangeEventHandler<HTMLInputElement> = (e) => {
        const value = e.target.value;

        setInputTitle(value);

        if (setStepTitle) setStepTitle(value, index);
    };

    const deleteStep = () => {
        if (deleteStepHandler) deleteStepHandler(index);
    };

    return (
        <div className={s.funnelStep} key={id} data-testid="step-container" ref={setNodeRef} style={style}>
            <div className={s.funnelStepHead}>
                <div className={s.funnelStepIndex} data-testid="title-text">
                    Шаг {index + 1}
                </div>
                <div className={s.funnelStepBtn} onClick={deleteStep} data-testid="delete-icon">
                    <IconTrash color="currentColor" />
                </div>
                <div className={s.funnelStepBtn} data-testid="drag-icon" {...attributes} {...listeners}>
                    <IconDragItem color="currentColor" />
                </div>
            </div>
            <Input
                className={s.funnelStepTitle}
                value={inputTitle}
                status={titleStatus as any}
                placeholder="Придумайте название шага"
                errorText="Заполните поле"
                onChange={titleChangeHandler}
                maxLength={255}
                showCount
                allowClear
                data-testid="step-input"
            />
            <div className={s.funnelStepInner}>
                <Button
                    className={s.funnelStepConditionsBtn}
                    prefixIcon={<IconCirclePlus color="currentColor" />}
                    type="link"
                    onClick={() => conditionsClickHandler(index)}
                    data-testid="addGoal-button"
                >
                    Условия
                </Button>
                <div className={s.funnelStepConditions}>
                    {data?.goals?.map((goal, i) => (
                        <>
                            {i > 0 ? <div className={s.funnelStepOrText}>или</div> : ''}
                            <Chip
                                key={goal?.goal_id}
                                onClose={() => chipCloseHandler(i, index)}
                                data-testid="chip-button"
                            >
                                {goal?.goal_title}
                            </Chip>
                        </>
                    ))}
                </div>
            </div>
            {goalsStatus ? (
                <Alert className={s.funnelStepAlert} message="Добавьте хотя бы 1 условие" type="warning" showIcon />
            ) : null}
        </div>
    );
};

export default FunnelStep;
