interface IFetchApi {
    url: string;
    method?: string;
    credentials?: RequestCredentials;
    headers?: HeadersInit;
    signal?: AbortSignal | null;
    body?: Record<string, any>;
    metaInfo?: Record<string, any>;
}

async function fetchApi({ url, method = 'GET', credentials = 'same-origin', headers, body, metaInfo }: IFetchApi) {
    const defaultHeaders = {
        Accept: 'application/json', // eslint-disable-line quote-props
        'Content-Type': 'application/json',
    };

    const options = {
        method,
        credentials,
        headers: headers || defaultHeaders,
        ...(body ? { body: JSON.stringify(body) } : {}),
    };

    const response = await fetch(url, options);
    const responseStatus = response.status;

    if (responseStatus === 204) return responseStatus;

    const result = await response.json();

    return metaInfo ? { ...result, metaInfo, responseStatus } : { ...result, responseStatus };
}

export default fetchApi;
