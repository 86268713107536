/* eslint-disable max-len */
import { Checkbox, Input, Alert } from '@adtech/ui';
import CounterBlocks from '@components/CounterBlocks';
import { Hint } from '@components/Hint';
import fieldDecorator from '@decorators/fieldDecorator';
import React from 'react';

import s from './SettingsOther.pcss';

interface ICheckboxProps {
    field: any;
    name: string;
    title: string;
    hint: string;
    docLink: string;
    description: string;
}

interface IInputProps {
    meta: any;
    field: any;
}

export const InputComponent = ({ meta, field }: IInputProps) => (
    <div className={s.input}>
        <div className={s.inputLabel}>Имя дата-атрибута:</div>
        <Input
            {...field}
            type="text"
            status={meta.invalid && meta.touched ? 'error' : null}
            errorText={meta.invalid && meta.touched ? meta.errorText : ''}
        />
    </div>
);

const BlockAnalyticsAttributeField = fieldDecorator({
    name: 'blockAnalyticsAttribute',
})(InputComponent);

export const CheckboxComponent = ({ field, name, title, hint, docLink, description }: ICheckboxProps) => (
    <div className={s.checkboxesItem}>
        <div className={s.checkboxesItemFirst}>
            <Checkbox
                className={s.checkboxesItemCheckbox}
                checked={field.value}
                onChange={() => field.onChange(null, !field.value)}
            >
                {title}
                <Hint>{hint}</Hint>
            </Checkbox>
            <a href={docLink} className={s.checkboxesItemLink} target="_blank" rel="noopener">
                Подробнее
            </a>
        </div>
        <div className={s.checkboxesItemSecond}>
            {field.value && description ? <Alert type="info" message={description} className={s.alert} /> : null}
            {name === 'useBlockAnalytics' && field.value ? <BlockAnalyticsAttributeField /> : null}
        </div>
    </div>
);

const UseBlockAnalyticsField = fieldDecorator({
    type: 'boolean',
    title: 'Аналитика блоков страниц',
    description: 'Для сбора данных по кликабельности блоков необходимо разметить их в коде страницы дата-атрибутами',
    name: 'useBlockAnalytics',
    hint: 'Разметка блоков data-атрибутами позволяет получить подробную статистику по кликабельности элементов страниц сайта. Данные доступны в отчёте «Аналитика блоков».',
    docLink: 'https://ads.sber.ru/help/podklyuchenie-i-nastroika-analitiki-blokov',
})(CheckboxComponent);

const UseHashesField = fieldDecorator({
    type: 'boolean',
    title: 'Учёт хэшей в страницах',
    name: 'useHashes',
    hint: 'Настройка для SPA-приложений, использующих hash параметры в качестве идентификаторов страниц. Hash параметры в URL будут учитываться как отдельные страницы, если на странице нет элемента с идентичным id. Иначе параметр будет интерпретирован как якорная ссылка.',
    docLink: 'https://ads.sber.ru/help/uchet-kheshei',
})(CheckboxComponent);

// prettier-ignore
const UseUserIdField = fieldDecorator({
    type: 'boolean',
    title: 'Учёт пользователей (user-id)',
    name: 'useUserId',
    hint: 'Для учёта авторизованных пользователей необходимо передавать параметр \'user_id\' при инициализации счётчика и впоследствии методом top100Counter.syncUserId(). Если при загрузке страницы со счётчиком пользователь не авторизован, необходимо указать \'user_id: null\'.',
    docLink: 'https://ads.sber.ru/help/nastroika-schyotchika',
})(CheckboxComponent);

export default () => (
    <CounterBlocks.Wrapper
        title="Расширенные возможности"
        rootClassName={s.otherRoot}
        withoutDelimiter
        smallTitle
        normalTitle
    >
        <div className={s.checkboxes}>
            <UseBlockAnalyticsField />
            <UseHashesField />
            <UseUserIdField />
        </div>
    </CounterBlocks.Wrapper>
);
