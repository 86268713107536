import { Button } from '@adtech/ui';
import Page from '@components/Page';
import loadProjectConfigs from '@decorators/loadProjectConfigs';
import { compose } from '@decorators/tools';
import { formsActions } from '@redux/slices/forms';
import { globalActions } from '@redux/slices/global';
import { projectsActions } from '@redux/slices/projects';
import React from 'react';
import DocumentTitle from 'react-document-title';
import { connect } from 'react-redux';
import s from './CreateCounter.pcss';
import FirstStep from './FirstStep';
import RestoreCounterStep from './RestoreCounterStep';
import SecondStep from './SecondStep';
import { Header } from './components/Header';

interface IProps {
    removeForm: (data: string[]) => void;
    clearCreatedCounter: () => void;
    counterId?: number;
    projectsWithSameUrlExist: () => void;
    globalLoading: boolean;
    setGlobalLoading: (param: boolean) => void;
}

interface IState {
    step: number;
    isMounted: boolean;
}

export class CreateCounter extends React.PureComponent<IProps, IState> {
    state = {
        step: 1,
        isMounted: false,
    };

    componentDidMount() {
        const { removeForm, clearCreatedCounter, globalLoading, setGlobalLoading } = this.props;
        Promise.all([
            // перед началом работы, очищаем все формы
            removeForm(['counterFirstStep', 'counterSecondStep']),
            // и прошлый созданный счётчик
            clearCreatedCounter(),
        ]).then(() => {
            this.setState({ isMounted: true });
        });

        if (globalLoading) {
            setGlobalLoading(false);
        }
    }

    getPageTitle = () => {
        const { step } = this.state;
        if (step === 3) return 'Восстановление доступа к счётчику';

        const { counterId } = this.props;
        const counter = counterId ? `${counterId} ` : '';

        return `Создание нового счётчика ${counter}- Статистика от SberAds`;
    };

    nextStep = (step: number) => () => {
        window.scrollTo({ top: 0 });
        this.setState({ step });
    };

    render() {
        const { step, isMounted } = this.state;
        const { counterId, projectsWithSameUrlExist } = this.props;

        if (!isMounted) return null;

        return (
            <DocumentTitle title={this.getPageTitle()}>
                <Page rootClass={s.createCounterRoot} rowClass={s.createCounterRow}>
                    {__DEV__ && (
                        <div style={{ position: 'absolute', right: '100px' }}>
                            <Button style={{ marginRight: '10px', display: 'inline-block' }} onClick={this.nextStep(1)}>
                                1
                            </Button>
                            <Button style={{ marginRight: '10px', display: 'inline-block' }} onClick={this.nextStep(2)}>
                                2
                            </Button>
                            <Button style={{ marginRight: '10px', display: 'inline-block' }} onClick={this.nextStep(3)}>
                                3
                            </Button>
                        </div>
                    )}

                    <Header step={step} counterId={counterId} />

                    <div className={s.content}>
                        {step === 1 && (
                            <FirstStep
                                nextStep={this.nextStep(2)}
                                goToRestoreCounter={this.nextStep(3)}
                                projectsWithSameUrlExist={projectsWithSameUrlExist}
                            />
                        )}
                        {step === 2 && <SecondStep />}
                        {step === 3 && <RestoreCounterStep goToCreateCounter={this.nextStep(1)} />}
                    </div>
                </Page>
            </DocumentTitle>
        );
    }
}

const mapStateToProps = ({
    projectsSlice: { createdCounter, projectsWithSameUrl },
    globalSlice: { globalLoading },
}) => ({
    counterId: createdCounter ? createdCounter.project_id : null,
    projectsWithSameUrlExist: projectsWithSameUrl && projectsWithSameUrl.total,
    globalLoading,
});

const mapDispatchToProps = (dispatch) => ({
    removeForm: (forms: string[]) => dispatch(formsActions.removeForm(forms)),
    clearCreatedCounter: () => dispatch(projectsActions.clearCreatedCounter()),
    setGlobalLoading(param) {
        dispatch(globalActions.setGlobalLoading(param));
    },
});

export default compose(loadProjectConfigs, connect(mapStateToProps, mapDispatchToProps))(CreateCounter);
