import { attributionModelData } from '@components/AttributionModel';
import { attributionWindowData } from '@components/AttributionWindow';
import { EConversionTitles, EConversionWidgetNames, widgetsDefaultDimensions } from '@configs/conversions';
import conversionConfig from '@configs/conversions/conversion';
import { conversionMetricsList } from '@configs/conversions/conversionForm';
import { EntityAccess, EntityActions, IEntityAction } from '@configs/entity';
import { ChartTypes } from '@configs/graph';
import { LatencyKeys } from '@constants/latency';
import reportsConstants from '@constants/reports';
import userRoles from '@constants/userRoles';
import useGlobalParams from '@hooks/useGlobalParams';
import useGoals from '@hooks/useGoals';
import useQuery from '@hooks/useQuery';
import useSnackbar from '@hooks/useSnackbar';
import { useAppDispatch } from '@hooks/useStore';
import { useTable } from '@hooks/useTable';
import { conversionsActions } from '@redux/slices/conversions';
import { reportsActions } from '@redux/slices/reports';
import {
    IConversionData,
    IConversionDictData,
    IConversionRequestData,
    IConversionRequestTableData,
    IConversionTableData,
    IConversionTableParams,
    IConversionWidgetRequestData,
} from '@typings/conversions';
import { IUpdateParams } from '@typings/dashboard';
import { DateLiteral } from '@typings/date';
import { IGoalsTableData } from '@typings/goals';
import { TChartDataItem } from '@typings/graph';
import { Metrics } from '@typings/metrics';
import { IQuery } from '@typings/query';
import { IUpdateReportOptions, IUpdateReportParams, ReportNameBase } from '@typings/reports';
import { IRootSlice } from '@typings/rootSlice';
import { IUpdateWidgetParams } from '@typings/widgets';
import DateUtils from '@utils/date';
import { urlUtils } from '@utils/index';
import reportsUtilsFunc from '@utils/reports';
import { useRef } from 'react';
import { useSelector } from 'react-redux';
import { useParams, useLocation } from 'react-router-dom';

const useConversions = () => {
    const params: IUrlParams = useParams();
    const projectId = params.projectId;
    const isOneConversionReport = !!params.conversionId;
    const conversionId = Number(params.conversionId);
    const location = useLocation();
    const dispatch = useAppDispatch();
    const { search } = location;
    const query: IQuery = urlUtils.parseQuery(search);

    const tableData = useSelector((state: IRootSlice) => state.conversionsSlice.tableData);
    const conversionWidgets = useSelector((state: IRootSlice) => state.conversionsSlice.widgets);
    const trafficSourcesDimension = useSelector(
        (state: IRootSlice) => state.conversionsSlice.widgets?.traffic_sources?.selected,
    );
    const audienceDimension =
        useSelector((state: IRootSlice) => state.conversionsSlice.widgets?.audience?.selected) ||
        widgetsDefaultDimensions.audience;
    const technologyDimension =
        useSelector((state: IRootSlice) => state.conversionsSlice.widgets?.technology?.selected) ||
        widgetsDefaultDimensions.technology;
    const attributionModel = useSelector((state: IRootSlice) => state.conversionsSlice.attributionModel);
    const attributionWindow = useSelector((state: IRootSlice) => state.conversionsSlice.attributionWindow);
    const numeratorsFilters = useSelector((state: IRootSlice) => state.conversionsSlice.numeratorsFilters) || [];
    const denominatorsFilters = useSelector((state: IRootSlice) => state.conversionsSlice.denominatorsFilters) || [];
    const showCommonConversions = useSelector((state: IRootSlice) => state.conversionsSlice.showCommonConversions);
    const limit = useSelector((state: IRootSlice) => state.conversionsSlice.limit) ?? conversionConfig.defaultLimit;
    const tableOffset = useSelector((state: IRootSlice) => state.conversionsSlice.offset) ?? 0;
    const tableSort = useSelector((state: IRootSlice) => state.conversionsSlice.sort) ?? conversionConfig.defaultSort;
    const tableDimensions = useSelector((state: IRootSlice) => state.conversionsSlice.dimensions);

    const latencySlice = useSelector((state: IRootSlice) => state.latencySlice);
    const userEmail = useSelector((state: IRootSlice) => state.userSlice.email);
    const userRole = useSelector((state: IRootSlice) => state.userSlice.roleId);

    const dateStart = useSelector((state: IRootSlice) => state.globalSlice.dateStart);
    const dateEnd = useSelector((state: IRootSlice) => state.globalSlice.dateEnd);
    const groupBy = useSelector((state: IRootSlice) => state.globalSlice.groupBy);

    const tableSlice = useSelector((state: IRootSlice) => state.tableSlice);
    const sample = useSelector((state: IRootSlice) => state.tableSlice.sample) ?? conversionConfig.sampleDefault;
    const searchWord = useSelector((state: IRootSlice) => state.tableSlice.titleFilter);

    const reportsUtils = reportsUtilsFunc({
        reportsConfig: conversionConfig,
    });

    const { getTable: getGoalsTable, getGoalsByIds } = useGoals();
    const { setSnackbar } = useSnackbar();
    const { updateGlobalParams } = useGlobalParams();
    const {
        api: { updateTableParams },
    } = useTable();

    const goalsList = useRef<IGoalsTableData[]>();

    const { getParamsFromQuery, checkQueryParams } = useQuery();

    const api = {
        // Метод инициализации для страницы со списком конверсий
        initConversionsSummary(reqParams?: IUpdateReportParams & { showCommonConversions?: boolean }): Promise<void> {
            let checkedParams = api.getParams();
            checkedParams = { ...checkedParams, ...reqParams };
            updateGlobalParams(checkedParams);
            updateTableParams(checkedParams);

            api.updateParams({ showCommonConversions: !!reqParams?.showCommonConversions });

            // Не ставим ограничение по isExcludedLatency,
            // потому что нам нужно получить список в любом случае
            // и всегда запрашиваем цели при инициализации
            api.getTable(checkedParams);

            return Promise.resolve();
        },

        // Метод инициализации для страницы просмотра одной конверсии
        initConversion(): Promise<void> {
            const checkedParams = api.getParams();

            updateGlobalParams(checkedParams);

            if (latencySlice[LatencyKeys.base]?.isExcludedLatency === false) {
                api.getConversion(conversionId, checkedParams);
            }

            return Promise.resolve();
        },

        // Метод получения параметров для запроса за конверсиями
        getParams(): IUpdateParams {
            const defaultParams = {
                groupBy,
                dateStart,
                dateEnd,
                sample,
            };

            const queryParams = getParamsFromQuery(query);

            return checkQueryParams(
                queryParams,
                defaultParams,
                {
                    ...tableSlice,
                },
                () => {},
            );
        },

        // Метод обновления данных конверсий (при изменении детализации, дат ...)
        updateReport(
            type: string, // all, table... - что нужно обновить, опциональный параметр
            updateParams: IUpdateReportOptions<IUpdateReportParams, ReportNameBase>,
        ): void {
            const { updateParams: updParams, options } = updateParams;
            const { withoutCompare } = options || {};

            updateTableParams(updParams, withoutCompare);
            updateGlobalParams(updParams, withoutCompare);

            // Так как модель и окно атрибуции находятся в reportTools (костыль ui),
            // то обновляют весь отчет, а нам нужно обновить только виджет источников и таблицу
            if (updParams.attributionModel || updParams.attributionWindow) {
                api.updateParams(updParams);
                api.getConversionWidgetData(EConversionWidgetNames.trafficSources, {
                    attribution_model: updParams.attributionModel,
                    window: Number(updParams.attributionWindow),
                });
                api.getConversionTable({
                    attribution_model: updParams.attributionModel,
                    window: Number(updParams.attributionWindow),
                });
                return;
            }

            if (isOneConversionReport) {
                api.getConversion(conversionId, updParams);
            } else {
                api.getConversionsData(tableData, updParams);
            }
        },

        // Метод для получения таблицы/списка конверсий
        getTable(reqParams?: IUpdateReportParams & { showCommonConversions?: boolean }): void {
            dispatch(
                conversionsActions.getTable({
                    projectId,
                    params: { titleFilter: searchWord, showCommonConversions, ...reqParams },
                }),
            )
                .unwrap()
                .then((resp) => {
                    api.getConversionsData(resp?.data?.conversions, reqParams, true);
                });
        },

        // Метод подгрузки данных для таблицы/списка конверсий
        getTableOffset(offset: number = 0): void {
            dispatch(
                conversionsActions.updateTable({
                    projectId,
                    offset,
                    searchWord,
                    showCommonConversions,
                }),
            )
                .unwrap()
                .then((resp) => {
                    api.getConversionsData(resp?.data?.conversions, null, true);
                });
        },

        // Обновление списка конверсий, например, при поиске
        updateTable(updateParams: IUpdateReportParams & { showCommonConversions?: boolean }): void {
            updateTableParams(updateParams);
            api.getTable(updateParams);
        },

        // Получение таблицы на отчете одной конверсии
        getConversionTable(reqParams?: IConversionTableParams): void {
            const finalDimensions = reqParams?.dimensions || tableDimensions || conversionConfig.tableFixedDimensions;

            const requestParams = {
                date_start: dateStart,
                date_end: dateEnd,
                group: groupBy,
                sample,
                limit,
                offset: tableOffset,
                sort: tableSort,
                numerator: numeratorsFilters[0],
                metrics: conversionConfig?.tableDefaultMetrics,
                // Расширяем входными параметрами
                ...reqParams,
                dimensions: finalDimensions,
                window: Number(reqParams?.window || attributionWindow || attributionWindowData[0].value),
                attribution_model: reqParams?.attribution_model || attributionModel || attributionModelData[0].value,
            } as IConversionRequestTableData;

            api.updateParams({
                limit: reqParams?.limit ?? limit,
                offset: reqParams?.offset ?? tableOffset,
                sort: reqParams?.sort || tableSort,
                dimensions: finalDimensions,
            });

            dispatch(
                conversionsActions.getConversionTable({
                    projectId,
                    params: { ...requestParams },
                }),
            );
        },

        createConversion(data: IConversionData, isCalledFromGoalsReport?: boolean): void {
            dispatch(conversionsActions.createConversion({ data, userEmail }))
                .unwrap()
                .then((res) => {
                    if (!res || res.responseStatus !== 200) return;
                    // eslint-disable-next-line max-len
                    setSnackbar(
                        `Конверсия "${data.title}" создана. <a href="${api.getLinkToConversion(
                            res?.id,
                        )}">Перейти к конверсии</a>`,
                    );

                    if (isCalledFromGoalsReport) {
                        getGoalsTable();
                    } else {
                        api.updateParams({ numeratorsFilters: [] });
                        api.updateParams({ denominatorsFilters: [] });
                        api.getConversionsData([{ id: res.id, ...data }], null, true);
                    }
                });
        },

        updateConversion(id: number, data: Partial<IConversionData>, withoutUpdateGraphData?: boolean): void {
            dispatch(conversionsActions.updateConversion({ id, data, isOneConversionReport }))
                .unwrap()
                .then((res) => {
                    if (!res || res.responseStatus !== 200 || withoutUpdateGraphData) return;
                    setSnackbar('Конверсия изменена');

                    // Сбрасываем сохраненные тела целей (фильтры)
                    api.updateParams({ numeratorsFilters: [] });
                    api.updateParams({ denominatorsFilters: [] });

                    api.getConversionsData([{ id, ...data }], null, true);
                });
        },

        deleteConversion(id: number): void {
            dispatch(conversionsActions.deleteConversion(id))
                .unwrap()
                .then((res) => {
                    if (!res || res.responseStatus !== 200) return;
                    setSnackbar('Конверсия удалена');
                });
        },

        // Получаем краткие данные (id целей) одной конверсии
        // Далее запрашиваем данные для графиков по этой конверсии
        getConversion(id: number, updateParams?: IUpdateReportParams): void {
            dispatch(conversionsActions.getConversion(id))
                .unwrap()
                .then((res) => {
                    if (!res || res.responseStatus !== 200) return;
                    api.getConversionsData([res.data], updateParams);
                });
        },

        // Метод получения данных для отображения конверсий (набор точек + тоталсы).
        // Запрашиваем цели для списка конверсий, получаем тело целей (фильтры),
        // используем в ручке получения данных конверсии (там старая api, не принимающая id целей)
        getConversionsData(
            conversions: Partial<IConversionTableData>[],
            updateParams?: IUpdateReportParams,
            isForceGoalsRequest?: boolean,
        ): void {
            if (!conversions) return;

            api.getGoalsForConversions(conversions, isForceGoalsRequest).then((res) => {
                const goals = res?.data || [];
                let numerator = {};
                let denominator = {};
                const numeratorArr = [...numeratorsFilters];
                const denominatorArr = [...denominatorsFilters];
                const requestParams = {
                    date_start: updateParams?.dateStart || dateStart,
                    date_end: updateParams?.dateEnd || dateEnd,
                    group: updateParams?.groupBy || groupBy,
                    sample: updateParams?.sample || sample,
                } as IConversionRequestData;

                goalsList.current = [...(goalsList.current || []), ...goals];

                conversions.forEach(({ id, numerator_goal_id, denominator_goal_id, metric }, index) => {
                    if (goals.length) {
                        denominatorArr[index] = { metric };

                        goals.forEach((goal) => {
                            if (goal.id === numerator_goal_id) {
                                numerator = goal.body;
                                numeratorArr[index] = { ...numerator, metric };
                            }
                            if (goal.id === denominator_goal_id) {
                                denominator = goal.body;
                                denominatorArr[index] = { ...denominator, metric };
                            }
                        });

                        // Сохраняем полученные данные по числителю, знаменателю для оптимизации
                        // Перезапрашивать цели имеет смысл только при изменении самой конверсии
                        api.updateParams({ numeratorsFilters: [...numeratorArr] });
                        api.updateParams({ denominatorsFilters: [...denominatorArr] });
                    }

                    api.getConversionData(
                        {
                            ...requestParams,
                            conversions: [
                                {
                                    id,
                                    numerator: numeratorArr[index] || { ...numerator, metric },
                                    denominator: denominatorArr[index] || { ...denominator, metric },
                                },
                            ],
                        },
                        id,
                    );

                    // Если отчет списка конверсий - не запрашиваем виджеты
                    if (!isOneConversionReport) return;

                    const widgetParams = {
                        ...requestParams,
                        numerator: numeratorArr[index] || { ...numerator, metric },
                        denominator: denominatorArr[index] || { ...denominator, metric },
                    };
                    api.getConversionWidgetData(EConversionWidgetNames.trafficSources, widgetParams);
                    api.getConversionWidgetData(EConversionWidgetNames.audience, widgetParams);
                    api.getConversionWidgetData(EConversionWidgetNames.technology, widgetParams);
                    api.getConversionTable({
                        ...(requestParams as IConversionTableParams),
                        numerator: numeratorArr[index] || { ...numerator, metric },
                    });
                });
            });
        },

        // Получение данных по одной конверсии
        getConversionData(requestData: IConversionRequestData, id: number): void {
            dispatch(
                conversionsActions.getConversionData({
                    projectId,
                    data: requestData,
                    id,
                }),
            );
        },

        formatConversionGraphData(data: IConversionDictData): TChartDataItem[] {
            if (!data?.goal) return [];

            return [
                {
                    data: data.conversion?.data,
                    name: EConversionTitles.conversion,
                },
                {
                    data: data.goal?.data,
                    name: EConversionTitles.goal,
                },
            ];
        },

        transformConversionDataForRequest(
            title: string,
            numeratorId: number,
            denominatorId: TOrNull<number>,
            metricTitle: string,
            description?: string,
        ): IConversionData {
            const metric = conversionMetricsList.find((item) => item.title === metricTitle)?.name as Metrics;

            return {
                title,
                ...(description ? { description } : {}),
                numerator_goal_id: numeratorId,
                denominator_goal_id: denominatorId,
                metric,
                project_id: Number(projectId),
            };
        },

        updateParams(newParams: Record<string, any>): void {
            dispatch(conversionsActions.updateParams(newParams));
        },

        // Получаем список всех целей для заданного списка конверсий
        getGoalsForConversions(
            conversions: Partial<IConversionTableData>[],
            isForceGoalsRequest?: boolean,
        ): Promise<any> {
            // Если есть сохраненные фильтры целей, то новый запрос не делаем
            if (!isForceGoalsRequest && numeratorsFilters.length) return Promise.resolve(null);

            let ids = [];

            conversions.forEach(({ numerator_goal_id, denominator_goal_id }) => {
                if (numerator_goal_id) ids.push(numerator_goal_id);
                if (denominator_goal_id != null) ids.push(denominator_goal_id);
            });

            ids = [...new Set(ids)];

            if (!ids.length) return Promise.resolve(null);

            return getGoalsByIds(ids);
        },

        getLinkToConversion(id: number): string {
            return `/stat/projects/${projectId}/conversions/${id}`;
        },

        handleChangeDate(date: string[], relativeDate: DateLiteral): void {
            const [start, end] = date;

            const updateParams = {
                ...(relativeDate ? DateUtils.getRelativeDates(relativeDate) : { dateStart: start, dateEnd: end }),
                groupBy,
            };

            updateParams.groupBy = DateUtils.checkCurrentGroup(
                [DateUtils.getDate(start), DateUtils.getDate(end)],
                groupBy,
            );

            api.updateReport('all', { updateParams });
        },

        getConversionWidgetData(
            widgetName?: EConversionWidgetNames,
            requestParams?: Partial<IConversionWidgetRequestData>,
            isBySwitchGraphType?: boolean,
        ): void {
            const graphType = conversionWidgets && conversionWidgets[widgetName]?.graphType;
            const isByDate = [ChartTypes.line, ChartTypes.area].includes(graphType);

            let requestData = {
                date_start: dateStart,
                date_end: dateEnd,
                group: groupBy,
                sample,
                by_date: isByDate,
                numerator: numeratorsFilters[0],
                denominator: denominatorsFilters[0],
                ...requestParams,
            } as IConversionWidgetRequestData;

            switch (widgetName) {
                case EConversionWidgetNames.trafficSources:
                    requestData = {
                        ...requestData,
                        dimension:
                            requestData.dimension !== undefined ? requestData.dimension : trafficSourcesDimension || '',
                        window: Number(requestData.window || attributionWindow || attributionWindowData[0].value),
                        attribution_model:
                            requestData.attribution_model || attributionModel || attributionModelData[0].value,
                    };
                    break;
                case EConversionWidgetNames.audience:
                    requestData = {
                        ...requestData,
                        dimension: requestData.dimension || audienceDimension,
                    };
                    break;
                case EConversionWidgetNames.technology:
                    requestData = {
                        ...requestData,
                        dimension: requestData.dimension || technologyDimension,
                    };
                    break;
                default:
                    break;
            }

            dispatch(
                conversionsActions.getConversionWidgetData({
                    widgetName,
                    projectId,
                    data: requestData,
                    isBySwitchGraphType,
                }),
            );
        },

        /* Обновление параметров виджета в store */
        updateWidgetParams(widgetName: string, options: IUpdateWidgetParams): void {
            dispatch(conversionsActions.updateWidgetParams({ widgetName, options }));

            // При переключении типа графика делаем запрос с by_date или нет
            if (
                [ChartTypes.line, ChartTypes.area].includes(options.graphType) &&
                !conversionWidgets[widgetName].data?.length
            ) {
                api.getConversionWidgetData(widgetName as EConversionWidgetNames, { by_date: true }, true);
            }

            if (
                [ChartTypes.bar, ChartTypes.pie].includes(options.graphType) &&
                !conversionWidgets[widgetName].dataTotals?.length
            ) {
                api.getConversionWidgetData(widgetName as EConversionWidgetNames, { by_date: false }, true);
            }
        },

        updateTrafficSourcesWidget(value: string | null): void {
            const dimension = value === null ? '' : value;
            api.getConversionWidgetData(EConversionWidgetNames.trafficSources, { dimension });
        },

        updateAudienceOrTechWidget(widgetName: string, options: IUpdateWidgetParams): void {
            const { dimensions } = options || {};
            const { audience, technology } = EConversionWidgetNames;

            if (!dimensions) return;

            const [dimension] = dimensions;

            if (widgetName === audience) {
                api.getConversionWidgetData(audience, { dimension });
            } else {
                api.getConversionWidgetData(technology, { dimension });
            }
        },

        // Получаем список действий для шестеренки конверсии
        getConversionActionsList(
            actions: IEntityAction[],
            idConversion: number,
            access: EntityAccess,
            ownerEmail: string,
            goalsIds: (number | null)[], // цели конверсии
            onClickEdit: (id?: number) => void,
        ): IEntityAction[] {
            const newActions = actions.filter((action) => {
                const { id } = action;

                const ownGoals = goalsIds.filter((goalId) => {
                    const goal = goalsList.current?.find((item) => item.id === goalId);
                    return goal?.access === EntityAccess.own;
                });

                // Конверсию можно сделать общей только если она личная
                // и состоит из общих целей
                if ((access === EntityAccess.common || ownGoals.length) && id === EntityActions.addToCommon)
                    return false;

                // Удалять может только owner и на отчете списка конверсий
                if ((userEmail !== ownerEmail || isOneConversionReport) && id === EntityActions.delete) return false;

                return true;
            });

            return newActions.map((item) => {
                const result = { ...item };

                switch (item.id) {
                    case EntityActions.edit:
                        result.onClick = () => onClickEdit(idConversion);
                        break;
                    case EntityActions.delete:
                        result.onClick = () => api.deleteConversion(idConversion);
                        break;
                    case EntityActions.addToCommon:
                        result.onClick = () =>
                            api.updateConversion(idConversion, {
                                access: EntityAccess.common,
                            });
                        break;
                    default:
                        break;
                }

                return result;
            });
        },

        requestCsv(): void {
            dispatch(
                reportsActions.requestReportCsv({
                    projectId,
                    data: {
                        numerator: numeratorsFilters?.[0],
                        dimensions: tableDimensions,
                        metrics: conversionConfig?.tableDefaultMetrics,
                        sort: tableSort,
                        attribution_model: attributionModel || attributionModelData[0].value,
                        window: attributionWindow || attributionWindowData[0].value,
                        date_start: dateStart,
                        date_end: dateEnd,
                        group: groupBy,
                        sample: 1,
                        title: 'conversion_table',
                    },
                    isEventBased: true,
                }),
            );
        },

        isDemoProject(): boolean {
            return Number(projectId) === reportsConstants.DEMO_PROJECT_ID && userRole !== userRoles.ADMIN;
        },
    };

    return { api, reportsUtils };
};

export default useConversions;
