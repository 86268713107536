import TableTools from '@components/TableTools';
import userRoles from '@constants/userRoles';
import { setUserProjectsExpanded } from '@redux/slices/users';
import { IProjectsListUpdateParams } from '@typings/projects';
import React from 'react';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';

interface IProps {
    isDeleted: boolean;
    searchValue: string;
    getProjectsTable: (updateParams: IProjectsListUpdateParams) => void;
    searchPlaceholder: string;
    userRole: number;
    isUserProjectsExpanded: boolean;
}

const ProjectsTools: React.FC<IProps> = ({
    isDeleted,
    searchValue,
    searchPlaceholder,
    getProjectsTable,
    userRole,
    isUserProjectsExpanded,
}) => {
    const { ADMIN, SUPPORT, ANALYST, MANAGER } = userRoles;
    const isAvailableExtendedTools = [ADMIN, SUPPORT, ANALYST, MANAGER].includes(userRole);
    const navigate = useNavigate();
    const dispatch = useDispatch();

    // Отображение всех доступных проектов или явно привязанных
    const handleChangeExpanded = (checked?: boolean) => {
        dispatch(setUserProjectsExpanded(checked));
    };

    // Ввод значения в поиске
    const handleSearchProjects = (value: string) => {
        getProjectsTable({ search: value });
    };

    return (
        <TableTools
            onSearchChange={handleSearchProjects}
            searchPlaceholder={searchPlaceholder}
            searchWord={searchValue}
            prefixButtonText={!isDeleted ? 'Добавить сайт' : ''}
            onClickPrefixButton={() => navigate(`/stat/create`)}
            switcherText={isAvailableExtendedTools ? 'Показать все доступные проекты' : ''}
            onChangeSwitcher={handleChangeExpanded}
            switcherHintText="Вы можете выбрать поиск по всем доступным вам проектам или только по явно привязанным к
            вашему профилю."
            switcherChecked={isUserProjectsExpanded}
        />
    );
};

export default ProjectsTools;
