import { Tooltip, DropDownList, DropDownButton } from '@adtech/ui';
import { Type, Params } from '@typings/dropdown';
import { ReportName } from '@typings/reports';

import { arrayUtils } from '@utils/index';
import cn from 'classnames';
import React, { useEffect, useState, ReactNode } from 'react';
import s from './Dropdown.pcss';
import ListCustom from './ListCustom';
import SelectMultiple from './SelectMultiple/index';
import SelectSingle from './SelectSingle/index';

export interface IProps {
    type: Type;
    params?: Params;
    isHidden?: boolean; // флаг для закрытия попапа

    // custom params
    arrowClassName?: string;
    contentClassName?: string;
    isShowArrow?: boolean;
    isActive?: boolean;

    // Anchor
    anchorClassName?: string; // класс анкора
    anchorText?: ReactNode; // текст анкора
    anchorTextOutside?: boolean; // текст анкора будет слева от initialSelected
    anchorPrefixIcon?: JSX.Element;
    anchorSize?: 'middle' | 'large' | 'small';
    isAnchorButton?: boolean;
    anchorButtonType?: 'dashed' | 'default';
    anchorRound?: boolean;

    placement?: 'topLeft' | 'topCenter' | 'topRight' | 'bottomLeft' | 'bottomCenter' | 'bottomRight' | 'top' | 'bottom';

    // attr
    reportName?: ReportName;
    dataAttrName?: string;
    dataAttrAnchor?: string | string[];
    dataAttrList?: string[];
    dataTestId?: string;
    btnDataTestId?: string;

    // Main
    customContent?: ReactNode;
    data?: Array<any>;
    dataInitialSelected?: any;
    initialSelected?: string;
    isShowSelected?: boolean;
    isDisabled?: boolean;
    tooltip?: string;
    onOpen?: () => void;
    onClose?: () => void;
    onClick?: () => void;
    onApply?: (dataSelected: string | string[], isChanged?: boolean) => void;
}

const Dropdown: React.FC<IProps> = ({
    type,
    params,
    isHidden,

    // custom params
    contentClassName,
    isShowArrow = true,
    isActive = false,

    // Anchor
    anchorClassName = '',
    anchorText = '',
    anchorTextOutside = false,
    anchorPrefixIcon,
    anchorSize = 'middle',
    isAnchorButton = true,
    anchorButtonType = 'dashed',
    anchorRound = false,

    placement,

    // attr
    reportName = '',
    dataAttrName = '',
    dataAttrAnchor = [],
    dataAttrList = [],
    dataTestId = '',
    btnDataTestId = '',

    // Main
    customContent,
    data = [],
    dataInitialSelected = [],
    initialSelected = null,
    isShowSelected = true,
    isDisabled = false,
    tooltip = null,
    onOpen = () => null,
    onClose = () => null,
    onClick = () => null,
    onApply = () => null,
}) => {
    const [isOpened, setIsOpened] = useState(false);
    const [isRequestClose, setIsRequestClose] = useState(false);

    useEffect(() => {
        if (!isHidden) return;
        setIsOpened(false);
    }, [isHidden]);

    if (!type) return null;

    const contentClassNames = cn(s.content, contentClassName, s[`content_${type}`]);

    const anchorClassNames = cn(s.anchor, anchorClassName, s[`anchor_${anchorSize}`], {
        [s.anchorEmptyButtonStyle]: !isAnchorButton,
        [s.anchorOpened]: isOpened,
        [s.anchorActive]: isActive,
    });

    let dataAttrAnchorColon: string;
    let dataAttrAnchorScores: string;

    if (dataAttrAnchor.length) {
        dataAttrAnchorColon = `${arrayUtils.convertToStringWithSeparator(dataAttrAnchor, '::')}${
            reportName ? `::${reportName}` : ''
        }`;
        dataAttrAnchorScores = arrayUtils.convertToStringWithSeparator(dataAttrAnchor, '_');
    }

    const getDataAttrAnchor = () => {
        if (!dataAttrAnchor.length) return {};
        if (dataAttrName) return { [`data-${dataAttrName}`]: dataAttrAnchorColon };
        return { 'data-atst': dataAttrAnchorScores };
    };

    const close = () => setIsOpened(false);

    // Клик по анкору
    const handleClick = () => {
        onClick();
        if (!isOpened) setIsOpened(true);
    };

    // Закрытие
    const handleClose = () => {
        if (isRequestClose) setIsRequestClose(false);
        close();
        onClose();
    };

    // Изменение
    const handleChange = async (open: boolean) => {
        if (!open) {
            await setIsRequestClose(true);
            setIsOpened(false);
            onClose();
        } else {
            setIsOpened(true);
            onOpen();
        }
    };

    // Применение
    const handleApply = async (dataSelected: string | string[], isChanged?: boolean) => {
        if (!isChanged) return;
        await onApply(dataSelected);
        await setIsRequestClose(false);
        if (isOpened) close();
    };

    // Рендер анкора
    const getSelected = () => {
        if (initialSelected) return initialSelected;
        const selected = data.find((item) => item.value === dataInitialSelected);
        return selected && selected.title;
    };

    const anchorTextNode = anchorText ? <span className={s.anchorText}>{anchorText}</span> : null;

    const renderAnchor = () => {
        const icon = !isShowArrow ? anchorPrefixIcon : undefined;

        const anchor = (
            <DropDownButton
                size={anchorSize}
                type={anchorButtonType}
                icon={icon}
                className={anchorClassNames}
                disabled={isDisabled}
                round={anchorRound}
                {...(btnDataTestId ? { 'data-testid': btnDataTestId } : {})}
            >
                {!anchorTextOutside && anchorTextNode}
                {isShowSelected && (dataInitialSelected?.length > 0 || initialSelected) && (
                    <span className={s.anchorText} data-atst="dropdown_selected">
                        {getSelected()}
                    </span>
                )}
            </DropDownButton>
        );

        return (
            <div className={s.anchorInner} onClick={handleClick} {...getDataAttrAnchor()}>
                {tooltip && !isOpened ? (
                    <Tooltip placement="bottom" title={tooltip}>
                        {anchor}
                    </Tooltip>
                ) : (
                    anchor
                )}
            </div>
        );
    };

    // Рендер контента
    const renderContent = () => {
        let content;
        switch (type) {
            case 'selectSingle':
                content = (
                    <SelectSingle
                        onApply={handleApply}
                        data={data}
                        dataInitialSelected={dataInitialSelected}
                        dataAttrName={dataAttrName}
                        dataAttrList={dataAttrList}
                        {...params}
                    />
                );
                break;
            case 'selectMultiple':
                content = (
                    <SelectMultiple
                        isRequestClose={isRequestClose}
                        onApply={handleApply}
                        data={data}
                        dataInitialSelected={dataInitialSelected}
                        dataAttrName={dataAttrName}
                        dataAttrList={dataAttrList}
                        {...params}
                    />
                );
                break;
            case 'listCustom':
                content = <ListCustom data={data} dataAttrName={dataAttrName} onSelect={handleClose} {...params} />;
                break;
            case 'customContent':
                content = customContent;
                break;
            default:
                content = null;
                break;
        }

        return <div data-testid={dataTestId}>{content}</div>;
    };

    return (
        <div className={cn(s.root, { [s.anchorTextOutside]: anchorTextOutside })}>
            {anchorTextOutside && anchorTextNode}
            {/* eslint-disable-next-line @typescript-eslint/ban-ts-comment */}
            {/* @ts-ignore */}
            <DropDownList
                open={isOpened}
                customButton={renderAnchor()}
                className={contentClassNames}
                dropdownRender={renderContent}
                onOpenChange={handleChange}
                {...(placement ? { placement } : {})}
            />
        </div>
    );
};

export default Dropdown;
