import pages from '@configs/projects/pages';
import snackbarsConstants from '@constants/snackbars';
import { useAppDispatch } from '@hooks/useStore';
import { globalActions } from '@redux/slices/global';
import { projectsActions } from '@redux/slices/projects';
import * as Sentry from '@sentry/react';
import { IProjectsListUpdateParams, IProjectsListRequestParams, ProjectsPageName } from '@typings/projects';
import { IRootSlice } from '@typings/rootSlice';
import { useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';

const useProjects = () => {
    const dispatch = useAppDispatch();
    const projectsSlice = useSelector((state: IRootSlice) => state.projectsSlice);
    const userSlice = useSelector((state: IRootSlice) => state.userSlice);
    const { pathname } = useLocation();
    const isDeleted = pathname.includes('deleted');

    const api = {
        /**
         * Инициализация
         */
        async init(): Promise<unknown> {
            await api.getProjectsTable({
                isUseRating: false,
            });
            return Promise.resolve();
        },

        /**
         * Запрос данных для таблицы
         */
        getProjectsTable(updateParams: IProjectsListUpdateParams = {}): void {
            const deleted = String(isDeleted || false);
            const expanded = String(userSlice.isUserProjectsExpanded);
            const use_rating = String(updateParams.isUseRating || false);
            const search = updateParams.search || '';
            const offset = updateParams.offset || 0;
            const lastReqId = projectsSlice.tableLastReqId + 1;

            const newRequestParams: IProjectsListRequestParams = {
                deleted,
                expanded,
                use_rating,
                offset,
                search,
                lastReqId,
            };

            dispatch(projectsActions.updateRequestParams({ tableSearch: search }));

            dispatch(projectsActions.getProjectsTable(newRequestParams))
                .unwrap()
                .then((response) => {
                    if (response.responseStatus !== 200) return;

                    dispatch(
                        projectsActions.updateProjectsTable({
                            ...response,
                            offset,
                            search,
                            lastReqId,
                        }),
                    );
                })
                .catch((err) => {
                    Sentry.captureException(err);
                });
        },

        getProjectsTableOffset(offset: number = 0): void {
            const search = projectsSlice.tableSearch;
            api.getProjectsTable({ offset, search });
        },

        applyModalPopup(projectId: number): Promise<void> {
            const pageName = api.getProjectsPageName();
            const configPage = pages[pageName];
            const counterActionName = configPage.counterActionName;
            const snackbarMessage = snackbarsConstants.counterMessages[configPage.snackbarMessage];

            dispatch(projectsActions[counterActionName](projectId)).then((res) => {
                if (res.error) return;

                if (res.payload.responseStatus === 200) {
                    dispatch(globalActions.setSnackbar(snackbarMessage));
                }
            });
            return Promise.resolve();
        },

        openModalPopup(modalPopupParams: Record<string, () => void> = {}): void {
            const pageName = api.getProjectsPageName();
            const modalPopupId = pages[pageName].modalPopupId;
            dispatch(globalActions.openModalPopup({ modalPopupId, modalPopupParams }));
        },

        getProjectsPageName: (): ProjectsPageName => (isDeleted ? 'deleted' : 'enabled'),
    };

    return { api };
};

export default useProjects;
