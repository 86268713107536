
import { sortOrders } from '@constants/sort';
import { IMediaConfig } from '@typings/media';

const config: IMediaConfig = {
    reportTitle: 'Отчёт по материалам',
    tableDataType: 'dynamic',
    relativeLinkToReport: 'material',
    tableTitleDimension: 'media_title',
    isTableTitleSearch: true,
    isShowTotals: false,
    availableTools: {
        periodPicker: true,
        activeSample: true,
        sample: true,
        detail: true,
        filters: true,
        savedFilters: true,
    },
    tableDefaultMetrics: [
        'page_views',
        'visits',
        'avg_fullread_percent',
    ],
    availableMetrics: [
        'page_views',
        'visitors',
        'visits',
        'avg_fullread_percent',
        'avg_fullscroll_percent',
        'avg_recirculation',
        'avg_view_duration',
        'recirculation_count',
        'uid',
        'rambler_id',
    ],
    availableDimensions: [
        'traffic_type',
        'media_title',
        'media_url',
    ],
    tableFixedDimensions: ['traffic_type'],
    defaultSort: {
        name: 'page_views',
        order: sortOrders.DESC,
    },
    disabledSortBy: ['title'],
    isDisabledCheckbox: true,
    reportHint: {
        message: '<p>Отчёты по медиа &mdash; это аналитика по&nbsp;контенту на&nbsp;сайте. '
            + 'Мы считаем посещаемость, источники переходов и специальные метрики для контента: '
            + 'рециркуляция, процент дочтения и время на&nbsp;материале.</p>'
            + '<p>Для корректной работы отчётов необходимо: '
            + '<br/>&mdash;&nbsp;сделать разметку по стандарту schema.org '
            + '(если на вашей площадке уже есть данные, проверьте корректность)'
            + '<br/>&mdash;&nbsp;отправить письмо на&nbsp;'
            + '<a href="mailto:counte@ads.sber.ru">counter@ads.sber.ru</a> '
            + 'с&nbsp;текстом &laquo;Подключите медиаотчёты&raquo; '
            + '(мы&nbsp;включим вашу площадку в&nbsp;специальный список '
            + 'и&nbsp;проверим, что все работает хорошо)</p>',
    },
    tableHint: {
        message: 'Самые посещаемые материалы за выбранный период. Доступные метрики — просмотры, посетители, время на материале, рециркуляция, % дочтения, источники, % доскролла и визиты. Есть поиск по названию материала, также доступна фильтрация и сортировка по значениям.',
    },
    keyMetricsHint: 'Основной раздел отчёта, демонстрирующий целевые метрики по всем материалам сайта за выбранный период (по умолчанию показаны метрики за сегодня)',
    isShowLinkWithTitle: true,
    dataRequestFunction: 'getMaterialsSummaryRequest',
};

export default config;
