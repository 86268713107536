const pages = {
    enabled: {
        title: 'Мои сайты',
        search: {
            placeholder: 'Искать по моим сайтам',
        },
        counterActionName: 'deleteProject',
        snackbarMessage: 'DELETE_PROJECT_COUNTER',
        modalPopupId: 'RemoveCounter',
    },
    deleted: {
        title: 'Удаленные сайты',
        search: {
            placeholder: 'Искать по удаленным сайтам',
        },
        counterActionName: 'restoreProject',
        snackbarMessage: 'RESTORE_PROJECT_COUNTER',
        modalPopupId: 'RestoreCounter',
    },
};

export default pages;
