import { useAppDispatch } from '@hooks/useStore';
import { projectsActions } from '@redux/slices/projects';
import * as Sentry from '@sentry/react';
import { ReportName } from '@typings/reports';
import { IRootSlice } from '@typings/rootSlice';
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';

interface IProps {
    params: {
        reportName: ReportName;
        projectId: string | number;
    };
    children: any;
    userId: number;
    projectCurrentId: number;
    projectUserId: number;
    getProject: () => void;
}

export default function projectRequired(Component: any) {
    const ProjectHOC: React.FC<IProps> = (props) => {
        const dispatch = useAppDispatch();
        const { projectId } = useParams();

        const [isReadyRender, setReadyRender] = useState<boolean>(false);

        const userId = useSelector((state: IRootSlice) => state.userSlice.id);
        const projectCurrentId = useSelector((state: IRootSlice) => state.projectsSlice.current.id);

        const getProject = (pId: string, uId: number) =>
            dispatch(projectsActions.getProject({ projectId: pId, userId: uId })).unwrap();

        useEffect(() => {
            getProject(projectId, userId)
                .then((res) => {
                    if (res?.responseStatus === 200) {
                        setReadyRender(true);
                    }
                    // В том числе, чтобы в юнит тестах не зависали промисы
                })
                .catch((err) => {
                    Sentry.captureException(err);
                });

            return () => {
                setReadyRender(false);
            };
        }, []);

        useEffect(() => {
            if (!isReadyRender) return;

            getProject(projectId, userId);
        }, [projectId, userId]);

        if (!projectCurrentId || !isReadyRender) {
            return null;
        }

        const component: any = Component ? <Component /> : props.children;

        return React.cloneElement(component, props);
    };

    return ProjectHOC;
}
