import store from '@redux/store';
import React from 'react';
import { Provider } from 'react-redux';
import { BrowserRouter as Router } from 'react-router-dom';

import AppRouter from './routes';

const App = (): JSX.Element => (
    <Provider store={store}>
        <Router>
            <AppRouter />
        </Router>
    </Provider>
);

export default App;
