class DomUtils {
    selectText = (id: string): void => {
        let sel;
        let range;
        const el = document.getElementById(id);

        if (!window && !document) {
            return;
        }

        if (window.getSelection && document.createRange) {
            sel = window.getSelection();
            if (sel.toString() === '') {
                window.setTimeout(() => {
                    range = document.createRange();
                    if (el) {
                        range.selectNodeContents(el);
                    }
                    sel.removeAllRanges();
                    sel.addRange(range);
                }, 1);
            }
        } else if (document.selection) {
            sel = document.selection.createRange();
            if (sel.text === '') {
                range = document.body.createTextRange();
                range.moveToElementText(el);
                range.select();
            }
        }
    };

    clearAllText = (): void => {
        if (window.getSelection) {
            if (window.getSelection().empty) {
                window.getSelection().empty();
            } else if (window.getSelection().removeAllRanges) {
                window.getSelection().removeAllRanges();
            }
        } else if (document.selection) {
            document.selection.empty();
        }
    };

    getCaretPosition = (ctrl: HTMLInputElement): { start: number; end: number } => {
        if (document.selection) {
            ctrl.focus();

            const range = document.selection.createRange();
            const rangelen = range.text.length;
            range.moveStart('character', -ctrl.value.length);
            const start = range.text.length - rangelen;
            return { start, end: start + rangelen };
        }

        if (ctrl.selectionStart || ctrl.selectionStart === 0) {
            return { start: ctrl.selectionStart, end: ctrl.selectionEnd };
        }

        return { start: 0, end: 0 };
    };

    setCaretPosition = (ctrl: any, start: number, end: number): void => {
        if (ctrl.setSelectionRange) {
            ctrl.focus();
            ctrl.setSelectionRange(start, end);
        } else if (ctrl.createTextRange) {
            const range = ctrl.createTextRange();
            range.collapse(true);
            range.moveEnd('character', end);
            range.moveStart('character', start);
            range.select();
        }
    };

    checkInputText = (ctrl: any): boolean =>
        (ctrl.nodeName === 'INPUT' &&
            (ctrl.getAttribute('type') === 'text' || ctrl.getAttribute('type') === 'password')) ||
        ctrl.nodeName === 'TEXTAREA';

    putDataAttr = (node: HTMLElement, dataAttr: string): void => {
        const data = dataAttr.split('=');

        if (data.length < 2) return;

        // eslint-disable-next-line no-param-reassign
        node.dataset[data[0]] = data[1];
    };
}

export default new DomUtils();
