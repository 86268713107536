import mediaConfig from '@configs/media';
import { useAppDispatch } from '@hooks/useStore';
import { globalActions } from '@redux/slices/global';
import { IRootSlice } from '@typings/rootSlice';
import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useParams, useNavigate } from 'react-router-dom';

interface IParams {
    isProjectRequired: boolean;
    customReport?: string;
}

const useCheckReportAccess = ({ isProjectRequired, customReport }: IParams) => {
    const navigate = useNavigate();
    const dispatch = useAppDispatch();
    const params = useParams();
    const [access, setAccess] = useState<boolean>(false);
    const userId = useSelector((state: IRootSlice) => state.userSlice.id);
    const availableReports = useSelector(({ projectsSlice }: IRootSlice) => projectsSlice.current.available_reports);

    const setError = (code: number) => {
        dispatch(globalActions.setError({ code }));
    };

    const setGlobalLoading = (state: boolean) => {
        dispatch(globalActions.setGlobalLoading(state));
    };

    const reportName = customReport || params.reportName;

    useEffect(() => {
        if (!isProjectRequired) return;

        let isReportAccessed = false;

        // в available reports для медиа приходят названия ручек, а не отчётов
        // проверяем доступность по главной ручке media_totals
        if (mediaConfig[reportName]) {
            isReportAccessed = availableReports.includes('media_totals');
        } else {
            // FIXME: для рейтинга для отображения заглушки, пока хардкод
            isReportAccessed = [...availableReports, 'rating'].includes(reportName);
        }

        if (isReportAccessed) {
            setAccess(true);
        } else if (!userId) {
            navigate('/');
        } else {
            setAccess(false);
            setError(403);
            setGlobalLoading(false);
        }
    }, [isProjectRequired]);

    return access;
};

export default useCheckReportAccess;
