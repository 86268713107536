import ReportMessage from '@components/ReportMessage';
import { Dimensions, DimensionsData } from '@typings/dimensions';
import { GraphLine, GraphLines, SelectedLines } from '@typings/graph';
import { Metrics, IMetricsTableData, IMetricsWithStates } from '@typings/metrics';
import { ReportName } from '@typings/reports';
import { IOrderBy, IDataTable, ITableConstants } from '@typings/table';
import { arrayUtils } from '@utils/index';
import debounce from 'lodash/debounce';
import React from 'react';
import { TransitionMotion, TransitionStyle, spring } from 'react-motion';

import s from '../../ReportTable.pcss';
import ReportTableRow from '../TableRow';
import TableShowMoreRow from '../TableShowMoreRow';

interface ITableRow {
    canBeExpanded: boolean;
    expand: boolean;
    id: string | number;
    level: number;
    metricsData: IMetricsTableData[];
    dimensionsData?: DimensionsData[];
    parentId: string;
    title: string;
    withParentsId: string;
    url: string;
    showmore?: boolean;
    totalLength?: number;
    currLength?: number;
    hash?: string | number;
    extra?: Record<string, string>;
    isHeadButton?: boolean;
    currOffset?: number;
    relativeTitleLink?: string;
    position?: number;
    diff?: number;
}

interface IProps {
    reportName: ReportName;
    metrics: Metrics[];
    metricsWithStates: IMetricsWithStates[];
    dimensions?: Dimensions[];
    selectedLines: SelectedLines;
    indicators: Metrics[];
    data: IDataTable;
    onExpandClick: (parents: string, options?: { needToOpenNode: boolean }) => void;
    getSelectedLines: (hash, selectedItems, deletedItems) => Record<string, any>;
    onUploadLine: (lines: GraphLines, isRemove: boolean) => void;
    onToggleLine: (lines: GraphLines, isRemove: boolean) => void;
    onShowmoreClick: (parentId: string, currentRows: number) => void;
    onShowmoreTopClick: (offset: number, limit: number) => void;
    toggleUseDefaultLines: (useDefaultLines: boolean) => void;
    orderBy: IOrderBy;
    useDefaultLines: boolean;
    counterTableRowsReport?: number;
    isRating?: boolean;
    isSummary?: boolean;
    utils: TAPI;
    labels?: Record<string, string>;
    constants: ITableConstants;
    isPagination?: boolean;
    isAnimated?: boolean;
}

interface IState {
    loadItems: SelectedLines;
    selectedItems: SelectedLines;
    deletedItems: SelectedLines;
}

export default class TableBody extends React.Component<IProps, IState> {
    static defaultProps = {
        reportName: '',
        indicatorsWithStates: [],
        selectedLines: [],
        deletedItems: [],
        indicators: [],
        onExpandClick: () => {},
        getSelectedLines: () => {},
        onUploadLine: () => {},
        onToggleLine: () => {},
        onShowmoreClick: () => {},
        onShowmoreTopClick: () => {},
        toggleUseDefaultLines: () => {},
        utils: {},
        labels: {},
        dimensions: [],
        constants: {},
    };

    expandPadding = false;

    constructor(props: IProps) {
        super(props);

        this.state = {
            loadItems: [],
            selectedItems: [],
            deletedItems: [],
        };
    }

    componentDidUpdate(prevProps: IProps) {
        const { selectedLines, useDefaultLines } = this.props;
        const { selectedItems } = this.state;

        const sameLinesToProps = arrayUtils.isEqualArray(prevProps.selectedLines, selectedLines);
        const sameItemsToState = arrayUtils.isEqualArray(selectedItems, selectedLines);

        // Если state пустой, а из props есть данные
        // Если state отличается от props и только линии по умолчанию --ИЛИ--
        // Если линии в prevProps больше нуля и в props их нет
        if (
            (!selectedItems.length && !sameLinesToProps) ||
            (!sameItemsToState && useDefaultLines) ||
            (prevProps.selectedLines.length > 0 && !selectedLines.length)
        ) {
            this.setState({ selectedItems: selectedLines });
        }

        // Если при сортировке/поиске useDefaultLines стал true, то
        // нужно очистить deletedItems, иначе будут дубли цветов
        if (useDefaultLines && useDefaultLines !== prevProps.useDefaultLines) {
            this.setState({ deletedItems: [] });
        }
    }

    handleUploadLine = debounce((line: GraphLine, isRemove: boolean) => {
        const { onUploadLine } = this.props;
        const { loadItems } = this.state;

        onUploadLine([...loadItems, line], isRemove);

        this.setState({ loadItems: [] });
    }, 1000);

    onSelectLine = (hash: string) => {
        const { selectedItems, deletedItems, loadItems } = this.state;
        const { getSelectedLines, toggleUseDefaultLines, onToggleLine, useDefaultLines } = this.props;

        if (useDefaultLines) {
            toggleUseDefaultLines(false);
        }

        const updatedLines: Record<string, any> = getSelectedLines(hash, selectedItems, deletedItems);

        this.setState(
            {
                loadItems: loadItems.concat(updatedLines.currentLine),
                selectedItems: updatedLines.newSelectedLines,
                deletedItems: updatedLines.newDeletedLines,
            },
            () => {
                this.handleUploadLine(updatedLines.currentLine, updatedLines.isRemoveLine);
                onToggleLine([updatedLines.currentLine], updatedLines.isRemoveLine);
            },
        );
    };

    renderRow = (itemData: TransitionStyle | ITableRow, index: number) => {
        const {
            onExpandClick,
            indicators,
            reportName,
            metricsWithStates,
            dimensions,
            orderBy,
            isRating,
            onShowmoreClick,
            onShowmoreTopClick,
            utils,
            labels,
            constants,
        } = this.props;

        const item: ITableRow = itemData.data || itemData;

        const { selectedItems } = this.state;
        const { data } = this.props;

        if (item.showmore) {
            return (
                <TableShowMoreRow
                    key={index}
                    parentId={item.parentId}
                    onShowMoreClick={onShowmoreClick}
                    onShowmoreTopClick={onShowmoreTopClick}
                    metrics={indicators}
                    dimensions={dimensions}
                    totalRows={item.totalLength || 0}
                    currentRows={item.currLength || 0}
                    reportName={reportName}
                    isHeadButton={item.isHeadButton}
                    currOffset={item.currOffset}
                />
            );
        }

        const isSelected = selectedItems.find((line) => line.id === item.withParentsId);
        const hasDisabledItems = constants.MAX_SELECTED_ROWS === selectedItems.length;
        const isDisabled = !isSelected && hasDisabledItems;
        const colorIndex = isSelected && isSelected.colorIndex;

        let children = null;

        if (item.expand) {
            const itemChildren = utils.prepareDataForRender(data, item.withParentsId);

            if (itemChildren !== null) {
                children = itemChildren.length ? itemChildren.map(this.renderRow) : this.renderReportMessage(item);
            }
        }

        const row = (
            <ReportTableRow
                key={itemData.key || index}
                reportName={reportName}
                indicators={indicators}
                onExpandClick={onExpandClick}
                canBeExpanded={item.canBeExpanded}
                expand={item.expand}
                level={item.level}
                title={item.title}
                url={item.extra.url}
                expandPadding={this.expandPadding}
                withoutExpandPadding={item.parentId === constants.ROOT_NODE}
                onSelectLine={this.onSelectLine}
                metricsWithStates={metricsWithStates}
                dimensions={dimensions}
                metricsData={item.metricsData}
                dimensionsData={item.dimensionsData}
                orderBy={orderBy}
                id={String(item.id)}
                withParentsId={item.withParentsId}
                isSelected={!!isSelected}
                isDisabled={isDisabled}
                colorIndex={colorIndex}
                isRating={isRating}
                utils={utils}
                labels={labels}
                relativeTitleLink={item.relativeTitleLink}
                position={item.position}
                diff={item.diff}
                style={itemData.style || {}}
            />
        );

        return [row, children];
    };

    renderReportMessage = (item: Record<string, any>) => (
        <tr className={s.tableRowMessage} key={item.withParentsId}>
            <td className={s.tableCellMessage} colSpan={4}>
                <ReportMessage className={s.tableMessage} message="Нет данных для отображения" />
            </td>
        </tr>
    );

    /**
     * Обогащаем данные таблицы информацией о стилях
     */
    prepareDataToStyles = (data: ITableRow[], isAnimate: boolean) => {
        const { TABLE_ROW_HEIGHT, TABLE_ANIMATION_PARAMS } = this.props.constants;

        return data.map((item, index) => {
            const top = isAnimate ? spring(index * TABLE_ROW_HEIGHT, TABLE_ANIMATION_PARAMS) : index * TABLE_ROW_HEIGHT;

            return {
                key: item.id,
                style: {
                    top,
                },
                data: item,
            };
        });
    };

    renderAnimatedTableBody = (resultData: ITableRow[]) => {
        const { data, constants } = this.props;
        // animate - анимировать ли текущий рендер
        const preparedData = this.prepareDataToStyles(resultData, data?.animate);
        return (
            <TransitionMotion styles={preparedData}>
                {(styledData) => (
                    <tbody
                        data-testid="animated-table"
                        style={{ height: `${styledData.length * constants.TABLE_ROW_HEIGHT}px` }}
                    >
                        {styledData.map(this.renderRow)}
                    </tbody>
                )}
            </TransitionMotion>
        );
    };

    render() {
        const { data, counterTableRowsReport, utils, isSummary, isPagination, isAnimated, constants } = this.props;
        const maxRows = constants.MAX_SELECTED_ROWS;
        let resultData = [];

        if (isSummary) {
            resultData = utils.prepareDataForRenderSummary(data, constants.ROOT_NODE, counterTableRowsReport);
        } else {
            resultData = utils.prepareDataForRender(data, constants.ROOT_NODE, maxRows, isPagination);
        }

        return isAnimated ? this.renderAnimatedTableBody(resultData) : <tbody>{resultData.map(this.renderRow)}</tbody>;
    }
}
