import { Alert, Button, IconCirclePlus, IconCross, Loader, Table, TableSearch, Tooltip } from '@adtech/ui';
import GoalForm from '@components/GoalForm';
import { dimensionsDict } from '@configs/dimensions';
import { EntityAccess } from '@configs/entity';
import { GoalFormType } from '@configs/goals/goalForm';
import { operationsReverseCnf } from '@configs/operations';
import useGoals from '@hooks/useGoals';
import { IFunnelStepGoal } from '@typings/funnels';
import { IConditionFilter, IGoalsConditionData, IGoalsTableData } from '@typings/goals';
import { top100Counter } from '@utils/index';
import cn from 'classnames';
import React, { useEffect, useState } from 'react';

import s from './FunnelSidebar.pcss';

interface IProps {
    isActive: boolean;
    data: IGoalsTableData[];
    dataRequest: boolean;
    selectedGoalsData: IFunnelStepGoal[];
    selectGoalsHandler: (goals: IFunnelStepGoal[]) => void;
    searchHandler: (value: string) => void;
    closeHandler: () => void;
}

export interface IGoalsTableDataType {
    key: React.Key;
    title: string;
    access: EntityAccess;
}

const FunnelSidebar: React.FC<IProps> = ({
    isActive,
    data,
    dataRequest,
    selectedGoalsData,
    searchHandler,
    closeHandler,
    selectGoalsHandler,
}) => {
    const [isSidebarFiltersActive, setSidebarFiltersActive] = useState(false);
    const [selectedRowKeys, setSelectedRowKeys] = useState<React.Key[]>(
        selectedGoalsData?.map((goal: IFunnelStepGoal) => goal.goal_id),
    );

    const { resetFormState } = useGoals();

    const sideBarClass = cn(s.funnelSidebar, { [s.funnelSidebarActive]: isActive });
    const sideBarFiltersClass = cn(s.funnelSidebarFilters, { [s.funnelSidebarFiltersActive]: isSidebarFiltersActive });
    const sideBarMaskClass = cn(s.funnelSidebarMask, { [s.funnelSidebarMaskActive]: isActive });

    useEffect(() => {
        setSelectedRowKeys(selectedGoalsData?.map((goal: IFunnelStepGoal) => goal.goal_id));
    }, [JSON.stringify(selectedGoalsData)]);

    const renderFilter = (filter: IConditionFilter) => {
        const { key, op, value, valuesTitles } = filter;
        const dict = dimensionsDict;
        const isEq = ['=', 'multilike', 'include'].includes(op);
        // Конвертация названий дименшинов
        const val = valuesTitles?.length ? valuesTitles.join(', ') : value;

        return (
            <div className={s.funnelSidebarTooltipText} key={key} data-testid="description-text">
                <span>
                    {dict[key]?.title}: {isEq ? '' : operationsReverseCnf[op]}
                </span>
                <span>{val}</span>
            </div>
        );
    };

    const renderTooltip = (conditions: IGoalsConditionData): React.ReactNode => {
        if (!conditions) return null;

        const { filters } = conditions;

        return (
            <div data-testid="description-container">
                <div data-testid="title-text">Условия:</div>
                {filters.map((filter) => renderFilter(filter))}
            </div>
        );
    };

    const tableColumns = [
        {
            dataIndex: 'title',
            render: ({ title, conditions }: { title: string; conditions: IGoalsConditionData }) =>
                conditions ? <Tooltip title={renderTooltip(conditions)}>{title}</Tooltip> : null,
        },
        {
            dataIndex: 'access',
            render: (access: EntityAccess) => (
                <div
                    className={cn(s.funnelSidebarTableGoal, {
                        [s.funnelSidebarTableGoalPublic]: access === EntityAccess.common,
                    })}
                    data-testid="condition-text"
                >
                    {access === EntityAccess.common ? 'Публичная' : 'Личная'}
                </div>
            ),
        },
    ];
    const prepareDataForRender = (item: IGoalsTableData) => {
        const { id, title, access, conditions } = item;

        return {
            key: id,
            id,
            title: { title, conditions },
            access,
        };
    };

    const rowSelection = {
        selectedRowKeys,
        onChange: (selectedRowValues: React.Key[]) => {
            setSelectedRowKeys(selectedRowValues);
        },
        getCheckboxProps: (record) => ({
            name: record.id,
        }),
        type: 'checkbox' as any,
    };

    const tableRowClickHandler = (record) => {
        const isChecked = selectedRowKeys.includes(record.key);
        const updatedSelectedRowKeys = isChecked
            ? selectedRowKeys.filter((key) => key !== record.key)
            : [...selectedRowKeys, record.key];

        setSelectedRowKeys(updatedSelectedRowKeys);
    };

    const selectGoals = () => {
        const selectedGoals = data
            ?.filter((goal) => selectedRowKeys.includes(goal.id))
            .map((goal) => ({
                goal_title: goal.title,
                goal_id: goal.id,
            }));
        selectGoalsHandler(selectedGoals);

        top100Counter.trackEvent('stat_funnel_save_n_exit_btn');
    };

    const filtersSidebarButtonsRender = (submitHandler: (reqParams) => void): React.ReactNode => (
        <div className={s.funnelSidebarFiltersActions}>
            <Button
                type="link"
                onClick={() => submitHandler({ showCommonGoals: true, limit: 100 })}
                data-testid="ок-button"
            >
                Сохранить и закрыть
            </Button>
        </div>
    );

    const closeFilterSidebar = () => {
        setSidebarFiltersActive(false);
        resetFormState();
    };

    const createGoalClickHandler = () => {
        setSidebarFiltersActive(true);
        top100Counter.trackEvent('stat_funnel_create_new_target_event_btn');
    };

    return (
        <div>
            <div className={sideBarMaskClass} />
            <div className={sideBarClass} data-testid="modalGoals-popUp">
                <div className={s.funnelSidebarHead}>
                    <div className={s.funnelSidebarTitle} data-testid="title-text">
                        Выбор целевых событий
                    </div>
                    <div className={s.funnelSidebarClose} onClick={closeHandler} data-testid="crossClose-button">
                        <IconCross />
                    </div>
                </div>
                <div className={s.funnelSidebarContent}>
                    <Alert
                        type="info"
                        message="Воронки — это публичный отчёт, поэтому все личные цели, используемые в шагах, станут публичными."
                        showIcon
                        data-testid="help-text"
                    />
                    <div className={s.funnelSidebarTableWrap}>
                        <div className={s.funnelSidebarTableTitle} data-testid="subtitle-text">
                            Мои события
                        </div>
                        <Button
                            prefixIcon={<IconCirclePlus color="currentColor" />}
                            type="link"
                            onClick={createGoalClickHandler}
                            size="small"
                            data-testid="add-button"
                        >
                            Создать
                        </Button>
                    </div>
                    <div className={s.funnelSidebarTable}>
                        <Loader loading={dataRequest}>
                            <div className={s.funnelSidebarTableSearch} data-testid="search-input">
                                <TableSearch
                                    onSearch={searchHandler}
                                    searchPlaceholder="Введите название целевого события"
                                />
                            </div>
                            <Table
                                columns={tableColumns}
                                dataSource={data?.map((goal: IGoalsTableData) => prepareDataForRender(goal))}
                                rowSelection={rowSelection}
                                onRow={(record) => ({
                                    onClick: () => tableRowClickHandler(record),
                                })}
                                scroll={{
                                    y: 'calc(100vh - 405px)',
                                }}
                                pagination={false}
                                showHeader={false}
                            />
                        </Loader>
                    </div>
                    <div className={s.funnelSidebarActions}>
                        <Button type="link" onClick={selectGoals} data-testid="ок-button">
                            Сохранить и закрыть
                        </Button>
                    </div>
                </div>
            </div>
            <div className={sideBarFiltersClass} data-testid="modalAddGoal-popUp">
                <div className={s.funnelSidebarFiltersHead}>
                    <div className={s.funnelSidebarFiltersTitle} data-testid="title-text">
                        Создание целевого события
                    </div>
                    <div
                        className={s.funnelSidebarFiltersClose}
                        onClick={closeFilterSidebar}
                        data-testid="crossClose-button"
                    >
                        <IconCross />
                    </div>
                </div>
                <div className={s.funnelSidebarFiltersContent}>
                    <div className={s.funnelSidebarFiltersAlert}>
                        <Alert
                            type="info"
                            message="Придумайте название цели и задайте ей необходимые условия."
                            showIcon
                            data-testid="help-text"
                        />
                    </div>
                    <div className={s.funnelSidebarFiltersForm}>
                        <GoalForm
                            title=""
                            type={GoalFormType.add}
                            customButtonsRender={filtersSidebarButtonsRender}
                            closePopup={closeFilterSidebar}
                        />
                    </div>
                </div>
            </div>
        </div>
    );
};

export default FunnelSidebar;
