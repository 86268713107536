import { ChartTypes } from '@configs/graph';
import { GroupBy } from '@configs/group';
import { sortOrders } from '@constants/sort';
import { IReportConfig } from '@typings/reports';

const reportConfig: IReportConfig = {
    reportTitle: 'Рейтинг',
    tableDataType: 'dynamic',
    tableTitle: 'Сайт',
    numberOfGraphs: 'two',
    defaultGraphs: [
        ChartTypes.line,
        ChartTypes.line,
    ],
    graphDefaultMetrics: [
        'position',
        'visitors',
    ],
    defaultSort: {
        name: 'visitors',
        order: sortOrders.DESC,
    },
    defaultGraphsGroup: GroupBy.day,
    disabledGraphs: {
        pie: true,
        bar: true,
        area: true,
    },
    tableHiddenMetrics: [
        'position',
    ],
    tableDefaultMetrics: [
        'position',
        'visitors',
        'page_views',
        'popularity',
        // 'visits', ждем бэк TODO
    ],
    availableMetrics: [
        'position',
        'visitors',
        'page_views',
        'popularity',
        // TODO: uid и rambler_id сейчас не применяются,
        // нужно будет разбираться, ждем бэк
        // 'visits',
        // 'uid',
        // 'rambler_id',
    ],
    disabledSortBy: [
        'position',
        'title',
    ],
    allowedSortBy: [
        sortOrders.DESC,
    ],
    isTableTitleSearch: true,
    isShowTotals: false,
    isShowCsv: false,
    availableTools: {
        numberOfGraphs: true,
        periodPicker: true,
        detail: true,
        disableDetail: true,
    },
    reportHint: {
        message: 'В данном разделе вы можете увидеть популярность вашего сайта и его позицию '
            + 'в рейтинге, а также сравнить ее с «соседями».',
    },
    nowDateDiff: -1,
    dateStart: 8,
    dateEnd: 2,
    isShowLinkWithTitle: true,
};

export default reportConfig;
