import { useAppDispatch } from '@hooks/useStore';
import { globalActions } from '@redux/slices/global';
import { useEffect } from 'react';

const useGlobalLoading = () => {
    const dispatch = useAppDispatch();

    useEffect(() => {
        dispatch(globalActions.setGlobalLoading(true));
    }, []);
};

export default useGlobalLoading;
