import serverErrorsConstants from '@constants/serverErrors';
import snackbarsConstants from '@constants/snackbars';
import formDecorator from '@decorators/formDecorator';
import { globalActions } from '@redux/slices/global';
import { projectsActions } from '@redux/slices/projects';
import { INotification } from '@typings/notification';
import { CounterFormComponent } from './CounterForm';

interface IData {
    url: string;
    title: string;
    // eslint-disable-next-line camelcase
    counter_settings: Record<string, any>;
}

/**
 * Функция получения данных для формы
 */
const getDataInitialize = (data: IData) => ({
    url: data.url,
    title: data.title,
    counterType: data.counter_settings.counter_type === 1,
    color: data.counter_settings.color,
    whitelistedUrls: data.counter_settings.whitelisted_urls,
    blacklistedUrls: data.counter_settings.blacklisted_urls,
    mergeSubdomains: data.counter_settings.merge_subdomains,
    useWhitelist: data.counter_settings.use_whitelist,
    mergeWww: !data.counter_settings.merge_www,
    // TODO: - use_blacklist нет в объекте
    useDomainBlacklist: data.counter_settings.blacklisted_urls.length > 0,
    useBlockAnalytics: data.counter_settings.use_block_analytics,
    useHashes: data.counter_settings.track_hashes,
    useUserId: data.counter_settings.track_user_id,
    blockAnalyticsAttribute: data.counter_settings.block_analytics_attribute || 'your-attribute',
});

const mapStateToProps = (state) => ({
    currentProject: state.projectsSlice.current,
    saveCounterRequest: state.projectsSlice.saveCounterRequest,
});

const mapDispatchToProps = (dispatch: any) => ({
    removeCounter: (id: number) =>
        dispatch(projectsActions.remove(id)).then(({ payload }) => {
            if (payload.responseStatus === 200) {
                const { DELETE_PROJECT_COUNTER } = snackbarsConstants.counterMessages;
                dispatch(globalActions.setSnackbar(DELETE_PROJECT_COUNTER));
            }
        }),
    setNotification(notification: INotification) {
        dispatch(globalActions.setNotification(notification));
    },

    clearNotification() {
        dispatch(globalActions.clearNotification());
    },
    onSubmit(values, invalid, currentProject, setServerError, initializeValues) {
        if (invalid) {
            return;
        }

        const data = {
            title: currentProject.in_rating ? currentProject.title : values.title,
            counter_settings: {
                block_analytics_attribute: values.blockAnalyticsAttribute,
                merge_subdomains: values.mergeSubdomains,
                track_hashes: values.useHashes,
                track_user_id: values.useUserId,
                use_block_analytics: values.useBlockAnalytics,
                blacklisted_urls: values.blacklistedUrls || [],
                merge_www: !values.mergeWww,
                use_whitelist: values.useWhitelist,
                whitelisted_urls: values.whitelistedUrls,
                color: values.color,
                counter_type: Number(values.counterType),
            },
        };

        /**
         * Отправляем запрос на сохранение изменений настроек счётчика
         */
        dispatch(projectsActions.save({ id: currentProject.id, data })).then(({ payload }) => {
            if (payload.responseStatus === 200) {
                dispatch(globalActions.setSnackbar(snackbarsConstants.SAVE_CHANGES));
                window.scrollTo(0, 0);
                initializeValues(getDataInitialize(payload.data));
            } else if (payload.errors && payload.errors.message) {
                if (payload.errors.message === serverErrorsConstants.REQUEST_API_ERROR) {
                    setServerError('Произошла ошибка при запросе на сервер.');
                }
            }
        });
    },

    setSnackbar(message: string, type: string) {
        dispatch(globalActions.setSnackbar({ message, type }));
    },

    openModalPopup(modalPopupId, modalPopupParams = {}) {
        dispatch(globalActions.openModalPopup({ modalPopupId, modalPopupParams }));
    },
});

export const CounterForm = formDecorator({
    formName: 'counterSettings',
    isRemoveForm: false,
    initializeValues(state) {
        const currentProject = state.projectsSlice.current;
        return getDataInitialize(currentProject);
    },
    mapStateToProps,
    mapDispatchToProps,
})(CounterFormComponent);
