import { Metrics } from '@typings/metrics';
import DateUtils from '@utils/date';
import isArray from 'lodash/isArray';
import measuresUtils from './measures';
import numbersUtils from './numbersUtils';

class WidgetUtils {
    // public

    /**
     * Приводим значение к формату указанной метрики
     */
    digitalsStringify = (int: number, metricName: Metrics, optionString: string = ''): string | number => {
        const config = measuresUtils.getConfig(metricName);

        let result: string | number = !Number.isFinite(int) ? 0 : int;

        switch (config.format) {
            case 'time':
                result = DateUtils.secondsToFormat(Math.abs(Math.round(result)));
                break;
            case 'percent':
                result = int === null ? 'NA' : `${result.toString().replace('.', ',')}%`;
                break;
            case 'float':
                result = result ? Math.abs(result).toFixed(2).toString().replace('.', ',') : '0';
                break;
            default: {
                const tmp = Math.abs(numbersUtils.toNumber(result) ? parseInt(String(result), 10) : 0);
                result = `${numbersUtils.numberFormat(tmp)}${optionString}`;
            }
        }

        return result;
    };

    /**
     * Подготавливаем данные к дальнейшему использованию
     */
    prepareDiffData = (value: number, metricName: Metrics): string | number => {
        const config = measuresUtils.getConfig(metricName);

        let result = null;

        switch (config.format) {
            case 'time':
                result = DateUtils.secondsToFormat(Math.abs(Math.round(value)));
                break;
            case 'number':
                result = Math.floor(value);
                break;
            default:
                result = numbersUtils.roundDiff(value);
        }

        return result;
    };

    /**
     * Получаем индикатор разницы для метрики - либо up, либо down
     */
    // TODO: типы из widgets.d.ts
    getDiffDirection = (diff: any[] = []): string => {
        let result = 'up';

        if (diff.length) {
            if (diff[0] === '-') {
                result = 'down';
            } else if (diff[0] === '+') {
                result = 'up';
            }
        }

        return result;
    };

    static replaceEmptyValue(data: [string, number][]) {
        return data.map((item: [string, number]) => {
            const [title, rest] = item;

            return title ? item : ['Нет значения', rest];
        });
    }

    /**
     * Трансформируем данные
     */
    dataTransform = (result, oldState = {}) => {
        const newData = {};

        Object.keys(result).forEach((key) => {
            const widget = result[key];
            const selected = widget.metrics && widget.metrics[0];

            const isArrayData = isArray(widget.data[0]);

            const ownData = {
                error: !!widget.errors || !widget.data.length,
                allMetrics: widget.all_metrics || [],
                metrics: widget.metrics || [],
                sort: widget.sort || {},
                data: isArrayData ? WidgetUtils.replaceEmptyValue(widget.data) : widget.data,
                loading: widget.loading,
                ...(selected ? { selected } : {}),
                ...(widget.dataTotals ? { dataTotals: widget.dataTotals } : {}),
                ...(widget.period ? { period: widget.period } : {}),
            };

            newData[key] = { ...oldState[key], ...ownData };
        });

        return newData;
    };
}

export default new WidgetUtils();
