import Dropdown from '@components/Dropdown';
// import attributesData from '@configs/attributesData'; FIXME добавить разметку аналитикой блоков
import { ReportName } from '@typings/reports';
import React from 'react';

export enum EAttributionModel {
    lastClick = 'last-click',
    firstClick = 'first-click',
    lastValuable = 'last-valuable',
    sberads = 'sberads',
}

export const attributionModelData = [
    {
        title: 'Последний переход',
        value: EAttributionModel.lastClick,
    },
    {
        title: 'Первый переход',
        value: EAttributionModel.firstClick,
    },
    {
        title: 'Последний значимый переход',
        value: EAttributionModel.lastValuable,
    },
    {
        title: 'Последний переход из SberAds',
        value: EAttributionModel.sberads,
    },
];

interface IProps {
    className?: string;
    onChangeAttributionModel: (val: string) => void;
    selected: string;
    isDisabled?: boolean;
    reportName: ReportName;
}

const AttributionModel: React.FC<IProps> = ({
    className = '',
    onChangeAttributionModel,
    selected,
    isDisabled,
    reportName,
}) => (
    <Dropdown
        type="selectSingle"
        anchorText="Модель атрибуции"
        anchorTextOutside
        data={attributionModelData}
        anchorClassName={className}
        dataInitialSelected={selected || EAttributionModel.lastClick}
        isDisabled={isDisabled}
        onApply={onChangeAttributionModel}
        reportName={reportName}
    />
);

export default AttributionModel;
