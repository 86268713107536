import { Tooltip, IconCalendar, Button } from '@adtech/ui';
import DateModal from '@components/DateModal';
import Dropdown from '@components/Dropdown';
import PeriodDetail from '@components/PeriodDetail';

import attributesData from '@configs/attributesData';
import groupParams, { GroupBy } from '@configs/group';

import { EPeriodDetail } from '@constants/periodDetail';
import { IAvailableTools, IPeriodDetailData } from '@typings/availableTools';
import { DateLiteral, DateType } from '@typings/date';
import { IUpdateMediaParams } from '@typings/media';
import { IUpdateReportOptions, IUpdateReportParams, ReportName } from '@typings/reports';
import DateUtils from '@utils/date';

import cn from 'classnames';
import React, { useState } from 'react';

import s from './Tools.pcss';

interface IProps {
    getTypeRequestReport: (reportName: ReportName, updateParam?: string) => string;
    minDate: DateType;
    maxDate: DateType;
    dateStart: DateType;
    dateEnd: DateType;
    serverTime: number;
    isExcludedLatency: boolean;
    groupBy: GroupBy;
    reportName: ReportName;
    updateReport: (
        type: string,
        options: IUpdateReportOptions<IUpdateReportParams | IUpdateMediaParams, ReportName>,
    ) => void;
    availableTools: IAvailableTools;
    className?: string;
    getLatency: () => void;
    nowDateDiff?: number;
    isOnlyToday?: boolean;
    periodDetail?: EPeriodDetail;
    periodDetailData?: IPeriodDetailData[];
    isPeriodDetailDisabled?: boolean;
    changePeriodDetailHandler?: (value: string, group?: GroupBy) => GroupBy;
    handleChangeDate?: (date: DateType[], relativeDate: DateLiteral) => void;
}

const ReportPeriodPicker: React.FC<IProps> = ({
    getTypeRequestReport,
    minDate,
    maxDate,
    dateStart,
    dateEnd,
    serverTime,
    isExcludedLatency,
    groupBy,
    reportName,
    updateReport,
    availableTools,
    className = '',
    getLatency,
    nowDateDiff,
    isOnlyToday,
    periodDetail,
    periodDetailData,
    isPeriodDetailDisabled,
    changePeriodDetailHandler,
    handleChangeDate,
}) => {
    const [isOpened, setIsOpened] = useState(false);

    const [start, end] = DateUtils.convertDates(dateStart, dateEnd);

    const groupItems = DateUtils.getGroupFromPeriod([DateUtils.getDate(start), DateUtils.getDate(end)], periodDetail);

    const containerClass = cn(s.period, className);
    const periodClass = cn(s.periodLabel, {
        [s.disabled]: isOnlyToday,
    });

    const toolsAttr = attributesData.newreport.tools;
    const setDateAttr = `${toolsAttr.attrName}::${toolsAttr.period}::${reportName}`;
    const detailAttr = [toolsAttr.attrName, toolsAttr.detail];
    const dataAttrList = [toolsAttr.attrName, toolsAttr.setDetail];

    const dataDetail = groupItems.map((item) => ({ value: item, title: groupParams.title[item] }));

    const handleChangeGroupBy = (value: GroupBy) => {
        const updateParams = { groupBy: value };

        const type = getTypeRequestReport(reportName, 'groupBy');

        updateReport(type, { updateParams });
    };

    const onChangePeriodDetail = (value: EPeriodDetail) => {
        let group;

        if (value === EPeriodDetail.oneHour || (value !== EPeriodDetail.thirtyMinutes && groupBy === GroupBy.minute)) {
            group = GroupBy.fiveMinute;
        }

        if (changePeriodDetailHandler) {
            group = changePeriodDetailHandler(value, group);
        }

        const updateParams = {
            periodDetail: value,
            ...(group ? { groupBy: group } : {}),
        };
        updateReport('all', { updateParams });
    };

    const openModal = () => {
        setIsOpened(true);
    };

    const closeModal = () => setIsOpened(false);

    const renderButtom = (
        <div className={periodClass} data-newreport={setDateAttr} data-testid="date-picker">
            <span className={s.mainParamsItemTitle}>Период: </span>
            <Button
                className={s.datePickerButton}
                size="middle"
                suffixIcon={<IconCalendar />}
                type="dashed"
                data-testid="period-button"
                onClick={openModal}
            >
                {DateUtils.readablePeriod(DateUtils.getDate(start), DateUtils.getDate(end))}
            </Button>
        </div>
    );

    return (
        <div className={containerClass} data-testid="period-picker">
            {/* TODO: убрать лишнее условие после правок в @adtech/ui,
                в правке должно быть добавлено условие: не отображать tooltip, если нет контента (title)
            */}
            {isOnlyToday ? (
                <Tooltip title="Доступен просмотр только за сегодня" placement="topLeft">
                    {renderButtom}
                </Tooltip>
            ) : (
                renderButtom
            )}

            <DateModal
                defaultDate={{ dateStart: start, dateEnd: end }}
                serverTime={serverTime}
                onChange={handleChangeDate}
                onClose={closeModal}
                minDate={minDate}
                maxDate={maxDate}
                nowDateDiff={nowDateDiff}
                isOpened={isOpened || isExcludedLatency}
                isExcludedLatency={isExcludedLatency}
                getLatency={getLatency}
            />

            {availableTools.detail && (
                <Dropdown
                    type="selectSingle"
                    anchorText="Детализация: "
                    dataTestId="dropdown-container"
                    btnDataTestId="detalization-button"
                    anchorTextOutside
                    isDisabled={availableTools.disableDetail}
                    initialSelected={groupParams.title[groupBy]}
                    data={dataDetail}
                    dataInitialSelected={groupBy || groupItems[0]}
                    onApply={handleChangeGroupBy}
                    reportName={reportName}
                    dataAttrName={attributesData.newreport.attrName}
                    dataAttrAnchor={detailAttr}
                    dataAttrList={dataAttrList}
                />
            )}

            {availableTools.periodDetail && (
                <PeriodDetail
                    reportName={reportName}
                    onChange={onChangePeriodDetail}
                    selected={periodDetail}
                    periodDetailData={periodDetailData}
                    disabled={isPeriodDetailDisabled}
                />
            )}
        </div>
    );
};

export default ReportPeriodPicker;
