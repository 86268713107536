import React from 'react';
/* eslint-disable */
export const ImageCounter = () => (
    <svg width="256" height="256" viewBox="0 0 256 256" fill="none" xmlns="http://www.w3.org/2000/svg">
        <g opacity="0.8" filter="url(#filter0_f_2055_25116)">
        <path d="M84.3246 105.484C87.4091 103.52 92.6496 103.394 95.8753 105.207L238.679 185.468C241.777 187.209 241.847 190.09 238.835 191.95L199.706 216.121C196.566 218.061 191.316 218.137 188.142 216.289L48.3226 134.874C45.3493 133.143 45.2867 130.343 48.1801 128.501L84.3246 105.484Z" fill="url(#paint0_linear_2055_25116)"/>
        </g>
        <path d="M74.7693 35.8005C76.8346 34.6546 79.5998 34.9196 82.492 36.5408L212.681 109.515C218.967 113.038 224.111 121.862 224.111 129.12L224.111 195.491C224.111 198.823 223.004 201.389 221.017 202.659L212.62 208.029C210.487 209.393 207.521 209.147 204.421 207.349L74.4036 131.914C68.1928 128.311 63.166 119.59 63.166 112.418L63.166 47.7016C63.166 44.2072 64.3836 41.5628 66.5444 40.3639L74.7693 35.8005Z" fill="url(#paint1_linear_2055_25116)"/>
        <mask id="mask0_2055_25116" style={{maskType: 'alpha'}} maskUnits="userSpaceOnUse" x="63" y="39" width="154" height="171">
        <rect x="0.35204" y="0.609751" width="175.683" height="90.5938" rx="12.6015" transform="matrix(0.866025 0.5 -2.20305e-08 1 63.2111 34.5801)" fill="url(#paint2_linear_2055_25116)" stroke="url(#paint3_linear_2055_25116)" strokeWidth="0.813001"/>
        </mask>
        <g mask="url(#mask0_2055_25116)">
        <rect width="175.608" height="91.3819" rx="13.008" transform="matrix(0.866025 0.5 -2.20305e-08 1 63.1639 34.7562)" fill="url(#paint4_linear_2055_25116)"/>
        <path d="M180.738 167.32C181.798 164.413 183.447 163.299 185.258 163.342C187.072 163.384 189.37 164.594 192.027 167.23C194.461 169.644 196.243 173.395 196.564 176.734C196.901 180.235 196.313 181.954 195.182 182.779C193.966 183.665 191.822 183.746 188.333 182.745C188.219 182.709 188.108 182.685 188 182.672C187.368 182.595 186.897 182.915 186.685 183.5C186.597 183.741 186.553 184.028 186.56 184.35C186.566 184.672 186.623 185.013 186.722 185.358C186.964 186.193 187.459 187.043 188.102 187.667C188.211 187.774 188.324 187.872 188.439 187.963C191.974 190.828 194.833 194.033 196.624 197.266C198.33 200.347 199.264 203.801 198.614 207.649C197.912 211.804 194.792 213.781 190.962 212.402C187.779 211.256 185.213 209.012 183.26 206.184C181.269 203.301 179.702 199.498 178.907 195.062C178.624 193.483 177.401 191.894 176.175 191.513C174.949 191.132 174.184 192.103 174.467 193.682C175.441 199.119 177.475 204.397 180.516 208.801C183.596 213.26 187.493 216.508 191.805 218.06C197.608 220.149 202.088 217.157 203.101 211.159C203.98 205.955 202.721 200.593 199.944 195.579C198.701 193.335 197.199 191.24 195.539 189.31C196.715 189.196 197.744 188.856 198.596 188.235C200.811 186.62 201.533 183.341 201.09 178.733C200.586 173.497 197.78 167.346 193.567 163.167C190.015 159.643 186.42 157.533 183.287 157.46C180.151 157.386 177.8 159.361 176.434 163.107C176.02 164.241 176.649 166.103 177.837 167.266C179.026 168.43 180.324 168.454 180.738 167.32Z" fill="url(#paint5_linear_2055_25116)"/>
        <path d="M195.292 106.164L178.169 126.147C177.859 126.508 178.194 127.423 178.756 127.747L200.229 140.145" stroke="url(#paint6_linear_2055_25116)" strokeWidth="4.87801" strokeLinecap="round"/>
        </g>
        <rect x="2.11224" y="3.65851" width="22.764" height="43.9021" rx="11.382" transform="matrix(0.866025 0.5 -2.20305e-08 1 76.8249 62.5612)" stroke="url(#paint7_linear_2055_25116)" strokeWidth="4.87801"/>
        <rect width="0.813001" height="91.0561" transform="matrix(0.866025 0.5 -2.20305e-08 1 113.153 63.6173)" fill="url(#paint8_linear_2055_25116)"/>
        <rect width="0.813001" height="91.0561" transform="matrix(0.866025 0.5 -2.20305e-08 1 163.143 92.479)" fill="url(#paint9_linear_2055_25116)"/>
        <path fillRule="evenodd" clipRule="evenodd" d="M145.543 105.893C145.543 104.546 144.598 102.908 143.431 102.235L134.982 97.3568C133.815 96.6833 132.87 97.2293 132.87 98.5763C132.87 99.9233 133.815 101.561 134.982 102.235L141.319 105.893L141.319 147.357C141.319 148.704 142.264 150.342 143.431 151.015C144.597 151.689 145.543 151.143 145.543 149.796L145.543 105.92C145.543 105.911 145.543 105.902 145.543 105.893Z" fill="url(#paint10_linear_2055_25116)"/>
        <rect opacity="0.5" width="105.95" height="6.85795" rx="3.12705" transform="matrix(0.866025 0.5 -0.866025 0.5 86.1377 177.225)" fill="#D9D9D9"/>
        <rect opacity="0.5" width="54.4919" height="6.85795" rx="3.12705" transform="matrix(0.866025 0.5 -0.866025 0.5 74.9941 184.569)" fill="#D9D9D9"/>
        <path d="M69.0355 173.327C58.7468 179.646 41.5658 179.61 31.0091 173.44C25.1466 170.014 23.9559 166.489 23.167 163.173C22.0487 158.472 24.3112 153.049 30.5707 149.435C41.1921 143.303 58.4141 144.988 69.0355 151.12C73.6201 153.767 76.5506 155.76 77.1774 159.214C77.1774 163.373 76.9507 168.466 69.0355 173.327Z" fill="url(#paint11_linear_2055_25116)"/>
        <ellipse cx="22.2069" cy="22.2069" rx="22.2069" ry="22.2069" transform="matrix(0.866025 0.5 -0.866025 0.5 50.0771 138.261)" fill="url(#paint12_linear_2055_25116)"/>
        <path d="M41.2394 156.158L41.2394 163.142C41.2394 163.312 41.4879 163.446 41.7818 163.436L63.0627 162.672" stroke="url(#paint13_linear_2055_25116)" strokeWidth="3.12705" strokeLinecap="round"/>
        <defs>
        <filter id="filter0_f_2055_25116" x="37.9206" y="95.7949" width="211.258" height="129.962" filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
        <feFlood floodOpacity="0" result="BackgroundImageFix"/>
        <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape"/>
        <feGaussianBlur stdDeviation="4.06501" result="effect1_foregroundBlur_2055_25116"/>
        </filter>
        <linearGradient id="paint0_linear_2055_25116" x1="110.704" y1="171.165" x2="134.764" y2="127.766" gradientUnits="userSpaceOnUse">
        <stop stopColor="#434677" stopOpacity="0"/>
        <stop offset="0.508107" stopColor="#5A3788"/>
        <stop offset="1" stopColor="#712899" stopOpacity="0"/>
        </linearGradient>
        <linearGradient id="paint1_linear_2055_25116" x1="66.6104" y1="30.1393" x2="103.356" y2="158.375" gradientUnits="userSpaceOnUse">
        <stop stopColor="#F1CEFF"/>
        <stop offset="0.046875" stopColor="#D88CFA"/>
        <stop offset="0.878665" stopColor="#EA85FF"/>
        <stop offset="0.949484" stopColor="#9A38C6"/>
        <stop offset="1" stopColor="#611783"/>
        </linearGradient>
        <linearGradient id="paint2_linear_2055_25116" x1="88.2479" y1="-2.4484" x2="88.2479" y2="91.4068" gradientUnits="userSpaceOnUse">
        <stop stopColor="#1BFFC6"/>
        <stop offset="1" stopColor="white"/>
        </linearGradient>
        <linearGradient id="paint3_linear_2055_25116" x1="243.317" y1="40.8654" x2="214.559" y2="-26.4485" gradientUnits="userSpaceOnUse">
        <stop stopColor="white"/>
        <stop offset="1" stopColor="#3BFFCE"/>
        </linearGradient>
        <linearGradient id="paint4_linear_2055_25116" x1="194.571" y1="0.917178" x2="91.4308" y2="167.507" gradientUnits="userSpaceOnUse">
        <stop offset="0.0614654" stopColor="#A308BC"/>
        <stop offset="1" stopColor="#006E47"/>
        </linearGradient>
        <linearGradient id="paint5_linear_2055_25116" x1="188.893" y1="159.378" x2="164.062" y2="202.386" gradientUnits="userSpaceOnUse">
        <stop stopColor="#01FFBF"/>
        <stop offset="1" stopColor="white" stopOpacity="0.67"/>
        </linearGradient>
        <linearGradient id="paint6_linear_2055_25116" x1="188.837" y1="102.437" x2="175.357" y2="125.785" gradientUnits="userSpaceOnUse">
        <stop stopColor="#01FFBF"/>
        <stop offset="1" stopColor="white" stopOpacity="0.67"/>
        </linearGradient>
        <linearGradient id="paint7_linear_2055_25116" x1="13.821" y1="0" x2="13.821" y2="48.7801" gradientUnits="userSpaceOnUse">
        <stop stopColor="#01FFBF"/>
        <stop offset="1" stopColor="white" stopOpacity="0.67"/>
        </linearGradient>
        <linearGradient id="paint8_linear_2055_25116" x1="0.406501" y1="0" x2="0.406501" y2="91.0561" gradientUnits="userSpaceOnUse">
        <stop stopColor="#01FFBF"/>
        <stop offset="1" stopColor="white" stopOpacity="0.67"/>
        </linearGradient>
        <linearGradient id="paint9_linear_2055_25116" x1="0.406501" y1="0" x2="0.406501" y2="91.0561" gradientUnits="userSpaceOnUse">
        <stop stopColor="#01FFBF"/>
        <stop offset="1" stopColor="white" stopOpacity="0.67"/>
        </linearGradient>
        <linearGradient id="paint10_linear_2055_25116" x1="139.206" y1="99.796" x2="118.084" y2="136.381" gradientUnits="userSpaceOnUse">
        <stop stopColor="#01FFBF"/>
        <stop offset="1" stopColor="white" stopOpacity="0.67"/>
        </linearGradient>
        <linearGradient id="paint11_linear_2055_25116" x1="48.7304" y1="141.704" x2="44.0547" y2="164.811" gradientUnits="userSpaceOnUse">
        <stop stopColor="#F1CEFF"/>
        <stop offset="0.046875" stopColor="#D88CFA"/>
        <stop offset="0.878665" stopColor="#EA85FF"/>
        <stop offset="0.949484" stopColor="#9A38C6"/>
        <stop offset="1" stopColor="#611783"/>
        </linearGradient>
        <linearGradient id="paint12_linear_2055_25116" x1="49.2097" y1="0.445771" x2="-5.95675" y2="46.8132" gradientUnits="userSpaceOnUse">
        <stop offset="0.0614654" stopColor="#A308BC"/>
        <stop offset="1" stopColor="#006E47"/>
        </linearGradient>
        <linearGradient id="paint13_linear_2055_25116" x1="52.118" y1="155.46" x2="46.7537" y2="167.157" gradientUnits="userSpaceOnUse">
        <stop stopColor="#01FFBF"/>
        <stop offset="1" stopColor="white" stopOpacity="0.67"/>
        </linearGradient>
        </defs>
    </svg>
);
