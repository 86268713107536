import IconSimpleArrow from '@images/svg/icons/icon-simple-arrow.svg';
import cn from 'classnames';
import React, { useCallback, useState } from 'react';
import List from './List';
import s from './List.pcss';

interface IProps {
    title: string;
    data: Array<any>;
    depth: number;
    isExpanded?: boolean;
    renderItem: ({ depth, children, item }) => JSX.Element;
}

const ListItemNested: React.FC<IProps> = ({ title, data, depth, isExpanded = false, renderItem }) => {
    const [expanded, setExpanded] = useState(isExpanded);
    const handleClick = useCallback(() => setExpanded(!expanded), [expanded]);
    const titleClass = cn(s.listItemTitle, { [s.expanded]: expanded });

    return (
        <>
            <span className={titleClass} onClick={handleClick}>
                <span className={s.listItemTitleText} data-atst="list_item_with_nested_title">
                    {title}
                </span>
                <IconSimpleArrow className={s.listItemTitleArrowIcon} />
            </span>
            {expanded ? <List data={data} depth={depth + 1} renderItem={renderItem} /> : null}
        </>
    );
};

export default ListItemNested;
