export type OrdersType = 'asc' | 'desc';

export const sortOrders: Record<string, OrdersType> = {
    ASC: 'asc',
    DESC: 'desc',
};

export const sortDisabledMessage: Record<string, string> = {
    ASC: 'Сортировка возможна только по возрастанию',
    DESC: 'Сортировка возможна только по убыванию',
};
