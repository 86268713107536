import { staticFilters } from '@configs/filters/filtersConfigEventBased';
import { staticFilters as staticFiltersMedia } from '@configs/filters/filtersConfigMedia';
import { useAppDispatch } from '@hooks/useStore';
import { formsActions } from '@redux/slices/forms';
import { globalActions } from '@redux/slices/global';
import { Dimensions } from '@typings/dimensions';
import { IMediaRequestParams } from '@typings/media';
import { IReportRequestParams, IUpdateReportParams } from '@typings/reports';
import { IRootSlice } from '@typings/rootSlice';
import {
    formatFilters,
    getDynamicFiltersNames,
    setDynamicDataToFilter,
    formatDynamicData,
    getCorrectToConfigFilters,
} from '@utils/filtersUtils';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';

const useFilters = () => {
    const params: IUrlParams = useParams();
    const { projectId } = params;

    const dispatch = useAppDispatch();

    const globalSlice = useSelector((state: IRootSlice) => state.globalSlice);
    const stateDynamicFiltersData = useSelector((state: IRootSlice) => state.globalSlice.dynamicFiltersData);

    const api = {
        // Очистка фильтров
        clearFilters(): void {
            dispatch(formsActions.resetForm({ formName: 'reportFilters' }));
            dispatch(globalActions.updateParams({ globalFilters: {} }));
        },

        // Сброс фильтров
        resetFilters(): void {
            dispatch(formsActions.removeForm('reportFilters'));
            dispatch(globalActions.updateParams({ globalFilters: {} }));
        },

        // Форматируем и обновляем данные применяемого фильтра
        // TODO: Покрыть метод тестами
        async updateApplyedFilterParams(
            updateParams: IUpdateReportParams,
            requestParams?: IReportRequestParams | IMediaRequestParams,
            isMedia: boolean = false,
        ): Promise<IUpdateReportParams> {
            if (!updateParams?.globalFilters?.filtersRequest) {
                return updateParams;
            }

            let newGlobalFilters = { ...updateParams.globalFilters };

            // Откидываем фильтры, которые не могут быть применены на текущем отчёте
            let configFilters = staticFilters;

            if (isMedia) {
                configFilters = staticFiltersMedia;
            }

            newGlobalFilters = getCorrectToConfigFilters(newGlobalFilters, configFilters);

            if (!newGlobalFilters.filtersRequest.length) {
                return { ...updateParams, globalFilters: {} };
            }

            if (!newGlobalFilters.selectedData) {
                // Форматируем фильтр из query параметров (добавляем SelectedData)
                newGlobalFilters = formatFilters(newGlobalFilters.filtersRequest);
            }
            const dynamicFiltersNames = getDynamicFiltersNames(newGlobalFilters);

            if (dynamicFiltersNames?.length) {
                // Отправляем запрос за необходимыми динамическими фильтрами
                // Которых еще нет в dynamicFiltersData
                const dynamicFilters = dynamicFiltersNames
                    .filter((filter) => !Object.keys(stateDynamicFiltersData).includes(filter))
                    .map((filterName) => api.getDynamicFilters(filterName, [], 0, 50, requestParams));

                let dynamicFiltersData = { ...stateDynamicFiltersData };

                await Promise.all(dynamicFilters)
                    .then((resp) => resp.filter(({ payload }) => payload.responseStatus === 200))
                    .then((results) => {
                        if (!results?.length) return;

                        // Форматируем полученые динамические фильтры
                        results.forEach((action) => {
                            const {
                                params: { parents },
                                filterName,
                            } = action.meta?.arg || {};
                            const { meta } = action.payload || {};

                            dynamicFiltersData[filterName] = {
                                ...(dynamicFiltersData[filterName] || {
                                    loaded: [],
                                    data: [],
                                    metrics: [],
                                }),
                            };

                            dynamicFiltersData = formatDynamicData(
                                filterName,
                                action.payload,
                                parents,
                                meta,
                                dynamicFiltersData,
                            );
                        });
                    });

                // Подмешиваем динамические фильтры в применяемый фильтр
                const filterWithDynamicData = setDynamicDataToFilter(
                    newGlobalFilters,
                    dynamicFiltersNames,
                    dynamicFiltersData,
                );

                newGlobalFilters = filterWithDynamicData;
            }

            return { ...updateParams, globalFilters: newGlobalFilters };
        },

        // Выполняем запрос для получения значений динамического фильтра
        getDynamicFilters(
            dimension: Dimensions,
            parents: number[] = [],
            offset: number = 0,
            limit: number = 50,
            requestParams?: IReportRequestParams | IMediaRequestParams,
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
        ): any {
            return dispatch(
                globalActions.getDynamicFilters({
                    projectId,
                    filterName: dimension,
                    params: {
                        date_start: requestParams?.date_start || globalSlice.dateStart,
                        date_end: requestParams?.date_end || globalSlice.dateEnd,
                        group: requestParams?.group || globalSlice.groupBy,
                        parents,
                        offset,
                        limit,
                    },
                }),
            );
        },

        // Обертка getDynamicFilters для более удобного вызова для eventbased
        getDynamicFiltersEventBased(
            dimension: Dimensions,
            parents: number[] = [],
            offset: number = 0,
            limit: number = 50,
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
        ): any {
            return api.getDynamicFilters(dimension, parents, offset, limit);
        },
    };

    return api;
};

export default useFilters;
