import Dropdown from '@components/Dropdown';
import attributesData from '@configs/attributesData';
import IconSettings from '@images/svg/icons/icon-settings.svg';

import { Metrics } from '@typings/metrics';
import { ReportName } from '@typings/reports';
import { measuresUtils } from '@utils/index';
import React from 'react';
import s from '../../ReportTable.pcss';

interface IProps {
    reportName: ReportName;
    metrics: Metrics[];
    allMetrics: Metrics[];
    onNewMetricSelect;
    metricsLimit?: number | null;
    utils: TAPI;
}

const DropdownIndicators: React.FC<IProps> = ({
    reportName,
    metrics,
    allMetrics,
    onNewMetricSelect,
    metricsLimit,
    utils,
}) => {
    const list = [];
    const selected = [...metrics];
    const newreportAttr = attributesData.newreport;
    const fixedMetrics = utils.getParamFromConfig(reportName, 'tableFixedMetrics') || [];

    // Убираем скрытые и "прибитые" метрики из дропдауна
    allMetrics.forEach((item) => {
        if (!utils.canHideIndicator(reportName, item) && !fixedMetrics.includes(item)) {
            list.push({
                value: item,
                title: measuresUtils.getTitle(item, reportName),
            });
        } else {
            const indexItem = selected.indexOf(item);
            if (indexItem !== -1) selected.splice(indexItem, 1);
        }
    });

    return (
        list.length > 0 && (
            <Dropdown
                type="selectMultiple"
                anchorClassName={s.tableCellAddMetrics}
                isAnchorButton={false}
                isShowArrow={false}
                isShowSelected={false}
                anchorPrefixIcon={<IconSettings />}
                data={list}
                dataInitialSelected={selected}
                onApply={onNewMetricSelect}
                reportName={reportName}
                dataAttrName={newreportAttr.attrName}
                dataAttrAnchor={[
                    newreportAttr.table.attrName,
                    newreportAttr.table.tools.attrName,
                    newreportAttr.table.tools.settingsMetrics,
                ]}
                dataAttrList={[
                    newreportAttr.table.attrName,
                    newreportAttr.table.tools.attrName,
                    newreportAttr.table.tools.setMetricAttr,
                ]}
                params={{
                    limit: metricsLimit,
                    isOneValueAllowed: !!fixedMetrics.length,
                }}
            />
        )
    );
};

export default DropdownIndicators;
