export const steps = [
    {
        title: 'Создайте счётчик',
        name: 'createCounter',
    },
    {
        title: 'Настройте параметры и добавьте код на сайт',
        name: 'settingsCounter',
    },
];
