import { Tooltip } from '@adtech/ui';
import { Dimensions } from '@typings/dimensions';
import { measuresUtils } from '@utils/index';
import cn from 'classnames';
import React from 'react';
import { useParams } from 'react-router-dom';

import s from '../../ReportTable.pcss';

interface IProps {
    indicator: Dimensions;
}

const DimensionTitle: React.FC<IProps> = ({ indicator }) => {
    const params: IUrlParams = useParams();
    const { reportName } = params;

    const className = cn(s.tableTitle, {
        [s.tableTitleOnGraph]: false,
        [s.tableTitleDisableSort]: true,
    });

    const tooltip = measuresUtils.getTooltip(indicator, reportName);
    const label = measuresUtils.getTitle(indicator, reportName);

    const newLabel = <span>{label}</span>;

    return (
        <div className={className}>
            <Tooltip title={tooltip} className={s.tableTitleTooltip}>
                {newLabel}
            </Tooltip>
        </div>
    );
};

export default DimensionTitle;
