import { Chart } from '@adtech/charts';
import { ChartGroupBy } from '@adtech/charts/dist/config';
import { Loader, Tooltip } from '@adtech/ui';

import { Hint } from '@components/Hint';
import { graphKeys } from '@configs/funnels/graph';
import { ChartTypes } from '@configs/graph';
import { IFunnelTableData } from '@typings/funnels';
import { IRootSlice } from '@typings/rootSlice';
import { top100Counter } from '@utils/index';
import NumbersUtils from '@utils/numbersUtils';
import cn from 'classnames';
import React from 'react';
import { useSelector } from 'react-redux';
import s from './FunnelGraph.pcss';

interface IProps {
    data: IFunnelTableData[];
    isLegendActive: boolean;
    setLegendActive: (active: boolean) => void;
}

const FunnelGraph: React.FC<IProps> = ({ data, isLegendActive, setLegendActive }) => {
    const tableRequest = useSelector((state: IRootSlice) => state.funnelsSlice.tableRequest);
    const graphDataMaxVal = useSelector((state: IRootSlice) => state.funnelsSlice.graphDataMaxVal);

    const STEP_WIDTH = 200;
    const format = NumbersUtils.formatValue;

    // Слишком маленькие значения не выводим
    const formatConversion = (value) => {
        if (!Number.isFinite(value)) return null;

        return value > 0 && value < 0.01 ? '< 0.01' : format(value, 'percent', 2, false);
    };

    // Данные для посетителей с пред. и не с пред. шага
    const dataSumGraph = data.map(({ stepTitle, graphMetrics }, i) => ({
        stepTitle,
        [graphKeys.prevStep]: graphMetrics.visitorsPrevStep,
        [graphKeys.notPrevStep]: graphMetrics.visitorsNotPrevStep,
        [graphKeys.sum]: graphMetrics.visitorsSum,
        conversion: i + 1 < data.length ? formatConversion(data[i + 1].graphMetrics.convPrevStepSum) : null,
    }));

    // Данные для посетителей только с пред. шага
    const dataGraph = data.map(({ stepTitle, graphMetrics }, i) => ({
        stepTitle,
        [graphKeys.prevStep]: graphMetrics.visitorsPrevStep,
        [graphKeys.notPrevStep]: null,
        [graphKeys.sum]: graphMetrics.visitorsPrevStep,
        conversion: i + 1 < data.length ? formatConversion(data[i + 1].graphMetrics.convPrevStep) : null,
    }));

    const renderFunnelGraphHeadItem = (item: Record<string, string | number>, index: number) => {
        const total = format(item[graphKeys.sum], 'number', 2);
        const conversion = isLegendActive
            ? data[index].graphMetrics.convFirstStepSum
            : data[index].graphMetrics.convFirstStep;
        const title = item?.stepTitle as string;

        const TextHint = ({ text }) => (
            <div className={s.funnelGraphHeadItemTitle} data-testid="description-text">
                {text}
            </div>
        );

        const conversionVal = conversion && formatConversion(conversion);

        return (
            <div className={s.funnelGraphHeadItem} data-testid="step-container">
                <div className={s.funnelGraphHeadItemNumber} data-testid="title-text">
                    Шаг {index + 1}
                </div>
                {title?.split('').length > 18 ? (
                    <Tooltip title={title}>
                        <TextHint text={title} />
                    </Tooltip>
                ) : (
                    <TextHint text={title} />
                )}
                <div className={s.funnelGraphHeadItemValue}>
                    <div data-testid="value-text">{total}</div>
                    {conversionVal !== null && (
                        <div className={s.funnelGraphHeadItemConv} data-testid="conversion-text">
                            {conversionVal.toString().split('').length > 6 ? (
                                <Tooltip title={`${conversionVal} %`}>
                                    <TextHint text={`${conversionVal} %`} />
                                </Tooltip>
                            ) : (
                                `${conversionVal} %`
                            )}
                        </div>
                    )}
                </div>
            </div>
        );
    };

    // Добавляем "Шаг ..." к заголовку
    const transformTitle = (item: Record<string, string | number>, index: number) => ({
        ...item,
        stepTitle: `Шаг ${index + 1} | ${item?.stepTitle}`,
    });

    const legendItemClickHandler = () => {
        setLegendActive(!isLegendActive);
        top100Counter.trackEvent('stat_funnel_visitors_not_from_prev_step_btn');
    };

    const legendClassName = cn(s.funnelGraphLegendItem, s.funnelGraphLegendItemLast, {
        [s.funnelGraphLegendItemActive]: isLegendActive,
    });

    const graphData = isLegendActive ? dataSumGraph : dataGraph;

    return (
        <div className={s.funnelGraph} data-testid="funnel-container">
            <div className={s.funnelGraphTitleWrap}>
                <div className={s.funnelGraphTitle} data-testid="title-text">
                    Воронка
                </div>
            </div>
            <Loader loading={tableRequest}>
                <div className={s.funnelGraphInner}>
                    <div className={s.funnelGraphHead}>{graphData.map(renderFunnelGraphHeadItem)}</div>
                    <Chart
                        period={[null, null]}
                        type={ChartTypes.funnelCustom}
                        data={graphData.map(transformTitle)}
                        dataKey="stepTitle"
                        colors={[
                            'rgba(24, 78, 161, 1)',
                            'rgba(0, 135, 205, 1)',
                            'rgba(232, 236, 242, 1)',
                            'rgba(246, 249, 253, 1)',
                        ]}
                        sizes={[STEP_WIDTH * graphData.length, 192]}
                        group={ChartGroupBy.hour}
                        metricsName=""
                        xAxisVisible={false}
                        yAxisVisible={false}
                        maxYAxisValue={isLegendActive ? graphDataMaxVal[1] : graphDataMaxVal[0]}
                    />
                </div>
                <div className={s.funnelGraphLegend}>
                    <div className={s.funnelGraphLegendItemWrap} data-testid="legend-container">
                        <div className={s.funnelGraphLegendItem} data-testid="legend-text">
                            Посетители с предыдущего шага
                        </div>
                        <Hint tooltipPlacement="rightTop">
                            Пользователи, проходящие воронку последовательно от&nbsp;первого до последнего шага воронки
                        </Hint>
                    </div>
                    <div className={s.funnelGraphLegendItemWrap} data-testid="legend-container">
                        <div className={legendClassName} onClick={legendItemClickHandler} data-testid="legend-button">
                            Посетители не с предыдущего шага
                        </div>
                        <Hint tooltipPlacement="rightTop">
                            Включить в расчёт пользователей, попадающих в&nbsp;воронку не с предыдущего шага
                        </Hint>
                    </div>
                </div>
            </Loader>
        </div>
    );
};

export default FunnelGraph;
