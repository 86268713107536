import reducer from '@redux/slices';
import { errorHandler } from '@redux/store/middlewares';
import { configureStore } from '@reduxjs/toolkit';
import { createLogger } from 'redux-logger';

export function setUpStore() {
    const middleware = [errorHandler];

    if (process.env.NODE_ENV !== 'production') {
        const loggerMiddleware = createLogger({
            collapsed: true,
            diff: true,
        });

        middleware.push(loggerMiddleware);
    }

    return configureStore({
        middleware: (gDM) => gDM({ serializableCheck: false }).concat(...middleware),
        reducer,
    });
}

const store = setUpStore();

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;

export default store;
