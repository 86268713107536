import { GroupBy } from '@configs/group';
import reportsConfig from '@configs/reports';
import { ESpecialEventsClasses } from '@constants/events';
import { LatencyKeys } from '@constants/latency';
import reportsConstants from '@constants/reports';
import useFilters from '@hooks/useFilters';
import useGlobalParams from '@hooks/useGlobalParams';
import { useGraph } from '@hooks/useGraph';
import useQuery from '@hooks/useQuery';
import { useRequestParams } from '@hooks/useRequestParams';
import { useAppDispatch } from '@hooks/useStore';
import { useTable } from '@hooks/useTable';
import useValidDates from '@hooks/useValidDates';
import { globalActions } from '@redux/slices/global';
import { graphActions } from '@redux/slices/graph';
import { reportsActions, initialState as initialReportState } from '@redux/slices/reports';
import { tableActions } from '@redux/slices/table';
import eventBasedUtils from '@redux/slices/table/eventBasedUtils';
import tableReducerUtils from '@redux/slices/table/utils';
import * as Sentry from '@sentry/react';
import { DateLiteral, DateType } from '@typings/date';
import { Dimensions } from '@typings/dimensions';
import { SelectedLines } from '@typings/graph';
import { Metrics } from '@typings/metrics';
import { IQuery } from '@typings/query';
import {
    IReportRequestOptions,
    IReportRequestParams,
    IUpdateReportOptions,
    IUpdateReportOpts,
    IUpdateReportParams,
    ReportNameBase,
} from '@typings/reports';
import { IRootSlice } from '@typings/rootSlice';
import { IDict, IReportTableReqResponse } from '@typings/table';
import DateUtils from '@utils/date';
import globalUtilsFunc from '@utils/global';
import { urlUtils } from '@utils/index';
import reportsUtilsFunc from '@utils/reports';
import { isNumber } from '@utils/typesChecks';
import isEmpty from 'lodash/isEmpty';
import { useSelector } from 'react-redux';
import { useLocation, useParams } from 'react-router-dom';

const useReport = () => {
    const params: IUrlParams = useParams();
    const location = useLocation();

    const dispatch = useAppDispatch();
    const reportsSlice = useSelector((state: IRootSlice) => state.reportsSlice);
    const tableSlice = useSelector((state: IRootSlice) => state.tableSlice);
    const graphSlice = useSelector((state: IRootSlice) => state.graphSlice);
    const globalSlice = useSelector((state: IRootSlice) => state.globalSlice);
    const projectsSlice = useSelector((state: IRootSlice) => state.projectsSlice);
    const latencySlice = useSelector((state: IRootSlice) => state.latencySlice);

    const { search } = location;
    const query: IQuery = urlUtils.parseQuery(search);

    const reportsUtils = reportsUtilsFunc({
        reportsConfig,
    });

    const { getValidRatingDates } = useValidDates();

    const { getParamsFromQuery, checkQueryParams } = useQuery(reportsConfig);

    const {
        api: { getParamsForRequest, getStoreParams },
    } = useRequestParams();

    const {
        api: { getTable, resetTable, updateTableParams, openSelectedNodes, addTableRequestId },
    } = useTable();

    const {
        api: {
            getGraph,
            clearGraphs,
            updateGraphParams,
            toggleUseDefaultLines,
            updateSelectedLines,
            getDefaultSelectedLines,
            setGraphSummary,
            increaseGraphRequestId,
        },
        graphUtils,
    } = useGraph();

    const { updateApplyedFilterParams } = useFilters();

    const { updateGlobalParams } = useGlobalParams();

    const api = {
        /**
         * Инициализация репорта
         */
        async initReport(): Promise<void> {
            const { reportName, projectId } = params;

            const typeRequest = api.getTypeRequestReport(reportName);
            const defaultParams = reportsUtils.getDefaultParams(reportName);
            const queryParams = getParamsFromQuery(query, reportName);
            let checkedParams = checkQueryParams(
                queryParams,
                defaultParams,
                { ...reportsSlice, ...tableSlice, ...graphSlice },
                api.resetParam,
                reportName,
            );
            checkedParams = api.correctParamsForRating(checkedParams, defaultParams);
            checkedParams = api.correctParamsForConstructor(checkedParams, defaultParams);
            checkedParams = api.correctParamsForBase(checkedParams);

            const requestParams = getParamsForRequest(reportName, 'table', {
                ...globalSlice,
                projectId,
            });
            checkedParams = await updateApplyedFilterParams(checkedParams, requestParams);

            updateTableParams(checkedParams);
            updateGraphParams(checkedParams);
            updateGlobalParams(checkedParams);

            api.updateReportParams({ updateParams: checkedParams });

            const latencyKey = LatencyKeys.base;

            // Не запрашиваем данные,
            // если выбранные даты не корректны к latency
            if (latencySlice[latencyKey]?.isExcludedLatency === false) {
                await api.reportRequest(
                    typeRequest,
                    {
                        params: checkedParams,
                    },
                    reportName,
                );
            }

            return Promise.resolve();
        },

        /**
         * Коррекция параметров отчётов для рейтинга
         */
        correctParamsForRating(
            customParams: IUpdateReportParams,
            defaultParams: IUpdateReportParams,
        ): IUpdateReportParams {
            const { reportName } = params;
            const isRating = reportsUtils.isRating(reportName);

            if (!isRating) return { ...customParams, categoryId: null };

            let dates: DateType[] = [customParams.dateStart, customParams.dateEnd];
            dates = getValidRatingDates(dates, defaultParams.dateStart, defaultParams.dateEnd, customParams.dateStart);

            return {
                ...customParams,
                dateStart: dates[0],
                dateEnd: dates[1],
                groupBy: GroupBy.day,
            };
        },

        correctParamsForConstructor(
            customParams: IUpdateReportParams,
            defaultParams: IUpdateReportParams,
        ): IUpdateReportParams {
            const dimensions = customParams.dimensions || defaultParams.dimensions || [];

            return {
                ...customParams,
                dimensions,
            };
        },

        /**
         * Коррекция параметров отчётов
         */
        correctParamsForBase(customParams: IUpdateReportParams): IUpdateReportParams {
            const { reportName } = params;
            const {
                isTableTitleSearch = false,
                availableTools: { activeSample = false },
            } = reportsUtils.getConfig(reportName);

            const newParams = { ...customParams };

            // Если в отчёте доступен поиск по названию в таблице
            // сбрасываем прошлое значение из строки поиска
            if (isTableTitleSearch) {
                newParams.titleFilter = '';
            }

            // Если sample неактивен, то скидываем предыдущее значение
            if (!activeSample) {
                newParams.sample = null;
            }

            return customParams;
        },

        resetParam(param: string): void {
            dispatch(reportsActions.resetParam(param));
            dispatch(tableActions.resetParam(param));
            dispatch(graphActions.resetParam(param));
        },

        /**
         * Запрос новых данных для отчёта
         */
        async updateReport(
            type: string,
            updateParams: IUpdateReportOptions<IUpdateReportParams, ReportNameBase>,
        ): Promise<void> {
            const { meta, options } = updateParams;
            const { withoutRequest, withoutCompare } = options || {};
            const { reportName, projectId } = params;

            const requestParams = getParamsForRequest(reportName, 'table', {
                ...globalSlice,
                projectId,
            });
            const paramsWithFilters = await updateApplyedFilterParams(updateParams.updateParams, requestParams);

            const { newParams: tableParams, noParamsUpdate: noUpdateTable } = updateTableParams(
                updateParams.updateParams,
                withoutCompare,
            );
            const { newParams: graphParams, noParamsUpdate: noUpdateGraph } = updateGraphParams(
                updateParams.updateParams,
                withoutCompare,
                meta,
            );
            const { newParams: globalParams, noParamsUpdate: noUpdateGlobal } = updateGlobalParams(
                paramsWithFilters,
                withoutCompare,
            );
            const { newParams: reportParams, noParamsUpdate } = api.updateReportParams(updateParams);

            const newParams = {
                ...tableParams,
                ...graphParams,
                ...globalParams,
                ...reportParams,
            };

            if (withoutRequest || (noParamsUpdate && noUpdateTable && noUpdateGraph && noUpdateGlobal)) return;

            api.reportRequest(
                type,
                {
                    params: getStoreParams(newParams),
                    meta,
                    options,
                    updateParams: newParams,
                },
                params.reportName,
            );
        },

        updateReportParams({ updateParams, options = {} }: IUpdateReportOptions<IUpdateReportParams, ReportNameBase>) {
            const { reportName } = params;
            let newParams = { ...updateParams };
            const { withoutCompare } = options;
            const { compareParams, filterParamsForStore } = globalUtilsFunc();

            if (reportsUtils.isSummary(reportName)) {
                dispatch(tableActions.removeTableRowsSummary());
            }

            // Если необходимо запинить линии для Рейтинга, то запомнаем их данные
            if (options.pinLines) {
                api.updatePinnedProjects(options.pinLines);
            }

            newParams = filterParamsForStore(newParams, initialReportState);

            const noParamsUpdate =
                !withoutCompare &&
                !compareParams(
                    {
                        ...reportsSlice,
                    },
                    newParams,
                );

            if (Object.keys(newParams).length) {
                dispatch(reportsActions.updateRequestParams(newParams));
            }

            return { newParams, noParamsUpdate };
        },

        /**
         * Выполняем обновление параметров в стейте без запроса
         */
        updateReportWithoutRequest(updateParams: Record<string, string>): void {
            api.updateReport('all', { updateParams, options: { withoutRequest: true } });
        },

        /**
         * Выполняем запрос данных для таблицы или графика (или того и другого)
         */
        reportRequest(
            type: string,
            {
                params: newParams,
                meta = {},
                options = {},
                updateParams = {},
            }: IUpdateReportOpts<IUpdateReportParams, ReportNameBase>,
            reportName: ReportNameBase,
        ): void {
            const { projectId } = params;
            const { selectedLines, useDefaultLines } = graphSlice;
            const reportConfig = reportsUtils.getConfig(reportName);
            const isRating = reportsUtils.isRating(reportName);
            const isSummary = reportsUtils.isSummary(reportName);
            const isConstructor = reportsUtils.isConstructor(reportName);

            const requestOptions: IReportRequestOptions = { ...options };
            if (!useDefaultLines) {
                requestOptions.forceResetSelectedLines = true;
            }

            let switchType = type;
            if (isSummary) switchType = 'tableWithGraph';
            if (isConstructor) {
                switchType = `${type === 'all' ? newParams.viewType || reportsSlice.viewType : type}Constructor`;
            }

            const requestParams = getParamsForRequest(reportName, switchType, {
                ...newParams,
                projectId,
                ...(isRating && !updateParams?.limit && !newParams.limit ? { limit: 49 } : {}),
            });

            // TODO: пока костыль, нужно для заглушки рейтинга
            // Иначе попадаем на 500 ошибку (Project not in rating)
            if (isRating && !api.checkRatingAccess(projectsSlice.current.status)) return;

            switch (switchType) {
                case 'all': {
                    const lastRequestId = increaseGraphRequestId();
                    getTable(projectId, reportName, requestParams, meta)
                        .then((res: IReportTableReqResponse & { responseStatus: number }) => {
                            // Не выполняем дальнейшее (в том числе запрос за графиками)
                            if (res?.responseStatus !== 200) return;

                            const { data, pinnedProjectsIds } = tableReducerUtils.prepareTableData(res, {
                                ...requestParams,
                                reportName,
                            });

                            const newUpdateParams = { ...updateParams };

                            if (!data || !data.list.length) {
                                clearGraphs();
                                return;
                            }

                            // Обновляем список, чтобы при передаче одинаковых id
                            // остались только уникальные
                            const listIds = [...new Set(data.list)];

                            if (!useDefaultLines && selectedLines.length && !requestOptions.forceResetSelectedLines) {
                                // оставляем выбранные ноды, которые есть в данных
                                const newSelectedLines = updateSelectedLines(selectedLines, listIds);

                                // делаем запросы дочерних нод, чтобы раскрыть таблицу
                                openSelectedNodes(newSelectedLines);

                                newUpdateParams.selectedLines = newSelectedLines;
                            } else {
                                let defaultLines = [];

                                if (pinnedProjectsIds?.length) {
                                    defaultLines = pinnedProjectsIds;
                                } else {
                                    defaultLines = getDefaultSelectedLines(listIds);
                                }

                                newUpdateParams.selectedLines = graphUtils.getSelectedLines(defaultLines);

                                toggleUseDefaultLines(true);
                                updateTableParams(newUpdateParams);
                                updateGraphParams(newUpdateParams);
                            }

                            const graphParams = getParamsForRequest(reportName, 'graph', {
                                ...newParams,
                                ...newUpdateParams,
                                projectId,
                            });

                            if (newUpdateParams.selectedLines.length) {
                                getGraph(projectId, reportName, graphParams, { ...meta, lastRequestId }, data);
                            } else {
                                dispatch(graphActions.resetGraph(false));
                            }
                            // В том числе, чтобы в юнит тестах не зависали промисы
                        })
                        .catch((err) => {
                            Sentry.captureException(err);
                        });
                    break;
                }
                case 'table': {
                    getTable(projectId, reportName, requestParams, meta)
                        .then((res: IReportTableReqResponse & { responseStatus: number }) => {
                            const {
                                result: { data },
                            } = res;

                            if (data.length) {
                                let listIds = data.map((item) => String(item.id));

                                // обновляем список, чтобы при передаче одинаковых id
                                // остались только уникальные
                                listIds = [...new Set(listIds)];

                                const newSelectedLines = updateSelectedLines(selectedLines, listIds);

                                // делаем запросы дочерних нод, чтобы раскрыть таблицу
                                openSelectedNodes(newSelectedLines);

                                updateGraphParams({ selectedLines: newSelectedLines });
                            }
                            // В том числе, чтобы в юнит тестах не зависали промисы
                        })
                        .catch((err) => {
                            Sentry.captureException(err);
                        });
                    break;
                }
                case 'graph': {
                    /*
                        Была ошибка при сбросе всех нод в отчётах и
                        смене параметров выпадала ошибка 500,
                        но в отчёте summary не должно быть selectedLines
                    */
                    if (!requestParams.selected_lines.length && !reportsUtils.isSummary(reportName)) {
                        dispatch(graphActions.resetGraph(false));
                    } else {
                        getGraph(projectId, reportName, requestParams, {
                            reportConfig,
                            reportsUtils,
                            ...meta,
                        });
                    }
                    break;
                }
                case 'tableWithGraph': {
                    // Отдельного запроса за графиком нет
                    updateGraphParams({ graphRequest: true });

                    getTable(projectId, reportName, requestParams, { ...meta, isNotClearTable: true })
                        .then((res: IReportTableReqResponse & { responseStatus: number }) => {
                            // Не выполняем дальнейшее (в том числе запрос за графиками)
                            if (res?.responseStatus !== 200) return;

                            const { data } = tableReducerUtils.prepareTableData(res, meta);

                            if (!data || !data.list.length) {
                                clearGraphs();
                                return;
                            }

                            setGraphSummary(res);
                            // В том числе, чтобы в юнит тестах не зависали промисы
                        })
                        .catch((err) => {
                            Sentry.captureException(err);
                        });
                    break;
                }
                case 'tableConstructor': {
                    const newRequestParams = api.setConstructorTableRequestParams(requestParams);

                    if (!newRequestParams) break;

                    dispatch(
                        tableActions.getConstructorTable({
                            projectId,
                            reportName,
                            params: newRequestParams,
                            meta: addTableRequestId(meta),
                        }),
                    );
                    break;
                }
                case 'graphConstructor': {
                    const newRequestParams = api.setConstructorTableRequestParams(requestParams);
                    if (!newRequestParams) break;

                    const firstDimension = newRequestParams.dimensions[0];

                    // Для графиков запрашиваем не больше 10 значений кроме дименшина дат
                    if (firstDimension !== 'date') {
                        newRequestParams.limit = reportsConstants.CONSTRUCTOR_GRAPH_LINES_MAX;
                        // Для графиков сортируем красиво столбцы
                        newRequestParams.sort.order = 'desc';
                    }

                    updateGraphParams({ graphRequest: true });

                    const lastRequestId = increaseGraphRequestId();
                    // Сначала делаем зарос за тоталсами для графиков
                    dispatch(
                        tableActions.getConstructorTable({
                            projectId,
                            reportName,
                            params: newRequestParams,
                            meta: addTableRequestId(meta),
                        }),
                    )
                        .unwrap()
                        .then((resp) => {
                            const payload = resp?.result;
                            // Для правильного отображения графиков нам нужны все промежуточные точки,
                            // даже если там нет данных
                            newRequestParams.empty_dates = true;
                            // Для даты нужно изменить сортировку
                            newRequestParams.sort = {
                                name: 'title',
                                order: 'asc',
                            };
                            // Для графиков делаем запрос с датами
                            if (firstDimension !== 'date') newRequestParams.dimensions.push('date');

                            // Получаем актуальные табличные данные по payload из предыдущего запроса
                            const tableData = eventBasedUtils.prepareTableConstructorData(payload, payload.dimensions);

                            // Передаем выбранные значения (линии) через фильтры, убираем пустые, undefined значения
                            let values =
                                (firstDimension !== 'date' &&
                                    tableData?.list.map((value) => tableData.dict[value]?.id || '')) ||
                                [];

                            // В гео попадаются пустые строки в качестве id, которые надо убрать
                            if (values.length && isNumber(values[0])) {
                                values = values.filter((value) => isNumber(value));
                            }

                            const filters = [
                                {
                                    key: payload.dimensions[0],
                                    op: 'include',
                                    value: values,
                                },
                            ];

                            if (values.length) {
                                newRequestParams.dimension_filters = [
                                    ...(newRequestParams.dimension_filters || []),
                                    ...filters,
                                ];
                            }

                            delete newRequestParams.limit;

                            dispatch(
                                graphActions.getConstructorGraph({
                                    projectId,
                                    reportName,
                                    params: newRequestParams,
                                    tableData,
                                    meta: { ...meta, lastRequestId },
                                }),
                            );
                        });
                    break;
                }
                default:
                    break;
            }
        },

        setConstructorTableRequestParams(paramsForRequest: IReportRequestParams): IReportRequestParams {
            const requestParams = { ...paramsForRequest };
            const { dimensions } = requestParams || {};
            const firstDimension = dimensions[0];

            if (!dimensions?.length || !requestParams.metrics.length) return null;

            // Запрос всегда идет только с первым дименшином,
            // поскольку все данные в виде дерева мы получить не можем
            requestParams.dimensions = [firstDimension];
            // Для даты нужно изменить сортировку
            if (firstDimension === 'date') {
                requestParams.sort = {
                    name: 'title',
                    order: 'asc',
                };
            }
            // Не выводим технические события.
            // Сделано через includes, чтобы в Итого не плюсовались значения тех. событий
            if (dimensions.includes('event_class')) {
                requestParams.dimension_filters = [
                    ...(requestParams.dimension_filters || []),
                    {
                        key: 'event_class',
                        op: '!=',
                        value: ESpecialEventsClasses.tech,
                    },
                ];
            }

            // Удаляем сплит, чтобы в запрос к бэкам он не уходил,
            // сплит запрашивается через дименшин
            delete requestParams.split;

            return requestParams;
        },

        /**
         * Определяем, что обновлять в отчёте по обновленному параметру
         * (детализация, дата, семпл, сплит, ...)
         */
        getTypeRequestReport(reportName: ReportNameBase, updateParam?: string): string {
            // Для отчёта аудитории-посещаемости всегда одно значение
            if (reportsUtils.isSummary(reportName)) return 'tableWithGraph';

            let type;
            switch (updateParam) {
                case 'groupBy':
                    type = 'graph';
                    break;
                default:
                    type = 'all';
                    break;
            }

            return type;
        },

        /**
         * Обновляем запиненные проекты
         * Если пришел пустой список выбранных линий - обнуляем pinnedProjects
         * Сохраняем данные из таблицы для линий в стор
         */
        updatePinnedProjects(selectedLines: SelectedLines): void {
            const { pinnedProjects } = reportsSlice;
            const { tableData } = tableSlice;

            if (!selectedLines.length) {
                if (isEmpty(pinnedProjects)) return;

                dispatch(reportsActions.updatePinnedProjects({}));
            } else {
                const pinnedProjectsIds = selectedLines.map((line) => line.id);

                const newPinnedProjects: Record<string, IDict> = {};

                pinnedProjectsIds.forEach((projectId) => {
                    newPinnedProjects[projectId] = tableData.dict[projectId];
                });

                dispatch(reportsActions.updatePinnedProjects(newPinnedProjects));
            }
        },

        resetReport() {
            resetTable();
            dispatch(reportsActions.allReset());
        },

        handleChangeDate(date: string[], relativeDate: DateLiteral): void {
            const { reportName } = params;
            const [dateStart, dateEnd] = date;
            const groupBy = globalSlice.groupBy;
            const selectedLines = graphSlice.selectedLines;
            const isRating = reportsUtils.isRating(reportName);

            const updateParams: IUpdateReportParams = {
                ...(relativeDate ? DateUtils.getRelativeDates(relativeDate) : { dateStart, dateEnd }),
                groupBy,
            };

            if (!isRating) {
                toggleUseDefaultLines(true);
            }

            if (isRating) {
                updateParams.groupBy = groupBy;
            } else {
                updateParams.groupBy = DateUtils.checkCurrentGroup(
                    [DateUtils.getDate(dateStart), DateUtils.getDate(dateEnd)],
                    groupBy,
                );
            }

            // Если список динамических фильтров не пустой, то очищаем его
            if (!isEmpty(globalSlice.dynamicFiltersData)) {
                updateParams.dynamicFiltersData = {};
            }

            const type = api.getTypeRequestReport(reportName);

            api.updateReport(type, {
                updateParams,
                meta: { reportName },
                options: {
                    ...(isRating ? { pinLines: selectedLines } : {}),
                },
            });
        },

        /**
         * Меняем выбранную категорию для рейтинга
         */
        changeRatingCategory(value: string): void {
            const categoryId = globalSlice.categoryId;
            const { reportName } = params;
            const isRating = reportsUtils.isRating(reportName);

            if (categoryId !== value) {
                toggleUseDefaultLines(true);

                api.updateReport('all', {
                    updateParams: {
                        categoryId: value,
                    },
                    options: {
                        forceResetSelectedLines: true,
                        ...(isRating ? { pinLines: [] } : {}),
                    },
                });
            }
        },

        requestReportCsv(reportName: ReportNameBase) {
            const { projectId } = params;
            const isConstructor = reportsUtils.isConstructor(reportName);
            const type = isConstructor ? 'tableConstructor' : 'table';

            const requestParams = getParamsForRequest(reportName, type, {
                ...getStoreParams({}),
                projectId,
            });

            if (isConstructor) {
                const { dimensions } = requestParams || {};
                const firstDimension = dimensions[0];

                // Если дата первая в списке, нужно изменить сортировку
                if (firstDimension === 'date') {
                    requestParams.sort = {
                        name: 'title',
                        order: 'asc',
                    };
                }
            }

            // Не передаем offset и limit, устанавливаем sample = 1,
            // чтобы сформировались все данные по отчету
            delete requestParams.offset;
            delete requestParams.limit;
            requestParams.sample = 1;

            return dispatch(
                reportsActions.requestReportCsv({
                    projectId,
                    data: {
                        ...requestParams,
                        title: reportName,
                    },
                    isEventBased: isConstructor,
                }),
            ).unwrap();
        },

        setGlobalLoading(param: boolean): void {
            dispatch(globalActions.setGlobalLoading(param));
        },

        clearError(): void {
            dispatch(globalActions.clearError());
        },

        setError(code: number): void {
            dispatch(globalActions.setError({ code }));
        },

        setSnackbar(message: string, type: string): void {
            dispatch(globalActions.setSnackbar({ message, type }));
        },

        getServerTime(): void {
            dispatch(globalActions.getServerTime());
        },

        checkRatingAccess(projectStatus: string): boolean {
            return (
                projectStatus === 'included' ||
                projectStatus === 'editing' ||
                projectStatus === 'need_change' ||
                Number(params.projectId) === reportsConstants.DEMO_PROJECT_ID
            );
        },

        updateConstructor(
            type: string, // тип конструктора (таблица/график)
            newParams: {
                metrics?: Metrics[];
                dimensions?: Dimensions[];
                offset?: number;
                limit?: number;
            },
        ): void {
            dispatch(reportsActions.updateViewType(type));

            api.updateReport(type, {
                updateParams: newParams,
                options: {
                    withoutCompare: true,
                },
            });
        },
    };

    return {
        api,
        reportsUtils,
    };
};

export default useReport;
