import { createAsyncThunk } from '@reduxjs/toolkit';
import fetchApi from '@utils/fetchApi';

export const requestReportCsv = createAsyncThunk(
    'reportsSlice/requestReportCsv',
    async ({
        projectId,
        data,
        isEventBased = false,
    }: {
        projectId: number | string;
        data: any;
        isEventBased: boolean;
    }) =>
        // eslint-disable-next-line no-return-await
        await fetchApi({
            url: `/api/report/v1.0/${isEventBased ? 'eventbase' : 'report'}/${projectId}/${data.title}/csv`,
            method: 'POST',
            body: { ...data },
        }),
);
