import trafficType from '@configs/widgets/trafficType';

export const dimensionEmptyValue = 'Не определено';

// Конфиг значений некоторых дименшинов, например для задания алиасов
export default {
    traffic_type: trafficType,
    gender: {
        female: {
            title: 'Женщины',
        },
        male: {
            title: 'Мужчины',
        },
        other: {
            title: 'Неизвестен',
        },
    },
    age_tns: {
        other: {
            title: 'Неизвестен',
        },
    },
    os_family: {
        Other: {
            title: dimensionEmptyValue,
        },
    },
    event_class: {
        base: {
            title: 'Базовые события',
        },
        ub: {
            title: 'События аналитики блоков',
        },
        media: {
            title: 'Cобытия медиа',
        },
        ec: {
            title: 'Ecom события',
        },
        cv: {
            title: 'Собственные события',
        },
        rec: {
            title: 'События рекомендаций',
        },
        tech: {
            title: 'Технические события',
        },
    },
    device_type: {
        pc: {
            title: 'ПК',
        },
        smartphone: {
            title: 'Смартфоны',
        },
        mobile: {
            title: 'Мобильные',
        },
        tablet: {
            title: 'Планшеты',
        },
        'Other devices': {
            title: 'Остальное',
        },
        bot: {
            title: 'Боты',
        },
        console: {
            title: 'Игровые консоли',
        },
        'portable media': {
            title: 'Портативные медиаплееры',
        },
    },
    auth_top100: {
        auth: {
            title: 'Авторизован на сайте',
        },
        not_auth: {
            title: 'Не авторизован на сайте',
        },
    },
    auth_rambler: {
        auth: {
            title: 'Авторизован по rambler_id',
        },
        not_auth: {
            title: 'Не авторизован по rambler_id',
        },
    },
    view_depth: {
        1: { title: '1 страница' },
        2: { title: '2 страницы' },
        3: { title: '3 страницы' },
        4: { title: '4 страницы' },
        5: { title: '5 страниц' },
        6: { title: '6 страниц' },
        7: { title: '7 страниц' },
        8: { title: '8 страниц' },
        9: { title: '9 страниц' },
        10: { title: '10 страниц' },
        11: { title: 'Больше 10 страниц' },
    },
    session_number: {
        addTitle: ' визит',
    },
};

// Конфиг названий пустых значений дименшинов
export const dimensionsEmptyValues = {
    url_args_key: 'Без параметров',
    title: 'Без заголовка',
    external_url: 'Не переходы',
    ad_campaign: 'Другой источник',
    ad_system: 'Другой источник',
    search_phrase: 'Другой источник',
    search_system: 'Другой источник',
    recom_system: 'Другой источник',
    exchange_system: 'Другой источник',
    message_system: 'Другой источник',
    aggrnews_system: 'Другой источник',
    qr_system: 'Другой источник',
    native_mobile: 'Другой источник',
    social_network: 'Другой источник',
    utm_campaign: 'Без utm',
    utm_content: 'Без utm',
    utm_medium: 'Без utm',
    utm_source: 'Без utm',
    utm_term: 'Без utm',
    openstat_ad: 'Без openstat',
    openstat_campaign: 'Без openstat',
    openstat_service: 'Без openstat',
    openstat_source: 'Без openstat',
    referer: 'Не определен или отсутствует',
    referer_domain: 'Не определен или отсутствует',
    referer_path: 'Не определен или отсутствует',
};
