import { formsActions } from '@redux/slices/forms';
import { formUtils } from '@utils/index';
import React from 'react';
import { connect } from 'react-redux';
import { Dispatch } from 'redux';

import ComponentForm from './component';
import { IParams, IWrappedComponentProps } from './types';

/**
 * Декоратор формы
 * @param params
 */
export default function formDecorator(params: IParams) {
    return (WrappedComponent: React.Component<IWrappedComponentProps> | React.FC<IWrappedComponentProps>) => {
        const mapStateToProps = (state) => {
            const wrappedState = params.mapStateToProps ? params.mapStateToProps(state) : {};

            const currentForm = state.formsSlice.forms[params.formName];

            const getValid = () => {
                if (!currentForm) {
                    return false;
                }

                const currentFields = currentForm.fields;
                const isValid =
                    Object.keys(currentFields)
                        .map(
                            (key) =>
                                (currentFields[key].valid && !currentFields[key].asyncValidating) ||
                                currentFields[key].hidden,
                        )
                        .filter((valid) => !valid).length === 0;

                return isValid;
            };
            const getValues = () => {
                if (!currentForm) {
                    return {};
                }

                return formUtils.getPureValues(currentForm.fields);
            };

            const valid = getValid();
            const values = getValues();

            const asyncInitializeValues = params.asyncInitializeValues
                ? params.asyncInitializeValues.map((item) => {
                      const isInitializeValues = item.should(state);
                      return {
                          isInitializeValues,
                          initialValues: isInitializeValues ? item.initializeValues(state) : {},
                      };
                  })
                : false;

            const isInitializeValues = params.shouldInitializeValues ? params.shouldInitializeValues(state) : true;

            const initialValues = isInitializeValues && params.initializeValues ? params.initializeValues(state) : {};
            const serverError = currentForm ? currentForm.serverError : '';

            return {
                ...wrappedState,
                forms: state.formsSlice.forms,
                values,
                valid,
                invalid: !valid,
                isInitializeValues,
                initialValues,
                asyncInitializeValues,
                serverError,
            };
        };
        const mapDispatchToProps = (dispatch: Dispatch) => {
            const wrappedDispatchToProps = params.mapDispatchToProps ? params.mapDispatchToProps(dispatch) : {};

            return {
                ...wrappedDispatchToProps,
                WrappedComponent,
                params,
                /**
                 * Экшн создания формы
                 * @param formName
                 * @param initialValues
                 */
                createForm: (formName: string, initialValues: Record<string, any> = {}) => {
                    dispatch(formsActions.createForm({ formName, initialValues }));
                },
                /**
                 * Экшн удаления формы / форм
                 * @param forms
                 */
                removeForm: (forms: string | string[]) => {
                    dispatch(formsActions.removeForm(forms));
                },
                /**
                 * Экшн сброса формы
                 * @param formName
                 */
                resetForm: (formName: string) => {
                    dispatch(formsActions.resetForm({ formName }));
                },
                /**
                 * Экшн подгрузки данных в форму
                 * @param formName
                 * @param values
                 * @param isForceUpdate
                 */
                initializeValues: (formName: string, values: Record<string, any>, isForceUpdate: boolean = false) => {
                    dispatch(formsActions.initializeValues({ formName, values, isForceUpdate }));
                },
                /**
                 * Экшн удаления поля из формы
                 * @param formName
                 * @param parentFieldName
                 * @param fieldName
                 * @param mod
                 */
                removeField: (formName: string, parentFieldName: string, fieldName: string, mod: string) =>
                    dispatch(formsActions.removeField({ formName, parentFieldName, fieldName, mod })),
                /**
                 * Экшн изменения значения в форме
                 * @param formName
                 * @param parentFieldName
                 * @param fieldName
                 * @param mod
                 * @param value
                 */
                changeField: (
                    formName: string,
                    parentFieldName: string[],
                    fieldName: string,
                    mod: string,
                    value: any,
                ) => {
                    dispatch(
                        formsActions.changeField({
                            prefix: 'CHANGE',
                            formName,
                            parentFieldName,
                            fieldName,
                            mod,
                            fieldData: value,
                        }),
                    );
                },
                /**
                 * Экшн добавление ошибок в форму
                 * @param formName
                 * @param errors
                 */
                setFieldErrors: (formName: string, errors: Record<string, any>) => {
                    dispatch(formsActions.setFieldErrors({ formName, errors }));
                },
                /**
                 * Экшн добавления серверных ошибок в форму
                 * @param formName
                 * @param serverError
                 */
                setServerError: (formName: string, serverError: string) => {
                    dispatch(formsActions.setServerError({ formName, serverError }));
                },
            };
        };

        return connect(mapStateToProps, mapDispatchToProps)(ComponentForm);
    };
}
