import { ChartTypes } from '@configs/graph';
import { sortOrders } from '@constants/sort';
import { IReportConfig } from '@typings/reports';

const reportConfig: IReportConfig = {
    reportTitle: 'Формат страниц',
    tableDataType: 'static',
    tableTitle: 'Тип, устройства',
    defaultSort: {
        name: 'visits',
        order: sortOrders.DESC,
    },
    numberOfGraphs: 'two',
    defaultGraphs: [
        ChartTypes.pie,
        ChartTypes.bar,
    ],
    graphDefaultMetrics: [
        'visits',
        'visitors',
    ],
    tableDefaultMetrics: [
        'visits',
        'page_views',
        'visitors',
    ],
    titleAliases: {
        web: 'Web',
        amp: 'Amp',
        mp: 'Turbo',
        other: 'Прочие',
    },
    availableMetrics: [
        'visits',
        'avg_view_depth',
        'page_views',
        'visitors',
        'uid',
        'rambler_id',
        // 'bounces',
        // 'avg_industry_duration',
    ],
    isShowTotals: true,
    availableTools: {
        numberOfGraphs: true,
        periodPicker: true,
        sample: true,
        activeSample: true,
        filters: true,
        savedFilters: true,
        detail: true,
    },
    reportHint: {
        message: 'Отчёт показывает распределение трафика по форматам страниц - desktop, mobile, amp и turbo. '
            + 'Доступные показатели: посетители, визиты, '
            + '<a href="https://ads.sber.ru/help/metod-dlya-peredachi-identifikatora-polzovatelya" target="_blank">пользователи</a>, '
            + 'просмотры, отказы, время на сайте, глубина просмотра.',
    },
};

export default reportConfig;
