/* eslint-disable camelcase */
import custom_vars from './custom_vars';
import likes from './likes';
import media from './media';
import rating from './rating';
import usability from './usability';

export default {
    usability,
    likes,
    rating,
    custom_vars,
    materials_summary: media,
    top: media,
    authors_summary: media,
};
