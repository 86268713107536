import { Button, ContextAlertMark } from '@adtech/ui';
import fieldDecorator from '@decorators/fieldDecorator';
import { IUsersValue } from '@typings/counter';
import { IField } from '@typings/form';
import formUtils from '@utils/formUtils';
import React, { useState, useEffect, useRef } from 'react';
import s from './AccessUsers.pcss';
import InputField from './Fields/InputField';
import RadioField from './Fields/RadioField';

interface IProps {
    field: IField;
    meta: Record<string, any>;
    api: {
        addField: (value: any, fieldName: string) => void;
        removeField: (index: number, fieldName: string) => void;
        getFieldData: (fieldName: string) => any;
    };
    reset: () => void;
    getUserExists: (email: string) => Promise<{ payload: { exists: boolean } }>;
}

export const NewUsersComponent: React.FC<IProps> = ({ field, api, reset, getUserExists }) => {
    const { addField, getFieldData } = api;
    const fieldData = useRef(null);
    const fieldValue = (field.value as IUsersValue[]) || [];
    const error = useRef({ type: '', message: '' });
    const [isInvalid, setIsInvalid] = useState(true);
    const [isLoading, setIsLoading] = useState(false);

    const getfieldsNewUsers = () => {
        const data = getFieldData('newUsers');
        const dataValue = data.value[0];

        if (dataValue.value && dataValue.value.email) {
            return dataValue.value.email;
        }

        return {};
    };

    useEffect(() => {
        const fieldsNewUsers = getfieldsNewUsers();

        if (isLoading && !fieldsNewUsers && !Object.keys(fieldsNewUsers)) return;

        fieldData.current = fieldsNewUsers;

        if (fieldsNewUsers.value && fieldData.current !== null) {
            // todo: проверки декоратора не работают, надо переделывать, meta была props
            // if (fieldsNewUsers.invalid || meta.invalid || !fieldValue[0].email || !fieldValue[0].accessLevel) {
            if (!fieldValue[0].email || !fieldValue[0].accessLevel) {
                setIsInvalid(true);
                if (fieldsNewUsers.errorType === 'unique') {
                    error.current = { type: 'unique', message: fieldsNewUsers.errorText };
                } else {
                    error.current = { type: '', message: '' };
                }
            } else {
                error.current = { type: '', message: '' };
                setIsInvalid(false);
            }
        } else {
            fieldData.current = fieldsNewUsers;
        }
    }, [field]);

    const handleClick = () => {
        if (!isInvalid && !isLoading && error.current.type !== 'noExists') {
            setIsLoading(true);
            getUserExists(fieldData.current.value)
                .then((data) => {
                    if (data.payload.exists) {
                        setIsInvalid(false);
                        error.current = { type: '', message: '' };

                        addField(fieldValue[0], 'addedUsers');
                        reset();
                    } else {
                        setIsInvalid(true);
                        error.current = {
                            type: 'noExists',
                            message: 'Такой email не найден в системе, пользователю необходимо пройти регистрацию',
                        };
                    }
                })
                .catch(() => {
                    setIsInvalid(true);
                    error.current = {
                        type: 'noExists',
                        message: 'Такой email не найден в системе, пользователю необходимо пройти регистрацию',
                    };
                })
                .finally(() => setIsLoading(false));
        } else {
            error.current = { type: '', message: '' };
        }
    };

    const renderItem = (item, key: number) => (
        <div key={key} className={s.addNewUserFields}>
            <InputField index={key} name="email" loading={isLoading} />
            <RadioField index={key} name="accessLevel" />
        </div>
    );

    if (!fieldValue.length) return null;

    return (
        <>
            {fieldValue?.map(renderItem)}
            <div className={s.addNewUserAction}>
                <Button
                    disabled={isInvalid || isLoading || !field.value[0].email || !field.value[0].accessLevel}
                    type="dashed"
                    onClick={handleClick}
                    className={s.addNewUserButton}
                >
                    Дать доступ
                </Button>
                {isInvalid && error.current.message && (
                    <ContextAlertMark status="error" message={error.current.message} />
                )}
            </div>
            <div className={s.addNewUserText}>
                Пользователь, которому выдается доступ, должен быть зарегистрирован в системе
            </div>
        </>
    );
};

export const NewUsersField = fieldDecorator({
    formName: 'accessSettings',
    name: 'newUsers',
    type: 'object',
    mod: 'array',
    hidden: true,
    initialValue: [{ email: '', accessLevel: '' }],
    rules: [
        { validate: formUtils.defaultRules.required },
        { validate: formUtils.defaultRules.email, errorType: 'email' },
        {
            validate: (value, values) => {
                const invalid = values && values.addedUsers && values.addedUsers.find((val) => val.email === value);

                return invalid ? 'Пользователь уже добавлен' : true;
            },
            errorType: 'unique',
        },
    ],
})(NewUsersComponent);
