import { Tooltip, Checkbox } from '@adtech/ui';
import { IItemMultiple } from '@typings/dropdown';
import React, { useEffect, useState } from 'react';
import s from '../Dropdown.pcss';
import { List } from '../components/List';

interface IProps {
    dataInitialSelected: string[];
    data: IItemMultiple[];
    onApply: (dataSelected: string[], isChanged?: boolean) => void;
    limit?: number;
    isRequestClose: boolean;
    dataAttrName?: string;
    dataAttrList?: string[];
    isOneValueAllowed?: boolean;
}

export const SelectMultiple: React.FC<IProps> = ({
    onApply,
    data,
    dataInitialSelected,
    limit,
    isRequestClose,
    dataAttrName = '',
    dataAttrList = [],
    isOneValueAllowed,
}) => {
    const [checkedItems, setCheckedItems] = useState(dataInitialSelected);

    useEffect(() => {
        if (!isRequestClose) return;
        let isChanged = false;

        if (dataInitialSelected.length !== checkedItems.length) {
            isChanged = true;
        } else {
            dataInitialSelected.map((item) => {
                if (!checkedItems.includes(item)) {
                    isChanged = true;
                }
                return item;
            });
        }

        onApply(checkedItems, isChanged);
    }, [isRequestClose]);

    const handleChange = (name, checked) => {
        if (checked && checkedItems.length === limit) return;
        if (!checked && checkedItems.length <= 1 && !isOneValueAllowed) return;
        setCheckedItems(checked ? [...checkedItems, name] : checkedItems.filter((value) => value !== name));
    };

    const renderItem = (item) => {
        const { value, title } = item;
        const isChecked = checkedItems.indexOf(value) !== -1;
        const isDisabled = !isChecked && limit !== null && checkedItems.length === limit;

        const renderCheckbox = (
            <Checkbox
                name={value}
                key={value}
                className={s.item}
                onChange={(e) => handleChange(value, e?.target?.checked)}
                checked={isChecked}
                disabled={isDisabled}
            >
                {title || 'Название не задано'}
            </Checkbox>
        );

        if (isDisabled) {
            const message = `Можно выбрать не больше ${String(limit)}`;
            return <Tooltip title={message}>{renderCheckbox}</Tooltip>;
        }

        return renderCheckbox;
    };

    return <List data={data} renderItem={renderItem} dataAttrName={dataAttrName} dataAttrList={dataAttrList} />;
};

export default SelectMultiple;
