import { ChartTypes } from '@configs/graph';
import { sortOrders } from '@constants/sort';
import { ViewType } from '@constants/viewType';
import { IReportConfig } from '@typings/reports';

const reportConfig: IReportConfig = {
    reportTitle: 'Конструктор отчётов',
    tableDataType: 'dynamic',
    tableTitle: '',
    defaultGraphs: [ChartTypes.line],
    graphDefaultMetrics: [
        'page_views',
        'visits',
        'visitors',
        'uid',
        'rambler_id',
        'avg_view_depth',
        'events_count',
        // 'bounces',
        // 'avg_sessions_duration',
    ],
    availableMetrics: [
        'page_views',
        'visits',
        'visitors',
        'uid',
        'rambler_id',
        'avg_view_depth',
        'events_count',
        // 'bounces',
        // 'avg_sessions_duration',
    ],
    numberOfGraphs: null,
    defaultSort: {
        name: 'visits',
        order: sortOrders.DESC,
    },
    disabledSortBy: ['title'], // бэк не поддерживает
    titleFormat: 'date',
    isDisabledCheckbox: true,
    disabledGraphs: {},
    tableFixedDimensions: [],
    tableDefaultMetrics: [],
    isConstructor: true,
    isShowTotals: true,
    availableTools: {
        filters: true,
        savedFilters: true,
        numberOfGraphs: false,
        periodPicker: true,
        sample: true,
        activeSample: true,
        detail: true,
    },
    isShowCsv: true,
    showMetricsSwitcher: false,
    isShowSaveReportBtn: true,
    viewTypeDefault: ViewType.table,
    defaultOffset: 0,
    defaultLimit: 10,
};

export default reportConfig;
