import { Button, Input } from '@adtech/ui';
import useCopy from '@hooks/useCopy';
import usePostClick from '@hooks/usePostClick';
import IconCopy from '@images/svg/icons/icon-copy.svg';
import React, { ChangeEvent, FC, useState } from 'react';
import s from '../../PostClick.pcss';

const CounterYandexAccess: FC = () => {
    const [counterNumber, setCounterNumber] = useState('');
    const api = usePostClick();
    const { copy } = useCopy();

    const syncCounter = () => {
        api.linkCounter(Number(counterNumber));
    };

    const inputHandler = (event: ChangeEvent<HTMLInputElement>) => {
        setCounterNumber(event.target.value);
    };

    const copyHandler = (email: string): void => {
        copy(email, 'Скопировано');
    };

    return (
        <>
            <div className={s.number}>
                <span>StatSberAds@yandex.ru</span>
                <IconCopy onClick={() => copyHandler('StatSberAds@yandex.ru')} className={s.numberCopy} />
            </div>
            <div className={s.sync}>
                <div className={s.syncLabel}>
                    <span className={s.syncLabelText}>Номер счётчика</span>
                    <Input className={s.syncInput} onChange={inputHandler} placeholder="Введите номер счётчика" />
                </div>
                <Button onClick={() => syncCounter()}>Синхронизировать</Button>
            </div>
        </>
    );
};

export default CounterYandexAccess;
