import { graphConstants } from '@constants/graph';
import tableConstants from '@constants/table';
import { IReportConstants } from '@typings/reports';
import { ITableConstants } from '@typings/table';

const reportsConstants: ITableConstants & IReportConstants = {
    ...tableConstants,
    ...graphConstants,
    OLD_GROUP_DATE: '01.01.2017',
    DEMO_PROJECT_ID: 7596044,
    CONSTRUCTOR_GRAPH_LINES_MAX: 10, // максимальное кол-во значений для графика
};

export default reportsConstants;
