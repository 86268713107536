import { Button, Input, DoubleDatepicker, Checkbox } from '@adtech/ui';
import CommonFilters from '@components/CommonFilters';
import { filtersCnf as filtersFormCnf } from '@components/CommonFilters/configs/filters';
import { filtersListCnf } from '@components/CommonFilters/configs/filtersConfigAll';
import { TFiltersListCnf } from '@components/CommonFilters/types/filters';
import ContentWithSidebar from '@components/ContentWithSidebar';
import PageHead from '@components/PageHead';

import { fields } from '@configs/export/fields';
import dateConstants from '@constants/date';
import useCopy from '@hooks/useCopy';
import useGlobalLoading from '@hooks/useGlobalLoading';
import useProjectRequired from '@hooks/useProjectRequired';
import { useAppDispatch } from '@hooks/useStore';
import { globalActions } from '@redux/slices/global';
import { ReportName } from '@typings/reports';

import { IRootSlice } from '@typings/rootSlice';
import DateUtils from '@utils/date';
import cn from 'classnames';
import React, { FC, useEffect, useRef, useState } from 'react';
import DocumentTitle from 'react-document-title';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';

import s from './Export.pcss';

const Export: FC = () => {
    useGlobalLoading();

    const reportName = 'export' as ReportName;

    const { DATE_FORMAT } = dateConstants;

    const isProjectRequired = useProjectRequired();

    const params: IUrlParams = useParams();
    const projectId = Number(params.projectId);

    const [limit, setLimit] = useState('1');
    const initialUrl = 'https://ads.sber.ru/stat/data.csv';

    const [requestUrl, setRequestUrl] = useState(initialUrl);
    const [date, setDate] = useState(null);
    const [showUrl, setShowUrl] = useState(false);
    const [selectedFields, setSelectedFields] = useState([]);
    const [selectAllChecked, setSelectAllChecked] = useState(false);

    const globalLoading = useSelector((state: IRootSlice) => state.globalSlice.globalLoading);

    const projectURL = useSelector((state: IRootSlice) => state.projectsSlice.current.cleanedUrl);
    const userId = useSelector((state: IRootSlice) => state.userSlice.id);

    const dispatch = useAppDispatch();

    const { copy } = useCopy();

    const setGlobalLoading = (param: boolean) => dispatch(globalActions.setGlobalLoading(param));

    const showUrlHandler = () => {
        setShowUrl((prevShowUrl) => !prevShowUrl);
    };

    const changeDateHandler = ([dateStart, dateEnd]: moment.Moment[]): void => {
        const newDateStart = DateUtils.getDate(+dateStart).format(DATE_FORMAT);
        const newDateEnd = DateUtils.getDate(+dateEnd).format(DATE_FORMAT);
        setDate([newDateStart, newDateEnd]);
    };

    const checkboxHandler = (field: string): void => {
        if (field === 'all') {
            if (!selectAllChecked) {
                setSelectedFields(fields);
            } else {
                setSelectedFields([]);
            }
            setSelectAllChecked(!selectAllChecked);
        } else if (selectedFields.includes(field)) {
            const filteredArray = selectedFields.filter((item) => item !== field);
            setSelectedFields(filteredArray);
        } else {
            setSelectedFields([...selectedFields, field]);
        }
    };

    const getPageTitle = () => {
        const url = projectURL ? `(${projectURL}) ` : '';
        return `Экспорт ${url} – Статистика от SberAds / ${projectId}`;
    };

    const onChangeInputHandler = (e: React.ChangeEvent<HTMLInputElement>) => {
        const { value } = e.target;

        if (value === '' || /^\d*$/.test(value)) {
            setLimit(value);
        }
    };

    const onBlurInputHandler = () => {
        if (Number.isNaN(parseInt(limit, 10))) {
            setLimit('1');
        }
    };

    // FIXME убрать после интеграции фильтров
    const dynamicFiltersData = useSelector((state: IRootSlice) => state.globalSlice.dynamicFiltersDataNew);
    const dateStart = useSelector((state: IRootSlice) => state.globalSlice.dateStart);
    const dateEnd = useSelector((state: IRootSlice) => state.globalSlice.dateEnd);
    const groupBy = useSelector((state: IRootSlice) => state.globalSlice.groupBy);

    const filtersData = [
        { key: 'gender', op: '=', value: 'male' },
        { key: 'gender', op: '=', value: 'female' },
        { key: 'gender', op: 'include', value: ['male', 'female'] },
        { key: 'gender', op: 'exclude', value: ['male', 'female'] },
        { key: 'avg_recirculation', op: '>=', value: '1' },
        { key: 'avg_recirculation', op: '<=', value: '2' },
        { key: 'published_datetime', op: '>', value: '2024-01-01 10:00:00' },
        { key: 'published_datetime', op: '<', value: '2024-02-02 11:00:00' },
        // {key: 'published_date', op: '<', value: '2024-01-01'},
        // {key: 'published_time', op: '>', value: '10:00:00'},
    ];
    const filtersValues = useRef(null);

    const getDynamicFilterData = (dimension: string) => {
        const dimensionData = dynamicFiltersData?.[dimension];

        if (dimensionData?.data?.length >= dimensionData?.totals) return;

        dispatch(
            globalActions.getDynamicFilters({
                projectId,
                filterName: dimension,
                params: {
                    date_end: dateStart || 'today',
                    date_start: dateEnd || 'today',
                    group: groupBy || 'five_minute',
                    limit: 50,
                    offset: dimensionData?.data?.length || 0,
                    parents: [],
                },
            }),
        );
    };

    const copyHandler = (): void => {
        copy(requestUrl, 'Скопировано');
        // console.log(filtersValues.current, filtersValues.current?.getValues()); FIXME
    };

    const updateRequestUrl = () => {
        let url = initialUrl;

        url += `?limit=${limit}`;

        if (date !== null) {
            url += `&date_start=${date[0]}&date_end=${date[1]}`;
        }

        if (selectedFields.length > 0) {
            const fieldsString = selectedFields.join(',');
            url += `&fields=${fieldsString}`;
        }

        setRequestUrl(url);
    };

    useEffect(() => {
        if (!isProjectRequired) {
            setGlobalLoading(true);
            return;
        }

        updateRequestUrl();

        setGlobalLoading(false);
    }, [isProjectRequired, limit, date, selectedFields]);

    useEffect(
        () => () => {
            setGlobalLoading(false);
        },
        [],
    );

    return (
        <DocumentTitle title={getPageTitle()}>
            {!globalLoading && (
                <>
                    <PageHead
                        reportName={reportName}
                        projectId={projectId}
                        userId={userId}
                        title="Экспорт данных"
                        hintContent={{
                            message: '<p>Описание</p>',
                            isMore: false,
                        }}
                    />
                    <ContentWithSidebar>
                        <div className={s.wrapper}>
                            <CommonFilters
                                filtersData={filtersData}
                                filtersListCnf={filtersListCnf as TFiltersListCnf}
                                filtersFormCnf={filtersFormCnf}
                                ref={filtersValues}
                                dynamicFilterData={dynamicFiltersData}
                                getDynamicFilterData={getDynamicFilterData}
                                // onApply={(val) => console.log(val)}
                            />
                            <div className={s.line}>
                                <span className={s.fieldName}>Период:</span>
                                <DoubleDatepicker onChange={changeDateHandler} />
                            </div>
                            <div className={s.line}>
                                <span className={s.fieldName}>Лимит:</span>
                                <Input
                                    className={s.input}
                                    name="limit"
                                    placeholder="Максимум"
                                    value={limit}
                                    onChange={onChangeInputHandler}
                                    onBlur={onBlurInputHandler}
                                />
                            </div>
                            <div className={s.line}>
                                <span className={cn(s.fieldName, s.fieldNameStart)}>Поля для выгрузки:</span>
                                <div className={s.fieldsList}>
                                    <Checkbox
                                        className={s.firstCheckbox}
                                        onChange={() => checkboxHandler('all')}
                                        checked={selectAllChecked}
                                    >
                                        Выбрать все
                                    </Checkbox>
                                    {fields.map((field) => (
                                        <Checkbox
                                            key={field}
                                            checked={selectedFields.includes(field)}
                                            onChange={() => checkboxHandler(field)}
                                        >
                                            {field}
                                        </Checkbox>
                                    ))}
                                </div>
                            </div>
                            <div className={s.buttons}>
                                <Button type="default">Скачать файл</Button>
                                <Button type="dashed" onClick={showUrlHandler}>
                                    {showUrl ? 'Скрыть URL запроса' : 'Показать URL запроса'}
                                </Button>
                            </div>
                            {showUrl && (
                                <div className={s.urlWrapper}>
                                    <p>{requestUrl}</p>
                                    <Button className={s.btnCopy} type="dashed" onClick={copyHandler}>
                                        Скопировать
                                    </Button>
                                </div>
                            )}
                        </div>
                    </ContentWithSidebar>
                </>
            )}
        </DocumentTitle>
    );
};

export default Export;
