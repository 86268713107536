import React from 'react';

interface IProps {
    className: string;
    content: string;
}

export const PopupContent: React.FC<IProps> = ({ className, content }) => (
    <div className={className}>
        <p>{content}</p>
    </div>
);
