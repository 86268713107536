import { IReportConfig } from '@typings/reports';

const config: Partial<IReportConfig> = {
    reportTitle: 'Целевые события',
    tableDataType: 'dynamic',
    reportHint: {
        message: '<p data-testid="hint-text">Целевые события&nbsp;&mdash; это действия посетителей на&nbsp;вашем ресурсе, по&nbsp;которым можно построить конверсии. '
        + 'Некоторые события собираются автоматически, также можно настроить отправку <a target="_blank" href="https://ads.sber.ru/help/otpravka-sobstvennykh-sobytii">собственных событий</a>. К&nbsp;целевому событию можно задать параметры:</p> '
        + '<ul><li>&mdash; выбрать определенный источник трафика,</li>'
        + '<li>&mdash; определить демографические особенности,</li>'
        + '<li>&mdash; настроить отслеживание события на определенной странице</li></ul> '
        + '<p>В&nbsp;качестве целевого можно использовать любое событие&nbsp;&mdash; которое уже происходило на&nbsp;вашем ресурсе или&nbsp;то, которое может произойти в&nbsp;будущем.</p>'
        + '<p>При создании целевое событие доступно только его&nbsp;создателю, но&nbsp;его&nbsp;можно сделать публичным для всех аккаунтов, у&nbsp;которых есть доступ к&nbsp;проекту.</p>',
        link: 'https://ads.sber.ru/help/celevye-sobytiya',
        isMore: false,
    },
};

export default config;
