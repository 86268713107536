import error400 from './error400';
import error403 from './error403';
import error404 from './error404';
import error500 from './error500';
import error504 from './error504';

export default {
    error400,
    error403,
    error404,
    error500,
    error504,
};
