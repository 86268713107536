import tableConstants from '@constants/table';
import { ITableConstants } from '@typings/table';

const mediaConstants: ITableConstants = {
    ...tableConstants,
    MAX_SELECTED_ROWS: 10,
    METRICS_MAX_LIMIT: 7,
    METRICS_BIG_LIMIT: 4,
    METRICS_LIMIT: 3,
};

export default mediaConstants;
