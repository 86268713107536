import { Button } from '@adtech/ui';
import { ReportName } from '@typings/reports';
import React, { ReactNode } from 'react';
import s from './ReportPlaceholder.pcss';
import reportContent from './reportContent';

interface IProps {
    reportName: ReportName;
    projectId: number;
}

interface IReportContent {
    title: string;
    textBlocks: (projectId: number) => ReactNode[];
    link: string;
    image: ReactNode;
    buttonTitle?: string;
    demoBtnTitle?: string;
    demoBtnLink?: string;
}

const ReportPlaceholder: React.FC<IProps> = ({ reportName, projectId }) => {
    if (!reportContent[reportName]) return null;

    const { title, textBlocks, link, image, buttonTitle, demoBtnTitle, demoBtnLink }: IReportContent =
        reportContent[reportName];

    const isDemoBtnExist = demoBtnTitle && demoBtnLink;

    const renderTextBlock = (text: string, i: number) => (
        <div className={s.textBlocksItem} key={i}>
            {text}
        </div>
    );

    return (
        <div className={s.root} data-testid={`report-placeholder-${reportName}`}>
            <div className={s.image}>{image}</div>
            <h2 className={s.title}>{title}</h2>
            <div className={s.textBlocks}>
                {textBlocks(projectId).map((item: string, i: number) => renderTextBlock(item, i))}
            </div>
            <div className={s.buttons}>
                {isDemoBtnExist ? (
                    <Button type="dashed" className={s.button} href={demoBtnLink} target="_blank">
                        {demoBtnTitle}
                    </Button>
                ) : (
                    ''
                )}
                {link && (
                    <Button className={s.button} href={link} target="_blank">
                        {buttonTitle || 'Подробнее'}
                    </Button>
                )}
            </div>
        </div>
    );
};

export default ReportPlaceholder;
