/* eslint-disable max-len */
export default {
    adBlock: {
        showClose: true,
        title: 'У вас включен блокировщик рекламы',
        body:
            '<p>Некоторые функции платформы могут быть недоступны из-за включенных блокировщиков  рекламы.</p>' +
            '<p>Чтобы продолжить пользоваться SberAds, отключите блокировщик и перезагрузите страницу.</p>',
        storageKey: 'adBlockHidden',
    },
};
