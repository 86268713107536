import hiddenHint from '@constants/hiddenHint';
import reportsConstants from '@constants/reports';
import { IHint } from '@typings/hint';
import { ReportName } from '@typings/reports';
import storagesUtils from '@utils/storagesUtils';
import { useState, useEffect, useCallback } from 'react';

const useHint = (
    projectId: number,
    userId: number,
    reportName: ReportName,
    hintContent: IHint,
    isSubheader: boolean = false,
) => {
    const isShowAlways = projectId === reportsConstants.DEMO_PROJECT_ID;
    const hintExist = hintContent && hintContent.message;

    const storageHintName = `${isSubheader ? hiddenHint.subheaderHint : hiddenHint.reportHint}${reportName}`;
    const storageAllHintsName = hiddenHint.allHints;

    const getStorageValue = useCallback(
        () => !!storagesUtils.get(storageHintName, userId) || !!storagesUtils.get(storageAllHintsName, userId),
        [storageHintName, storageAllHintsName, userId],
    );

    const [showHintOnClick, setToggleShowHint] = useState(false);
    const [isHiddenHint, setHiddenHint] = useState((getStorageValue() && !isShowAlways) || !hintExist);

    const showQuestionIcon = hintExist && isHiddenHint;

    useEffect(() => {
        const newHintExistInStorage = getStorageValue();

        setHiddenHint((!!newHintExistInStorage && !isShowAlways) || !hintExist);
    }, [reportName, hintExist, isShowAlways, userId]);

    const handleShowHint = () => {
        if (!isHiddenHint) {
            setToggleShowHint(true);
        } else {
            storagesUtils.set(storageHintName, '', userId);
            setHiddenHint(false);
        }
    };

    const handleHiddenHint = () => {
        if (isHiddenHint) {
            setToggleShowHint(false);
        } else {
            storagesUtils.set(storageHintName, 'on', userId);
            setHiddenHint(true);
        }
    };

    const handleHiddenAllHints = () => {
        if (isHiddenHint) {
            setToggleShowHint(false);
        }
        storagesUtils.set(storageAllHintsName, 'on', userId);
        setHiddenHint(true);
    };

    return {
        handleHiddenHint,
        handleShowHint,
        handleHiddenAllHints,
        setToggleShowHint,
        showHintOnClick,
        showQuestionIcon,
        isHiddenHint,
        isShowAlways,
    };
};

export default useHint;
