import { dimensionsDict, DimensionValueType } from '@configs/dimensions';
import mediaConfig from '@configs/media';
import { ESpecialEventsClasses } from '@constants/events';
import { Op } from '@constants/listData';
import mediaConstants from '@constants/media';
import { EPeriodDetail } from '@constants/periodDetail';
import tableConstants from '@constants/table';
import { useGraph } from '@hooks/useGraph';
import { useRequestParams, useRequestParamsMedia } from '@hooks/useRequestParams';
import { useAppDispatch } from '@hooks/useStore';
import { tableActions } from '@redux/slices/table';
import * as Sentry from '@sentry/react';
import { Dimensions } from '@typings/dimensions';
import { SelectedLines } from '@typings/graph';
import { IMediaRequestParams, ITableReqResponse, ReportNameMedia } from '@typings/media';
import { IMetricsWithStates, Metrics } from '@typings/metrics';
import { IReportRequestParams, IUpdateReportParams, IUpdateReportSortOpts, ReportNameBase } from '@typings/reports';
import { IRootSlice } from '@typings/rootSlice';
import { IReportTableReqResponse, IUpdateTableParams } from '@typings/table';
import DateUtils from '@utils/date';
import globalUtilsFunc from '@utils/global';
import reportsUtilsFunc from '@utils/reports';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { AnyAction } from 'redux';

export const useTable = () => {
    const { DEFAULT_LINES_COUNT, PARAMS_DELIMITER, PARENTS_DELIMITER, ROOT_NODE } = tableConstants;

    const params: IUrlParams = useParams();

    const dispatch = useAppDispatch();

    const reportsSlice = useSelector((state: IRootSlice) => state.reportsSlice);
    const tableSlice = useSelector((state: IRootSlice) => state.tableSlice);
    const graphSlice = useSelector((state: IRootSlice) => state.graphSlice);
    const globalSlice = useSelector((state: IRootSlice) => state.globalSlice);

    const {
        api: { getStoreParams, getParamsForRequest },
        reportsUtils,
        tableUtils,
    } = useRequestParams();

    const {
        api: { getGraph, updateGraphParams, getChildSelectedLines, getSelectedLinesDict },
    } = useGraph();

    const api = {
        updateTableParams(updateParams: IUpdateTableParams, withoutCompare?: boolean) {
            const { compareParams, filterParamsForStore } = globalUtilsFunc();
            let newParams = { ...updateParams };

            if (newParams.metrics) {
                const tableFilters = tableSlice.tableFilters.length ? tableSlice.tableFilters : newParams?.tableFilters;

                if (tableFilters?.length) {
                    newParams.tableFilters = tableFilters.filter((filterItem) =>
                        newParams.metrics.find((metric) => metric === filterItem.key),
                    );
                }
            }

            newParams = filterParamsForStore(newParams, tableSlice);

            const noParamsUpdate =
                !withoutCompare &&
                !compareParams(
                    {
                        ...tableSlice,
                    },
                    newParams,
                );

            if (Object.keys(newParams).length) dispatch(tableActions.updateParams(newParams));

            return { newParams, noParamsUpdate };
        },

        getTable(
            projectId: string,
            reportName: ReportNameBase,
            requestParams: IReportRequestParams,
            meta?: any,
        ): Promise<IReportTableReqResponse> {
            return dispatch(
                tableActions.getReportTable({
                    projectId,
                    reportName,
                    params: requestParams,
                    meta: api.addTableRequestId(meta),
                }),
            ).unwrap();
        },

        /**
         * Меняем сортировку на клиенте без запросов к бэку
         */
        updateTableSort({ orderBy, data, metrics, reportName }: IUpdateReportSortOpts): Promise<SelectedLines> {
            if (reportsUtils.isSummary(reportName)) {
                dispatch(tableActions.removeTableRowsSummary());
            }

            const tableData = tableUtils.updateFromSort({
                sort: orderBy,
                data,
                metrics,
            });

            return Promise.all([dispatch(tableActions.updateParams({ orderBy, tableData }))]).then(() =>
                tableData.map[ROOT_NODE].list.slice(0, DEFAULT_LINES_COUNT).map((id, i) => ({ id, colorIndex: i })),
            );
        },

        getParamsForConstructor(reqParams: IReportRequestParams, id: string, parents: string[], offset?: number) {
            const { reportName, projectId } = params;
            const requestParams = { ...reqParams };
            const { tableData, dimensions } = tableSlice;
            const node = tableData.dict[id];
            const parentsLength = parents.length;
            let key = '';
            // Если дочерний параметр, requestedDimension — дименшин-родитель
            const requestedDimensionIndex =
                node.type === DimensionValueType.key ? node.parentDimensionIndex : node.parentDimensionIndex + 1;
            let requestedDimension = dimensions[requestedDimensionIndex];
            // Фильтры
            let dimensionKeys = [];
            let filters = [];

            // Не выводим технические события.
            // Сделано через includes, чтобы при раскрытии дочерних также не отображались тех. события
            if (dimensions.includes('event_class')) {
                filters.push({
                    key: 'event_class',
                    op: '!=',
                    value: ESpecialEventsClasses.tech,
                });
            }

            parents.forEach((parent, i: number) => {
                key += key ? PARENTS_DELIMITER + parent : parent;
                const item = tableData.dict[key];
                const type = item.type;
                const dimensionIndex: number = item.parentDimensionIndex;
                const dimension = dimensions[dimensionIndex];
                const dimensionConfig = dimensionsDict[dimension];
                const value = item?.id || ''; // id может быть undefined, если на бэке значение дименшена равно пустой строке
                const isDatePeriodValue =
                    dimensions[dimensionIndex] === dimensionsDict.date.name && value.includes(' - ');
                const filter = {
                    key: dimension,
                    op: Op.eq,
                    value,
                };

                switch (type) {
                    case DimensionValueType.valueByKey: {
                        // Разделение фильтрации по значению и ключам
                        filters = [
                            ...filters,
                            { ...filter, key: dimensionConfig.subDimensionForValueByKey || filter.key },
                            { ...filter, value: dimensionKeys.join(PARAMS_DELIMITER) },
                        ];
                        // Обнуление списка ключей
                        dimensionKeys = [];
                        break;
                    }
                    case DimensionValueType.key: {
                        // Обновление списка ключей
                        dimensionKeys = [...dimensionKeys, value];
                        // Если значение крайнее и является ключом, в фильтре передается список ключей
                        if (parentsLength - 1 === i) {
                            // Переопределяем requestedDimension
                            // TODO: временный фикс, будет исправлено в отдельной задаче
                            requestedDimension =
                                dimensionConfig.name !== 'customer_params_key'
                                    ? dimensionConfig.subDimensionForValueByKey
                                    : requestedDimension;
                            filters = [...filters, { ...filter, value: dimensionKeys.join(PARAMS_DELIMITER) }];
                        }
                        break;
                    }
                    case DimensionValueType.value:
                    default: {
                        // Для диапазона дат формируется фильтр с range
                        if (isDatePeriodValue) {
                            const dateValue = id.split(' - ');
                            filter.op = 'range';
                            filter.value = [dateValue[0], dateValue[1]];
                        }
                        filters = [...filters, filter];
                        break;
                    }
                }

                return filters;
            });

            requestParams.dimension_filters = [...(requestParams?.dimension_filters || []), ...filters];

            requestParams.dimensions = [requestedDimension];

            // При раскрытии узлов в конструкторе не учитываем введенный offset, только для root
            if (offset) {
                requestParams.offset = offset;
            } else {
                delete requestParams.offset;
            }

            return dispatch(
                tableActions.getConstructorTable({
                    projectId,
                    reportName,
                    params: requestParams,
                    meta: {
                        expandedNode: id, // раскрытие узла, а не загрузка всей таблицы
                    },
                }),
            );
        },

        async extendTableNode(id: string, options?: { needToOpenNode: boolean }): Promise<any> {
            const { dict } = tableSlice.tableData;

            const isDataExists = !!Object.keys(dict).filter((key) => dict[key].parentId === id).length;

            if (!isDataExists || options?.needToOpenNode) {
                await api.newLevelRequest(id);
            }

            dispatch(tableActions.toggleTableNode({ id, options }));
        },

        newLevelRequest(id: string) {
            const { reportName, projectId } = params;
            const parents = id.split(PARENTS_DELIMITER);
            const isConstructor = reportsUtils.isConstructor(reportName);

            const defaultParamsForRequest: IUpdateReportParams = getStoreParams();
            const requestParams = getParamsForRequest(reportName, 'table', {
                ...defaultParamsForRequest,
                projectId,
            });

            if (isConstructor) {
                return api.getParamsForConstructor(requestParams, id, parents);
            }

            return dispatch(
                tableActions.updateTableExpand({
                    projectId,
                    reportName,
                    params: {
                        ...requestParams,
                        parents,
                    },
                }),
            );
        },

        updateGraphAfterTableOffset(response) {
            const { reportName, projectId } = params;
            const { selectedLines, useDefaultLines } = graphSlice;
            const { data } = response?.result || {};

            if (!useDefaultLines && data.list?.length) {
                const linesInResult = data.list.filter((item) => selectedLines.includes(item));

                if (linesInResult.length) {
                    const requestParams = getParamsForRequest(reportName, 'graph', {
                        projectId,
                    });
                    getGraph(projectId, reportName, requestParams);
                }
            }

            // Обработка для рейтинга, у него приходит data, а не data.list
            if (reportsUtils.isRating(reportName) && !useDefaultLines && data?.length) {
                updateGraphParams({ selectedLines });
                const requestParams = getParamsForRequest(reportName, 'graph', {
                    projectId,
                });
                getGraph(projectId, reportName, requestParams);
            }
        },

        updateTableHeadOffset(offset: number, limit: number) {
            const { reportName, projectId } = params;

            let requestParams = {};

            requestParams = getParamsForRequest(reportName, 'table', {
                ...reportsSlice,
                ...tableSlice,
                ...globalSlice,
                limit,
                projectId,
            });

            return dispatch(
                tableActions.updateTableHeadOffset({
                    projectId,
                    reportName,
                    params: {
                        ...requestParams,
                        offset,
                        limit,
                    },
                }),
            )
                .unwrap()
                .then((res) => {
                    api.updateGraphAfterTableOffset(res);
                    // В том числе, чтобы в юнит тестах не зависали промисы
                })
                .catch((err) => {
                    Sentry.captureException(err);
                });
        },

        updateTableOffset(offset: number, parents: string[]) {
            const { reportName, projectId } = params;
            const isConstructor = reportsUtils.isConstructor(reportName);
            const isRoot = parents.length === 1 && parents[0] === ROOT_NODE;

            if (reportsUtils.isSummary(reportName)) {
                return dispatch(tableActions.addTableRowsSummary(offset));
            }

            const requestParams = getParamsForRequest(reportName, 'table', {
                ...reportsSlice,
                ...tableSlice,
                ...globalSlice,
                projectId,
            });

            if (isConstructor) {
                const id = parents.join(PARENTS_DELIMITER);
                return api.getParamsForConstructor(requestParams, id, parents, offset);
            }

            return dispatch(
                tableActions.updateTableOffset({
                    projectId,
                    reportName,
                    params: {
                        ...requestParams,
                        offset,
                        parents: isRoot ? undefined : parents,
                    },
                }),
            )
                .unwrap()
                .then((res) => {
                    api.updateGraphAfterTableOffset(res);
                    // В том числе, чтобы в юнит тестах не зависали промисы
                })
                .catch((err) => {
                    Sentry.captureException(err);
                });
        },

        toggleMetricWithState(metric: Metrics, metricsWithStates: IMetricsWithStates[]): AnyAction {
            return dispatch(tableActions.toggleMetricWithState({ metric, metricsWithStates }));
        },

        resetTable() {
            dispatch(tableActions.allReset());
        },

        /**
         * Запрашиваем родительские ноды для открытия дочерних
         */
        async requestExtendTable(parents: string[]): Promise<unknown> {
            // используем for...of с await для последовательной отправки запросов
            // eslint-disable-next-line no-restricted-syntax
            for (const parent of parents) {
                // eslint-disable-next-line no-await-in-loop
                await api.extendTableNode(parent, { needToOpenNode: true });
            }
            return Promise.resolve();
        },

        /**
         * Делаем запросы для каждого уровня вложенности
         */
        async handleDict(dict: Map<number, string[]>): Promise<unknown> {
            // используем for...of с await для последовательной отправки запросов
            // eslint-disable-next-line no-restricted-syntax
            for (const value of dict.values()) {
                await api.requestExtendTable(value); // eslint-disable-line no-await-in-loop
            }
            return Promise.resolve();
        },

        /**
         * Создаем запросы для открытия вложенных уровней в таблице
         */
        openSelectedNodes(newSelectedLines: SelectedLines): void {
            const childSelectedLines = getChildSelectedLines(newSelectedLines);
            const selectedLinesDict = getSelectedLinesDict(childSelectedLines);

            if (childSelectedLines.length) {
                api.handleDict(selectedLinesDict);
            }
        },

        clearTable(): void {
            dispatch(tableActions.resetTable());
        },

        setRequestTable(): void {
            dispatch(tableActions.setRequestTable());
        },

        resetRequestTable(): void {
            dispatch(tableActions.resetRequestTable());
        },

        resetParam(param: string): void {
            dispatch(tableActions.resetParam(param));
        },

        /**
         * Добавляем к мета информации id запроса для отсеивания результатов старых запросов
         */
        addTableRequestId(meta: any): Record<string, any> {
            let { lastRequestId } = tableSlice;
            lastRequestId += 1;

            api.updateTableParams({ lastRequestId });

            return { ...meta, lastRequestId };
        },
    };

    return {
        api,
        tableUtils,
    };
};

export const useTableMedia = () => {
    const { api: baseTableApi } = useTable();
    const params: IUrlParams<ReportNameMedia> = useParams();

    const {
        api: { getParamsForRequest, getStoreParams },
        tableUtils,
    } = useRequestParamsMedia();

    const reportsUtils = reportsUtilsFunc({
        reportsConfig: mediaConfig,
    });

    const dispatch = useAppDispatch();

    const globalSlice = useSelector((state: IRootSlice) => state.globalSlice);
    const tableSlice = useSelector((state: IRootSlice) => state.tableSlice);

    // Определяем новые и переопределяем базовые методы
    const api = {
        ...baseTableApi,

        /**
         * Запрашиваем данные для таблицы
         */
        getTable(
            projectId: string,
            reportName: ReportNameMedia,
            requestParams: IMediaRequestParams<Metrics | Dimensions>,
        ): Promise<ITableReqResponse> {
            return new Promise((resolve, reject) => {
                const apiExtraPath = reportsUtils.getParamFromConfig(reportName, 'apiExtraPath', '');
                const dimensions = reportsUtils.getParamFromConfig(reportName, 'availableDimensions');
                const limit = reportsUtils.getParamFromConfig(reportName, 'tableLimit');
                const fixedMetrics = reportsUtils.getParamFromConfig(reportName, 'tableFixedMetrics', []);
                // Фиксируем tableFixedMetris из конфига на первых позициях метрик в таблице
                const metricsParam = [...new Set([...fixedMetrics, ...requestParams.metrics])];

                const newParams = {
                    ...requestParams,
                    dimensions,
                    ...(fixedMetrics ? { metrics: metricsParam } : {}),
                    // Глобальные метричные фильтры + табличные
                    ...(requestParams.table_filters ? { metric_filters: requestParams.table_filters } : {}),
                    ...(limit ? { limit } : {}),
                };
                delete newParams.table_filters;

                dispatch(
                    tableActions.getMediaTable({
                        projectId,
                        params: newParams,
                        apiExtraPath,
                    }),
                )
                    .unwrap()
                    .then((res: ITableReqResponse & { responseStatus: number }) => {
                        if (res?.responseStatus !== 200) return;

                        dispatch(
                            tableActions.updateMediaTable({
                                ...res,
                                reportName,
                                projectId,
                            }),
                        );

                        resolve(res);
                        // В том числе, чтобы в юнит тестах не зависали промисы
                    })
                    .catch((err) => {
                        reject();
                        Sentry.captureException(err);
                    });
            });
        },

        /**
         * Запрашиваем больше строк для показа в таблице отчёта
         */
        updateTableOffset(offset: number, parents: string[]) {
            const { reportName, projectId, mediaId } = params;
            const apiExtraPath = reportsUtils.getParamFromConfig(reportName, 'apiExtraPath', '');
            const isRoot = parents.length === 1 && parents[0] === mediaConstants.ROOT_NODE;

            const requestParams = getParamsForRequest(reportName, {
                ...getStoreParams({}),
                dimensions: reportsUtils.getParamFromConfig(reportName, 'availableDimensions'),
                sample: tableSlice.sample,
                projectId,
                ...(mediaId ? { mediaId } : {}),
            });

            return dispatch(
                tableActions.updateMediaTableOffset({
                    projectId,
                    reportName,
                    apiExtraPath,
                    params: {
                        ...requestParams,
                        offset,
                        parents: isRoot ? undefined : parents,
                    },
                }),
            ).unwrap();
        },

        updateMediaTopTable(offset?: number) {
            const { reportName, projectId } = params;
            const apiExtraPath = reportsUtils.getParamFromConfig(reportName, 'apiExtraPath', '');
            const dimensions = reportsUtils.getParamFromConfig(reportName, 'availableDimensions');
            const limit = reportsUtils.getParamFromConfig(reportName, 'tableLimit');

            const requestParams = getParamsForRequest(reportName, {
                ...getStoreParams({}),
                dimensions,
                sample: tableSlice.sample,
                projectId,
            });
            const [dateStart, dateEnd] = DateUtils.getDatesByPeriodDetail(
                globalSlice.periodDetail,
                globalSlice.timeOffset,
            );

            if (offset) requestParams.offset = offset;
            if (limit) requestParams.limit = limit;
            if (dateStart && dateEnd) {
                const periodFilterKey =
                    globalSlice.periodDetail === EPeriodDetail.thirtyMinutes ? 'five_minute' : 'hour';

                requestParams.dimension_filters = [
                    ...(requestParams?.dimension_filters || []),
                    { key: periodFilterKey, op: 'range', value: [dateStart, dateEnd] },
                ];
            }

            return dispatch(
                tableActions.updateMediaTableOffset({
                    projectId,
                    reportName,
                    apiExtraPath,
                    params: requestParams,
                    isTopTable: true,
                }),
            ).unwrap();
        },
    };

    return {
        api,
        tableUtils,
    };
};
