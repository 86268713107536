import { ChartGroupBy as GroupBy } from '@adtech/charts/dist/config';

export { GroupBy };

export default {
    period: {
        day: [
            GroupBy.fiveMinute,
            GroupBy.hour,
        ],
        lessWeek: [
            GroupBy.hour,
            GroupBy.day,
        ],
        lessMonth: [
            GroupBy.day,
            GroupBy.week,
        ],
        moreMonth: [
            GroupBy.day,
            GroupBy.week,
            GroupBy.month,
        ],
        // Конфиг для учета periodDetail = 30 минут
        lessHour: [
            GroupBy.minute,
            GroupBy.fiveMinute,
        ],
    },
    title: {
        [GroupBy.minute]: 'по минутам',
        [GroupBy.fiveMinute]: 'по 5 минут',
        [GroupBy.hour]: 'по часам',
        [GroupBy.day]: 'по дням',
        [GroupBy.week]: 'по неделям',
        [GroupBy.month]: 'по месяцам',
    },
};
