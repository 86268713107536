import { Tooltip } from '@adtech/ui';
import attributesData from '@configs/attributesData';
import IconArrowRight from '@images/svg/icons/icon-arrow-right.svg';
import { DataArrayType } from '@typings/graph';
import { Metrics } from '@typings/metrics';
import { ISort } from '@typings/table';
import { measuresUtils, widgetUtils } from '@utils/index';
import cn from 'classnames';
import React, { FC, ReactNode } from 'react';
import s from './WidgetTable.pcss';
import WidgetTableCell from './components/TableCell';

interface IProps {
    name: string;
    attrCnfName?: string;
    dataTable: DataArrayType | DataArrayType[];
    dataTotals?: [string, number][];
    visibleMetrics: Metrics[];
    selectedMetric: Metrics;
    labels?: string[];
    tableTitle: string;
    notHiddenHeads: boolean;
    emptyTableTitleAlias?: string;
    isDisabledTabs: boolean;
    isDisabledSort: boolean;
    sort: ISort;
    onChangeSort: (visibleMetrics: Metrics[], selectedMetric: Metrics, sort: { name: string; order: string }) => void;
}

const WidgetTable: FC<IProps> = ({
    name = '',
    attrCnfName = '',
    dataTable = [],
    dataTotals = [],
    visibleMetrics = [],
    selectedMetric = null,
    labels = [],
    tableTitle = '',
    notHiddenHeads = false,
    emptyTableTitleAlias,
    isDisabledTabs = false,
    isDisabledSort = false,
    sort = null,
    onChangeSort = null,
}) => {
    const isNotOneVisibleMetrics = visibleMetrics.length > 1;
    const cellTitleClassNames = cn(s.cellTitle, { [s.cellTitleBig]: isNotOneVisibleMetrics });

    const dataAttr = attrCnfName in attributesData ? attributesData[attrCnfName] : {};
    const dataAttrName = dataAttr.attrName;
    const widgetsDataAttr = dataAttr.widgets || {};
    const widgetsAttrName = widgetsDataAttr.attrName;
    const tableWidgetAttrName = name in widgetsDataAttr ? widgetsDataAttr[name]?.widgetName : '';

    // Рендер первой ячейки в виде списка или ссылки,
    // в зависимости от типа
    const renderTitleCellContent = (item: string | string[], itemLabel?: string): ReactNode => {
        if (Array.isArray(item)) {
            const content = (
                <div className={s.titleList}>
                    {item.map((title, ind) => (
                        <span className={s.titleListItem} key={`${title || emptyTableTitleAlias + ind}`}>
                            {title || emptyTableTitleAlias}
                            <IconArrowRight className={s.titleListItemArrow} />
                        </span>
                    ))}
                </div>
            );
            return (
                <Tooltip placement="top" className={s.tooltipAnchor} overlayClassName={s.tooltip} title={content}>
                    {content}
                </Tooltip>
            );
        }
        return (
            <Tooltip placement="top" title={item} className={s.tooltipAnchor}>
                <div className={s.titleLinkWrapper}>
                    <div className={s.titleLink}>
                        <a
                            href={item}
                            className={s.titleLinkContent}
                            target="_blank"
                            data-dashboard={`${widgetsAttrName}::${tableWidgetAttrName}::${widgetsDataAttr.link}`}
                            rel="noopener"
                        >
                            {itemLabel || item}
                        </a>
                        {/* Дубль ссылки на случай, если блокировщик рекламы скроет ссылку */}
                        <span className={s.titleLinkContent}>{item}</span>
                    </div>
                </div>
            </Tooltip>
        );
    };

    // Рендер ячейки с метрикой
    const renderMetricCell = (metric: Metrics, key: number) => {
        const isBold = sort?.name === metric && isNotOneVisibleMetrics;
        const title = measuresUtils.getTitle(metric, null);
        const linkToDoc = measuresUtils.getParamFromConfig(metric, 'linkToDoc');
        const description = measuresUtils.getTooltip(metric);
        const tooltipContent = (
            <>
                {title}
                <br /> {description}
                {linkToDoc && (
                    <>
                        <br />
                        <a href={linkToDoc} target="_blank" rel="noopener">
                            Подробнее в&nbsp;Документации
                        </a>
                    </>
                )}
            </>
        );
        const dataAttrList = !isDisabledSort
            ? [widgetsAttrName, tableWidgetAttrName, widgetsDataAttr.sort, metric]
            : [];

        return (
            <WidgetTableCell
                key={`cell_${metric}_${key}`}
                sort={sort?.name === metric ? sort : null}
                onChangeSort={!isDisabledSort ? () => onChangeSort(visibleMetrics, metric, sort) : null}
                dataAttrName={dataAttrName}
                dataAttrList={dataAttrList}
                isBold={isBold}
                isHead
                notHiddenHeads={notHiddenHeads}
            >
                <Tooltip
                    title={tooltipContent}
                    placement="bottom"
                    className={cn({
                        [s.tooltipAnchor]: !notHiddenHeads,
                    })}
                >
                    {title}
                </Tooltip>
            </WidgetTableCell>
        );
    };

    // Рендер ячейки со значением
    const renderValueCell = (value: number, key: number) => {
        const activeMetric = visibleMetrics[key];
        const isBold = sort?.name === activeMetric && isNotOneVisibleMetrics;
        const newValue = widgetUtils.digitalsStringify(value, activeMetric);
        return (
            <WidgetTableCell key={`cell_value_${key}`} isBold={isBold}>
                {newValue}
            </WidgetTableCell>
        );
    };

    // Рендер шапки таблицы
    const renderRowHead = (
        <tr className={cn(s.row, s.rowHead)}>
            <WidgetTableCell
                key="cell_table_title"
                className={cellTitleClassNames}
                isHead
                notHiddenHeads={notHiddenHeads}
            >
                <Tooltip
                    className={cn({
                        [s.tooltipAnchor]: !notHiddenHeads,
                    })}
                    title={tableTitle}
                    placement="bottom"
                >
                    {tableTitle}
                </Tooltip>
            </WidgetTableCell>
            {visibleMetrics.map((item, i) => renderMetricCell(item, i))}
        </tr>
    );

    // Рендер тела таблицы
    const renderRowBody = (titleCellContent: ReactNode, data: number[], key: number): ReactNode => {
        const index = visibleMetrics.indexOf(selectedMetric) || 0;
        const values = isDisabledTabs && isNotOneVisibleMetrics ? data : [data[index]];

        return (
            <tr className={s.row} key={`row_${key}`}>
                <WidgetTableCell key="cell_title" className={cellTitleClassNames}>
                    {titleCellContent}
                </WidgetTableCell>
                {values.map((item, i) => renderValueCell(item, i))}
            </tr>
        );
    };

    return (
        <table className={s.table}>
            <thead>{renderRowHead}</thead>
            <tbody>
                {dataTable.map((item, i) =>
                    renderRowBody(renderTitleCellContent(item[0], labels[i]), item.slice(1) as number[], i),
                )}
            </tbody>
            {dataTotals?.length ? (
                <thead>
                    <tr className={s.rowHead}>
                        <WidgetTableCell className={cellTitleClassNames}>{dataTotals[0]}</WidgetTableCell>
                        <WidgetTableCell>{dataTotals[1]}</WidgetTableCell>
                    </tr>
                </thead>
            ) : null}
        </table>
    );
};

export default WidgetTable;
