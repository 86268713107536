import { LatencyKeys } from '@constants/latency';
import { getLatency } from '@redux/slices/latency/api';
import { createSlice } from '@reduxjs/toolkit';
import { ILatencySlice } from '@typings/rootSlice';

export const initialState: ILatencySlice = {
    base: null,
    eventBase: null,
    media: null,
    mediaAuthor: null,
    today: null,
};

export const setLatencyRequest = (state: ILatencySlice, key: LatencyKeys) => {
    if (!state[key]) state[key] = {};
    state[key].latencyRequest = true;
    state[key].isExcludedLatency = null;
};

/**
 * Запись полученных временных границ для отчётов
 */
export const setLatency = (state: ILatencySlice, action, key: LatencyKeys) => {
    const { result, error } = action.payload;

    if (error) return state;

    if (!state[key]) state[key] = {};
    Object.assign(state[key], result);
    state[key].latencyRequest = false;
};

/**
 * Установка временных границ только на сегодня,
 * пока нужно только для отчёта по топу медиа
 */
export const setLatencyToday = (state: ILatencySlice, action) => {
    const todayDate = action.payload;
    const newAction = {
        ...action,
        payload: {
            ...action.payload,
            result: {
                start: todayDate,
                end: todayDate,
            },
        },
    };

    setLatency(state, newAction, LatencyKeys.today);
};

/**
 * Устанавливаем параметр isExcludedLatency, определяющий
 * что выбранные даты за границами latency
 */
export const updateExcludedParamFunc = (state: ILatencySlice, action) => {
    const { result, latencyKey } = action.payload;

    if (!state[latencyKey]) state[latencyKey] = {};

    state[latencyKey].latencyRequest = true;
    state[latencyKey].isExcludedLatency = result;
};

const latencySlice = createSlice({
    name: 'latencySlice',
    initialState,
    reducers: {
        getLatencyToday: (state, action) => setLatencyToday(state, action),
        updateExcludedParam: (state, action) => updateExcludedParamFunc(state, action),
    },
    extraReducers: (builder) => {
        builder
            .addCase(getLatency.pending, (state) => setLatencyRequest(state, LatencyKeys.base))
            .addCase(getLatency.fulfilled, (state, action) => setLatency(state, action, LatencyKeys.base));
    },
});

export const { getLatencyToday, updateExcludedParam } = latencySlice.actions;

export const latencyActions = {
    getLatencyToday,
    updateExcludedParam,
    getLatency,
};

export default latencySlice.reducer;
