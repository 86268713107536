import { Button } from '@adtech/ui';
import CategoriesList from '@components/CategoriesList';
import ConstructorSettings from '@components/ConstructorSettings';
import ConstructorView from '@components/ConstructorView';
import ContentWithSidebar from '@components/ContentWithSidebar';
import Csv from '@components/Csv';
import PageHead from '@components/PageHead';
import ReportPlaceholder from '@components/ReportPlaceholder';
import ReportTable from '@components/ReportTable';
import ReportTools from '@components/ReportTools';
import { popularityFilters, staticFilters } from '@configs/filters/filtersConfigEventBased';
import reportsParamsConfig from '@configs/reportsParams';
import dateConstants from '@constants/date';
import { LatencyKeys } from '@constants/latency';
import reportsConstants from '@constants/reports';
import ChartContainer from '@containers/Chart';
import { Error507 } from '@containers/Error';
import { useAvailableToolsReport } from '@hooks/useAvailableTools';
import useCheckDates from '@hooks/useCheckDates';
import useCheckReportAccess from '@hooks/useCheckReportAccess';
import useFilters from '@hooks/useFilters';
import useGlobalLoading from '@hooks/useGlobalLoading';
import { useGraph } from '@hooks/useGraph';
import useLatency from '@hooks/useLatency';
import useModalPopup from '@hooks/useModalPopup';
import useNotification from '@hooks/useNotification';
import useProjectRequired from '@hooks/useProjectRequired';
import useQuery from '@hooks/useQuery';
import useReport from '@hooks/useReport';
import { useRestrictAccessByEmail } from '@hooks/useRestrictAccessByEmail';
import { useTable } from '@hooks/useTable';
import useTemplates from '@hooks/useTemplates';
import IconSave from '@images/svg/icons/icon-save.svg';
import { globalActions } from '@redux/slices/global';
import { DateLiteral } from '@typings/date';
import { IRootSlice } from '@typings/rootSlice';
import DateUtils from '@utils/date';
import cn from 'classnames';
import React, { FC, useEffect, useRef } from 'react';
import DocumentTitle from 'react-document-title';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';

import s from './Report.pcss';

const { DATE_FORMAT } = dateConstants;

const Report: FC = () => {
    useGlobalLoading();

    const { reportsUtils, api: apiReport } = useReport();
    const { tableUtils, api: apiTable } = useTable();
    const { graphUtils, api: apiGraph } = useGraph();
    const { api: apiTemplates } = useTemplates();
    const api = { ...apiTable, ...apiGraph, ...apiReport };
    const utils = { ...reportsUtils, ...tableUtils };

    const isProjectRequired = useProjectRequired();
    const isReportAccess = useCheckReportAccess({ isProjectRequired });
    const params: IUrlParams = useParams();
    const { reportName } = params;

    const userEmail = useSelector((state: IRootSlice) => state.userSlice.email);
    const userId = useSelector((state: IRootSlice) => state.userSlice.id);

    const globalLoading = useSelector((state: IRootSlice) => state.globalSlice.globalLoading);
    const globalFilters = useSelector((state: IRootSlice) => state.globalSlice.globalFilters);
    const groupBy = useSelector((state: IRootSlice) => state.globalSlice.groupBy);
    const dateStart = useSelector((state: IRootSlice) => state.globalSlice.dateStart);
    const dateEnd = useSelector((state: IRootSlice) => state.globalSlice.dateEnd);
    const categoryId = useSelector((state: IRootSlice) => state.globalSlice.categoryId);
    const serverTime = useSelector((state: IRootSlice) => state.globalSlice.serverTime);
    const dynamicFiltersData = useSelector((state: IRootSlice) => state.globalSlice.dynamicFiltersData);

    const viewType = useSelector((state: IRootSlice) => state.reportsSlice.viewType);

    const savedTemplateId = useSelector((state: IRootSlice) => state.templatesSlice.savedTemplate?.id);
    const savedTemplateTitle = useSelector((state: IRootSlice) => state.templatesSlice.savedTemplate?.title);

    const sample = useSelector((state: IRootSlice) => state.tableSlice.sample);
    const tableData = useSelector((state: IRootSlice) => state.tableSlice.tableData);
    const tableRequest = useSelector((state: IRootSlice) => state.tableSlice.tableRequest);
    const metrics = useSelector((state: IRootSlice) => state.tableSlice.metrics);
    const dimensions = useSelector((state: IRootSlice) => state.tableSlice.dimensions);
    const allMetrics = useSelector((state: IRootSlice) => state.tableSlice.allMetrics);
    const errorCode = useSelector((state: IRootSlice) => state.tableSlice.errorCode);
    const orderBy = useSelector((state: IRootSlice) => state.tableSlice.orderBy);
    const metricsWithStates = useSelector((state: IRootSlice) => state.tableSlice.metricsWithStates);
    const totals = useSelector((state: IRootSlice) => state.tableSlice.totals);
    const titleFilter = useSelector((state: IRootSlice) => state.tableSlice.titleFilter);
    const tableFilters = useSelector((state: IRootSlice) => state.tableSlice.tableFilters);
    const totalRows = useSelector((state: IRootSlice) => state.tableSlice.totalRows);
    const counterTableRowsReport = useSelector((state: IRootSlice) => state.tableSlice.counterTableRowsReport);
    const tableOffset = useSelector((state: IRootSlice) => state.tableSlice.offset);
    const tableLimit = useSelector((state: IRootSlice) => state.tableSlice.limit);

    const graphRequest = useSelector((state: IRootSlice) => state.graphSlice.graphRequest);
    const graphs = useSelector((state: IRootSlice) => state.graphSlice.graphs);
    const graphData = useSelector((state: IRootSlice) => state.graphSlice.graphData);
    const selectedLines = useSelector((state: IRootSlice) => state.graphSlice.selectedLines);
    const useDefaultLines = useSelector((state: IRootSlice) => state.graphSlice.useDefaultLines);

    const permissions = useSelector((state: IRootSlice) => state.projectsSlice.current.permissions);
    const categories = useSelector((state: IRootSlice) => state.projectsSlice.current.categories);
    const projectId = useSelector((state: IRootSlice) => state.projectsSlice.current.id);
    const projectURL = useSelector((state: IRootSlice) => state.projectsSlice.current.cleanedUrl);
    const projectStatus = useSelector((state: IRootSlice) => state.projectsSlice.current.status);

    const reportConfig = reportsUtils.getConfig(reportName);
    const isConstructor = reportConfig?.isConstructor || false;
    const isSummary = reportsUtils.isSummary(reportName);
    const isRating = reportsUtils.isRating(reportName);
    const isGraphsNotUsed = reportsUtils.isGraphsNotUsed(reportName);

    const dispatch = useDispatch();

    const latencyKey = LatencyKeys.base;
    const latencyConfig = useSelector((state: IRootSlice) => state.latencySlice[latencyKey]);

    const isExcludedLatency = latencyConfig?.isExcludedLatency;

    const getLatency = useLatency(latencyKey);
    const checkedDates = useCheckDates(latencyKey);
    const { updateQuery } = useQuery();

    const minCalendarDate = latencyConfig?.start;
    let maxCalendarDate = latencyConfig?.end;

    // Для рейтинга переопределяем maxCalendarDate на вчера
    // если дата "вчера" раньше maxCalendarDate
    if (isRating) {
        const yesterday = DateUtils.getYesterday();

        maxCalendarDate = DateUtils.isBefore(yesterday, DateUtils.getDate(maxCalendarDate))
            ? yesterday.format(DATE_FORMAT)
            : maxCalendarDate;
    }

    const isReportInited = useRef(false);

    const {
        updateReport,
        setError,
        clearError,
        updateReportWithoutRequest,
        initReport,
        resetReport,
        setGlobalLoading,
        requestReportCsv,
        checkRatingAccess,
        getTypeRequestReport,
        handleChangeDate,
        changeRatingCategory,
        updateConstructor,
    } = apiReport;

    const { clearFilters, getDynamicFiltersEventBased } = useFilters();

    const { setNotification } = useNotification();
    const { openModalPopup } = useModalPopup();

    const availableTools = useAvailableToolsReport(reportName);

    const { isAccessRestricted, reports } = useRestrictAccessByEmail();

    useEffect(() => {
        if (isAccessRestricted && reports?.includes(reportName)) setError(403);

        if (!reportConfig || !Object.keys(reportConfig).length) {
            setError(404);
            setGlobalLoading(false);
        }

        // Unmount
        return () => {
            resetReport();
            clearFilters();
            setGlobalLoading(false);
        };
    }, []);

    // При переходе между проектами сплиты и фильтры сбрасываем
    useEffect(() => {
        // Чтобы updateQuery не отрабатывал в момент перехода между проектами на старом компоненте
        isReportInited.current = false;
        dispatch(
            globalActions.updateParams({
                split: null,
                globalFilters: {},
                dynamicFiltersData: {},
            }),
        );
    }, [params.projectId]);

    useEffect(() => {
        if (!checkedDates || !isProjectRequired || !isReportAccess) {
            setGlobalLoading(true);
            return;
        }

        isReportInited.current = false;

        // При переходе между отчётами сбрасываем состояние графиков и поиск в таблице
        apiTable.resetParam('titleFilter');
        apiGraph.resetGraph(true);
        // При переходе между отчетами сбрасываем динамические фильтры
        dispatch(
            globalActions.updateParams({
                dynamicFiltersData: {},
            }),
        );

        initReport().then(() => {
            isReportInited.current = true;
            setGlobalLoading(false);
        });
    }, [checkedDates, isProjectRequired, isReportAccess, reportName]);

    useEffect(() => {
        if (!isReportInited.current) return;

        updateQuery({
            dateEnd,
            dateStart,
            groupBy,
            metrics,
            dimensions,
            graphs,
            orderBy,
            categoryId,
            sample,
            viewType,
            globalFilters: globalFilters.filtersRequest,
            tableFilters,
            offset: tableOffset,
            limit: tableLimit,
        });
    }, [
        isReportInited.current,
        reportName,
        dateEnd,
        dateStart,
        groupBy,
        metrics,
        dimensions,
        graphs,
        orderBy,
        categoryId,
        sample,
        viewType,
        globalFilters.filtersRequest,
        tableFilters,
        tableOffset,
        tableLimit,
    ]);

    const getNeedsPlaceholder = () => {
        const noDataLoaded = !Object.keys(tableData.dict).length && !tableRequest;

        return (
            !globalLoading &&
            ((reportsParamsConfig.behaviorReports.includes(reportName) && noDataLoaded) ||
                (isRating && !checkRatingAccess(projectStatus)))
        );
    };

    const needsPlaceholder = getNeedsPlaceholder();
    const showError507Page = errorCode === 507 && !globalLoading;
    const showContent = !errorCode;

    const dates = DateUtils.convertDates(dateStart, dateEnd);
    const showTotals = reportsUtils.getParamFromConfig(reportName, 'isShowTotals', true);
    const reportContentClass = cn(s.content, {
        [s.contentConstructor]: isConstructor,
    });

    const handleChangeDate507 = (date: string[], relativeDate: DateLiteral) => {
        clearError();
        handleChangeDate(date, relativeDate);
    };

    /**
     * Формируем Title для страницы отчётов
     */
    const getPageTitle = () => {
        const reportTitle = reportsUtils.getParamFromConfig(reportName, 'reportTitle');

        const title = reportTitle ? `${reportTitle} ` : '';
        const url = projectURL ? `(${projectURL}) ` : '';

        return `${title}${url} – Статистика от SberAds / ${projectId}`;
    };

    const renderTools = () => {
        const reportSample = sample || reportsUtils.getParamFromConfig(reportName, 'sampleDefault');
        // Дизейблим кол-во графиков если на отчёте возможен только 1 график
        const disableNumberOfGraphs = reportsUtils.getParamFromConfig(reportName, 'numberOfGraphs') === 'one';

        return (
            <ReportTools
                reportName={reportName}
                reportsUtils={reportsUtils}
                updateReport={updateReport}
                updateReportWithoutRequest={updateReportWithoutRequest}
                getLatency={getLatency}
                serverTime={serverTime}
                sample={reportSample}
                minDate={minCalendarDate}
                maxDate={maxCalendarDate}
                nowDateDiff={reportConfig?.nowDateDiff}
                dateStart={dateStart}
                dateEnd={dateEnd}
                isExcludedLatency={isExcludedLatency}
                projectId={projectId}
                groupBy={groupBy}
                graphs={graphs}
                metrics={metrics}
                handleChangeDate={handleChangeDate}
                toggleUseDefaultLines={apiGraph.toggleUseDefaultLines}
                disableNumberOfGraphs={disableNumberOfGraphs}
                getTypeRequestReport={getTypeRequestReport}
                availableTools={availableTools}
                accessLevel={permissions.can_edit_project}
                dynamicFiltersData={dynamicFiltersData}
                globalFilters={globalFilters}
                getDynamicFilters={getDynamicFiltersEventBased}
                clearFilters={clearFilters}
                filtersConfig={{
                    staticFilters,
                    popularityFilters,
                }}
            />
        );
    };

    const renderPageHeadExtraHeaderNode = () => {
        const useDropdownCategories = isRating && Boolean(categories.length && categoryId);

        return (
            useDropdownCategories && (
                <CategoriesList
                    currentCategory={categoryId}
                    categories={categories}
                    changeRatingCategory={changeRatingCategory}
                />
            )
        );
    };

    const renderPageHeadExtraRightNode = () => {
        const isEmptyTableData = !Object.keys(tableData.dict).length;
        const isShowSaveReportBtn =
            reportsUtils.getParamFromConfig(reportName, 'isShowSaveReportBtn') && userId !== null;
        const isDisabledSaveReportBtn = !(metrics.length && dimensions.length);
        const isEditedSaveReportBtn = savedTemplateId ?? false;
        const isShowCsv = reportsUtils.getParamFromConfig(reportName, 'isShowCsv', true) && !!userEmail;
        const isDisabledCsv = isEmptyTableData;

        return (
            <>
                {isShowCsv && (
                    <Csv
                        requestReportCsv={requestReportCsv}
                        reportName={reportName}
                        userEmail={userEmail}
                        isDisabledCsv={isDisabledCsv}
                    />
                )}
                {isShowSaveReportBtn && (
                    <Button
                        className={s.saveReportBtn}
                        suffixIcon={<IconSave />}
                        onClick={
                            isEditedSaveReportBtn
                                ? apiTemplates.openSaveOrEditPopup
                                : apiTemplates.openSaveTemplatePopup
                        }
                        disabled={isDisabledSaveReportBtn}
                        type="default"
                    >
                        Сохранить отчёт
                    </Button>
                )}
            </>
        );
    };

    const renderHead = () => {
        const hintContent = reportsUtils.getParamFromConfig(reportName, 'reportHint');
        const reportTitle = reportsUtils.getParamFromConfig(reportName, 'reportTitle');
        const customTitle = isConstructor ? savedTemplateTitle || reportTitle : reportTitle;
        const handleClickTitle = isConstructor && savedTemplateId ? apiTemplates.openRenameTemplatePopup : null;

        return (
            <PageHead
                reportName={reportName}
                projectId={projectId}
                userId={userId}
                title={customTitle}
                hintContent={hintContent}
                extraRightNode={renderPageHeadExtraRightNode()}
                extraHeaderNode={renderPageHeadExtraHeaderNode()}
                openModalPopup={openModalPopup}
                setNotification={setNotification}
                onClickTitle={handleClickTitle}
            />
        );
    };

    const renderTable = (
        title?: string,
        isHideMetricsDropdown?: boolean,
        isForceEmpty?: boolean,
        isStickyTableHead?: boolean,
        isSmallWidthTable?: boolean,
        forceDisableSort?: boolean,
    ) => {
        const tableTitle = title || tableUtils.getTableTitle(reportName);

        return (
            <ReportTable
                reportName={reportName}
                utils={utils}
                api={api}
                metrics={metrics}
                allMetrics={allMetrics}
                metricsWithStates={metricsWithStates}
                tableTitle={tableTitle}
                orderBy={orderBy}
                graphData={graphData}
                tableData={tableData}
                totals={totals}
                selectedLines={selectedLines}
                titleFilter={titleFilter}
                tableFilters={tableFilters}
                totalRows={totalRows}
                tableRequest={tableRequest}
                graphRequest={graphRequest}
                useDefaultLines={useDefaultLines}
                counterTableRowsReport={counterTableRowsReport}
                isRating={isRating}
                isSummary={isSummary}
                showTotals={showTotals}
                constants={reportsConstants}
                isHideMetricsDropdown={isHideMetricsDropdown}
                isForceEmpty={isForceEmpty}
                isStickyTableHead={isStickyTableHead}
                isSmallWidthTable={isSmallWidthTable}
                forceDisableSort={forceDisableSort}
            />
        );
    };

    const renderChart = (isForceEmpty?: boolean) => (
        <ChartContainer api={apiReport} utils={{ ...reportsUtils, ...graphUtils }} isForceEmpty={isForceEmpty} />
    );

    const renderSidebarAddition = () =>
        isConstructor &&
        viewType && (
            <ConstructorSettings
                metrics={metrics}
                dimensions={dimensions}
                updateConstructor={updateConstructor}
                viewType={viewType}
                limit={tableLimit}
                offset={tableOffset}
            />
        );

    return (
        <DocumentTitle title={getPageTitle()}>
            <div className={reportContentClass}>
                {!showError507Page && !globalLoading && renderHead()}
                <ContentWithSidebar
                    sidebarAddition={renderSidebarAddition()}
                    className={s.main}
                    sidebarClassName={s.mainSidebar}
                    contentClassName={s.mainContent}
                >
                    {showError507Page && (
                        <Error507
                            dateStart={dates[0]}
                            dateEnd={dates[1]}
                            handleChangeDate={handleChangeDate507}
                            minCalendarDate={minCalendarDate}
                            maxCalendarDate={maxCalendarDate}
                            serverTime={serverTime}
                            getLatency={getLatency}
                            isExcludedLatency={isExcludedLatency}
                        />
                    )}
                    {showContent && !globalLoading && (
                        <>
                            {renderTools()}
                            {needsPlaceholder ? (
                                <ReportPlaceholder reportName={reportName} projectId={projectId} />
                            ) : (
                                <>
                                    {!isGraphsNotUsed && <div>{renderChart()}</div>}
                                    {isConstructor && viewType ? (
                                        <ConstructorView
                                            metrics={metrics}
                                            dimensions={dimensions}
                                            renderTable={renderTable}
                                            renderChart={renderChart}
                                            viewType={viewType}
                                        />
                                    ) : (
                                        <div>{renderTable()}</div>
                                    )}
                                </>
                            )}
                        </>
                    )}
                </ContentWithSidebar>
            </div>
        </DocumentTitle>
    );
};

export default Report;
