import { sortOrders } from '@constants/sort';
import { IMediaConfig } from '@typings/media';

const config: IMediaConfig = {
    reportTitle: 'Автор',
    tableDataType: 'dynamic',
    mediaIdParamReq: 'author_id',
    relativeLinkToReport: 'material',
    isShowLinkWithTitle: true,
    isTableTitleSearch: true,
    isShowTotals: false,
    availableTools: {
        periodPicker: true,
        activeSample: true,
        sample: true,
        detail: true,
        filters: true,
        savedFilters: true,
    },
    tableDefaultMetrics: [
        'page_views',
        'visitors',
        'visits',
        'avg_view_duration',
    ],
    availableMetrics: [
        'page_views',
        'visitors',
        'visits',
        'avg_view_duration',
        'avg_recirculation',
        'avg_fullread_percent',
        'avg_fullscroll_percent',
        'uid',
        'rambler_id',
    ],
    availableDimensions: [
        'traffic_type',
        'media_title',
        'media_url',
    ],
    tableFixedDimensions: ['traffic_type'],
    defaultSort: {
        name: 'page_views',
        order: sortOrders.DESC,
    },
    isDisabledCheckbox: true,
    reportHint: {
        message: '<p>Детальная статистика по&nbsp;определенному автору за&nbsp;выбранный период.</p> '
        + '<p>На&nbsp;графиках&nbsp;&mdash; статистика по аудитории автора, источники переходов на&nbsp;материалы, '
        + 'средний %&nbsp;дочтений и&nbsp;доскроллов.</p> '
        + '<p>В&nbsp;таблице доступны такие показатели, как просмотры, посетители, '
        + 'источники, среднее время на&nbsp;материал, визиты, рециркуляция, доскроллы и&nbsp;дочтения. </p>',
    },
    disabledSortBy: ['title'],
    keyMetricsHint: 'Основной раздел отчёта, демонстрирующий целевые метрики по автору за выбранный период',
    dataRequestFunction: 'getAuthorRequest',
    useDropdownAuthors: true,
};

export default config;
