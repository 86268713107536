import { OrdersType } from '@constants/sort';
import { Dimensions } from '@typings/dimensions';
import { IMetricFilter } from '@typings/filters';
import { Metrics, IMetricsWithStates } from '@typings/metrics';
import { ReportName } from '@typings/reports';
import { IOrderBy, ITableConstants } from '@typings/table';
import cn from 'classnames';
import React, { useEffect, useState, useRef } from 'react';
import s from '../../ReportTable.pcss';
import TableButtons from '../TableButtons';
import TableTotal from '../TableTotal';
import Cell from './Cell';
import DimensionTitle from './DimensionTitle';
import IndicatorTitle from './IndicatorTitle';

interface IProps {
    reportName: ReportName;
    tableTitle: string;
    onIndicatorClick: (indicator: Metrics, order?: OrdersType) => void;
    onFilterSelect: (metric: string, op: string, value: string) => void;
    onPercentSelect: (metric: string) => void;
    onResetSelectedLines: () => void;
    onTitleFilterSelect: (newTitleFilter: string) => void;
    onNewMetricSelect: (metrics: Metrics[]) => void;
    visible: boolean;
    orderBy: IOrderBy;
    titleFilter: string;
    totals: number[];
    tableFilters: IMetricFilter[];
    metrics: Metrics[];
    allMetrics: Metrics[];
    metricsWithStates: IMetricsWithStates[];
    dimensions: Dimensions[];
    tableRequest: boolean;
    showTotals: boolean;
    isShowTableButtons?: boolean;
    utils: TAPI;
    constants: ITableConstants;
    isHideMetricsDropdown?: boolean;
    isStickyTableHead?: boolean;
    forceDisableSort?: boolean;
}

const TableHead: React.FC<IProps> = ({
    tableTitle,
    onFilterSelect,
    onPercentSelect,
    onResetSelectedLines,
    onTitleFilterSelect,
    onIndicatorClick,
    onNewMetricSelect,
    reportName,
    orderBy,
    titleFilter,
    totals,
    tableFilters,
    metrics,
    allMetrics,
    metricsWithStates,
    dimensions,
    tableRequest,
    visible,
    showTotals,
    isShowTableButtons = true,
    utils,
    constants,
    isHideMetricsDropdown,
    isStickyTableHead,
    forceDisableSort,
}) => {
    const [metricsLimit, setMetricsLimit] = useState(null);
    const refMetricsLimit = useRef(null);
    const fixedMetrics = utils.getParamFromConfig(reportName, 'tableFixedMetrics') || [];
    const fixedMetricsNum = fixedMetrics.length;
    const cellHeadClassName = cn(s.tableCell, s.tableCellHead);

    const getLimitSelectedMetrics = () => {
        let limit = null;
        // Количество ячеек, занятых под дименшины без учёта источников траффика
        const dimensionsCells = dimensions ? dimensions.length - 1 : 0;

        if (document.body.clientWidth < 1695) {
            limit = constants.METRICS_BIG_LIMIT;

            if (document.body.clientWidth < 1275) {
                limit = constants.METRICS_LIMIT;
            }
        } else {
            limit = constants.METRICS_MAX_LIMIT;
        }

        limit -= dimensionsCells + fixedMetricsNum;

        if (refMetricsLimit.current !== limit) {
            setMetricsLimit(limit);
        }

        refMetricsLimit.current = limit;
    };

    useEffect(() => {
        // Из выбранных метрик убираем "прибитые"
        const dropdownMetrics = metrics.filter((metric) => !fixedMetrics.includes(metric));
        if (metricsLimit && dropdownMetrics.length > metricsLimit) {
            onNewMetricSelect(dropdownMetrics.slice(0, metricsLimit));
        }
    }, [metricsLimit]);

    useEffect(() => {
        getLimitSelectedMetrics();

        let timer = null;
        const resizeListener = () => {
            clearTimeout(timer);
            timer = setTimeout(() => getLimitSelectedMetrics(), 500);
        };

        window.addEventListener('resize', resizeListener);

        return () => {
            window.removeEventListener('resize', resizeListener);
        };
    }, []);

    const tableHeadClasses = cn(s.tableHead, {
        [s.tableHeadHidden]: !visible,
        [s.tableHeadSticky]: isStickyTableHead,
    });
    const rowHeadStyles = cn(s.tableRow, s.tableRowHead);

    const renderCellMetrics = (item: Metrics, index: number, array: Metrics[]) => (
        <Cell
            key={`cell-m-${index}`}
            item={item}
            index={index}
            array={array}
            onIndicatorClick={onIndicatorClick}
            onNewMetricSelect={onNewMetricSelect}
            metricsLimit={metricsLimit}
            metrics={metrics}
            allMetrics={allMetrics}
            orderBy={orderBy}
            reportName={reportName}
            utils={utils}
            isHideMetricsDropdown={isHideMetricsDropdown}
            forceDisableSort={forceDisableSort}
        />
    );

    const renderCellDimensions = (item: Dimensions, index: number) => (
        <th className={cellHeadClassName} key={`cell-d-${index}`}>
            <DimensionTitle indicator={item} />
        </th>
    );

    return (
        <thead className={tableHeadClasses}>
            <tr className={rowHeadStyles}>
                <th className={cellHeadClassName}>
                    <IndicatorTitle
                        indicator="title"
                        label={tableTitle}
                        orderBy={orderBy}
                        onIndicatorClick={onIndicatorClick}
                        utils={utils}
                        reportName={reportName}
                        forceDisableSort={forceDisableSort}
                    />
                </th>
                {dimensions && dimensions.map(renderCellDimensions)}
                {metrics.map(renderCellMetrics)}
            </tr>
            {isShowTableButtons && (
                <TableButtons
                    titleFilter={titleFilter}
                    totalLines={totals}
                    reportName={reportName}
                    tableFilters={tableFilters}
                    metrics={metrics}
                    metricsWithStates={metricsWithStates}
                    dimensions={dimensions}
                    tableRequest={tableRequest}
                    onFilterSelect={onFilterSelect}
                    onPercentSelect={onPercentSelect}
                    onResetSelectedLines={onResetSelectedLines}
                    onTitleFilterSelect={onTitleFilterSelect}
                    utils={utils}
                />
            )}

            {showTotals && <TableTotal metrics={metrics} data={totals} orderBy={orderBy} />}
        </thead>
    );
};

export default TableHead;
