import { Button, IconArrowDown } from '@adtech/ui';
import { Search } from '@components/index';
import attributesData from '@configs/attributesData';
import { IProjectResponse } from '@typings/projects';
import { ISearchParams } from '@typings/search';
import cn from 'classnames';
import React, { useEffect, useRef, useState } from 'react';
import { Link, useLocation } from 'react-router-dom';

import s from './ProjectSearch.pcss';

interface IProps {
    name: string;
    currentProjectId: number;
    className: string;
    textClassName: string;
    getUserProjects: (params: ISearchParams) => void;
    userProjects: IProjectResponse[];
    userProjectsRequest: boolean;
    mode: string;
    dataAttrName?: string;
    dataAttr?: string;
}

const ProjectSearch: React.FC<IProps> = ({
    name,
    currentProjectId,
    className = '',
    textClassName = '',
    getUserProjects,
    userProjects,
    userProjectsRequest,
    mode,
    dataAttrName = '',
    dataAttr = '',
}) => {
    const [openSearch, setOpenSearch] = useState(false);
    const location = useLocation();
    const node = useRef(null);

    const closeProjectSearch = (event: MouseEvent) => {
        if (!node?.current) return;
        if (node.current?.contains(event.target)) return;

        setOpenSearch(false);
    };

    const handleEscapePress = (event: KeyboardEvent) => {
        if (event.key === 'Escape') {
            event.preventDefault();
            setOpenSearch(false);
        }
    };

    useEffect(() => {
        document.addEventListener('click', closeProjectSearch, true);
        document.addEventListener('keydown', handleEscapePress);

        return () => {
            document.removeEventListener('click', closeProjectSearch, true);
            document.addEventListener('keydown', handleEscapePress);
        };
    }, []);

    const toggleSearch = () => setOpenSearch(!openSearch);

    const handleClick = () => {
        toggleSearch();
    };

    const formatResults = () => {
        let path = location.pathname;
        const isMaterial = path.includes('/material/');
        const isAuthor = path.includes('/author/');

        // Редиректы для материалов и авторов, которые уникальны для проекта
        if (isMaterial) {
            path = path.replace(/material\/.*/, 'materials_summary');
        } else if (isAuthor) {
            path = path.replace(/author\/.*/, 'authors_summary');
        }

        const results = userProjects.filter((item) => item.id !== currentProjectId);

        return results.map((item) => {
            const { id } = item;
            const href = path.replace(/projects\/(\d*)\//, `projects/${id}/`);

            return {
                element: item.title,
                key: String(id),
                href,
            };
        });
    };

    const searchAttr = attributesData.search;

    if (mode === 'settings') {
        return (
            <div className={s.root}>
                <Link to={`/stat/projects/${currentProjectId}/dashboard`} className={className}>
                    <span className={textClassName}>{name}</span>
                </Link>
            </div>
        );
    }

    return (
        <div className={s.root} ref={node}>
            <Button
                type="dashed"
                onClick={() => handleClick()}
                className={cn(className, {
                    active: openSearch,
                })}
                {...(dataAttr && dataAttrName ? { [`data-${dataAttrName}`]: dataAttr } : {})}
            >
                <span className={textClassName}>{name}</span>
                <IconArrowDown />
            </Button>

            {openSearch ? (
                <Search
                    getSearchResults={getUserProjects}
                    results={formatResults()}
                    resultRequest={userProjectsRequest}
                    placeholder="Искать по моим сайтам"
                    rootClassName={s.search}
                    dataAttrName={dataAttrName}
                    dataAttrSearch={[dataAttr, searchAttr.searchInput]}
                    dataAttrList={[dataAttr, searchAttr.searchItem]}
                    isLinks
                />
            ) : null}
        </div>
    );
};

export default ProjectSearch;
