import FieldDecorator from '@decorators/fieldDecorator/component';
import { FieldDecoratorContext } from '@decorators/fieldDecorator/context';
import { FormDecoratorContext } from '@decorators/formDecorator/context';
import { formsActions } from '@redux/slices/forms';
import React from 'react';
import { connect } from 'react-redux';
import { Dispatch } from 'redux';
import { IProps } from './types';

export default function fieldDecorator(fieldParams: any) {
    return (WrappedComponent: any) => {
        const mapStateToProps = (state) => ({
            forms: state.formsSlice.forms,
        });
        const mapDispatchToProps = (dispatch: Dispatch) => ({
            ...fieldParams,
            WrappedComponent,
            /**
             * Создание поля
             */
            createField: (
                formName: string,
                parentFieldName: string,
                fieldName: string,
                mod: string,
                params: Record<string, any> = {},
            ) =>
                dispatch(
                    formsActions.createField({
                        formName,
                        parentFieldName,
                        fieldName,
                        mod,
                        params,
                    }),
                ),
            /**
             * Удаление поля
             */
            removeField: (formName: string, parentFieldName: string, fieldName: string, mod: string) =>
                dispatch(formsActions.removeField({ formName, parentFieldName, fieldName, mod })),
            /**
             * Изменение поля
             */
            changeField: (
                prefix: string,
                formName: string,
                parentFieldName: string,
                fieldName: string,
                mod: string,
                value: string,
                isPushValue: boolean = false,
            ) => {
                dispatch(
                    formsActions.changeField({
                        prefix,
                        formName,
                        parentFieldName,
                        fieldName,
                        mod,
                        fieldData: value,
                        isPushValue,
                    }),
                );
            },
            /**
             * Сброс поля
             */
            resetField: (
                formName: string,
                parentFieldName: string,
                fieldName: string,
                mod: string,
                params: Record<string, any> = {},
            ) =>
                dispatch(
                    formsActions.resetField({
                        formName,
                        parentFieldName,
                        fieldName,
                        mod,
                        params,
                    }),
                ),
            /**
             * Удаляем поле из поля
             */
            removeFieldValue: (
                formName: string,
                parentFieldName: string,
                fieldName: string,
                mod: string,
                index: number,
            ) =>
                dispatch(
                    formsActions.removeFieldValue({
                        formName,
                        parentFieldName,
                        fieldName,
                        mod,
                        index,
                    }),
                ),
        });

        const ConsumerFieldDecorator = (props: IProps) => (
            <FormDecoratorContext.Consumer>
                {(formContext) => (
                    <FieldDecoratorContext.Consumer>
                        {(fieldContext) => (
                            <FieldDecorator {...props} formContext={formContext} fieldContext={fieldContext} />
                        )}
                    </FieldDecoratorContext.Consumer>
                )}
            </FormDecoratorContext.Consumer>
        );

        return connect(mapStateToProps, mapDispatchToProps)(ConsumerFieldDecorator);
    };
}
