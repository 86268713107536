import { Accordion, AccordionItem, IconChevronUp, Button } from '@adtech/ui';
import ContentWithSidebar from '@components/ContentWithSidebar';
import PageHead from '@components/PageHead';

import useGlobalLoading from '@hooks/useGlobalLoading';
import useGoals from '@hooks/useGoals';
import usePostClick from '@hooks/usePostClick';
import useProjectRequired from '@hooks/useProjectRequired';
import { useAppDispatch } from '@hooks/useStore';

import IconLink from '@images/svg/icons/icon-external-link.svg';
import IconArrow from '@images/svg/icons/icon-simple-arrow.svg';

import { globalActions } from '@redux/slices/global';
import { ReportName } from '@typings/reports';
import { IRootSlice } from '@typings/rootSlice';
import cn from 'classnames';
import React, { FC, useEffect, useMemo, useRef, useState } from 'react';
import DocumentTitle from 'react-document-title';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';

import s from './PostClick.pcss';

import CounterAbsent from './components/Counter/CounterAbsent';
import CounterCreated from './components/Counter/CounterCreated';
import GoalsTable from './components/GoalsTable';
import GoalsUpdatePopup from './components/GoalsUpdatePopup';

const PostClick: FC = () => {
    useGlobalLoading();

    const dispatch = useAppDispatch();

    const reportName = 'post_click' as ReportName;

    const isProjectRequired = useProjectRequired();
    const isReportInited = useRef(false);
    const [showGoalsUpdatePopup, setShowGoalsUpdatePopup] = useState(false);
    const [accordionActive, setAccordionActive] = useState(false);

    const params: IUrlParams = useParams();
    const projectId = Number(params.projectId);

    const globalLoading = useSelector((state: IRootSlice) => state.globalSlice.globalLoading);

    const projectURL = useSelector((state: IRootSlice) => state.projectsSlice.current.cleanedUrl);
    const userId = useSelector((state: IRootSlice) => state.userSlice.id);

    const goalsTableData = useSelector((state: IRootSlice) => state.goalsSlice.tableData);
    const commonGoals = useMemo(() => goalsTableData.filter((item) => item.access === 'common'), [goalsTableData]);
    const totalCommonGoals = useMemo(() => commonGoals.length, [goalsTableData]);

    const { counterIsCreated, counter, requestUpdateGoals, selectedGoalsIds } = useSelector(
        (state: IRootSlice) => state.postClickSlice,
    );

    const api = usePostClick();
    const { getTable: getGoalsTable } = useGoals();

    const setGlobalLoading = (param: boolean) => dispatch(globalActions.setGlobalLoading(param));

    const getPageTitle = () => {
        const url = projectURL ? `(${projectURL}) ` : '';
        return `Пост клик в Яндекс Директ ${url} – Статистика от SberAds / ${projectId}`;
    };

    const onChangeAccordion = (key: string[]) => {
        if (key.length) {
            setAccordionActive(true);
        } else {
            setAccordionActive(false);
        }
    };

    useEffect(() => {
        api.resetCounterData();

        if (!isProjectRequired) {
            setGlobalLoading(true);
            return;
        }

        isReportInited.current = false;

        api.init().then(async () => {
            isReportInited.current = true;
        });

        setGlobalLoading(false);
    }, [isProjectRequired, projectId]);

    // TODO: возможно это лишнее, в процессе доработок нужно будет проверить, и если что спилить
    useEffect(
        () => () => {
            setGlobalLoading(false);
            api.resetCounterData();
        },
        [],
    );

    useEffect(() => {
        if (!isReportInited.current || !counter.id) return;
        getGoalsTable({ showCommonGoals: true });
    }, [isReportInited.current, counter]);

    // TODO: временно, нужно будет скорректировать
    useEffect(() => {
        if (!goalsTableData.length) return;
        api.updateSelectedGoals(goalsTableData.filter((item) => !!item.is_yandex_sending).map((item) => item.id));
    }, [goalsTableData]);

    return (
        <DocumentTitle title={getPageTitle()}>
            {!globalLoading && (
                <>
                    <PageHead
                        reportName={reportName}
                        projectId={projectId}
                        userId={userId}
                        title="Передача целевых событий в Яндекс Директ"
                        labelText="Бета"
                    />
                    <ContentWithSidebar>
                        <div className={s.wrapper}>
                            <Accordion
                                className={s.accordion}
                                onChange={onChangeAccordion}
                                expandIcon={() => <>&nbsp;</>}
                            >
                                <AccordionItem
                                    key="hint"
                                    header={
                                        <div className={s.accordionHeader}>
                                            <p>Как экспортировать цели Статистики от SberAds в Яндекс Директ?</p>
                                            <div className={s.accordionLink}>
                                                <span>{accordionActive ? 'Свернуть' : 'Развернуть'}</span>
                                                <IconArrow
                                                    className={cn(s.accordionArrow, {
                                                        [s.accordionArrowActive]: accordionActive,
                                                    })}
                                                />
                                            </div>
                                        </div>
                                    }
                                >
                                    <div className={s.accordionText}>
                                        <p>
                                            1. Для синхронизации Статистики и Яндекс Директа нужен счётчик Яндекс
                                            Метрики:
                                        </p>
                                        <p>
                                            &nbsp; • Если счётчика Метрики нет, то Статистика создаст счётчик
                                            автоматически.
                                        </p>
                                        <p>
                                            &nbsp; • Если счётчик Метрики есть, то необходимо синхронизировать
                                            существующий счётчик Метрики и Статистики.
                                        </p>
                                        <p>2. Скопируйте номер счётчика Яндекс Метрики.</p>
                                        <p>
                                            3. Укажите этот номер в кабинете Яндекс Директа.&nbsp;
                                            <a
                                                href="https://ads.sber.ru/help/peredacha-celevykh-sobytii-v-yandeks-direkt"
                                                target="_blank"
                                                rel="noopener"
                                                className={s.hintLink}
                                            >
                                                <span>Подробнее в документации</span>
                                                <IconLink />
                                            </a>
                                        </p>
                                    </div>
                                </AccordionItem>
                            </Accordion>
                            <div className={s.counterBlock}>
                                <div className={s.counterBlockHeader}>Синхронизация счётчика</div>
                                <div className={s.counterBlockContent}>
                                    {counterIsCreated ? (
                                        <CounterCreated deleteCounter={api.deleteCounter} counterNumber={counter.id} />
                                    ) : (
                                        <CounterAbsent />
                                    )}
                                </div>
                            </div>

                            <Accordion
                                expandIconPosition="end"
                                expandIcon={(panelProps) => (
                                    <IconChevronUp isActive={counter.id && panelProps.isActive} fill="currentColor" />
                                )}
                                activeKey={counter.id ? ['direct'] : []}
                            >
                                <AccordionItem
                                    key="direct"
                                    header="Передача целевых событий в Яндекс Директ"
                                    {...(!counter.id ? { collapsible: 'disabled' } : {})}
                                >
                                    <GoalsTable
                                        totalGoals={totalCommonGoals}
                                        tableData={commonGoals}
                                        selected={selectedGoalsIds}
                                        isLoading={requestUpdateGoals}
                                        updateSelectedGoals={api.updateSelectedGoals}
                                    />
                                </AccordionItem>
                            </Accordion>
                            <Button
                                className={s.submit}
                                disabled={!counter.id || !goalsTableData.length}
                                onClick={() => setShowGoalsUpdatePopup(true)}
                            >
                                Передавать цели в Яндекс Директ
                            </Button>
                            <GoalsUpdatePopup
                                isShowPopup={showGoalsUpdatePopup}
                                closePopup={() => setShowGoalsUpdatePopup(false)}
                                updateGoals={api.updateGoals}
                            />
                        </div>
                    </ContentWithSidebar>
                </>
            )}
        </DocumentTitle>
    );
};

export default PostClick;
