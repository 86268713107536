import fieldDecorator from '@decorators/fieldDecorator';
import IconTrash from '@images/svg/icons/icon-trash.svg';
import { IUsersValue } from '@typings/counter';
import { IField } from '@typings/form';
import cn from 'classnames';
import React, { FC } from 'react';
import s from './AccessUsers.pcss';
import RadioField from './Fields/RadioField';

interface IProps {
    field?: IField;
    currentEmail: string;
}

export const AddedUsersComponent: FC<IProps> = ({ field, currentEmail = '' }) => {
    const fieldValue = (field.value as IUsersValue[]) || [];

    if (!fieldValue.length) return null;

    const renderItem = (item, index: number) => {
        const simpleKey = index + 1;
        const isCurrentUser = item.email === currentEmail;

        return (
            <div className={s.item} key={index}>
                <div className={s.itemNumber}>{simpleKey}.</div>
                <div className={s.itemMain}>
                    <div className={cn(s.user, { [s.userCurrent]: isCurrentUser })}>
                        {item.email} {isCurrentUser && '(это вы)'}
                        {!isCurrentUser && (
                            <IconTrash className={s.userRemoveButton} onClick={() => field?.removeField(index)} />
                        )}
                    </div>
                    <RadioField index={index} disabled={isCurrentUser} />
                </div>
            </div>
        );
    };

    return <div className={s.list}>{fieldValue.map(renderItem)}</div>;
};

export const AddedUsersField = fieldDecorator({
    name: 'addedUsers',
    type: 'object',
    mod: 'array',
})(AddedUsersComponent);
