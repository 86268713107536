import GlobalFiltersList from '@components/GlobalFiltersList';
import { IWrappedComponentProps } from '@decorators/formDecorator/types';
import { formDecorator } from '@decorators/index';
import { globalActions } from '@redux/slices/global';
import { GetFiltersFunction, IDynamicFiltersData } from '@typings/filters';
import React, { FC } from 'react';
import { connect } from 'react-redux';

interface IProps extends IWrappedComponentProps {
    data: Record<string, any>; // все данные, имеем свободный формат
    selectedData: Record<string, any>; // данные, которые были выбраны
    dynamicData: IDynamicFiltersData; // динамические данные из стейта
    getDynamicData: GetFiltersFunction; // функция, которая получает динамические данные

    // функции
    initializeValues: any;
    setSnackbar?: (string) => void;

    // форма
    initialValues: Record<string, any>;
    values: Record<string, any>;
    removeField: any;
    changeValueFields: any;
}

// Обертка над фильтрами, чтобы разделить фильтры цели и фильтры отчета по названию формы
export const GoalFilters: FC<IProps> = (props) => (
    <GlobalFiltersList
        reportName="goals"
        onApply={() => {}}
        getFilterData={(properties) => properties.data.staticFilters}
        allFiltersData={props.data.staticFilters.data}
        isSavedFilterApplied={false}
        isFiltersListOnly
        {...props}
    />
);

// TODO: переписать globalFilters на функциональный, заюзать хук
const mapDispatchToProps = (dispatch) => ({
    setSnackbar(message: string, type: string) {
        dispatch(globalActions.setSnackbar({ message, type }));
    },
});

export default formDecorator({
    formName: 'goalFilters',
    isRemoveForm: false,
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
})(connect(null, mapDispatchToProps)(GoalFilters));
