import { staticFilters } from '@configs/filters/filtersConfigAll';
import dateConst from '@constants/date';
import { Op } from '@constants/listData';
import reportsConstants from '@constants/reports';
import tableUtils from '@redux/slices/table/utils';
import { Dimensions } from '@typings/dimensions';
import { IDynamicFiltersData, IFilter, IGlobalFilters, IStaticFilter } from '@typings/filters';
import { IReportTableReqResponse } from '@typings/table';
import DateUtils from '@utils/date';
import listDataUtils from '@utils/listDataUtils';
import { isArray, isString } from '@utils/typesChecks';

/**
 * Проверяем нужно ли преобразовать время в минуты, если да, то переводим в минуты.
 * У интервала переводим каждое значение из массива.
 * Добавляем параметр format, для определения минут или секунд.
 */
export const getTimeValue = (timeValue: any, type?: string) => {
    let isMinutes = false;
    let newTimeValue;

    if (type === 'range') {
        isMinutes = timeValue.every((val) => Number(val) >= 60 && Number(val) % 60 === 0);

        newTimeValue = timeValue.map((val) => (isMinutes ? String(val / 60) : String(val)));
    } else {
        newTimeValue = String(timeValue);

        if (Number(timeValue) >= 60 && Number(timeValue) % 60 === 0) {
            isMinutes = true;
            newTimeValue = String(timeValue / 60);
        }
    }

    return {
        time: isMinutes ? 'minutes' : 'seconds',
        value: newTimeValue,
    };
};

/**
 * Метод преобразования данных сохраненного фильтра
 * для типа фильтра "checkbox".
 * Строим элемент для filterRequest и элемент selectedData.
 */
export const getFilterTypeCheckbox = (staticFilter: IStaticFilter, filter: IFilter) => {
    const { op, value } = filter;
    const filterRequestItem = {
        condition: op,
        op,
        value,
        key: filter.key,
        name: staticFilter.name,
        filterType: staticFilter.type,
        valueType: 'array',
        ...(staticFilter.metric ? { isMetricFilter: true } : {}),
    };

    const filterCnfValues = typeof value === 'string' ? value.split(' ') : value;

    const selectedDataItem = {
        checkboxes:
            isArray(filterCnfValues) &&
            filterCnfValues.map((filterValue) => {
                const filterTitle = staticFilter.data
                    ? staticFilter.data.find((dataItem) => dataItem.id === filterValue).title
                    : filterValue;

                return {
                    ...staticFilter,
                    id: String(filterValue),
                    title: filterTitle,
                    ...(staticFilter.name.includes('geo') ? { geoId: Number(filterValue) } : {}),
                };
            }),
        condition: op,
    };

    return {
        filterRequestItem,
        selectedDataItem,
    };
};

/**
 * Метод преобразования данных сохраненного фильтра
 * для типа фильтра "period".
 * Строим элемент для filterRequest и элемент selectedData.
 */
export const getFilterTypePeriod = (staticFilter: IStaticFilter, filter: IFilter) => {
    const { op, value } = filter;
    const isRange = op === Op.range;
    const filterRequestItem = {
        condition: null,
        op: filter.op,
        value: filter.value,
        key: filter.key,
        name: staticFilter.name,
        filterType: staticFilter.type,
        valueType: isRange ? 'array' : 'number',
    };

    const periodValue = staticFilter?.options?.isTime ? getTimeValue(value, op) : { value };

    // prettier-ignore
    const selectedDataItem = isRange
        ? {
            ...periodValue,
            from: periodValue.value[0],
            to: periodValue.value[1],
            op,
            value: '',
        }
        : {
            ...periodValue,
            op,
        };

    return {
        filterRequestItem,
        selectedDataItem,
    };
};

/**
 * Метод преобразования данных сохраненного фильтра
 * для типа фильтра "textarea".
 * Строим элемент для filterRequest и элемент selectedData.
 */
export const getFilterTypeTextarea = (staticFilter: IStaticFilter, filter: IFilter) => {
    const { op, value } = filter;

    const filterRequestItem = {
        condition: op,
        op,
        value,
        key: filter.key,
        name: staticFilter.name,
        filterType: staticFilter.type,
        valueType: 'array',
    };

    const valArr = isString(value) ? [value] : value;

    // Замена символов % на * и @ для selectedData
    const result = valArr
        .map((val) => {
            let res = val;

            if (val.includes('%')) {
                if (val.startsWith('%') && val.endsWith('%')) {
                    res = val.replace(/^%/, '@').replace(/%$/, '');
                }
                res = res.replace(/%/gi, '*');
            }

            return res;
        })
        .join('\n');

    const selectedDataItem = { value: result, condition: op };

    return {
        filterRequestItem,
        selectedDataItem,
    };
};

/**
 * Метод преобразования данных сохраненного фильтра
 * для типа фильтра "date|dateTime".
 * Строим элемент для filterRequest и элемент selectedData.
 */
export const getFilterTypeDate = (staticFilter: IStaticFilter, filter: IFilter) => {
    const { op, value } = filter;
    const { type } = staticFilter;
    const filterRequestItem = {
        condition: null,
        op,
        value,
        key: filter.key,
        name: staticFilter.name,
        filterType: type,
        valueType: 'array',
    };

    const isRange = op === Op.range; // интервал
    const val = isRange ? value[0] : value;
    const isISODateFormat = val.includes('-'); // гггг-мм-дд
    const isDateTime = type === 'dateTime'; // гггг-мм-дд чч:мм:сс
    let selectedDataItem;

    // Приводим к привычному формату дд.мм.гггг
    const formatDate = (date) =>
        isISODateFormat ? DateUtils.reformatDate(date, dateConst.DATE_FORMAT, dateConst.DATE_VIEW) : date;

    if (isRange) {
        const from = isDateTime ? value[0].split(' ')[0] : value[0];
        const to = isDateTime ? value[1].split(' ')[0] : value[1];

        selectedDataItem = {
            op,
            value: '',
            from: formatDate(from),
            to: formatDate(to),
        };
    } else {
        selectedDataItem = {
            op,
            value: formatDate(value),
        };
    }

    if (isDateTime) {
        // prettier-ignore
        selectedDataItem = {
            ...selectedDataItem,
            ...(isRange ? {
                fromTime: value[0].split(' ')[1].slice(0, -3), // чч:мм
                toTime: value[1].split(' ')[1].slice(0, -3),
            } : {
                valueTime: (typeof value === 'string') && value.split(' ')[1].slice(0, -3),
            }),
        };
    }

    return {
        filterRequestItem,
        selectedDataItem,
    };
};

/**
 * Метод преобразования данных сохраненного фильтра
 * для типа фильтра "time".
 * Строим элемент для filterRequest и элемент selectedData.
 */
export const getFilterTypeTime = (staticFilter: IStaticFilter, filter: IFilter) => {
    const { op, value } = filter;
    const filterRequestItem = {
        condition: null,
        op,
        value,
        key: filter.key,
        name: staticFilter.name,
        filterType: staticFilter.type,
        valueType: 'array',
    };

    // prettier-ignore
    const selectedDataItem = op === 'range'
        ? {
            op,
            from: value[0].slice(0, -3), // чч:мм
            to: value[1].slice(0, -3),
            value: '',
        }
        : {
            op,
            value: value.slice(0, -3),
        };

    return {
        filterRequestItem,
        selectedDataItem,
    };
};

/**
 * Метод преобразования данных фильтра
 * для дальнейшего применения фильтра
 */
export const formatFilters = (filters: IFilter[], isSaved: boolean = false, projectId?: number) => {
    // Получаем список статических фильтров
    const staticFiltersList = listDataUtils.computeData(staticFilters);
    const selectedData = {};
    const filtersRequest = [];

    // Для каждого фильтра полученного из запроса находим фильтр из статических данных
    filters.forEach((filter) => {
        // prettier-ignore
        const staticFilter: IStaticFilter = filter.key
            ? staticFiltersList.data.find((
                staticFilterItem => filter.key === (
                    staticFilterItem.dimension || staticFilterItem.metric
                )))
            : null;

        // Если фильтр не найден, то мы пропускаем дальнейшее форматирование
        if (!staticFilter) return;

        let formatFilterItem;

        // Строим новые данные для сохраненного фильтра в зависимости от типа фильтра
        // filtersRequest - данные фильтра для работы с ним и отправок запросов
        // selectedData - данные фильтра для дальнейшего применения и рендера выбранного фильтра
        switch (staticFilter.type) {
            case 'checkbox':
                formatFilterItem = getFilterTypeCheckbox(staticFilter, filter);
                break;

            case 'period':
                formatFilterItem = getFilterTypePeriod(staticFilter, filter);
                break;

            case 'textarea':
                formatFilterItem = getFilterTypeTextarea(staticFilter, filter);
                break;

            case 'date':
            case 'dateTime':
                formatFilterItem = getFilterTypeDate(staticFilter, filter);
                break;

            case 'time':
                formatFilterItem = getFilterTypeTime(staticFilter, filter);
                break;

            default:
                formatFilterItem = {};
                break;
        }

        if (staticFilter.metric) {
            formatFilterItem.filterRequestItem.isMetricFilter = true;
        }

        filtersRequest.push(formatFilterItem.filterRequestItem);
        selectedData[staticFilter.name] = formatFilterItem.selectedDataItem;
    });

    // Если filtersRequest пустой, то возвращаем null
    // prettier-ignore
    return filtersRequest.length
        ? {
            filtersRequest,
            selectedData: { ...selectedData },
            ...(isSaved ? {
                savedFilterOptions: {
                    isSaved,
                    projectId,
                },
            } : {}),
        } : null;
};

export const formatDynamicData = (
    filterName: Dimensions,
    data: IReportTableReqResponse,
    parents: number[],
    meta: any,
    dynamicFiltersData: IDynamicFiltersData,
) => {
    const {
        metrics,
        data: { collection: newData },
    } = tableUtils.prepareTableData(data, {
        ...meta,
        reportName: filterName, // TODO: дичь какая-то
    });

    const newDynamicFiltersData = { ...dynamicFiltersData };

    if (newDynamicFiltersData[filterName]) {
        const { data: oldData, loaded: oldLoaded } = newDynamicFiltersData[filterName];

        // создаем новую колонку с мета-данными
        // для каждого уровня
        const newMeta = {
            [reportsConstants.ROOT_NODE]: { ...meta },
        };

        let curData = oldData;

        // если есть родитель (parents - это вложенность)
        // добавляем данные как children у последнего в списке родителя
        if (parents.length > 0) {
            parents.forEach((parentId, index) => {
                const curIndex = curData.findIndex((itm) => String(itm.id) === String(parentId));

                if (index !== parents.length - 1) {
                    curData = curData[curIndex].children;
                } else {
                    curData[curIndex] = {
                        ...curData[curIndex],
                        children: [...curData[curIndex].children, ...newData],
                    };
                }
            });

            // записываем в мету данные, для крайнего родителя
            newMeta[parents[parents.length - 1]] = { ...newMeta };
        }

        newDynamicFiltersData[filterName] = {
            ...(newDynamicFiltersData[filterName] || {}),
            isLoading: false,
            loaded: [...oldLoaded, ...parents],
            // prettier-ignore
            data: parents.length > 0 // eslint-disable-line
                ? [...oldData]
                : newDynamicFiltersData[filterName]
                && newDynamicFiltersData[filterName].data.length > 0
                    ? [...oldData, ...newData]
                    : [...newData],
            metrics,
            meta: newMeta,
        };
    }

    return newDynamicFiltersData;
};

export const getDynamicFiltersNames = (filter: IGlobalFilters): Dimensions[] =>
    Object.keys(filter.selectedData).filter(
        (key) =>
            filter.selectedData[key].checkboxes &&
            filter.selectedData[key].checkboxes.some((item) => item?.options?.isDynamic),
    ) as Dimensions[];

/**
 * Добавление динамических данных в фильтр:
 * Находим в динамических данных нужный фильтр по id и вытаскиваем оттуда title
 * Добавляем тайтл в текущий фильтр
 */
export const setDynamicDataToFilter = (
    filter: IGlobalFilters,
    selectedDataKeys: Array<string>,
    dynamicFiltersData: IDynamicFiltersData,
): IGlobalFilters => {
    const newFilter = { ...filter };

    selectedDataKeys.forEach((key) => {
        const checkboxesData = newFilter.selectedData[key].checkboxes;

        if (!checkboxesData || !checkboxesData.length) return;

        newFilter.selectedData[key].checkboxes = checkboxesData.map((checkboxesItem) => {
            const filtersDynamicData = dynamicFiltersData[key]?.data;

            if (filtersDynamicData && filtersDynamicData.length) {
                const filterDynamicItem = filtersDynamicData.find(
                    (dynamicItem) =>
                        String(dynamicItem.id) === checkboxesItem.id ||
                        String(dynamicItem.geoId) === String(checkboxesItem.id),
                );

                const filterDynamicTitle = filterDynamicItem ? filterDynamicItem.title : checkboxesItem.title;

                return {
                    ...checkboxesItem,
                    title: filterDynamicTitle,
                };
            }

            return { ...checkboxesItem };
        });
    });

    return newFilter;
};

// TODO: допокрыть тестами
export const getCorrectToConfigFilters = (filter: IGlobalFilters, configFilters: IStaticFilter[]): IGlobalFilters => {
    // Из массива фильтров конфига делаем плоский объект с одними ключами фильтров
    const staticFiltersFlatCnf = listDataUtils.getFlatConfigObj(configFilters);
    const newFilterRequest = filter.filtersRequest.filter((filterItem) => !!staticFiltersFlatCnf[filterItem.key]);

    return {
        ...filter,
        filtersRequest: newFilterRequest,
    };
};

// TODO: допокрыть тестами
// Выбираем сохраненные фильтры, соответствующие конфигу текущего отчёта
export const getSavedFiltersForReport = (
    filtersData: IGlobalFilters[],
    configFilters: IStaticFilter[],
): IGlobalFilters[] => {
    // Из массива фильтров конфига делаем плоский объект с одними ключами фильтров
    const staticFiltersFlatCnf = listDataUtils.getFlatConfigObj(configFilters);

    // Убираем фильтры, если не соответствуют текущему конфигу фильтров отчёта
    const filteredFilters = filtersData.filter((item) =>
        item.filtersRequest.every((filter) => !!staticFiltersFlatCnf[filter.key]),
    );

    return filteredFilters;
};
