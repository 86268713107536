import { ChartTypes } from '@configs/graph';
import { sortOrders } from '@constants/sort';
import { IReportConfig } from '@typings/reports';

const reportConfig: IReportConfig = {
    reportTitle: 'Посещаемость',
    tableDataType: 'static',
    tableTitle: 'Даты визита',
    defaultGraphs: [
        ChartTypes.area,
    ],
    graphDefaultMetrics: [
        'visits',
    ],
    numberOfGraphs: 'one',
    defaultSort: {
        name: 'title',
        order: sortOrders.ASC,
    },
    titleFormat: 'date',
    isDisabledCheckbox: true,
    disabledGraphs: {
        bar: true,
        pie: true,
        line: true,
    },
    tableDefaultMetrics: [
        'visits',
        'page_views',
        'visitors',
    ],
    customLegend: 'metrics',
    isHideTooltipHead: true,
    availableMetrics: [
        'visits',
        'avg_view_depth',
        'page_views',
        'visitors',
        'uid',
        'rambler_id',
        // 'bounces',
        // 'avg_industry_duration',
    ],
    isShowTotals: true,
    availableTools: {
        numberOfGraphs: true,
        disableNumberOfGraphs: true,
        periodPicker: true,
        sample: true,
        activeSample: true,
        filters: true,
        savedFilters: true,
        detail: true,
    },
    reportHint: {
        message: 'Общий отчёт о посетителях, визитах и просмотрах вашего сайта. '
            + 'Доступные показатели: посетители, визиты, '
            + '<a href="https://ads.sber.ru/help/metod-dlya-peredachi-identifikatora-polzovatelya" target="_blank">пользователи</a>, '
            + 'просмотры, отказы, время на сайте, глубина просмотра.',
    },
};

export default reportConfig;
