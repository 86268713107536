import { Tooltip, IconInformationSign } from '@adtech/ui';
import { TooltipPlacement } from 'antd/lib/tooltip';
import cn from 'classnames';
import React from 'react';

import s from './Hint.pcss';

interface IProps {
    className?: string;
    children: JSX.Element | string;
    tooltipPlacement?: TooltipPlacement;
    defaultOpen?: boolean;
    showButtonClose?: boolean;
    overlayInnerStyle?: React.CSSProperties;
    handleClose?: () => void;
    trigger?: 'hover' | 'focus' | 'click' | 'contextMenu';
    open?: boolean;
    handleShowHint?: () => void;
    onOpenChange?: (open: boolean) => void;
}

export const Hint: React.FC<IProps> = ({
    className = null,
    children,
    tooltipPlacement,
    defaultOpen,
    showButtonClose,
    overlayInnerStyle,
    handleClose,
    handleShowHint,
    trigger,
    open,
    onOpenChange = () => {},
}) => (
    <Tooltip
        className={cn(s.hint, className)}
        title={
            <div className={s.hintContent} data-testid="hint-popUp">
                <div data-testid="hint-text">{children}</div>
            </div>
        }
        placement={tooltipPlacement}
        defaultOpen={defaultOpen}
        showButtonClose={showButtonClose}
        overlayInnerStyle={overlayInnerStyle}
        handleClose={handleClose}
        trigger={trigger}
        open={open}
        onOpenChange={onOpenChange}
    >
        <span className={s.hintIcon} data-testid="hint-icon" onClick={handleShowHint}>
            <IconInformationSign />
        </span>
    </Tooltip>
);
