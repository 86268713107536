import { ChartTypes } from '@configs/graph';
import groupParams, { GroupBy } from '@configs/group';
import { reportQueryParams } from '@configs/queryParams';
import reportsConfig from '@configs/reports';
import { sortOrders } from '@constants/sort';
import tableConstants from '@constants/table';
import { ViewType } from '@constants/viewType';
import useValidDates from '@hooks/useValidDates';
import { DateType } from '@typings/date';
import { IFilter } from '@typings/filters';
import { IGraphs } from '@typings/graph';
import { IMediaConfig, IUpdateMediaParams } from '@typings/media';
import { Metrics } from '@typings/metrics';
import { IQuery } from '@typings/query';
import { IReportConfig, IUpdateReportParams, ReportName } from '@typings/reports';
import { IRootSlice, IDashboardSlice, IMediaSlice, IReportsSlice, ITableSlice, IGraphSlice } from '@typings/rootSlice';
import DateUtils from '@utils/date';
import { measuresUtils, queryUtils, urlUtils, validator } from '@utils/index';
import reportsUtilsFunc from '@utils/reports';
import { useSelector } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';

const useQuery = (config: Record<string, IReportConfig | IMediaConfig> = reportsConfig) => {
    const globalSlice = useSelector((state: IRootSlice) => state.globalSlice);
    const projectsSlice = useSelector((state: IRootSlice) => state.projectsSlice);

    const location = useLocation();
    const navigate = useNavigate();

    const { getValidIntervalDates } = useValidDates();

    const utils = reportsUtilsFunc({
        reportsConfig: config,
    });

    // TODO: возможно стоит перенести сюда метод checkQueryParams
    // из useReport и useMedia, сделав его более универсальным.
    // TODO: в будущем, перенести из utils/reports метод validateQueryParam
    // и сделать на основе него один универсальный метод для валидации query

    const api = {
        /**
         * Получение параметров отчёта из query-параметров
         */
        getParamsFromQuery(query: IQuery, reportName?: ReportName): IQuery {
            const result = {};

            Object.keys(reportQueryParams).forEach((name) => {
                const { type, customDelimiters, onlyState } = reportQueryParams[name];

                let resultValue = null;

                const queryValue = query[name];

                if (!queryValue || onlyState) return;

                switch (type) {
                    case 'object': {
                        let validateValue = queryValue;

                        // TODO: временный фикс, если в сортировке нету значения метода сортировки,
                        //  прописываем по дефолту,
                        //  позднее нужно сделать что-то лучше.
                        if (name === 'orderBy' && !queryValue.includes(':')) {
                            validateValue += `:${sortOrders.DESC}`;
                        }

                        resultValue = queryUtils.queryItemToObject(validateValue);
                        break;
                    }
                    case 'objectArray': {
                        resultValue = queryUtils
                            .queryStringToArray(queryValue)
                            .map((value) => queryUtils.queryItemToObject(value, customDelimiters));
                        break;
                    }
                    case 'array': {
                        if (query[name]) {
                            resultValue = queryUtils.queryStringToArray(query[name]);
                        }
                        break;
                    }

                    case 'filtersArray': {
                        resultValue = {
                            filtersRequest: queryUtils
                                .queryStringToArray(queryValue, tableConstants.PARENTS_DELIMITER)
                                .map((value) => queryUtils.queryItemToFilter(value))
                                .filter((item) => !!item),
                        };

                        break;
                    }
                    default: {
                        resultValue = queryValue;
                    }
                }

                result[name] = resultValue;
            });

            return api.validateQueryParam(result, reportName);
        },

        /**
         * Валидация query-параметров
         */
        validateQueryParam(params: IQuery, reportName?: ReportName): Record<string, unknown> {
            const validParams = {};

            Object.keys(params).forEach((key) => {
                const value = params[key];
                let valid = true;

                switch (key) {
                    case 'graphs': {
                        value.forEach((item, index) => {
                            if (!Object.values(ChartTypes).includes(item.value)) {
                                value.splice(index, 1);
                            }
                        });

                        if (!value.length || value.length > 2) valid = false;

                        break;
                    }
                    case 'dateEnd':
                    case 'dateStart': {
                        valid = validator.test(value, 'date');
                        break;
                    }
                    case 'groupBy': {
                        let groups = DateUtils.getGroupFromPeriod([
                            DateUtils.getDate(params.dateStart),
                            DateUtils.getDate(params.dateEnd),
                        ]);

                        if (!groups.length) {
                            groups = [...groupParams.period.day];
                        }

                        valid = groups.includes(value);
                        break;
                    }
                    case 'orderBy': {
                        const disabledOrders = utils.getParamFromConfig(reportName, 'disabledSortBy', []);

                        valid = !disabledOrders.includes(value.key);

                        break;
                    }
                    default: {
                        break;
                    }
                }

                if (valid) {
                    validParams[key] = params[key];
                }
            });

            return validParams;
        },

        getQueryParam(
            param: string,
            queryParams: IQuery,
            state: Partial<(IReportsSlice | IMediaSlice | IDashboardSlice) & ITableSlice & IGraphSlice>,
        ) {
            const isDefined = (value) =>
                !(
                    value === undefined ||
                    value === null ||
                    value === '' ||
                    (typeof value === 'object' && !value?.length && !Object.keys(value)?.length)
                );

            return (
                (isDefined(queryParams[param]) && queryParams[param]) ||
                (isDefined(globalSlice[param]) && globalSlice[param]) ||
                (isDefined(state[param]) && state[param])
            );
        },

        /**
         * Проверка и корректировка QUERY-параметров, запись значений из globalSlice
         */
        checkQueryParams(
            queryParams: IQuery,
            defaultParams: IUpdateReportParams | IUpdateMediaParams,
            state: Partial<(IReportsSlice | IMediaSlice | IDashboardSlice) & ITableSlice & IGraphSlice>,
            resetParam: (param: string) => void,
            reportName?: ReportName,
        ): IUpdateReportParams {
            let newParams = {};
            let newValue;
            let dates;
            const params = Object.keys(reportQueryParams).filter((key) => !!reportQueryParams[key]?.inQuery);

            params.forEach((param: string) => {
                // Отсекаем лишние параметры на отчёте, значений которых нет в сторе
                if (globalSlice[param] === undefined && state[param] === undefined) return;

                // Отсекаем и сбрасываем параметры, у которых на отчёте нет дефолтных значений
                if (defaultParams[param] === undefined) {
                    resetParam(param);
                    return;
                }

                const value = api.getQueryParam(param, queryParams, state);

                switch (param) {
                    case 'graphs':
                        newValue = api.validateGraphs(value, reportName, defaultParams);
                        break;
                    case 'metrics':
                        newValue = api.validateMetrics(value, reportName);
                        break;
                    case 'split':
                        newValue = api.validateSplit(value, queryParams);
                        break;
                    case 'dateStart':
                        dates = api.validateDates(value, api.getQueryParam('dateEnd', queryParams, state));
                        newValue = dates.dateStart;
                        break;
                    case 'dateEnd':
                        dates = api.validateDates(api.getQueryParam('dateStart', queryParams, state), value);
                        newValue = dates.dateEnd;
                        break;
                    case 'groupBy':
                        if (!Object.keys(dates)?.length) break;
                        newValue = api.validateGroupBy(value, dates.dateStart, dates.dateEnd);
                        break;
                    case 'categoryId':
                        newValue = api.validateCategoryId(value);
                        break;
                    case 'viewType':
                        newValue = api.validateViewType(value);
                        break;
                    case 'sample':
                        newValue = api.validateSample(value);
                        break;
                    case 'tableFilters':
                        newValue = api.validateTableFilters(value, reportName);
                        break;
                    case 'limit':
                        newValue = api.validateLimit(value);
                        break;
                    case 'offset':
                        newValue = api.validateOffset(value);
                        break;
                    default:
                        newValue = value;
                        break;
                }

                if (!newValue) newValue = defaultParams[param];

                if (newValue) newParams = { ...newParams, [param]: newValue };
            });

            return newParams;
        },

        /**
         * Обновляем QUERY-параметры в URL
         */
        updateQuery(params: Record<string, any>): void {
            const query = urlUtils.parseQuery(location.search);

            const newQuery = {};

            Object.keys(reportQueryParams).forEach((name) => {
                const { type } = reportQueryParams[name];
                const value = typeof params[name] !== 'undefined' ? params[name] : query[name];

                if (name === 'selectedLines') return; // TODO: исправить костыли

                switch (type) {
                    case 'object': {
                        if (typeof value !== 'object' && query[name]) {
                            newQuery[name] = query[name];
                        } else if (value) {
                            newQuery[name] = queryUtils.objectToQueryString(value);
                        }
                        break;
                    }
                    case 'objectArray': {
                        if (!Array.isArray(value) && query[name]) {
                            newQuery[name] = query[name];
                        } else if (value && value.length) {
                            newQuery[name] = queryUtils.arrayToQueryString(
                                value.map((item) => queryUtils.objectToQueryString(item)),
                            );
                        }
                        break;
                    }
                    case 'array': {
                        if (!Array.isArray(value) && query[name]) {
                            newQuery[name] = query[name];
                        } else if (value && value.length) {
                            newQuery[name] = value.join(',');
                        }
                        break;
                    }
                    case 'filtersArray': {
                        if (Array.isArray(value)) {
                            newQuery[name] = queryUtils.arrayToQueryString(
                                value.map((item) => queryUtils.filterObjToQueryString(item)),
                                tableConstants.PARENTS_DELIMITER,
                            );
                        }
                        break;
                    }
                    default: {
                        if (value) {
                            newQuery[name] = value;
                        }
                    }
                }
            });

            api.updateWithParams(newQuery);
        },

        updateWithParams(params: Record<string, string> = {}): void {
            const { pathname, search } = location;
            const newUrl = `${pathname}?${urlUtils.objectToQuery(params)}`;
            const oldUrl = pathname + search;

            if (oldUrl === newUrl) return;

            // Храним в истории только последнюю версию текущего отчёта
            // без учёта изменений query параметров
            navigate(newUrl, { replace: true });
        },

        /**
         * Методы валидации параметров
         */
        validateGraphs(graphs: IGraphs[], reportName: ReportName, defaultParams: IUpdateReportParams): IGraphs[] {
            const { getParamFromConfig } = utils;
            const numberOfGraphs = getParamFromConfig(reportName, 'numberOfGraphs');
            const availableMetrics = getParamFromConfig(reportName, 'availableMetrics', []);
            const disabledGraphs = getParamFromConfig(reportName, 'disabledGraphs');

            if (!graphs) return null;

            if (numberOfGraphs === null && graphs.length > 1) {
                return defaultParams.graphs;
            }

            if ((numberOfGraphs === 'one' && graphs.length > 1) || (numberOfGraphs === 'two' && graphs.length === 1)) {
                return defaultParams.graphs;
            }

            return graphs.map(({ key, value }, index) => {
                const disabledMetricGraphs = measuresUtils.getDisabledGraphs(key, disabledGraphs, reportName);

                if (Object.keys(disabledMetricGraphs).includes(value) || !availableMetrics.includes(key)) {
                    return defaultParams.graphs[index];
                }

                return { key, value };
            });
        },

        validateMetrics(metrics: Metrics[], reportName: ReportName): Metrics[] {
            const availableMetrics = utils.getParamFromConfig(reportName, 'availableMetrics', []);
            const tableHiddenMetrics = utils.getParamFromConfig(reportName, 'tableHiddenMetrics', []);
            let newMetrics = [];
            const metricsLen = metrics?.length || 0;

            if (!metricsLen) return null;

            newMetrics = metrics.filter((metric) => availableMetrics.includes(metric));

            if (
                !newMetrics.length ||
                newMetrics.length < metricsLen ||
                tableHiddenMetrics.length === newMetrics.length
            ) {
                return null;
            }

            return newMetrics;
        },

        validateSplit(value: string, query: IQuery): string {
            // Берем сплиты из store только если query параметров нет или они некорректны
            if (!value && Object.keys(query).length) return null;

            return value;
        },

        validateDates(dateStart: DateType, dateEnd: DateType): Record<string, DateType> {
            // Проверяем валидность интервала, и если он некорректный, то ревертим
            return getValidIntervalDates({ dateStart, dateEnd });
        },

        validateGroupBy(group: GroupBy, dateStart: DateType, dateEnd: DateType): GroupBy {
            return DateUtils.checkCurrentGroup(
                [DateUtils.getDate(dateStart), DateUtils.getDate(dateEnd)],
                group,
                globalSlice.periodDetail,
            );
        },

        validateCategoryId(id: string): string {
            let categoryId = id;
            const categories = projectsSlice.current.categories;
            const isCategoryExist = !!categories?.filter((item) => item.id === categoryId)?.length;
            if (!categoryId || (!isCategoryExist && categories.length)) {
                categoryId = categories[1]?.id;
            }

            return categoryId;
        },

        validateViewType(viewType: ViewType | null): ViewType | null {
            let newViewType = null;
            if (viewType === ViewType.table || viewType === ViewType.graph) {
                newViewType = viewType;
            }

            return newViewType;
        },

        validateSample(sample: number | null): number | null {
            let newSample = null;
            if (sample !== null) {
                if (Number(sample) > 0 && Number(sample) <= 1) {
                    newSample = Number(sample);
                }
            }

            return newSample;
        },

        validateTableFilters(filters: IFilter[], reportName: ReportName): IFilter[] {
            const availableMetrics = utils.getParamFromConfig(reportName, 'availableMetrics', []);
            let newFilters = [];

            if (!filters.length) return null;

            newFilters = filters.filter((filter) => availableMetrics.includes(filter.key));

            if (!newFilters.length) return null;

            return newFilters;
        },

        validateLimit(limit: number | null): number | null {
            if (limit < 0 || limit > 100 || limit === null) return null;
            return Number(limit);
        },

        validateOffset(offset: number | null): number | null {
            if (offset < 0 || offset === null) return null;
            return Number(offset);
        },
    };

    return api;
};

export default useQuery;
