import { Input } from '@adtech/ui';
import fieldDecorator from '@decorators/fieldDecorator';
import React from 'react';

import s from './ParamsArrayType.pcss';

export default fieldDecorator({
    ignoreHidden: true,
    type: 'string',
})(({ field, placeholder }) => <Input {...field} className={s.input} placeholder={placeholder} />);
