import { IconCross, Loader, Select } from '@adtech/ui';
import * as React from 'react';

import { useEffect } from 'react';
import FilterFormHeader from '../components/FilterFormHeader';
import {
    IDynamicFilterItem,
    IFilterRuleFormValue,
    IFiltersCnfItem,
    TAllFormValues,
    TDimension,
} from '../types/filters';

interface IProps {
    filterCnf: IFiltersCnfItem;
    currentKey: string;
    allFormsValues: TAllFormValues;
    setAllFormsValues: (formsValues: TAllFormValues) => void;
    ruleFormValue: IFilterRuleFormValue;
    curFilterFormsValues: IFilterRuleFormValue[];
    ruleIndex: number;
    dynamicFilterData?: IDynamicFilterItem;
    getDynamicFilterData?: (dimension: TDimension, offset?: number) => void;
}

const EventFilter: React.FC<IProps> = ({
    filterCnf,
    currentKey,
    allFormsValues,
    setAllFormsValues,
    ruleFormValue,
    curFilterFormsValues,
    ruleIndex,
    dynamicFilterData,
    getDynamicFilterData,
}) => {
    const eventClassItems = filterCnf.data?.map((item) => ({ ...item, key: item.value })) || [];
    const eventClassValue = ruleFormValue.extra?.eventClass || filterCnf?.defaultValue || null;
    const eventNameItems = dynamicFilterData?.data?.filter(({ key }) => key.split('::')[0] === eventClassValue);

    // Запрашиваем данные по динамическому фильтру (FIXME: переделать после правок на бэке)
    useEffect(() => {
        if (dynamicFilterData?.data?.length) return;

        getDynamicFilterData?.(filterCnf?.dynamicFilterAlias || currentKey);
    }, [currentKey]);

    const changeEventNameHandler = (value: string[] | null) => {
        const newFormVal = [...curFilterFormsValues];
        newFormVal[ruleIndex] = { ...newFormVal[ruleIndex], value };
        setAllFormsValues({ ...allFormsValues, [currentKey]: newFormVal });
    };

    const clearEventName = () => changeEventNameHandler(null);

    const changeEventClassHandler = (value: string | null) => {
        const newFormVal = [...curFilterFormsValues];
        newFormVal[ruleIndex] = {
            ...newFormVal[ruleIndex],
            extra: { eventClass: value },
            value: null,
        };
        setAllFormsValues({ ...allFormsValues, [currentKey]: newFormVal });
    };

    const clearEventClass = () => changeEventClassHandler(null);

    const editTag = (nextValue: string, prevValue: string) => {
        const newValue = (ruleFormValue.value as string[])?.map((value) => (value === prevValue ? nextValue : value));
        changeEventNameHandler(newValue);
    };

    // Настройки поиска по label
    const filterOption = (input: string, option?: { label: string; value: string }) =>
        (option?.label ?? '').toLowerCase().includes(input.toLowerCase());

    return (
        <div>
            <FilterFormHeader
                label="Класс события"
                clearFilter={ruleFormValue.extra?.eventClass ? clearEventClass : null}
            />
            <Select
                placeholder="Выберите значение из списка"
                options={eventClassItems}
                value={eventClassValue}
                onChange={changeEventClassHandler}
                filterOption={filterOption}
            />
            <FilterFormHeader
                label={filterCnf?.label}
                hint={filterCnf?.hint}
                clearFilter={ruleFormValue.value?.length ? clearEventName : null}
            />
            <Select
                placeholder="Введите или выберите значение из списка"
                mode="tags"
                options={eventNameItems}
                value={ruleFormValue.value || []}
                onChange={changeEventNameHandler}
                editTags={editTag}
                showArrow={false}
                showSearch={false}
                removeIcon={<IconCross />}
                autoClearSearchValue
                dropdownRender={(menu) => (
                    <Loader loading={dynamicFilterData?.loading} size="small">
                        {menu}
                    </Loader>
                )}
            />
        </div>
    );
};

export default EventFilter;
