import { createAsyncThunk } from '@reduxjs/toolkit';
import { Dimensions } from '@typings/dimensions';
import { IMediaRequestParams } from '@typings/media';
import { Metrics } from '@typings/metrics';
import { IReportRequestParams } from '@typings/reports';
import fetchApi from '@utils/fetchApi';

/**
 * Экшен получения данных для таблицы
 */
export const getReportTable = createAsyncThunk(
    'tableSlice/getReportTable',
    async ({
        projectId,
        reportName,
        params,
        meta,
    }: {
        projectId: string;
        reportName: string;
        params: IReportRequestParams;
        meta: any;
    }) =>
        // eslint-disable-next-line no-return-await
        await fetchApi({
            url: `/api/report/v1.0/report/${projectId}/${reportName}/table`,
            method: 'POST',
            body: params,
            metaInfo: {
                body: params,
                ...params,
                ...meta,
                reportName,
            },
        }),
);

/**
 * Экшен получения данных для таблицы
 */
export const getMediaTable = createAsyncThunk(
    'tableSlice/getMediaTable',
    async ({
        projectId,
        params,
        apiExtraPath = '',
    }: {
        projectId: string;
        params: IMediaRequestParams<Metrics | Dimensions>;
        apiExtraPath: string;
    }) =>
        // eslint-disable-next-line no-return-await
        await fetchApi({
            url: `/api/report/v1.0/project/media/${projectId}/${apiExtraPath}table`,
            method: 'POST',
            body: params,
            metaInfo: {
                body: params,
                ...params,
            },
        }),
);

export const getConstructorTable = createAsyncThunk(
    'tableSlice/getConstructorTable',
    async ({
        projectId,
        reportName,
        params,
        meta,
    }: {
        projectId: string;
        reportName: string;
        params: IReportRequestParams;
        meta: any;
    }) =>
        // eslint-disable-next-line no-return-await
        await fetchApi({
            url: `/api/report/v1.0/eventbase/${projectId}/constructor`,
            method: 'POST',
            body: params,
            metaInfo: {
                body: params,
                ...params,
                ...meta,
                reportName,
            },
        }),
);

/**
 * Экшен добавления дополнительной верхней части таблицы
 */
export const updateTableHeadOffset = createAsyncThunk(
    'tableSlice/updateTableHeadOffset',
    async ({ projectId, reportName, params }: { projectId: string; reportName: string; params: any }) =>
        // eslint-disable-next-line no-return-await
        await fetchApi({
            url: `/api/report/v1.0/report/${projectId}/${reportName}/table`,
            method: 'POST',
            body: params,
            metaInfo: {
                ...params,
                reportName,
            },
        }),
);

// TODO: дубликат updateTableHeadOffset?
/**
 * Экшен добавления дополнительной части таблицы
 */
export const updateTableOffset = createAsyncThunk(
    'tableSlice/updateTableOffset',
    async ({ projectId, reportName, params }: { projectId: string; reportName: string; params: any }) =>
        // eslint-disable-next-line no-return-await
        await fetchApi({
            url: `/api/report/v1.0/report/${projectId}/${reportName}/table`,
            method: 'POST',
            body: params,
            metaInfo: {
                ...params,
                reportName,
            },
        }),
);

/**
 * Экшен добавления дополнительной части таблицы
 */
export const updateMediaTableOffset = createAsyncThunk(
    'tableSlice/updateMediaTableOffset',
    async ({
        projectId,
        reportName,
        apiExtraPath = '',
        params,
        isTopTable = false,
    }: {
        projectId: string;
        reportName: string;
        apiExtraPath: string;
        params: IMediaRequestParams<Metrics>;
        isTopTable?: boolean;
    }) =>
        // eslint-disable-next-line no-return-await
        await fetchApi({
            url: `/api/report/v1.0/project/media/${projectId}/${apiExtraPath}table`,
            method: 'POST',
            body: params,
            metaInfo: {
                ...params,
                reportName,
                projectId,
                isTopTable,
            },
        }),
);

/**
 * Экшен добавления дочерний элементов в таблице
 */
export const updateTableExpand = createAsyncThunk(
    'tableSlice/updateTableExpand',
    async ({ projectId, reportName, params }: { projectId: string; reportName: string; params: any }) =>
        // eslint-disable-next-line no-return-await
        await fetchApi({
            url: `/api/report/v1.0/report/${projectId}/${reportName}/table`,
            method: 'POST',
            body: params,
            metaInfo: {
                ...params,
                reportName,
            },
        }),
);
