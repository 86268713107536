import { Button, Loader, Table } from '@adtech/ui';
import TableSettingsModal from '@components/Funnel/modals/TableSettingsModal';
import { defaultTableColumns, columnsKeys } from '@configs/funnels/defaultTableColumns';
import { metricsDict } from '@configs/metrics';
import useFunnels from '@hooks/useFunnels';
import IconSettings from '@images/svg/icons/icon-settings-table.svg';
import { IFunnelTableData } from '@typings/funnels';
import { Metrics } from '@typings/metrics';
import { IRootSlice } from '@typings/rootSlice';
import { top100Counter } from '@utils/index';
import NumbersUtils from '@utils/numbersUtils';
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';

import s from './FunnelTable.pcss';

interface IProps {
    data: IFunnelTableData[];
    metrics: Metrics[];
    allMetrics: Metrics[];
    isLegendActive: boolean;
}

const FunnelTable: React.FC<IProps> = ({ data, metrics, allMetrics, isLegendActive }) => {
    const currentFunnelId = useSelector((state: IRootSlice) => state.funnelsSlice.currentFunnelId);
    const tableRequest = useSelector((state: IRootSlice) => state.funnelsSlice.tableRequest);

    const { api } = useFunnels();

    const transformToColumns = (metricsValues: Metrics[]) =>
        metricsValues.map((value) => ({
            dataIndex: value,
            title: () => {
                switch (value) {
                    case 'conversion_first_step':
                        return (
                            <span>
                                Конверсия
                                <br />
                                от первого шага,<span className={s.funnelTableCellPercent}>%</span>
                            </span>
                        );
                    case 'conversion_previous_step':
                        return (
                            <span>
                                Конверсия
                                <br />
                                от предыдущего шага,<span className={s.funnelTableCellPercent}>%</span>
                            </span>
                        );
                    case 'visitors_left_step':
                        return (
                            <span>
                                Ушли с шага
                                <br />
                                (отток)
                            </span>
                        );
                    case 'visitors_left_step_share':
                        return (
                            <span>
                                Ушли с шага
                                <br />
                                (отток),<span className={s.funnelTableCellPercent}>%</span>
                            </span>
                        );
                    default:
                        return metricsDict[value]?.title || '';
                }
            },
            align: 'right' as any,
        }));
    const prepareTableData = (item: IFunnelTableData) => {
        const { stepNumber, stepTitle, metrics: metricsValues, metricsWithNotPrevStep } = item;
        const format = (val: number, metric: Metrics) =>
            val ? NumbersUtils.formatValue(val, metricsDict[metric].format) : val;

        // Формируем динамически столбцы из метрик
        const cols = metrics.reduce(
            (obj, metric, i) => ({
                ...obj,
                // В зависимости от переключения легенды меняем значения
                [metric]:
                    isLegendActive && metricsWithNotPrevStep?.length
                        ? format(metricsWithNotPrevStep[i], metric)
                        : format(metricsValues[i], metric),
            }),
            {},
        );

        return {
            key: stepNumber,
            stepNumber,
            title: stepTitle,
            ...cols,
        };
    };
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [columns, setColumns] = useState(transformToColumns(metrics));
    const [tableData, setTableData] = useState(data?.map((step: IFunnelTableData) => prepareTableData(step)));
    const tableDefaultColumns = [
        {
            dataIndex: columnsKeys[0],
            title: defaultTableColumns[columnsKeys[0]],
        },
        {
            dataIndex: columnsKeys[1],
            title: defaultTableColumns[columnsKeys[1]],
            className: s.funnelTableCell,
        },
    ];

    useEffect(() => {
        setColumns(transformToColumns(metrics));
    }, [JSON.stringify(metrics)]);

    useEffect(() => {
        setTableData(data?.map((step: IFunnelTableData) => prepareTableData(step)));
    }, [JSON.stringify(data), isLegendActive]);

    const iconSettingsClickHandler = () => {
        setIsModalOpen(true);
        top100Counter.trackEvent('stat_funnel_edit_table_btn');
    };

    const onModalSettingsOkHandler = (metricsSelected?: Metrics[]) => {
        if (metricsSelected && JSON.stringify(metricsSelected) !== JSON.stringify(metrics)) {
            api.updateParams({ metrics: metricsSelected });
            api.getFunnelTable(currentFunnelId, { metrics: metricsSelected });
        }
        setIsModalOpen(false);
    };

    return (
        <div className={s.funnelTable} data-testid="table-container">
            <Loader loading={tableRequest}>
                <div className={s.funnelTableTitleWrap}>
                    <div className={s.funnelTableTitle} data-testid="title-text">
                        Детализация
                    </div>
                    <Button prefixIcon={<IconSettings />} type="dashed" onClick={iconSettingsClickHandler} />
                </div>
                <Table columns={[...tableDefaultColumns, ...columns]} dataSource={tableData} pagination={false} />
            </Loader>
            <TableSettingsModal
                isOpen={isModalOpen}
                onOkHandler={onModalSettingsOkHandler}
                onCloseHandler={() => setIsModalOpen(false)}
                metrics={metrics}
                allMetrics={allMetrics}
            />
        </div>
    );
};

export default FunnelTable;
