export const getRegistrationUrl = (backParam: string) => {
    const url = 'https://id.rambler.ru/login-20/registration/';

    return `${url}?back=${encodeURIComponent(backParam)}`;
};

export const getLoginUrl = (backParam: string, registrationParam: string) => {
    const url = 'https://id.rambler.ru/login-20/';

    let resultLoginUrl = `${url}?back=${encodeURIComponent(backParam)}`;

    if (registrationParam) {
        resultLoginUrl += `&registration_link=${encodeURIComponent(registrationParam)}`;
    }

    return `${resultLoginUrl}#/login`;
};

export const getDomain = (domainLevel = 2) => {
    const { hostname } = document.location;
    let domain = '';

    if (hostname === 'localhost') {
        domain = 'localhost';
    } else {
        domain = `.${hostname.split('.').reverse().splice(0, domainLevel).reverse().join('.')}`;
    }
    return domain;
};
