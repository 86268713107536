import snackbarTypes from '@constants/snackbarTypes';
import { useAppDispatch } from '@hooks/useStore';
import { globalActions } from '@redux/slices/global';
import { ReactNode } from 'react';

const useSnackbar = () => {
    const dispatch = useAppDispatch();

    const setSnackbar = (message: ReactNode, type: string = snackbarTypes.SUCCESS): void => {
        dispatch(globalActions.setSnackbar({ message, type }));
    };

    const clearSnackbar = (): void => {
        dispatch(globalActions.clearSnackbar());
    };

    return {
        setSnackbar,
        clearSnackbar,
    };
};

export default useSnackbar;
