import { Textarea, Alert } from '@adtech/ui';
import fieldDecorator from '@decorators/fieldDecorator';
import { IField, IMetaField } from '@typings/form';
import { formUtils } from '@utils/index';
import React from 'react';
import Wrapper from '../Wrapper';

import s from './KeywordsData.pcss';

interface IProps {
    meta: IMetaField;
    field: IField;
    disabled: boolean;
    maxKeywordsLength: number;
    isRequired: boolean;
}

export const TextareaData = ({ meta, field, maxKeywordsLength, disabled, isRequired }: IProps) => {
    const isError = meta.invalid && meta.touched && !meta.focused;

    const message = 'Укажите ключевые слова через запятую (не менее 3 и не более 500 символов)';
    const errorText = meta.errorType === 'required' ? message : meta.errorText;

    const value = field?.value as string;
    const { name, onBlur, onChange, onClick, onFocus } = field;

    const handleTextareaChange: React.ChangeEventHandler<HTMLTextAreaElement> = (event) => {
        const changedValue = event.target.value;
        onChange(event, changedValue);
    };

    return (
        <Wrapper
            subWrap
            title="Ключевые слова"
            description="По ключевым словам сайт можно будет найти в каталоге Топ-100."
            rootClassName={s.root}
            smallTitle
            fixPadding
            isRequired={isRequired}
        >
            <div className={s.wrapper}>
                <Alert type="info" message={message} showIcon className={s.alert} />
                <Textarea
                    name={name}
                    value={value}
                    onBlur={onBlur}
                    onChange={handleTextareaChange}
                    onClick={onClick}
                    onFocus={onFocus}
                    disabled={disabled}
                    className={s.textarea}
                    status={isError ? 'error' : null}
                    errorText={isError ? errorText : ''}
                    maxLength={maxKeywordsLength}
                    showCount
                />
            </div>
        </Wrapper>
    );
};

export default fieldDecorator({
    rules: [
        { validate: formUtils.defaultRules.required, errorType: 'required' },
        { validate: formUtils.defaultRules.maxLength(500), errorType: 'length' },
        { validate: formUtils.defaultRules.minLength(3), errorType: 'minlength' },
    ],
    name: 'keywords',
    label: 'Ключевые слова',
    maxKeywordsLength: 500,
})(TextareaData);
