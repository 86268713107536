import { Loader } from '@adtech/ui';
import { Dimensions } from '@typings/dimensions';
import { IMaterialInfo } from '@typings/media';
import { measuresUtils } from '@utils/index';
import React from 'react';

import s from './MaterialInfo.pcss';

const MaterialInfo: React.FC<IMaterialInfo> = ({ title, url, list, loading }: IMaterialInfo) => {
    if (loading || !title) {
        return null;
    }

    return (
        <div className={s.root}>
            <Loader loading={loading}>
                <div className={s.header}>
                    <h2 className={s.headerTitle}>{title}</h2>
                    {url && (
                        <a href={url} className={s.headerLink} target="_blank" rel="noopener">
                            {url}
                        </a>
                    )}
                </div>
                {list && Object.keys(list).length && (
                    <div className={s.list}>
                        {Object.keys(list).map((key: Dimensions) => (
                            <div className={s.item} key={key}>
                                <div className={s.itemTitle}>{measuresUtils.getTitle(key)}</div>
                                <div className={s.itemValue}>{measuresUtils.prepareValue(key, list[key])}</div>
                            </div>
                        ))}
                    </div>
                )}
            </Loader>
        </div>
    );
};

export default MaterialInfo;
