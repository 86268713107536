import React from 'react';

export const IconGreaterOrEq = () => (
    <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
        <g id="icon greater_or_equal 20*20">
            <g id="Union">
                <path
                    d="M6.16147 3.45544C6.46229 2.99227 7.08162 2.86065 7.54479 3.16147L13.4569 7.00123C14.1862 7.47488 14.1853 8.54279 13.4551 9.01514L7.5433 12.8397C7.07959 13.1397 6.46049 13.007 6.1605 12.5433C5.86051 12.0796 5.99323 11.4605 6.45694 11.1605L11.3325 8.00629L6.45544 4.83876C5.99227 4.53795 5.86066 3.91861 6.16147 3.45544Z"
                    fill="#83868D"
                />
                <path
                    d="M6.00293 16C6.00293 15.4477 6.45064 15 7.00293 15H13.0029C13.5552 15 14.0029 15.4477 14.0029 16C14.0029 16.5523 13.5552 17 13.0029 17H7.00293C6.45064 17 6.00293 16.5523 6.00293 16Z"
                    fill="#83868D"
                />
            </g>
        </g>
    </svg>
);
