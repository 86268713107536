/**
 * При правке конфига поправь и filtersConfigAll
 */
import { filtersCnf } from '@configs/filters/filters';
import { IPopularityFilter } from '@typings/filters';

const {
    sex,
    age,
    segments,
    geo_country,
    geo_area,
    geo_city,
    traffic_sources,
    ad_systems,
    ad_campaign,
    utm_eventbase,
    openstat_eventbase,
    sites,
    search_phrase_system,
    search_system_phrase,
    counter_type,
    event_class,
    event_name,
    session_number,
    split,
    device,
    browser,
    os,
    auth_top100,
    auth_rambler,
    view_depth,
    url,
    url_no_args,
    url_args_key,
    entrance_pages,
    exit_pages,
    external_url,
    page_titles,
    recom_system,
    exchange_system,
    message_system,
    qr_system,
    social_network,
    aggrnews_system,
    app_version,
    ub_block_list,
    customer_params_key,
} = filtersCnf;

export const popularityFilters: IPopularityFilter[] = [
    { children: [], title: 'Аудитория: география', tab: 'all', navigation: ['audience', 'geo'], name: 'geo' },
    { children: [], title: 'Источники: источники переходов', tab: 'all', navigation: ['sources', 'traffic_sources'], name: 'traffic_sources' },
    { children: [], title: 'Источники: сайты', tab: 'all', navigation: ['sources', 'sites'], name: 'sites' },
    { children: [], title: 'Источники: поисковые системы', tab: 'all', navigation: ['sources', 'search_system_phrase'], name: 'search_system_phrase' },
];

export const staticFilters = [
    {
        title: 'События',
        name: 'events',
        children: [
            event_class,
            event_name,
            {
                title: 'Параметры события',
                name: 'events_params',
                children: [
                    ub_block_list,
                    customer_params_key,
                ],
            },
        ],
    },
    {
        title: 'Аудитория',
        name: 'audience',
        children: [
            sex,
            age,
            segments,
            {
                title: 'География',
                name: 'geo',
                children: [
                    geo_country,
                    geo_area,
                    geo_city,
                ],
            },
            session_number,
            view_depth,
        ],
    },
    {
        title: 'Технологии',
        name: 'technology',
        children: [
            device,
            browser,
            os,
            counter_type,
            app_version,
        ],
    },
    {
        title: 'Источники',
        name: 'sources',
        children: [
            traffic_sources,
            ad_systems,
            ad_campaign,
            utm_eventbase,
            openstat_eventbase,
            sites,
            search_system_phrase,
            search_phrase_system,
            aggrnews_system,
            exchange_system,
            recom_system,
            social_network,
            message_system,
            qr_system,
        ],
    },
    {
        title: 'Содержание',
        name: 'content',
        children: [
            url,
            url_no_args,
            url_args_key,
            entrance_pages,
            exit_pages,
            external_url,
            page_titles,
        ],
    },
    {
        title: 'Тип авторизации',
        name: 'auth',
        children: [
            auth_top100,
            auth_rambler,
        ],
    },
    {
        title: 'Эксперименты',
        name: 'experiments',
        children: [
            split,
        ],
    },
];
