import dateConst from '@constants/date';
import { LatencyKeys } from '@constants/latency';
import { useAppDispatch } from '@hooks/useStore';
import useValidDates from '@hooks/useValidDates';
import { globalActions } from '@redux/slices/global';
import { latencyActions } from '@redux/slices/latency';
import { IQuery } from '@typings/query';
import { IRootSlice } from '@typings/rootSlice';
import DateUtils from '@utils/date';
import { urlUtils } from '@utils/index';
import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';

const useCheckDates = (latencyKey: LatencyKeys) => {
    const dispatch = useAppDispatch();
    const { search } = useLocation();
    const query: IQuery = urlUtils.parseQuery(search);

    const dateStart = useSelector((state: IRootSlice) => state.globalSlice.dateStart);
    const dateEnd = useSelector((state: IRootSlice) => state.globalSlice.dateEnd);
    const latencyConfig = useSelector((state: IRootSlice) => state.latencySlice[latencyKey]);

    const { getValidIntervalDates } = useValidDates();

    const [datesChecked, setDatesChecked] = useState<boolean>(false);

    const latencyExist = latencyConfig?.start && latencyConfig?.end;

    /**
     * Получаем дефолтные параметры
     * @returns {{dateStart, dateEnd}}
     */
    const getDefaultParams = () => ({ dateStart, dateEnd });

    /**
     * Получение объекта QUERY-параметров
     * @returns {{dateStart, dateEnd, dateStart, dateEnd}}
     */
    const getDatesRange = () => {
        const defaultParams = getDefaultParams();
        const mergedParams = { ...defaultParams, ...query };

        return getValidIntervalDates({
            dateStart: mergedParams.dateStart,
            dateEnd: mergedParams.dateEnd,
        });
    };

    /**
     * Обновляем параметры dateStart, dateEnd в глобальном стейте.
     * Если данные по датам из глобального стейта недефолтные,
     * не запускаем лишний раз обновление глобального стейта
     */
    const updateDatesParams = () => {
        if (dateStart !== dateConst.DEFAULT_DATE && dateEnd !== dateConst.DEFAULT_DATE) {
            return;
        }

        const datesRange = getDatesRange();

        dispatch(globalActions.updateParams(datesRange));
    };

    /**
     * Проверка интервала на допустимые значения.
     * Если интервал некорректный, устанавливаем значение isExcludedLatency = true,
     * которое вызывает календарь для выбора корректных дат
     * @return bool
     */
    const checkDatesInterval = () => {
        const range = {
            minDate: latencyConfig.start,
            maxDate: latencyConfig.end,
        };

        dispatch(
            latencyActions.updateExcludedParam({
                result:
                    DateUtils.checkIsDateExcludedRange(dateStart, range) ||
                    DateUtils.checkIsDateExcludedRange(dateEnd, range),
                latencyKey,
            }),
        );

        setDatesChecked(true);
    };

    useEffect(() => {
        updateDatesParams();
    }, []);

    useEffect(() => {
        if (!latencyExist) return;

        checkDatesInterval();
    }, [dateStart, dateEnd, latencyExist, latencyKey]);

    return datesChecked;
};

export default useCheckDates;
