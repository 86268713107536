import { metricsDict } from '@configs/metrics';
import reportsConfig from '@configs/reports';
import reportsConstants from '@constants/reports';
import { IMetricsTableData, IMetricsWithStates, Metrics } from '@typings/metrics';
import { ITableSlice } from '@typings/rootSlice';
import { IReportTableData, IReportTableReqResponse } from '@typings/table';
import DateUtils from '@utils/date';
import { arrayUtils, measuresUtils, numbersUtils } from '@utils/index';

const tableReducerUtils = {
    defaultTitle: 'Нет названия',
    notValue: 'NA',

    getTotals: (data: IReportTableReqResponse, isRating: boolean, tableSlice?: ITableSlice) => {
        if (isRating) {
            return data?.meta?.leader?.metrics || tableSlice?.totals || [];
        }

        return data?.result?.totals || [];
    },

    /**
     * Форматируем и возвращаем title
     */
    getTitle: (item, parents, config) => {
        const { titleAliases, childrenTitleDelimiter, titleFormat } = config;

        let title = item?.extra?.title || item.id;

        title = String(title);

        if (titleFormat) {
            switch (titleFormat) {
                case 'date': {
                    title = DateUtils.readable(title);
                    break;
                }
                default:
                    break;
            }
        }

        if (titleAliases && titleAliases[title]) {
            title = titleAliases[title];
        }

        if (childrenTitleDelimiter) {
            const parts = [...parents, title];

            title = parts.reduce((acc, part, index) => {
                const delimiter = childrenTitleDelimiter[index - 1];

                return acc + delimiter + part;
            });
        }

        if (title.length > 0 && title !== 'undefined') return title;

        return tableReducerUtils.defaultTitle;
    },

    /**
     * Преобразовываем metrics в metricsWithState
     */
    prepareMetricsWithState: (metrics: Metrics[], oldMetrics: IMetricsWithStates[]): IMetricsWithStates[] =>
        metrics.map((metric, index) => {
            if (arrayUtils.isArray(oldMetrics) && oldMetrics[index]) {
                if (metric === oldMetrics[index].name) {
                    return oldMetrics[index];
                }
            }
            return {
                name: metric,
                state: 'normal',
            };
        }),

    getMetrics: (metricsData: number[], metrics: Metrics[], totals: number[]): IMetricsTableData[] => {
        const result = [];
        const notValue = 'NA';

        metrics.forEach((item, index) => {
            const normal = metricsData[index];
            result.push({
                normal: normal ?? notValue,
                percent: tableReducerUtils.getPercent(metrics[index], totals[index], metricsData[index]),
            });
        });

        return result;
    },

    getSortName: (defaultName: Metrics, sortName: Metrics): Metrics => {
        let name = defaultName;

        if (Object.keys(measuresUtils.getConfig(sortName)).length) {
            name = sortName;
        }

        return name;
    },

    /**
     * Проверяем можно ли использовать проценты
     */
    getPercent: (metricName: Metrics, total: number, value: number): string => {
        const config = metricsDict[metricName];

        if (config && !config.hidePercent && total) {
            const percent = numbersUtils.getPercentValueByNumber(total, value);

            return numbersUtils.percentFormat(percent);
        }

        return null;
    },

    /**
     * Возвращает id для Гео
     * id для Сегментов
     */
    getGeoId: (geoItem: IReportTableData): number =>
        geoItem.geo_country_id || geoItem.geo_area_id || geoItem.geo_city_id || geoItem.category_1_id,

    /**
     * Форматирует данные для передачи на клиент для таблицы
     */
    prepareTableData: (data: IReportTableReqResponse, meta: any, tableSlice?: ITableSlice) => {
        const { parents = [], reportName } = meta;
        const config = reportsConfig[reportName] || {};
        const isRating = reportName === 'rating';

        const metrics = data.result.metrics ? data.result.metrics : meta.metrics;
        // const metrics = meta.metrics ? meta.metrics : data.result.metrics;

        // нужен для отчётов, где для запроса вложенностей нужно отправлять id, а не title
        const resultMetrics = metrics;

        // собираем collection, list и dict
        const collection = [];
        const list = [];
        const dict = {};

        const totals = tableReducerUtils.getTotals(data, isRating, tableSlice);

        const items = [...data.result.data];

        if (isRating && items.length && data.meta.leader?.id && data.meta.offset) {
            // Лидер приходит первым элементом в data.result.data, см. HYDRA-10486, HYDRA-9882
            items[0] = {
                ...items[0],
                isLeader: true,
            };
        }

        let pinned = [];
        if (isRating && data.meta.pinned) {
            pinned = data.meta.pinned.map((item) => item.toString());
        }

        // декомпозируем результат
        items.forEach((item) => {
            const geoId = tableReducerUtils.getGeoId(item);
            const isGeo = Number.isFinite(geoId);
            const itemId = isGeo ? geoId : item.id;
            const title = tableReducerUtils.getTitle(item, parents, config);
            const itemMetrics = tableReducerUtils.getMetrics(item.metrics, metrics, totals);
            const withParentsId = `${[...parents, itemId].join(reportsConstants.PARENTS_DELIMITER)}`;

            list.push(withParentsId);

            dict[withParentsId] = {
                withParentsId,
                id: itemId,
                geoId: isGeo ? geoId : null,
                // item.extra.public_stats приходит с сервера только для Рейтинга,
                // остальные отчёты доступны по умолчанию
                publicStats: item?.extra?.public_stats ?? true,
                parentId: item.parent_id || reportsConstants.ROOT_NODE,
                canBeExpanded: item.can_be_expanded,
                expand: false,
                title,
                metricsData: itemMetrics,
                level: item.level,
                ...(item.isLeader ? { isLeader: item.isLeader } : {}),
            };

            if (item.extra) {
                dict[withParentsId].extra = item.extra;
            }

            collection.push(dict[withParentsId]);
        });

        // возвращаем результат
        return {
            data: { dict, list, collection },
            metrics: resultMetrics,
            allMetrics: data.result.all_metrics,
            totals,
            total: data.meta.total,
            sort: data.result.sort,
            sample: data.request.sample,
            ...(pinned.length
                ? {
                      pinnedProjectsIds: pinned,
                  }
                : {}),
            ...(data.meta.offset ? { offsetHead: data.meta.offset } : {}),
        };
    },
};

export default tableReducerUtils;
