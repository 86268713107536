import { createAsyncThunk } from '@reduxjs/toolkit';
import {
    IConversionData,
    IConversionRequestData,
    IConversionRequestTableData,
    IConversionWidgetRequestData,
} from '@typings/conversions';
import { IUpdateReportParams } from '@typings/reports';
import fetchApi from '@utils/fetchApi';

export const getTable = createAsyncThunk(
    'conversionsSlice/getTable',
    async ({
        projectId,
        params,
    }: {
        projectId: string;
        params?: IUpdateReportParams & { showCommonConversions: boolean };
    }) => {
        const { limit, titleFilter: searchWord, showCommonConversions } = params || {};
        let queryParams = limit ? `&limit=${limit}` : '';
        queryParams += searchWord ? `&search=${searchWord}` : '';
        queryParams += showCommonConversions ? '&show_common=true' : '';

        // eslint-disable-next-line no-return-await
        return await fetchApi({
            url: `/api/v1.0/user/conversions?project_id=${projectId}${queryParams}`,
        });
    },
);

export const updateTable = createAsyncThunk(
    'conversionsSlice/updateTable',
    async ({
        projectId,
        offset,
        searchWord,
        showCommonConversions,
    }: {
        projectId: string;
        offset: number;
        searchWord?: string;
        showCommonConversions?: boolean;
    }) => {
        let queryParams = offset ? `&offset=${offset}` : '';
        queryParams += searchWord ? `&search=${searchWord}` : '';
        queryParams += showCommonConversions ? '&show_common=true' : '';

        // eslint-disable-next-line no-return-await
        return await fetchApi({
            url: `/api/v1.0/user/conversions?project_id=${projectId}${queryParams}`,
        });
    },
);

export const createConversion = createAsyncThunk(
    'conversionsSlice/createConversion',
    async ({ data, userEmail }: { data: IConversionData; userEmail?: string }) =>
        // eslint-disable-next-line no-return-await
        await fetchApi({
            url: '/api/v1.0/conversion',
            method: 'POST',
            body: { ...data },
            metaInfo: {
                ...data,
                email: userEmail,
                access: 'own',
            }, // дописываем в мету email, access, которых нет в данных
        }),
);

export const updateConversion = createAsyncThunk(
    'conversionsSlice/updateConversion',
    async ({
        id,
        data,
        isOneConversionReport,
    }: {
        id: number;
        data: Partial<IConversionData>;
        isOneConversionReport?: boolean;
    }) =>
        // eslint-disable-next-line no-return-await
        await fetchApi({
            url: `/api/v1.0/conversion/${id}`,
            method: 'PUT',
            body: { ...data },
            metaInfo: {
                data,
                isOneConversionReport,
            },
        }),
);

export const deleteConversion = createAsyncThunk(
    'conversionsSlice/deleteConversion',
    async (id: number) =>
        // eslint-disable-next-line no-return-await
        await fetchApi({
            url: `/api/v1.0/conversion/${id}`,
            method: 'DELETE',
            metaInfo: { id },
        }),
);

export const getConversion = createAsyncThunk(
    'conversionsSlice/getConversion',
    async (id: number) =>
        // eslint-disable-next-line no-return-await
        await fetchApi({
            url: `/api/v1.0/conversion/${id}`,
            method: 'GET',
            metaInfo: { id },
        }),
);

export const getConversionData = createAsyncThunk(
    'conversionsSlice/getConversionData',
    async ({ projectId, data, id }: { projectId: string; data: IConversionRequestData; id: number }) =>
        // eslint-disable-next-line no-return-await
        await fetchApi({
            url: `/api/report/v1.0/eventbase/${projectId}/conversions`,
            method: 'POST',
            body: { ...data },
            metaInfo: { id },
        }),
);

export const getConversionWidgetData = createAsyncThunk(
    'conversionsSlice/getConversionWidgetData',
    async ({
        widgetName,
        projectId,
        data,
        isBySwitchGraphType,
    }: {
        widgetName: string;
        projectId: string;
        data: IConversionWidgetRequestData;
        isBySwitchGraphType?: boolean;
    }) =>
        // eslint-disable-next-line no-return-await
        await fetchApi({
            url: `/api/report/v1.0/eventbase/${projectId}/conversion_widget/eventbase_conversion_${widgetName}`,
            method: 'POST',
            body: { ...data },
            metaInfo: { widgetName, isBySwitchGraphType },
        }),
);

export const getConversionTable = createAsyncThunk(
    'conversionsSlice/getConversionTable',
    async ({ projectId, params }: { projectId: string; params?: IConversionRequestTableData }) =>
        fetchApi({
            url: `/api/report/v1.0/eventbase/${projectId}/conversion_table`,
            method: 'POST',
            body: { ...params },
        }),
);
