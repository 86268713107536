import { GroupBy } from '@configs/group';
import { ViewType } from '@constants/viewType';
import { DateType } from '@typings/date';
import { Dimensions } from '@typings/dimensions';
import { IFilter } from '@typings/filters';
import { IHint } from '@typings/hint';
import { Metrics } from '@typings/metrics';
import { ReportName } from '@typings/reports';

interface ILink {
    name: string;
    title: string;
    icon?: ETemplateIcon;
    url?: string;
    dateStart?: DateType,
    dateEnd?: DateType,
    daysInRange?: {
        period: 'day' | 'week' | 'month' | 'year',
        range: number,
    },
    groupBy: GroupBy,
    metrics?: Metrics[];
    dimensions?: Dimensions[];
    order?: Metrics;
    link?: string;
    viewType?: ViewType;
    globalFilters?: IFilter[]
}

interface IGlobalParams {
    dateStart: DateType,
    dateEnd: DateType,
    groupBy: GroupBy,
}
export interface ITemplate {
    name: string;
    title: string;
    description: string;
    children: ILink[];
}

const constructorUrl = '/new/report/constructor';
const globalParams: IGlobalParams = {
    dateStart: 'today',
    dateEnd: 'today',
    groupBy: GroupBy.fiveMinute,
};

interface IConfig {
    title: string;
    reportName: ReportName;
    reportHint: IHint;
    tableHint?: IHint;
    tableHeadTitles: Record<string, string>[];
    templateActions: Record<string, string>[];
}

export const templatesConfig: IConfig = {
    title: 'Конструктор отчётов',
    reportName: 'constructor_templates',
    reportHint: {
        message: 'Конструктор отчётов — инструмент для создания произвольных отчётов и отображения собранных данных в удобном виде. <br />'
        + 'Используйте предустановленные шаблоны отчётов или создайте новый. Шаблоны можно сохранять, а отчёты передавать коллегам с помощью ссылки (в разработке). <br />',
    },
    // TODO: Пока скрываем
    // tableHint: {
    //     message: 'Описание таблицы сохраненных шаблонов отчётов конструктора',
    // },
    tableHeadTitles: [
        {
            title: 'Название отчёта',
            param: 'title',
        }, {
            title: 'Создатель',
            param: 'email',
        }, {
            title: 'Последнее изменение',
            param: 'update_date',
        },
    ],
    templateActions: [
        /* {
            title: 'Предоставить доступ',
            id: 'share',
        }, {
            title: 'Создать копию',
            id: 'copy',
        }, */
        {
            title: 'Переименовать',
            id: 'rename',
        }, {
            title: 'Удалить',
            id: 'delete',
        },
    ],
};

export const creatingTemplate: Record<string, string> = {
    title: 'Создать новый отчёт',
    description: 'Новый отчёт можно сохранить и использовать в качестве шаблона.',
};

export enum ETemplateIcon {
    click = 'click-link',
    dau = 'dau',
    wau = 'wau',
    mau = 'mau',
    depth = 'page-depth',
    geo = 'geo',
    sex = 'sex',
    age = 'age',
    interests = 'interests',
    devices = 'devices',
    browser = 'browser',
    os = 'os',
    screen = 'screen',
    phone = 'phone',
    sources = 'sources',
    adv = 'adv',
    url = 'url',
    openstat = 'openstat',
    site = 'site',
    se = 'se',
    news = 'news',
    exchange = 'exchange',
    social = 'social',
    messengers = 'messengers',
    qr = 'qr',
    recom = 'recom',
}

export const templates: ITemplate[] = [
    {
        name: 'audience',
        title: 'Отчёты по аудитории',
        description: 'Общая структура аудитории, а также соцдем характеристики пользователей.',
        children: [
            {
                name: 'page_views',
                title: 'Посещаемость',
                icon: ETemplateIcon.click,
                url: constructorUrl,
                ...globalParams,
                metrics: ['page_views'],
                dimensions: ['date'],
                viewType: ViewType.graph,
            },
            {
                name: 'dau',
                title: 'DAU за 3 дня',
                icon: ETemplateIcon.dau,
                url: constructorUrl,
                daysInRange: {
                    period: 'day',
                    range: 2,
                },
                groupBy: GroupBy.day,
                metrics: ['visitors'],
                dimensions: ['date'],
            },
            {
                name: 'wau',
                title: 'WAU за 3 недели',
                icon: ETemplateIcon.wau,
                url: constructorUrl,
                daysInRange: {
                    period: 'week',
                    range: 2,
                },
                groupBy: GroupBy.week,
                metrics: ['visitors'],
                dimensions: ['date'],
            },
            {
                name: 'mau',
                title: 'MAU за 3 месяца',
                icon: ETemplateIcon.mau,
                url: constructorUrl,
                daysInRange: {
                    period: 'month',
                    range: 2,
                },
                groupBy: GroupBy.month,
                metrics: ['visitors'],
                dimensions: ['date'],
            },
            {
                name: 'view_depth',
                title: 'Глубина просмотра',
                icon: ETemplateIcon.depth,
                url: constructorUrl,
                ...globalParams,
                metrics: ['page_views', 'visits'],
                dimensions: ['view_depth'],
            },
            // TODO: Ждем деменшен по времени на сайте
            // {
            //     name: 'time',
            //     title: 'Время на сайте',
            //     icon: ETemplateIcon.news,
            //     url: constructorUrl,
            //     ...globalParams,
            //     metrics: ['page_views', 'visits', 'visitors'],
            //     dimensions: [''],
            // },
            {
                name: 'geo',
                title: 'География',
                icon: ETemplateIcon.geo,
                url: constructorUrl,
                ...globalParams,
                metrics: ['page_views', 'visits', 'visitors'],
                dimensions: ['geo_country', 'geo_area', 'geo_city'],
            },
            {
                name: 'gender',
                title: 'Пол',
                icon: ETemplateIcon.sex,
                url: constructorUrl,
                ...globalParams,
                metrics: ['page_views', 'visits', 'visitors'],
                dimensions: ['gender'],
            },
            {
                name: 'age',
                title: 'Возраст',
                icon: ETemplateIcon.age,
                url: constructorUrl,
                ...globalParams,
                metrics: ['page_views', 'visits', 'visitors'],
                dimensions: ['age_tns'],
            },
            {
                name: 'segments',
                title: 'Интересы',
                icon: ETemplateIcon.interests,
                url: constructorUrl,
                ...globalParams,
                metrics: ['page_views', 'visits', 'visitors'],
                dimensions: ['category_1'],
            },
        ],
    },
    {
        name: 'technology',
        title: 'Отчёты по технологиям',
        description: 'Браузеры, ОС, платформы (web/inapp), которыми пользуется аудитория.',
        children: [
            {
                name: 'device',
                title: 'Устройства',
                icon: ETemplateIcon.devices,
                url: constructorUrl,
                ...globalParams,
                metrics: ['page_views', 'visits', 'visitors'],
                dimensions: ['device_type', 'device_brand', 'device_family', 'device_model'],
            },
            {
                name: 'browser',
                title: 'Браузеры',
                icon: ETemplateIcon.browser,
                url: constructorUrl,
                ...globalParams,
                metrics: ['page_views', 'visits', 'visitors'],
                dimensions: ['browser_family', 'browser_version_major', 'browser_version_minor'],
            },
            {
                name: 'os',
                title: 'Операционные системы',
                icon: ETemplateIcon.os,
                url: constructorUrl,
                ...globalParams,
                metrics: ['page_views', 'visits', 'visitors'],
                dimensions: ['os_family', 'os_version_major', 'os_version_minor'],
            },
            {
                name: 'screen_size',
                title: 'Разрешение экрана',
                icon: ETemplateIcon.screen,
                url: constructorUrl,
                ...globalParams,
                metrics: ['page_views', 'visits', 'visitors'],
                dimensions: ['screen_width', 'screen_height'],
            },
            {
                name: 'page_formats',
                title: 'Формат страниц (web-in-app)',
                icon: ETemplateIcon.phone,
                url: constructorUrl,
                ...globalParams,
                metrics: ['page_views', 'visits', 'visitors'],
                dimensions: ['counter_type_eventbase'],
            },
        ],
    },
    {
        name: 'sources',
        title: 'Отчёты по источникам',
        description: 'Распределение аудитории по разным каналам переходов.',
        children: [
            {
                name: 'traffic_type',
                title: 'Источники переходов',
                icon: ETemplateIcon.sources,
                url: constructorUrl,
                ...globalParams,
                metrics: ['page_views', 'visits', 'visitors'],
                dimensions: ['traffic_type'],
            },
            {
                name: 'ad_system',
                title: 'Рекламные системы',
                icon: ETemplateIcon.adv,
                url: constructorUrl,
                ...globalParams,
                metrics: ['page_views', 'visits', 'visitors'],
                dimensions: ['ad_system'],
            },
            {
                name: 'utm',
                title: 'UTM-метки',
                icon: ETemplateIcon.url,
                url: constructorUrl,
                ...globalParams,
                metrics: ['page_views', 'visits', 'visitors'],
                dimensions: ['utm_campaign', 'utm_content', 'utm_medium', 'utm_source', 'utm_term'],
            },
            {
                name: 'openstat',
                title: 'Метки Openstat',
                icon: ETemplateIcon.openstat,
                url: constructorUrl,
                ...globalParams,
                metrics: ['page_views', 'visits', 'visitors'],
                dimensions: ['openstat_ad', 'openstat_campaign', 'openstat_service', 'openstat_source'],
            },
            {
                name: 'referer',
                title: 'Сайты / Referer',
                icon: ETemplateIcon.site,
                url: constructorUrl,
                ...globalParams,
                metrics: ['page_views', 'visits', 'visitors'],
                dimensions: ['referer_domain', 'referer_path'],
            },
            {
                name: 'search_system',
                title: 'Поисковые системы',
                icon: ETemplateIcon.se,
                url: constructorUrl,
                ...globalParams,
                metrics: ['page_views', 'visits', 'visitors'],
                dimensions: ['search_system'],
            },
            {
                name: 'aggrnews_system',
                title: 'Новостные агрегаторы',
                icon: ETemplateIcon.news,
                url: constructorUrl,
                ...globalParams,
                metrics: ['page_views', 'visits', 'visitors'],
                dimensions: ['aggrnews_system'],
            },
            {
                name: 'exchange_system',
                title: 'Обменные сети',
                icon: ETemplateIcon.exchange,
                url: constructorUrl,
                ...globalParams,
                metrics: ['page_views', 'visits', 'visitors'],
                dimensions: ['exchange_system'],
            },
            {
                name: 'recom_system',
                title: 'Рекомендательные системы',
                icon: ETemplateIcon.recom,
                url: constructorUrl,
                ...globalParams,
                metrics: ['page_views', 'visits', 'visitors'],
                dimensions: ['recom_system'],
            },
            {
                name: 'social_network',
                title: 'Социальные сети',
                icon: ETemplateIcon.social,
                url: constructorUrl,
                ...globalParams,
                metrics: ['page_views', 'visits', 'visitors'],
                dimensions: ['social_network'],
            },
            {
                name: 'message_system',
                title: 'Мессенджеры',
                icon: ETemplateIcon.messengers,
                url: constructorUrl,
                ...globalParams,
                metrics: ['page_views', 'visits', 'visitors'],
                dimensions: ['message_system'],
            },
            {
                name: 'qr_system',
                title: 'Переходы по QR-кодам',
                icon: ETemplateIcon.qr,
                url: constructorUrl,
                ...globalParams,
                metrics: ['page_views', 'visits', 'visitors'],
                dimensions: ['qr_system'],
            },
        ],
    },
    {
        name: 'content',
        title: 'Отчёты по содержанию',
        description: 'URL, параметры URL и заголовки страниц проекта.',
        children: [
            {
                name: 'url',
                title: 'URL',
                icon: ETemplateIcon.url,
                url: constructorUrl,
                ...globalParams,
                metrics: ['page_views', 'visits', 'visitors'],
                dimensions: ['url'],
            },
            {
                name: 'url_no_args',
                title: 'Популярные URL',
                icon: ETemplateIcon.url,
                url: constructorUrl,
                ...globalParams,
                metrics: ['page_views', 'visits', 'visitors'],
                dimensions: ['url_no_args'],
            },
            {
                name: 'url_args_key',
                title: 'Параметры URL',
                icon: ETemplateIcon.url,
                url: constructorUrl,
                ...globalParams,
                metrics: ['page_views', 'visits', 'visitors'],
                dimensions: ['url_args_key'],
            },
            {
                name: 'entry_page',
                title: 'Страницы входа',
                icon: ETemplateIcon.site,
                url: constructorUrl,
                ...globalParams,
                metrics: ['page_views', 'visits', 'visitors'],
                dimensions: ['entry_page'],
            },
            {
                name: 'exit_page',
                title: 'Страницы выхода',
                icon: ETemplateIcon.site,
                url: constructorUrl,
                ...globalParams,
                metrics: ['page_views', 'visits', 'visitors'],
                dimensions: ['exit_page'],
            },
            {
                name: 'title',
                title: 'Заголовки страниц',
                icon: ETemplateIcon.news,
                url: constructorUrl,
                ...globalParams,
                metrics: ['page_views', 'visits', 'visitors'],
                dimensions: ['title'],
            },
        ],
    },
    {
        name: 'behavior',
        title: 'Отчёты по поведению',
        description: 'Клики и показы по блокам, а также другие параметры событий.',
        children: [
            {
                name: 'custom_events',
                title: 'Собственные события',
                icon: ETemplateIcon.click,
                url: constructorUrl,
                ...globalParams,
                metrics: ['events_count'],
                dimensions: ['event_name', 'customer_params_key'],
                globalFilters: [{
                    key: 'event_class',
                    op: 'include',
                    value: ['cv'],
                }],
            },
            {
                name: 'usability',
                title: 'Аналитика блоков',
                icon: ETemplateIcon.news,
                url: constructorUrl,
                ...globalParams,
                metrics: ['events_count'],
                dimensions: ['event_name', 'ub_block_list'],
                globalFilters: [{
                    key: 'event_class',
                    op: 'include',
                    value: ['ub'],
                }],
            },
            {
                name: 'external_url',
                title: 'Переходы по внешним ссылкам',
                icon: ETemplateIcon.sources,
                url: constructorUrl,
                ...globalParams,
                metrics: ['events_count'],
                dimensions: ['external_url', 'event_name'],
            },
        ],
    },
];
