import { Operations } from '@configs/operations';

export const reportQueryParams = {
    dateStart: {
        type: 'date',
        required: true,
        inQuery: true,
    },
    dateEnd: {
        type: 'date',
        required: true,
        inQuery: true,
    },
    groupBy: {
        type: 'string',
        required: true,
        inQuery: true,
    },
    split: {
        type: 'string',
        required: true,
        inQuery: true,
    },
    orderBy: {
        type: 'object',
        required: false,
        inQuery: true,
    },
    categoryId: {
        type: 'string',
        required: false,
        inQuery: true,
    },
    graphs: {
        type: 'objectArray',
        required: true,
        inQuery: true,
    },
    metrics: {
        type: 'array',
        required: true,
        inQuery: true,
    },
    dimensions: {
        type: 'array',
        required: false,
        inQuery: true,
    },
    sample: {
        type: 'number',
        required: false,
        inQuery: true,
    },
    viewType: {
        type: 'string',
        required: false,
        inQuery: true,
    },
    globalFilters: {
        type: 'filtersArray',
        required: false,
        inQuery: true,
    },
    tableFilters: {
        type: 'objectArray',
        required: false,
        customDelimiters: [Operations.lt, Operations.gt, Operations.eq],
        inQuery: true,
    },
    limit: {
        type: 'number',
        required: false,
        inQuery: true,
    },
    offset: {
        type: 'number',
        required: false,
        inQuery: true,
    },
    // На данный момент не являются по факту query параметрами
    selectedLines: {
        type: 'array',
        required: true,
    },
    titleFilter: {
        type: 'string',
        required: false,
    },
    // временное переключение дизайна
    sberads: {
        type: 'number',
        required: false,
    },
    // парамтр для создания счетчика юзерами из TD
    td: {
        type: 'number',
        required: false,
    }
};
