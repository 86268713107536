import { sortOrders } from '@constants/sort';
import { IReportConfig } from '@typings/reports';

const config: IReportConfig = {
    reportTitle: 'Воронки',
    tableDataType: 'dynamic',
    availableTools: {
        periodPicker: true,
        detail: false,
        sample: true,
        activeSample: true,
        filters: true,
    },
    defaultSort: {
        name: 'visitors',
        order: sortOrders.DESC,
    },
    defaultGraphs: [],
    graphDefaultMetrics: [],
    tableDefaultMetrics: [
        'visitors_all',
        'conversion_first_step',
        'conversion_previous_step',
        'visitors_left_step',
        'visitors_left_step_share',
    ],
    reportHint: {
        message: '<p>Отчет &laquo;Воронки&raquo;&nbsp;&mdash; это инструмент для пошагового анализа конверсии в&nbsp;настраиваемых пользовательских сценариях. '
        + 'С&nbsp;его помощью вы&nbsp;можете получить подробные данные о&nbsp;том, как пользователи взаимодействуют с&nbsp;сайтом/приложением, насколько успешно они проходят каждый этап воронки до&nbsp;выполнения нужного действия. '
        + 'Отчет состоит из&nbsp;интерактивного графика, отображающего конверсию на&nbsp;каждом этапе воронки, а&nbsp;также таблицы.</p>',
        link: 'https://ads.sber.ru/help/voronki',
    },
};

export default config;
