import { Button, Loader, Pagination, Table, IconSortArrowDown } from '@adtech/ui';
import ReportMessage from '@components/ReportMessage';
import conversionConfig from '@configs/conversions/conversion';
import { dimensionsDict } from '@configs/dimensions';
import { metricsDict } from '@configs/metrics';
import { sortOrders } from '@constants/sort';
import useConversions from '@hooks/useConversions';
import useModalPopup from '@hooks/useModalPopup';
import useSnackbar from '@hooks/useSnackbar';
import IconSettings from '@images/svg/icons/icon-settings-table.svg';
import { ISingleConversionTableData } from '@typings/conversions';
import { Dimensions } from '@typings/dimensions';
import { Metrics } from '@typings/metrics';
import { IRootSlice } from '@typings/rootSlice';
import numbersUtils from '@utils/numbersUtils';
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';

import s from './MarketingTable.pcss';
import TableSettingsModal from './TableSettingsModal';

interface IProps {
    data: ISingleConversionTableData[];
    allDimensions: Dimensions[];
    defaultColumn: Metrics;
}

const MarketingTable: React.FC<IProps> = ({ data, defaultColumn, allDimensions }) => {
    const tableRequest = useSelector((state: IRootSlice) => state.conversionsSlice.conversionTableRequest);
    const total = useSelector((state: IRootSlice) => state.conversionsSlice.conversionTableTotal);
    const dimensions = useSelector((state: IRootSlice) => state.conversionsSlice.dimensions) || [];
    const totalMetrics = useSelector((state: IRootSlice) => state.conversionsSlice.totalMetrics) || [];
    const userEmail = useSelector((state: IRootSlice) => state.userSlice?.email);

    const { api } = useConversions();

    const { setSnackbar } = useSnackbar();
    const { openModalPopup } = useModalPopup();

    const defaultDimension = conversionConfig.tableFixedDimensions[0];
    const defaultLimit = conversionConfig.defaultLimit || 10;

    const [sortDesc, setSortDesc] = useState(true);
    const [currentPage, setCurrentPage] = useState(1);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [totalValue, setTotalValue] = useState(0);
    const [limit, setLimit] = useState(defaultLimit);

    useEffect(() => {
        const foundMetric = totalMetrics.find((obj) => Object.prototype.hasOwnProperty.call(obj, defaultColumn));
        setTotalValue(foundMetric ? foundMetric[defaultColumn] : 0);
    }, [totalMetrics]);

    const handleChangePagination = (page: number, pageSize?: number) => {
        setCurrentPage(page);
        setLimit(pageSize);
        const newOffset = { offset: limit * (page - 1), limit: pageSize };
        api.updateParams(newOffset);
        api.getConversionTable(newOffset);
    };

    const handleRequestCsv = () => {
        api.requestCsv();

        openModalPopup('Csv', { userEmail });
    };

    const changeSort = () => {
        const newSort = { sort: { name: defaultColumn, order: !sortDesc ? sortOrders.DESC : sortOrders.ASC } };
        setSortDesc(!sortDesc);
        api.updateParams(newSort);
        api.getConversionTable(newSort);
    };

    const transformToColumns = (dimensionsValues: string[]) =>
        dimensionsValues.map((value) => ({
            dataIndex: value,
            title: () => {
                switch (value) {
                    case 'traffic_system':
                        return <span>Источники трафика детально</span>;
                    case 'traffic_type':
                        return <span>Источники трафика</span>;
                    case 'utm_source':
                        return <span>utm_source</span>;
                    case 'utm_medium':
                        return <span>utm_medium</span>;
                    case 'utm_campaign':
                        return <span>utm_campaign</span>;
                    case 'utm_content':
                        return <span>utm_content</span>;
                    case 'utm_term':
                        return <span>utm_term</span>;
                    default:
                        return dimensionsDict[value]?.title || '';
                }
            },
            align: 'left' as any,
        }));

    const [columns, setColumns] = useState(transformToColumns([defaultDimension, ...dimensions]));

    const cellTitle = (
        <div className={s.titleCell} onClick={changeSort}>
            <div className={s.sortCell}>
                {metricsDict[defaultColumn]?.title || ''}
                <IconSortArrowDown className={!sortDesc && s.sortIconReversed} />
            </div>
            <span className={s.totalCell}>итого: {numbersUtils.numberFormat(totalValue || 0)}</span>
        </div>
    );

    const tableDefaultColumns = [
        {
            id: defaultColumn,
            dataIndex: defaultColumn,
            checked: true,
            disabled: false,
            sortDisabled: false,
            title: cellTitle,
        },
    ];

    useEffect(() => {
        setColumns(transformToColumns(dimensions));
    }, [JSON.stringify(dimensions)]);

    const onModalSettingsOkHandler = (dimensionsSelected?: Dimensions[]) => {
        if (dimensionsSelected && JSON.stringify(dimensionsSelected) !== JSON.stringify(dimensions)) {
            setCurrentPage(1);
            api.updateParams({ dimensions: dimensionsSelected, offset: 0 });
            api.getConversionTable({ dimensions: dimensionsSelected });
        }
        setIsModalOpen(false);
        setSnackbar('Данные пересчитаны');
    };

    return (
        <div className={s.marketingTable} data-testid="table-container">
            <Loader loading={tableRequest}>
                <div className={s.marketingTableTitleWrap}>
                    <div className={s.marketingTableTitle} data-testid="title-text">
                        Детализация <span className={s.marketingTableNew}>Новое</span>
                    </div>
                    <div className={s.marketingTableBtns}>
                        <Button size="small" type="dashed" onClick={() => handleRequestCsv()}>
                            Скачать в CSV
                        </Button>
                        <Button
                            icon={<IconSettings />}
                            size="small"
                            type="dashed"
                            onClick={() => setIsModalOpen(true)}
                        />
                    </div>
                </div>

                {data?.length ? (
                    <>
                        <Table
                            columns={[...tableDefaultColumns, ...columns]}
                            dataSource={data as any[]}
                            pagination={false}
                        />
                        {total > limit && (
                            <div className={s.pagination}>
                                <Pagination
                                    total={total}
                                    defaultPageSize={limit}
                                    current={currentPage}
                                    onChange={handleChangePagination}
                                />
                            </div>
                        )}
                    </>
                ) : (
                    <ReportMessage className={s.tableNoResults} message="Нет данных для отображения" />
                )}
            </Loader>
            <TableSettingsModal
                isOpen={isModalOpen}
                defaultColumn={defaultColumn}
                onOkHandler={onModalSettingsOkHandler}
                onCloseHandler={() => setIsModalOpen(false)}
                dimensions={dimensions}
                allDimensions={allDimensions}
            />
        </div>
    );
};

export default MarketingTable;
