import dateConstants from '@constants/date';
import { DateType, IDateRange } from '@typings/date';
import DateUtils from '@utils/date';
import { Dayjs } from 'dayjs';

const useDateModal = () => {
    const { DATE_FORMAT } = dateConstants;

    const api = {
        // Проверка интервала
        checkInterval: (dates: IDateRange, newDateStart: Dayjs, newDateEnd: Dayjs): boolean => {
            const { dateStart, dateEnd } = dates;

            return newDateStart.format(DATE_FORMAT) === dateStart && newDateEnd.format(DATE_FORMAT) === dateEnd;
        },

        // Валидация диапозона с учетом минимальной и максимальной доступных для выбора дат
        validationDateRange: (dates: IDateRange, minDate: DateType, maxDate: DateType): boolean => {
            const isValidStart = !DateUtils.checkIsDateExcludedRange(dates.dateStart, { minDate, maxDate });
            const isValidEnd = !DateUtils.checkIsDateExcludedRange(dates.dateEnd, { minDate, maxDate });

            return isValidStart && isValidEnd;
        },

        // Получение дат для календаря
        getCheckedDatesForCalendar: (dates: IDateRange): (Date | null)[] => {
            const newDateRange = DateUtils.validAndRevertInterval([dates.dateStart, dates.dateEnd]);
            const [dateStart, dateEnd] = newDateRange;
            const start = DateUtils.getDate(dateStart).toDate();
            const end = DateUtils.getDate(dateEnd).toDate();

            // Если даты начала и конца равны,
            // Возвращаем дату начала и null
            // if (+start === +end) end = null;

            return [start, end];
        },
    };

    return { api };
};

export default useDateModal;
