import { ContextAlertMark, Radio } from '@adtech/ui';
import fieldDecorator from '@decorators/fieldDecorator';
import { IField, IMetaField } from '@typings/form';
import { formUtils } from '@utils/index';
import React from 'react';
import Wrapper from '../Wrapper';
import s from './DisplayStatistics.pcss';

interface IProps {
    meta: IMetaField;
    field: IField;
}

export const DisplayStatisticsComponent = ({ meta, field }: IProps) => {
    const items = [
        {
            title: 'Доступно всем',
            value: 'opened',
        },
        {
            title: 'Закрытая',
            value: 'closed',
        },
    ];

    const renderRadio = (item, index) => (
        <Radio
            name="publicStats"
            value={item.value}
            onChange={field.onChange}
            checked={field.value === item.value}
            key={index}
        >
            {item.title}
        </Radio>
    );

    return (
        <Wrapper
            withoutDelimiter
            subWrap
            title="Отображение статистики"
            // eslint-disable-next-line max-len
            description="При открытой статистике любой посетитель Статистики от SberAds сможет увидеть динамику посещаемости вашего сайта. При закрытой — только динамику популярности."
            smallTitle
            fixPadding
        >
            <div className={s.radioGroup}>{items.map(renderRadio)}</div>
            {meta.invalid && meta.touched && <ContextAlertMark status="error" message={meta.errorText || ''} />}
        </Wrapper>
    );
};

export const DisplayStatistics = fieldDecorator({
    rules: [
        {
            validate: formUtils.defaultRules.required,
            customErrorText: 'Необходино выбрать один из пунктов',
        },
    ],
    name: 'publicStats',
})(DisplayStatisticsComponent);
