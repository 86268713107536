export default {
    'Рекламные системы': 'adv',
    'Новостные агрегаторы': 'aggrnews_system',
    'Почтовые рассылки': 'email',
    'Внутренние переходы': 'internal',
    'Сохраненные страницы': 'local_page',
    'Рекомендательные системы': 'recsystems',
    'Поисковые системы': 'search',
    'По ссылкам на сайтах': 'site',
    'Социальные сети': 'social',
    'Прямые заходы': 'straight',
    'Яндекс.Дзен': 'zen',
    'Обменные сети': 'exchange',
    'Мессенджеры': 'messenger',
    'QR-системы': 'qr',
    'Мобильные приложения': 'native_mobile',
    'Женщины': 'female',
    'Мужчины': 'male',
    'Не определено': 'other',
    'Не определен': 'other',
};
