import { ReportSearch } from '@components/index';
import attributesData from '@configs/attributesData';
import { ReportName } from '@typings/reports';
import React from 'react';

import s from '../../ReportTable.pcss';

interface IProps {
    titleFilter: string;
    tableRequest: boolean;
    onFilter: (value: string) => void;
    reportName: ReportName;
}

const TableTitleFilter: React.FC<IProps> = (props) => {
    const { titleFilter, onFilter, reportName, tableRequest } = props;

    const handleClear = () => {
        onFilter('');
    };

    const handleSearch = (value: string) => {
        const searchValue = value.trim();

        if (tableRequest && !searchValue) return;

        onFilter(searchValue);
    };

    const newreportAttr = attributesData.newreport.attrName;
    const tableAttr = attributesData.newreport.table;

    return (
        <ReportSearch
            className={s.tableFiltersSearch}
            classNameRoot={s.tableFiltersSearchInput}
            customSearch
            onChange={handleSearch}
            onClear={handleClear}
            searchWord
            value={titleFilter}
            dataAttrName={newreportAttr}
            dataAttr={[tableAttr.attrName, tableAttr.tableSearch, reportName]}
        />
    );
};

export default TableTitleFilter;
