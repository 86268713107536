import { createAsyncThunk } from '@reduxjs/toolkit';
import { Dimensions } from '@typings/dimensions';
import { IMediaRequestParams } from '@typings/media';
import { Metrics } from '@typings/metrics';
import fetchApi from '@utils/fetchApi';

/**
 * Экшен получения данных для Ключевых метрик
 */
export const getTotals = createAsyncThunk(
    'mediaSlice/getTotals',
    async ({
        projectId,
        params,
        body,
    }: {
        projectId: string;
        params: IMediaRequestParams<Metrics>;
        body: Record<string, string>;
    }) =>
        // eslint-disable-next-line no-return-await
        await fetchApi({
            url: `/api/report/v1.0/project/media/${projectId}/totals`,
            method: 'POST',
            body: params,
            metaInfo: { body },
        }),
);

/**
 * Экшен получения данных для Источников Трафика
 */
export const getTrafficSources = createAsyncThunk(
    'mediaSlice/getTrafficSources',
    async ({ projectId, params }: { projectId: string; params: IMediaRequestParams<Metrics> }) =>
        // eslint-disable-next-line no-return-await
        await fetchApi({
            url: `/api/report/v1.0/project/media/${projectId}/traffic_sources`,
            method: 'POST',
            body: params,
            metaInfo: {
                body: params,
                ...params,
            },
        }),
);

export const getAudience = createAsyncThunk(
    'mediaSlice/getAudience',
    async ({ projectId, params }: { projectId: string; params: IMediaRequestParams<Dimensions> }) =>
        // eslint-disable-next-line no-return-await
        await fetchApi({
            url: `/api/report/v1.0/project/media/${projectId}/audience`,
            method: 'POST',
            body: params,
            metaInfo: {
                body: params,
                ...params,
            },
        }),
);

/**
 * Экшен получения данных для виджета Дочтений/Доскроллов
 */
export const getFullReadScroll = createAsyncThunk(
    'mediaSlice/getFullReadScroll',
    async ({ projectId, params }: { projectId: string; params: IMediaRequestParams<Dimensions> }) =>
        // eslint-disable-next-line no-return-await
        await fetchApi({
            url: `/api/report/v1.0/project/media/${projectId}/reading_metrics`,
            method: 'POST',
            body: params,
            metaInfo: {
                body: params,
                ...params,
            },
        }),
);

/**
 * Экшен получения данных для виджета Источников переходов
 */
export const getRecirculationSources = createAsyncThunk(
    'mediaSlice/getRecirculationSources',
    async ({ projectId, params }: { projectId: string; params: IMediaRequestParams<Metrics> }) =>
        // eslint-disable-next-line no-return-await
        await fetchApi({
            url: `/api/report/v1.0/project/media/${projectId}/recirculation_sources`,
            method: 'POST',
            body: params,
            metaInfo: {
                body: params,
                ...params,
            },
        }),
);

/**
 * Экшен получения данных для информации о материале
 */
export const getMaterialInfo = createAsyncThunk(
    'mediaSlice/getMaterialInfo',
    async ({
        projectId,
        params,
    }: {
        projectId: string;
        params: any; // FIXME
    }) =>
        // eslint-disable-next-line no-return-await
        await fetchApi({
            url: `/api/report/v1.0/project/media/${projectId}/info`,
            method: 'POST',
            body: params,
            metaInfo: {
                body: params,
            },
        }),
);

/**
 * Экшен получения списка авторов
 */
export const getAuthors = createAsyncThunk(
    'mediaSlice/getAuthors',
    async ({ projectId, params }: { projectId: number | string; params: IMediaRequestParams<Dimensions> }) =>
        // eslint-disable-next-line no-return-await
        await fetchApi({
            url: `/api/report/v1.0/project/media/${projectId}/authors`,
            method: 'POST',
            body: params,
            metaInfo: {
                body: params,
            },
        }),
);
