import { Button, IconEraser } from '@adtech/ui';
import attributesData from '@configs/attributesData';
import { ReportName } from '@typings/reports';
import cn from 'classnames';
import React, { MouseEvent, useRef, useEffect } from 'react';

import s from './ClearFilters.pcss';

interface IProps {
    onClearFilters: () => void;
    disabled: boolean;
    reportName: ReportName;
}

const ClearFilters: React.FC<IProps> = ({ onClearFilters, disabled = true, reportName }) => {
    const hidden = useRef(true);
    const dataAttrName = attributesData.filters.attrName;
    const dataAttr = attributesData.filters.resetFilter;

    useEffect(() => {
        // При первой загрузке не дизейблим, а скрываем кнопку, если нет примененных фильтров
        if (!hidden?.current) return;
        if (!disabled) hidden.current = false;
    }, [disabled]);

    const handleClick = (e: MouseEvent) => {
        e.preventDefault();
        onClearFilters();
    };

    return (
        <Button
            onClick={handleClick}
            disabled={disabled}
            prefixIcon={<IconEraser />}
            type="link"
            {...(dataAttrName && dataAttr ? { [`data-${dataAttrName}`]: `${dataAttr}::${reportName}` } : {})}
            data-testid="clearFilter-button"
            className={cn(s.button, { [s.hidden]: hidden.current })}
        >
            Сбросить
        </Button>
    );
};

export default ClearFilters;
