import serverErrorsConstants from '@constants/serverErrors';
import snackbarsConstants from '@constants/snackbars';
import { formDecorator } from '@decorators/index';
import { globalActions } from '@redux/slices/global';
import { projectsActions } from '@redux/slices/projects';
import { listDataUtils } from '@utils/index';
import { RatingFormComponent } from './RatingForm';

const mapStateToProps = (state) => ({
    currentProject: state.projectsSlice.current,
    saveCounterRequest: state.projectsSlice.saveCounterRequest,
    config: state.globalSlice.projectConfig,
});

const mapDispatchToProps = (dispatch) => ({
    saveCounter: (id: number, data: Record<string, any>) => dispatch(projectsActions.save({ id, data })),
    onSubmit(values, invalid, saveCounter, currentProject, setServerError, config) {
        if (invalid) {
            return;
        }

        const categories = values.categories
            ? listDataUtils.getThemeIdsFromCategories(values.categories, config.categories.data)
            : [];

        const data = {
            categories,
            regions: values.regions ? values.regions : [],
            types: values.types ? values.types : [],
            in_rating: values.inRating,
            keywords: values.keywords,
            public_stats: values.publicStats === 'opened',
            title: values.title,
        };

        saveCounter(currentProject.id, data).then(({ payload }) => {
            if (payload.responseStatus === 200) {
                dispatch(globalActions.setSnackbar(snackbarsConstants.SAVE_CHANGES));
                window.scrollTo(0, 0);
            } else if (payload.errors && payload.errors.message) {
                if (payload.errors.message === serverErrorsConstants.REQUEST_API_ERROR) {
                    setServerError('Произошла ошибка при запросе на сервер.');
                }
            }
        });
    },
});

export const RatingForm = formDecorator({
    formName: 'ratingSettings',
    isRemoveForm: false,
    initializeValues(state) {
        const currentProject = state.projectsSlice.current;

        return {
            title: currentProject.title,
            inRating: currentProject.in_rating,
            keywords: currentProject.keywords,
            publicStats: currentProject.publicStats ? 'opened' : 'closed',
        };
    },
    asyncInitializeValues: [
        {
            should: ({ globalSlice }) => globalSlice.projectConfig.categories.loaded,
            initializeValues: (state) => {
                const projectConfig = state.globalSlice.projectConfig;
                const currentProject = state.projectsSlice.current;

                let categories = listDataUtils.matchingConfigItems(currentProject.categories, projectConfig.categories);

                categories = listDataUtils.getThemeIdsFromCategories(categories, projectConfig.categories.data);

                categories = listDataUtils.getSimpleCategories(categories, projectConfig.categories.data);

                return {
                    categories,
                };
            },
        },
        {
            should: ({ globalSlice }) => globalSlice.projectConfig.regions.loaded,
            initializeValues: (state) => {
                const projectConfig = state.globalSlice.projectConfig;
                const currentProject = state.projectsSlice.current;
                const regions = listDataUtils.matchingConfigItems(currentProject.regions, projectConfig.regions);

                return {
                    regions,
                };
            },
        },
        {
            should: ({ globalSlice }) => globalSlice.projectConfig.types.loaded,
            initializeValues: (state) => {
                const projectConfig = state.globalSlice.projectConfig;
                const currentProject = state.projectsSlice.current;
                const types = listDataUtils.matchingConfigItems(currentProject.types, projectConfig.types);

                return {
                    types,
                };
            },
        },
    ],
    mapStateToProps,
    mapDispatchToProps,
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
})(RatingFormComponent);
