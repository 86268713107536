import { Button, ContextAlertMark, Accordion, AccordionItem, IconChevronUp } from '@adtech/ui';
import CounterBlocks from '@components/CounterBlocks';
import Form from '@components/Form';
import inputConstants from '@constants/input';
import notifications from '@constants/notifications';
import { IWrappedComponentProps } from '@decorators/formDecorator/types';
import IconTrash from '@images/svg/icons/icon-trash.svg';
import { INotification } from '@typings/notification';
import { IProject } from '@typings/projects';
import React, { useEffect } from 'react';
import { Link, useNavigate } from 'react-router-dom';

import s from '../SettingsCounter.pcss';

interface ICurrent extends Omit<IProject, 'id'> {
    id: string;
}

interface IProps extends IWrappedComponentProps {
    saveCounterRequest: boolean;
    onSubmit;
    removeCounter;
    currentProject: ICurrent;
    values: Record<string, any>;
    invalid: boolean;
    serverError: string;
    setSnackbar;
    setNotification: (notification: INotification) => void;
    clearNotification;
    openModalPopup;
}

export const CounterFormComponent: React.FC<IProps> = ({
    saveCounterRequest,
    onSubmit,
    removeCounter,
    currentProject,
    values,
    invalid,
    serverError,
    setSnackbar,
    setNotification,
    clearNotification,
    openModalPopup,
    setServerError,
    initializeValues,
}) => {
    const navigate = useNavigate();

    const showPopupNotification = () => setNotification(notifications.settingsCounter);

    useEffect(() => {
        let timer;
        if (typeof setNotification === 'function') {
            timer = setTimeout(() => {
                showPopupNotification();
            }, 4000);
        }
        return () => clearTimeout(timer);
    }, []);

    const handleApplyRemoveCounter = () => {
        removeCounter(currentProject.id).then(() => {
            navigate('/stat/projects');
        });
    };

    const handleOpenPopup = () => {
        openModalPopup('RemoveCounter', {
            okButtonHandler: handleApplyRemoveCounter,
        });
    };

    const saveSettingsCounter = () => {
        onSubmit(values, invalid, currentProject, setServerError, initializeValues);
    };

    const messageTitle = (
        <span className={s.alertMessage}>
            Отправить заявку на изменение названия сайта можно на вкладке&nbsp;
            <Link to={`/stat/projects/${currentProject.id}/settings/rating`}>«Рейтинг»</Link>.
        </span>
    );

    const messageUrl = (
        <span className={s.alertMessage}>
            Для изменения адреса сайта, участвующего в рейтинге, обратитесь в&nbsp;
            <a href="mailto:counter@ads.sber.ru" target="_blank" rel="noopener noreferrer">
                службу поддержки Статистики от SberAds
            </a>
            .
        </span>
    );

    const handleChangeAccordion = (key) => {
        if (key) {
            showPopupNotification();
        } else {
            clearNotification();
        }
    };

    return (
        <Form onSubmit={saveSettingsCounter}>
            <div key="info_counter" className={s.block}>
                <div className={s.blockTitle}>Вводные данные</div>
                <CounterBlocks.InputData
                    disabledUrl
                    disabledTitle={currentProject.in_rating}
                    isShowMaxLength={false}
                    maxTitleLength={inputConstants.MAX_TITLE_LENGTH}
                    messageUrl={messageUrl}
                    messageTitle={currentProject.in_rating ? messageTitle : ''}
                />
            </div>
            <Accordion
                expandIconPosition="end"
                expandIcon={(panelProps) => <IconChevronUp isActive={panelProps.isActive} fill="currentColor" />}
                defaultActiveKey={['settings_counter']}
                onChange={handleChangeAccordion}
            >
                <AccordionItem key="settings_counter" header="Настройки счётчика">
                    <>
                        <CounterBlocks.SettingsCounter counterType={values.counterType || false} />
                        <CounterBlocks.SettingsDomain useDomainBlacklist={values.useDomainBlacklist} />
                        <CounterBlocks.SettingsOther />
                    </>
                </AccordionItem>
            </Accordion>
            <div key="code_counter" className={s.block}>
                <div className={s.blockTitle}>Код счётчика</div>
                <CounterBlocks.CounterCode
                    id={currentProject.id}
                    counterType={Number(values.counterType)}
                    blockAnalyticsAttribute={values.blockAnalyticsAttribute}
                    useBlockAnalytics={values.useBlockAnalytics}
                    useHashes={values.useHashes}
                    useUserId={values.useUserId}
                    setSnackbar={setSnackbar}
                />
            </div>
            <div className={s.actions}>
                <Button loading={saveCounterRequest} disabled={invalid || saveCounterRequest} htmlType="submit">
                    Сохранить изменения
                </Button>
                <div className={s.removeCounter} onClick={handleOpenPopup}>
                    <IconTrash className={s.removeCounterIcon} />
                    <span>Удалить счётчик</span>
                </div>
            </div>
            {serverError && <ContextAlertMark message={serverError} status="error" />}
        </Form>
    );
};
