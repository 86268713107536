import { widgetsMediaConfig } from '@configs/media/widgets';
import { metricsDict } from '@configs/metrics';
import { graphConstants } from '@constants/graph';
import {
    getTotals,
    getTrafficSources,
    getAudience,
    getFullReadScroll,
    getRecirculationSources,
    getMaterialInfo,
    getAuthors,
} from '@redux/slices/media/api';
import { createSlice } from '@reduxjs/toolkit';
import { IMediaSlice } from '@typings/rootSlice';
import { measuresUtils, numbersUtils } from '@utils/index';

export const initialState: IMediaSlice = {
    reportName: null,
    totalsRequest: false,
    totals: {},
    isAutoUpdate: true,
    trafficSources: {
        allMetrics: [],
        metrics: [],
        selected: null,
        loading: false,
        data: [],
        dataTotals: [],
        graphType: null,
    },
    audience: {
        allDimensions: [],
        dimensions: [],
        selected: null,
        loading: false,
        data: [],
        dataTotals: [],
        graphType: null,
    },
    fullReadScrollMedia: {
        allDimensions: [],
        dimensions: [],
        selected: null,
        loading: false,
        data: [],
    },
    recirculationSourcesMedia: {
        allMetrics: [],
        dimensions: [],
        metrics: [],
        selected: null,
        loading: false,
        data: [],
        dataTotals: null,
        labels: [],
        sort: { name: null, order: null },
    },
    materialInfo: {
        title: null,
        loading: false,
    },
    authorsRequest: false,
    authorInfo: {
        name: null,
        id: null,
    },
    authors: {
        data: [],
        dataTotals: null,
    },
    errorCode: null,
};

export const setRequestTotals = (state: IMediaSlice) => {
    state.totalsRequest = true;
};

export const failureTotals = (state: IMediaSlice) => {
    state.totalsRequest = false;
};

export const updateTotalsFunc = (state: IMediaSlice, action) => {
    const { reportName, data } = action.payload;
    const newData = { ...data.data };
    delete newData.status;

    state.reportName = reportName;
    state.totals = newData;
    state.totalsRequest = false;
};

export const setTrafficSourcesRequest = (state: IMediaSlice) => {
    state.trafficSources.loading = true;
};

export const failureTrafficSources = (state: IMediaSlice) => {
    state.trafficSources = {
        ...initialState.trafficSources, // чтобы при ошибке запроса данных показывать заглушку
        loading: false,
        reqError: true,
    };
};

export const updateTrafficSourcesFunc = (state: IMediaSlice, action) => {
    const { payload } = action;
    const allMetrics = payload.available_metrics;
    const metrics = payload.metrics || state.trafficSources.metrics;
    const selected = payload.selected || metrics[0];
    let graphType = state.trafficSources.graphType;
    const data = [];
    const dataTotals = [];
    const { totals } = payload;

    // Сортируем по убыванию на основе totals данных
    const dataKeys = Object.keys(totals).sort((a, b) => totals[b] - totals[a]);
    const metricFormatterValue = metricsDict[selected]?.formatterValue || 'number';

    dataKeys.forEach((key) => {
        // удаляем "неопределенный" срез источников
        if (key === graphConstants.EMPTY_GRAPH_DATA_VALUE) return;
        const itemLabel = payload.labels[key];
        const itemData = payload.data[key];
        let itemDataTotal = totals[key];

        if (metricFormatterValue === 'percent') {
            itemDataTotal = numbersUtils.convertPercent(itemDataTotal);
        }

        // line, area данные
        data.push({
            title: itemLabel,
            data: itemData.map((item) => {
                let value = item;
                if (metricFormatterValue === 'percent') value = numbersUtils.convertPercent(item);
                return [itemLabel, value];
            }),
        });
        // bar, pie данные
        dataTotals.push([itemLabel, itemDataTotal]);
    });

    const availableGraphs = measuresUtils.getAvailableGraphs(selected);
    if (!availableGraphs.includes(graphType)) {
        graphType = availableGraphs[0];
    }

    state.trafficSources = {
        allMetrics,
        metrics,
        selected,
        data,
        dataTotals,
        dataKeys,
        graphType,
        loading: false,
    };
};

export const setAudienceRequest = (state: IMediaSlice) => {
    state.audience.loading = true;
};

export const failureAudience = (state: IMediaSlice) => {
    state.audience = {
        ...initialState.audience,
        loading: false,
        reqError: true,
    };
};

export const updateAudienceFunc = (state: IMediaSlice, action) => {
    const { payload } = action;
    const allDimensions = payload.available_dimensions;
    const dimensions = payload.dimensions || state.audience.dimensions;
    const selected = payload.selected || dimensions[0];
    let graphType = state.audience.graphType;
    const data = [];
    const dataTotals = [];

    // сортировка лейблов для возраста по возрастанию
    const sortedData = selected === 'age_tns' ? [...Object.keys(payload.data)].sort() : Object.keys(payload.data);

    sortedData.forEach((key) => {
        // удаляем "неопределенный" срез аудитории
        if (key === graphConstants.EMPTY_GRAPH_DATA_VALUE) return;
        const itemLabel = payload.labels[key];
        const itemData = payload.data[key];
        const itemDataTotal = payload.totals[key];
        // line, area данные
        data.push({
            title: itemLabel,
            data: itemData.map((item) => [itemLabel, item]),
        });
        // bar, pie данные
        dataTotals.push([itemLabel, itemDataTotal]);
    });

    const availableGraphs = measuresUtils.getAvailableGraphs(selected);
    if (!availableGraphs.includes(graphType)) {
        graphType = availableGraphs[0];
    }

    state.audience = {
        allDimensions,
        dimensions,
        selected,
        data,
        dataTotals,
        graphType,
        loading: false,
    };
};

export const setFullReadScrollRequest = (state: IMediaSlice) => {
    state.fullReadScrollMedia.loading = true;
};

export const failureFullReadScroll = (state: IMediaSlice) => {
    state.fullReadScrollMedia = {
        ...initialState.fullReadScrollMedia,
        loading: false,
        reqError: true,
    };
};

export const updateFullReadScrollFunc = (state: IMediaSlice, action) => {
    const { payload } = action;
    const allDimensions = payload.available_dimensions;
    const dimensions = payload.dimensions || state.fullReadScrollMedia.dimensions;
    const selected = payload.selected || dimensions[0];
    const { totals } = payload;
    const data = [];

    if (totals === 0) {
        return {
            ...state,
            fullReadScrollMedia: {
                ...initialState.fullReadScrollMedia,
                selected,
                loading: false,
            },
        };
    }

    Object.keys(payload.data).forEach((key) => {
        const itemLabel = payload.labels[key];
        const itemDataTotal = payload.data[key];
        // bar, pie данные
        data.push([itemLabel, itemDataTotal]);
    });

    state.fullReadScrollMedia = {
        allDimensions,
        dimensions,
        selected,
        data,
        loading: false,
    };
};

export const setRecirculationSourcesRequest = (state: IMediaSlice) => {
    state.recirculationSourcesMedia.loading = true;
};

export const failureRecirculationSources = (state: IMediaSlice) => {
    state.recirculationSourcesMedia = {
        ...initialState.recirculationSourcesMedia,
        loading: false,
        reqError: true,
    };
};

export const updateRecirculationSourcesFunc = (state: IMediaSlice, action) => {
    const { metaInfo: meta, result } = action.payload;
    const allMetrics = meta.available_metrics;
    const dimensions = meta.dimensions || state.recirculationSourcesMedia.dimensions;
    const metrics = [meta.metric] || state.recirculationSourcesMedia.metrics;
    const sort = meta.sorts[0];
    const selected = action.payload.selected || metrics[0];
    const dataTotals = [widgetsMediaConfig.recirculationSources.tableFooterTitle, result.totals];
    const data = [];
    const labels = [];

    result.data.forEach((item) => {
        data.push([item.media_url, item.metric]);
        labels.push(item.media_title);
    });

    state.recirculationSourcesMedia = {
        allMetrics,
        dimensions,
        metrics,
        selected,
        data,
        dataTotals,
        sort,
        labels,
        loading: false,
    };
};

export const updateWidgetParamsFunc = (state: IMediaSlice, action) => {
    const { widgetName, options } = action.payload;

    Object.assign(state[widgetName], options);
};

/**
 * Обновляем параметры в стейте
 * @returns {*}
 */
export const updateRequestParamsFunc = (state: IMediaSlice, action) => {
    Object.assign(state, action.payload);
};

export const setMaterialInfoRequest = (state: IMediaSlice) => {
    state.materialInfo.loading = true;
};

export const updateMaterialInfoFunc = (state: IMediaSlice, action) => {
    const { payload } = action;

    if (!payload.data.media_title) {
        return {
            ...state,
            errorCode: 404,
            materialInfo: {
                ...initialState.materialInfo,
                loading: false,
            },
        };
    }

    const dataList = payload.dimensions.reduce(
        (acc, item) => ({
            ...acc,
            [item]: payload.data[item] || null,
        }),
        {},
    );
    const { media_title, media_url, published_datetime, ...list } = dataList;

    state.materialInfo = {
        title: media_title,
        url: media_url,
        list,
        publishedDateTime: published_datetime,
        loading: false,
    };
};

/**
 * Включаем режим загрузки данных для списка авторов
 */
export const setRequestAuthors = (state: IMediaSlice) => {
    state.authorsRequest = true;
};

export const failureAuthors = (state: IMediaSlice) => {
    state.authorsRequest = false;
};

/**
 * Установка полученных данных по авторам
 */
export const updateAuthorsFunc = (state: IMediaSlice, action) => {
    const { data, isLoadMore } = action.payload;

    // Обработка пришедших данныых по авторам
    const newData = data.result.map((item) => ({
        name: item.dimensions[0], // author_name
        id: item.dimensions[1], // author_id
    }));

    // Если необходима подгрузка, добавляются новые данные,
    // иначе данные заменяются на новые
    const resultsData = isLoadMore ? [...state.authors.data, ...newData] : newData;

    state.authors = {
        data: resultsData,
        dataTotals: data.meta.rows,
    };
    state.authorsRequest = false;
};

/**
 * Установка полученных одному автору
 */
export const updateAuthorInfoFunc = (state: IMediaSlice, action) => {
    const { result } = action.payload;

    state.authorInfo = {
        name: result[0]?.dimensions[0] || null, // author_name
        id: result[0]?.dimensions[1] || null, // author_id
    };
    state.authorsRequest = false;
};

/**
 * Установка параметра, определяющего необходимость в автообновлении
 */
export const setIsAutoUpdateFunc = (state: IMediaSlice, action) => {
    state.isAutoUpdate = action.payload;
};

export const resetParamFunc = (state: IMediaSlice, action) => {
    const param = action.payload;

    if (!(param in initialState)) return state;

    state[param] = initialState[param];
};

const mediaSlice = createSlice({
    name: 'mediaSlice',
    initialState,
    reducers: {
        updateTotals: (state, action) => updateTotalsFunc(state, action),
        updateTrafficSources: (state, action) => updateTrafficSourcesFunc(state, action),
        updateAudience: (state, action) => updateAudienceFunc(state, action),
        updateFullReadScroll: (state, action) => updateFullReadScrollFunc(state, action),
        updateRecirculationSources: (state, action) => updateRecirculationSourcesFunc(state, action),
        updateWidgetParams: (state, action) => updateWidgetParamsFunc(state, action),
        updateRequestParams: (state, action) => updateRequestParamsFunc(state, action),
        updateMaterialInfo: (state, action) => updateMaterialInfoFunc(state, action),
        updateAuthors: (state, action) => updateAuthorsFunc(state, action),
        updateAuthorInfo: (state, action) => updateAuthorInfoFunc(state, action),
        setIsAutoUpdate: (state, action) => setIsAutoUpdateFunc(state, action),
        resetParam: (state, action) => resetParamFunc(state, action),
    },
    extraReducers: (builder) => {
        builder
            .addCase(getTotals.pending, (state) => setRequestTotals(state))
            .addCase(getTotals.rejected, (state) => failureTotals(state))

            .addCase(getTrafficSources.pending, (state) => setTrafficSourcesRequest(state))
            .addCase(getTrafficSources.rejected, (state) => failureTrafficSources(state))

            .addCase(getAudience.pending, (state) => setAudienceRequest(state))
            .addCase(getAudience.rejected, (state) => failureAudience(state))

            .addCase(getFullReadScroll.pending, (state) => setFullReadScrollRequest(state))
            .addCase(getFullReadScroll.rejected, (state) => failureFullReadScroll(state))

            .addCase(getRecirculationSources.pending, (state) => setRecirculationSourcesRequest(state))
            .addCase(getRecirculationSources.rejected, (state) => failureRecirculationSources(state))

            .addCase(getMaterialInfo.pending, (state) => setMaterialInfoRequest(state))

            .addCase(getAuthors.pending, (state) => setRequestAuthors(state))
            .addCase(getAuthors.rejected, (state) => failureAuthors(state));
    },
});

export const {
    updateTotals,
    updateTrafficSources,
    updateAudience,
    updateFullReadScroll,
    updateRecirculationSources,
    updateWidgetParams,
    updateRequestParams,
    updateMaterialInfo,
    updateAuthors,
    updateAuthorInfo,
    setIsAutoUpdate,
    resetParam,
} = mediaSlice.actions;

export const mediaActions = {
    updateTotals,
    updateTrafficSources,
    updateAudience,
    updateFullReadScroll,
    updateRecirculationSources,
    updateWidgetParams,
    updateRequestParams,
    updateMaterialInfo,
    updateAuthors,
    updateAuthorInfo,
    setIsAutoUpdate,
    resetParam,
    getTotals,
    getTrafficSources,
    getAudience,
    getFullReadScroll,
    getRecirculationSources,
    getMaterialInfo,
    getAuthors,
};

export default mediaSlice.reducer;
