export interface ISettingsMenuItem {
    title: string;
    id: string;
    to?: string;
}

export const settingsMenuConfig: ISettingsMenuItem[] = [
    {
        title: 'Код счётчика',
        id: 'counterCode',
        to: 'counter#counter-code',
    },
    {
        title: 'Настройки счётчика',
        id: 'counter',
        to: 'counter',
    },
    {
        title: 'Настройки рейтинга',
        id: 'rating',
        to: 'rating',
    },
    {
        title: 'Доступы',
        id: 'access',
        to: 'access',
    },
    {
        title: 'Удалить счётчик',
        id: 'delete',
    },
];
