/* eslint-disable max-len */
export default {
    settingsCounter: {
        showClose: true,
        body: 'Если появятся вопросы, посмотрите наше руководство по настройке счётчика',
        actionButton: 'Посмотреть',
        onAction: () => {
            window.open('https://ads.sber.ru/help/stat', '_blank');
        },
    },
    closeAllHints: {
        showClose: true,
        body: 'Все подсказки скрыты. Подробная информация о работе с отчётом доступна в разделе «Помощь»',
        actionButton: 'Посмотреть',
        onAction: () => {
            window.open('https://ads.sber.ru/help/prakticheskoe-rukovodstvo-po-analitike-blokov', '_blank');
        },
    },
};
