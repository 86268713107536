export default {
    counterMessages: {
        DELETE_PROJECT_COUNTER: 'Счётчик удален',
        RESTORE_PROJECT_COUNTER: 'Счётчик восстановлен',
    },
    COPY_CODE: 'Код скопирован',
    SAVE_CHANGES: 'Изменения сохранены',
    ACCSSES_GRANTED: 'Доступ выдан. Ссылка для активации доступа отправлена на указанный адрес',
    ACCSSES_CHANGED: 'Права доступа изменены',
    filtersMessages: {
        SAVE_FILTER: 'Создан новый шаблон',
        UPDATE_FILTER: 'Шаблон обновлен',
        DELETE_FILTER: 'Шаблон удалён',
    },
};
