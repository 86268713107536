import { MainShape } from '@adtech/ui';
import useUser from '@hooks/useUser';
import { LogoSBAStat } from '@images/svg/logo-sba-stat';
import { IRootSlice } from '@typings/rootSlice';
import cn from 'classnames';
import React from 'react';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import s from './Header.pcss';

const Header: React.FC = () => {
    const email = useSelector((state: IRootSlice) => state.userSlice.email) || '';
    const userId = useSelector((state: IRootSlice) => state.userSlice.id);
    const userName = useSelector((state: IRootSlice) => state.userSlice.userName);

    const { hostname } = window.location;
    let linkToProfile = 'https://ads.sber.ru/td/profile/';

    const { logout } = useUser();

    if (hostname.includes('ift') || hostname === 'localhost') {
        linkToProfile = 'https://perf-td-frontend.ift-mesh.ad-tech.ru/profile/';
    }

    const renderLogo = (
        <Link to="/stat/projects" className={s.logo}>
            <LogoSBAStat />
        </Link>
    );

    const onLogout = () => {
        if (userId === null) {
            window.location.href = '/';
            return;
        }

        logout();
    };

    const onSettingsClick = () => window.open(linkToProfile, '_self');

    return (
        <div className={cn(s.root, { [s.notAuthorized]: !email })}>
            <MainShape
                renderLogo={renderLogo}
                onLogout={onLogout}
                menuProps={{
                    text: userName || email,
                    activeKey: 1,
                    onSettingsClick,
                    onLogOut: onLogout,
                }}
            />
        </div>
    );
};

export default Header;
