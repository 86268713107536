import { Radio } from '@adtech/ui';
import fieldDecorator from '@decorators/fieldDecorator';
import { IField } from '@typings/form';
import React, { FC } from 'react';

import s from '../AccessUsers.pcss';

interface IProps {
    field: IField;
    disabled: boolean;
}

export const RadioFieldComponent: FC<IProps> = ({ field, disabled = false }: IProps) => {
    const { value, onChange } = field;
    const items = [
        {
            title: 'Только просмотр',
            value: 'view',
        },
        {
            title: 'Редактирование',
            value: 'edit',
        },
    ];

    const renderRadio = (item, index) => (
        <Radio
            name="accessLevel"
            value={item.value}
            disabled={disabled}
            onChange={onChange}
            checked={value === item.value}
            key={index}
        >
            {item.title}
        </Radio>
    );

    return <div className={s.radioGroup}>{items.map(renderRadio)}</div>;
};

export default fieldDecorator({
    name: 'accessLevel',
})(RadioFieldComponent);
