import { Button } from '@adtech/ui';
import { ReportName } from '@typings/reports';
import * as React from 'react';

import s from './Dropdown.pcss';

interface IProps {
    reportName: ReportName;
    onClose?: () => void;
    onApply?: (cb: () => void, isOnlyState?: boolean, filterName?: string) => void;
    dataAttrName?: string;
    applyBtnAttr?: string;
    cancelBtnAttr?: string;
}

export default class Actions extends React.PureComponent<IProps> {
    handleApply = () => {
        const { onApply, onClose } = this.props;

        onApply(onClose);
    };

    handleClose = () => {
        const { onClose } = this.props;

        onClose();
    };

    render() {
        const { reportName, dataAttrName, cancelBtnAttr, applyBtnAttr } = this.props;

        return (
            <div className={s.actions}>
                <Button
                    className={s.actionsButton}
                    onClick={this.handleApply}
                    {...(dataAttrName && applyBtnAttr
                        ? { [`data-${dataAttrName}`]: `${applyBtnAttr}::${reportName}` }
                        : {})}
                >
                    Применить
                </Button>
                <Button
                    type="dashed"
                    className={s.actionsButton}
                    onClick={this.handleClose}
                    {...(dataAttrName && cancelBtnAttr
                        ? { [`data-${dataAttrName}`]: `${cancelBtnAttr}::${reportName}` }
                        : {})}
                >
                    Отмена
                </Button>
            </div>
        );
    }
}
