import { Table, Loader, Pagination } from '@adtech/ui';
import { EntityAccess } from '@configs/entity';
import React, { FC, useState } from 'react';
import s from '../../PostClick.pcss';

interface IProps {
    tableData;
    totalGoals: number;
    isLoading: boolean;
    updateSelectedGoals: (listIds: number[]) => void;
    selected: number[];
}

const GoalsTable: FC<IProps> = ({ tableData, totalGoals, isLoading = false, updateSelectedGoals, selected = [] }) => {
    const [currentPage, setCurrentPage] = useState(1);
    const [limit, setLimit] = useState(10);

    const tableColumns = [
        {
            title: 'Название цели',
            dataIndex: 'title',
            render: (dataTitle) => <span className={s.tableCellTitle}>{dataTitle.text}</span>,
        },
        {
            title: 'Тип',
            dataIndex: 'access',
        },
        {
            title: 'ID цели в Яндекс Метрике',
            dataIndex: 'yandex_goal_name',
        },
        {
            title: (
                <span>
                    Номер цели
                    <br /> в Яндекс Метрике
                </span>
            ),
            dataIndex: 'id',
        },
    ];

    const prepareTableData = () => {
        const paginatedData = tableData.slice((currentPage - 1) * limit, currentPage * limit);
        const result = [];
        paginatedData.forEach((item) => {
            if (item.access === 'common') {
                result.push({
                    key: item.id,
                    title: { text: item.title, isShowIcon: !!item.yandex_id },
                    access: item.access === EntityAccess.common ? 'Публичная' : 'Личная',
                    yandex_goal_name: item.yandex_goal_name || '-',
                    id: item.yandex_id || '-',
                });
            }
        });
        return result;
    };

    const goalsTableRowSelection = {
        selectedRowKeys: selected,
        onChange: (selectedRowKeys) => updateSelectedGoals(selectedRowKeys),
    };

    const handleRowClick = (record: any) => {
        const isSelected = selected.includes(record.key);
        const updatedSelectedRowKeys = isSelected
            ? selected.filter((key) => key !== record.key)
            : [...selected, record.key];

        updateSelectedGoals(updatedSelectedRowKeys);
    };

    const onChangePagination = (page: number, pageSize?: number) => {
        setCurrentPage(page);
        setLimit(pageSize);
    };

    return (
        <>
            <div className={s.direct}>
                <div className={s.directText}>Выберите цели, которые хотите передавать в Яндекс Директ</div>
            </div>
            {tableData && (
                <Loader data-testid="loader" loading={isLoading}>
                    <Table
                        data-testid="table"
                        pagination={false}
                        className={s.table}
                        columns={tableColumns}
                        dataSource={prepareTableData()}
                        rowSelection={{
                            type: 'checkbox',
                            ...goalsTableRowSelection,
                        }}
                        onRow={(record) => ({
                            onClick: () => handleRowClick(record),
                        })}
                    />
                    {totalGoals > limit && (
                        <div className={s.pagination}>
                            <Pagination
                                total={totalGoals}
                                defaultPageSize={limit}
                                current={currentPage}
                                onChange={onChangePagination}
                                showSizeChanger
                            />
                        </div>
                    )}
                </Loader>
            )}
        </>
    );
};

export default GoalsTable;
