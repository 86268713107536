import { reduxAsyncConstants as constants } from '@constants/reduxAsync';

class ConstantsUtils {
    /**
     * Создаем объект с константами и префиксом экшена
     */
    createApiConstants = (actionName): Record<string, any> => {
        const result: Record<string, string> = {};

        Object.values(constants).forEach((item: string) => {
            result[item] = `${item}_${actionName}`;
        });

        return result;
    };

    /**
     * Массив из дефолтных констант для запросов
     */
    apiConstantToRequest = (constant) => [
        constant[constants.REQUEST],
        constant[constants.RECEIVE],
        constant[constants.FAILURE],
    ];
}

export default new ConstantsUtils();
