import { IItemSingle } from '@typings/dropdown';
import cn from 'classnames';
import React from 'react';
import s from '../Dropdown.pcss';
import { List } from '../components/List';

interface IProps {
    dataInitialSelected: string;
    data: IItemSingle[];
    onApply: (dataSelected: string, isChanged?: boolean) => void;
    dataAttrName?: string;
    dataAttrList?: string[];
}

export const SelectSingle: React.FC<IProps> = ({
    onApply,
    data,
    dataInitialSelected,
    dataAttrName = '',
    dataAttrList = [],
}) => {
    let checkedItem = dataInitialSelected;

    const handleClick = (e) => {
        const { name, checked, disabled } = e.target.dataset;

        if (checked || disabled) return;

        checkedItem = name;
        const isChanged = checkedItem !== dataInitialSelected;
        onApply(checkedItem, isChanged);
    };

    const renderItem = (item) => {
        const { value, title } = item;
        const isChecked = checkedItem === value ? 'checked' : '';
        const isDisabled = item?.disabled && item?.disabled === true ? 'disabled' : '';
        const itemClassName = cn(s.item, { [s.selected]: isChecked, [s.disabled]: isDisabled });

        return (
            <span
                key={value}
                data-name={value}
                data-checked={isChecked}
                data-disabled={isDisabled}
                className={itemClassName}
                onClick={handleClick}
            >
                {title}
            </span>
        );
    };

    return <List data={data} renderItem={renderItem} dataAttrName={dataAttrName} dataAttrList={dataAttrList} />;
};

export default SelectSingle;
