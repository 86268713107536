import React from 'react';

export const IconLessOrEq = () => (
    <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
        <g id="icon less_or_equal 20*20">
            <g id="Union">
                <path
                    d="M13.8415 3.45544C13.5406 2.99227 12.9213 2.86065 12.4581 3.16147L6.546 7.00123C5.8167 7.47488 5.81766 8.54279 6.54779 9.01514L12.4596 12.8397C12.9233 13.1397 13.5424 13.007 13.8424 12.5433C14.1424 12.0796 14.0097 11.4605 13.546 11.1605L8.67039 8.00629L13.5475 4.83876C14.0107 4.53795 14.1423 3.91861 13.8415 3.45544Z"
                    fill="#83868D"
                />
                <path
                    d="M14 16C14 15.4477 13.5523 15 13 15H7C6.44772 15 6 15.4477 6 16C6 16.5523 6.44772 17 7 17H13C13.5523 17 14 16.5523 14 16Z"
                    fill="#83868D"
                />
            </g>
        </g>
    </svg>
);
