import snackbarTypes from '@constants/snackbarTypes';
import { useAppDispatch } from '@hooks/useStore';
import { globalActions } from '@redux/slices/global';

const useCopy = () => {
    const dispatch = useAppDispatch();

    const copy = (content: number | string, message: string, type: string = snackbarTypes.WARNING): void => {
        const contentString = String(content);

        if (navigator.clipboard) {
            navigator.clipboard.writeText(contentString);
        } else {
            const tempInput = document.createElement('input');
            tempInput.value = contentString;
            document.body.appendChild(tempInput);
            tempInput.select();
            document.execCommand('copy');
            document.body.removeChild(tempInput);
        }

        dispatch(globalActions.setSnackbar({ message, type }));
    };

    return {
        copy,
    };
};

export default useCopy;
