/* eslint-disable max-len */
import { Checkbox } from '@adtech/ui';
import CounterBlocks from '@components/CounterBlocks';
import { Hint } from '@components/Hint';
import fieldDecorator from '@decorators/fieldDecorator';
import { formUtils } from '@utils/index';
import React from 'react';

import s from './SettingsDomain.pcss';

interface IProps {
    useDomainBlacklist: boolean;
}

interface ICheckboxType {
    field: any;
    title: string;
    hint: string;
}

export const CheckboxComponent = ({ field, title, hint }: ICheckboxType) => (
    <Checkbox className={s.checkboxesItem} checked={field.value} onChange={() => field.onChange(null, !field.value)}>
        {title}
        <Hint>{hint}</Hint>
    </Checkbox>
);

const UseDomainBlacklistField = fieldDecorator({
    type: 'boolean',
    name: 'useDomainBlacklist',
    title: 'Исключить из статистики данные со следующих доменов',
    hint: 'Данные, поступающие с указанных адресов, не будут учитываться в статистике. Например, вы можете указать адрес тестовой версии сайта.',
})(CheckboxComponent);

const UseSubDomainsField = fieldDecorator({
    type: 'boolean',
    name: 'mergeSubdomains',
    title: 'Учитывать поддомены',
    hint: 'Все домены второго уровня и выше указанных доменов будут считаться частью сайта при расчёте статистики.',
})(CheckboxComponent);

const UseWhitelistField = fieldDecorator({
    type: 'boolean',
    name: 'useWhitelist',
    title: 'Показывать в статистике данные только с указанных доменов',
    hint: 'В статистике будут учитываться только данные с указанных адресов, независимо от того, на каких ещё страницах установлен счётчик.',
})(CheckboxComponent);

const UseWwwField = fieldDecorator({
    type: 'boolean',
    name: 'mergeWww',
    title: 'Считать раздельно URL с www и без',
    hint: 'В отчётах группы «Содержание» страницы с "www" будут учитываться отдельно.',
})(CheckboxComponent);

const rulesWhitelist = [
    { validate: formUtils.defaultRules.required, errorType: 'required' },
    { validate: formUtils.defaultRules.urlWithDomainZone },
    { validate: formUtils.defaultRules.urlWithProtocolAndParams },
];

const rulesBlacklist = [
    { validate: formUtils.defaultRules.required, errorType: 'required' },
    { validate: formUtils.defaultRules.url },
];

const docLink = 'https://ads.sber.ru/help/nastroika-schyotchika#dopolnitelnye-domeny';

export default ({ useDomainBlacklist }: IProps) => (
    <div className={s.settingsDomain}>
        <CounterBlocks.Wrapper
            title="Дополнительные домены"
            description="Укажите дополнительные домены, на которых располагается ваш сайт, для корректного расчёта статистики по источникам трафика."
            rootClassName={s.domainRoot}
            titleClassName={s.domainTitle}
            descClassName={s.domainDescr}
            smallTitle
        >
            <div className={s.blockLink}>
                <a href={docLink} className={s.link} target="_blank" rel="noopener">
                    Подробнее
                </a>
            </div>
            <CounterBlocks.MultiInputData
                name="whitelistedUrls"
                button="Добавить"
                placeholder="Доменное имя"
                rulesInput={rulesWhitelist}
                ignoreShowErrors={['required']}
            />
            <div className={s.checkboxes}>
                <UseSubDomainsField />
                <UseWhitelistField />
                <UseWwwField />
            </div>
            <CounterBlocks.Wrapper
                subWrap
                withoutDelimiter
                rootClassName={s.customPaddingRoot}
                normalPadding={useDomainBlacklist}
            >
                <UseDomainBlacklistField withoutDelimiter />
                {useDomainBlacklist && (
                    <CounterBlocks.MultiInputData
                        className={s.inputBlackList}
                        name="blacklistedUrls"
                        button="Добавить"
                        placeholder="Доменное имя"
                        rulesInput={rulesBlacklist}
                        ignoreShowErrors={['required']}
                    />
                )}
            </CounterBlocks.Wrapper>
        </CounterBlocks.Wrapper>
    </div>
);
