import dateConstants from '@constants/date';
import { Op } from '@constants/listData';
import { DateType } from '@typings/date';
import { IFiltersConfig, IGlobalFilters } from '@typings/filters';
import DateUtils from '@utils/date';
import escapeRegExp from 'lodash/escapeRegExp';
import flattenDeep from 'lodash/flattenDeep';
import trim from 'lodash/trim';
import uniq from 'lodash/uniq';
import uniqBy from 'lodash/uniqBy';
import unset from 'lodash/unset';

class ListDataUtils {
    constructor() {
        return Object.freeze({
            getFiltersData: this.getFiltersData,
            filterBySearch: this.filterBySearch,
            computeData: this.computeData,
            toFlatConfigData: this.toFlatConfigData,
            getFlatConfigObj: this.getFlatConfigObj,
            matchingConfigItems: this.matchingConfigItems,
            getIndeterminate: this.getIndeterminate,
            getThemeIdsFromCategories: this.getThemeIdsFromCategories,
            getLians: this.getLians,
            getSimpleCategories: this.getSimpleCategories,
            getParentIdsByIds: this.getParentIdsByIds,
            generateIds: this.generateIds,
            getCountSelected: this.getCountSelected,
            convertValuesToArray: this.convertValuesToArray,
            convertValuesToFlat: this.convertValuesToFlat,
            filteredValues: this.filteredValues,
            filteredNotEmpty: this.filteredNotEmpty,
            filterOnlySubItems: this.filterOnlySubItems,
            checkIsDateValid: this.checkIsDateValid,
            checkIsTimeValid: this.checkIsTimeValid,
            checkIsNotValid: this.checkIsNotValid,
            removeEmptyData: this.removeEmptyData,
            __private: {},
        });
    }

    // TODO: Описать типы для методов ListDataUtils

    // public

    /**
     * Собираем данные для компонента фильтров GlobalFiltersList
     */
    getFiltersData = (filtersCnf: IFiltersConfig, globalFilters: IGlobalFilters) => {
        if (!filtersCnf) return {};

        const { staticFilters, popularityFilters } = filtersCnf;
        let filters = { ...globalFilters };

        const listData = {
            popularityFilters: this.computeData(popularityFilters),
            staticFilters: this.computeData(staticFilters),
        };

        // Из массива фильтров конфига делаем плоский объект с одними ключами фильтров
        const staticFiltersFlatCnf = this.getFlatConfigObj(staticFilters);

        // Не показываем фильтр не соответствующий текущему конфигу фильтров отчёта
        const isFilterInConfig =
            filters?.filtersRequest && filters.filtersRequest.every((filter) => !!staticFiltersFlatCnf[filter.key]);

        if (!isFilterInConfig) filters = {};

        const selectedData = { ...(filters?.selectedData || {}) };
        const isSavedFilterApplied = !!filters?.savedFilterOptions?.isSaved;

        return {
            listData,
            selectedData,
            isSavedFilterApplied,
        };
    };

    filterBySearch = (searchValue, originData) => {
        if (searchValue === '') {
            return originData;
        }

        // сначала просто фильтруем по поисковому запросу
        const filteredItems = originData.data.filter((item) => {
            const upperTitle = item.title.toUpperCase();
            const upperSearchValue = escapeRegExp(searchValue.toUpperCase());

            return upperTitle.search(upperSearchValue) !== -1;
        });

        const otherItems = [];
        let childItems = [];

        const addChildItemRec = (item) => {
            const items = originData.data.filter((itm) => itm.parent_id === item.id);
            childItems = [...childItems, ...items];

            if (items.length) {
                items.forEach((i) => {
                    addChildItemRec(i);
                });
            }
        };

        const addParentItemRec = (item, isSub = false) => {
            // если элемент ребенок, ищем его родителей и его детей
            if (item.parent_id) {
                // добавляем родителя
                const addedItem = originData.map[item.parent_id];
                otherItems.push(addedItem);

                // ищем родителей (рекурсионно)
                addParentItemRec(addedItem, true);
                // если элемент родитель первого уровня, ищем детей
            } else if (!item.parent_id && !isSub) {
                addChildItemRec(item);
            }
        };

        // собираем родителей найденных элементов
        filteredItems.forEach((item) => {
            addParentItemRec(item);
            addChildItemRec(item);
        });

        const data = uniqBy([...otherItems, ...childItems, ...filteredItems], 'id');

        // собираем объект с найденными элементами и их родителями
        const map = {};

        data.forEach((item) => {
            map[item.id] = item;
        });

        return { data, map, mode: originData.mode };
    };

    /**
     * Высчитываем данные
     */
    computeData = (originData) => this.toFlatConfigData(this.generateIds(originData));

    /**
     * Приводим данные к проскому виду
     */
    toFlatConfigData = (originData) => {
        const map = {};
        let result = [];
        let mode = 'three';

        // функция разложения вложенностей на плоский уровень
        const flat = (currentData, curNesting = 0) => {
            currentData.forEach((el) => {
                if (el.children && el.children.length) {
                    flat(el.children, curNesting + 1);
                }

                const element = {
                    parent_id: 0,
                    with_parents_id: null,
                    main: curNesting === 0,
                    nesting: curNesting,
                    ...el,
                };

                if (element.parentId) {
                    element.parent_id = String(element.parentId);
                    unset(element, 'parentId');
                }

                if (element.canBeExpanded) {
                    element.can_be_expanded = element.canBeExpanded;
                    unset(element, 'canBeExpanded');
                }

                if (element.withParentsId) {
                    element.with_parents_id = String(element.withParentsId);
                    unset(element, 'withParentsId');
                }

                if (element.theme_id) {
                    element.theme_id = String(element.theme_id);
                }

                element.id = String(element.id);

                if (element.parent_id) {
                    element.parent_id = String(element.parent_id);
                }

                map[element.id] = element;

                result.push(element);
            });
        };

        // запускаем операцию
        flat(originData);

        // пробегаемся по плоскому списку и выявляем main
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        result = result.map(({ children, ...other }) => ({ ...other }));
        mode = result.find((item) => item.parent_id) ? 'three' : 'flat';

        // собираем тоталы в объект по уровням
        const parentIds = uniq(Object.keys(map).map((id) => map[id].parent_id));
        const totals = {};
        parentIds.forEach((id) => {
            totals[id] = result.filter((item) => item.parent_id === id).length;
        });

        return { data: result, mode, map, totals };
    };

    getFlatConfigObj = (staticFilters) => {
        // Преобразовываем вложенный конфиг фильтров в плоский
        const staticFiltersList = this.computeData(staticFilters);

        if (!staticFiltersList?.data?.length) return {};

        // Из массива фильтров конфига делаем плоский объект с одними ключами фильтров
        return staticFiltersList.data.reduce(
            (res, item) => ({
                ...res,
                [item.dimension || item.metric]: true,
            }),
            {},
        );
    };

    /**
     * Делаем массив из id доступных элементов
     * и оставляем только выбранных детей
     */
    matchingConfigItems = (originData, config) => {
        const { data } = this.toFlatConfigData(originData);

        let result = data
            .map((item) => {
                const resItem = config.data.map[item.id];

                if (!resItem) {
                    return false;
                }

                return resItem.id;
            })
            .filter((item) => item !== false);

        result = result.filter((id) => {
            const resItem = config.data.map[id];

            return resItem && !this.getIndeterminate(resItem, result, config.data.data);
        });

        return result;
    };

    /**
     * Проверяем, есть ли выбранные дети
     */
    getIndeterminate = (item, selectedValues, data) => {
        const checked = selectedValues.includes(item.id);

        const subItems = data.filter((itm) => itm.parent_id === item.id || itm.parent_id === item.with_parents_id);
        const subChecked = subItems.filter((subItem) => selectedValues.includes(subItem.id)).length > 0;

        return checked && subItems && subChecked;
    };

    /**
     * Получаем theme_id элементов по их id
     */
    getThemeIdsFromCategories = (categories, config) => {
        const { map } = config;

        return uniq(
            categories.map((id) => (map[id].id === map[id].theme_id ? String(map[id].id) : String(map[id].theme_id))),
        );
    };

    /**
     * Получаем лианы элемента
     */
    getLians = (item, data) =>
        data.filter((itm) => item.theme_id && itm.theme_id === item.theme_id && itm.id !== item.id).map((i) => i.id);

    /**
     * Преобразуем полученные категории к пригодному виду
     * 1 - получаем лианы
     * 2 - удаляем родителей, если есть выбранные дети
     */
    getSimpleCategories = (categories, config) => {
        //  получаем лианы
        let result = flattenDeep(
            categories.map((id) => {
                const item = config.map[id];
                const data = config.data;
                const lians = this.getLians(item, data);

                return [id, ...lians];
            }),
        );

        // фильтруем категории - удаляем родителя, если есть выбранные дети
        result = this.filterOnlySubItems(result, config.map);

        // только уникальные значения
        result = uniq(result);

        return result;
    };

    /**
     * Удаляем родителей, оставляем только детей
     */
    filterOnlySubItems = (result, map) =>
        result.filter((mainId) => {
            const mainItem = map[mainId];

            const isSubChecked =
                result.filter((subId) => {
                    const subItem = map[subId];
                    return subItem.parent_id === mainId;
                }).length > 0;

            return (!mainItem.parent_id && !isSubChecked) || !!mainItem.parent_id;
        });

    /**
     * Получение родителей детей, если они есть
     */
    getParentIdsByIds = (ids, map, recursion = true) =>
        uniq(
            flattenDeep(
                ids.map((id) => {
                    const item = map[id];
                    const parentId = item ? item.parent_id : 0;

                    if (!parentId) {
                        return [];
                    }

                    const parentIds = recursion ? this.getParentIdsByIds([parentId], map) : [];

                    return [parentId, ...parentIds];
                }),
            ),
        );

    /**
     * Генератор ID для статических фильтров
     */
    generateIds = (filters) => {
        let currentId = 1;

        if (!filters) return null;

        const generateId = (items) => {
            const result = items.map((item) => {
                const children = generateId(item.children);

                const resItem = {
                    ...item,
                    id: currentId,
                    children,
                };

                currentId += 1;

                return resItem;
            });

            return result;
        };
        const getParentData = (items, parentId = null, parentTitle = null) => {
            const result = items.map((item) => {
                const curItem = { ...item, id: String(item.id) };

                const resItem = {
                    ...curItem,
                    parent_id: parentId,
                    parent_title: parentTitle,
                    children: getParentData(curItem.children, curItem.id, curItem.title),
                };

                return resItem;
            });

            return result;
        };

        return getParentData(generateId(filters));
    };

    /**
     * Получает количество выбранных элементров у фильтра
     */
    getCountSelected = (value) => {
        const { data, filter } = value;
        let result = 0;

        switch (filter?.type) {
            case 'checkbox':
                result = data.checkboxes?.length || 0;
                break;
            case 'textarea':
                result = data?.value?.split('\n').filter((item) => !!item).length || 0;
                break;
            case 'period':
            case 'date':
            case 'time':
                if (data.op === Op.range) {
                    result = data.from !== '' && data.to !== '' ? 1 : 0;
                } else {
                    result = data.value?.length ? 1 : 0;
                }
                break;
            case 'paramsArray':
                result = data?.params?.length
                    ? data.params.filter((param) => param.key !== '' && param.value !== '').length
                    : 0;
                break;
            case 'dateTime':
                if (data.op === Op.range) {
                    result = data.from && data.to && data.fromTime && data.toTime ? 1 : 0;
                } else {
                    result = data.value && data.valueTime ? 1 : 0;
                }
                break;
            default:
                break;
        }

        return result;
    };

    /**
     * Конвертируем выбранные значения в массив и приводим к одному виду
     */
    convertValuesToArray = (values = {}, filters = []) => {
        const keys = Object.keys(values);
        let result = [];

        if (!keys.length) return [];

        keys.forEach((key) => {
            const filter = filters.find((item) => item.name === key);

            // Отсекаем значения фильтров, которых нет в текущем конфиге
            if (!filter) return;

            result = [...result, { filter, data: values[key] }];
        });

        return result;
    };

    /**
     * Конвертируем данные к более простому виду и приводим их к нужному для бэка формату
     */
    convertValuesToFlat = (values = [], isAddTitles = false) =>
        flattenDeep(
            values.map((value) => {
                const { data, filter } = value;
                const valuesTitles = [];

                const defaultOp = filter.type === 'textarea' ? 'multilike' : '=';

                const op = data.condition || data.op || defaultOp;

                // Если значения нужно передавать в разных dimensions (в зависимости от уровня)
                // пример - ['geo_country', 'geo_area', 'geo_city']
                if (filter.dimensions) {
                    const allSelectedIds = data.checkboxes.map((checkbox) => checkbox.id);
                    const checkboxes = data.checkboxes;

                    return filter.dimensions.map((key, index) => {
                        // работает только с checkboxes
                        const val = checkboxes
                            .filter((checkbox) => !this.getIndeterminate(checkbox, allSelectedIds, checkboxes))
                            .filter((checkbox) => checkbox.level === index + 1)
                            .map((checkbox) => checkbox.id);

                        return { op, key, value: val };
                    });
                }

                let val = value;

                const allSelectedIds = data.checkboxes ? data.checkboxes.map((checkbox) => checkbox.id) : [];
                const checkboxes = data.checkboxes || [];

                const formatTime = (param) => `${param}:00`; // чч:мм:сс

                // Если это обычный dimension/metric, просто приводим данные к нужному виду
                switch (filter.type) {
                    case 'checkbox': {
                        val = checkboxes
                            .filter((checkbox) => !this.getIndeterminate(checkbox, allSelectedIds, checkboxes))
                            .map((checkbox) => {
                                // Сохраняем оригинальные названия полей (в geo вместо названий id)
                                if (isAddTitles) valuesTitles.push(checkbox.title);
                                // В ручке /filter/geo будет приходить специальное поле geoId,
                                // если оно есть, то берём его
                                return Number.isFinite(checkbox.geoId) ? checkbox.geoId : checkbox.id;
                            });

                        break;
                    }
                    case 'textarea': {
                        val = data.value.split('\n');

                        val = val
                            .map((v) => {
                                let newVal = trim(String(v));

                                // экранирование
                                newVal = newVal.replace(/%/gi, '\\%');

                                // замена символов на нужные
                                newVal = newVal.search(/@/gi) !== -1 ? `${newVal.replace(/@/gi, '%')}%` : newVal;
                                newVal = newVal.replace(/\*/gi, '%');

                                return newVal;
                                // убираем пустые строки
                            })
                            .filter((item) => !!item);

                        // ...multiLike...
                        break;
                    }
                    case 'period': {
                        val = data.op === Op.range ? [data.from, data.to] : data.value;

                        if (data.time === 'minutes') {
                            if (data.op === Op.range) {
                                val = val.map((int) => String(int * 60));
                            } else {
                                val = String(val * 60);
                            }
                        }
                        break;
                    }
                    case 'dateTime':
                    case 'date': {
                        const toISODateFormat = (date: DateType) =>
                            DateUtils.reformatDate(date, dateConstants.DATE_VIEW, dateConstants.DATE_FORMAT);

                        if (data.op === Op.range) {
                            const from = toISODateFormat(data.from);
                            const to = toISODateFormat(data.to);
                            const fromTime = data.fromTime ? ` ${formatTime(data.fromTime)}` : '';
                            const toTime = data.toTime ? ` ${formatTime(data.toTime)}` : '';
                            val = [from + fromTime, to + toTime];
                        } else {
                            const dValue = toISODateFormat(data.value);
                            const valueTime = data.valueTime ? ` ${formatTime(data.valueTime)}` : '';
                            val = dValue + valueTime;
                        }
                        break;
                    }
                    case 'time': {
                        val =
                            data.op === Op.range
                                ? [formatTime(data.from), formatTime(data.to)]
                                : formatTime(data.value);
                        break;
                    }
                    case 'paramsArray': {
                        val = data.params;
                        break;
                    }
                    default:
                        val = 0;
                        break;
                }

                return {
                    op,
                    key: filter.dimension || filter.metric,
                    value: val,
                    ...(isAddTitles ? { valuesTitles } : {}),
                    // Помечаем метричные фильтры, чтобы проще их фильтровать
                    ...(filter.metric ? { isMetricFilter: true } : {}),
                };
            }),
        );

    /**
     * Фильтруем значения фильтров по их заполненности
     */
    filteredValues = (values, isFlat = false, isAddTitles = false) => {
        // Отсекаем пустые значения
        let result = this.filteredNotEmpty(values);

        // Если требуется, приводим к простому виду
        if (isFlat) {
            result = this.convertValuesToFlat(result, isAddTitles);
        }

        return result;
    };

    filteredNotEmpty = (values = []) =>
        values
            .map((value) => {
                const { filter, data } = value;
                let newData = { ...data };

                if (filter?.type === 'paramsArray' && value.data.params) {
                    newData = {
                        ...newData,
                        params: value.data.params.filter((param) => param.key !== '' && param.value !== ''),
                    };
                }

                return {
                    filter: value.filter,
                    data: newData,
                };
            })
            .filter((value) => this.getCountSelected(value) > 0);

    /**
     * Проверяем на корректность дату
     */
    checkIsDateValid = (data, isRange) => {
        const { isValidDate, isAfter, getDayjsObject } = DateUtils;
        const format = dateConstants.DATE_VIEW;

        if (isRange) {
            // Указываем явно формат, иначе dayjs некорректно считывает дату
            const from = getDayjsObject(data.from, format);
            const to = getDayjsObject(data.to, format);

            // prettier-ignore
            return isValidDate(data.from)
                && isValidDate(data.to)
                && (isAfter(to, from) || data.from === data.to); // можно указать один день
        }

        return isValidDate(data.value);
    };

    /**
     * Проверяем на корректность время
     */
    checkIsTimeValid = (data, isRange) => {
        const { isValidTime } = DateUtils;

        return isRange ? isValidTime(data.from) && isValidTime(data.to) : isValidTime(data.value);
    };

    /**
     * Проверяем на некорректность значения выбранных фильтров
     */
    checkIsNotValid = (values) =>
        values.some((value) => {
            const { filter, data } = value;

            const isRange = data.op === Op.range;
            let isValid = true;

            switch (filter.type) {
                case 'date': {
                    isValid = this.checkIsDateValid(data, isRange);
                    break;
                }
                case 'time': {
                    isValid = this.checkIsTimeValid(data, isRange);
                    break;
                }
                case 'dateTime': {
                    const { fromTime, toTime, valueTime } = data;

                    isValid =
                        this.checkIsDateValid(data, isRange) &&
                        this.checkIsTimeValid(
                            {
                                from: fromTime,
                                to: toTime,
                                value: valueTime,
                            },
                            isRange,
                        );
                    break;
                }
                default:
                    break;
            }

            return !isValid;
        });

    /**
     * Удаляем поля с пустыми данными
     * (value = '' для обычных input, from|to - для интервалов, checkboxes = [])
     */
    removeEmptyData = (data) => {
        const newData = {};

        Object.keys(data).forEach((item) => {
            const { value, checkboxes, from, to } = data[item];

            if (value || from || to || checkboxes?.length) {
                newData[item] = data[item];
            }
        });

        return newData;
    };
}

export default new ListDataUtils();
