import { Modal } from '@adtech/ui';
import React from 'react';

interface IProps {
    isShowPopup: boolean;
    closePopup: () => void;
    updateGoals: () => void;
}

const GoalsUpdatePopup = ({ isShowPopup = false, closePopup = () => {}, updateGoals = () => {} }: IProps) => {
    // Обновить выбранные цели в ЯМ
    const handleUpdateGoals = () => {
        closePopup();
        updateGoals();
    };

    return (
        <Modal
            data-testid="modal"
            title="Передача целей"
            size="md"
            isOpened={isShowPopup}
            onOk={handleUpdateGoals}
            okText="Подтвердить"
            cancelText="Отменить"
            onClose={closePopup}
        >
            Подтвердите передачу целей в Яндекс Директ.
        </Modal>
    );
};

export default GoalsUpdatePopup;
