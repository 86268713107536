import { Loader } from '@adtech/ui';
import DataList, { IDataListItem } from '@components/DataList';
import Dropdown from '@components/Dropdown';
import IconSettings from '@images/svg/icons/icon-settings.svg';
import React, { FC } from 'react';
import s from './WidgetFilter.pcss';

interface IProps {
    list: IDataListItem[];
    renderListItemHandler: (item: IDataListItem) => JSX.Element;
    listRequest?: boolean;
    onClick?: () => void;
    onClose?: () => void;
}

const WidgetFilter: FC<IProps> = ({
    list = [],
    listRequest = false,
    renderListItemHandler,
    onClick = () => {},
    onClose = () => {},
}) => {
    const renderList = () => (
        <Loader
            loading={listRequest}
            size="small"
            className={s.loader}
            // blurContent // FIXME: после обновления @adtech-ui
        >
            <DataList
                list={list || []}
                renderItemHandler={renderListItemHandler}
                classNameListScroller={s.widgetFilterListScroller}
            />
        </Loader>
    );

    return (
        <Dropdown
            dataTestId="dropdown-container"
            btnDataTestId="setting-button"
            type="customContent"
            anchorPrefixIcon={<IconSettings className={s.anchorIcon} />}
            anchorClassName={s.anchor}
            anchorSize="small"
            isShowArrow={false}
            customContent={renderList()}
            onClick={onClick}
            onClose={onClose}
        />
    );
};

export default WidgetFilter;
