import { Select } from '@adtech/ui';
import fieldDecorator from '@decorators/fieldDecorator';
import * as React from 'react';

interface IProps {
    field: Record<string, any>;
    options: Record<string, any>;
    className: string;
}

class SelectField extends React.Component<IProps> {
    prepareForOption = (item: { label: string; value: string }) => ({
        value: item.value,
        label: item.label,
    });

    render() {
        const { field, options, className } = this.props;
        const curItem = options.find((item) => item.value === field.value);
        return (
            <Select
                className={className}
                options={options.map(this.prepareForOption)}
                onChange={(value) => field.onChange(null, value)}
                value={curItem ? curItem.label : ''}
            />
        );
    }
}

export default fieldDecorator({
    ignoreHidden: true,
    type: 'string',
})(SelectField);
