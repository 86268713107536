import { Input } from '@adtech/ui';
import inputConstants from '@constants/input';
import fieldDecorator from '@decorators/fieldDecorator';
import { IField, IMetaField } from '@typings/form';
import formUtils from '@utils/formUtils';
import React, { useEffect, useRef } from 'react';
import s from '../FormCreateCounter.pcss';

interface IProps {
    field: IField;
    meta: IMetaField;
    defaultTitle?: string;
}

const TitleComponent: React.FC<IProps> = ({ field, meta, defaultTitle }) => {
    const { onBlur, onClick, onChange, onFocus, value, name } = field || {};
    const isError = meta.invalid && meta.touched && !meta.focused;
    const defaultTitlePrev = useRef('');

    useEffect(() => {
        // Если при запросе title сайта по введенному пользователем url пришло не пустое значение,
        // и оно не равно значению title запрошенному до этого,
        // и пользователь еще не вводил ничего в поле title
        if (defaultTitle && defaultTitle !== defaultTitlePrev.current && (!meta.touched || value.lenght === 0)) {
            onChange(null, defaultTitle);
        }
        defaultTitlePrev.current = defaultTitle;
    }, [defaultTitle]);

    return (
        <div className={s.formRow}>
            <span>Название сайта</span>
            <Input
                value={value}
                name={name}
                placeholder="Будет отображаться в списке сайтов"
                type="text"
                errorText={meta.errorText}
                onBlur={onBlur}
                maxLength={inputConstants.MAX_TITLE_LENGTH}
                onChange={onChange}
                onClick={onClick}
                onFocus={onFocus}
                status={isError ? 'error' : ''}
            />
        </div>
    );
};

const Title = fieldDecorator({
    rules: [
        { validate: formUtils.defaultRules.required, errorType: 'required' },
        {
            validate: (value, values, props) => formUtils.defaultRules.maxLength(props.maxTitleLength)(value),
            errorType: 'maxLength',
            customErrorText: 'Превышен лимит символов',
        },
        {
            validate: formUtils.defaultRules.minLength(3),
            errorType: 'minLength',
            customErrorText: 'Минимум 3 символа',
        },
    ],
    name: 'title',
})(TitleComponent);

export default Title;
