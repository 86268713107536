import { Tooltip } from '@adtech/ui';
import { OrderIcon } from '@components/index';
import attributesData from '@configs/attributesData';
import { OrdersType } from '@constants/sort';
import { Metrics } from '@typings/metrics';
import { ReportName } from '@typings/reports';
import { IOrderBy } from '@typings/table';
import { measuresUtils } from '@utils/index';
import cn from 'classnames';
import React from 'react';
import { useParams } from 'react-router-dom';

import s from '../../ReportTable.pcss';

interface IProps {
    indicator: Metrics;
    orderBy: IOrderBy;
    label?: string | null;
    onIndicatorClick: (indicator: Metrics, order?: OrdersType) => void;
    utils: TAPI;
    reportName?: ReportName;
    forceDisableSort?: boolean;
}

const IndicatorTitle: React.FC<IProps> = ({
    indicator,
    orderBy,
    label = '',
    onIndicatorClick,
    utils,
    reportName,
    forceDisableSort,
}) => {
    const params: IUrlParams = useParams();
    const nameReport = reportName || params.reportName;

    const isDisabledSort = forceDisableSort || utils.getParamFromConfig(nameReport, 'isDisabledSort');
    const disabledSortBy = utils.getParamFromConfig(nameReport, 'disabledSortBy', []);
    const allowedSortBy = utils.getParamFromConfig(nameReport, 'allowedSortBy');
    const isDisabledSortBy = isDisabledSort || disabledSortBy.includes(indicator);
    const className = cn(s.tableTitle, {
        [s.tableTitleOnGraph]: false,
        [s.tableTitleOrdered]: measuresUtils.isOrderingBy(indicator, orderBy),
        [s.tableTitleDisableSort]: isDisabledSortBy,
    });
    const orderDirection = measuresUtils.checkOrderDirection(orderBy);

    const tooltip = measuresUtils.getTooltip(indicator, nameReport);
    const linkToDoc = measuresUtils.getParamFromConfig(indicator, 'linkToDoc');

    const tableAttr = attributesData.newreport.table;

    let onClick = (order: OrdersType) => {
        if (!isDisabledSortBy) {
            onIndicatorClick(indicator, order);
        }
    };

    const handleLabelClick = () => {
        if (!isDisabledSortBy) {
            onIndicatorClick(indicator);
        }
    };

    const labelFromDict = measuresUtils.getTitle(indicator, nameReport);
    const newLabel = <span onClick={handleLabelClick}>{label || labelFromDict}</span>;

    if (isDisabledSortBy) {
        onClick = null;
    }

    const isSelectedOrder = orderBy?.key === indicator;

    const orders = {
        asc: isSelectedOrder ? orderDirection.asc : false,
        desc: isSelectedOrder ? orderDirection.desc : false,
    };

    const dataAttr = `${tableAttr.attrName}::${tableAttr.tools.attrName}::${tableAttr.tools.sort}::${indicator}::${nameReport}`; // eslint-disable-line max-len

    return (
        <div className={className} data-newreport={dataAttr}>
            <Tooltip
                title={
                    <>
                        {tooltip || newLabel}
                        {linkToDoc && (
                            <>
                                <br />
                                <a href={linkToDoc} target="_blank" rel="noopener">
                                    Подробнее в&nbsp;Документации
                                </a>
                            </>
                        )}
                    </>
                }
                className={s.tableTitleTooltip}
            >
                {newLabel}
            </Tooltip>
            {!isDisabledSort && (
                <div className={s.tableTitleButtons}>
                    {Object.keys(orderDirection).length !== 0 && !isDisabledSortBy && (
                        <div className={s.tableTitleOrder}>
                            <OrderIcon allowedSortBy={allowedSortBy} orders={orders} onClick={onClick} />
                        </div>
                    )}
                </div>
            )}
        </div>
    );
};

export default IndicatorTitle;
