class ArrayUtils {
    /**
     * Проверяем, массив ли входная переменная?
     */
    isArray = (array: any): boolean => Array.isArray(array);

    /**
     * Проверяем эквивалентность двух массивов
     */
    isEqualArray = (arrOne: any[], arrTwo: any[]): boolean => {
        if (arrOne.length !== arrTwo.length) return false;

        let isEqual = true;

        arrOne.forEach((item, i) => {
            if (item !== arrTwo[i]) {
                isEqual = false;
            }
        });

        return isEqual;
    };

    /**
     * Строим строку с разделителем из массива, если входной параметр массив,
     * если нет, возвращаем строку
     */
    convertToStringWithSeparator = (stringArray: string | string[], separator: string): string =>
        Array.isArray(stringArray) ? stringArray.join(separator) : stringArray;

    uniqBy = <T extends Array<TDict<string>>>(arr: T, key: string) => {
        const hash: Record<string, boolean> = {};

        return arr.filter((item) => {
            const k = item[key] as string;

            if (hash[k]) return false;

            hash[k] = true;

            return true;
        });
    };
}

export default new ArrayUtils();
