import ConversionForm from './ConversionForm';
import CreateCounterFinal from './CreateCounterFinal';
import Csv from './Csv';
import DeleteTemplate from './DeleteTemplate';
import GoalForm from './GoalForm';
import HintPopup from './HintPopup';
import RemoveCounter from './RemoveCounter';
import RestoreAccessCounter from './RestoreAccessCounter';
import RestoreAccessFinal from './RestoreAccessFinal';
import RestoreCounter from './RestoreCounter';
import SaveOrEdit from './SaveOrEdit';
import SaveTemplate from './SaveTemplate';
import SuccessRestoreCounter from './SuccessRestoreCounter';

export default {
    Csv,
    CreateCounterFinal,
    RestoreAccessCounter,
    RestoreAccessFinal,
    SuccessRestoreCounter,
    HintPopup,
    RemoveCounter,
    RestoreCounter,
    GoalForm,
    ConversionForm,
    SaveTemplate,
    SaveOrEdit,
    DeleteTemplate,
};
