/**
 * Общий конфиг всех статических фильтров
 */
import { Operations } from '@configs/operations';
import { IStaticFilter } from '@typings/filters';

export const filtersCnf: Record<string, IStaticFilter> = {
    view_depth: {
        tab: 'all',
        name: 'view_depth',
        dimension: 'view_depth',
        title: 'Глубина просмотра',
        type: 'period',
        children: [],
    },
    visit_time: {
        tab: 'all',
        name: 'visit_time',
        dimension: 'duration_full',
        title: 'Время на сайте',
        options: {
            isTime: true,
            defaultValue: Operations.gt,
        },
        type: 'period',
        children: [],
    },
    // География для новой модели
    geo_country: {
        tab: 'all',
        name: 'geo',
        dimension: 'geo_country',
        title: 'Страна',
        type: 'checkbox',
        options: {
            isDynamic: true,
            isThree: false,
        },
        condition: 'turning',
        children: [],
    },
    geo_area: {
        tab: 'all',
        name: 'geo_area',
        dimension: 'geo_area',
        title: 'Регион',
        type: 'checkbox',
        options: {
            isDynamic: true,
            isThree: false,
        },
        condition: 'turning',
        children: [],
    },
    geo_city: {
        tab: 'all',
        name: 'geo_city',
        dimension: 'geo_city',
        title: 'Город',
        type: 'checkbox',
        options: {
            isDynamic: true,
            isThree: false,
        },
        condition: 'turning',
        children: [],
    },
    sex: {
        tab: 'all',
        name: 'sex',
        dimension: 'gender',
        title: 'Пол',
        type: 'checkbox',
        options: {
            isThree: false,
            isHideCount: true,
        },
        data: [
            {
                can_be_expanded: false,
                expand: false,
                id: 'male',
                level: 1,
                main: true,
                nesting: 0,
                parent_id: 'DATA_ROOT_NODE',
                title: 'Мужчины',
                with_parents_id: 'male',
            },
            {
                can_be_expanded: false,
                expand: false,
                id: 'female',
                level: 1,
                main: true,
                nesting: 0,
                parent_id: 'DATA_ROOT_NODE',
                title: 'Женщины',
                with_parents_id: 'female',
            },
            {
                can_be_expanded: false,
                expand: false,
                id: 'other',
                level: 1,
                main: true,
                nesting: 0,
                parent_id: 'DATA_ROOT_NODE',
                title: 'Не определено',
                with_parents_id: 'other',
            },
        ],
        condition: 'turning',
        children: [],
    },
    age: {
        tab: 'all',
        name: 'age',
        dimension: 'age_tns',
        title: 'Возраст',
        type: 'checkbox',
        options: {
            isThree: false,
            isHideCount: true,
        },
        data: [
            {
                id: '12-18',
                title: '12-18',
                level: 1,
                expand: false,
                nesting: 0,
                parent_id: 'DATA_ROOT_NODE',
                can_be_expanded: true,
                with_parents_id: '12-18',
                main: true,
            },
            {
                id: '18-25',
                title: '18-25',
                level: 1,
                expand: false,
                nesting: 0,
                parent_id: 'DATA_ROOT_NODE',
                can_be_expanded: true,
                with_parents_id: '18-25',
                main: true,
            },
            {
                id: '25-35',
                title: '25-35',
                level: 1,
                expand: false,
                nesting: 0,
                parent_id: 'DATA_ROOT_NODE',
                can_be_expanded: true,
                with_parents_id: '25-35',
                main: true,
            },
            {
                id: '35-45',
                title: '35-45',
                level: 1,
                expand: false,
                nesting: 0,
                parent_id: 'DATA_ROOT_NODE',
                can_be_expanded: true,
                with_parents_id: '35-45',
                main: true,
            },
            {
                id: '45-55',
                title: '45-55',
                level: 1,
                expand: false,
                nesting: 0,
                parent_id: 'DATA_ROOT_NODE',
                can_be_expanded: true,
                with_parents_id: '45-55',
                main: true,
            },
            {
                id: '55-65',
                title: '55-65',
                level: 1,
                expand: false,
                nesting: 0,
                parent_id: 'DATA_ROOT_NODE',
                can_be_expanded: true,
                with_parents_id: '55-65',
                main: true,
            },
            {
                id: '65+',
                title: '65+',
                level: 1,
                expand: false,
                nesting: 0,
                parent_id: 'DATA_ROOT_NODE',
                can_be_expanded: true,
                with_parents_id: '65+',
                main: true,
            },
            {
                id: 'other',
                title: 'Не определено',
                level: 1,
                expand: false,
                nesting: 0,
                parent_id: 'DATA_ROOT_NODE',
                can_be_expanded: true,
                with_parents_id: 'other',
                main: true,
            },
        ],
        condition: 'turning',
        children: [],
    },
    segments: {
        tab: 'all',
        name: 'segments',
        dimension: 'category_1',
        title: 'Интересы',
        type: 'checkbox',
        options: {
            isDynamic: true,
            isThree: false,
        },
        condition: 'turning',
        children: [],
    },
    device: {
        tab: 'all',
        name: 'device',
        dimension: 'device_type',
        title: 'Устройства',
        type: 'checkbox',
        options: {
            isDynamic: true,
            isThree: false,
        },
        condition: 'turning',
        children: [],
    },
    browser: {
        tab: 'all',
        name: 'browser',
        dimension: 'browser_family',
        title: 'Браузеры',
        type: 'checkbox',
        options: {
            isDynamic: true,
            isThree: false,
        },
        condition: 'turning',
        children: [],
    },
    os: {
        tab: 'all',
        name: 'os',
        dimension: 'os_family',
        title: 'Операционные системы',
        type: 'checkbox',
        options: {
            isDynamic: true,
            isThree: false,
        },
        condition: 'turning',
        children: [],
    },
    counter_type: {
        tab: 'all',
        name: 'counter_type',
        dimension: 'counter_type_eventbase',
        title: 'Формат страниц',
        type: 'checkbox',
        options: {
            isThree: false,
            isHideCount: true,
        },
        data: [
            {
                id: 'Web',
                title: 'Web',
                level: 1,
                expand: false,
                can_be_expanded: false,
                nesting: 0,
                parent_id: 'DATA_ROOT_NODE',
                with_parents_id: 'Web',
                main: true,
            },
            {
                id: 'Web-view',
                title: 'Web-view',
                level: 1,
                expand: false,
                can_be_expanded: false,
                nesting: 0,
                parent_id: 'DATA_ROOT_NODE',
                with_parents_id: 'Web-view',
                main: true,
            },
            {
                id: 'Android',
                title: 'Android',
                level: 1,
                expand: false,
                can_be_expanded: false,
                nesting: 0,
                parent_id: 'DATA_ROOT_NODE',
                with_parents_id: 'Android',
                main: true,
            },
            {
                id: 'iOS',
                title: 'iOS',
                level: 1,
                expand: false,
                can_be_expanded: false,
                nesting: 0,
                parent_id: 'DATA_ROOT_NODE',
                with_parents_id: 'iOS',
                main: true,
            },
            {
                id: 'AMP',
                title: 'Amp',
                level: 1,
                expand: false,
                can_be_expanded: false,
                nesting: 0,
                parent_id: 'DATA_ROOT_NODE',
                with_parents_id: 'AMP',
                main: true,
            },
            {
                id: 'Turbo',
                title: 'Turbo',
                level: 1,
                expand: false,
                can_be_expanded: false,
                nesting: 0,
                parent_id: 'DATA_ROOT_NODE',
                with_parents_id: 'Turbo',
                main: true,
            },
        ],
        condition: 'turning',
        children: [],
    },
    traffic_sources: {
        tab: 'all',
        name: 'traffic_sources',
        dimension: 'traffic_type',
        title: 'Источники переходов',
        type: 'checkbox',
        options: {
            isThree: false,
            isHideCount: true,
        },
        data: [
            {
                id: 'straight',
                title: 'Прямые заходы',
                level: 1,
                expand: false,
                can_be_expanded: false,
                nesting: 0,
                parent_id: 'DATA_ROOT_NODE',
                with_parents_id: 'straight',
                main: true,
            },
            {
                id: 'site',
                title: 'По ссылкам на сайтах',
                level: 1,
                expand: false,
                can_be_expanded: false,
                nesting: 0,
                parent_id: 'DATA_ROOT_NODE',
                with_parents_id: 'site',
                main: true,
            },
            {
                id: 'internal',
                title: 'Внутренние переходы',
                level: 1,
                expand: false,
                can_be_expanded: false,
                nesting: 0,
                parent_id: 'DATA_ROOT_NODE',
                with_parents_id: 'internal',
                main: true,
            },
            {
                id: 'search',
                title: 'Поисковые системы',
                level: 1,
                expand: false,
                can_be_expanded: false,
                nesting: 0,
                parent_id: 'DATA_ROOT_NODE',
                with_parents_id: 'search',
                main: true,
            },
            {
                id: 'adv',
                title: 'Рекламные системы',
                level: 1,
                expand: false,
                can_be_expanded: false,
                nesting: 0,
                parent_id: 'DATA_ROOT_NODE',
                with_parents_id: 'adv',
                main: true,
            },
            {
                id: 'social',
                title: 'Социальные сети',
                level: 1,
                expand: false,
                can_be_expanded: false,
                nesting: 0,
                parent_id: 'DATA_ROOT_NODE',
                with_parents_id: 'social',
                main: true,
            },
            {
                id: 'zen',
                title: 'Яндекс.Дзен',
                level: 1,
                expand: false,
                can_be_expanded: false,
                nesting: 0,
                parent_id: 'DATA_ROOT_NODE',
                with_parents_id: 'zen',
                main: true,
            },
            {
                id: 'email',
                title: 'Почтовые рассылки',
                level: 1,
                expand: false,
                can_be_expanded: false,
                nesting: 0,
                parent_id: 'DATA_ROOT_NODE',
                with_parents_id: 'email',
                main: true,
            },
            {
                id: 'local_page',
                title: 'Сохраненные страницы',
                level: 1,
                expand: false,
                can_be_expanded: false,
                nesting: 0,
                parent_id: 'DATA_ROOT_NODE',
                with_parents_id: 'local_page',
                main: true,
            },
            // Пока не работают
            // {
            //     id: 'recom_system',
            //     title: 'Рекомендательные системы',
            //     level: 1,
            //     expand: false,
            //     can_be_expanded: false,
            //     nesting: 0,
            //     parent_id: 'DATA_ROOT_NODE',
            //     with_parents_id: 'recom_system',
            //     main: true,
            // },
            // {
            //     id: 'exchange_system',
            //     title: 'Обменные сети',
            //     level: 1,
            //     expand: false,
            //     can_be_expanded: false,
            //     nesting: 0,
            //     parent_id: 'DATA_ROOT_NODE',
            //     with_parents_id: 'exchange_system',
            //     main: true,
            // },
            // {
            //     id: 'message_system',
            //     title: 'Мессенджеры',
            //     level: 1,
            //     expand: false,
            //     can_be_expanded: false,
            //     nesting: 0,
            //     parent_id: 'DATA_ROOT_NODE',
            //     with_parents_id: 'message_system',
            //     main: true,
            // },
            // {
            //     id: 'aggrnews_system',
            //     title: 'Новостные агрегаторы',
            //     level: 1,
            //     expand: false,
            //     can_be_expanded: false,
            //     nesting: 0,
            //     parent_id: 'DATA_ROOT_NODE',
            //     with_parents_id: 'aggrnews_system',
            //     main: true,
            // },
            // {
            //     id: 'qr_system',
            //     title: 'Переходы по QR-кодам',
            //     level: 1,
            //     expand: false,
            //     can_be_expanded: false,
            //     nesting: 0,
            //     parent_id: 'DATA_ROOT_NODE',
            //     with_parents_id: 'qr_system',
            //     main: true,
            // },
            {
                id: 'native_mobile',
                title: 'Мобильные приложения',
                level: 1,
                expand: false,
                can_be_expanded: false,
                nesting: 0,
                parent_id: 'DATA_ROOT_NODE',
                with_parents_id: 'native_mobile',
                main: true,
            },
        ],
        condition: 'turning',
        children: [],
    },
    ad_systems: {
        tab: 'all',
        name: 'ad_systems',
        dimension: 'ad_system',
        title: 'Рекламные системы',
        type: 'checkbox',
        options: {
            isDynamic: true,
            isThree: false,
        },
        condition: 'turning',
        children: [],
    },
    ad_campaign: {
        tab: 'all',
        name: 'ad_campaign',
        dimension: 'ad_campaign',
        title: 'Рекламные кампании',
        type: 'textarea',
        options: {
            descr: 'Введите полное название рекламной кампании или используйте специальные символы:',
            placeholder: ['campaign_example', 'campaign_*', '@example'],
            rules: [
                { mark: '*', text: 'заменяет любое количество любых символов' },
                { mark: '@', text: 'в начале — вхождение строки' },
            ],
        },
        condition: 'entry',
        children: [],
    },
    // UTM для старой модели
    utms: {
        tab: 'all',
        name: 'utms',
        title: 'UTM Метки',
        condition: 'entry',
        children: [
            {
                name: 'utms_source',
                dimension: 'utm_source_in_first_pv',
                title: 'Source',
                type: 'textarea',
                options: {
                    descr: 'Введите точное значение параметра или используйте специальные символы:',
                    placeholder: ['parameter_example', 'parameter_*', '@example'],
                    rules: [
                        { mark: '*', text: 'заменяет любое количество любых символов' },
                        { mark: '@', text: 'в начале — вхождение строки' },
                    ],
                },
                children: [],
            },
            {
                name: 'utms_medium',
                dimension: 'utm_medium_in_first_pv',
                title: 'Medium',
                type: 'textarea',
                options: {
                    descr: 'Введите точное значение параметра или используйте специальные символы:',
                    placeholder: ['parameter_example', 'parameter_*', '@example'],
                    rules: [
                        { mark: '*', text: 'заменяет любое количество любых символов' },
                        { mark: '@', text: 'в начале — вхождение строки' },
                    ],
                },
                children: [],
            },
            {
                name: 'utms_campaign',
                dimension: 'utm_campaign_in_first_pv',
                title: 'Campaign',
                type: 'textarea',
                options: {
                    descr: 'Введите точное значение параметра или используйте специальные символы:',
                    placeholder: ['parameter_example', 'parameter_*', '@example'],
                    rules: [
                        { mark: '*', text: 'заменяет любое количество любых символов' },
                        { mark: '@', text: 'в начале — вхождение строки' },
                    ],
                },
                children: [],
            },
            {
                name: 'utms_content',
                dimension: 'utm_content_in_first_pv',
                title: 'Content',
                type: 'textarea',
                options: {
                    descr: 'Введите точное значение параметра или используйте специальные символы:',
                    placeholder: ['parameter_example', 'parameter_*', '@example'],
                    rules: [
                        { mark: '*', text: 'заменяет любое количество любых символов' },
                        { mark: '@', text: 'в начале — вхождение строки' },
                    ],
                },
                children: [],
            },
            {
                name: 'utms_term',
                dimension: 'utm_term_in_first_pv',
                title: 'Term',
                type: 'textarea',
                options: {
                    descr: 'Введите точное значение параметра или используйте специальные символы:',
                    placeholder: ['parameter_example', 'parameter_*', '@example'],
                    rules: [
                        { mark: '*', text: 'заменяет любое количество любых символов' },
                        { mark: '@', text: 'в начале — вхождение строки' },
                    ],
                },
                children: [],
            },
        ],
    },
    // UTM для новой модели
    utm_eventbase: {
        tab: 'all',
        name: 'utm_eventbase',
        title: 'UTM Метки',
        condition: 'entry',
        children: [
            {
                name: 'utm_source',
                dimension: 'utm_source',
                title: 'Source',
                type: 'textarea',
                options: {
                    descr: 'Введите точное значение параметра или используйте специальные символы:',
                    placeholder: ['parameter_example', 'parameter_*', '@example'],
                    rules: [
                        { mark: '*', text: 'заменяет любое количество любых символов' },
                        { mark: '@', text: 'в начале — вхождение строки' },
                    ],
                },
                children: [],
            },
            {
                name: 'utm_medium',
                dimension: 'utm_medium',
                title: 'Medium',
                type: 'textarea',
                options: {
                    descr: 'Введите точное значение параметра или используйте специальные символы:',
                    placeholder: ['parameter_example', 'parameter_*', '@example'],
                    rules: [
                        { mark: '*', text: 'заменяет любое количество любых символов' },
                        { mark: '@', text: 'в начале — вхождение строки' },
                    ],
                },
                children: [],
            },
            {
                name: 'utm_campaign',
                dimension: 'utm_campaign',
                title: 'Campaign',
                type: 'textarea',
                options: {
                    descr: 'Введите точное значение параметра или используйте специальные символы:',
                    placeholder: ['parameter_example', 'parameter_*', '@example'],
                    rules: [
                        { mark: '*', text: 'заменяет любое количество любых символов' },
                        { mark: '@', text: 'в начале — вхождение строки' },
                    ],
                },
                children: [],
            },
            {
                name: 'utm_content',
                dimension: 'utm_content',
                title: 'Content',
                type: 'textarea',
                options: {
                    descr: 'Введите точное значение параметра или используйте специальные символы:',
                    placeholder: ['parameter_example', 'parameter_*', '@example'],
                    rules: [
                        { mark: '*', text: 'заменяет любое количество любых символов' },
                        { mark: '@', text: 'в начале — вхождение строки' },
                    ],
                },
                children: [],
            },
            {
                name: 'utm_term',
                dimension: 'utm_term',
                title: 'Term',
                type: 'textarea',
                options: {
                    descr: 'Введите точное значение параметра или используйте специальные символы:',
                    placeholder: ['parameter_example', 'parameter_*', '@example'],
                    rules: [
                        { mark: '*', text: 'заменяет любое количество любых символов' },
                        { mark: '@', text: 'в начале — вхождение строки' },
                    ],
                },
                children: [],
            },
        ],
    },
    // Openstat для старой модели
    openstat: {
        tab: 'all',
        name: 'openstat',
        title: 'Метки Openstat',
        condition: 'entry',
        children: [
            {
                name: 'openstat_service',
                dimension: 'openstat_service_in_first_pv',
                title: 'Service',
                type: 'textarea',
                options: {
                    descr: 'Введите точное значение параметра или используйте специальные символы:',
                    placeholder: ['parameter_example', 'parameter_*', '@example'],
                    rules: [
                        { mark: '*', text: 'заменяет любое количество любых символов' },
                        { mark: '@', text: 'в начале — вхождение строки' },
                    ],
                },
                children: [],
            },
            {
                name: 'openstat_campaign',
                dimension: 'openstat_campaign_in_first_pv',
                title: 'Campaign',
                type: 'textarea',
                options: {
                    descr: 'Введите точное значение параметра или используйте специальные символы:',
                    placeholder: ['parameter_example', 'parameter_*', '@example'],
                    rules: [
                        { mark: '*', text: 'заменяет любое количество любых символов' },
                        { mark: '@', text: 'в начале — вхождение строки' },
                    ],
                },
                children: [],
            },
            {
                name: 'openstat_ad',
                dimension: 'openstat_ad_in_first_pv',
                title: 'Ad',
                type: 'textarea',
                options: {
                    descr: 'Введите точное значение параметра или используйте специальные символы:',
                    placeholder: ['parameter_example', 'parameter_*', '@example'],
                    rules: [
                        { mark: '*', text: 'заменяет любое количество любых символов' },
                        { mark: '@', text: 'в начале — вхождение строки' },
                    ],
                },
                children: [],
            },
            {
                name: 'openstat_source',
                dimension: 'openstat_source_in_first_pv',
                title: 'Source',
                type: 'textarea',
                options: {
                    descr: 'Введите точное значение параметра или используйте специальные символы:',
                    placeholder: ['parameter_example', 'parameter_*', '@example'],
                    rules: [
                        { mark: '*', text: 'заменяет любое количество любых символов' },
                        { mark: '@', text: 'в начале — вхождение строки' },
                    ],
                },
                children: [],
            },
        ],
    },
    // Openstat для новой модели
    openstat_eventbase: {
        tab: 'all',
        name: 'openstat_eventbase',
        title: 'Метки Openstat',
        condition: 'entry',
        children: [
            {
                name: 'openstat_service',
                dimension: 'openstat_service',
                title: 'Service',
                type: 'textarea',
                options: {
                    descr: 'Введите точное значение параметра или используйте специальные символы:',
                    placeholder: ['parameter_example', 'parameter_*', '@example'],
                    rules: [
                        { mark: '*', text: 'заменяет любое количество любых символов' },
                        { mark: '@', text: 'в начале — вхождение строки' },
                    ],
                },
                children: [],
            },
            {
                name: 'openstat_campaign',
                dimension: 'openstat_campaign',
                title: 'Campaign',
                type: 'textarea',
                options: {
                    descr: 'Введите точное значение параметра или используйте специальные символы:',
                    placeholder: ['parameter_example', 'parameter_*', '@example'],
                    rules: [
                        { mark: '*', text: 'заменяет любое количество любых символов' },
                        { mark: '@', text: 'в начале — вхождение строки' },
                    ],
                },
                children: [],
            },
            {
                name: 'openstat_ad',
                dimension: 'openstat_ad',
                title: 'Ad',
                type: 'textarea',
                options: {
                    descr: 'Введите точное значение параметра или используйте специальные символы:',
                    placeholder: ['parameter_example', 'parameter_*', '@example'],
                    rules: [
                        { mark: '*', text: 'заменяет любое количество любых символов' },
                        { mark: '@', text: 'в начале — вхождение строки' },
                    ],
                },
                children: [],
            },
            {
                name: 'openstat_source',
                dimension: 'openstat_source',
                title: 'Source',
                type: 'textarea',
                options: {
                    descr: 'Введите точное значение параметра или используйте специальные символы:',
                    placeholder: ['parameter_example', 'parameter_*', '@example'],
                    rules: [
                        { mark: '*', text: 'заменяет любое количество любых символов' },
                        { mark: '@', text: 'в начале — вхождение строки' },
                    ],
                },
                children: [],
            },
        ],
    },
    search_system_phrase: {
        tab: 'all',
        name: 'search_system_phrase',
        dimension: 'search_system',
        title: 'Поисковые системы',
        type: 'checkbox',
        options: {
            isDynamic: true,
            isThree: false,
        },
        condition: 'turning',
        children: [],
    },
    search_phrase_system: {
        tab: 'all',
        name: 'search_phrase_system',
        dimension: 'search_phrase',
        title: 'Поисковые фразы',
        type: 'textarea',
        options: {
            descr: 'Введите точный текст или используйте специальные символы:',
            placeholder: ['Поисковая фраза', 'Поиск*', '@фраза'],
            rules: [
                { mark: '*', text: 'заменяет любое количество любых символов' },
                { mark: '@', text: 'в начале — вхождение строки' },
            ],
        },
        condition: 'entry',
        children: [],
    },
    recom_system: {
        tab: 'all',
        name: 'recom_systems',
        dimension: 'recom_system',
        title: 'Рекомендательные системы',
        type: 'checkbox',
        options: {
            isDynamic: true,
            isThree: false,
        },
        condition: 'turning',
        children: [],
    },
    message_system: {
        tab: 'all',
        name: 'message_systems',
        dimension: 'message_system',
        title: 'Мессенджеры',
        type: 'checkbox',
        options: {
            isDynamic: true,
            isThree: false,
        },
        condition: 'turning',
        children: [],
    },
    qr_system: {
        tab: 'all',
        name: 'qr_systems',
        dimension: 'qr_system',
        title: 'Переходы по QR-кодам',
        type: 'checkbox',
        options: {
            isDynamic: true,
            isThree: false,
        },
        condition: 'turning',
        children: [],
    },
    aggrnews_system: {
        tab: 'all',
        name: 'aggrnews_systems',
        dimension: 'aggrnews_system',
        title: 'Новостные агрегаторы',
        type: 'checkbox',
        options: {
            isDynamic: true,
            isThree: false,
        },
        condition: 'turning',
        children: [],
    },
    exchange_system: {
        tab: 'all',
        name: 'exchange_system',
        dimension: 'exchange_system',
        title: 'Обменные сети',
        type: 'textarea',
        options: {
            descr: 'Введите точный текст или используйте специальные символы:',
            placeholder: ['Название', 'Поиск*', '@фраза'],
            rules: [
                { mark: '*', text: 'заменяет любое количество любых символов' },
                { mark: '@', text: 'в начале — вхождение строки' },
            ],
        },
        condition: 'entry',
        children: [],
    },
    social_network: {
        tab: 'all',
        name: 'social_networks',
        dimension: 'social_network',
        title: 'Социальные сети',
        type: 'checkbox',
        options: {
            isDynamic: true,
            isThree: false,
        },
        condition: 'turning',
        children: [],
    },
    sites: {
        tab: 'all',
        name: 'sites',
        dimension: 'referer',
        title: 'Сайты / Referer',
        type: 'textarea',
        options: {
            descr: 'Введите точный URL либо используйте специальные символы:',
            placeholder: ['http://example.com/index.html', 'http://example.com/photos/', '@/articles/'],
            rules: [
                { mark: '*', text: 'заменяет любое количество любых символов' },
                { mark: '@', text: 'в начале — вхождение строки' },
            ],
        },
        condition: 'entry',
        children: [],
    },
    // URL для старой модели
    page_urls: {
        tab: 'all',
        name: 'page_urls',
        dimension: 'page_no_utm',
        title: 'Популярное',
        type: 'textarea',
        options: {
            descr: 'Введите точный URL либо используйте специальные символы:',
            placeholder: ['http://example.com/index.html', 'http://example.com/photos/', '@/articles/'],
            rules: [
                { mark: '*', text: 'заменяет любое количество любых символов' },
                { mark: '@', text: 'в начале — вхождение строки' },
            ],
        },
        condition: 'entry',
        children: [],
    },
    // URL для новой модели
    url: {
        tab: 'all',
        name: 'url',
        dimension: 'url',
        title: 'URL',
        type: 'textarea',
        options: {
            descr: 'Введите точный URL либо используйте специальные символы:',
            placeholder: ['http://example.com/index.html', 'http://example.com/photos/', '@/articles/'],
            rules: [
                { mark: '*', text: 'заменяет любое количество любых символов' },
                { mark: '@', text: 'в начале — вхождение строки' },
            ],
        },
        condition: 'entry',
        children: [],
    },
    url_no_args: {
        tab: 'all',
        name: 'url_no_args',
        dimension: 'url_no_args',
        title: 'Популярный URL',
        type: 'textarea',
        options: {
            descr: 'Введите точный URL либо используйте специальные символы:',
            placeholder: ['http://example.com/index.html', 'http://example.com/photos/', '@/articles/'],
            rules: [
                { mark: '*', text: 'заменяет любое количество любых символов' },
                { mark: '@', text: 'в начале — вхождение строки' },
            ],
        },
        condition: 'entry',
        children: [],
    },
    // Параметры URL для новой модели
    url_args_key: {
        tab: 'all',
        name: 'url_args_key',
        dimension: 'url_args_key',
        title: 'Параметры URL',
        type: 'checkbox',
        options: {
            isDynamic: true,
            isThree: false,
        },
        condition: 'turning',
        children: [],
    },
    entrance_pages: {
        tab: 'all',
        name: 'entrance_pages',
        dimension: 'entry_page',
        title: 'Страницы входа',
        type: 'textarea',
        options: {
            descr: 'Введите точный URL либо используйте специальные символы:',
            placeholder: ['http://example.com/index.html', 'http://example.com/photos/', '@/articles/'],
            rules: [
                { mark: '*', text: 'заменяет любое количество любых символов' },
                { mark: '@', text: 'в начале — вхождение строки' },
            ],
        },
        condition: 'entry',
        children: [],
    },
    exit_pages: {
        tab: 'all',
        name: 'exit_pages',
        dimension: 'exit_page',
        title: 'Страницы выхода',
        type: 'textarea',
        options: {
            descr: 'Введите точный URL либо используйте специальные символы:',
            placeholder: ['http://example.com/index.html', 'http://example.com/photos/', '@/articles/'],
            rules: [
                { mark: '*', text: 'заменяет любое количество любых символов' },
                { mark: '@', text: 'в начале — вхождение строки' },
            ],
        },
        condition: 'entry',
        children: [],
    },
    external_url: {
        tab: 'all',
        name: 'external_url',
        dimension: 'external_url',
        title: 'Переходы по внешним ссылкам',
        type: 'textarea',
        options: {
            descr: 'Введите точный URL либо используйте специальные символы:',
            placeholder: ['http://example.com/index.html', 'http://example.com/photos/', '@/articles/'],
            rules: [
                { mark: '*', text: 'заменяет любое количество любых символов' },
                { mark: '@', text: 'в начале — вхождение строки' },
            ],
        },
        condition: 'entry',
        children: [],
    },
    page_titles: {
        tab: 'all',
        name: 'page_titles',
        dimension: 'title',
        title: 'Заголовки страниц',
        type: 'textarea',
        options: {
            descr: 'Введите точный заголовок или используйте специальные символы:',
            placeholder: ['Заголовок страницы', 'Заголовок*', '@cтраницы'],
            rules: [
                { mark: '*', text: 'заменяет любое количество любых символов' },
                { mark: '@', text: 'в начале — вхождение строки' },
            ],
        },
        condition: 'entry',
        children: [],
    },
    recirculation: {
        tab: 'all',
        name: 'recirculation',
        metric: 'avg_recirculation',
        title: 'Рециркуляция',
        type: 'period',
        children: [],
    },
    time_on_material: {
        tab: 'all',
        name: 'time_on_material',
        metric: 'avg_view_duration',
        title: 'Время на материале',
        options: {
            isTime: true,
            defaultValue: Operations.gt,
        },
        type: 'period',
        children: [],
    },
    author_media: {
        tab: 'all',
        name: 'author_media',
        dimension: 'author_name',
        title: 'Автор материала',
        type: 'textarea',
        options: {
            descr: 'Введите точное имя автора либо используйте специальные символы:',
            placeholder: ['Имя автора', 'Имя*', '@фамилия'],
            rules: [
                { mark: '*', text: 'заменяет любое количество любых символов' },
                { mark: '@', text: 'в начале — вхождение строки' },
            ],
        },
        condition: 'entry',
        children: [],
    },
    title_media: {
        tab: 'all',
        name: 'title_media',
        dimension: 'media_title',
        title: 'Название материала',
        type: 'textarea',
        options: {
            descr: 'Введите точное название материала либо используйте специальные символы:',
            placeholder: ['Название материала', 'Название*', '@материала'],
            rules: [
                { mark: '*', text: 'заменяет любое количество любых символов' },
                { mark: '@', text: 'в начале — вхождение строки' },
            ],
        },
        condition: 'entry',
        children: [],
    },
    publish_datetime: {
        tab: 'all',
        name: 'publish_datetime',
        dimension: 'published_datetime',
        title: 'Период публикации',
        type: 'dateTime',
        children: [],
    },
    publish_date: {
        tab: 'all',
        name: 'publish_date',
        dimension: 'published_date',
        title: 'Дата публикации',
        type: 'date',
        children: [],
    },
    publish_time: {
        tab: 'all',
        name: 'publish_time',
        dimension: 'published_time',
        title: 'Время публикации',
        type: 'time',
        children: [],
    },
    event_class: {
        tab: 'all',
        name: 'event_class',
        dimension: 'event_class',
        title: 'Класс события',
        type: 'checkbox',
        options: {
            isThree: false,
            isHideCount: true,
        },
        data: [
            {
                can_be_expanded: false,
                expand: false,
                id: 'base',
                level: 1,
                main: true,
                nesting: 0,
                parent_id: 'DATA_ROOT_NODE',
                title: 'Базовые события',
                with_parents_id: 'base',
            },
            {
                can_be_expanded: false,
                expand: false,
                id: 'media',
                level: 1,
                main: true,
                nesting: 0,
                parent_id: 'DATA_ROOT_NODE',
                title: 'События медиа',
                with_parents_id: 'media',
            },
            {
                can_be_expanded: false,
                expand: false,
                id: 'ec',
                level: 1,
                main: true,
                nesting: 0,
                parent_id: 'DATA_ROOT_NODE',
                title: 'Ecom события',
                with_parents_id: 'ec',
            },
            {
                can_be_expanded: false,
                expand: false,
                id: 'ub',
                level: 1,
                main: true,
                nesting: 0,
                parent_id: 'DATA_ROOT_NODE',
                title: 'События аналитики блоков',
                with_parents_id: 'ub',
            },
            {
                can_be_expanded: false,
                expand: false,
                id: 'cv',
                level: 1,
                main: true,
                nesting: 0,
                parent_id: 'DATA_ROOT_NODE',
                title: 'Собственные события',
                with_parents_id: 'cv',
            },
        ],
        condition: 'turning',
        children: [],
    },
    event_name: {
        tab: 'all',
        name: 'event_name',
        dimension: 'event_name',
        title: 'Название события',
        type: 'textarea',
        options: {
            descr: 'Введите полное название события или используйте специальные символы:',
            placeholder: ['page_view', 'page_*', '@view'],
            rules: [
                { mark: '*', text: 'заменяет любое количество любых символов' },
                { mark: '@', text: 'в начале — вхождение строки' },
            ],
        },
        condition: 'entry',
        children: [],
    },
    session_number: {
        tab: 'all',
        name: 'session_number',
        dimension: 'session_number',
        title: 'Номер визита',
        type: 'period',
        children: [],
    },
    split: {
        tab: 'all',
        name: 'split',
        dimension: 'split',
        title: 'Сплит',
        type: 'checkbox',
        options: {
            isDynamic: true,
            isThree: false,
        },
        condition: 'turning',
        children: [],
    },
    auth_top100: {
        tab: 'all',
        name: 'auth_top100',
        dimension: 'auth_top100',
        title: 'Авторизация сайта',
        type: 'checkbox',
        options: {
            isThree: false,
            isHideCount: true,
        },
        data: [
            {
                can_be_expanded: false,
                expand: false,
                id: 'auth',
                level: 1,
                main: true,
                nesting: 0,
                parent_id: 'DATA_ROOT_NODE',
                title: 'Авторизован на сайте',
                with_parents_id: 'auth',
            },
            {
                can_be_expanded: false,
                expand: false,
                id: 'not_auth',
                level: 1,
                main: true,
                nesting: 0,
                parent_id: 'DATA_ROOT_NODE',
                title: 'Не авторизован на сайте',
                with_parents_id: 'not_auth',
            },
        ],
        condition: 'turning',
        children: [],
    },
    auth_rambler: {
        tab: 'all',
        name: 'auth_rambler',
        dimension: 'auth_rambler',
        title: 'Пользователи Рамблер',
        type: 'checkbox',
        options: {
            isThree: false,
            isHideCount: true,
        },
        data: [
            {
                can_be_expanded: false,
                expand: false,
                id: 'auth',
                level: 1,
                main: true,
                nesting: 0,
                parent_id: 'DATA_ROOT_NODE',
                title: 'Авторизован по rambler_id',
                with_parents_id: 'auth',
            },
            {
                can_be_expanded: false,
                expand: false,
                id: 'not_auth',
                level: 1,
                main: true,
                nesting: 0,
                parent_id: 'DATA_ROOT_NODE',
                title: 'Не авторизован по rambler_id',
                with_parents_id: 'not_auth',
            },
        ],
        condition: 'turning',
        children: [],
    },
    app_version: {
        tab: 'all',
        name: 'app_version',
        dimension: 'app_version',
        title: 'Версия приложения',
        type: 'checkbox',
        options: {
            isDynamic: true,
            isThree: false,
        },
        condition: 'turning',
        children: [],
    },
    ub_block_list: {
        tab: 'all',
        name: 'ub_block_list',
        dimension: 'ub_block_list',
        title: 'Аналитика блоков: Список блоков',
        type: 'checkbox',
        options: {
            isDynamic: true,
            isThree: false,
        },
        condition: 'turning',
        children: [],
    },
    customer_params_key: {
        tab: 'all',
        name: 'customer_params_key',
        dimension: 'customer_params_key',
        title: 'Собственные события: Список параметров',
        type: 'checkbox',
        options: {
            isDynamic: true,
            isThree: false,
        },
        condition: 'turning',
        children: [],
    },
};
