import mediaConfig from '@configs/media';
import mediaConstants from '@constants/media';
import tableUtils from '@redux/slices/table/utils';
import { Metrics } from '@typings/metrics';
import { ReportName } from '@typings/reports';
import reportsUtilsFunc from '@utils/reports';

interface ITableItem {
    media_title?: string;
    media_url?: string;
    media_id?: string;
    author_name?: string;
    author_id?: string;
    metrics: number[];
    traffic_type?: Record<string, number>;
    published_datetime?: string;
}

const reduxUtils = () => {
    const utils = {
        prepareTableData: (
            data: ITableItem[],
            metrics: Metrics[],
            metricsTotals: number[],
            projectId: string,
            reportName: ReportName,
        ) => {
            const list = [];
            const dict = {};

            const reportsUtils = reportsUtilsFunc({
                reportsConfig: mediaConfig,
            });

            const availableDimensions = reportsUtils.getParamFromConfig(reportName, 'tableFixedDimensions', []);
            const itemTitle = reportsUtils.getParamFromConfig(reportName, 'tableTitleDimension', 'media_title');
            const itemId = reportsUtils.getParamFromConfig(reportName, 'tableIdDimension', 'media_id');
            const relativeLinkToReport = reportsUtils.getParamFromConfig(reportName, 'relativeLinkToReport');

            data.forEach((item: ITableItem) => {
                const title = item[itemTitle];
                let id = item[itemId];

                const itemMetrics = tableUtils.getMetrics(item.metrics, metrics, metricsTotals);

                const relativeTitleLink = relativeLinkToReport
                    ? utils.getMediaUrl(id, projectId, relativeLinkToReport)
                    : null;

                const dimensionsData = availableDimensions.map((dimension) => item[dimension]);

                // TODO: фикс media id для дубликатов материалов
                if (reportName === 'top') {
                    if (!item.traffic_type) {
                        const currentItem = data.filter((dataItem) => dataItem[itemId] === id && dataItem.traffic_type);
                        dimensionsData[0] = currentItem[0].traffic_type;
                    }

                    // Добавляем к id дату публикации и на случай, если дата публикации отсутствует/одинаковая,
                    // добавляем еще количество символов в media_url
                    id += (item.published_datetime || '').replace(/[:\-\s]/g, '') + item.media_url.length;
                }

                if (title && id) {
                    list.push(id);
                    dict[id] = {
                        withParentsId: id,
                        id,
                        extra: { url: item.media_url },
                        geoId: null,
                        publicStats: true,
                        parentId: mediaConstants.ROOT_NODE,
                        canBeExpanded: false,
                        expand: false,
                        title,
                        relativeTitleLink,
                        metricsData: itemMetrics,
                        dimensionsData,
                        level: 1,
                    };
                }
            });

            return {
                list,
                dict,
            };
        },

        getMediaUrl: (mediaId: string, projectId: string, path: string) => {
            if (!mediaId || mediaId.split('_').length < 2) return null;

            return `/stat/projects/${projectId}/new/media/${path}/${mediaId}`;
        },
    };

    return utils;
};

export default reduxUtils;
