import { Loader } from '@adtech/ui';
import { ChartWithLegend } from '@components/index';
import { ChartColors, ChartTypes } from '@configs/graph';
import { GroupBy } from '@configs/group';
import { DateType } from '@typings/date';
import { ISelectedLine, IGraphData, IGraphs, Totals, CurrentGraphTotals, GraphsDict } from '@typings/graph';
import { Metrics } from '@typings/metrics';
import { ReportName } from '@typings/reports';
import { graphUtils, measuresUtils } from '@utils/index';
import cn from 'classnames';

import React from 'react';

import ReportMessage from '../ReportMessage';

import s from './ReportChart.pcss';
import ReportChartGraph from './ReportChartGraph';

interface ILegendDataItem {
    title: string;
    color: string;
    id: string;
}

interface IProps {
    reportName: ReportName;
    graphTotals: Totals;
    currentGraphsTotals: CurrentGraphTotals;
    availableMetrics: Metrics[];
    metrics: Metrics[];
    graphData: IGraphData[];
    graphCustomTitle: string;
    graphs: IGraphs[];
    disabledGraphs: GraphsDict;
    period: [DateType, DateType];
    onChangeActiveChartParam: (
        activeMetricParam: Partial<Metrics>,
        activeGraphParam: Partial<ChartTypes>,
        chartOrder: number,
    ) => void;
    className: string;
    groupBy: GroupBy;
    graphRequest: boolean;
    graphRequests: number[];
    selectedLines: ISelectedLine[];
    utils: Record<string, TCallback>;
    showMetricsSwitcher?: boolean;
    isForceEmpty?: boolean;
}

interface IState {
    keyPrefix: number;
}

export default class ReportChart extends React.PureComponent<IProps, IState> {
    state = {
        // префикс для ключа графиков
        // нужен для того, чтобы принудительно
        // перерендерить графики, меняя его значение на новое
        keyPrefix: Date.now(),
    };

    componentDidUpdate(prevProps: IProps) {
        // меняем keyPrefix для того, чтобы мы
        // нормально пересчитали ширину графиков (HYDRA-5985)
        if (prevProps.graphs.length !== this.props.graphs.length) {
            this.setState({ keyPrefix: Date.now() });
        }
    }

    getLegend() {
        const { graphData, reportName, graphs, graphCustomTitle, selectedLines, utils } = this.props;
        const customLegend = utils.getParamFromConfig(reportName, 'customLegend');

        const legendData: ILegendDataItem[] = graphData.map((item) => {
            const line = selectedLines.find((elem) => elem.id === item.id);
            const colorIndex = line ? line.colorIndex : 0;
            const color = ChartColors[colorIndex];

            return {
                title: item.title,
                id: color,
                color,
            };
        });

        const type = customLegend || graphCustomTitle;
        if (type) {
            const newLegendData = [];

            switch (type) {
                case 'metrics':
                    legendData.forEach((item, i) => {
                        if (graphs[i]) {
                            newLegendData.push({
                                ...item,
                                title: measuresUtils.getTitle(graphs[i].key, reportName),
                            });
                        }
                    });

                    return newLegendData;

                default:
                    return legendData;
            }
        }

        return legendData;
    }

    renderGraph = (graph: IGraphs, i: number, metricsForGraphs: Metrics[]) => {
        const {
            period,
            graphData,
            graphTotals,
            graphs,
            reportName,
            availableMetrics,
            currentGraphsTotals,
            onChangeActiveChartParam,
            graphRequests,
            graphRequest,
            disabledGraphs,
            metrics,
            groupBy,
            utils,
            showMetricsSwitcher,
            graphCustomTitle,
        } = this.props;

        const { keyPrefix } = this.state;

        if (!graphData.length) return null;

        const activeMetric = graph.key;
        const graphType = graph.value;
        const chartClassName = cn(s.reportChartGraph, {
            [s.reportChartGraph_one]: graphs.length === 1,
            [s.reportChartGraph_two]: graphs.length === 2,
        });

        const key = activeMetric + keyPrefix + i;

        const isLoading = graphRequests.includes(i) && !graphRequest;

        return (
            <ReportChartGraph
                key={key}
                className={chartClassName}
                data={graphData}
                totals={graphTotals}
                currentGraphsTotals={currentGraphsTotals}
                availableMetrics={availableMetrics}
                activeMetric={activeMetric}
                graphType={graphType}
                period={period}
                metrics={metrics}
                metricsForGraphs={metricsForGraphs}
                groupBy={groupBy}
                onChangeActiveChartParam={onChangeActiveChartParam}
                chartOrder={i}
                reportName={reportName}
                isLoading={isLoading}
                disabledGraphs={disabledGraphs}
                utils={utils}
                showMetricsSwitcher={showMetricsSwitcher}
                graphCustomTitle={graphCustomTitle}
                graphRequest={graphRequest}
            />
        );
    };

    renderEmptyTable = (className) => <ReportMessage className={className} message="Нет данных для отображения" />;

    render() {
        const { graphs, className, graphData, graphRequest, reportName, metrics, isForceEmpty } = this.props;
        const rootClassName = cn(s.reportChart, className, {
            [s.reportChartEmpty]: isForceEmpty,
        });
        const showMessage = !graphData.length && !graphRequest;

        let newMetrics = graphUtils.getMetrics(graphs);
        if (reportName === 'summary') newMetrics = metrics;

        return (
            <>
                {isForceEmpty && this.renderEmptyTable(rootClassName)}
                {!isForceEmpty && (
                    <div className={s.root}>
                        <Loader loading={graphRequest}>
                            {showMessage && this.renderEmptyTable(rootClassName)}
                            {!showMessage && (
                                <ChartWithLegend
                                    rootClassName={rootClassName}
                                    chartClassName={s.reportChartContent}
                                    data={this.getLegend()}
                                    legendClassName={s.reportChartLegend}
                                    classNameItem={s.reportChartLegendItem}
                                    graphTypes={graphs.map((item) => item.value)}
                                >
                                    {graphs.map((item, i) => this.renderGraph(item, i, newMetrics))}
                                </ChartWithLegend>
                            )}
                        </Loader>
                    </div>
                )}
            </>
        );
    }
}
