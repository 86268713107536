import { ETemplateIcon } from '@configs/constructor/templates';
import IconAdv from '@images/svg/icons/icon-adv.svg';
import IconAge from '@images/svg/icons/icon-age.svg';
import IconBrowser from '@images/svg/icons/icon-browser.svg';
import IconClickLink from '@images/svg/icons/icon-click-link.svg';
import IconDau from '@images/svg/icons/icon-dau.svg';
import IconDevices from '@images/svg/icons/icon-devices.svg';
import IconExchange from '@images/svg/icons/icon-exchange.svg';
import IconGeo from '@images/svg/icons/icon-geo.svg';
import IconInterests from '@images/svg/icons/icon-interests.svg';
import IconMau from '@images/svg/icons/icon-mau.svg';
import IconMessengers from '@images/svg/icons/icon-messengers.svg';
import IconNews from '@images/svg/icons/icon-news.svg';
import IconOpenstat from '@images/svg/icons/icon-openstat.svg';
import IconOs from '@images/svg/icons/icon-os.svg';
import IconPageDepth from '@images/svg/icons/icon-page-depth.svg';
import IconPhone from '@images/svg/icons/icon-phone.svg';
import IconQr from '@images/svg/icons/icon-qr.svg';
import IconRecom from '@images/svg/icons/icon-recom.svg';
import IconScreen from '@images/svg/icons/icon-screen.svg';
import IconSe from '@images/svg/icons/icon-se.svg';
import IconSex from '@images/svg/icons/icon-sex.svg';
import IconSite from '@images/svg/icons/icon-site.svg';
import IconSocial from '@images/svg/icons/icon-social.svg';
import IconSources from '@images/svg/icons/icon-sources.svg';
import IconUrl from '@images/svg/icons/icon-url.svg';
import IconWau from '@images/svg/icons/icon-wau.svg';
import React from 'react';

interface IProps {
    name: ETemplateIcon;
    className: string;
}

const ReportCardIcon: React.FC<IProps> = ({ name, className }) => {
    switch (name) {
        case ETemplateIcon.click:
            return <IconClickLink className={className} />;
        case ETemplateIcon.dau:
            return <IconDau className={className} />;
        case ETemplateIcon.wau:
            return <IconWau className={className} />;
        case ETemplateIcon.mau:
            return <IconMau className={className} />;
        case ETemplateIcon.depth:
            return <IconPageDepth className={className} />;
        case ETemplateIcon.geo:
            return <IconGeo className={className} />;
        case ETemplateIcon.sex:
            return <IconSex className={className} />;
        case ETemplateIcon.age:
            return <IconAge className={className} />;
        case ETemplateIcon.interests:
            return <IconInterests className={className} />;
        case ETemplateIcon.devices:
            return <IconDevices className={className} />;
        case ETemplateIcon.browser:
            return <IconBrowser className={className} />;
        case ETemplateIcon.os:
            return <IconOs className={className} />;
        case ETemplateIcon.screen:
            return <IconScreen className={className} />;
        case ETemplateIcon.phone:
            return <IconPhone className={className} />;
        case ETemplateIcon.sources:
            return <IconSources className={className} />;
        case ETemplateIcon.adv:
            return <IconAdv className={className} />;
        case ETemplateIcon.url:
            return <IconUrl className={className} />;
        case ETemplateIcon.openstat:
            return <IconOpenstat className={className} />;
        case ETemplateIcon.se:
            return <IconSe className={className} />;
        case ETemplateIcon.news:
            return <IconNews className={className} />;
        case ETemplateIcon.exchange:
            return <IconExchange className={className} />;
        case ETemplateIcon.social:
            return <IconSocial className={className} />;
        case ETemplateIcon.messengers:
            return <IconMessengers className={className} />;
        case ETemplateIcon.qr:
            return <IconQr className={className} />;
        case ETemplateIcon.recom:
            return <IconRecom className={className} />;
        default:
            return <IconSite className={className} />;
    }
};

export default ReportCardIcon;
