import { dimensionsOrder } from '@configs/conversions';
import dimensionsValues, { dimensionEmptyValue } from '@configs/dimensionsValues';
import { GroupBy } from '@configs/group';
import {
    getTable,
    updateTable,
    getConversion,
    getConversionData,
    getConversionWidgetData,
    createConversion,
    updateConversion,
    deleteConversion,
    getConversionTable,
} from '@redux/slices/conversions/api';
import { createSlice } from '@reduxjs/toolkit';
import { IConversionData, IConversionTableDataEntry } from '@typings/conversions';
import { IConversionsSlice } from '@typings/rootSlice';
import DateUtils from '@utils/date';
import numbersUtils from '@utils/numbersUtils';

export const initialState: IConversionsSlice = {
    deleteRequest: false,
    tableRequest: false,
    tableData: [],
    totals: null,
    conversionsDict: null,
    currentConversionRequest: false,
    currentConversion: null,
    numeratorsFilters: null,
    denominatorsFilters: null,
    widgets: null,
    attributionModel: null,
    attributionWindow: null,
    errorCode: null,
    showCommonConversions: null,

    // conversion table
    limit: null,
    offset: null,
    sort: null,
    dimensions: null,
    allDimensions: [],
    totalMetrics: null,
    conversionTableTotal: null,
    conversionTableData: [],
    conversionTableRequest: false,
};

export const setConversionTableRequest = (state) => {
    state.conversionTableRequest = true;
};

export const setConversionTableData = (state, action) => {
    const payload = action.payload?.result || {};

    state.conversionTableRequest = false;
    // С бэка приходят значения аля session_traffic_type (разные для каждой модели атрибуции),
    // с фронта же мы для основных источников всегда отправляем traffic_type
    state.dimensions = (payload?.dimensions || []).map((dimension) =>
        dimension.includes('traffic_type') ? 'traffic_type' : dimension,
    );

    const dimensions = payload?.available_dimensions || [];
    const sortedDimensions = dimensionsOrder
        .filter((key) => dimensions.includes(key))
        .map((key) => dimensions.find((dim) => dim === key));

    state.allDimensions = sortedDimensions;
    state.conversionTableTotal = action.payload?.meta?.totals;

    const data = payload?.data || [];
    const availableMetrics = payload?.available_metrics || [];
    state.availableMetrics = availableMetrics;

    state.conversionTableData = data.map((item, index) => {
        const entry: IConversionTableDataEntry = {
            key: index + 1,
            events_count: numbersUtils.numberFormat(item.metrics?.[0] || 0),
            visitors: numbersUtils.numberFormat(item.metrics?.[1] || 0),
            visits: numbersUtils.numberFormat(item.metrics?.[2] || 0),
        };

        item.dimensions.forEach((dimensionValue, dimIndex) => {
            let dimensionKey = payload?.dimensions[dimIndex];

            if (dimensionKey) {
                dimensionKey = dimensionKey.includes('traffic_type') ? 'traffic_type' : dimensionKey;
                const dimensionCnf = dimensionsValues[dimensionKey];
                const convertedTitle =
                    (dimensionCnf && dimensionCnf[dimensionValue]?.title) || dimensionValue || dimensionEmptyValue;
                entry[dimensionKey] = convertedTitle;
            }
        });

        return entry;
    });

    state.totalMetrics = availableMetrics.map((metric: string, index) => ({
        [metric]: payload?.totals[index] || 0,
    }));
};

export const setTableRequest = (state: IConversionsSlice) => {
    state.tableRequest = true;
};

export const setDeleteRequest = (state: IConversionsSlice) => {
    state.deleteRequest = true;
};

export const setConversionRequest = (state: IConversionsSlice) => {
    state.currentConversionRequest = true;
};

export const setConversionDataRequest = (state: IConversionsSlice, action, isRequest = false) => {
    const { id } = action.meta?.arg || {};

    if (!state.conversionsDict) state.conversionsDict = {};
    if (!state.conversionsDict[id]) state.conversionsDict[id] = {};

    state.conversionsDict[id].request = isRequest;
};

export const setConversionWidgetDataRequest = (state: IConversionsSlice, action, isRequest = false) => {
    const { widgetName } = action.meta?.arg || {};

    if (!state.widgets) state.widgets = {};
    if (!state.widgets[widgetName]) state.widgets[widgetName] = {};

    state.widgets[widgetName].loading = isRequest;
};

export const updateTableFunc = (state: IConversionsSlice, action, isUpdate?: boolean) => {
    const { conversions, totals } = action.payload?.data || {};

    const data = conversions.map((item) => ({
        ...item,
        update_date: DateUtils.readableDay(DateUtils.getDate(item.update_date)),
    }));
    const tableData = isUpdate ? [...state.tableData, ...data] : data;

    state.tableRequest = false;
    state.tableData = tableData;
    state.totals = totals;
};

export const createConversionFunc = (state: IConversionsSlice, action) => {
    const data = action.payload?.metaInfo as IConversionData;
    const { id } = action.payload;
    const newData = { ...data, id };

    state.tableData.unshift(newData);
    state.totals += 1;
};

// Обновление конверсии в списке конверсий
export const updateConversionInList = (state: IConversionsSlice, action) => {
    const data = (action.payload?.metaInfo.data as IConversionData) || {};
    const { id } = action.payload;
    const index = state.tableData.findIndex((item) => item.id === id);

    Object.assign(state.tableData[index], data);
};

// Обновление конверсии в отчете одной конверсии
export const updateConversionFunc = (state: IConversionsSlice, action) => {
    const data = (action.payload?.metaInfo.data as IConversionData) || {};

    Object.assign(state.currentConversion, data);
};

export const setConversion = (state: IConversionsSlice, action) => {
    state.currentConversionRequest = false;
    state.currentConversion = action?.payload?.data;
};

export const setConversionFailure = (state: IConversionsSlice) => {
    state.currentConversionRequest = false;
};

export const setConversionData = (state: IConversionsSlice, action) => {
    const { id } = action?.payload?.metaInfo || {};
    const result = action?.payload?.result;
    const { numerator, denominator } = (result?.length && result[0]) || {};
    const conversionData = numerator.data?.map((item, i) => {
        const value = (item * 100) / (denominator.data[i] || 1);
        return parseFloat(value < 1 ? value.toFixed(6) : value.toFixed(2));
    });
    let conversionTotals = (numerator.totals * 100) / (denominator.totals || 1);
    conversionTotals = parseFloat(conversionTotals < 1 ? conversionTotals.toFixed(6) : conversionTotals.toFixed(2));

    if (!state.conversionsDict) state.conversionsDict = {};

    state.conversionsDict[id] = {
        goal: numerator,
        conversion: {
            data: conversionData,
            totals: conversionTotals,
        },
        request: false,
    };
};

export const setConversionWidgetData = (state: IConversionsSlice, action) => {
    const { widgetName, isBySwitchGraphType } = action?.payload?.metaInfo || {};
    const result = action?.payload?.result;

    if (!result || !Object.keys(result).length) {
        Object.assign(state.widgets[widgetName], {
            data: [],
            dataTotals: [],
            loading: false,
        });
        return;
    }

    const dimensions = action?.payload?.meta?.dimensions;
    const isTotals = ![GroupBy.fiveMinute, GroupBy.hour, GroupBy.day, GroupBy.week, GroupBy.month].includes(
        dimensions[0],
    );
    const selectedDimension = action?.meta?.arg?.data?.dimension || '';
    const dimensionFromBackend = isTotals ? dimensions[0] : dimensions[1]; // берем значение из ответа запроса
    // С бэка приходят значения аля session_traffic_type (разные для каждой модели атрибуции),
    // с фронта же мы для основных источников всегда отправляем пустую строку
    const isTrafficSources = dimensionFromBackend?.includes('traffic_type');

    const valueToPercent = (value: number) => {
        const newValue = value * 100;
        return parseFloat(newValue < 1 ? newValue.toFixed(6) : newValue.toFixed(2));
    };

    const respData = Object.keys(result)
        .sort()
        .map((key) => {
            const dimensionCnf = dimensionsValues[isTrafficSources ? 'traffic_type' : selectedDimension];
            const convertedTitle = (dimensionCnf && dimensionCnf[key]?.title) || key || dimensionEmptyValue;

            // Для тоталсов одно преобразование, для набора точек другое
            if (isTotals) {
                return [convertedTitle, valueToPercent(result[key][0])];
            }

            let values = result[key];
            values = values.map(([date, value]) => [date, valueToPercent(value)]);

            return {
                title: convertedTitle,
                data: values,
            };
        });

    let data = !isTotals ? respData : [];
    let dataTotals = isTotals ? respData : [];

    // Достаем данные из кеша при переключении типа графиков
    if (isBySwitchGraphType) {
        data = isTotals ? state.widgets[widgetName].data : data;
        dataTotals = isTotals ? dataTotals : state.widgets[widgetName].dataTotals;
    }

    if (!state.widgets) state.widgets = {};
    if (!state.widgets[widgetName]) state.widgets[widgetName] = {};

    Object.assign(state.widgets[widgetName], {
        data,
        dataTotals,
        selected: selectedDimension,
        loading: false,
    });
};

export const deleteConversionFromTable = (state: IConversionsSlice, action) => {
    state.tableData = state.tableData.filter((item) => Number(item.id) !== action.payload?.metaInfo?.id);
    state.deleteRequest = false;
    state.totals -= 1;
};

export const updateParamsFunc = (state: IConversionsSlice, action) => {
    Object.assign(state, action.payload);
};

export const updateWidgetParamsFunc = (state: IConversionsSlice, action) => {
    const { widgetName, options } = action.payload;

    if (!state.widgets) state.widgets = {};
    if (!state.widgets[widgetName]) state.widgets[widgetName] = {};

    Object.assign(state.widgets[widgetName], options);
};

const conversionsSlice = createSlice({
    name: 'conversionsSlice',
    initialState,
    reducers: {
        updateParams: (state, action) => updateParamsFunc(state, action),
        updateWidgetParams: (state, action) => updateWidgetParamsFunc(state, action),
    },
    extraReducers: (builder) => {
        builder
            .addCase(getTable.pending, (state) => setTableRequest(state))
            .addCase(getTable.fulfilled, (state, action) => updateTableFunc(state, action))

            .addCase(updateTable.pending, (state) => setTableRequest(state))
            .addCase(updateTable.fulfilled, (state, action) => updateTableFunc(state, action, true))

            .addCase(getConversion.pending, (state) => setConversionRequest(state))
            .addCase(getConversion.fulfilled, (state, action) => setConversion(state, action))
            .addCase(getConversion.rejected, (state) => setConversionFailure(state))

            .addCase(getConversionData.pending, (state, action) => setConversionDataRequest(state, action, true))
            .addCase(getConversionData.fulfilled, (state, action) => setConversionData(state, action))
            .addCase(getConversionData.rejected, (state, action) => setConversionDataRequest(state, action, false))

            .addCase(getConversionWidgetData.pending, (state, action) =>
                setConversionWidgetDataRequest(state, action, true),
            )
            .addCase(getConversionWidgetData.fulfilled, (state, action) => setConversionWidgetData(state, action))
            .addCase(getConversionWidgetData.rejected, (state, action) =>
                setConversionWidgetDataRequest(state, action, false),
            )

            .addCase(createConversion.fulfilled, (state, action) => createConversionFunc(state, action))

            .addCase(updateConversion.fulfilled, (state, action) => {
                if (action?.payload?.metaInfo.isOneConversionReport) {
                    updateConversionFunc(state, action);
                } else {
                    updateConversionInList(state, action);
                }
            })

            .addCase(deleteConversion.pending, (state) => setDeleteRequest(state))
            .addCase(deleteConversion.fulfilled, (state, action) => deleteConversionFromTable(state, action))

            .addCase(getConversionTable.pending, (state) => setConversionTableRequest(state))
            .addCase(getConversionTable.fulfilled, (state, action) => setConversionTableData(state, action));
    },
});

export const { updateParams, updateWidgetParams } = conversionsSlice.actions;

export const conversionsActions = {
    updateParams,
    updateWidgetParams,
    getTable,
    updateTable,
    getConversion,
    getConversionData,
    getConversionWidgetData,
    createConversion,
    updateConversion,
    deleteConversion,
    getConversionTable,
};

export default conversionsSlice.reducer;
