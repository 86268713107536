import React from 'react';
/* eslint-disable */
export const ImageRating = () => (
    <svg width="256" height="256" viewBox="0 0 256 256" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M48.9788 18.3143C50.63 17.3811 52.8575 17.6059 55.1803 18.9402L188.787 95.6883C193.683 98.5005 197.658 105.374 197.658 111.027L197.658 217.615C197.658 220.488 196.611 222.624 194.772 223.498L186.062 227.642L49.14 145.78C44.2995 142.886 40.4269 136.078 40.4489 130.5L40.8736 22.8954L48.9788 18.3143Z" fill="url(#paint0_linear_2055_25079)"/>
        <g opacity="0.8" filter="url(#filter0_f_2055_25079)">
        <rect width="170.171" height="97.717" rx="7.43088" transform="matrix(0.866034 0.499985 -0.866034 0.499985 96.626 112.06)" fill="url(#paint1_linear_2055_25079)"/>
        </g>
        <rect width="171.784" height="125.627" rx="7.43088" transform="matrix(0.866034 0.499985 -2.20314e-08 1 39.8643 19.1779)" fill="url(#paint2_linear_2055_25079)"/>
        <circle opacity="0.5" cx="4.17117" cy="4.17117" r="4.17117" transform="matrix(0.866034 0.499985 -2.20314e-08 1 47.2959 32.1818)" fill="white"/>
        <circle opacity="0.5" cx="4.17117" cy="4.17117" r="4.17117" transform="matrix(0.866034 0.499985 -2.20314e-08 1 59.9376 39.3671)" fill="white"/>
        <circle opacity="0.5" cx="4.17117" cy="4.17117" r="4.17117" transform="matrix(0.866034 0.499985 -2.20314e-08 1 72.584 46.7804)" fill="white"/>
        <path d="M113.437 102.852C113.917 102.015 115.291 102.805 115.775 104.195L118.202 111.162C118.394 111.713 118.76 112.229 119.183 112.543L124.452 116.464C125.53 117.265 125.956 119.045 125.17 119.462L121.392 121.464C121.036 121.653 120.902 122.168 121.046 122.795L122.573 129.446C122.904 130.887 121.796 131.359 120.77 130.214L115.227 124.026C114.857 123.614 114.429 123.378 114.067 123.388L109.176 123.521C108.214 123.547 107.093 121.953 107.283 120.828L108.216 115.279C108.291 114.833 108.153 114.253 107.846 113.727L103.981 107.1C103.21 105.778 103.643 104.511 104.715 104.954L110.111 107.185C110.537 107.361 110.906 107.267 111.098 106.933L113.437 102.852Z" fill="url(#paint3_linear_2055_25079)"/>
        <path d="M143.813 119.162C144.398 118.141 146.077 119.105 146.668 120.803L149.632 129.311C149.867 129.985 150.314 130.615 150.83 130.999L157.266 135.787C158.582 136.766 159.103 138.939 158.143 139.448L153.528 141.894C153.093 142.124 152.93 142.753 153.106 143.52L154.971 151.642C155.375 153.402 154.022 153.979 152.769 152.58L145.998 145.023C145.547 144.52 145.024 144.231 144.583 144.243L138.609 144.406C137.434 144.438 136.065 142.491 136.296 141.117L137.436 134.34C137.528 133.795 137.359 133.086 136.984 132.444L132.264 124.351C131.322 122.736 131.851 121.188 133.16 121.73L139.751 124.455C140.271 124.67 140.722 124.554 140.956 124.146L143.813 119.162Z" fill="url(#paint4_linear_2055_25079)"/>
        <g filter="url(#filter1_b_2055_25079)">
        <path d="M185.453 137.655C186.324 136.136 188.819 137.57 189.698 140.094L194.104 152.743C194.453 153.744 195.118 154.681 195.885 155.252L205.453 162.37C207.41 163.826 208.184 167.057 206.757 167.813L199.895 171.449C199.249 171.792 199.007 172.726 199.268 173.866L202.041 185.941C202.641 188.557 200.63 189.414 198.767 187.336L188.702 176.101C188.031 175.353 187.253 174.924 186.598 174.942L177.717 175.184C175.97 175.232 173.935 172.336 174.279 170.293L175.973 160.219C176.11 159.409 175.858 158.355 175.302 157.401L168.284 145.369C166.884 142.968 167.671 140.667 169.616 141.472L179.415 145.523C180.188 145.843 180.858 145.671 181.206 145.064L185.453 137.655Z" fill="url(#paint5_linear_2055_25079)"/>
        </g>
        <g filter="url(#filter2_b_2055_25079)">
        <path d="M90.8302 82.3859C93.1677 80.8711 95.1142 81.9568 95.0111 84.7179L94.2843 104.167C94.2843 107.208 93.9687 110.422 91.8285 113.498L78.8459 132.179C78.512 132.66 78.3152 133.226 78.3152 133.707L78.3152 169.635C78.3152 171.687 76.8746 174.183 75.0975 175.209L67.056 179.851L55.7968 186.351L45.8577 192.09C45.1286 192.51 44.5375 192.169 44.5375 191.327L44.5375 179.921L44.5375 160.699C44.5375 160.293 44.088 160.383 43.8804 160.831L30.9079 188.8C29.0811 192.739 25.5793 195.398 23.0733 194.748C20.5511 194.095 19.9949 190.341 21.8329 186.377L38.809 149.765C40.9262 145.193 44.3282 141.25 47.9241 139.174L50.1672 137.879L72.5273 124.97C72.6309 124.91 72.7324 124.819 72.8208 124.705L79.4005 116.28C83.7225 110.746 86.4663 103.854 86.732 97.8658L87.1331 88.8264C87.2384 86.4538 88.829 83.6828 90.8302 82.3859ZM61.4264 99.0522C64.4125 97.3282 67.2764 97.0372 69.3879 98.2431C71.4994 99.449 72.6856 102.053 72.6856 105.483C72.6856 108.912 71.4994 112.886 69.3879 116.53C67.2764 120.174 64.4125 123.189 61.4264 124.913C58.4403 126.637 55.5764 126.928 53.4649 125.723C51.3534 124.517 50.1672 121.912 50.1672 118.483C50.1672 115.054 51.3534 111.08 53.4649 107.436C55.5764 103.792 58.4403 100.776 61.4264 99.0522Z" fill="url(#paint6_linear_2055_25079)"/>
        <path d="M93.8821 104.4L93.8821 104.389L93.8825 104.379L94.6092 84.9295C94.7019 82.45 92.9539 81.475 90.8547 82.8353C89.0577 83.9999 87.6293 86.4883 87.5347 88.6189L87.1337 97.6583C86.861 103.804 84.0452 110.876 79.6097 116.556L73.03 124.981C72.8786 125.175 72.7047 125.332 72.5273 125.434L50.1672 138.343L47.9241 139.638C44.4554 141.641 41.1747 145.444 39.1338 149.851L39.1336 149.852L22.1576 186.464C20.4511 190.144 20.9675 193.629 23.3092 194.236C25.6359 194.839 28.8872 192.37 30.5832 188.714L43.5557 160.744C43.993 159.801 44.9397 159.612 44.9397 160.467L44.9397 179.689L44.9397 191.095C44.9397 191.681 45.3507 191.918 45.8577 191.625L55.7968 185.887L67.056 179.387L75.0975 174.744C76.6525 173.846 77.913 171.663 77.913 169.868L77.913 133.939C77.913 133.312 78.1697 132.573 78.6054 131.946L91.588 113.265L91.5883 113.265C93.5556 110.437 93.8821 107.441 93.8821 104.4ZM69.1042 98.7361C67.0683 97.5734 64.3066 97.8538 61.4264 99.5166C58.5462 101.179 55.7845 104.088 53.7485 107.601C51.7127 111.115 50.5694 114.945 50.5694 118.251C50.5694 121.556 51.7127 124.067 53.7485 125.229C55.7845 126.392 58.5462 126.112 61.4264 124.449C64.3066 122.786 67.0683 119.878 69.1042 116.364C71.1401 112.851 72.2834 109.02 72.2834 105.715C72.2834 102.409 71.1401 99.8988 69.1042 98.7361Z" stroke="url(#paint7_linear_2055_25079)" strokeWidth="0.92886"/>
        </g>
        <defs>
        <filter id="filter0_f_2055_25079" x="8.33859" y="106.806" width="239.322" height="144.449" filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
        <feFlood floodOpacity="0" result="BackgroundImageFix"/>
        <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape"/>
        <feGaussianBlur stdDeviation="3.71544" result="effect1_foregroundBlur_2055_25079"/>
        </filter>
        <filter id="filter1_b_2055_25079" x="163.858" y="133.318" width="47.347" height="58.8961" filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
        <feFlood floodOpacity="0" result="BackgroundImageFix"/>
        <feGaussianBlur in="BackgroundImageFix" stdDeviation="1.85772"/>
        <feComposite in2="SourceAlpha" operator="in" result="effect1_backgroundBlur_2055_25079"/>
        <feBlend mode="normal" in="SourceGraphic" in2="effect1_backgroundBlur_2055_25079" result="shape"/>
        </filter>
        <filter id="filter2_b_2055_25079" x="17.0336" y="77.9839" width="81.6968" height="120.578" filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
        <feFlood floodOpacity="0" result="BackgroundImageFix"/>
        <feGaussianBlur in="BackgroundImageFix" stdDeviation="1.85772"/>
        <feComposite in2="SourceAlpha" operator="in" result="effect1_backgroundBlur_2055_25079"/>
        <feBlend mode="normal" in="SourceGraphic" in2="effect1_backgroundBlur_2055_25079" result="shape"/>
        </filter>
        <linearGradient id="paint0_linear_2055_25079" x1="43.7744" y1="18.6074" x2="120.792" y2="154.506" gradientUnits="userSpaceOnUse">
        <stop stopColor="#F1CEFF"/>
        <stop offset="0.046875" stopColor="#D88CFA"/>
        <stop offset="0.878665" stopColor="#EA85FF"/>
        <stop offset="0.949484" stopColor="#9A38C6"/>
        <stop offset="1" stopColor="#611783"/>
        </linearGradient>
        <linearGradient id="paint1_linear_2055_25079" x1="71.1287" y1="96.5483" x2="71.1024" y2="-1.79303" gradientUnits="userSpaceOnUse">
        <stop stopColor="#434677" stopOpacity="0"/>
        <stop offset="0.508107" stopColor="#5A3788"/>
        <stop offset="1" stopColor="#712899" stopOpacity="0"/>
        </linearGradient>
        <linearGradient id="paint2_linear_2055_25079" x1="190.334" y1="1.26089" x2="24.5145" y2="142.465" gradientUnits="userSpaceOnUse">
        <stop offset="0.0614654" stopColor="#A308BC"/>
        <stop offset="1" stopColor="#006E47"/>
        </linearGradient>
        <linearGradient id="paint3_linear_2055_25079" x1="114.602" y1="100.823" x2="102.796" y2="121.27" gradientUnits="userSpaceOnUse">
        <stop stopColor="#01FFBF"/>
        <stop offset="1" stopColor="white" stopOpacity="0.67"/>
        </linearGradient>
        <linearGradient id="paint4_linear_2055_25079" x1="145.235" y1="116.684" x2="130.817" y2="141.657" gradientUnits="userSpaceOnUse">
        <stop stopColor="#01FFBF"/>
        <stop offset="1" stopColor="white" stopOpacity="0.67"/>
        </linearGradient>
        <linearGradient id="paint5_linear_2055_25079" x1="187.567" y1="133.972" x2="166.133" y2="171.097" gradientUnits="userSpaceOnUse">
        <stop stopColor="#01FFBF"/>
        <stop offset="1" stopColor="white" stopOpacity="0.67"/>
        </linearGradient>
        <linearGradient id="paint6_linear_2055_25079" x1="56.8523" y1="101.693" x2="93.2459" y2="164.731" gradientUnits="userSpaceOnUse">
        <stop stopColor="#01FFBF"/>
        <stop offset="1" stopColor="white" stopOpacity="0.67"/>
        </linearGradient>
        <linearGradient id="paint7_linear_2055_25079" x1="30.1113" y1="190.081" x2="34.1137" y2="136.685" gradientUnits="userSpaceOnUse">
        <stop stopColor="white"/>
        <stop offset="1" stopColor="#00A87D" stopOpacity="0"/>
        </linearGradient>
        </defs>
    </svg>
);
