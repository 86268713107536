import { Chart } from '@adtech/charts';
import { Loader } from '@adtech/ui';
import { ChartFormatterValues, ChartTypes } from '@configs/graph';
import { GroupBy } from '@configs/group';
import { DateType } from '@typings/date';
import { Totals, CurrentGraphTotals, IGraphData, GraphsDict } from '@typings/graph';
import { Metrics } from '@typings/metrics';
import { ReportName } from '@typings/reports';
import { graphUtils, measuresUtils, dateUtils } from '@utils/index';
import cn from 'classnames';
import isEqual from 'lodash/isEqual';
import throttle from 'lodash/throttle';
import React from 'react';

import s from './ReportChart.pcss';
import ReportChartHeader from './ReportChartHeader';

interface IProps {
    reportName: ReportName;
    data: IGraphData[];
    totals: Totals;
    metrics: Metrics[];
    metricsForGraphs: Metrics[];
    currentGraphsTotals: CurrentGraphTotals;
    availableMetrics: Metrics[];
    period: [DateType, DateType];
    colors: string[];
    onChangeActiveChartParam: (
        activeMetricParam: Partial<Metrics>,
        activeGraphParam: Partial<ChartTypes>,
        chartOrder: number,
    ) => void;
    activeMetric: Metrics;
    className: string;
    groupBy: GroupBy;
    chartOrder: number;
    graphType: ChartTypes;
    isLoading: boolean;
    disabledGraphs: GraphsDict;
    utils;
    showMetricsSwitcher?: boolean;
    graphCustomTitle?: string;
    graphRequest: boolean;
}

interface IState {
    data: [];
    chartHeight: number | null;
}

export default class ReportChartGraph extends React.Component<IProps, IState> {
    static defaultProps = {
        reportName: '',
        colors: [],
        availableMetrics: [],
        activeMetric: '',
        currentGraphsTotals: [],
        utils: {},
    };

    node: React.RefObject<HTMLDivElement | null>;

    constructor(props: IProps) {
        super(props);

        this.node = React.createRef<HTMLDivElement | null>();

        this.state = {
            data: [],
            chartHeight: null,
        };
    }

    componentDidMount() {
        const data = this.getChartData();

        this.setData(data);

        window.addEventListener('resize', throttle(this.getChartHeight));
    }

    shouldComponentUpdate(nextProps, nextState) {
        return !(
            (isEqual(this.props, nextProps) && isEqual(this.state, nextState) && this.state.data.length) ||
            nextProps.graphRequest
        );
    }

    componentDidUpdate(prevProps) {
        const { data, graphType, currentGraphsTotals, totals } = this.props;

        if (
            !isEqual(prevProps.data, data) ||
            prevProps.graphType !== graphType ||
            prevProps.currentGraphsTotals.length !== currentGraphsTotals.length ||
            prevProps.totals.length !== totals.length ||
            !this.props.graphRequest
        ) {
            const newData = this.getChartData();
            this.setData(newData);
        }
    }

    componentWillUnmount() {
        window.removeEventListener('resize', this.getChartHeight);
    }

    setData = (data) => {
        this.setState({ data });
    };

    onChangeParamType = (activeMetricParam: Metrics, activeGraphParam: Partial<ChartTypes>, chartOrder: number) => {
        const { onChangeActiveChartParam } = this.props;
        onChangeActiveChartParam(activeMetricParam, activeGraphParam, chartOrder);
    };

    getChartHeight = () => {
        if (!this.node || !document.body) return;

        if (document.body.clientWidth < 1275) {
            this.setState({ chartHeight: 217 });
        } else {
            this.setState({ chartHeight: 320 });
        }
    };

    getChartData = () => {
        const { data, totals, metricsForGraphs, currentGraphsTotals, chartOrder, activeMetric, graphType } = this.props;

        return graphUtils.getReportData(graphType, {
            data,
            totals,
            measuresForGraphs: metricsForGraphs,
            activeMeasure: activeMetric,
            currentGraphsTotals,
            chartOrder,
        });
    };

    getTitleAliases = () => {
        const { reportName, activeMetric } = this.props;

        if (reportName === 'summary') {
            return {
                summary: measuresUtils.getTitle(activeMetric, reportName) as unknown as string,
            };
        }

        return null;
    };

    render() {
        const {
            className,
            period,
            availableMetrics,
            chartOrder,
            colors,
            reportName,
            isLoading,
            metrics,
            disabledGraphs,
            activeMetric,
            graphType,
            groupBy,
            utils,
            showMetricsSwitcher,
            graphCustomTitle,
        } = this.props;

        const { chartHeight, data } = this.state;
        const rootClassName = cn(s.chart, className);

        const dropdownMetrics = reportName === 'summary' ? metrics : availableMetrics;

        const dropdownData = dropdownMetrics.map((item) => ({
            title: measuresUtils.getTitle(item, reportName),
            value: item,
        }));

        const titleAliases = this.getTitleAliases();
        const titles = measuresUtils.getTitle(activeMetric, reportName);
        const formatMetrics = measuresUtils.getFormat(activeMetric);

        const relativeHeight = (document.body && document.body.clientWidth) < 1275 ? 217 : 320;

        // Передаем неопределённую ширину в <Chart/>, чтобы график был шириной с контейнер
        const width = null;
        const height = chartHeight || relativeHeight;

        const timePeriod = dateUtils.convertPeriodToNative(period);
        const graphData = graphUtils.prepareChartData(data, timePeriod, groupBy);
        const hiddenStats = graphUtils.getHiddenStats(data);
        const hideXAxis = graphType === ChartTypes.bar;

        return (
            <div className={rootClassName} ref={this.node}>
                <Loader loading={isLoading} className={s.reportChartLoader}>
                    <ReportChartHeader
                        selectedGraph={graphType}
                        onChangeParamType={this.onChangeParamType}
                        data={dropdownData}
                        activeMetric={activeMetric}
                        chartOrder={chartOrder}
                        reportName={reportName}
                        disabledGraphs={disabledGraphs}
                        showMetricsSwitcher={showMetricsSwitcher}
                    />
                    <Chart
                        hideTooltipHead={utils.getParamFromConfig(reportName, 'isHideTooltipHead', !!graphCustomTitle)}
                        period={timePeriod}
                        metricsName={titles}
                        type={graphType}
                        data={graphData}
                        dataKey="date"
                        group={groupBy}
                        colors={colors}
                        sizes={[width, height]}
                        minValue={measuresUtils.getParamFromConfig(activeMetric, 'minValue', 0)}
                        formatterValues={formatMetrics as ChartFormatterValues}
                        titleAliases={titleAliases || null}
                        hiddenStats={hiddenStats}
                        verticalGrid={!hideXAxis}
                        withoutXAxis={hideXAxis}
                        tooltipLargeData
                        pieLabels
                    />
                </Loader>
            </div>
        );
    }
}
