import { Switch, Progress } from '@adtech/ui';
import cn from 'classnames';
import React, { useState, useEffect, useRef } from 'react';

import s from './PageUpdater.pcss';

interface IProps {
    seconds: number;
    disabled: boolean;
    run: boolean;
    isResetTimer?: boolean;
    useSwitch?: boolean;
    className?: string;
    onUpdate?: () => void;
    onSwitchChange?: (checked: boolean) => void;
}

const PageUpdater: React.FC<IProps> = ({
    seconds,
    disabled = true,
    run = false,
    isResetTimer = false,
    useSwitch = false,
    className = '',
    onUpdate,
    onSwitchChange,
}) => {
    const [isUpdate, setIsUpdate] = useState<boolean>(true);
    const timerRef = useRef(null);
    const [currentStep, setCurrentStep] = useState<number>(0);
    const delay = (seconds * 1000) / 100;

    const rootClassNames = cn(s.root, className, {
        [s.disabled]: disabled,
    });

    const runTimer = () => {
        timerRef.current = setTimeout(() => {
            setCurrentStep(currentStep + 1);
        }, delay);
    };

    const resetTimer = () => {
        clearTimeout(timerRef.current);
        setCurrentStep(0);
        if (run) runTimer();
    };

    useEffect(() => {
        if (isResetTimer || !isUpdate || disabled) resetTimer();
    }, [isResetTimer, isUpdate, disabled]);

    useEffect(() => {
        if (run && currentStep < 100 && !disabled && isUpdate) {
            runTimer();
        } else if (currentStep === 100) {
            onUpdate();
            resetTimer();
        }
        return () => clearTimeout(timerRef.current);
    }, [currentStep, run, isUpdate]);

    const handleSwitchChange = (checked: boolean) => {
        setIsUpdate(checked);
        onSwitchChange?.(checked);
    };

    return (
        <div className={rootClassNames}>
            {useSwitch && (
                <div className={s.switch}>
                    <Switch disabled={disabled} checked={isUpdate} onChange={handleSwitchChange} />
                    <div className={s.switchLabel}>Автообновление</div>
                </div>
            )}
            <div className={cn(s.progress, { [s.progressActive]: isUpdate })}>
                <Progress
                    percent={currentStep}
                    status={disabled ? 'normal' : 'active'}
                    className={s.progressBar}
                    showInfo={false}
                    type="line"
                />
            </div>
        </div>
    );
};

export default PageUpdater;
