import { Button, Chip, IconCross, IconChevronDown, Tooltip } from '@adtech/ui';
import { transformFormsValuesToFilters } from '@components/CommonFilters/utils/filtersUtils';
import cn from 'classnames';
import * as React from 'react';

import { useState } from 'react';
import { IconExclude } from '../icons';
import {
    EFilterRuleOperation,
    EFilterType,
    IFilterRuleFormValue,
    TAllFormValues,
    TDimension,
    TFilters,
    TFiltersFormCnf,
} from '../types/filters';
import s from './FilterChips.pcss';

interface IProps {
    filtersFormCnf: TFiltersFormCnf;
    allFormsValues: TAllFormValues;
    setAllFormsValues: (formsValues: TAllFormValues) => void;
    clickChipHandler: (dimension: TDimension) => void;
    onApplyFilters?: (filtersData: TFilters) => void;
}

const FilterChips: React.FC<IProps> = ({ filtersFormCnf, allFormsValues, clickChipHandler, onApplyFilters }) => {
    const [isChipsCollapse, setChipsCollapse] = useState(false);

    const chipClickHandler = (dimension: TDimension) => {
        clickChipHandler(dimension);
    };

    const chipCloseHandler = (e: React.MouseEvent<HTMLElement>, dimension: TDimension, ruleIndex: number) => {
        e.stopPropagation();
        const curFilterFormsValues = allFormsValues[dimension];
        const newFormVal = [...curFilterFormsValues].splice(ruleIndex, 1);
        // setAllFormsValues({ ...allFormsValues, [dimension]: newFormVal });
        const newAllFormsValues = { ...allFormsValues, [dimension]: newFormVal };
        onApplyFilters?.(transformFormsValuesToFilters(newAllFormsValues, filtersFormCnf));
    };

    // const transformValueToCyrillic = (value: string) => {
    // };

    const renderChip = (formRule: IFilterRuleFormValue, dimension: TDimension, index: number) => {
        const { op, value } = formRule;
        const { label, type } = filtersFormCnf[dimension];
        const isDatePeriod = type === EFilterType.datePeriod;
        const isExclude = op === EFilterRuleOperation.NOT;
        const chipClass = cn('', { [s.filterChipsChip]: isExclude });

        let delimiter = ' ';
        let opText = '';

        switch (op) {
            case EFilterRuleOperation.AND:
                delimiter = ' и ';
                break;
            case EFilterRuleOperation.OR:
                delimiter = ' или ';
                break;
            case EFilterRuleOperation.NOT:
                delimiter = ' , ';
                break;
            case EFilterRuleOperation.EQ:
                opText = 'равно ';
                break;
            case EFilterRuleOperation.NOTEQ:
                opText = 'не равно ';
                break;
            case EFilterRuleOperation.GTOE:
                opText = 'больше или равно ';
                break;
            case EFilterRuleOperation.GT:
                opText = isDatePeriod ? 'после ' : 'больше ';
                break;
            case EFilterRuleOperation.LTOE:
                opText = 'меньше или равно ';
                break;
            case EFilterRuleOperation.LT:
                opText = isDatePeriod ? 'до ' : 'меньше ';
                break;
            default:
                break;
        }

        const formattedValue = typeof value === 'string' ? value : value.join(delimiter);
        const text = `${label}: ${opText}${formattedValue}`;

        return (
            <Tooltip title={text} overlayClassName={s.filterChipsTooltip} key={index}>
                <Chip
                    className={chipClass}
                    prefixIcon={isExclude ? <IconExclude /> : null}
                    suffixIcon={
                        <Button
                            type="link"
                            size="small"
                            icon={<IconCross />}
                            onClick={(e) => chipCloseHandler(e, dimension, index)}
                        />
                    }
                    type="default"
                    onClick={() => chipClickHandler(dimension)}
                    fixedWidth
                >
                    {text}
                </Chip>
            </Tooltip>
        );
    };

    const filterChipsClass = cn(s.filterChips, { [s.filterChipsHidden]: isChipsCollapse });
    const btnCollapseText = isChipsCollapse ? 'Показать ещё' : 'Свернуть';

    return (
        <>
            <div className={filterChipsClass}>
                {Object.keys(allFormsValues).map((dimension) => {
                    const formRules = allFormsValues[dimension];
                    return formRules.map((formRule, index) => renderChip(formRule, dimension, index));
                })}
            </div>
            <Button
                className={cn(s.filterChipsCollapseBtn, { [s.filterChipsCollapseBtnUp]: !isChipsCollapse })}
                type="link"
                size="small"
                suffixIcon={<IconChevronDown />}
                onClick={() => setChipsCollapse(!isChipsCollapse)}
            >
                {btnCollapseText}
            </Button>
        </>
    );
};

export default FilterChips;
