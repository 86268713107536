import { dimensionsDict, DimensionValueType } from '@configs/dimensions';
import dimensionsValuesCnf, { dimensionEmptyValue, dimensionsEmptyValues } from '@configs/dimensionsValues';
import { ChartTypes } from '@configs/graph';
import { metricsDict } from '@configs/metrics';
import { graphConstants } from '@constants/graph';
import tableConstants from '@constants/table';
import graphUtils from '@redux/slices/graph/utils';
import { Dimensions } from '@typings/dimensions';
import { ISelectedLine, IGraphs } from '@typings/graph';
import { Metrics } from '@typings/metrics';
import { IDataTable, IDict, IPreparedTableData } from '@typings/table';
import DateUtils from '@utils/date';
import { isNumber } from '@utils/typesChecks';
import tableUtils from './utils';

interface ITableItem {
    metrics: number[];
    dimensions: string[];
    can_be_expanded?: boolean;
}

const { PARAMS_DELIMITER } = tableConstants;

const eventBasedUtils = {
    /**
     * Форматируем и возвращаем title для конструктора
     */
    getTitleConstructor: (
        dimensionValue: string,
        requestedDimension: Dimensions,
        dimensions: Dimensions[],
        parents?: string,
    ) => {
        const { format, titleDelimiters, relatedDimensions } = dimensionsDict[requestedDimension] || {};
        let title = dimensionValue;

        const cnf = dimensionsValuesCnf[requestedDimension];
        if (cnf && cnf[dimensionValue]?.title) {
            title = cnf[dimensionValue]?.title;
        }
        if (cnf && cnf.addTitle) {
            title = dimensionValue + cnf.addTitle;
        }

        if (format) {
            switch (format) {
                case 'date': {
                    title = DateUtils.readable(title);
                    break;
                }
                default:
                    break;
            }
        }

        // Для определенных дименшинов приклеиваем названия родительских узлов
        // (напр. os family + os version)
        if (relatedDimensions?.length && titleDelimiters && title && parents) {
            const parentsArr = parents.split(tableConstants.PARENTS_DELIMITER);
            const currentIndex = dimensions.indexOf(requestedDimension);
            let parts = [title];

            relatedDimensions.forEach((dimension, i) => {
                const index = dimensions.indexOf(dimension);

                // Если связанный дименшин является родительским узлом, корректируется название
                if (currentIndex < index || index === -1) return;

                const parent = parentsArr[index];
                const delimiter = titleDelimiters[i];
                parts = [...parts, delimiter, parent];
            });

            title = parts.reverse().join('');
        }

        const emptyValueTitle = dimensionsEmptyValues[requestedDimension];
        if (!title && emptyValueTitle) {
            title = emptyValueTitle;
        }

        return title || dimensionEmptyValue;
    },

    /**
     * Форматирует данные для таблицы в новой модели данных
     */
    prepareTableConstructorData: (
        result,
        dimensionsInStore: Dimensions[] = [],
        expandedNode: string = '',
        tableData?: IDataTable,
    ): IPreparedTableData => {
        const { data, dimensions, metrics, totals } = result;
        const list = [];
        const dict = {} as Record<string, IDict>;
        const [requestedDimension] = dimensions;
        const totalDimensions = dimensionsInStore.length;
        // TODO: временный фикс, будет исправлено в сл. задаче
        // prettier-ignore
        const dimensionIndex = totalDimensions ? dimensionsInStore.findIndex(
            (item) => item === requestedDimension || item === requestedDimension?.replace('_value', '_key')) : 0;
        // Уровень параметра в списке выбранных параметров
        const dimensionLevel = dimensionIndex + 1;
        // Если параметр не последний в списке выбранных, то возможно раскрытие сл.
        const canBeExpandedNextDimension = !(dimensionLevel === totalDimensions);

        data.forEach((item: ITableItem) => {
            const parentId = expandedNode;
            const parentLevel = tableData?.dict[parentId]?.level;
            // id (некоторые параметры запрашиваются по id (например: geo))
            const dimensionId = item.dimensions[1] !== undefined ? String(item.dimensions[1]) : undefined;
            // Значение (значение, ключ или цепочка ключей (param1->param2->...))
            let dimensionValue =
                item.dimensions[0] || isNumber(item.dimensions[0]) ? String(item.dimensions[0]) : undefined;
            let key = expandedNode;
            let level = dimensionLevel;
            // Значение, ключ или значение по ключу
            let type = DimensionValueType.value;

            // Если есть флаг can_be_expanded, переопределяем type
            // can_be_expanded true: ключ, если can_be_expanded false: значение по ключу
            // FIXME: временная проверка на event_name, приходит can_be_expanded (а не должно),
            // после корректировки на строне бэка, нужно будет спилить это доп. условие
            // или внести корректировки в общую логику
            if (typeof item.can_be_expanded === 'boolean' && requestedDimension !== 'event_name') {
                type = item.can_be_expanded ? DimensionValueType.key : DimensionValueType.valueByKey;
            }

            // TODO: временный фикс для значений по ключу, не определяем по флагу can_be_expanded,
            // нужно будет дораотать, скорректировать в конфиге
            if (requestedDimension.endsWith('_value')) type = DimensionValueType.valueByKey;

            // Определяем возможность раскрытия (если ключ или параметр не крайний в списке выбранных)
            const canBeExpanded = type === DimensionValueType.key || canBeExpandedNextDimension;

            // Если запрос был по id и dimensionValue не определен
            if (dimensionId && !dimensionValue) dimensionValue = dimensionEmptyValue;

            // При раскрытии узла в таблице
            if (expandedNode) {
                // Переопределяем level, если определен parentLevel
                if (parentLevel) level = parentLevel + 1;
                // Если ключ, в dimensionValue устанавливаем крайнее значение в цепочке
                if (type === DimensionValueType.key) {
                    dimensionValue = dimensionValue?.split(PARAMS_DELIMITER)?.reverse()[0] || dimensionValue;
                }
            }

            const title = eventBasedUtils.getTitleConstructor(
                dimensionValue,
                requestedDimension,
                dimensionsInStore,
                expandedNode,
            );

            const metricsData = tableUtils.getMetrics(item.metrics, metrics, totals);

            key += key ? tableConstants.PARENTS_DELIMITER + dimensionValue : dimensionValue;
            list.push(key);

            if (dict[key]) return;

            dict[key] = {
                withParentsId: key,
                id: dimensionId || dimensionValue,
                title,
                metricsData,
                parentId: parentId || tableConstants.ROOT_NODE,
                canBeExpanded,
                expand: false,
                level,
                extra: {},
                parentDimensionIndex: dimensionIndex,
                type,
            };
        });

        return {
            list,
            dict,
            totals,
        };
    },

    /**
     * Форматирует данные для передачи на клиент для графика
     */
    prepareGraphConstructorData: (response: any, selectedLines: ISelectedLine[]) => {
        const { metrics, dimensions } = response.result;
        let data;

        // Если выбрана только "Дата"
        if (dimensions.length === 1) {
            // В качестве title используем выбранную метрику как в отчете Аудитория
            // В качестве ключа нельзя использовать зарезервированное date
            const key = metrics[0];

            data = [
                {
                    data: response.result.data.map((item) => [
                        key,
                        ...graphUtils.prepareMetricsValues(item.metrics, metrics),
                    ]),
                    title: metricsDict[key].title,
                    id: dimensions[0],
                    publicStats: true,
                },
            ];
        } else {
            const preparedData = {};

            selectedLines.forEach((item) => {
                preparedData[item?.id || ''] = [];
            });

            response.result.data.forEach((item) => {
                const key = item.dimensions[0];

                // Формируем пустую точку для каждого выбранного значения дименшина
                // если с бэка пришло пустое значение для определенной даты
                if (key === graphConstants.EMPTY_GRAPH_DATA_VALUE) {
                    selectedLines.forEach((line) => {
                        preparedData[line.id].push([
                            line.id,
                            ...graphUtils.prepareMetricsValues(item.metrics, metrics),
                        ]);
                    });
                    // Пушим данные для конкретного выбранного значения дименшина
                } else if (preparedData[key]) {
                    preparedData[key].push([key, ...graphUtils.prepareMetricsValues(item.metrics, metrics)]);
                }
            });

            // Важно! id может быть undefined если с бэка приходит пустая строка
            data = selectedLines.map((item) => ({
                data: preparedData[item?.id || ''],
                title: eventBasedUtils.getTitleConstructor(item.id, dimensions[0], dimensions),
                id: item?.id || '',
                publicStats: true,
            }));
        }

        return data;
    },

    /**
     * Формируем SelectedLines и тоталсы для них
     */
    prepareSelectedLines: (dimensions: Dimensions[], tableData: IPreparedTableData) => {
        let graphCustomTitle;
        let graphTotals;
        let selectedLines;
        const { dict, list, totals } = tableData || {};

        // Если выбрана только "Дата"
        if (dimensions.length === 1) {
            graphCustomTitle = 'metrics';
            selectedLines = [
                {
                    id: 'Дата',
                    colorIndex: 0,
                },
            ];
            graphTotals = [totals];
        } else {
            selectedLines = list.map((item, index) => ({
                id: item,
                colorIndex: index,
            }));
            graphTotals = selectedLines.map((item) => [dict[item.id].metricsData[0].normal]);
        }

        return {
            graphCustomTitle,
            selectedLines,
            graphTotals,
        };
    },

    updateGraph: (metrics: Metrics[], graphs: IGraphs[], defaultGraphs: ChartTypes[]) => {
        const newGraphs = [...graphs];
        if (graphs.length) {
            newGraphs[0].key = metrics[0];
        } else {
            newGraphs.push({ key: metrics[0], value: defaultGraphs[0] });
        }

        return newGraphs;
    },
};

export default eventBasedUtils;
