import { Input, Checkbox, Modal } from '@adtech/ui';
import { formUtils } from '@utils/index';
import React, { useEffect, useState, FC } from 'react';

import s from '../SavedFilters.pcss';

enum ETemplateType {
    PRIVATE = 'private',
    SHARED = 'shared',
}

interface IProps {
    savedFiltersTitles;
    accessLevel: boolean;
    modalData: {
        actionName: string;
        data: {
            id?: number;
            title?: string;
            type?: 'private' | 'shared';
        };
    };
    isOpen;
    onOkHandler;
    onCloseHandler: () => void;
}

const CreateOrEditSavedFiltersModal: FC<IProps> = ({
    savedFiltersTitles = [],
    accessLevel,
    modalData,
    isOpen,
    onOkHandler,
    onCloseHandler,
}: IProps) => {
    const { actionName, data } = modalData || {};
    const { id, title, type } = data || {};
    const [inputTitleValue, setInputTitleValue] = useState<string>(title);
    const [inputTypeValue, setInputTypeValue] = useState(type || ETemplateType.PRIVATE);
    const [validTitle, setValidTitle] = useState({ isValid: true, message: '' });
    const titles = actionName === 'edit' ? savedFiltersTitles.filter((value) => title !== value) : savedFiltersTitles;

    useEffect(() => {
        if (isOpen) {
            setInputTitleValue(title || '');
            setInputTypeValue(type || ETemplateType.PRIVATE);
        }
    }, [isOpen]);

    if (!actionName) return null;

    const modalTitle = actionName === 'edit' ? 'Редактирование шаблона' : 'Сохранение шаблона';

    // Валидация имени шаблона фильтров, от 1 до 255 символов
    const validateTitle = (value: string) => {
        let result = {
            isValid: true,
            message: undefined,
        };

        const trimValue = value.trim();
        const validRequired = formUtils.defaultRules.required(trimValue);

        if (validRequired !== true) {
            result = {
                isValid: false,
                message: validRequired,
            };
        } else if (titles.includes(trimValue)) {
            // Проверяем введенное имя на уникальность (если редактируем шаблон, не учитываем его исходное название)
            result = {
                isValid: false,
                message: 'Шаблон с таким названием уже существует', // todo: можно будет потом перенести в formUtils
            };
        }

        setValidTitle(result);

        return result.isValid;
    };

    const onChangeType = (e) => {
        const checked = e?.target?.checked;
        setInputTypeValue(checked ? ETemplateType.SHARED : ETemplateType.PRIVATE);
    };

    const handleChangeTitle = (e) => {
        const value = e.target.value;
        validateTitle(value);
        setInputTitleValue(value);
    };

    const handleBlurTitle = (e) => {
        validateTitle(e.target.value);
    };

    const modalOkHandler = () => {
        if (!validateTitle(inputTitleValue)) return;

        onOkHandler?.(actionName, {
            title: inputTitleValue,
            type: inputTypeValue,
            id,
        });

        setInputTitleValue('');
        setInputTypeValue(ETemplateType.PRIVATE);
    };

    const modalCloseHandler = () => {
        onCloseHandler?.();
    };

    return (
        <Modal
            className={s.сreateOrEditModal}
            isOpened={isOpen}
            title={modalTitle}
            okText="Сохранить"
            cancelText="Отменить"
            onOk={modalOkHandler}
            onClose={modalCloseHandler}
            closeOnClickOutside={false}
            centered
            size="lg"
        >
            <div className={s.inner}>
                <div className={s.label}>Название шаблона</div>
                <Input
                    defaultValue={title}
                    value={inputTitleValue}
                    type="text"
                    onChange={handleChangeTitle}
                    onBlur={handleBlurTitle}
                    placeholder="Введите название шаблона"
                    status={validTitle.isValid ? null : 'error'}
                    errorText={validTitle.message || ''}
                    showCount
                    maxLength={255}
                />
                <div className={s.type}>
                    <Checkbox
                        checked={inputTypeValue === ETemplateType.SHARED}
                        disabled={!accessLevel}
                        onChange={onChangeType}
                    >
                        Публичный шаблон
                    </Checkbox>
                    <div className={s.typeDescription}>
                        Все пользователи с&nbsp;доступом к&nbsp;статистике сайта могут использовать этот шаблон для
                        создания отчётов. Его можно будет найти на&nbsp;вкладке «Сохранённые шаблоны».
                    </div>
                </div>
            </div>
        </Modal>
    );
};

export default CreateOrEditSavedFiltersModal;
