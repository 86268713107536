import { Button, Checkbox } from '@adtech/ui';
import reportsConstants from '@constants/reports';
import fieldDecorator from '@decorators/fieldDecorator';
import IconMinus from '@images/svg/icons/icon-minus.svg';
import IconPlus from '@images/svg/icons/icon-plus.svg';

import { Metrics } from '@typings/metrics';
import { listDataUtils, measuresUtils } from '@utils/index';

import * as React from 'react';

import s from './CheckboxType.pcss';

interface IProps {
    getDynamicData;
    field: Record<string, any>;
    dynamicData: Record<string, any>;
    activeItem: Record<string, any>;
    data: Record<string, any>;
}

class CheckboxType extends React.PureComponent<IProps> {
    getDynamicData = () => {
        const { dynamicData } = this.props;

        return dynamicData?.data;
    };

    getDynamicMetrics = (): string[] => {
        const { dynamicData } = this.props;

        if (!dynamicData) return [];

        return dynamicData.metrics;
    };

    /**
     * Функция получения данных
     */
    getData = () => {
        const { activeItem } = this.props;

        // если есть статический список, возвращаем его
        if (activeItem.data) {
            return activeItem.data;
        }

        // или запрашиваем динамические данные
        const data = this.getDynamicData();

        if (!data) return [];

        return listDataUtils.toFlatConfigData(data).data;
    };

    /**
     * Функция получения объекта с ключами
     */
    getMap = () => {
        const data = this.getDynamicData();

        if (!data) return [];

        return listDataUtils.toFlatConfigData(data).map;
    };

    /**
     * Определяем, выбраны ли у элемента его дети
     */
    getIndeterminate = (item: Record<string, any>) => {
        const { field } = this.props;

        const ids = field.value.map((curItem) => curItem.id);
        const data = this.getData();
        return listDataUtils.getIndeterminate(item, ids, data);
    };

    /**
     * Получение родителей элементов
     */
    getParentIdsByIds = (ids: string[]): any[] => {
        const map = this.getMap();
        const parents = listDataUtils.getParentIdsByIds(ids, map).filter((id) => id !== reportsConstants.ROOT_NODE);
        return parents;
    };

    /**
     * Событие при клике на чекбокс
     */
    onCheck = (item: Record<string, any>, isExpanded: boolean = false) => {
        const { field, activeItem, dynamicData, getDynamicData } = this.props;

        const id = item.id;
        const isDynamicLoaded = dynamicData && dynamicData.loaded.includes(id);

        const { isDynamic, isThree } = activeItem.options;

        if (item.can_be_expanded && isExpanded && isThree) {
            // получаем вложенные данные, если их нет
            if (isDynamic && !isDynamicLoaded) {
                const parents = this.getParentIdsByIds([id]);
                getDynamicData(activeItem.name, [...parents, id]);
            }
        }

        let value = field.value;
        const findCurItem = value.find((curItem) => curItem.id === item.id);

        if (!findCurItem) {
            value.push(item);
        } else if (!isExpanded || (isExpanded && isDynamicLoaded)) {
            value = value.filter((curItem) => curItem.id !== item.id && curItem.parent_id !== item.id);
        }

        this.props.field.onChange(null, value);
    };

    /**
     * Рендер элемента
     */
    renderItem = ({ item, key, index }: any) => {
        const { field, activeItem, dynamicData } = this.props;
        const { isThree, isHideCount } = activeItem.options;

        const data = this.getData();

        // Узнаем, показывать ли кнопку "Показать еще"
        const isShowMore =
            data.length === index + 1 && dynamicData && dynamicData.meta[item.parent_id].total > data.length;

        // Список детей у элемента
        const subItems = data.filter((itm) => itm.parent_id === item.id || itm.parent_id === item.with_parents_id);

        // Показываем обертку для иконки, если в списке есть вложенность на уровень выше
        // или у динамического элемента есть вложенность
        const isShowWrapIcon =
            data.filter((itm) => itm.nesting === item.nesting + 1 || (itm.can_be_expanded && isThree)).length > 0;

        // Проверяем, выбран ли элемент
        const isChecked = !!field.value.find((curItem) => String(item.id) === String(curItem.id));

        // Проверяем, есть ли выбранные дети у элемента
        const isIndeterminate = this.getIndeterminate(item);

        // Лэйбл метрики
        const dynamicMetrics = this.getDynamicMetrics();
        let metricLabel = '';
        if (item.metrics && dynamicMetrics.length) {
            metricLabel = measuresUtils.prepareValue(dynamicMetrics[0] as Metrics, item.metrics[0].normal);
        }

        const onClickIcon = () => this.onCheck(item, true);

        const Icon = () =>
            isChecked ? (
                <IconMinus className={s.itemIconSvg} onClick={onClickIcon} />
            ) : (
                <IconPlus className={s.itemIconSvg} onClick={onClickIcon} />
            );

        return (
            <div className={s.item} key={key}>
                <div className={s.itemFlex}>
                    {isShowWrapIcon && (
                        <div className={s.itemIcon}>
                            {(subItems.length || (item.can_be_expanded && isThree)) && <Icon />}
                        </div>
                    )}
                    <Checkbox
                        onChange={() => this.onCheck(item)}
                        checked={isChecked && !isIndeterminate}
                        indeterminate={isIndeterminate}
                        className={s.itemContainer}
                    >
                        <div className={s.itemTitle}>{item.title}</div>
                        {metricLabel && !isHideCount && <div className={s.itemMetrics}>{metricLabel}</div>}
                    </Checkbox>
                </div>
                {isChecked && subItems.length > 0 ? (
                    <div className={s.itemSub}>
                        {subItems.map((subItm, subKey) =>
                            this.renderItem({
                                item: subItm,
                                key: `sub-${subKey}`,
                                index: subKey,
                            }),
                        )}
                    </div>
                ) : null}
                {isShowMore && this.renderMoreButton(item)}
            </div>
        );
    };

    renderMoreButton = (item: Record<string, any>) => {
        const {
            activeItem,
            getDynamicData,
            dynamicData: { isLoading },
        } = this.props;

        // получаем данные
        const data = this.getData();

        // получаем ids родителей
        const id = item.id;
        const parents = this.getParentIdsByIds([id]);

        return (
            <Button
                disabled={isLoading}
                loading={isLoading}
                className={s.itemMore}
                type="dashed"
                size="small"
                onClick={() => getDynamicData(activeItem.name, parents, data.length)}
            >
                Показать еще
            </Button>
        );
    };

    render() {
        const data = this.getData();

        const filteredData = data.filter((item) => (item.main ? item.main : item));

        return (
            <div>
                {filteredData.map((item, key) =>
                    this.renderItem({
                        item,
                        key: `main-${key}`,
                        index: key,
                    }),
                )}
            </div>
        );
    }
}

export default fieldDecorator({
    name: 'checkboxes',
    type: 'array',
    ignoreHidden: true,
})(CheckboxType);
