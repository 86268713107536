import { Checkbox, ContextAlertMark } from '@adtech/ui';
import fieldDecorator from '@decorators/fieldDecorator';
import { IField, IMetaField } from '@typings/form';
import formUtils from '@utils/formUtils';
import React from 'react';
import s from '../FormCreateCounter.pcss';

interface IProps {
    field: IField;
    meta: IMetaField;
}

const TermsComponent: React.FC<IProps> = ({ field, meta }) => {
    const { onChange, value, name } = field || {};
    const isError = meta.invalid && meta.touched;

    return (
        <div className={s.termsAcceptedRow}>
            <div className={s.termsAccepted}>
                <Checkbox name={name} checked={value} onChange={() => onChange(null, !value)}>
                    Я принимаю условия
                </Checkbox>
                <a href="https://ads.sber.ru/legal/termsofuse" target="_blank" rel="noopener noreferrer">
                    Пользовательского соглашения
                </a>
            </div>
            {isError && <ContextAlertMark message="Вы должны принять условия" status="error" />}
        </div>
    );
};

const Terms = fieldDecorator({
    type: 'boolean',
    name: 'agree',
    rules: [{ validate: formUtils.defaultRules.isTrue, customErrorText: 'Вы должны принять условия' }],
})(TermsComponent);

export default Terms;
