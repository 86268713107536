const commonLink = '/stat/projects/{id}/new/report/constructor?dateStart=today&dateEnd=today&groupBy=five_minute';

const widgetsLinksConfig = {
    pageViews: `${commonLink}&orderBy=page_views%3Aasc&metrics=page_views%2Cvisits%2Cvisitors&dimensions=date`,
    trafficSources: `${commonLink}&orderBy=visits%3Adesc&metrics=visits%2Cpage_views%2Cvisitors&dimensions=traffic_type`,
    activePages: `${commonLink}&orderBy=page_views%3Adesc&metrics=page_views%2Cvisits%2Cvisitors&dimensions=url_no_args`,
    audience: `${commonLink}&orderBy=visitors%3Adesc&metrics=visitors%2Cpage_views%2Cvisits&dimensions=gender%2Cage_tns`,
    platforms: `${commonLink}&orderBy=visits%3Adesc&metrics=visits%2Cpage_views%2Cvisitors&dimensions=counter_type_eventbase`,
    events: `${commonLink}&orderBy=visits%3Adesc&metrics=visits%2Cpage_views%2Cvisitors&dimensions=event_class%2Cevent_name`,
    popularUtm: `${commonLink}&orderBy=visits%3Adesc&metrics=visits%2Cpage_views%2Cvisitors&dimensions=utm_campaign%2Cutm_content%2Cutm_medium%2Cutm_source%2Cutm_term`,
};

const getWidgetsLinks = (id: number) => {
    const cnf = { ...widgetsLinksConfig };

    Object.keys(cnf).forEach(key => {
        cnf[key] = cnf[key].replace(/{id}/, id);
    });

    return cnf;
};

export default getWidgetsLinks;
