/* eslint-disable max-len */
import { ChartTypes } from '@configs/graph';
import { sortOrders } from '@constants/sort';
import { IReportConfig } from '@typings/reports';

const reportConfig: IReportConfig = {
    reportTitle: 'Поисковые фразы',
    tableDataType: 'dynamic',
    tableTitle: 'Поисковая фраза, поисковая система',
    defaultSort: {
        name: 'visits',
        order: sortOrders.DESC,
    },
    numberOfGraphs: 'two',
    defaultGraphs: [
        ChartTypes.pie,
        ChartTypes.bar,
    ],
    graphDefaultMetrics: [
        'visits',
        'page_views',
    ],
    tableDefaultMetrics: [
        'visits',
        'page_views',
        'avg_view_depth',
    ],
    titleAliases: {
        system_coded: 'Не определено',
    },
    availableMetrics: [
        'visits',
        'avg_view_depth',
        'page_views',
        'visitors',
        'uid',
        'rambler_id',
        // 'bounces',
        // 'avg_industry_duration',
    ],
    isShowTotals: true,
    availableTools: {
        numberOfGraphs: true,
        periodPicker: true,
        sample: true,
        activeSample: true,
        filters: true,
        savedFilters: true,
        detail: true,
    },
    reportHint: {
        message: 'Поисковые фразы и слова, которые привели посетителей на ваш сайт. '
            + 'Также в отчёте можно увидеть, в каких именно поисковых системах был осуществлен поиск по конкретному запросу. '
            + 'Следует учитывать, что поисковые системы шифруют большую часть запросов и не передают её в открытом для считывания виде. '
            + 'В данном отчёте доступен показатель пользователи.',
    },
};

export default reportConfig;
