import { DateRangeType } from '@typings/date';

export const dateRangeConfig: Record<DateRangeType, string> = {
    today: 'Сегодня',
    yesterday: 'Вчера',
    week: 'За 7 дней',
    lastWeek: 'За прошлую неделю',
    month: 'За 30 дней',
    lastMonth: 'За прошлый месяц',
};
