import { sortOrders } from '@constants/sort';
import { IReportConfig } from '@typings/reports';

const config: Partial<IReportConfig> = {
    reportTitle: 'Конверсия',
    tableDataType: 'dynamic',
    reportHint: {
        message: '<p>В&nbsp;отчете можно увидеть детали достижения конверсии&nbsp;&mdash; ее&nbsp;динамику по&nbsp;времени, из&nbsp;каких источников, устройств и&nbsp;площадок пришли пользователи, совершившие целевое действие, а&nbsp;также демографию этих пользователей.</p>'
            + '<p>Также к&nbsp;конверсии можно применять различные Модели атрибуции&nbsp;&mdash; это метод определения источника визита, в&nbsp;котором совершилось достижение целевого действия.</p>',
        link: 'https://ads.sber.ru/help/konversii',
        isMore: false,
    },
    availableTools: {
        periodPicker: true,
        detail: true,
        sample: true,
        activeSample: true,
        attributionModel: true,
        attributionWindow: true,
    },
    defaultSort: {
        name: 'visits',
        order: sortOrders.DESC,
    },
    tableDefaultMetrics: [
        'events_count',
        'visitors',
        'visits'
    ],
    tableFixedDimensions: [
        'traffic_type',
    ],
    defaultLimit: 10,
    sampleDefault: 1,
};

export default config;
