import { Button, IconCirclePlus, IconEraser, IconInformationSign, Modal, Tooltip } from '@adtech/ui';
import * as React from 'react';
import { useState, useImperativeHandle, LegacyRef } from 'react';

import s from './CommonFilters.pcss';
import FilterChips from './components/FilterChips';
import FiltersContent from './components/FiltersContent';
import {
    TFilters,
    TFiltersListCnf,
    TFiltersFormCnf,
    TAllFormValues,
    TDimension,
    TDynamicFilterData,
} from './types/filters';
import { transformFiltersToFormsValues, transformFormsValuesToFilters } from './utils/filtersUtils';

interface IProps {
    filtersData: TFilters; // Данные фильтров
    filtersListCnf: TFiltersListCnf; // Конфиг списка, структуры фильтров
    filtersFormCnf: TFiltersFormCnf; // Конфиг фильтров, где хранятся параметры каждого фильтра
    ref: LegacyRef<any>; // FIXME
    onApply?: (filtersData: TFilters) => void;
    dynamicFilterData?: TDynamicFilterData;
    getDynamicFilterData?: (dimension: TDimension, offset?: number) => void;
    mode?: 'modal' | 'simple'; // Режим с модалкой и без
    isSearchExists?: boolean;
}

const CommonFilters: React.FC<IProps> = React.forwardRef(
    (
        {
            filtersData,
            filtersListCnf,
            filtersFormCnf,
            mode = 'modal',
            dynamicFilterData,
            getDynamicFilterData,
            isSearchExists,
            onApply,
        },
        ref,
    ) => {
        const formsValuesFromProps = transformFiltersToFormsValues(filtersData, filtersFormCnf);
        const [allFormsValues, setAllFormsValues] = useState<TAllFormValues>(formsValuesFromProps);
        const [currentKey, setCurrentKey] = useState<string>(null);
        const [isModalOpen, setIsModalOpen] = useState(false);
        const [isClickOnFilterChip, setIsClickOnFilterChip] = useState(false);

        const hint = '';

        useImperativeHandle(
            ref,
            () => ({
                getValues() {
                    return transformFormsValuesToFilters(allFormsValues, filtersFormCnf);
                },
            }),
            [allFormsValues],
        );

        const openModal = () => setIsModalOpen(true);

        const okModalHandler = () => {
            setIsModalOpen(false);
            onApply?.(transformFormsValuesToFilters(allFormsValues, filtersFormCnf));
        };

        const closeModalHandler = () => {
            setIsModalOpen(false);
            setCurrentKey(null);
            setIsClickOnFilterChip(false);
        };

        const modalFooterCustomBtn = () => (
            <Button onClick={() => setAllFormsValues({})} type="link" suffixIcon={<IconEraser />}>
                Сбросить настройки
            </Button>
        );

        const clearFiltersHandler = () => {
            setAllFormsValues({});
            onApply?.([]);
        };

        const clickFilterChipHandler = (dimension: TDimension) => {
            setCurrentKey(dimension);
            openModal();
        };

        const filterContent = () => (
            <FiltersContent
                filtersListCnf={filtersListCnf}
                filterCnf={filtersFormCnf[currentKey]}
                currentKey={currentKey}
                setActiveKey={setCurrentKey}
                allFormsValues={allFormsValues}
                setAllFormsValues={setAllFormsValues}
                dynamicFilterData={dynamicFilterData}
                getDynamicFilterData={getDynamicFilterData}
                isSearchExists={isSearchExists}
                isClickOnFilterChip={isClickOnFilterChip}
            />
        );

        return (
            <div className={s.commonFilters}>
                {mode === 'modal' ? (
                    <>
                        <div className={s.commonFiltersBtnsWrapper}>
                            <Button
                                onClick={openModal}
                                className={s.commonFiltersBtn}
                                type="link"
                                prefixIcon={<IconCirclePlus />}
                            >
                                Фильтры
                            </Button>
                            <Tooltip className={s.commonFiltersHint} title={hint}>
                                <IconInformationSign />
                            </Tooltip>
                            {formsValuesFromProps ? (
                                <Button
                                    onClick={clearFiltersHandler}
                                    className={s.commonFiltersClearBtn}
                                    type="link"
                                    prefixIcon={<IconEraser />}
                                >
                                    Сбросить
                                </Button>
                            ) : null}
                        </div>
                        <FilterChips
                            filtersFormCnf={filtersFormCnf}
                            allFormsValues={formsValuesFromProps}
                            setAllFormsValues={setAllFormsValues}
                            clickChipHandler={clickFilterChipHandler}
                            onApplyFilters={onApply}
                        />
                        <Button
                            onClick={() => {
                                setCurrentKey('gender');
                                setIsClickOnFilterChip(true);
                                setIsModalOpen(true);
                            }}
                        >
                            Открыть пол
                        </Button>
                        <Modal
                            isOpened={isModalOpen}
                            title="Фильтры по условиям"
                            okText="Применить"
                            cancelText="Отменить"
                            onOk={okModalHandler}
                            onClose={closeModalHandler}
                            footerCustomElement={modalFooterCustomBtn()}
                            closeOnClickOutside={false}
                            centered
                            size="lightbox"
                            destroyOnClose
                        >
                            {filterContent()}
                        </Modal>
                    </>
                ) : (
                    filterContent()
                )}
            </div>
        );
    },
);

export default CommonFilters;
