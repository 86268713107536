import React from 'react';
/* eslint-disable */
export const ImageLikes = () => (
    <svg width="256" height="256" viewBox="0 0 256 256" fill="none" xmlns="http://www.w3.org/2000/svg">
        <g opacity="0.2" filter="url(#filter0_f_2055_25070)">
        <rect width="127.009" height="84.2319" rx="6.12211" transform="matrix(0.866025 0.5 -0.866025 0.5 109.475 109.886)" fill="url(#paint0_linear_2055_25070)"/>
        </g>
        <rect width="163.847" height="108.662" rx="6.12211" transform="matrix(0.866025 0.5 -0.866025 0.5 104.104 66.2537)" fill="url(#paint1_linear_2055_25070)"/>
        <mask id="mask0_2055_25070" style={{maskType: 'alpha'}} maskUnits="userSpaceOnUse" x="13" y="68" width="230" height="133">
        <rect width="163.847" height="108.662" rx="6.12211" transform="matrix(0.866025 0.5 -0.866025 0.5 104.105 66.2537)" fill="url(#paint2_linear_2055_25070)"/>
        </mask>
        <g mask="url(#mask0_2055_25070)">
        <g opacity="0.25">
        <line y1="-0.284456" x2="110.938" y2="-0.284456" transform="matrix(-0.866025 0.5 -0.866025 -0.5 104.105 79.9075)" stroke="white" strokeWidth="0.568913"/>
        <line y1="-0.284456" x2="110.938" y2="-0.284456" transform="matrix(-0.866025 0.5 -0.866025 -0.5 115.93 86.7345)" stroke="white" strokeWidth="0.568913"/>
        <line y1="-0.284456" x2="110.938" y2="-0.284456" transform="matrix(-0.866025 0.5 -0.866025 -0.5 127.754 93.5614)" stroke="white" strokeWidth="0.568913"/>
        <line y1="-0.284456" x2="110.938" y2="-0.284456" transform="matrix(-0.866025 0.5 -0.866025 -0.5 139.579 100.388)" stroke="white" strokeWidth="0.568913"/>
        <line y1="-0.284456" x2="110.938" y2="-0.284456" transform="matrix(-0.866025 0.5 -0.866025 -0.5 151.404 107.215)" stroke="white" strokeWidth="0.568913"/>
        <line y1="-0.284456" x2="110.938" y2="-0.284456" transform="matrix(-0.866025 0.5 -0.866025 -0.5 163.228 114.042)" stroke="white" strokeWidth="0.568913"/>
        <line y1="-0.284456" x2="110.938" y2="-0.284456" transform="matrix(-0.866025 0.5 -0.866025 -0.5 175.053 120.869)" stroke="white" strokeWidth="0.568913"/>
        <line y1="-0.284456" x2="110.938" y2="-0.284456" transform="matrix(-0.866025 0.5 -0.866025 -0.5 186.877 127.696)" stroke="white" strokeWidth="0.568913"/>
        <line y1="-0.284456" x2="110.938" y2="-0.284456" transform="matrix(-0.866025 0.5 -0.866025 -0.5 198.702 134.523)" stroke="white" strokeWidth="0.568913"/>
        <line y1="-0.284456" x2="110.938" y2="-0.284456" transform="matrix(-0.866025 0.5 -0.866025 -0.5 210.527 141.35)" stroke="white" strokeWidth="0.568913"/>
        <line y1="-0.284456" x2="110.938" y2="-0.284456" transform="matrix(-0.866025 0.5 -0.866025 -0.5 222.352 148.177)" stroke="white" strokeWidth="0.568913"/>
        </g>
        </g>
        <g opacity="0.25">
        <line y1="-0.284456" x2="164.416" y2="-0.284456" transform="matrix(0.866025 0.5 -0.866025 0.5 104.104 66.2537)" stroke="white" strokeWidth="0.568913"/>
        <line y1="-0.284456" x2="164.416" y2="-0.284456" transform="matrix(0.866025 0.5 -0.866025 0.5 92.2797 73.0806)" stroke="white" strokeWidth="0.568913"/>
        <line y1="-0.284456" x2="164.416" y2="-0.284456" transform="matrix(0.866025 0.5 -0.866025 0.5 80.4551 79.9076)" stroke="white" strokeWidth="0.568913"/>
        <line y1="-0.284456" x2="164.416" y2="-0.284456" transform="matrix(0.866025 0.5 -0.866025 0.5 68.6305 86.7345)" stroke="white" strokeWidth="0.568913"/>
        <line y1="-0.284456" x2="164.416" y2="-0.284456" transform="matrix(0.866025 0.5 -0.866025 0.5 56.8058 93.5615)" stroke="white" strokeWidth="0.568913"/>
        <line y1="-0.284456" x2="164.416" y2="-0.284456" transform="matrix(0.866025 0.5 -0.866025 0.5 44.9812 100.388)" stroke="white" strokeWidth="0.568913"/>
        <line y1="-0.284456" x2="164.416" y2="-0.284456" transform="matrix(0.866025 0.5 -0.866025 0.5 33.1566 107.215)" stroke="white" strokeWidth="0.568913"/>
        <line y1="-0.284456" x2="164.416" y2="-0.284456" transform="matrix(0.866025 0.5 -0.866025 0.5 21.3319 114.042)" stroke="white" strokeWidth="0.568913"/>
        </g>
        <circle opacity="0.5" cx="3.41348" cy="3.41348" r="3.41348" transform="matrix(0.866025 0.5 -0.866025 0.5 104.104 69.6671)" fill="#D9D9D9"/>
        <circle opacity="0.5" cx="3.41348" cy="3.41348" r="3.41348" transform="matrix(0.866025 0.5 -0.866025 0.5 113.959 75.3563)" fill="#D9D9D9"/>
        <circle opacity="0.5" cx="3.41348" cy="3.41348" r="3.41348" transform="matrix(0.866025 0.5 -0.866025 0.5 123.812 81.0453)" fill="#D9D9D9"/>
        <g opacity="0.8" filter="url(#filter1_f_2055_25070)">
        <path d="M91.0057 150.433C87.5388 152.434 78.6842 143.355 71.9173 139.449C65.1505 135.542 57.3114 139.807 60.7782 137.806C64.2451 135.804 75.4389 125.864 82.2057 129.771C88.9726 133.678 94.4726 148.431 91.0057 150.433Z" fill="url(#paint3_linear_2055_25070)" fillOpacity="0.7"/>
        </g>
        <path d="M90.3386 97.5038C87.4638 95.844 84.9191 96.1243 83.4048 98.0258C83.3298 98.12 83.153 98.0179 83.078 97.8371C81.5636 94.187 79.019 90.9684 76.1442 89.3087C71.4601 86.6043 65.3721 89.9635 65.3721 95.875C65.3721 105.115 78.4745 120.606 81.9617 124.565C82.4932 125.169 83.1001 125.48 83.5993 125.404C86.8342 124.915 98.8551 122.471 98.8551 113.169C98.8551 107.257 95.0227 100.208 90.3386 97.5038Z" fill="url(#paint4_linear_2055_25070)"/>
        <g filter="url(#filter2_b_2055_25070)">
        <path d="M88.3954 98.3572C85.52 96.6971 82.9748 96.9368 81.4606 98.7744C81.3852 98.8659 81.2113 98.7654 81.136 98.587C79.6217 95.0009 77.0765 91.8222 74.2011 90.1621C69.517 87.4577 65.6845 89.9683 65.6845 95.7412C65.6845 104.627 77.0626 120.209 80.3929 124.579C80.9521 125.313 81.6452 125.715 82.2056 125.629C85.5383 125.12 96.912 122.729 96.912 113.77C96.912 107.998 93.0796 101.062 88.3954 98.3572Z" fill="url(#paint5_linear_2055_25070)"/>
        <path d="M81.6534 99.0293C83.1276 97.2404 85.6019 97.0093 88.3954 98.6221C92.939 101.245 96.6826 107.995 96.6826 113.638C96.6826 115.813 95.9917 117.606 94.8864 119.081C93.7816 120.555 92.2702 121.7 90.6486 122.586C87.4051 124.358 83.7438 125.081 82.083 125.334C81.5977 125.408 81.0004 125.062 80.5161 124.427C78.8564 122.249 75.1944 117.281 71.95 111.756C70.3279 108.994 68.8159 106.102 67.7106 103.357C66.6048 100.611 65.914 98.0302 65.914 95.8737C65.914 90.2306 69.6576 87.8038 74.2011 90.427C76.9946 92.0399 79.469 95.1281 80.9431 98.6193C81.1088 99.0117 81.4877 99.2304 81.6534 99.0293Z" stroke="url(#paint6_linear_2055_25070)" strokeWidth="0.529894"/>
        </g>
        <path d="M164.944 61.817C160.792 59.4201 157.117 59.8249 154.93 62.571C154.822 62.707 154.567 62.5596 154.458 62.2985C152.271 57.027 148.596 52.3788 144.445 49.9818C137.68 46.0762 128.888 50.9275 128.888 59.4647C128.888 72.8093 147.81 95.1807 152.846 100.899C153.614 101.77 154.49 102.219 155.211 102.11C159.883 101.404 177.243 97.8743 177.243 84.4405C177.243 75.9032 171.709 65.7227 164.944 61.817Z" fill="url(#paint7_linear_2055_25070)"/>
        <g filter="url(#filter3_b_2055_25070)">
        <path d="M162.138 63.0496C157.985 60.6521 154.309 60.9984 152.122 63.6522C152.014 63.7843 151.762 63.6393 151.654 63.3816C149.467 58.2026 145.791 53.6119 141.638 51.2144C134.874 47.3088 129.339 50.9346 129.339 59.2717C129.339 72.1038 145.771 94.6071 150.58 100.919C151.388 101.979 152.389 102.559 153.198 102.435C158.011 101.701 174.437 98.2467 174.437 85.3092C174.437 76.9721 168.902 66.9553 162.138 63.0496Z" fill="url(#paint8_linear_2055_25070)"/>
        <path d="M152.401 64.0204C154.53 61.4367 158.103 61.103 162.138 63.4323C168.699 67.2207 174.106 76.9682 174.106 85.1178C174.106 88.2592 173.108 90.8487 171.512 92.9782C169.916 95.1069 167.733 96.7611 165.392 98.0407C160.707 100.6 155.42 101.644 153.021 102.01C152.32 102.116 151.458 101.617 150.758 100.699C148.362 97.5533 143.073 90.3784 138.387 82.3999C136.045 78.4109 133.861 74.235 132.265 70.2704C130.668 66.304 129.67 62.5774 129.67 59.463C129.67 51.3133 135.077 47.8086 141.638 51.597C145.673 53.9263 149.246 58.3862 151.375 63.4281C151.614 63.9949 152.162 64.3108 152.401 64.0204Z" stroke="url(#paint9_linear_2055_25070)" strokeWidth="0.765264"/>
        </g>
        <path d="M151.914 101.637L151.914 148.137" stroke="white" strokeOpacity="0.4" strokeWidth="0.396804"/>
        <path d="M134.216 120.02C132.193 118.852 130.401 119.049 129.335 120.387C129.283 120.454 129.158 120.382 129.105 120.255C128.04 117.685 126.248 115.42 124.225 114.252C120.928 112.348 116.643 114.712 116.643 118.873C116.643 125.377 125.865 136.281 128.32 139.068C128.694 139.493 129.121 139.711 129.472 139.658C131.749 139.314 140.211 137.594 140.211 131.046C140.211 126.885 137.513 121.923 134.216 120.02Z" fill="url(#paint10_linear_2055_25070)"/>
        <g filter="url(#filter4_b_2055_25070)">
        <path d="M132.849 120.621C130.825 119.452 129.034 119.621 127.968 120.914C127.915 120.979 127.792 120.908 127.739 120.782C126.673 118.258 124.882 116.021 122.858 114.852C119.561 112.949 116.863 114.716 116.863 118.779C116.863 125.033 124.872 136.001 127.216 139.078C127.61 139.594 128.098 139.877 128.492 139.817C130.838 139.459 138.844 137.775 138.844 131.47C138.844 127.406 136.146 122.524 132.849 120.621Z" fill="url(#paint11_linear_2055_25070)"/>
        <path d="M128.103 121.094C129.141 119.834 130.883 119.672 132.849 120.807C136.047 122.654 138.682 127.404 138.682 131.376C138.682 132.907 138.196 134.17 137.418 135.207C136.64 136.245 135.576 137.051 134.435 137.675C132.152 138.922 129.575 139.431 128.406 139.609C128.064 139.661 127.644 139.418 127.303 138.97C126.135 137.437 123.557 133.94 121.273 130.052C120.132 128.107 119.067 126.072 118.289 124.14C117.511 122.207 117.025 120.39 117.025 118.872C117.025 114.9 119.66 113.192 122.858 115.039C124.824 116.174 126.566 118.348 127.604 120.805C127.72 121.081 127.987 121.235 128.103 121.094Z" stroke="url(#paint12_linear_2055_25070)" strokeWidth="0.372981"/>
        </g>
        <line x1="128.033" y1="139.135" x2="128.033" y2="169.564" stroke="white" strokeOpacity="0.4" strokeWidth="0.382632"/>
        <path d="M109.515 81.7887C107.885 80.8474 106.441 81.0063 105.583 82.0847C105.54 82.1382 105.44 82.0803 105.397 81.9777C104.538 79.9076 103.095 78.0822 101.465 77.1409C98.8083 75.6071 95.3555 77.5122 95.3555 80.8649C95.3555 86.1054 102.786 94.8909 104.764 97.1364C105.066 97.4786 105.41 97.655 105.693 97.6122C107.528 97.3347 114.345 95.9487 114.345 90.6731C114.345 87.3205 112.172 83.3225 109.515 81.7887Z" fill="url(#paint13_linear_2055_25070)"/>
        <g filter="url(#filter5_b_2055_25070)">
        <path d="M108.415 82.2726C106.784 81.3311 105.34 81.4671 104.481 82.5093C104.439 82.5611 104.34 82.5042 104.297 82.403C103.439 80.3692 101.995 78.5664 100.364 77.6248C97.7077 76.0911 95.5342 77.5149 95.5342 80.789C95.5342 85.8283 101.987 94.6656 103.876 97.1443C104.193 97.5605 104.586 97.7882 104.904 97.7397C106.794 97.4513 113.245 96.0949 113.245 91.0142C113.245 87.7401 111.071 83.8064 108.415 82.2726Z" fill="url(#paint14_linear_2055_25070)"/>
        <path d="M104.591 82.6539C105.427 81.6392 106.83 81.5082 108.415 82.4229C110.991 83.9107 113.115 87.7386 113.115 90.9391C113.115 92.1727 112.723 93.1896 112.096 94.0259C111.469 94.8618 110.612 95.5115 109.692 96.014C107.853 97.0191 105.776 97.4289 104.834 97.5726C104.559 97.6146 104.221 97.4183 103.946 97.0579C103.005 95.8226 100.928 93.0049 99.0876 89.8717C98.1676 88.3052 97.3101 86.6653 96.6833 85.1083C96.0561 83.5507 95.6643 82.0872 95.6643 80.8641C95.6643 77.6637 97.7875 76.2874 100.364 77.7751C101.949 78.6898 103.352 80.4413 104.188 82.4213C104.282 82.6438 104.497 82.7679 104.591 82.6539Z" stroke="url(#paint15_linear_2055_25070)" strokeWidth="0.300527"/>
        </g>
        <line x1="104.458" y1="87.8626" x2="104.458" y2="114.647" stroke="white" strokeOpacity="0.4" strokeWidth="0.382632"/>
        <defs>
        <filter id="filter0_f_2055_25070" x="20.5019" y="92.5471" width="214.992" height="140.298" filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
        <feFlood floodOpacity="0" result="BackgroundImageFix"/>
        <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape"/>
        <feGaussianBlur stdDeviation="9.5658" result="effect1_foregroundBlur_2055_25070"/>
        </filter>
        <filter id="filter1_f_2055_25070" x="54.5727" y="123.513" width="42.8591" height="32.561" filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
        <feFlood floodOpacity="0" result="BackgroundImageFix"/>
        <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape"/>
        <feGaussianBlur stdDeviation="2.67842" result="effect1_foregroundBlur_2055_25070"/>
        </filter>
        <filter id="filter2_b_2055_25070" x="63.5649" y="86.9539" width="35.4667" height="40.8061" filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
        <feFlood floodOpacity="0" result="BackgroundImageFix"/>
        <feGaussianBlur in="BackgroundImageFix" stdDeviation="1.05979"/>
        <feComposite in2="SourceAlpha" operator="in" result="effect1_backgroundBlur_2055_25070"/>
        <feBlend mode="normal" in="SourceGraphic" in2="effect1_backgroundBlur_2055_25070" result="shape"/>
        </filter>
        <filter id="filter3_b_2055_25070" x="126.278" y="46.5812" width="51.2204" height="58.9315" filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
        <feFlood floodOpacity="0" result="BackgroundImageFix"/>
        <feGaussianBlur in="BackgroundImageFix" stdDeviation="1.53053"/>
        <feComposite in2="SourceAlpha" operator="in" result="effect1_backgroundBlur_2055_25070"/>
        <feBlend mode="normal" in="SourceGraphic" in2="effect1_backgroundBlur_2055_25070" result="shape"/>
        </filter>
        <filter id="filter4_b_2055_25070" x="115.371" y="112.594" width="24.9642" height="28.7225" filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
        <feFlood floodOpacity="0" result="BackgroundImageFix"/>
        <feGaussianBlur in="BackgroundImageFix" stdDeviation="0.745961"/>
        <feComposite in2="SourceAlpha" operator="in" result="effect1_backgroundBlur_2055_25070"/>
        <feBlend mode="normal" in="SourceGraphic" in2="effect1_backgroundBlur_2055_25070" result="shape"/>
        </filter>
        <filter id="filter5_b_2055_25070" x="94.3321" y="75.8053" width="20.1147" height="23.143" filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
        <feFlood floodOpacity="0" result="BackgroundImageFix"/>
        <feGaussianBlur in="BackgroundImageFix" stdDeviation="0.601053"/>
        <feComposite in2="SourceAlpha" operator="in" result="effect1_backgroundBlur_2055_25070"/>
        <feBlend mode="normal" in="SourceGraphic" in2="effect1_backgroundBlur_2055_25070" result="shape"/>
        </filter>
        <linearGradient id="paint0_linear_2055_25070" x1="140.724" y1="0.845419" x2="28.3274" y2="106.387" gradientUnits="userSpaceOnUse">
        <stop offset="0.0614654" stopColor="#A308BC"/>
        <stop offset="1" stopColor="#006E47"/>
        </linearGradient>
        <linearGradient id="paint1_linear_2055_25070" x1="181.539" y1="1.09062" x2="36.5435" y2="137.244" gradientUnits="userSpaceOnUse">
        <stop offset="0.0614654" stopColor="#A308BC"/>
        <stop offset="1" stopColor="#006E47"/>
        </linearGradient>
        <linearGradient id="paint2_linear_2055_25070" x1="181.539" y1="1.09062" x2="36.5435" y2="137.244" gradientUnits="userSpaceOnUse">
        <stop offset="0.0614654" stopColor="#A308BC"/>
        <stop offset="1" stopColor="#006E47"/>
        </linearGradient>
        <linearGradient id="paint3_linear_2055_25070" x1="84.4718" y1="132.2" x2="78.1945" y2="143.072" gradientUnits="userSpaceOnUse">
        <stop stopColor="#01FFBF"/>
        <stop offset="1" stopColor="white" stopOpacity="0"/>
        </linearGradient>
        <linearGradient id="paint4_linear_2055_25070" x1="85.0942" y1="58.2859" x2="53.8999" y2="107.087" gradientUnits="userSpaceOnUse">
        <stop stopColor="#01FFBF"/>
        <stop offset="1" stopColor="white"/>
        </linearGradient>
        <linearGradient id="paint5_linear_2055_25070" x1="81.2983" y1="94.2596" x2="67.6633" y2="117.876" gradientUnits="userSpaceOnUse">
        <stop stopColor="#01FFBF"/>
        <stop offset="1" stopColor="white"/>
        </linearGradient>
        <linearGradient id="paint6_linear_2055_25070" x1="108.735" y1="124.178" x2="102.181" y2="98.4241" gradientUnits="userSpaceOnUse">
        <stop stopColor="white"/>
        <stop offset="1" stopColor="#3BFFCE"/>
        </linearGradient>
        <linearGradient id="paint7_linear_2055_25070" x1="157.37" y1="5.17924" x2="112.32" y2="75.6571" gradientUnits="userSpaceOnUse">
        <stop stopColor="#01FFBF"/>
        <stop offset="1" stopColor="white"/>
        </linearGradient>
        <linearGradient id="paint8_linear_2055_25070" x1="151.888" y1="57.132" x2="132.197" y2="91.2384" gradientUnits="userSpaceOnUse">
        <stop stopColor="#01FFBF"/>
        <stop offset="1" stopColor="white"/>
        </linearGradient>
        <linearGradient id="paint9_linear_2055_25070" x1="191.511" y1="100.339" x2="182.047" y2="63.1463" gradientUnits="userSpaceOnUse">
        <stop stopColor="white"/>
        <stop offset="1" stopColor="#3BFFCE"/>
        </linearGradient>
        <linearGradient id="paint10_linear_2055_25070" x1="130.525" y1="92.4153" x2="108.568" y2="126.765" gradientUnits="userSpaceOnUse">
        <stop stopColor="#01FFBF"/>
        <stop offset="1" stopColor="white"/>
        </linearGradient>
        <linearGradient id="paint11_linear_2055_25070" x1="127.854" y1="117.736" x2="118.256" y2="134.359" gradientUnits="userSpaceOnUse">
        <stop stopColor="#01FFBF"/>
        <stop offset="1" stopColor="white"/>
        </linearGradient>
        <linearGradient id="paint12_linear_2055_25070" x1="147.165" y1="138.795" x2="142.553" y2="120.668" gradientUnits="userSpaceOnUse">
        <stop stopColor="white"/>
        <stop offset="1" stopColor="#3BFFCE"/>
        </linearGradient>
        <linearGradient id="paint13_linear_2055_25070" x1="106.541" y1="59.5464" x2="88.8491" y2="87.2238" gradientUnits="userSpaceOnUse">
        <stop stopColor="#01FFBF"/>
        <stop offset="1" stopColor="white"/>
        </linearGradient>
        <linearGradient id="paint14_linear_2055_25070" x1="104.389" y1="79.9487" x2="96.6565" y2="93.3427" gradientUnits="userSpaceOnUse">
        <stop stopColor="#01FFBF"/>
        <stop offset="1" stopColor="white"/>
        </linearGradient>
        <linearGradient id="paint15_linear_2055_25070" x1="119.95" y1="96.9166" x2="116.233" y2="82.3106" gradientUnits="userSpaceOnUse">
        <stop stopColor="white"/>
        <stop offset="1" stopColor="#3BFFCE"/>
        </linearGradient>
        </defs>
    </svg>
);
