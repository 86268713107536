import mediaConfig from '@configs/media';
import tableConstants from '@constants/table';
import { useAppDispatch } from '@hooks/useStore';
import { graphActions } from '@redux/slices/graph';
import { Dimensions } from '@typings/dimensions';
import { GraphLines, SelectedLines } from '@typings/graph';
import { IMediaRequestParams, ReportNameMedia } from '@typings/media';
import { Metrics } from '@typings/metrics';
import { IReportOptionsMeta, IReportRequestParams, ReportName, ReportNameBase } from '@typings/reports';
import { IRootSlice } from '@typings/rootSlice';
import { IDataTable, IUpdateTableParams } from '@typings/table';
import globalUtilsFunc from '@utils/global';
import { reportsUtilsFunc, graphUtils } from '@utils/index';
import { useSelector } from 'react-redux';

export const useGraph = () => {
    const dispatch = useAppDispatch();

    const tableSlice = useSelector((state: IRootSlice) => state.tableSlice);
    const graphSlice = useSelector((state: IRootSlice) => state.graphSlice);

    const api = {
        updateGraphParams(
            updateParams: IUpdateTableParams,
            withoutCompare?: boolean,
            meta?: IReportOptionsMeta<ReportName>,
        ) {
            let newParams = { ...updateParams };
            const metrics = newParams.metrics || [];

            newParams = globalUtilsFunc().filterParamsForStore(newParams, graphSlice);

            // Метрики нужны при проверке параметров графиков
            const noParamsUpdate =
                !withoutCompare &&
                !graphUtils.compareParams(
                    {
                        ...graphSlice,
                        ...metrics,
                    },
                    { ...newParams, ...metrics },
                );

            if (Object.keys(newParams).length) dispatch(graphActions.updateParams({ params: newParams, meta }));

            return { newParams, noParamsUpdate };
        },

        /**
         * Получение дефолтных линий для отчёта - первые 5 элементов
         */
        getDefaultSelectedLines(list: string[]): string[] {
            const linesCount = tableConstants.DEFAULT_LINES_COUNT;
            return list.slice(0, linesCount);
        },

        getGraph(
            projectId: string,
            reportName: ReportNameBase,
            requestParams: IReportRequestParams,
            meta?: any,
            dataTable?: Partial<IDataTable>,
        ) {
            let tableData = dataTable;

            // При запросе к ручке графиков сразу после таблицы
            // стор не успевает установить значения,
            // поэтому прокидываем преобразованные данные из промиса, а если их нет,
            // то берем из стора
            if (!dataTable || !Object.keys(dataTable.dict).length) {
                tableData = { ...tableSlice.tableData };
            }

            return dispatch(
                graphActions.getReportGraph({
                    projectId,
                    reportName,
                    params: requestParams,
                    tableData,
                    meta: api.addGraphRequestId(meta),
                }),
            ).unwrap();
        },

        setGraphSummary(payload) {
            return dispatch(graphActions.setGraphSummary(payload));
        },

        /**
         * Определяем дочернюю ноду
         */
        isChildLine(line: string): boolean {
            return line.includes(tableConstants.PARENTS_DELIMITER);
        },

        /**
         * Создаем массив из дочерних выделенных нод
         */
        getChildSelectedLines(lines: SelectedLines): SelectedLines {
            return lines.filter((item) => api.isChildLine(item.id));
        },

        /**
         * Отбираем выбранные ноды, которые есть в загруженных данных
         */
        updateSelectedLines(lines: SelectedLines, list: string[]): SelectedLines {
            return lines.filter((item) => {
                if (api.isChildLine(item.id)) {
                    const parent = item.id.split(tableConstants.PARENTS_DELIMITER)[0];
                    return list.includes(parent);
                }
                return list.includes(item.id);
            });
        },

        /**
         * Собираем словарь для запросов выделенных нод с учётом уровней вложенности
         * нода 'a-:-b-:-c' будет записана так:
         * { 1: ['a'], 2: ['a-:-b'] }, последний элемент не записываем,
         * т.к. его не нужно запрашивать
         */
        getSelectedLinesDict(childSelectedLines: SelectedLines): Map<number, string[]> {
            const delimiter = tableConstants.PARENTS_DELIMITER;
            const linesDict = new Map<number, string[]>();

            childSelectedLines.forEach((item) => {
                // Разбиваем дочернюю ноду на массив,
                // удаляем последний элемент - он не нужен для запроса
                // 'a-:-b-:-c' => [a, b]
                const arr = item.id.split(delimiter);
                const nodeArr = arr.slice(0, arr.length - 1);

                // Записываем каждый элемент дочерней ноды в словарь
                // для запроса соответствующего уровня
                nodeArr.forEach((node, ind) => {
                    // для запроса 1-го уровня оставляем 1-ый элемент массива - 'a'
                    // для последующих уровней формируем запись с разделителем 'a-:-b'
                    const currentNode = nodeArr.slice(0, ind + 1).join(delimiter);

                    if (!linesDict.has(ind + 1)) {
                        linesDict.set(ind + 1, []);
                    }

                    if (!linesDict.get(ind + 1).includes(currentNode)) {
                        linesDict.get(ind + 1).push(currentNode);
                    }
                });
            });

            return linesDict;
        },

        /**
         * Удаляем линию с графика
         */
        removeLines(lines: GraphLines): void {
            dispatch(graphActions.removeLines(lines));
        },

        /**
         * Добавляем удаленные линии
         */
        recoveryRemovedLines(lines: GraphLines): void {
            dispatch(graphActions.recoveryRemovedLines(lines));
        },

        /**
         * Сменяем параметр дефолтного использования линий
         * @param useDefaultLines
         */
        toggleUseDefaultLines(useDefaultLines: boolean): void {
            dispatch(graphActions.toggleUseDefaultLines(useDefaultLines));
        },

        resetGraph(loading?: boolean): void {
            dispatch(graphActions.resetGraph(loading));
        },

        clearGraphs(): void {
            dispatch(graphActions.updateParams({ params: { selectedLines: [] } }));
            dispatch(graphActions.resetGraph(false));
        },

        setRequestGraph(): void {
            dispatch(graphActions.setRequestGraph(null));
        },

        resetRequestGraph(): void {
            dispatch(graphActions.resetRequestGraph());
        },

        resetParam(param: string): void {
            dispatch(graphActions.resetParam(param));
        },

        /**
         * Добавляем к мета информации id запроса для отсеивания результатов старых запросов
         */
        addGraphRequestId(meta: any): Record<string, any> {
            const lastRequestId = api.increaseGraphRequestId();

            return { ...meta, lastRequestId };
        },

        increaseGraphRequestId(): number {
            let { lastRequestId } = graphSlice;
            lastRequestId += 1;

            api.updateGraphParams({ lastRequestId });

            return lastRequestId;
        },
    };

    return {
        api,
        graphUtils,
    };
};

export const useGraphMedia = () => {
    const { api: baseGraphApi } = useGraph();

    const reportsUtils = reportsUtilsFunc({
        reportsConfig: mediaConfig,
    });

    const dispatch = useAppDispatch();
    const tableSlice = useSelector((state: IRootSlice) => state.tableSlice);

    // Определяем новые и переопределяем базовые методы
    const api = {
        ...baseGraphApi,

        /**
         * Запрашиваем данные для графиков
         */
        getGraph(
            projectId: string,
            reportName: ReportNameMedia,
            requestParams: IMediaRequestParams<Metrics | Dimensions>,
            meta?: any,
            dataTable?: Partial<IDataTable>,
        ): void {
            const apiExtraPath = reportsUtils.getParamFromConfig(reportName, 'apiExtraPath', '');
            const newParams = {
                date_start: requestParams.date_start,
                date_end: requestParams.date_end,
                group: requestParams.group,
                sort: requestParams.sort,
                metrics: requestParams.metrics,
                need_all_total: requestParams.need_all_total,
                selected_lines: requestParams.selected_lines,
                dimension_filters: requestParams.dimension_filters,
            };

            let tableData = dataTable;

            // При запросе к ручке графиков сразу после таблицы
            // стор не успевает установить значения,
            // поэтому прокидываем преобразованные данные из промиса, а если их нет,
            // то берем из стора
            if (!dataTable || !Object.keys(dataTable.dict).length) {
                tableData = { ...tableSlice.tableData };
            }

            dispatch(
                graphActions.getMediaGraph({
                    projectId,
                    reportName,
                    apiExtraPath,
                    params: newParams,
                    tableData,
                    meta,
                }),
            );
        },
    };

    return {
        api,
        graphUtils,
    };
};
