import Dropdown from '@components/Dropdown';
import attributesData from '@configs/attributesData';
import { ReportName } from '@typings/reports';
import React from 'react';

const data = [
    {
        title: '1 график',
        value: 'one',
    },
    {
        title: '2 графика',
        value: 'two',
    },
];

interface IProps {
    className?: string;
    onChangeGraphNumber;
    selected: number;
    isDisabled: boolean;
    reportName: ReportName;
}

const NumberOfGraphs: React.FC<IProps> = ({
    className = '',
    onChangeGraphNumber,
    selected,
    isDisabled,
    reportName,
}) => {
    if (!selected) return null;

    const reportTools = attributesData.newreport.tools;
    const dataAttrAnchor = [reportTools.attrName, reportTools.graphCount];
    const dataAttrList = [reportTools.attrName, reportTools.setGraphCount];

    return (
        <Dropdown
            type="selectSingle"
            data={data}
            anchorClassName={className}
            dataInitialSelected={data[selected - 1].value}
            isDisabled={isDisabled}
            onApply={onChangeGraphNumber}
            reportName={reportName}
            dataAttrName={attributesData.newreport.attrName}
            dataAttrAnchor={dataAttrAnchor}
            dataAttrList={dataAttrList}
            dataTestId="number-of-graphs"
        />
    );
};

export default NumberOfGraphs;
