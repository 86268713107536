import DeleteTemplate from '@components/TemplateForms/DeleteTemplate';
import React from 'react';

import s from '../ModalPopup.pcss';

interface IProps {
    deleteTemplate: () => void;
    closePopup: () => void;
}

export default {
    getContent(props: IProps) {
        const popupContent = <DeleteTemplate deleteTemplate={props.deleteTemplate} closePopup={props.closePopup} />;

        return {
            popupClassName: s.deleteTemplate,
            width: 'md',
            title: 'Удалить отчёт',
            content: popupContent,
        };
    },
};
