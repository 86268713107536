import { Button, IconPlus } from '@adtech/ui';
import usePostClick from '@hooks/usePostClick';
import React, { FC } from 'react';
import s from '../../PostClick.pcss';

const CounterCreate: FC = () => {
    const api = usePostClick();

    const handleCreateCounter = () => {
        api.createCounter();
    };

    return (
        <div className={s.createCounter}>
            <p className={s.createCounterText}>
                Создайте счётчик Яндекс Метрики и укажите его номер в Яндекс Директе для привязки целей.
                <br />
                Счётчик будет собирать события только по выбранным вами целям.
            </p>
            <Button onClick={handleCreateCounter} className={s.createCounterBtn} prefixIcon={<IconPlus />}>
                Создать счётчик
            </Button>
        </div>
    );
};

export default CounterCreate;
