import { ChipButton, IconCross, Button, IconPlus } from '@adtech/ui';
import fieldDecorator from '@decorators/fieldDecorator';
import { IField, IMetaField } from '@typings/form';
import { listDataUtils } from '@utils/index';
import cn from 'classnames';
import flattenDeep from 'lodash/flattenDeep';
import React from 'react';
import Wrapper from '../Wrapper';
import Dropdown from './Dropdown';

import s from './ListData.pcss';

interface IProps {
    isLoadedConfig: boolean;
    config: Record<string, any>;
    name: string;
    label: string;
    map: Record<string, any>;
    data: any[];
    mode: 'three' | 'flat';
    meta: IMetaField;
    field: IField;
    isRequired: boolean;
    disabled: boolean;
    smallTitle?: boolean;
}

interface IState {
    isOpened: boolean;
}

export class ListData extends React.Component<IProps, IState> {
    state = {
        isOpened: false,
    };

    /**
     * Закрываем дропдаун
     */
    handleClose = () => {
        this.props.field.onBlur();
        this.setState({ isOpened: false });
    };

    /**
     * Открываем дрондаун
     */
    handleOpen = () => {
        this.setState({ isOpened: true }, () =>
            setTimeout(() => {
                this.props.field.onClick();
                this.props.field.onFocus();
            }, 500),
        );
    };

    /**
     * Применяем новые выбранные чекбоксы
     */
    handleApply = async (values: string[]) => {
        // это нужно, чтобы сначала изменились данные,
        // а затем закрылся <Dropdown />
        await this.changeValues(values);
        this.handleClose();
    };

    /**
     * Меняем количество выбранных элементов (прилетает новый список)
     */
    changeValues = (values: string[]) => {
        this.props.field.onClick();
        this.props.field.onChange(null, values);

        return Promise.resolve();
    };

    /**
     * Получение лиан элемента
     */
    getLians = (item: Record<string, unknown>) => {
        const { data } = this.props;
        const lians = listDataUtils.getLians(item, data);
        return lians;
    };

    /**
     * Получение родителей элементов
     */
    getParentIdsByIds = (ids: number[]) => {
        const { map } = this.props;
        const parents = listDataUtils.getParentIdsByIds(ids, map);
        return parents;
    };

    /**
     * Удаляем выбранный элемент
     */
    removeSelectedItem = (values?: string[]) => {
        const { getLians, getParentIdsByIds, changeValues } = this;
        const { map, field } = this.props;
        const selectedValues = field.value as string[];

        // получаем удаляемые элементы, путем сравнения разницы двух массивов
        const removedValues = selectedValues.filter((id) => !values.includes(id));

        // получаем лианы и родителей всех элементов (в том числе лиан)
        const liansAndParents = flattenDeep(
            removedValues.map((id) => {
                const item = map[id];
                const lians = getLians(item);
                const parents = getParentIdsByIds([item.id, ...lians]);

                return [...lians, ...parents];
            }),
        );

        // удаляем элементы, их лианы и их родителей
        const resSelectedValues = selectedValues.filter(
            (id) => !removedValues.includes(id) && !liansAndParents.includes(id),
        );

        return changeValues(resSelectedValues);
    };

    removeRemainingElements(id: string) {
        const { disabled, field } = this.props || {};
        const removeFunction = disabled ? undefined : this.removeSelectedItem;
        if (removeFunction) {
            if (Array.isArray(field.value)) {
                removeFunction(field.value.filter((elem) => elem !== id));
            } else if (field.value === id) {
                removeFunction();
            }
        }
    }

    removeElement(e: React.MouseEvent<HTMLDivElement>) {
        const target = e.target as Element;
        if (target.closest('svg')) {
            const span = target.closest('span');
            const id = span?.dataset?.id;
            if (id) {
                this.removeRemainingElements(id);
            }
        }
    }

    renderButtonAnchor = () => {
        const { config } = this.props;
        return (
            <Button onClick={() => this.handleOpen()} prefixIcon={<IconPlus />} type="dashed">
                {config.labelButton}
            </Button>
        );
    };

    renderAction() {
        const {
            config,
            isLoadedConfig,
            meta: { touched, focused, invalid },
            disabled,
        } = this.props;

        const isErrorClass = invalid && touched && !focused;

        if (disabled) {
            return null;
        }

        return (
            <div className={s.action}>
                {this.renderButtonAnchor()}
                {isLoadedConfig && (
                    <div className={cn(s.actionLabel, { [s.error]: isErrorClass })}>{config.labelSelected}</div>
                )}
            </div>
        );
    }

    renderSelectedItem = (id: number, key: number) => {
        const { map } = this.props;
        const currentItem = map[id];

        const title = [];

        const pushParentTitles = (item: Record<string, any>) => {
            if (item.parent_id) {
                const parentItem = map[item.parent_id];
                if (parentItem) title.push(parentItem.title);

                pushParentTitles(parentItem);
            }
        };

        pushParentTitles(currentItem);

        title.reverse();
        title.push(currentItem.title);

        return (
            <ChipButton
                key={key}
                className={s.chipButton}
                fixedWidth
                icon={<IconCross className={s.removeIcon} />}
                data-id={id}
                checked={false}
            >
                {`${title.join(' / ')}`}
            </ChipButton>
        );
    };

    renderSelected() {
        const { field, isLoadedConfig, map } = this.props;

        if (!field.value?.length || !isLoadedConfig) {
            return null;
        }

        const listSelected = listDataUtils.filterOnlySubItems(field.value, map);
        return (
            <div className={s.listSelected} onClick={this.removeElement.bind(this)}>
                {listSelected.map((id, key) => this.renderSelectedItem(id, key))}
            </div>
        );
    }

    render() {
        const { config, smallTitle, isRequired } = this.props;
        return (
            <Wrapper
                subWrap
                fixPadding
                title={config.title}
                description={config.description}
                smallTitle={smallTitle}
                isRequired={isRequired}
            >
                {this.renderAction()}
                {this.renderSelected()}
                <Dropdown
                    {...this.props}
                    isOpened={this.state.isOpened}
                    onClose={this.handleClose}
                    onOpen={this.handleOpen}
                    onApply={this.handleApply}
                    getLians={this.getLians}
                    getParentIdsByIds={this.getParentIdsByIds}
                />
            </Wrapper>
        );
    }
}

export default fieldDecorator({
    type: 'array',
})(ListData);
