import { IQuery } from '@typings/query';
import { urlUtils } from '@utils/index';
import { useLocation } from 'react-router-dom';

const useDesignForTD = () => {
    const { search } = useLocation();
    const { td }: IQuery = urlUtils.parseQuery(search);
    return !!td;
};

export default useDesignForTD;
