import { dateRangeConfig } from '@configs/date';
import { DateRangeType, DateLiteral } from '@typings/date';
import DateUtils from '@utils/date';
import { Dayjs } from 'dayjs';

export interface IDateRangeConfig {
    title: string;
    literal?: DateLiteral;
    startDate: () => Dayjs;
    endDate: () => Dayjs;
}

export const getDateRangeConfig = (nowDateDiff: number = 0) => {
    const now = DateUtils.getDate('today');
    const isNowDateDiff = nowDateDiff !== 0;

    const result: Record<DateRangeType, IDateRangeConfig> = {
        today: {
            literal: 'today',
            title: dateRangeConfig.today,
            startDate: () => now.startOf('day'),
            endDate: () => now.startOf('day'),
        },
        yesterday: {
            literal: 'yesterday',
            title: dateRangeConfig.yesterday,
            startDate() {
                return now.add(-1, 'day').startOf('day');
            },
            endDate() {
                return now.add(-1, 'day').startOf('day');
            },
        },
        week: {
            ...(!isNowDateDiff ? { literal: '7days' } : {}),
            title: dateRangeConfig.week,
            startDate() {
                return now.add(-6 + nowDateDiff, 'day').startOf('day');
            },
            endDate: () => now.add(nowDateDiff, 'day').startOf('day'),
        },
        lastWeek: {
            title: dateRangeConfig.lastWeek,
            startDate() {
                return now.startOf('week').add(-1, 'day').startOf('week').startOf('day');
            },
            endDate() {
                return now.startOf('week').add(-1, 'day').startOf('day');
            },
        },
        month: {
            ...(!isNowDateDiff ? { literal: '30days' } : {}),
            title: dateRangeConfig.month,
            startDate() {
                return now.add(-29 + nowDateDiff, 'day').startOf('day');
            },
            endDate: () => now.add(nowDateDiff, 'day').startOf('day'),
        },
        lastMonth: {
            title: dateRangeConfig.lastMonth,
            startDate() {
                return now.startOf('month').add(-1, 'day').startOf('month').startOf('day');
            },
            endDate() {
                return now.startOf('month').add(-1, 'day').startOf('day');
            },
        },
    };

    if (isNowDateDiff) delete result.today;

    return result;
};
