import MediaAuthor from '@containers/MediaAuthor';
import MediaAuthorsSummary from '@containers/MediaAuthorsSummary';
import MediaMaterial from '@containers/MediaMaterial';
import MediaMaterialsSummary from '@containers/MediaMaterialsSummary';
import MediaTop from '@containers/MediaTop';
import { IMediaContainer } from '@typings/media';
import React from 'react';

const containersConfig: Record<string, React.FC<IMediaContainer>> = {};

containersConfig.top = MediaTop;
containersConfig.materials_summary = MediaMaterialsSummary;
containersConfig.material = MediaMaterial;
containersConfig.authors_summary = MediaAuthorsSummary;
containersConfig.author = MediaAuthor;

export default containersConfig;
