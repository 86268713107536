import {
    getFunnelTable,
    getFunnelsList,
    getFunnel,
    createFunnel,
    updateFunnel,
    deleteFunnel,
} from '@redux/slices/funnels/api';
import { createSlice } from '@reduxjs/toolkit';
import { IFunnelTableDataResponse } from '@typings/funnels';
import { Metrics } from '@typings/metrics';
import { IFunnelsSlice } from '@typings/rootSlice';

export const initialState: IFunnelsSlice = {
    currentFunnelId: null,
    funnelsListRequest: false,
    funnelRequest: false,
    tableRequest: false,
    tableData: [],
    graphDataMaxVal: [],
    metrics: [],
    allMetrics: [],
    funnelList: [],
    funnel: null,
    errorCode: null,
};

export const setRequestTable = (state: IFunnelsSlice, isFailure?: boolean) => {
    state.tableRequest = isFailure || true;
};

export const setFunnelsListRequest = (state: IFunnelsSlice, isFailure?: boolean) => {
    state.funnelsListRequest = isFailure || true;
};

export const setFunnelRequest = (state: IFunnelsSlice, isFailure?: boolean) => {
    state.funnelRequest = isFailure || true;
};

export const setFunnelTableData = (state: IFunnelsSlice, action) => {
    const {
        data,
        metrics,
        all_metrics,
    }: {
        data: IFunnelTableDataResponse[];
        metrics: Metrics[];
        all_metrics: Metrics[];
    } = action?.payload?.result || {};
    let visitorsMax = 0;
    let visitorsSumMax = 0;

    const correctLimits = (value) => (Number.isFinite(value) ? value : null);

    const dataTransformed = data.map(({ step_number, step_title, metrics: metricsData, graph_metrics }, i) => {
        const metricsWithNotPrevStep = []; // Метрики с учетом значений "Не с предыдущего шага"

        const visitorsFirstStep = data[0].graph_metrics[0]; // посетители 1 шага
        const visitorsSumFirstStep = data[0].graph_metrics[0] + data[0].graph_metrics[1]; // посетители 1 шага в сумме

        let visitorsPrevStep = null;
        let visitorsSumPrevStep = null;

        if (i > 0) {
            visitorsPrevStep = data[i - 1].graph_metrics[0]; // посетители предыдущего шага
            visitorsSumPrevStep = data[i - 1].graph_metrics[0] + data[i - 1].graph_metrics[1]; // посетители предыдущего шага в сумме
        }

        const visitors = graph_metrics[0]; // посетители = посетители с пред. шага + не с предыдущего шага
        const visitorsSum = graph_metrics[0] + graph_metrics[1]; // посетители = посетители с пред. шага + не с предыдущего шага
        if (visitors > visitorsMax) visitorsMax = visitors;
        if (visitorsSum > visitorsSumMax) visitorsSumMax = visitorsSum;

        const convFirstStep = correctLimits((visitors / visitorsFirstStep) * 100);
        const convFirstStepSum = correctLimits((visitorsSum / visitorsSumFirstStep) * 100); // конверсия от первого шага с учетом значений не с пред. шага

        let convPrevStep = null;
        let convPrevStepSum = null;

        if (visitorsPrevStep !== null && visitorsSumPrevStep !== null) {
            convPrevStep = correctLimits((visitors / visitorsPrevStep) * 100);
            convPrevStepSum = correctLimits((visitorsSum / visitorsSumPrevStep) * 100); // конверсия от предыдущего шага с учетом значений не с пред. шага
        }

        // Вычисляем метрики, которые меняются динамически
        const newMetrics = metricsData.map((metric, index) => {
            let val;
            let valSum;

            switch (metrics[index]) {
                case 'visitors_all':
                    val = visitors;
                    valSum = visitorsSum;
                    break;
                case 'conversion_first_step':
                    val = convFirstStep;
                    valSum = convFirstStepSum;
                    break;
                case 'conversion_previous_step':
                    val = convPrevStep;
                    valSum = convPrevStepSum;
                    break;
                default:
                    val = metric;
                    valSum = metric;
            }

            valSum = valSum ? Math.round(valSum * 100) / 100 : valSum;
            metricsWithNotPrevStep.push(valSum);

            return val ? Math.round(val * 100) / 100 : val;
        });

        // Вычисляем данные для графиков
        const graphMetrics = {
            visitorsPrevStep: graph_metrics[0],
            visitorsNotPrevStep: graph_metrics[1],
            visitorsSum,
            convPrevStep,
            convPrevStepSum,
            convFirstStep,
            convFirstStepSum,
        };

        return {
            stepNumber: step_number,
            stepTitle: step_title,
            metrics: newMetrics,
            metricsWithNotPrevStep,
            graphMetrics,
        };
    });
    state.tableData = dataTransformed;
    state.graphDataMaxVal = [visitorsMax, visitorsSumMax];
    state.allMetrics = all_metrics;
    state.metrics = metrics;
    state.tableRequest = false;
};

export const setFunnelsList = (state: IFunnelsSlice, action) => {
    state.funnelList = action?.payload?.data;
    state.funnelsListRequest = false;
};

export const setFunnel = (state: IFunnelsSlice, action) => {
    state.funnel = action?.payload?.data;
    state.funnelRequest = false;
};

export const createFunnelFunc = (state: IFunnelsSlice, action) => {
    state.funnelList.unshift(action?.payload?.data);
    state.funnelRequest = false;
};

export const updateFunnelFunc = (state: IFunnelsSlice, action) => {
    const { data } = action?.payload || {};

    state.funnel = data;
    state.funnelList = state.funnelList.map((funnel) => {
        if (funnel.id === data?.id) return data;
        return funnel;
    });
    state.funnelRequest = false;
};

export const deleteFunnelFunc = (state: IFunnelsSlice, action) => {
    state.funnelList = state.funnelList.filter((funnel) => funnel.id !== action?.payload?.id);
    state.funnel = null;
    state.funnelRequest = false;
};

export const updateParamsFunc = (state: IFunnelsSlice, action) => {
    Object.assign(state, action.payload);
};

const funnelsSlice = createSlice({
    name: 'funnelsSlice',
    initialState,
    reducers: {
        updateParams: (state, action) => updateParamsFunc(state, action),
    },
    extraReducers: (builder) => {
        builder
            .addCase(getFunnelTable.pending, (state) => setRequestTable(state))
            .addCase(getFunnelTable.fulfilled, (state, action) => setFunnelTableData(state, action))
            .addCase(getFunnelTable.rejected, (state) => setRequestTable(state, false))

            .addCase(getFunnelsList.pending, (state) => setFunnelsListRequest(state))
            .addCase(getFunnelsList.fulfilled, (state, action) => setFunnelsList(state, action))
            .addCase(getFunnelsList.rejected, (state) => setFunnelsListRequest(state, false))

            .addCase(getFunnel.pending, (state) => setFunnelRequest(state))
            .addCase(getFunnel.fulfilled, (state, action) => setFunnel(state, action))
            .addCase(getFunnel.rejected, (state) => setFunnelRequest(state, false))

            .addCase(createFunnel.pending, (state) => setFunnelRequest(state))
            .addCase(createFunnel.fulfilled, (state, action) => createFunnelFunc(state, action))
            .addCase(createFunnel.rejected, (state) => setFunnelRequest(state, false))

            .addCase(updateFunnel.pending, (state) => setFunnelRequest(state))
            .addCase(updateFunnel.fulfilled, (state, action) => updateFunnelFunc(state, action))
            .addCase(updateFunnel.rejected, (state) => setFunnelRequest(state, false))

            .addCase(deleteFunnel.pending, (state) => setFunnelRequest(state))
            .addCase(deleteFunnel.fulfilled, (state, action) => deleteFunnelFunc(state, action))
            .addCase(deleteFunnel.rejected, (state) => setFunnelRequest(state, false));
    },
});

export const { updateParams } = funnelsSlice.actions;

export const funnelsActions = {
    updateParams,
    getFunnelTable,
    getFunnelsList,
    getFunnel,
    createFunnel,
    updateFunnel,
    deleteFunnel,
};

export default funnelsSlice.reducer;
