import React from 'react';
/* eslint-disable */
export const LogoSberads = () => (
    <svg width="71" height="14" viewBox="0 0 71 14" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M20.4149 6.32119C19.5539 6.10946 18.8106 5.92574 18.8106 5.29832C18.8106 4.4576 19.8012 4.3673 20.2272 4.3673C20.8171 4.3673 21.1538 4.45293 21.5932 4.66155C21.8062 4.49652 22.7805 3.74766 22.7805 3.74766C22.1384 3.32107 21.1746 2.98634 20.2391 2.98634C18.0061 2.98634 17.2107 4.22718 17.2107 5.38862C17.2107 7.06071 18.602 7.40946 19.8295 7.71616C20.692 7.93257 21.4368 8.11784 21.4368 8.75616C21.4368 9.08311 21.2506 9.63113 19.9993 9.63113C19.1696 9.63113 18.2609 9.32598 17.6144 8.83401L17.0721 10.1309C17.7291 10.6665 18.8701 11.0121 19.9889 11.0121C21.8405 11.0121 23.0382 10.0733 23.0382 8.62071C23.0382 6.96574 21.6453 6.62323 20.4164 6.32119H20.4149Z" fill="#83868D"/>
        <path d="M29.8205 6.87702L29.7431 6.85055L29.8146 6.81007C30.4253 6.46289 30.762 5.86348 30.762 5.11929C30.762 3.86756 29.7461 3.11869 28.0449 3.11869H24.5278V10.8782H28.2519C30.1051 10.8782 31.124 10.1325 31.124 8.77798C31.124 7.8563 30.6488 7.16348 29.819 6.87702H29.8205ZM26.1307 4.41091H27.8378C28.9133 4.41091 29.1398 4.92935 29.1398 5.35905C29.1398 5.98336 28.6899 6.3072 27.8378 6.3072H26.1307V4.41091ZM27.8378 6.3072H26.1307H27.8378ZM28.1283 9.586H26.1307V7.59941H28.1283C29.04 7.59941 29.5032 7.92947 29.5032 8.59271C29.5032 9.25594 29.0534 9.586 28.1283 9.586Z" fill="#83868D"/>
        <path d="M36.5523 4.41091L38.2296 3.11869H32.6166V10.8782H38.2296V9.586H34.218V7.59941H37.6397V6.3072H34.218V4.41091H36.5523Z" fill="#83868D"/>
        <path d="M46.0532 10.8782L44.4221 8.35762C45.3844 7.91857 45.9385 7.0545 45.9385 5.89618C45.9385 4.1836 44.7274 3.11869 42.776 3.11869H39.7192V10.8782H41.3206V8.67989H42.7745C42.8207 8.67989 42.8654 8.67989 42.9101 8.67678L44.3342 10.8767H46.0517L46.0532 10.8782ZM41.3236 4.41091H42.7432C43.7696 4.41091 44.3356 4.94025 44.3356 5.89929C44.3356 6.85833 43.7711 7.38768 42.7432 7.38768H41.3236V4.41091Z" fill="#83868D"/>
        <path d="M13.1797 6.99844C13.1797 6.60144 13.1454 6.21222 13.0799 5.83389L11.7407 6.86455C11.7407 6.90814 11.7422 6.95329 11.7422 6.99844C11.7422 9.75413 9.5971 11.996 6.96043 11.996C4.32375 11.996 2.17866 9.75413 2.17866 6.99844C2.17866 4.24275 4.32375 2.00084 6.96043 2.00084C7.95998 2.00084 8.88803 2.32311 9.65669 2.87269L10.8663 1.94168C9.7982 1.04024 8.43965 0.5 6.96043 0.5C3.5253 0.5 0.74115 3.40982 0.74115 7C0.74115 10.5902 3.5253 13.5 6.96043 13.5C10.3956 13.5 13.1797 10.5902 13.1797 7V6.99844Z" fill="#83868D"/>
        <path d="M11.8822 3.02371C12.1757 3.42071 12.4274 3.85353 12.6271 4.31592L6.96044 8.68143L4.59339 7.13077V5.26407L6.96044 6.81472L11.8822 3.02371Z" fill="#83868D"/>
        <path d="M56.4525 10.9031H55.0313L54.3163 9.05044H50.8886L50.184 10.9031H48.827L51.8599 3.08912H53.3763L56.4525 10.9031ZM53.8247 7.76601L52.5958 4.56194L51.3668 7.76601H53.8247Z" fill="#83868D"/>
        <path d="M59.977 3.08912C61.3653 3.08912 62.3976 3.41296 63.0739 4.06062C63.7502 4.70828 64.0884 5.63463 64.0884 6.83966C64.0884 7.55427 63.9871 8.16769 63.7845 8.68146C63.5819 9.19523 63.2974 9.61559 62.9295 9.94254C62.563 10.2695 62.125 10.5124 61.6156 10.668C61.1061 10.8237 60.549 10.9031 59.9442 10.9031H57.4029V3.08912H59.977ZM59.9457 9.6187C60.8782 9.6187 61.5694 9.3914 62.0178 8.93834C62.4662 8.48373 62.6911 7.82984 62.6911 6.97355C62.6911 6.11727 62.4721 5.45248 62.0342 5.01966C61.5962 4.5884 60.9035 4.372 59.9561 4.372H58.7391V9.6187H59.9457Z" fill="#83868D"/>
        <path d="M69.968 5.09906C69.8012 4.93559 69.612 4.7908 69.4019 4.66313C69.1919 4.53702 68.964 4.43583 68.7182 4.3611C68.4724 4.28637 68.2102 4.249 67.9332 4.249C67.4058 4.25679 67.0275 4.33774 66.7951 4.49499C66.5642 4.65068 66.448 4.86397 66.448 5.13175C66.448 5.33259 66.5105 5.49607 66.6342 5.62217C66.7578 5.74828 66.9202 5.85259 67.1198 5.93511C67.3194 6.01762 67.5473 6.08924 67.8036 6.15307C68.0598 6.2169 68.3234 6.28541 68.5946 6.36014C68.8657 6.43487 69.1279 6.52517 69.3856 6.63415C69.6418 6.74158 69.8697 6.88169 70.0693 7.05295C70.2689 7.22421 70.4298 7.44062 70.5549 7.70062C70.6801 7.96062 70.7411 8.28134 70.7411 8.66122C70.7411 8.98816 70.6845 9.29798 70.5698 9.58756C70.4551 9.87715 70.2779 10.1294 70.0365 10.3411C69.7937 10.5528 69.4898 10.721 69.1234 10.844C68.7569 10.967 68.3234 11.0277 67.8259 11.0277C67.4982 11.0277 67.1898 10.9981 66.9023 10.9389C66.6133 10.8798 66.3526 10.8035 66.1173 10.7101C65.8819 10.6167 65.6778 10.5124 65.5035 10.3971C65.3293 10.2819 65.1922 10.1652 65.0924 10.0453V8.32649C65.2056 8.52732 65.3516 8.71415 65.5304 8.88385C65.7076 9.05511 65.9132 9.20457 66.1441 9.33068C66.375 9.45679 66.6342 9.55798 66.9187 9.63271C67.2032 9.70744 67.5101 9.74481 67.8378 9.74481C68.3652 9.74481 68.7525 9.65607 69.0027 9.47702C69.2515 9.29798 69.3766 9.04888 69.3766 8.72972C69.3766 8.45415 69.2709 8.24864 69.0608 8.11008C68.8508 7.97307 68.5856 7.85942 68.2654 7.76912C67.9451 7.68038 67.601 7.59008 67.2345 7.50134C66.8681 7.41259 66.524 7.28181 66.2037 7.11056C65.8834 6.9393 65.6182 6.70265 65.4082 6.40217C65.1982 6.1017 65.0939 5.68912 65.0939 5.16912C65.0939 4.47008 65.3427 3.92828 65.8417 3.54529C66.3392 3.16229 67.0379 2.9708 67.9346 2.9708C68.5111 2.97858 69.0102 3.05487 69.4303 3.19966C69.8503 3.34445 70.1676 3.50325 70.3806 3.67451V5.60505C70.2734 5.43379 70.1363 5.2672 69.9695 5.10217L69.968 5.09906Z" fill="#83868D"/>
    </svg> 
);
