import SaveTemplate from '@components/TemplateForms/SaveTemplate';
import React from 'react';

import s from '../ModalPopup.pcss';

interface IProps {
    name?: string;
    title?: string;
    type?: string;
    isEdited?: boolean;
    saveTemplate: (templateName: string) => void;
    closePopup: () => void;
}

export default {
    getContent(props: IProps) {
        const popupContent = (
            <SaveTemplate
                name={props.name}
                type={props.type}
                isEdited={props.isEdited}
                saveTemplate={props.saveTemplate}
                closePopup={props.closePopup}
            />
        );

        return {
            popupClassName: s.templateForm,
            width: 'md',
            title: props.title || 'Сохранить отчёт',
            content: popupContent,
            buttonContainerClassName: s.buttons,
        };
    },
};
