import ChartSwitcher from '@components/ChartSwitcher';
import Dropdown from '@components/Dropdown';
import attributesData from '@configs/attributesData';
import { ChartTypes } from '@configs/graph';
import { GraphsDict } from '@typings/graph';
import { Metrics } from '@typings/metrics';
import { ReportName } from '@typings/reports';
import React from 'react';

import s from './ReportChart.pcss';

interface IProps {
    data: Array<{ value: Metrics; title: string | string[] }>;
    reportName: ReportName;
    selectedGraph: ChartTypes;
    activeMetric: Metrics;
    chartOrder: number;
    onChangeParamType: (activeMetricParam: Metrics, activeGraphParam: ChartTypes, chartOrder: number) => void;
    disabledGraphs: GraphsDict;
    showMetricsSwitcher?: boolean; // отображать дропдаун по выбору метрики
}

const ReportChartHeader: React.FC<IProps> = ({
    data,
    reportName,
    selectedGraph,
    activeMetric,
    chartOrder,
    onChangeParamType,
    disabledGraphs,
    showMetricsSwitcher = true,
}) => {
    const dataAttrName = attributesData.newreport.attrName;
    const graphAttr = attributesData.newreport.graph;
    const graphTools = graphAttr.tools;

    const handleMetricSelect = (value: Metrics) => {
        onChangeParamType(value, null, chartOrder);
    };

    const handleGraphSelect = (graphName: ChartTypes) => {
        onChangeParamType(null, graphName, chartOrder);
    };

    const renderDropdown = () => {
        if (data.length && data.length < 2) return data[0].title;

        return (
            <Dropdown
                type="selectSingle"
                data={data}
                dataInitialSelected={activeMetric}
                onApply={handleMetricSelect}
                reportName={reportName}
                dataAttrName={dataAttrName}
                dataAttrAnchor={[graphAttr.attrName, graphTools.attrName, graphTools.reportChart]}
                dataAttrList={[graphAttr.attrName, graphTools.attrName, graphTools.setMetric]}
            />
        );
    };

    return (
        <div className={s.chartHeader}>
            {showMetricsSwitcher && renderDropdown()}
            <div className={s.chartHeaderSwitcher}>
                <ChartSwitcher
                    reportName={reportName}
                    selectedGraph={selectedGraph}
                    activeMeasure={activeMetric}
                    disabledGraphs={disabledGraphs}
                    onSelectGraph={handleGraphSelect}
                />
            </div>
        </div>
    );
};

export default ReportChartHeader;
