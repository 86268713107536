import { Select, Loader, debounce } from '@adtech/ui';
import * as React from 'react';

import { useCallback, useEffect } from 'react';
import FilterFormHeader from '../components/FilterFormHeader';
import {
    IDynamicFilterItem,
    IFilterRuleFormValue,
    IFiltersCnfItem,
    TAllFormValues,
    TDimension,
} from '../types/filters';

interface IProps {
    filterCnf: IFiltersCnfItem;
    currentKey: string;
    allFormsValues: TAllFormValues;
    setAllFormsValues: (formsValues: TAllFormValues) => void;
    ruleFormValue: IFilterRuleFormValue;
    curFilterFormsValues: IFilterRuleFormValue[];
    ruleIndex: number;
    dynamicFilterData?: IDynamicFilterItem;
    getDynamicFilterData?: (dimension: TDimension, offset?: number) => void;
}

const CheckboxFilter: React.FC<IProps> = ({
    filterCnf,
    currentKey,
    allFormsValues,
    setAllFormsValues,
    ruleFormValue,
    curFilterFormsValues,
    ruleIndex,
    dynamicFilterData,
    getDynamicFilterData,
}) => {
    const SELECT_ITEM_HEIGHT = 40;
    const isDynamicFilter = filterCnf.isDynamic;
    let selectItems;

    if (isDynamicFilter) {
        selectItems = dynamicFilterData?.data;
    } else {
        selectItems = filterCnf.data?.map((item) => ({ ...item, key: item.value }));
    }

    // Запрашиваем данные по динамическому фильтру
    useEffect(() => {
        if (dynamicFilterData?.data?.length || !isDynamicFilter) return;

        getDynamicFilterData?.(filterCnf?.dynamicFilterAlias || currentKey);
    }, [currentKey]);

    const onChangeSelectHandler = (value: string[] | null) => {
        const newFormVal = [...curFilterFormsValues];
        newFormVal[ruleIndex] = { ...newFormVal[ruleIndex], value };
        setAllFormsValues({ ...allFormsValues, [currentKey]: newFormVal });
    };

    const clearFilter = () => onChangeSelectHandler(null);

    // Настройки поиска по label
    const filterOption = (input: string, option?: { label: string; value: string }) =>
        (option?.label ?? '').toLowerCase().includes(input.toLowerCase());

    const selectScrollHandler = (e) => {
        const { scrollTop, clientHeight } = e.target;
        const itemsCount = dynamicFilterData?.data?.length || 0;
        const limit = (itemsCount - 5) * SELECT_ITEM_HEIGHT - clientHeight;

        if (scrollTop > limit && !dynamicFilterData?.loading) {
            getDynamicFilterData(filterCnf?.dynamicFilterAlias || currentKey, itemsCount);
        }
    };

    const debouncedScrollHandler = useCallback(debounce(selectScrollHandler, 300), [
        currentKey,
        dynamicFilterData?.data?.length,
    ]);

    return (
        <div key={ruleIndex}>
            <FilterFormHeader
                label={filterCnf?.label}
                hint={filterCnf?.hint}
                clearFilter={ruleFormValue.value?.length ? clearFilter : null}
            />
            <Select
                loading
                placeholder="Выберите значение из списка"
                mode="multiple"
                options={selectItems || []}
                value={ruleFormValue.value || null}
                onChange={onChangeSelectHandler}
                filterOption={filterOption}
                dropdownRender={(menu) => (
                    <Loader loading={isDynamicFilter ? dynamicFilterData?.loading : false} size="small">
                        {menu}
                    </Loader>
                )}
                onPopupScroll={isDynamicFilter ? debouncedScrollHandler : null}
                // onSearch={(val) => console.log(val)}
                // onInputKeyDown={(e) => console.log(e)}
            />
        </div>
    );
};

export default CheckboxFilter;
