import { EntityActions, IEntityAction } from '@configs/entity';
import { Dimensions } from '@typings/dimensions';
import { IReportConfig } from '@typings/reports';
import conversionConfig from './conversion';
import conversionsSummaryConfig from './conversionsSummary';

export const configs: Record<string, Partial<IReportConfig>> = {
    conversionsSummaryConfig,
    conversionConfig,
};

export enum EConversionTitles {
    conversion = 'Конверсия',
    goal = 'Достижения цели',
}

export enum EConversionWidgetNames {
    trafficSources = 'traffic_sources',
    audience = 'audience',
    technology = 'technology',
}

export const widgetsDefaultDimensions: Record<string, Dimensions> = {
    audience: 'gender',
    technology: 'counter_type_eventbase',
};

export const conversionActionsConfig: IEntityAction[] = [
    {
        id: EntityActions.addToCommon,
        title: 'Сделать конверсию публичной',
    },
    {
        id: EntityActions.edit,
        title: 'Редактировать',
    },
    {
        id: EntityActions.delete,
        title: 'Удалить',
    },
];

export const dimensionsOrder = [
    'traffic_system',
    'traffic_type',
    'utm_source',
    'utm_medium',
    'utm_campaign',
    'utm_term',
    'utm_content'
];
