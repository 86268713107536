import { IconInformationSign } from '@adtech/ui';
import Dropdown from '@components/Dropdown';
import Hint from '@components/ReportHint';
import useHint from '@hooks/useHint';
import { IHint } from '@typings/hint';
import { INotification } from '@typings/notification';
import { ReportName } from '@typings/reports';
import React from 'react';

import s from './Subheader.pcss';

interface IProps {
    reportName: ReportName;
    reportTitle: string;
    projectId: number;
    userId: number;
    hintContent?: IHint;
    openModalPopup?: (modalPopupId: string, modalPopupParams?: Record<string, unknown>) => void;
    setNotification?: (value: INotification) => void;
    isDropdown?: boolean;
    values?: Record<string, any>[];
    dropdownSelectHandler?;
    selected?: string;
}

const Subheader: React.FC<IProps> = ({
    reportName,
    reportTitle,
    projectId,
    userId,
    hintContent,
    setNotification = () => {},
    openModalPopup = () => {},
    isDropdown = false,
    values = [],
    dropdownSelectHandler = () => {},
    selected = '',
}) => {
    const isSubheader = true;

    const {
        handleHiddenHint,
        handleHiddenAllHints,
        setToggleShowHint,
        showHintOnClick,
        showQuestionIcon,
        isHiddenHint,
        isShowAlways,
    } = useHint(projectId, userId, reportName, hintContent, isSubheader);

    return (
        <div className={s.subheader}>
            <div className={s.titleAndIcon}>
                <h2 className={s.title}>
                    {isDropdown ? (
                        <Dropdown
                            anchorClassName={s.title}
                            type="selectSingle"
                            onApply={dropdownSelectHandler}
                            data={values}
                            initialSelected={values.find((item) => item.value === selected).title}
                        />
                    ) : (
                        reportTitle
                    )}
                </h2>
                {showQuestionIcon && (
                    <button className={s.hintButton} onClick={() => setToggleShowHint(!showHintOnClick)} type="button">
                        <IconInformationSign />
                    </button>
                )}
            </div>
            {hintContent && (!isHiddenHint || showHintOnClick) && (
                <Hint
                    message={hintContent.message}
                    isMore={hintContent.isMore}
                    modalContent={hintContent.modalContent}
                    title={reportTitle}
                    reportName={reportName}
                    openModalPopup={openModalPopup}
                    setNotification={setNotification}
                    isShowAlways={isShowAlways}
                    handleHiddenHint={handleHiddenHint}
                    handleHiddenAllHints={handleHiddenAllHints}
                />
            )}
        </div>
    );
};

export default Subheader;
