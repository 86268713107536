import { Button, IconBackArrow } from '@adtech/ui';
import useCopy from '@hooks/useCopy';
import IconCopy from '@images/svg/icons/icon-copy.svg';
import React, { FC } from 'react';
import s from '../../PostClick.pcss';

interface CounterCreatedProps {
    counterNumber: number;
    deleteCounter: () => void;
}

const CounterCreated: FC<CounterCreatedProps> = ({ counterNumber, deleteCounter }) => {
    const { copy } = useCopy();

    const copyHandler = (counter: number): void => {
        copy(counter, 'Скопировано');
    };

    return (
        <>
            <Button onClick={deleteCounter} type="link" className={s.unlink}>
                <IconBackArrow />
                <span className={s.unlinkText}>Отключить счётчик</span>
            </Button>
            <div className={s.linked}>
                <div className={s.number}>
                    <span id="number">{counterNumber}</span>
                    <IconCopy onClick={() => copyHandler(counterNumber)} className={s.numberCopy} />
                </div>
                <p className={s.linkedTitle}> Счётчик синхронизирован</p>
                <p className={s.linkedText}>Теперь вы можете перенести цели в личный кабинет Яндекс Директа</p>
            </div>
        </>
    );
};

export default CounterCreated;
