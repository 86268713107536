import moment from 'moment';

type RangePresets = {
	default: Record<string, [moment.Moment, moment.Moment]>;
	nowDateDiff: Record<string, [moment.Moment, moment.Moment]>;
};

export const rangePresets: RangePresets = {
    default: {
        'Сегодня': [moment().startOf('day'), moment().endOf('day')],
        'Вчера': [moment().subtract(1, 'day').startOf('day'), moment().subtract(1, 'day').startOf('day')],
        'Последние 7 дней': [moment().subtract(6, 'days').endOf('day'), moment().startOf('day')],
        'Последние 30 дней': [moment().subtract(29, 'days').endOf('day'), moment().startOf('day')],
        'Прошлый месяц': [
            moment().subtract(1, 'month').startOf('month'),  moment().subtract(1, 'month').endOf('month')
        ]
    },
    nowDateDiff: {
        'Вчера': [moment().subtract(1, 'day').startOf('day'), moment().subtract(1, 'day').startOf('day')],
        'Последние 7 дней': [moment().subtract(7, 'days').endOf('day'), moment().subtract(1, 'day').startOf('day')],
        'Последние 30 дней': [
            moment().subtract(30, 'days').endOf('day'), moment().subtract(1, 'day').startOf('day')
        ],
        'Прошлый месяц': [
            moment().subtract(1, 'month').startOf('month'),moment().subtract(1, 'month').endOf('month')
        ]
    }
}