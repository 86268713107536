import { Button, Loader, Modal } from '@adtech/ui';
import { listDataUtils } from '@utils/index';
import flattenDeep from 'lodash/flattenDeep';
import isEqualWith from 'lodash/isEqualWith';
import max from 'lodash/max';
import React from 'react';
import s from '../ListData.pcss';
import List from './List';
import Selected from './Selected';

interface IProps {
    isLoadedConfig: boolean;
    isOpened: boolean;
    onOpen;
    onClose;
    onApply;
    getLians;
    getParentIdsByIds;
    config: Record<string, any>;
    map: Record<string, any>;
    data: any[];
    mode: 'three' | 'flat';
    field: Record<string, any>;
    meta: Record<string, any>;
}

interface IState {
    currentElement: number[];
    initialValues: number[];
    selectedValues: number[];
    currentNesting: number;
}

export default class MyDropdown extends React.Component<IProps, IState> {
    constructor(props) {
        super(props);
        const { field, data, getParentIdsByIds } = this.props;
        const parents = getParentIdsByIds(field.value);
        const values = [...field.value, ...parents]; // todo
        const parentItems = data.filter((itm) => !itm.parent_id);
        const currentNesting = this.getNesting(data, parentItems);

        this.state = {
            // начальные значения (нужны для сброса, чтобы правки не учитывались)
            initialValues: values,
            // текущая позиция в списке: [id_parent, ...(ids_childs : []), id_value]
            currentElement: [],
            // выбранные значения
            selectedValues: values,
            // уровень вложенности
            currentNesting,
        };
    }

    componentDidUpdate(prevProps: IProps) {
        const { getParentIdsByIds, isOpened, field } = this.props;
        const selectedValues = this.getFilteredValues(prevProps.field.value);
        const value = this.getFilteredValues(field.value);
        const { data: prevData } = prevProps;
        const { data: currentData } = this.props;

        if (!isEqualWith(value, selectedValues) && !isOpened) {
            const parents = getParentIdsByIds(value);
            const values = [...value, ...parents];

            this.setState({
                selectedValues: values,
                initialValues: values,
                currentElement: [],
            });
        }

        if (!isEqualWith(prevData, currentData)) {
            const parentItems = currentData.filter((item) => !item.parent_id);
            const currentNesting = this.getNesting(currentData, parentItems);
            this.setState({ currentNesting });
        }
    }

    /**
     * Получаем уровень вложенности
     */
    getNesting = (data: any[], items: { parent_id: number; id: number }[], nesting: number = 0): number => {
        if (!items.length) {
            return nesting;
        }

        const curNesting = items.map((parentItem) => {
            const childItems = data.filter((childItem) => childItem.parent_id === parentItem.id);

            return this.getNesting(data, childItems, nesting + 1);
        });

        return max(curNesting);
    };

    /**
     * Событие срабатывает при нажатие на кнопку "Применить"
     */
    handleApply = () => {
        const { onClose } = this.props;
        const { selectedValues } = this.state;

        this.setState({ initialValues: selectedValues }, () => {
            onClose();
        });
    };

    handleClose = () => {
        const { initialValues } = this.state;
        const { onApply, isOpened } = this.props;

        if (!isOpened) {
            return;
        }

        const values = this.getFilteredValues(initialValues);
        onApply(values);
    };

    /**
     * Удаляем выбранный элемент
     */
    removeSelectedItem = (values: number[]) => {
        const { field, map, getParentIdsByIds, getLians } = this.props;
        const { selectedValues } = this.state;
        const { getFilteredValues } = this;

        // получаем удаляемые элементы, путем сравнения разницы двух массивов
        const removedValues = getFilteredValues(selectedValues).filter((id) => !values.includes(id));

        let resSelectedValues = [...selectedValues];

        // получаем лианы и родителей всех элементов (в том числе лиан)
        const liansAndParents = flattenDeep(
            removedValues.map((id) => {
                const item = map[id];
                const lians = getLians(item);
                const parents = getParentIdsByIds([item.id, ...lians]);

                return [...lians, ...parents];
            }),
        );

        // удаляем элементы, их лианы и их родителей
        resSelectedValues = selectedValues.filter((id) => !removedValues.includes(id) && !liansAndParents.includes(id));

        // после фильтрации ищем родителей, если их нету в списке
        const parents = getParentIdsByIds(resSelectedValues).filter((id) => !resSelectedValues.includes(id));

        // мержим
        resSelectedValues = [...resSelectedValues, ...parents];

        this.setState(
            {
                selectedValues: resSelectedValues,
            },
            () => field.onChange(null, this.getFilteredValues(resSelectedValues)),
        );
    };

    /**
     * Уставливаем флаг / убираем флаг
     */
    setValueItem = ({ item, parentOffsets }) => {
        const { map, field, getParentIdsByIds, getLians } = this.props;
        let { selectedValues } = this.state;
        const { currentElement } = this.state;
        const { getSubCheckedIds, getIndeterminate } = this;

        const isIncluded = selectedValues.includes(item.id);
        const isIndeterminate = getIndeterminate(item);
        const isEqualParentOffsets = isEqualWith(currentElement, parentOffsets);

        if ((isIndeterminate && isEqualParentOffsets) || !isIndeterminate) {
            selectedValues = isIncluded ? selectedValues.filter((id) => id !== item.id) : [...selectedValues, item.id];

            const lians = getLians(item);

            const parentIds = getParentIdsByIds([item.id, ...lians]);

            if (isIncluded) {
                // если мы снимаем галочку, должны снять галочки у всех детей
                const subCheckedIds = getSubCheckedIds(item);

                // собираем лианы и родителей лиан в массив
                const subCheckedLiansAndParents = flattenDeep(
                    subCheckedIds.map((id) => {
                        const subLians = getLians(map[id]);
                        const subParents = getParentIdsByIds(subLians);
                        return [...subLians, ...subParents];
                    }),
                );

                // если у снимаемого элемента есть лианы, получаем его родителей
                const parentLiansCheckedIds = lians.length ? parentIds.filter((id) => selectedValues.includes(id)) : [];

                // фильтруем значения
                selectedValues = selectedValues.filter(
                    (id) =>
                        !subCheckedIds.includes(id) &&
                        !subCheckedLiansAndParents.includes(id) &&
                        !lians.includes(id) &&
                        !parentLiansCheckedIds.includes(id),
                );

                // после фильтрации ищем родителей, если их нету в списке
                const parents = getParentIdsByIds(selectedValues).filter((id) => !selectedValues.includes(id));

                // мержим
                selectedValues = [...selectedValues, ...parents];
            } else {
                // если мы ставим галочку, должны проставить галочку всем родителям
                const parentNoCheckedIds = parentIds.filter((id) => !selectedValues.includes(id));
                selectedValues = [...selectedValues, ...lians, ...parentNoCheckedIds];
            }
        }

        this.setState(
            {
                selectedValues,
                currentElement: parentOffsets,
            },
            () => field.onChange(null, this.getFilteredValues(selectedValues)),
        );
    };

    /**
     * Определяем, выбраны ли у элемента его дети
     */
    getIndeterminate = (item: Record<string, any>) => {
        const { data } = this.props;
        const { selectedValues } = this.state;

        return listDataUtils.getIndeterminate(item, selectedValues, data);
    };

    getSubCheckedIds = (item: Record<string, any>) => {
        const { data } = this.props;
        const { selectedValues } = this.state;

        const subItems = data.filter((itm) => itm.parent_id === item.id);
        const subCheckedIds = subItems
            .filter((subItem) => selectedValues.includes(subItem.id))
            .map((subItem) => subItem.id);

        const recursionSubs = flattenDeep(subItems.map((subItem) => this.getSubCheckedIds(subItem)));

        return [...subCheckedIds, ...recursionSubs];
    };

    /**
     * Получаем отфильтрованные выбранные элементы (без учёта indeterminate)
     */
    getFilteredValues = (values: number[], isOnlySubItems: boolean = false): Array<number> => {
        const { map } = this.props;

        let result = values.filter((id: number) => {
            const item = map[id];

            return item && !this.getIndeterminate(item);
        });

        if (isOnlySubItems) result = listDataUtils.filterOnlySubItems(result, map);

        return result;
    };

    /**
     * Получаем количество выбранных элементов из списка
     */
    getCountLengthSelected = (item: Record<string, any>): number => {
        const { selectedValues } = this.state;

        const selectedItem = selectedValues.includes(item.id) ? [item.id] : [];
        const subItems = this.getSubCheckedIds(item);

        return this.getFilteredValues([...selectedItem, ...subItems]).length;
    };

    renderActions() {
        const { meta } = this.props;

        return (
            <div className={s.actions}>
                <Button disabled={meta.invalid} type="default" onClick={this.handleApply} className={s.actionsButton}>
                    Применить
                </Button>
                <Button type="dashed" className={s.actionsButton} onClick={this.handleClose}>
                    Отмена
                </Button>
            </div>
        );
    }

    renderLoading = () => (
        <Button type="dashed" suffixIcon={<Loader size="small" />} className={s.loader}>
            Загружается...
        </Button>
    );

    renderError = () => {
        const { meta, config } = this.props;

        if (meta.valid || meta.errorType !== 'length') {
            return null;
        }

        return <div className={s.error}>{meta.errorText || config.labelSelected}</div>;
    };

    render() {
        const { data, isOpened } = this.props;
        const { currentElement, selectedValues, currentNesting } = this.state;

        if (!data.length) {
            return this.renderLoading();
        }

        return (
            <Modal
                isOpened={isOpened}
                closeOnClickOutside={false}
                onClose={this.handleClose}
                customFooter={this.renderActions()}
                size="lg"
                title={this.props.config.titlePopup}
            >
                <List
                    {...this.props}
                    currentNesting={currentNesting}
                    currentElement={currentElement}
                    selectedValues={selectedValues}
                    setSelectedData={this.setState}
                    getIndeterminate={this.getIndeterminate}
                    getSubCheckedIds={this.getSubCheckedIds}
                    getCountLengthSelected={this.getCountLengthSelected}
                    setValueItem={this.setValueItem}
                />
                <Selected
                    {...this.props}
                    removeSelectedItem={this.removeSelectedItem}
                    selectedValues={this.getFilteredValues(selectedValues, true)}
                />
                {this.renderError()}
            </Modal>
        );
    }
}
