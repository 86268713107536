import { Dimensions } from '@typings/dimensions';
import { IFilter } from '@typings/filters';
import { Metrics } from '@typings/metrics';

interface IObjectToQueryString {
    key: string;
    value: string | number;
    op?: string;
}

class QueryUtils {
    constructor() {
        return Object.freeze({
            queryItemToObject: this.queryItemToObject,
            queryStringToArray: this.queryStringToArray,
            arrayToQueryString: this.arrayToQueryString,
            objectToQueryString: this.objectToQueryString,
            filterObjToQueryString: this.filterObjToQueryString,
            queryItemToFilter: this.queryItemToFilter,
        });
    }

    queryItemToObject = (item: string, customDelimiters?: string[]): Record<string, string> => {
        const delimiters: string[] = customDelimiters || [':'];
        let customOp: any = null;
        let queryItem: string | string[] = item;

        delimiters.forEach((op) => {
            if (item.includes(op)) {
                queryItem = item.split(op);
                customOp = op;
            }
        });

        const result: Record<string, string> = {
            key: queryItem[0],
            value: queryItem[1],
        };

        if (customOp) result.op = customOp;

        return result;
    };

    queryStringToArray = (string: string, delimiter: string = ','): string[] => {
        const result = string && string.split ? string.split(delimiter) : [''];
        return result;
    };

    arrayToQueryString = (arr: string[], delimiter: string = ','): string => arr.join(delimiter);

    objectToQueryString = ({ key, value, op = ':' }: IObjectToQueryString): string => `${key}${op}${value}`;

    filterObjToQueryString = (filterObj: IFilter): string =>
        // eslint-disable-next-line max-len
        `${filterObj.key}:${filterObj.op}:${
            Array.isArray(filterObj.value) ? `[${String(filterObj.value)}]` : String(filterObj.value)
        }`;

    queryItemToFilter = (item: string): TOrNull<IFilter> => {
        const queryItems = item.match(/([^:]*):([^:]*):(.*)/);

        if (!queryItems?.length) return null;

        return {
            key: queryItems[1] as Metrics | Dimensions,
            op: queryItems[2],
            value: queryItems[3].includes('[')
                ? queryItems[3].slice(queryItems[3].indexOf('[') + 1, queryItems[3].indexOf(']')).split(',')
                : queryItems[3],
        } as IFilter;
    };
}

export default new QueryUtils();
