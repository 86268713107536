import { Tooltip } from '@adtech/ui';
import { ChartGrayColorLight } from '@configs/graph';
import trafficConf from '@configs/widgets/trafficType';
import { Dimensions, TrafficSourcesData } from '@typings/dimensions';
import numbersUtils from '@utils/numbersUtils';
import React from 'react';

import s from './TrafficSource.pcss';

interface IProps {
    data: TrafficSourcesData;
    labels: Record<Dimensions, string>;
    total: number;
}

const TrafficSource: React.FC<IProps> = ({ data, labels, total }) => {
    const filter = () => {
        const tmp = [];

        Object.keys(data).forEach((key) => {
            tmp.push({ name: [key], value: data[key] });
        });

        return tmp.sort((a, b) => b.value - a.value);
    };

    const filteredData = filter();

    const label = (
        <div className={s.trafficSource}>
            {filteredData.map((item) => (
                <span
                    key={item.name}
                    className={s.trafficSourceItem}
                    style={{
                        backgroundColor: trafficConf[item.name]?.color || ChartGrayColorLight,
                        width: `${numbersUtils.getPercentValueByNumber(total, data[item.name])}%`,
                    }}
                />
            ))}
        </div>
    );

    const tooltip = (
        <div className={s.trafficSourceTooltipContent}>
            <div className={s.trafficSourceTooltipTitle}>Источники переходов</div>
            {filteredData.map((item) => (
                <div key={item.name} className={s.trafficSourceTooltipItem}>
                    <span
                        className={s.trafficSourceTooltipItemDot}
                        style={{
                            backgroundColor: trafficConf[item.name]?.color || ChartGrayColorLight,
                        }}
                    />
                    <span className={s.trafficSourceTooltipItemName}>{labels[item.name]}</span>
                    <span className={s.trafficSourceTooltipItemPercent}>
                        {numbersUtils.getPercentValueByNumber(total, item.value)}%
                    </span>
                </div>
            ))}
        </div>
    );

    return (
        <Tooltip title={tooltip} placement="left" overlayClassName={s.trafficSourceTooltip}>
            {label}
        </Tooltip>
    );
};

export default TrafficSource;
