import imgSrc from './403-error.png';
import imgSet2 from './403-error@2x.png';
import imgSet3 from './403-error@3x.png';

const imgSet = [imgSet2, imgSet3];

export {
    imgSrc,
    imgSet,
};
