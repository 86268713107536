import {
    EFilterOperation,
    EFilterRuleOperation,
    EFilterType,
    IFilterItem,
    IFilterRuleFormValue,
    IFiltersCnfItem,
    TAllFormValues,
    TDimension,
    TFilters,
    TFiltersFormCnf,
} from '../types/filters';

/* Конвертация значений форм в фильтры */
const convertCheckboxFormToFilters = (
    { op, value }: IFilterRuleFormValue<string[]>,
    dimension: TDimension,
): TFilters => {
    const filters = [];

    if (op === EFilterRuleOperation.AND && value?.length > 1) {
        value.forEach((val) => {
            filters.push({
                key: dimension,
                op: EFilterOperation.EQ,
                value: val,
            });
        });
    } else if ((op === EFilterRuleOperation.AND || op === EFilterRuleOperation.OR) && value?.length === 1) {
        filters.push({
            key: dimension,
            op: EFilterOperation.EQ,
            value: value[0],
        });
    } else if (op === EFilterRuleOperation.OR && value?.length > 1) {
        filters.push({
            key: dimension,
            op: EFilterOperation.INCLUDE,
            value,
        });
    } else if (op === EFilterRuleOperation.NOT && value?.length > 1) {
        filters.push({
            key: dimension,
            op: EFilterOperation.EXCLUDE,
            value,
        });
    } else if (op === EFilterRuleOperation.NOT && value?.length === 1) {
        filters.push({
            key: dimension,
            op: EFilterOperation.NOTEQ,
            value: value[0],
        });
    }

    return filters;
};

const convertTextareaFormToFilters = (
    { op, value }: IFilterRuleFormValue<string[]>,
    dimension: TDimension,
): TFilters => {
    const filters = [];
    // Преобразовываем спец символы регулярок
    const newVal = value.map((val) => val.replaceAll('*', '%').replace(/^@(.*)$/, '%$1%'));

    if (op === EFilterRuleOperation.AND && value?.length > 1) {
        filters.push({
            key: dimension,
            op: EFilterOperation.MULTILIKEAND,
            value: newVal,
        });
    } else if ((op === EFilterRuleOperation.AND || op === EFilterRuleOperation.OR) && value?.length === 1) {
        filters.push({
            key: dimension,
            op: EFilterOperation.LIKE,
            value: newVal[0],
        });
    } else if (op === EFilterRuleOperation.OR && value?.length > 1) {
        filters.push({
            key: dimension,
            op: EFilterOperation.MULTILIKE,
            value: newVal,
        });
    } else if (op === EFilterRuleOperation.NOT && value?.length > 1) {
        filters.push({
            key: dimension,
            op: EFilterOperation.NOTMULTILIKE,
            value: newVal,
        });
    } else if (op === EFilterRuleOperation.NOT && value?.length === 1) {
        filters.push({
            key: dimension,
            op: EFilterOperation.NOTLIKE,
            value: newVal[0],
        });
    }

    return filters;
};

const convertDatePeriodFormToFilters = (
    { op, value }: IFilterRuleFormValue<string[]>,
    dimension: TDimension,
): TFilters => {
    const filters = [];
    const [date, time] = value;
    const formattedDate = date.replace(/^(.*)\.(.*)\.(.*)$/, '$3-$2-$1');
    const formattedTime = `${time}:00`;

    if (date && time) {
        filters.push({
            key: dimension,
            op,
            value: `${formattedDate} ${formattedTime}`,
        });
    } else if (date) {
        filters.push({
            key: 'published_date',
            op,
            value: formattedDate,
        });
    } else if (time) {
        filters.push({
            key: 'published_time',
            op,
            value: formattedTime,
        });
    }

    return filters;
};

const convertEventFormToFilters = (
    { op, value, extra }: IFilterRuleFormValue<string[]>,
    dimension: TDimension,
    filterCnf: IFiltersCnfItem,
): TFilters => {
    const filters = [];
    const eventClass = extra?.eventClass || filterCnf?.defaultValue;

    filters.push({
        key: 'event_class',
        op: EFilterOperation.EQ,
        value: eventClass,
    });

    if (op === EFilterRuleOperation.AND && value?.length > 1) {
        value.forEach((val) => {
            filters.push({
                key: dimension,
                op: EFilterOperation.EQ,
                value: val,
            });
        });
    } else if ((op === EFilterRuleOperation.AND || op === EFilterRuleOperation.OR) && value?.length === 1) {
        filters.push({
            key: dimension,
            op: EFilterOperation.EQ,
            value: value[0],
        });
    } else if (op === EFilterRuleOperation.OR && value?.length > 1) {
        filters.push({
            key: dimension,
            op: EFilterOperation.INCLUDE,
            value,
        });
    } else if (op === EFilterRuleOperation.NOT && value?.length > 1) {
        filters.push({
            key: dimension,
            op: EFilterOperation.EXCLUDE,
            value,
        });
    } else if (op === EFilterRuleOperation.NOT && value?.length === 1) {
        filters.push({
            key: dimension,
            op: EFilterOperation.NOTEQ,
            value: value[0],
        });
    }

    return filters;
};

export const transformFormsValuesToFilters = (
    formsValues: TAllFormValues,
    filtersFormCnf: TFiltersFormCnf,
): TFilters => {
    let filters: TFilters = [];

    Object.keys(formsValues).forEach((dimension) => {
        const formValue = formsValues[dimension];
        const filterCnf = filtersFormCnf[dimension];
        const { type } = filterCnf;

        formValue.forEach((formRule) => {
            let filter: TFilters;

            // Отсекаем пустые значения
            if (!formRule?.value?.length) return;

            switch (type) {
                case EFilterType.checkbox:
                    // Из одного правила может получиться массив фильтров (например при связке по AND)
                    filter = convertCheckboxFormToFilters(formRule as IFilterRuleFormValue<string[]>, dimension);
                    break;
                case EFilterType.textarea:
                    filter = convertTextareaFormToFilters(formRule as IFilterRuleFormValue<string[]>, dimension);
                    break;
                case EFilterType.radio:
                    filter = [{ key: dimension, op: EFilterOperation.EQ, value: formRule.value }];
                    break;
                case EFilterType.period:
                    filter = [{ key: dimension, op: formRule.op, value: formRule.value }];
                    break;
                case EFilterType.datePeriod:
                    filter = convertDatePeriodFormToFilters(formRule as IFilterRuleFormValue<string[]>, dimension);
                    break;
                case EFilterType.event:
                    filter = convertEventFormToFilters(
                        formRule as IFilterRuleFormValue<string[]>,
                        dimension,
                        filterCnf,
                    );
                    break;
                default:
                    filter = [];
                    break;
            }

            filters = [...filters, ...filter];
        });
    });

    return filters;
};

/* Конвертация фильтров в значения форм */
const convertCheckboxFiltersToForm = ({ op, value }: IFilterItem): IFilterRuleFormValue[] => {
    let operation;
    const newVal = typeof value === 'string' ? [value] : value;

    switch (op) {
        case EFilterOperation.EQ:
            operation = EFilterRuleOperation.AND;
            break;
        case EFilterOperation.NOTEQ:
            operation = EFilterRuleOperation.NOT;
            break;
        case EFilterOperation.INCLUDE:
            operation = EFilterRuleOperation.OR;
            break;
        case EFilterOperation.EXCLUDE:
            operation = EFilterRuleOperation.NOT;
            break;
        default:
            break;
    }

    return operation ? [{ op: operation, value: newVal }] : [];
};

const convertTextareaFiltersToForm = ({ op, value }: IFilterItem): IFilterRuleFormValue[] => {
    let operation;

    // Преобразовываем спец символы регулярок
    const newVal = (typeof value === 'string' ? [value] : value).map((val) => val.replaceAll('%', '*'));

    switch (op) {
        case EFilterOperation.LIKE:
            operation = EFilterRuleOperation.AND;
            break;
        case EFilterOperation.NOTLIKE:
            operation = EFilterRuleOperation.NOT;
            break;
        case EFilterOperation.MULTILIKEAND:
            operation = EFilterRuleOperation.AND;
            break;
        case EFilterOperation.MULTILIKE:
            operation = EFilterRuleOperation.OR;
            break;
        case EFilterOperation.NOTMULTILIKE:
            operation = EFilterRuleOperation.NOT;
            break;
        default:
            break;
    }

    return operation ? [{ op: operation, value: newVal }] : [];
};

const convertPeriodFiltersToForm = ({ op, value }: IFilterItem): IFilterRuleFormValue[] => {
    let formValue;

    // Поддержка старых фильтров
    if (op === EFilterOperation.RANGE) {
        formValue = [
            {
                op: EFilterRuleOperation.GTOE,
                value: value[0],
            },
            {
                op: EFilterRuleOperation.LTOE,
                value: value[1],
            },
        ];
    } else {
        formValue = [{ op, value }];
    }

    return formValue;
};

const convertDatePeriodFiltersToForm = ({ op, value }: IFilterItem): IFilterRuleFormValue[] => {
    let formValue;

    const getDate = (val) => val.replace(/^(.*)-(.*)-(.*)$/, '$3.$2.$1');
    const getTime = (val) => val.replace(/^(.*):(.*):(.*)$/, '$1:$2');

    if (op === EFilterOperation.RANGE) {
        const dateStart = getDate(value[0]);
        const timeStart = getTime(value[0]);

        const dateEnd = getDate(value[1]);
        const timeEnd = getTime(value[1]);

        formValue = [
            {
                op: EFilterRuleOperation.GTOE,
                value: [dateStart, timeStart],
            },
            {
                op: EFilterRuleOperation.LTOE,
                value: [dateEnd, timeEnd],
            },
        ];
    } else {
        let date = (value as string).split(' ')[0];
        let time = (value as string).split(' ')[1];

        if (time) {
            date = getDate(date);
            time = getTime(time);
            // Значение - одна строка - либо дата, либо время
        } else {
            const isTime = value.includes(':');
            date = isTime ? '' : getDate(value);
            time = isTime ? getTime(value) : '';
        }

        formValue = [
            {
                op,
                value: [date, time],
            },
        ];
    }

    return formValue;
};

const convertEventFiltersToForm = ({ op, value }: IFilterItem, eventClass?: string): IFilterRuleFormValue[] => {
    let operation;
    const newVal = typeof value === 'string' ? [value] : value;

    switch (op) {
        case EFilterOperation.EQ:
            operation = EFilterRuleOperation.AND;
            break;
        case EFilterOperation.NOTEQ:
            operation = EFilterRuleOperation.NOT;
            break;
        case EFilterOperation.INCLUDE:
            operation = EFilterRuleOperation.OR;
            break;
        case EFilterOperation.EXCLUDE:
            operation = EFilterRuleOperation.NOT;
            break;
        default:
            break;
    }

    return operation ? [{ op: operation, value: newVal, extra: { eventClass } }] : [];
};

export const transformFiltersToFormsValues = (filters: TFilters, filtersFormCnf: TFiltersFormCnf): TAllFormValues => {
    const formsValues: TAllFormValues = {};

    filters.forEach((filter) => {
        const filterCnf = filtersFormCnf[filter.key];
        const { type } = filterCnf;
        let dimension = filter.key;
        let formValue;
        let eventClassFilter;
        let eventClass;

        switch (type) {
            case EFilterType.checkbox:
                formValue = convertCheckboxFiltersToForm(filter);
                break;
            case EFilterType.textarea:
                formValue = convertTextareaFiltersToForm(filter);
                break;
            case EFilterType.radio:
                formValue = [
                    {
                        op: EFilterRuleOperation.AND,
                        value: filter.value,
                    },
                ];
                break;
            case EFilterType.period:
                formValue = convertPeriodFiltersToForm(filter);
                break;
            case EFilterType.datePeriod:
                formValue = convertDatePeriodFiltersToForm(filter);
                dimension = 'published_datetime'; // костыль для трех дименшинов дат и времени
                break;
            case EFilterType.event:
                eventClassFilter = filters.find((item) => item.key === 'event_class');
                // Поддержка старых фильтров
                eventClass = eventClassFilter?.op === EFilterOperation.EQ ? eventClassFilter?.value : null;
                formValue = convertEventFiltersToForm(filter, eventClass);
                break;
            default:
                formValue = [];
                break;
        }

        formsValues[dimension] = [...(formsValues?.[dimension] || []), ...formValue];
    });

    // Склейка отдельных фильтров с операцией AND в одно правило
    Object.keys(formsValues).forEach((dimension) => {
        const filterCnf = filtersFormCnf[dimension];
        const { type } = filterCnf;
        const rules = formsValues[dimension];
        let andOpRules: IFilterRuleFormValue[];
        let otherRules: IFilterRuleFormValue[];
        let andOpRuleValue: string[] = [];

        if (type === EFilterType.checkbox || type === EFilterType.event) {
            andOpRules = rules.filter((rule) => rule.op === EFilterRuleOperation.AND);

            if (andOpRules?.length <= 1) return;

            otherRules = rules.filter((rule) => rule.op !== EFilterRuleOperation.AND);

            andOpRules.forEach((rule) => {
                andOpRuleValue = [...andOpRuleValue, ...rule.value];
            });
            formsValues[dimension] = [
                {
                    op: EFilterRuleOperation.AND,
                    value: andOpRuleValue,
                },
                ...otherRules,
            ];
        }
    });

    return formsValues;
};
