import { setError } from '@redux/slices/global';
import isEmpty from 'lodash/isEmpty';
import isObject from 'lodash/isObject';

import store from '../index';

const ignoredErrors = [507, 401];

// FIXME Не обрабатываем ошибки запросов медиа отчётов глобально, ставим заглушки
const ignoredTypes = [
    'mediaSlice/getTrafficSources/fulfilled',
    'mediaSlice/getAudience/fulfilled',
    'mediaSlice/getTotals/fulfilled',
    'mediaSlice/getFullReadScroll/fulfilled',
    'mediaSlice/getRecirculationSources/fulfilled',
];

export default () => (next) => (action) => {
    const { error, payload, type } = action;
    const status = payload?.responseStatus || payload?.status;
    const errors = payload?.errors;

    const isIgnoredError =
        payload &&
        // общий игнор ошибок
        (ignoredErrors.includes(status) ||
            // или кастомный из экшена
            (payload.metaInfo?.ignoredErrors && payload.metaInfo.ignoredErrors.includes(status)) ||
            payload.metaInfo?.ignoreAllErrors ||
            // игнор оборванных экшенов
            (action.signal && action.signal.aborted) ||
            // игнор ошибок медиа отчётов
            ignoredTypes.includes(type));

    // проверка на пустой объект
    const checkedError = isObject(error) ? !isEmpty(error) : error;
    const checkedErrors = isObject(errors) ? !isEmpty(errors) : errors;

    if ((checkedError || checkedErrors) && !isIgnoredError) {
        store.dispatch(setError({ code: status }));
    }

    return next(action);
};
