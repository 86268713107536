import { Button, ContextAlertMark } from '@adtech/ui';
import CounterBlocks from '@components/CounterBlocks';
import Form from '@components/Form';
import { IWrappedComponentProps } from '@decorators/formDecorator/types';
import { IUsersValue } from '@typings/counter';
import isEmpty from 'lodash/isEmpty';
import isEqual from 'lodash/isEqual';
import * as React from 'react';

import s from '../SettingsCounter.pcss';

interface IProps extends IWrappedComponentProps {
    values: {
        addedUsers: IUsersValue[];
    };
    saveCounterRequest: boolean;
    getUserExists: (email: string) => Promise<{ payload: { exists: boolean } }>;
    onSubmit: (isAddUsers: boolean, isDeleteUsers: boolean) => Promise<void>;
    invalid: boolean;
    currentEmail: string;
    serverError: string;
}

interface IState {
    isChangedValues: boolean;
    isAddUsers: boolean;
    isDeleteUsers: boolean;
}

export default class AccessForm extends React.PureComponent<IProps, IState> {
    state = {
        isChangedValues: false,
        isAddUsers: false,
        isDeleteUsers: false,
    };

    componentDidUpdate(prevProps: IProps) {
        if (
            !isEmpty(this.props.values) &&
            prevProps.values.addedUsers &&
            !isEqual(this.props.values, prevProps.values)
        ) {
            let { isAddUsers, isDeleteUsers } = this.state;

            if (this.props.values.addedUsers.length > prevProps.values.addedUsers.length) {
                isAddUsers = true;
            } else {
                isDeleteUsers = true;
            }

            this.updateAccessState(isAddUsers, isDeleteUsers);
        }
    }

    updateAccessState = (isAddUsers: boolean, isDeleteUsers: boolean) => {
        this.setState({
            isChangedValues: true,
            isAddUsers,
            isDeleteUsers,
        });
    };

    handleSubmit = () => {
        const { isAddUsers, isDeleteUsers } = this.state;

        this.props.onSubmit(isAddUsers, isDeleteUsers).then(() => {
            this.setState({
                isChangedValues: false,
                isAddUsers: false,
                isDeleteUsers: false,
            });
        });
    };

    render() {
        const { isChangedValues } = this.state;
        const { saveCounterRequest, getUserExists, currentEmail, serverError } = this.props;

        return (
            <Form onSubmit={this.handleSubmit}>
                <CounterBlocks.AccessUsers currentEmail={currentEmail} getUserExists={getUserExists} />
                <div className={s.actions}>
                    <Button
                        loading={saveCounterRequest}
                        disabled={saveCounterRequest || !isChangedValues}
                        htmlType="submit"
                        className={s.save}
                    >
                        Сохранить изменения
                    </Button>
                    {serverError && <ContextAlertMark message={serverError} status="error" />}
                </div>
            </Form>
        );
    }
}
