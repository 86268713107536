import groupParams, { GroupBy } from '@configs/group';
import dateConstants from '@constants/date';
import { EPeriodDetail } from '@constants/periodDetail';
import { DateType, DateLiteral, TimeUnits, DateString, TChartPeriod, TChartDate } from '@typings/date';
import Validator from '@utils/validator';
import dayjs, { Dayjs } from 'dayjs';

import store from '../redux/store';

const { DATE_FORMAT, DATE_VIEW, DATETIME_VIEW } = dateConstants;

type TAddEntityType = Exclude<keyof typeof dateConstants.TIME_INTERVALS, 'ms'>;

// TODO: обертка над оберткой dayjs, по-хорошему надо разобраться и почистить,
//  много дублирующегося функционала.
//  Импорт store постоянно вынуждает мокать утилсы в тестах, чтобы не было рекурсивных вызовов
//  Переписать на хуки.
class DateUtils {
    /**
     * Проверяем старше ли дата указанной
     * TODO: никак не валидируется перевод в NaN
     */
    isAfter = (currentDate: Dayjs, date: Dayjs): boolean => {
        if (!currentDate || !date) return false;

        return +currentDate > +date;
    };

    /**
     * Проверяем раньше ли дата указанной
     * TODO: никак не валидируется перевод в NaN
     */
    isBefore = (currentDate: Dayjs, date: Dayjs): boolean => {
        if (!currentDate && !date) return false;

        if (!date) return true; // TODO - проверить где это используется

        return +currentDate < +date;
    };

    /**
     * Проверяем переданную дату на то сегодняшняя она или нет
     */
    isToday = (date?: DateType): boolean => {
        const today = this.getToday().format(DATE_FORMAT);
        const currentDate = this.getDate(date).format(DATE_FORMAT);
        return currentDate === today;
    };

    /**
     * Проверяем что обе даты равны сегодняшнему дню
     */
    isPeriodToday = (start: DateType, end: DateType): boolean => this.isToday(start) && this.isToday(end);

    /**
     * Проверяем переданный период на размерность
     */
    isLongPeriod = (
        {
            dateStart,
            dateEnd,
        }: {
            dateStart: DateType;
            dateEnd: DateType;
        },
        unit: TimeUnits,
    ) => {
        const diff = this.getDiffDate(dateStart, dateEnd, unit);

        return Math.abs(diff) > dateConstants.LONG_PERIOD;
    };

    /**
     * Проверяем является ли дата литералом
     */
    isLiteralDate = (date: DateString) => dateConstants.LITERALS.includes(date);

    /**
     * Возвращаем дату в определённом формате
     */
    getDateWithFormat = (date: DateType, format: string): string => this.getDate(date).format(format);

    /**
     * Возвращаем период в определенном формате
     */
    convertDates = (dateStart: DateType, dateEnd: DateType, format: string = DATE_FORMAT): [string, string] => [
        this.getDateWithFormat(dateStart, format),
        this.getDateWithFormat(dateEnd, format),
    ];

    /**
     * Получение даты
     * Функция может применять относительные и абсолютные даты.
     * Возвращает dayjs()
     */
    getDate = (date?: DateType): Dayjs => {
        let query = date || 'today';

        if (typeof query === 'string' && Validator.hasDaysInString(query)) {
            query = 'days';
        }

        const relativeDates = {
            today: () => this.getToday(),
            yesterday: () => this.getYesterday(),
            days: () => {
                const daysNumber = typeof date === 'string' && this.getDaysNumber(date);

                return this.someDaysAgo(daysNumber);
            },
        };

        if (typeof query === 'string' && relativeDates[query]) {
            return relativeDates[query]();
        }

        return this.getDayjsObject(query);
    };

    /**
     * Возвращаем дату в определённом формате
     */
    getCustomDate = (date: DateType, format: string): Dayjs => this.getDayjsObject(date, format);

    /**
     * Приводим период к читаемому виду
     */
    readablePeriod = (date1: Dayjs, date2: Dayjs): string => {
        const date1Year = date1.year();
        const date2Year = date2.year();
        let result;

        if (+date1 === +date2) {
            result = date1.format(DATE_VIEW);
        } else if (date1Year === date2Year) {
            result = [date1.format('DD.MM'), date2.format(DATE_VIEW)].join(' — ');
        } else {
            result = [date1.format(DATE_VIEW), date2.format(DATE_VIEW)].join(' — ');
        }

        return result;
    };

    /**
     * Приводим день к читаемому виду
     */
    readableDay = (date: Dayjs) => date.format(DATETIME_VIEW);

    /**
     * Приводим секунды к читаемому формату
     */
    secondsToFormat = (seconds: number): string => {
        const hour = 3600;
        const result = [];
        const leadingZero = (input) => (input < 10 ? `0${input}` : input);

        if (seconds >= hour) {
            result.push(leadingZero(Math.floor(seconds / hour)));
        }

        result.push(leadingZero(Math.floor(seconds / 60) % 60));

        result.push(leadingZero(seconds % 60));

        return result.join(':');
    };

    /**
     * Получаем дату сегодняшнего дня
     */
    getToday = (): Dayjs => {
        const today = store.getState().globalSlice.serverTime;

        return this.getDate(today);
    };

    /**
     * Получаем дату вчерашнего дня
     */
    getYesterday = (): Dayjs => this.getToday().add(-1, 'day');

    /**
     * Высчитываем дату, меньшую на number дней от текущей даты
     */
    someDaysAgo = (daysCount: number): Dayjs => {
        const count = daysCount - 1;

        return this.getToday().add(-count, 'day');
    };

    /**
     * Получаем массив в возможными вариантами группировки, в зависимости от периода
     */
    getGroupFromPeriod = (period: Dayjs[] | string[], periodDetail?: EPeriodDetail): GroupBy[] => {
        let [startDate, endDate] = period;
        let group: GroupBy[];

        if (!startDate || !endDate) return [];

        startDate = dayjs(startDate);
        endDate = dayjs(endDate);

        const groups = groupParams.period;

        // Получаем разницу по периоду в днях
        const daysNum = endDate.diff(startDate, 'day');

        if (Number.isNaN(daysNum)) return [];

        if (daysNum < 1) {
            group = groups.day;
        } else if (daysNum >= 1 && daysNum < 7) {
            group = groups.lessWeek;
        } else if (daysNum >= 7 && daysNum < startDate.daysInMonth()) {
            group = groups.lessMonth;
        } else {
            group = groups.moreMonth;
        }

        if (this.isPeriodToday(startDate, endDate) && periodDetail === EPeriodDetail.thirtyMinutes) {
            group = groups.lessHour;
        }

        return group;
    };

    /**
     * Проверяем текущую группировку на вхождение
     * Если не найдена - берем первый элемент из доступных
     * Если найдена - возвращаем указанную
     */
    checkCurrentGroup = (period: Dayjs[], currentGroup: GroupBy, periodDetail?: EPeriodDetail): GroupBy => {
        const availableGroups = this.getGroupFromPeriod(period, periodDetail);

        if (!availableGroups.includes(currentGroup)) {
            return availableGroups[0];
        }

        return currentGroup;
    };

    /**
     * Проверяем вхождение текущего дня в период
     */
    todayInRange = (start: Dayjs, end: Dayjs): boolean => {
        const today = this.getToday();

        return +start <= +today && +today <= +end;
    };

    /**
     * Формируем границы периода исходя из переданного periodDetail (Показать за...)
     */
    getDatesByPeriodDetail = (periodDetail: EPeriodDetail, timeOffset: number): string[] => {
        const detailPeriod = periodDetail;
        // Приводим детализацию в минутах к мс
        const detail = detailPeriod && Number(detailPeriod) * 60 * 1000;

        if (Number.isNaN(detail)) return [];

        const date = new Date().getTime();

        const end = date - timeOffset; // к серверному времени
        const start = end - detail;

        const dateStart = this.getDateWithFormat(start, dateConstants.DATE_ISO_FORMAT);
        const dateEnd = this.getDateWithFormat(end, dateConstants.DATE_ISO_FORMAT);

        return [dateStart, dateEnd];
    };

    /**
     * Валидация введенной в инпут даты
     */
    isValidInputDate = (date: DateType) => this.getDayjsObject(date).isValid();

    /**
     * Проверяем существование даты
     */
    checkDateExists = (date: DateType): boolean => {
        const dayjsDate = this.getDayjsObject(date);
        const isSameDate = this.getDayjsObject(date, 'YYYY-MM-DD').format('YYYY-MM-DD') === date;

        if (dayjsDate.isValid() && isSameDate) {
            return !this.isAfter(dayjsDate, this.getToday());
        }

        return false;
    };

    /**
     * Проверяем правильно ли введен интервал.
     * Если дата конца интервала меньше, чем начала, то меняем даты местами.
     */
    validAndRevertInterval = (period: DateType[]): DateType[] => {
        const [dateStart, dateEnd] = period;
        const start = this.getDate(dateStart);
        const end = this.getDate(dateEnd);

        return this.isAfter(start, end) ? [dateEnd, dateStart] : period;
    };

    getDiffDate = (dateStart: DateType, dateEnd: DateType, unit: TimeUnits = 'month'): number =>
        this.getDate(dateEnd).diff(this.getDate(dateStart), unit);

    /**
     * Приводим дату к читаемому виду
     */
    readable = (dateString: string) => {
        const dates = dateString.split(' - ');
        let result;

        if (dates.length === 2) {
            result = this.readablePeriod(this.getDate(dates[0]), this.getDate(dates[1]));
        } else {
            result = this.readableDay(this.getDate(dates[0]));
        }

        return result;
    };

    /**
     * Получаем дату в dayjs()
     */
    getDayjsObject = (date: DateType, customFormat?: string) =>
        customFormat ? dayjs(date, customFormat) : dayjs(date);

    /**
     * Получаем количество дней из строки типа "{n}days"
     */
    getDaysNumber = (daysStr: DateType): number => {
        if (typeof daysStr === 'string') {
            return Number(daysStr.split('days').join(''));
        }
        return 0;
    };

    /**
     * Проверка даты на валидность
     * При преобразовании даты в dayjs объект дата может измениться, но остаться валидной
     * 12.13.2021 => 12.01.2022, учитываем это
     */
    isValidDate = (date: DateType, format?: string) => {
        const dateRegExp = dateConstants.DATE_SIMPLE;

        // Все числа у даты введены
        if (!dateRegExp.test(date?.toString())) return false;

        const formatDate = format || dateConstants.DATE_VIEW;
        const dateObj = this.getDayjsObject(date, formatDate);
        const dateString = dateObj.format(formatDate);
        const isValid = dateObj.isValid();
        return isValid && date.toString() === dateString;
    };

    /**
     * Проверка времени на валидность
     */
    isValidTime = (time: string) => {
        if (!dateConstants.TIME_REGEXP_STRICT.test(time)) return false;

        const timeSplit = time.split(':');
        const hours = parseInt(timeSplit[0], 10);
        const min = parseInt(timeSplit[1], 10);
        const sec = timeSplit.length > 2 ? parseInt(timeSplit[2], 10) : 0;
        // Проверяем границы
        return hours >= 0 && hours < 24 && min >= 0 && min < 60 && sec >= 0 && sec < 60;
    };

    reformatDate = (date: DateType, format, newFormat) => {
        const newDate = this.getDayjsObject(date, format);

        return newDate.format(newFormat);
    };

    /**
     * Возвращаем константные даты
     */
    getRelativeDates = (relativeDate: DateLiteral) => {
        const dateEnd =
            (relativeDate === 'yesterday' && 'yesterday') ||
            (relativeDate === 'today' && 'today') ||
            (relativeDate.includes('days') && 'today');

        const dateStart = relativeDate;

        return { dateStart, dateEnd };
    };

    /**
     * Проверяем дату на вхождение в допустимый интервал
     */
    checkIsDateExcludedRange = (
        date: DateType,
        range: {
            minDate: DateType;
            maxDate: DateType;
        },
    ): boolean => {
        // Дополнительно конвертируем формат,
        // чтобы избавиться от текстовых дат типа "today"
        const dateFormat = this.getDate(date).format(DATE_FORMAT);

        return (
            this.isBefore(this.getDate(dateFormat), this.getDate(range.minDate)) ||
            this.isAfter(this.getDate(dateFormat), this.getDate(range.maxDate))
        );
    };

    /**
     * Конвертация периода в нативные объекты Date (Wed May 31 2023 13:54:44 GMT+0300)
     */
    convertPeriodToNative = (period: [DateType, DateType]): [Date, Date] => [
        this.getDate(period[0]).toDate(),
        this.getDate(period[1]).toDate(),
    ];

    /**
     * Хелпер для добавления к дате нужного кол-ва времени
     */
    add = (date: TChartDate, entity: TAddEntityType, count: number) => {
        if (!entity) return new Date(date);

        return new Date(
            +new Date(date) + count * dateConstants.TIME_INTERVALS[entity] * dateConstants.TIME_INTERVALS.ms,
        );
    };

    /**
     * Возвращает начало(date) и конец месяца
     */
    getMonthPeriod = (date: TChartDate) => {
        const startMonth = new Date(date);
        const endMonth = new Date(startMonth.getFullYear(), startMonth.getMonth() + 1, 0);

        return [+startMonth, +endMonth];
    };

    /**
     * Возвращает начало(date) и конец недели
     */
    getWeekPeriod = (date: TChartDate) => {
        const obj = new Date(date);
        // приведение индекса дней недели от 1 до 7
        const indexDayOfWeek = ((obj.getDay() + 6) % 7) + 1;
        const day = obj.getDate();
        const lastDay = day + 7 - indexDayOfWeek;
        const daysCount = (lastDay - day) * dateConstants.TIME_INTERVALS.day * dateConstants.TIME_INTERVALS.ms;

        return [+obj, new Date(+obj + daysCount).getTime()];
    };

    /**
     * Возвращает список недель в рамках начала и конца периода
     */
    getWeeksFromPeriod = ([start, end]: TChartPeriod): string[] => {
        const weeks: string[] = [];
        let lastDateTime = new Date(start).getTime();

        while (lastDateTime <= +end) {
            const period = this.getWeekPeriod(lastDateTime);
            weeks.push(period.join('_'));
            lastDateTime = +period[1] + dateConstants.TIME_INTERVALS.day * dateConstants.TIME_INTERVALS.ms;
        }

        weeks[weeks.length - 1] = [weeks[weeks.length - 1].split('_')[0], +end].join('_');

        return weeks;
    };

    /**
     * Возвращает список месяцев в рамках начала и конца периода
     */
    getMonthsFromPeriod = ([start, end]: TChartPeriod) => {
        const months = [];
        let lastDate = start;

        let count = 0;

        while (+lastDate < +end && count < 30) {
            const period = this.getMonthPeriod(lastDate);
            months.push(period.join('_'));
            lastDate = +period[1] + dateConstants.TIME_INTERVALS.day * dateConstants.TIME_INTERVALS.ms;
            count += 1;
        }

        months[months.length - 1] = [months[months.length - 1].split('_')[0], +end].join('_');

        return months;
    };

    /**
     * Возвращает список дней в рамках начала и конца периода
     */
    getDaysFromPeriod = ([start, end]: TChartPeriod) => {
        const res: number[] = [];
        let nextDate = new Date(start).getTime();

        while (nextDate <= Number(end)) {
            res.push(nextDate);
            nextDate = this.add(nextDate, GroupBy.day, 1).getTime();
        }

        return res;
    };

    /**
     * Возвращает список часов в рамках начала периода
     */
    getHoursFromPeriod = ([start]: TChartPeriod, count: number) =>
        new Array(count).fill(null).map((_, i) => this.add(start, GroupBy.hour, i));

    /**
     * Возвращает список по 5 минут в рамках начала периода
     */
    getFiveMinutesFromPeriod = ([start]: TChartPeriod, count: number) =>
        new Array(count).fill(null).map((_, i) => this.add(start, GroupBy.minute, i * 5));

    /**
     * Возвращает список минут в рамках начала периода
     */
    getMinutesFromPeriod = ([start]: TChartPeriod, count: number) => {
        const ms = new Date(start).getTime();
        return new Array(count).fill(null).map((_, i) => ms + 60 * 1000 * i);
    };

    /**
     * Приводим период из любых дат, включая литералы к ISO формату,
     * который используется в графиках
     */
    transformAnyDateToISO = (period: DateType[]) => {
        let [start, end] = period;

        // Приводим literal дату к формату YYYY-MM-DD (в том числе обрезаем часы и минуты)
        if (this.isLiteralDate(String(start)) || this.isLiteralDate(String(end))) {
            [start, end] = this.convertDates(start, end);
        }

        // Приводим дату к iso формату, чтобы у recharts не было проблем с таймзоной
        const periodFormatted = this.convertDates(start, end, dateConstants.DATE_ISO_FORMAT);

        return this.convertPeriodToNative(periodFormatted);
    };
}

export default new DateUtils();
