import React from 'react';
/* eslint-disable */
export const LogoStat = () => (
    <svg width="85" height="16" viewBox="0 0 85 16" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M27.2373 10.4752C27.1586 10.5721 27.0466 10.669 26.9013 10.7658C26.762 10.8566 26.5955 10.9414 26.4018 11.0201C26.2081 11.0928 25.9901 11.1533 25.748 11.2017C25.5119 11.2502 25.2576 11.2744 24.9852 11.2744C24.4645 11.2744 23.9923 11.1987 23.5685 11.0473C23.1447 10.896 22.7815 10.6811 22.4788 10.4026C22.1821 10.118 21.949 9.77297 21.7795 9.36734C21.6161 8.96172 21.5343 8.50161 21.5343 7.98702C21.5343 7.50875 21.6161 7.06983 21.7795 6.67026C21.943 6.27069 22.1761 5.92561 22.4788 5.63502C22.7815 5.34442 23.1447 5.12042 23.5685 4.96302C23.9983 4.79956 24.4766 4.71783 25.0033 4.71783C25.5058 4.71783 25.9356 4.7814 26.2928 4.90853C26.65 5.02961 26.9225 5.15675 27.1101 5.28994V6.87005C27.0133 6.7308 26.8922 6.59459 26.7469 6.4614C26.6076 6.32821 26.4472 6.21015 26.2656 6.10724C26.09 6.00432 25.8933 5.92259 25.6753 5.86205C25.4634 5.79545 25.2394 5.76215 25.0033 5.76215C24.6582 5.76215 24.3465 5.81664 24.068 5.92561C23.7895 6.03459 23.5534 6.18594 23.3596 6.37967C23.1659 6.5734 23.0176 6.80951 22.9147 7.08799C22.8117 7.36042 22.7603 7.66313 22.7603 7.9961C22.7603 8.30486 22.8057 8.59545 22.8965 8.86788C22.9934 9.13426 23.1326 9.36734 23.3142 9.56713C23.5019 9.76691 23.735 9.92734 24.0135 10.0484C24.292 10.1635 24.6128 10.224 24.9761 10.23C25.2546 10.23 25.5088 10.1998 25.7389 10.1392C25.975 10.0787 26.1869 9.99697 26.3746 9.89405C26.5683 9.79113 26.7378 9.67005 26.8831 9.5308C27.0284 9.39156 27.1465 9.24021 27.2373 9.07675V10.4752Z" fill="#83868D"/>
        <path d="M33.1214 5.86205H30.9783V11.1745H29.8432V5.86205H27.7364V4.81772H33.1214V5.86205Z" fill="#83868D"/>
        <path d="M39.1495 11.1745H37.9417L37.3333 9.66702H34.4183L33.8189 11.1745H32.6656L35.2446 4.81772H36.5342L39.1495 11.1745ZM36.9156 8.62269L35.8712 6.01642L34.8269 8.62269H36.9156Z" fill="#83868D"/>
        <path d="M44.0826 5.86205H41.9395V11.1745H40.8043V5.86205H38.6975V4.81772H44.0826V5.86205Z" fill="#83868D"/>
        <path d="M46.1721 9.56713L49.6411 4.81772H50.8761V11.1745H49.741V6.35242L46.2538 11.1745H45.037V4.81772H46.1721V9.56713Z" fill="#83868D"/>
        <path d="M57.7795 10.4752C57.7008 10.5721 57.5888 10.669 57.4435 10.7658C57.3042 10.8566 57.1378 10.9414 56.944 11.0201C56.7503 11.0928 56.5324 11.1533 56.2902 11.2017C56.0541 11.2502 55.7998 11.2744 55.5274 11.2744C55.0067 11.2744 54.5345 11.1987 54.1107 11.0473C53.687 10.896 53.3237 10.6811 53.021 10.4026C52.7244 10.118 52.4913 9.77297 52.3218 9.36734C52.1583 8.96172 52.0766 8.50161 52.0766 7.98702C52.0766 7.50875 52.1583 7.06983 52.3218 6.67026C52.4852 6.27069 52.7183 5.92561 53.021 5.63502C53.3237 5.34442 53.687 5.12042 54.1107 4.96302C54.5406 4.79956 55.0188 4.71783 55.5455 4.71783C56.048 4.71783 56.4779 4.7814 56.8351 4.90853C57.1922 5.02961 57.4647 5.15675 57.6524 5.28994V6.87005C57.5555 6.7308 57.4344 6.59459 57.2891 6.4614C57.1499 6.32821 56.9894 6.21015 56.8078 6.10724C56.6322 6.00432 56.4355 5.92259 56.2175 5.86205C56.0057 5.79545 55.7817 5.76215 55.5455 5.76215C55.2005 5.76215 54.8887 5.81664 54.6102 5.92561C54.3317 6.03459 54.0956 6.18594 53.9019 6.37967C53.7081 6.5734 53.5598 6.80951 53.4569 7.08799C53.354 7.36042 53.3025 7.66313 53.3025 7.9961C53.3025 8.30486 53.3479 8.59545 53.4387 8.86788C53.5356 9.13426 53.6748 9.36734 53.8565 9.56713C54.0441 9.76691 54.2772 9.92734 54.5557 10.0484C54.8342 10.1635 55.1551 10.224 55.5183 10.23C55.7968 10.23 56.0511 10.1998 56.2811 10.1392C56.5172 10.0787 56.7291 9.99697 56.9168 9.89405C57.1105 9.79113 57.28 9.67005 57.4253 9.5308C57.5706 9.39156 57.6887 9.24021 57.7795 9.07675V10.4752Z" fill="#83868D"/>
        <path d="M63.6637 5.86205H61.5205V11.1745H60.3854V5.86205H58.2786V4.81772H63.6637V5.86205Z" fill="#83868D"/>
        <path d="M65.7532 9.56713L69.2222 4.81772H70.4572V11.1745H69.322V6.35242L65.8349 11.1745H64.618V4.81772H65.7532V9.56713Z" fill="#83868D"/>
        <path d="M73.2468 11.1745H72.1117V4.81772H73.2468V7.48756H74.2004L76.2436 4.81772H77.6239L75.1993 7.84172L78.0144 11.1745H76.5614L74.2548 8.42291H73.2468V11.1745Z" fill="#83868D"/>
        <path d="M84.7411 11.1745H83.5334L82.9249 9.66702H80.0099L79.4106 11.1745H78.2573L80.8363 4.81772H82.1258L84.7411 11.1745ZM82.5072 8.62269L81.4629 6.01642L80.4186 8.62269H82.5072Z" fill="#83868D"/>
        <path fillRule="evenodd" clipRule="evenodd" d="M0 3.49062C0 1.5628 1.5628 0 3.49062 0H12.5094C14.4372 0 16 1.5628 16 3.49062V12.5094C16 14.2101 14.7836 15.6268 13.1734 15.9369V4.48805C13.1734 4.17837 12.9223 3.92732 12.6127 3.92732C12.303 3.92732 12.0519 4.17837 12.0519 4.48805V16H9.78716V5.29266C9.78716 4.98298 9.53611 4.73193 9.22643 4.73193C8.91675 4.73193 8.6657 4.98298 8.6657 5.29266V16H6.3371V6.08806C6.3371 5.77838 6.08605 5.52734 5.77637 5.52734C5.46669 5.52734 5.21564 5.77838 5.21564 6.08806V16H3.49062C3.27042 16 3.05499 15.9796 2.84611 15.9406V7.5426C2.84611 7.23291 2.59506 6.98187 2.28538 6.98187C1.9757 6.98187 1.72465 7.23291 1.72465 7.5426V15.521C1.02236 15.1082 0.477183 14.4569 0.200541 13.6783C0.428866 13.4686 0.731344 13.267 1.10008 13.0757V8.37462C1.10008 8.06511 0.849171 7.81421 0.539663 7.81421C0.282697 7.81421 0.0661252 7.98715 0 8.223V3.49062ZM14.8652 11.1816V10.6163C14.8652 10.3068 14.6143 10.0559 14.3048 10.0559C13.9953 10.0559 13.7444 10.3068 13.7444 10.6163V11.1849C14.1226 11.1841 14.4964 11.1828 14.8652 11.1816ZM11.424 11.2052V10.0559C11.424 9.74635 11.173 9.49545 10.8635 9.49545C10.554 9.49545 10.3031 9.74635 10.3031 10.0559V11.2491C10.6715 11.2304 11.0453 11.2157 11.424 11.2052ZM7.98266 11.427V9.49545C7.98266 9.18594 7.73176 8.93503 7.42225 8.93503C7.11274 8.93503 6.86183 9.18594 6.86183 9.49545V11.5598C7.22594 11.5111 7.59988 11.4668 7.98266 11.427ZM4.54137 11.9561V8.93503C4.54137 8.62553 4.29046 8.37462 3.98096 8.37462C3.67145 8.37462 3.42054 8.62553 3.42054 8.93503V12.2247C3.77397 12.1297 4.14822 12.04 4.54137 11.9561Z" fill="#83868D"/>
    </svg>    
);
