import { Alert, Button, IconCirclePlus, Input, Modal } from '@adtech/ui';
import { EModalAction } from '@components/Funnel';
import FunnelEmptyPlaceholder from '@components/Funnel/components/FunnelEmptyPlaceholder';
import FunnelSidebar from '@components/Funnel/components/FunnelSidebar';
import FunnelStep from '@components/Funnel/components/FunnelStep';
import { closestCenter, DndContext } from '@dnd-kit/core';
import { restrictToParentElement } from '@dnd-kit/modifiers';
import { arrayMove, SortableContext, verticalListSortingStrategy } from '@dnd-kit/sortable';
import useGoals from '@hooks/useGoals';
import { IFunnelDTO, IFunnelStep, IFunnelStepGoal } from '@typings/funnels';
import { IRootSlice } from '@typings/rootSlice';
import { top100Counter } from '@utils/index';
import React, { ChangeEventHandler, useEffect, useRef, useState } from 'react';
import { useSelector } from 'react-redux';

import s from '../Funnel.pcss';

interface IProps {
    data: IFunnelDTO | null;
    isOpen: boolean;
    action: EModalAction;
    onOkHandler: (funnelData: IFunnelDTO) => void;
    onCloseHandler: () => void;
}

const CreateOrEditFunnelModal: React.FC<IProps> = ({ isOpen, action, onOkHandler, onCloseHandler, data }) => {
    const goalsList = useSelector((state: IRootSlice) => state.goalsSlice.tableData) || [];
    const goalsListRequest = useSelector((state: IRootSlice) => state.goalsSlice.tableRequest);

    const MAX_STEPS_LENGTH = 11;
    const GOALS_LIMIT = 100;
    const isEdit = action === EModalAction.edit;
    const title = isEdit ? 'Редактирование' : 'Создание';
    const okText = isEdit ? 'Сохранить' : 'Создать воронку';

    const [inputTitle, setInputTitle] = useState(data?.title || '');
    const [titleStatus, setTitleStatus] = useState('');
    const [steps, setSteps] = useState<IFunnelStep[]>(data?.steps || []);
    const [isStepsNumberNotValid, setIsStepsNumberNotValid] = useState(false); // статус валидации кол-ва шагов
    const [stepsStatuses, setStepsStatuses] = useState<string[]>([]); // статус валидации содержимого шагов
    const [isSidebarActive, setSidebarActive] = useState(false);
    const [stepIndex, setStepIndex] = useState<number>(null);

    const inputTitleRef = useRef(null);
    const resetRequest = useRef(0);
    const addBtnRef = useRef<HTMLDivElement>(null);

    const { getTable: getGoalsList } = useGoals();

    // Перезапрашиваем после создания/редактирования так как цели могли стать публичными
    useEffect(() => {
        getGoalsList({ limit: GOALS_LIMIT, showCommonGoals: true });
    }, [resetRequest.current]);

    useEffect(() => {
        if (isOpen) {
            setInputTitle(data?.title || '');
            setSteps(data?.steps || []);
            // Задержка, чтобы input успел отрендериться
            setTimeout(() => inputTitleRef.current?.focus(), 200);
        }
    }, [isOpen]);

    const titleChangeHandler: ChangeEventHandler<HTMLInputElement> = (e) => {
        setInputTitle(e.target.value);
    };

    const addStep = (isPlaceholderBtn?: boolean) => {
        if (steps.length === MAX_STEPS_LENGTH) return;

        const numbers = steps.length ? steps.map((step) => step.step_number || 0) : [0];
        const maxStepNumber = Math.max(...numbers);

        setSteps([
            ...(steps || []),
            {
                step_number: maxStepNumber + 1,
                step_title: '',
                goals: [],
            },
        ]);

        // Откладываем скролл, чтобы dom успел обновиться
        setTimeout(() => {
            addBtnRef.current?.scrollIntoView({
                behavior: 'smooth',
                block: 'center',
                inline: 'start',
            });
        }, 100);

        top100Counter.trackEvent(
            isPlaceholderBtn ? 'stat_funnel_add_step_placeholder_btn' : 'stat_funnel_add_step_main_btn',
        );
    };
    const deleteStep = (index: number) => {
        setSteps([...steps.slice(0, index), ...steps.slice(index + 1)]);
        setStepsStatuses([]);

        top100Counter.trackEvent('stat_funnel_step_delete_btn');
    };

    const setStepTitle = (value: string, index: number) => {
        const newSteps = structuredClone(steps);
        newSteps[index].step_title = value;
        setSteps(newSteps);
    };

    const chipCloseHandler = (chipIndex: number, index: number) => {
        const newSteps = structuredClone(steps);
        const goals = newSteps[index].goals;
        newSteps[index].goals = [...goals.slice(0, chipIndex), ...goals.slice(chipIndex + 1)];
        setSteps(newSteps);

        top100Counter.trackEvent('stat_funnel_chips_remove_btn');
    };

    const conditionsClickHandler = (index: number) => {
        setSidebarActive(true);
        setStepIndex(index);

        top100Counter.trackEvent('stat_funnel_step_add_condition');
    };

    const selectGoalsHandler = (goals: IFunnelStepGoal[]) => {
        const newSteps = structuredClone(steps || []);
        newSteps[stepIndex].goals = [...goals];
        setSteps(newSteps);
        setSidebarActive(false);
    };

    const goalsTableSearchHandler = (value: string) => {
        getGoalsList({ limit: GOALS_LIMIT, showCommonGoals: true, titleFilter: value });

        top100Counter.trackEvent('stat_funnel_search_bar_events_submit');
    };

    const modalOkHandler = () => {
        const statuses = [];
        let isStepNotValid = false;

        setTitleStatus(inputTitle ? '' : 'error');

        setIsStepsNumberNotValid(!steps?.length || steps?.length < 2);

        // Обновляем step_number для конечного массива
        const newSteps = steps?.map((step, i) => {
            statuses[i] = `${!step?.step_title}-${!step?.goals?.length}`;
            setStepsStatuses(statuses);

            if (!step?.step_title || !step?.goals?.length) {
                isStepNotValid = true;
            }

            return { ...step, step_number: i + 1 };
        });

        if (!inputTitle || !steps?.length || steps?.length < 2 || isStepNotValid) return;

        onOkHandler?.({
            title: inputTitle,
            steps: newSteps,
            ...(data?.id ? { id: data?.id } : {}),
        });

        resetRequest.current += 1;
        setTitleStatus(null);
        setIsStepsNumberNotValid(false);
        setStepsStatuses([]);

        top100Counter.trackEvent(isEdit ? 'stat_funnel_save_funnel_btn' : 'stat_funnel_create_funnel_btn');
    };

    const modalCloseHandler = () => {
        onCloseHandler?.();

        setSteps([]);
        setInputTitle('');
        setTitleStatus(null);
        setIsStepsNumberNotValid(false);
        setStepsStatuses([]);

        top100Counter.trackEvent('stat_funnel_cancel_funnel_btn');
    };

    const dragEndHandler = (event) => {
        const {
            active: { id: oldId },
            over: { id: newId },
        } = event;

        if (oldId !== newId) {
            setSteps((items) => {
                let oldIndex;
                let newIndex;

                items.forEach((item, index) => {
                    if (item.step_number === oldId) oldIndex = index;
                    if (item.step_number === newId) newIndex = index;
                });

                return arrayMove(items, oldIndex, newIndex);
            });
        }

        top100Counter.trackEvent('stat_funnel_move_btn');
    };

    return (
        <Modal
            className={s.funnelsModal}
            isOpened={isOpen}
            title={`${title} воронки`}
            description={`${title} воронок и порядок их отображения на графике`}
            okText={okText}
            cancelText="Отменить"
            onOk={modalOkHandler}
            onClose={modalCloseHandler}
            closeOnClickOutside={false}
            centered
            size="lightbox"
        >
            <div className={s.funnelsModalInner}>
                <div className={s.funnelsModalInputTitle}>Название воронки</div>
                <Input
                    inputRef={inputTitleRef}
                    value={inputTitle}
                    status={titleStatus as any}
                    placeholder="Придумайте название для воронки"
                    errorText="Заполните поле"
                    onChange={titleChangeHandler}
                    allowClear
                    maxLength={255}
                    showCount
                    data-testid="title-input"
                />
                <div className={s.funnelsModalInfo}>Доступно создание от 2 до 11 шагов</div>
                {isStepsNumberNotValid ? (
                    <Alert
                        className={s.funnelsModalAlert}
                        type="warning"
                        showIcon
                        message="Добавьте не менее 2 шагов"
                        data-testid="help-text"
                    />
                ) : null}
                {steps?.length ? (
                    <div className={s.funnelsModalContent}>
                        <DndContext
                            collisionDetection={closestCenter}
                            modifiers={[restrictToParentElement]}
                            onDragEnd={dragEndHandler}
                        >
                            <SortableContext
                                items={steps.map((item) => item?.step_number)}
                                strategy={verticalListSortingStrategy}
                            >
                                {steps.map((stepData: IFunnelStep, index) => (
                                    <FunnelStep
                                        {...stepData}
                                        data={stepData}
                                        id={stepData.step_number}
                                        index={index}
                                        deleteStepHandler={deleteStep}
                                        setStepTitle={setStepTitle}
                                        chipCloseHandler={chipCloseHandler}
                                        conditionsClickHandler={conditionsClickHandler}
                                        validationError={stepsStatuses[index]}
                                    />
                                ))}
                            </SortableContext>
                        </DndContext>
                    </div>
                ) : null}
                <div className={s.funnelsModalAddBtnWrap} ref={addBtnRef}>
                    {steps?.length !== MAX_STEPS_LENGTH ? (
                        <Button
                            className={s.funnelsModalAddBtn}
                            prefixIcon={<IconCirclePlus color="currentColor" />}
                            type="link"
                            onClick={() => addStep()}
                            data-testid="add-button"
                        >
                            Добавить шаг
                        </Button>
                    ) : null}
                </div>
                {!steps?.length ? (
                    <div className={s.funnelsModalEmptyWrap}>
                        <FunnelEmptyPlaceholder onBtnClick={() => addStep(true)} btnText="Добавить шаг" />
                    </div>
                ) : null}
                <FunnelSidebar
                    isActive={isSidebarActive}
                    data={goalsList}
                    dataRequest={goalsListRequest}
                    selectedGoalsData={steps?.[stepIndex]?.goals}
                    searchHandler={goalsTableSearchHandler}
                    closeHandler={() => setSidebarActive(false)}
                    selectGoalsHandler={selectGoalsHandler}
                />
            </div>
        </Modal>
    );
};

export default CreateOrEditFunnelModal;
