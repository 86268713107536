import { imgSrc, imgSet } from '@images/error504';
import React from 'react';

export default {
    imgSrc,
    imgSet: `${imgSet[0]} 2x, ${imgSet[1]} 3x`,
    code: '504',
    title: 'Шлюз не отвечает',
    getContent() {
        return (
            <div>
                <p>Сервер задумался и не успел ответить.</p>
                <p>
                    Попробуйте перезагрузить страницу или поискать нужный счётчик в&nbsp;
                    <a href="/stat/projects" target="_self">
                        Моих сайтах
                    </a>
                    .
                </p>
            </div>
        );
    },
};
