import { PageHeader, Button, IconShare } from '@adtech/ui';
import { Hint } from '@components/Hint';
import { LinksPanel } from '@components/LinksPanel';
import useHint from '@hooks/useHint';
import { IHint } from '@typings/hint';
import { INotification } from '@typings/notification';
import { ReportName } from '@typings/reports';
import React, { ReactNode } from 'react';

import s from './PageHead.pcss';

interface IProps {
    reportName?: ReportName;
    projectId?: number;
    userId?: number;
    title: string;
    hintContent?: IHint;
    labelText?: string;
    extraHeaderNode?: ReactNode;
    extraRightNode?: ReactNode;
    openModalPopup?: (modalPopupId: string, modalPopupParams?: Record<string, unknown>) => void;
    setNotification?: (value: INotification) => void;
    onClickTitle?: () => void;
    back?: {
        to: string;
        title: string;
    };
    onOpenHintChange?: (open: boolean) => void;
    closeHintHandler?: () => void;
    clickHintLinkHandler?: () => void; // Клик по ссылке на документацию
}

const PageHead: React.FC<IProps> = ({
    reportName,
    projectId,
    userId,
    title = '',
    hintContent,
    labelText,
    extraHeaderNode = null,
    extraRightNode = null,
    onClickTitle,
    back = {
        to: '/stat/projects',
        title: 'Мои сайты',
    },
    onOpenHintChange = () => {},
    closeHintHandler = () => {},
    clickHintLinkHandler = () => {},
}) => {
    const { handleHiddenHint, isHiddenHint, showHintOnClick, handleShowHint } = useHint(
        projectId,
        userId,
        reportName,
        hintContent,
    );
    const renderTitleHead: ReactNode =
        typeof onClickTitle === 'function' ? (
            <button type="button" className={s.titleHeadButton} onClick={onClickTitle}>
                {title}
            </button>
        ) : (
            title
        );

    const onCloseHint = () => {
        handleHiddenHint();
        closeHintHandler();
    };

    const isOpen = !isHiddenHint || showHintOnClick;
    const showHint = isOpen ? () => {} : handleShowHint;
    const renderTitle: ReactNode = (
        <div className={s.title} data-testid="title-container">
            <div className={s.titleInner}>
                <h1 className={s.titleHead} data-testid="title-text">
                    {renderTitleHead}
                </h1>
                {extraHeaderNode && <span className={s.titleExtraNode}>{extraHeaderNode}</span>}
                {hintContent && (
                    <Hint
                        open={isOpen}
                        showButtonClose
                        tooltipPlacement="rightTop"
                        overlayInnerStyle={{ width: '400px' }}
                        className={s.titleHintAnchor}
                        handleClose={onCloseHint}
                        handleShowHint={showHint}
                        trigger="click"
                        onOpenChange={onOpenHintChange}
                    >
                        <div className={s.titleHint}>
                            <div dangerouslySetInnerHTML={{ __html: hintContent.message }} />
                            {hintContent.link && (
                                <Button
                                    type="link"
                                    href={hintContent.link}
                                    className={s.titleHintLink}
                                    suffixIcon={<IconShare color="currentColor" />}
                                    onClick={clickHintLinkHandler}
                                >
                                    Документация
                                </Button>
                            )}
                        </div>
                    </Hint>
                )}
                {labelText && <span className={s.titleLabelText}>{labelText}</span>}
            </div>
        </div>
    );

    return (
        <div className={s.root}>
            <LinksPanel />
            <PageHeader title={renderTitle} extra={extraRightNode} {...(back ? { back } : {})} />
        </div>
    );
};

export default PageHead;
