import { Button, Loader } from '@adtech/ui';
import Input from '@components/Input';
import { ISearchParams, ISearhResultItem } from '@typings/search';
import { arrayUtils } from '@utils/index';
import cn from 'classnames';
import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';

import s from './Search.pcss';

interface IProps {
    rootClassName?: string;
    getSearchResults: (newParams: ISearchParams) => void;
    results: ISearhResultItem[];
    resultRequest: boolean;
    placeholder: string;
    isLinks?: boolean;
    onSelectResult?;
    dataAttrName?: string;
    dataAttrSearch?: string | string[];
    dataAttrList?: string[];
    isLoadMore?: boolean;
}

const Search: React.FC<IProps> = ({
    rootClassName = '',
    getSearchResults = () => {},
    results,
    resultRequest,
    placeholder,
    isLinks = false,
    onSelectResult = () => {},
    dataAttrName = '',
    dataAttrSearch = '',
    dataAttrList = [],
    isLoadMore = false,
}) => {
    const [focus, setFocus] = useState(true);
    const [searchValue, setSearchValue] = useState('');

    const requestResults = () => {
        if (searchValue) setSearchValue('');

        getSearchResults({ limit: 10 });
    };

    useEffect(() => {
        requestResults();
    }, []);

    const onChange = (value: string) => {
        setSearchValue(value);
        setFocus(true);

        let params: ISearchParams = { limit: 10 };

        if (value) {
            params = {
                ...params,
                offset: 0,
                search: value,
            };
        }

        getSearchResults(params);
    };

    const onSelectResultHandler = (item: string | JSX.Element) => {
        if (onSelectResult) onSelectResult(item);
    };

    const onClickLoadMoreHandler = () => {
        const length = results.length;

        const params: ISearchParams = {
            limit: length + 10,
            offset: length,
            search: searchValue,
        };

        getSearchResults(params);
    };

    const renderResults = (): React.ReactNode[] | React.ReactNode => {
        if (!results.length && !resultRequest) {
            return <div className={s.searchResultEmpty}>Нет данных для отображения</div>;
        }

        return results.map((item: ISearhResultItem) => {
            const { element, key, href } = item;
            const isActiveItem = !!item?.active;

            const itemClassName = cn(s.searchResultItem, {
                [s.searchResultItemActive]: isActiveItem,
            });

            const commonProps = {
                children: element,
                className: itemClassName,
                ...(dataAttrName && dataAttrList.length
                    ? {
                          [`data-${dataAttrName}`]: `${dataAttrList.join('::')}`,
                      }
                    : {}),
            };

            return isLinks ? (
                <Link
                    key={key}
                    {...commonProps}
                    to={href}
                    {...(!isActiveItem ? { onClick: () => onSelectResultHandler(element) } : {})}
                />
            ) : (
                <span
                    key={key}
                    {...commonProps}
                    {...(!isActiveItem ? { onClick: () => onSelectResultHandler(element) } : {})}
                />
            );
        });
    };

    const searchAttr = arrayUtils.convertToStringWithSeparator(dataAttrSearch, '::');

    return (
        <div className={cn(s.search, rootClassName)}>
            <Input
                focus={focus}
                value={searchValue}
                onChange={onChange}
                onClear={requestResults}
                placeholder={placeholder}
                className={s.searchInput}
                classNameRoot={s.searchInputBlock}
                dataAttrName={dataAttrName}
                dataAttr={searchAttr}
            />

            <Loader loading={resultRequest} className={s.loader} size="middle">
                <div className={s.searchResult}>
                    {renderResults()}
                    {isLoadMore && (
                        <Button onClick={() => onClickLoadMoreHandler()} type="dashed" className={s.buttonLoadMore}>
                            Показать ещё
                        </Button>
                    )}
                </div>
            </Loader>
        </div>
    );
};

export default Search;
