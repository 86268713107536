import { Button, Modal } from '@adtech/ui';
import useModalNotification from '@hooks/useModalNotification';
import { INotification } from '@typings/notification';
import { IRootSlice } from '@typings/rootSlice';
import { storagesUtils } from '@utils/index';
import React, { useEffect, useState, useRef } from 'react';
import { useSelector } from 'react-redux';

import s from './ModalNotification.pcss';

interface IProps {
    notificationProp?: INotification | null;
}

const ModalNotification: React.FC<IProps> = ({ notificationProp }) => {
    const [isOpened, setOpenNotify] = useState(false);
    const isFirstRender = useRef(true);

    let notification = useSelector((state: IRootSlice) => state.globalSlice.modalNotification);
    const userId = useSelector((state: IRootSlice) => state.userSlice.id);

    if (notificationProp) notification = notificationProp;

    const { clearNotification } = useModalNotification();

    const { storageKey } = notification || {};

    /**
     * useEffect следит за изменениями notification и
     * чтобы контент не просто подменился, а сработала
     * анимация, добавляем setTimeout.
     */
    useEffect(() => {
        let timer;

        if (notification?.body && !isFirstRender.current) {
            setOpenNotify(false);

            timer = setTimeout(() => {
                setOpenNotify(true);
            }, 500);
        } else {
            setOpenNotify(false);
            isFirstRender.current = false;
        }

        return () => clearTimeout(timer);
    }, [notification]);

    /**
     * Если нужен колбек именно только по закрытию на крестик
     */
    const closeNotification = () => {
        clearNotification();

        // Если указан параметр-ключ в localStorage, то используем его для скрытия уведомления
        if (storageKey) {
            storagesUtils.set(storageKey, 'true', userId);
        }
    };

    const renderMessage = (
        <div className={s.inner}>
            <div className={s.icon} />
            {notification?.title && <h3 className={s.title}>{notification.title}</h3>}
            {notification?.body && (
                <div className={s.content} dangerouslySetInnerHTML={{ __html: notification.body }} />
            )}
            <Button type="dashed" size="middle" className={s.button} onClick={closeNotification}>
                Все равно продолжить
            </Button>
        </div>
    );

    return (
        (notification?.body && isOpened && (
            <Modal className={s.wrapper} cancelText="" okText="" isOpened onClose={closeNotification}>
                {renderMessage}
            </Modal>
        )) ||
        null
    );
};

export default ModalNotification;
