import cn from 'classnames';
import React from 'react';
import s from './Grid.pcss';

interface IProps {
    children: React.ReactNode;
    className?: string;
}

const Grid: React.FC<IProps> = ({ children = null, className = '' }) => {
    const gridClassNames = cn(s.grid, className);

    const renderWidgets = () =>
        React.Children.map(children, (child: React.ReactElement) => {
            if (child) {
                const classNameColumn = cn(s.gridColumn, s[`gridColumn_${child.props.size || 1}`]);
                return <div className={classNameColumn}>{child}</div>;
            }

            return null;
        });

    return (
        <div className={gridClassNames}>
            <div className={s.gridRow}>{renderWidgets()}</div>
        </div>
    );
};

export default Grid;
