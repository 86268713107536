/* eslint-disable max-len */
import { ChartTypes } from '@configs/graph';
import { sortOrders } from '@constants/sort';
import { IReportConfig } from '@typings/reports';

// пока не используется
const reportConfig: IReportConfig = {
    reportTitle: 'Время на сайте',
    tableDataType: 'static',
    defaultSort: {
        name: 'visits',
        order: sortOrders.DESC,
    },
    numberOfGraphs: 'two',
    defaultGraphs: [
        ChartTypes.bar,
        ChartTypes.bar,
    ],
    graphDefaultMetrics: [
        'visits',
        'avg_view_depth',
    ],
    tableDefaultMetrics: [
        // 'visitors',
        'visits',
        // 'page_views',
        'avg_view_depth',
    ],
    availableMetrics: [
        // 'visitors',
        'visits',
        // 'page_views',
        // 'bounces',
        'avg_view_depth',
        'uid',
        'rambler_id',
    ],
    titleAliases: {
        0: 'Менее 1 секунды',
        1: 'От 1 до 10 секунд',
        10: 'От 10 до 30 секунд',
        30: 'От 30 до 60 секунд',
        60: 'От 1 до 2 минут',
        120: 'От 2 до 3 минут',
        180: 'От 3 до 4 минут',
        240: 'От 4 до 5 минут',
        300: 'От 5 до 10 минут',
        600: 'От 10 до 20 минут',
        1200: 'От 20 до 30 минут',
        1800: 'От 30 до 60 минут',
        3600: 'Более часа',
    },
    isShowTotals: true,
    availableTools: {
        numberOfGraphs: true,
        periodPicker: true,
        sample: true,
        activeSample: true,
        filters: true,
        savedFilters: true,
        detail: true,
    },
    reportHint: {
        message: 'Среднее время присутствия посетителя на страницах вашего сайта с группировкой по интервалам. Доступные показатели: посетители, визиты, просмотры, глубина просмотра, отказы.',
    },
};

export default reportConfig;
