import { IReportConfig } from '@typings/reports';

import funnelsParams from '../funnels/funnels';
import adSystemsParams from './ad_systems';
import ageParams from './age';
import aggrnewsParams from './aggrnews_system';
import browserParams from './browser';
import constructorParams from './constructor';
import customVarsParams from './custom_vars';
import deviceParams from './device';
import drilldownSources from './drilldown_sources';
import drilldownWidth from './drilldown_width';
import entrancePagesParams from './entrance_pages';
import exchangeParams from './exchange_system';
import exitPagesParams from './exit_pages';
import geoParams from './geo';
import likesParams from './likes';
import messageParams from './message_system';
import openStatParams from './openstat';
import osParams from './os';
import pageArgsParams from './page_args';
import pageFormatsParams from './page_formats';
import pageTitlesParams from './page_titles';
import pageUrlsParams from './page_urls';
import qrParams from './qr_system';
import ratingParams from './rating';
import recomParams from './recom_system';
import screensizeParams from './screensize';
import searchPhraseSystemParams from './search_phrase_system';
import searchSystemPhraseParams from './search_system_phrase';
import segmentsParams from './segments';
import sexParams from './sex';
import sitesParams from './sites';
import socialParams from './social_network';
import summaryParams from './summary';
import trafficSourcesParams from './traffic_sources';
import usabilityParams from './usability';
import utmsParams from './utms';
import viewDepthParams from './view_depth';
import visitTimeParams from './visit_time';

const reportsConfig: Record<string, IReportConfig> = {
    summary: summaryParams,
    browser: browserParams,
    device: deviceParams,
    os: osParams,
    screensize: screensizeParams,
    page_formats: pageFormatsParams,
    usability: usabilityParams,
    page_urls: pageUrlsParams,
    page_args: pageArgsParams,
    page_titles: pageTitlesParams,
    entrance_pages: entrancePagesParams,
    exit_pages: exitPagesParams,
    traffic_sources: trafficSourcesParams,
    custom_vars: customVarsParams,
    geo: geoParams,
    utms: utmsParams,
    sex: sexParams,
    age: ageParams,
    segments: segmentsParams,
    view_depth: viewDepthParams,
    rating: ratingParams,
    visit_time: visitTimeParams,
    openstat: openStatParams,
    ad_systems: adSystemsParams,
    likes: likesParams,
    sites: sitesParams,
    search_phrase_system: searchPhraseSystemParams,
    search_system_phrase: searchSystemPhraseParams,
    drilldown_sources: drilldownSources,
    drilldown_width: drilldownWidth,
    constructor: constructorParams,
    aggrnews_system: aggrnewsParams,
    exchange_system: exchangeParams,
    recom_system: recomParams,
    social_network: socialParams,
    qr_system: qrParams,
    message_system: messageParams,
    funnels: funnelsParams,
};

export default reportsConfig;
