import { FormDecoratorContext } from '@decorators/formDecorator/context';

import isEmpty from 'lodash/isEmpty';
import isEqual from 'lodash/isEqual';
import React from 'react';

import { IProps, IState } from './types';

class ComponentForm extends React.Component<IProps, IState> {
    state = {
        asyncInitialized: [],
    };

    isUnmounting = false;

    componentDidMount() {
        const { createForm, params, initialValues } = this.props;

        this.isUnmounting = false;

        createForm(params.formName, initialValues);
    }

    componentDidUpdate(prevProps: IProps) {
        let { asyncInitialized } = this.state;
        const { asyncInitializeValues, isInitializeValues } = this.props;

        if (!prevProps.isInitializeValues && isInitializeValues) {
            this.initializeValues(this.props.initialValues);
        }

        if (asyncInitializeValues) {
            let scopeData = {};
            asyncInitializeValues.forEach((item, index) => {
                if (!asyncInitialized.includes(index) && item.isInitializeValues) {
                    asyncInitialized = [...asyncInitialized, index];
                    scopeData = { ...scopeData, ...item.initialValues };
                }
            });

            // сверяем новые асинхронные initialValues, с текущим
            const formInitialValues = this.props.forms[this.props.params.formName].initialValues;
            const scopeInitialValues = { ...formInitialValues, ...scopeData };

            // если новые асинхронные initialValues - не пустые,
            // и различаются от текущего - обновляем
            if (!isEmpty(scopeData) && !isEqual(formInitialValues, scopeInitialValues)) {
                this.setState({ asyncInitialized });
                this.initializeValues(scopeData);
            }
        }
    }

    componentWillUnmount() {
        const { params } = this.props;

        if (!params.isRemoveForm) {
            return;
        }

        this.isUnmounting = true;

        this.removeForm();
    }

    /**
     * Инициализация формы
     */
    initializeValues = (
        values: Record<string, any>,
        isChangeFields: boolean = true,
        isForceUpdate: boolean = false,
    ) => {
        const { params, initializeValues } = this.props;

        initializeValues(params.formName, values, isForceUpdate);
        if (isChangeFields) {
            this.changeValueFields(values);
        }
    };

    /**
     * Удаление формы
     */
    removeForm = () => {
        const { removeForm, params } = this.props;

        removeForm(params.formName);
    };

    /**
     * Сброс формы
     */
    resetForm = () => {
        const { resetForm, params } = this.props;

        resetForm(params.formName);
    };

    /**
     * Удаление поля из формы
     */
    removeField = (fieldName: string, parentFieldName: string[] = []) => {
        const { removeField, forms, params } = this.props;

        const form = forms[params.formName];

        if (!form) return;

        const field = form.fields[fieldName];

        if (!field) return;

        removeField(params.formName, parentFieldName, fieldName, field.mod);
    };

    /**
     * Изменение значений в форме
     */
    changeValueFields = (values: Record<string, any>) => {
        const { params, forms } = this.props;

        Object.keys(values).forEach((key) => {
            const value = values[key];
            const form = forms[params.formName];

            if (form && form.fields[key]) {
                const field = form.fields[key];

                if (field.mod === 'object') {
                    Object.keys(value).map((k) => this.changeField(k, { value: value[k] }, [key], field.value[k].mod));
                } else {
                    this.changeField(key, { value });
                }
            }
        });
    };

    /**
     * Изменение поля
     */
    changeField = (
        fieldName: string,
        fieldData: Record<string, any>,
        parentFieldName: string[] = [],
        mod: string = 'default',
    ) => {
        const { params, changeField } = this.props;

        changeField(params.formName, parentFieldName, fieldName, mod, fieldData);
    };

    /**
     * Устанавливаем ошибки
     */
    setFieldErrors = (errors: Record<string, any>) => {
        const { params, setFieldErrors } = this.props;

        setFieldErrors(params.formName, errors);
    };

    setServerError = (serverError: string) => {
        const { params, setServerError } = this.props;

        setServerError(params.formName, serverError);
    };

    render() {
        const { WrappedComponent, ...rest } = this.props;
        const { params, forms } = this.props;

        const currentForm = forms[params.formName];

        if (!currentForm) {
            return null;
        }

        const props = {
            ...rest,
            initialValues: currentForm.initialValues,
            removeField: this.removeField,
            removeForm: this.removeForm,
            resetForm: this.resetForm,
            initializeValues: this.initializeValues,
            changeField: this.changeField,
            changeValueFields: this.changeValueFields,
            setFieldErrors: this.setFieldErrors,
            setServerError: this.setServerError,
        };

        return (
            <FormDecoratorContext.Provider
                value={{
                    currentFormName: params.formName,
                    isRemoveForm: params.isRemoveForm,
                    checkUnmounting: () => this.isUnmounting,
                }}
            >
                <WrappedComponent {...props} />
            </FormDecoratorContext.Provider>
        );
    }
}

export default ComponentForm;
