import { Button, Input } from '@adtech/ui';
import { formUtils } from '@utils/index';
import React, { FC, useState } from 'react';

import s from './TemplateForms.pcss';

interface IProps {
    name: string;
    type: string;
    isEdited: boolean;
    saveTemplate: (templateName: string) => void;
    closePopup: () => void;
}

// TODO: скрываем уровни доступа пока не поддерживаются
// const PRIVATE = 'private';
// const SHARED = 'shared';

const SaveTemplate: FC<IProps> = ({
    name = '',
    // type = SHARED,
    // isEdited = false,
    saveTemplate,
    closePopup,
}: IProps) => {
    const [templateName, setTemplateName] = useState(name);
    const [validName, setValidName] = useState({ isValid: true, message: '' });
    // const [templateType, setTemplateType] = useState(type);

    // Изменение названия шаблона
    const handleChangeName = (e: React.ChangeEvent<HTMLInputElement>) => {
        setTemplateName(e.target.value);
    };

    // TODO: скрываем уровни доступа пока не поддерживаются
    // Изменение типа шаблона
    // const handleSetType = (newChecked: boolean) => {
    //     const newType = newChecked ? SHARED : PRIVATE;

    //     setTemplateType(newType);
    // };

    // Валидация имени шаблона, от 1 до 255 символов
    const validate = (value: string) => {
        let validateTitle = {
            isValid: true,
            message: undefined,
        };

        const trimValue = value.trim();
        const validRequired = formUtils.defaultRules.required(trimValue);

        if (validRequired !== true) {
            validateTitle = {
                isValid: false,
                message: validRequired,
            };
        } else {
            const validMaxLength = formUtils.defaultRules.maxLength(255)(trimValue);

            if (validMaxLength !== true) {
                validateTitle = {
                    isValid: false,
                    message: validMaxLength,
                };
            }

            const validMinLength = formUtils.defaultRules.minLength(3)(trimValue);

            if (validMinLength !== true) {
                validateTitle = {
                    isValid: false,
                    message: validMinLength,
                };
            }
        }

        setValidName(validateTitle);
        return validateTitle.isValid;
    };

    // Валидация названия шаблона
    const validateName = (e: React.ChangeEvent<HTMLInputElement>) => {
        validate(e.target.value);
    };

    // Сохранение шаблона
    const handleSaveTemplate = () => {
        const isValidName = validate(templateName);
        if (isValidName) {
            saveTemplate(templateName);
            closePopup();
        }
    };

    const handleKeyPress = (e: { key: string }) => {
        if (e.key === 'Enter') {
            handleSaveTemplate();
        }
    };

    // TODO: скрываем уровни доступа пока не поддерживаются
    // const checked = templateType === SHARED;
    // const disabled = isEdited;

    return (
        <>
            <div className={s.templateForm}>
                <div className={s.label}>Название отчёта</div>
                <Input
                    value={templateName}
                    type="text"
                    onChange={handleChangeName}
                    onBlur={validateName}
                    placeholder="Введите название отчёта"
                    status={validName.isValid ? null : 'error'}
                    errorText={validName.message}
                    onKeyPress={handleKeyPress}
                />
            </div>
            <div>
                {
                    // TODO: скрываем уровни доступа пока не поддерживаются
                    /* <Checkbox
                    checkboxClassName={s.checkboxButton}
                    checked={checked}
                    disabled={disabled}
                    onCheck={() => handleSetType(!checked)}
                    variation="awesome"
                >
                    <div className={s.checkboxTitle}>
                        Общий отчёт
                    </div>
                    <div className={s.checkboxDescription}>
                        Все пользователи с доступом к статистике сайта смогут использовать этот
                        отчёт. Его можно будет найти в «Конструкторе отчётов».
                    </div>
                </Checkbox> */
                }
                <div className={s.buttons}>
                    <Button onClick={handleSaveTemplate}>Сохранить</Button>
                </div>
            </div>
        </>
    );
};

export default SaveTemplate;
