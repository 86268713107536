import conversionsReducer from '@redux/slices/conversions';
import dashboardReducer from '@redux/slices/dashboard';
import formsReducer from '@redux/slices/forms';
import globalReducer from '@redux/slices/global';
import goalsReducer from '@redux/slices/goals';
import graphReducer from '@redux/slices/graph';
import latencyReducer from '@redux/slices/latency';
import mediaReducer from '@redux/slices/media';
import projectsReducer from '@redux/slices/projects';
import reportsReducer from '@redux/slices/reports';
import tableReducer from '@redux/slices/table';
import templatesReducer from '@redux/slices/templates';
import usersReducer from '@redux/slices/users';
import funnelsReducer from './funnels/index';
import postClickReducer from './postClick/index';

const reducer = {
    userSlice: usersReducer,
    projectsSlice: projectsReducer,
    globalSlice: globalReducer,
    formsSlice: formsReducer,
    latencySlice: latencyReducer,
    tableSlice: tableReducer,
    graphSlice: graphReducer,
    reportsSlice: reportsReducer,
    dashboardSlice: dashboardReducer,
    mediaSlice: mediaReducer,
    templatesSlice: templatesReducer,
    goalsSlice: goalsReducer,
    conversionsSlice: conversionsReducer,
    postClickSlice: postClickReducer,
    funnelsSlice: funnelsReducer,
};

export default reducer;
