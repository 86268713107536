import { IMediaConfig } from '@typings/media';

import authorParams from './author';
import authorsSummaryParams from './authors_summary';
import materialParams from './material';
import materialsSummaryParams from './materials_summary';
import topParams from './top';

const mediaConfig: Record<string, IMediaConfig> = {
    top: topParams,
    materials_summary: materialsSummaryParams,
    material: materialParams,
    authors_summary: authorsSummaryParams,
    author: authorParams,
};

export default mediaConfig;
