import { EFilterType } from '../types/filters';

export const rulesLength = {
    [EFilterType.checkbox]: 3,
    [EFilterType.textarea]: 3,
    [EFilterType.radio]: 1,
    [EFilterType.event]: 1,
    [EFilterType.period]: 2,
    [EFilterType.datePeriod]: 2,
};
