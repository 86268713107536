import { Checkbox } from '@adtech/ui';
import { IDataListItem } from '@components/DataList';
import WidgetFilter from '@components/WidgetFilter';
import { isFunction } from '@utils/typesChecks';
import React, { FC, useRef, useState } from 'react';

interface IProps {
    onApply: (value: string) => void;
}

const TrafficSourcesWidgetFilter: FC<IProps> = ({ onApply }) => {
    const [sourceChecked, setSourceChecked] = useState<string>(null);
    const prevSource = useRef<string>(null);

    const onCheckSource = (checked: boolean, item: IDataListItem) => {
        const { name } = item;
        const value = checked ? name : null;

        setSourceChecked(value);
    };

    const renderListItem = (item: IDataListItem) => {
        const { name, title } = item;
        const checkedStatus = sourceChecked === name || false;

        return (
            <Checkbox
                key={name}
                name={name}
                checked={checkedStatus}
                onChange={(e) => onCheckSource(e?.target?.checked, item)}
            >
                {title}
            </Checkbox>
        );
    };

    const onCloseDropdown = () => {
        if (prevSource.current === sourceChecked) return;

        prevSource.current = sourceChecked;
        if (isFunction(onApply)) onApply(sourceChecked);
    };

    const sourcesList = [
        {
            title: 'Рекламные системы',
            name: 'adv',
        },
        {
            title: 'Рекомендательные системы',
            name: 'recom',
        },
        {
            title: 'Социальные сети',
            name: 'social',
        },
        {
            title: 'Обменные сети',
            name: 'exchange',
        },
        {
            title: 'Поисковые системы',
            name: 'search',
        },
        {
            title: 'Сайты / referer',
            name: 'site',
        },
        {
            title: 'Мессенджеры',
            name: 'messenger',
        },
    ];

    return <WidgetFilter list={sourcesList} renderListItemHandler={renderListItem} onClose={onCloseDropdown} />;
};

export default TrafficSourcesWidgetFilter;
