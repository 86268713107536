import dateConstants from '@constants/date';
import { LatencyKeys } from '@constants/latency';
import { useAppDispatch } from '@hooks/useStore';
import { latencyActions } from '@redux/slices/latency';
import { IRootSlice } from '@typings/rootSlice';
import DateUtils from '@utils/date';
import { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';

const useLatency = (key: LatencyKeys) => {
    const params: IUrlParams = useParams();
    const { reportName } = params;

    const dispatch = useAppDispatch();
    const serverTime = useSelector((state: IRootSlice) => state.globalSlice.serverTime);
    const latencySlice = useSelector((state: IRootSlice) => state.latencySlice);
    const latencyCnf = latencySlice[key];
    let getLatency;

    switch (key) {
        case LatencyKeys.base: {
            getLatency = () => dispatch(latencyActions.getLatency()).unwrap();
            break;
        }
        case LatencyKeys.today: {
            const todayDate = DateUtils.getDate(serverTime).format(dateConstants.DATE_FORMAT);
            getLatency = () => dispatch(latencyActions.getLatencyToday(todayDate));
            break;
        }
        default:
            getLatency = null;
    }

    /**
     * Получаем границы интервала если это необходимо
     */
    useEffect(() => {
        if (!latencyCnf) {
            getLatency();
        }
    }, [reportName]);

    return getLatency;
};

export default useLatency;
