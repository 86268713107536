import { Loader } from '@adtech/ui';
import Dropdown from '@components/Dropdown';
import Search from '@components/Search';
import { IAuthor } from '@typings/media';
import { ISearchParams } from '@typings/search';
import { urlUtils } from '@utils/index';
import React, { useState, useEffect } from 'react';

import s from './AuthorsList.pcss';

interface IProps {
    projectId: number;
    authorsRequest?: boolean;
    authorInfo?: IAuthor;
    authors?: {
        data: IAuthor[];
        dataTotals: number;
    };
    updateSearchAuthors?: (newParams: ISearchParams) => void;
}

const AuthorsList: React.FC<IProps> = ({
    projectId,
    authorsRequest = false,
    authorInfo = null,
    authors = {
        data: [],
        dataTotals: 0,
    },
    updateSearchAuthors = () => {},
}) => {
    const [isDropdownHidden, setIsDropdownHidden] = useState(false);
    const isLoadMoreAuthors = authors.data.length < authors.dataTotals;

    useEffect(() => {
        setIsDropdownHidden(false);
    });

    const handleClickAuthor = () => {
        updateSearchAuthors({ search: '' });
        setIsDropdownHidden(true);
    };

    const prepareDataAuthors = () => {
        const result = [];

        authors.data.forEach((item) => {
            const itemId = item.id || '';
            const href = urlUtils.generateUrl(projectId, { name: 'author', id: itemId }, 'media');

            result.push({
                element: item.name,
                key: item.id,
                href,
                active: itemId === authorInfo.id,
            });
        });
        return result;
    };

    const formSearchAuthors = (
        <Search
            getSearchResults={updateSearchAuthors}
            results={prepareDataAuthors()}
            resultRequest={authorsRequest}
            placeholder="Искать по автору (минимум 3 символа)"
            onSelectResult={handleClickAuthor}
            isLoadMore={isLoadMoreAuthors}
            isLinks
        />
    );

    return (
        <div className={s.root}>
            <Loader loading={authorsRequest && !authorInfo.name} className={s.loader} size="small">
                <Dropdown
                    contentClassName={s.authorsSearch}
                    type="customContent"
                    customContent={formSearchAuthors}
                    anchorText={authorInfo?.name || ''}
                    isHidden={isDropdownHidden}
                />
            </Loader>
        </div>
    );
};

export default AuthorsList;
