import StarsPoll from '@components/StarsPoll';
import { ImageCounter } from '@images/svg/counter';
import { top100Counter } from '@utils/index';
import React from 'react';
import { NavigateFunction } from 'react-router-dom';

import s from '../ModalPopup.pcss';
import { PopupHeader } from '../components/PopupHeader';

export default {
    getContent({ projectId }: Record<string, number>, navigate: NavigateFunction) {
        let mark;

        const popupHeader = <PopupHeader title={`Счётчик ${projectId} успешно создан`} image={<ImageCounter />} />;

        const onApply = (num: number) => {
            mark = num + 1;
        };

        const popupContent = (
            <div className={s.content}>
                Данные в статистике появятся через 5-10 минут после корректной установки счётчика на сайт
                <br />
                Оцените удобство создания и настройки счётчика
                <StarsPoll onApply={onApply} />
            </div>
        );

        const goToProjects = () => {
            navigate('/stat/projects');

            top100Counter.trackEvent('create-poll', {
                creation_rating: mark,
                project: projectId,
            });
        };

        const okButton = {
            className: s.btnShowProjects,
            type: 'default',
            onClick: goToProjects,
            children: 'Мои сайты',
            closePopupOnClick: true,
        };

        return {
            popupClassName: s.createCounterFinal,
            width: 'lg',
            title: popupHeader,
            content: popupContent,
            buttonContainerClassName: s.buttons,
            okButton,
            onClose: goToProjects,
        };
    },
};
