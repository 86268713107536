import restrictAccessCnf from '@configs/userRestrictAccessSettings';
import { IRootSlice } from '@typings/rootSlice';
import { useSelector } from 'react-redux';

export interface IAccessCnf {
    isAccessRestricted: boolean;
    reports?: string[];
    constructorDimensions?: string[];
}

export const useRestrictAccessByEmail = (): IAccessCnf => {
    const userEmail = useSelector((state: IRootSlice) => state.userSlice.email);
    let result = { isAccessRestricted: false };

    Object.keys(restrictAccessCnf).forEach((email) => {
        if (userEmail.includes(email)) {
            result = {
                isAccessRestricted: true,
                ...restrictAccessCnf[email],
            };
        }
    });

    return result;
};
