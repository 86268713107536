import Dropdown from '@components/Dropdown';
import attributesData from '@configs/attributesData';
import { ICategories } from '@typings/projects';
import React from 'react';

interface IProps {
    currentCategory?: string;
    categories?: ICategories[];
    changeRatingCategory?: (value: string) => void;
}

const CategoriesList: React.FC<IProps> = ({
    currentCategory = '0',
    categories = [],
    changeRatingCategory = () => {},
}) => {
    const newreportAttr = attributesData.newreport;
    const ratingAttr = newreportAttr.rating;
    const dataCategories = categories.map((item) => ({ title: item.name, value: item.id }));

    return (
        <Dropdown
            type="selectSingle"
            onApply={changeRatingCategory}
            data={dataCategories}
            dataInitialSelected={currentCategory}
            dataAttrName={newreportAttr.attrName}
            dataAttrAnchor={[ratingAttr.attrName, ratingAttr.categoryBtn]}
            dataAttrList={[ratingAttr.attrName, ratingAttr.setCategory]}
        />
    );
};

export default CategoriesList;
