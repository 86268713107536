import { ChartColorsMin } from '@configs/graph';

export default {
    male: {
        title: 'Мужчины',
        color: ChartColorsMin[0],
    },
    female: {
        title: 'Женщины',
        color: ChartColorsMin[1],
    },
    other: {
        title: 'Не определено',
        color: ChartColorsMin[2],
    },
};
