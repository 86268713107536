import { Input } from '@adtech/ui';
import fieldDecorator from '@decorators/fieldDecorator';
import { IField } from '@typings/form';
import React, { HTMLInputTypeAttribute } from 'react';

interface IInputProps {
    field: IField;
    type: HTMLInputTypeAttribute;
    placeholder: string;
}

export default fieldDecorator({
    ignoreHidden: true,
    type: 'number',
})(({ field, placeholder, type }: IInputProps) => <Input {...field} type={type} placeholder={placeholder} />);
