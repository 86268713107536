import cn from 'classnames';
import * as React from 'react';

import s from './Page.pcss';

interface IProps {
    title?: string;
    children: React.ReactNode;
    rootClass?: string;
    rowClass?: string;
    contentClass?: string;
}

export const Page: React.FC<IProps> = (props) => {
    const { rootClass = '', rowClass = '', contentClass = '', title = '', children } = props;

    return (
        <div className={cn(s.root, rootClass)}>
            <div className={cn(s.row, rowClass)}>
                {title && <div className={s.title}>{title}</div>}
                <div className={cn(s.content, contentClass)}>{children}</div>
            </div>
        </div>
    );
};

export default Page;
