import Chart from '@components/ChartWithLegend/Chart';
import Legend from '@components/ChartWithLegend/Legend';
import { IGraphColorsData } from '@typings/graph';
import React, { useState, useEffect } from 'react';

interface IProps {
    data: IGraphColorsData[];
    children: any; // React.Node - не используем, т.к в коде есть вызовы children, как к массиву
    graphTypes: string[];
    rootClassName?: string;
    legendClassName?: string;
    classNameItem?: string;
    chartClassName?: string;
}

const ChartWithLegend: React.FC<IProps> = ({
    data,
    chartClassName,
    rootClassName,
    legendClassName,
    classNameItem,
    children,
    graphTypes,
}) => {
    const [customGraphColors, setCustomGraphColors] = useState([]);
    const [isTransparent, setIsTransparent] = useState(false);
    const [resetColors, setResetColors] = useState([]);

    const getCustomColors = (dataArr: IGraphColorsData[]): string[] => dataArr.map((item) => item.color);

    useEffect(() => {
        const newCustomGraphColors = getCustomColors(data);

        setCustomGraphColors(newCustomGraphColors);
        setResetColors(newCustomGraphColors);
    }, [data]);

    return (
        <div className={rootClassName}>
            <div className={chartClassName}>
                <Chart
                    graphTypes={graphTypes}
                    data={data}
                    customGraphColors={customGraphColors}
                    getCustomColors={getCustomColors}
                    isTransparent={isTransparent}
                >
                    {children}
                </Chart>
            </div>
            {data.length ? (
                <Legend
                    classNameItem={classNameItem}
                    legendClassName={legendClassName}
                    data={data}
                    customGraphColors={customGraphColors}
                    setCustomGraphColors={setCustomGraphColors}
                    setIsTransparent={setIsTransparent}
                    resetColors={resetColors}
                />
            ) : null}
        </div>
    );
};

export default ChartWithLegend;
