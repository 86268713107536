import React from 'react';
import s from './List.pcss';

interface IProps {
    data: Array<any>;
    depth?: number;
    dataAttrName?: string;
    dataAttrList?: string[];
    renderItem: ({ depth, children, item }) => JSX.Element;
}

export const List: React.FC<IProps> = ({ data, renderItem, depth = 0, dataAttrName = '', dataAttrList = [] }) => {
    const getKey = (i: number) => `depth-${depth}-item-${i}`;
    const dataAttrAtstList = dataAttrList.length ? `${dataAttrList.join('-')}` : 'dropdown_list';

    const getDataAttrListItem = (id?: string) => {
        if (!dataAttrList.length || !dataAttrName) return null;
        return { [`data-${dataAttrName}`]: `${dataAttrList.join('::')}${id ? `::${id}` : ''}` };
    };

    return (
        <ul className={s.list} data-atst={dataAttrAtstList}>
            {data.map((item, i) => (
                <li
                    data-testid="list-item"
                    className={s.listItem}
                    key={getKey(i)}
                    {...getDataAttrListItem(item.value || item.name)}
                >
                    {renderItem({ ...item, depth })}
                </li>
            ))}
        </ul>
    );
};

export default List;
