import { Button, IconTrash, MaskedInput, Select } from '@adtech/ui';
import * as React from 'react';
import { IMask } from 'react-imask';

import {
    EFilterRuleOperation,
    IFilterRuleFormValue,
    IFiltersCnfItem,
    TAllFormValues,
    TFilterRuleOperation,
} from '../types/filters';
import s from './DatePeriod.pcss';

interface IProps {
    filterCnf: IFiltersCnfItem;
    currentKey: string;
    allFormsValues: TAllFormValues;
    setAllFormsValues: (formsValues: TAllFormValues) => void;
    ruleFormValue: IFilterRuleFormValue;
    curFilterFormsValues: IFilterRuleFormValue[];
    ruleIndex: number;
    deleteRuleHandler: (index: number) => void;
}

const DatePeriodFilter: React.FC<IProps> = ({
    currentKey,
    allFormsValues,
    setAllFormsValues,
    ruleFormValue,
    curFilterFormsValues,
    ruleIndex,
    deleteRuleHandler,
}) => {
    const PERIOD_VALUES = [
        {
            label: 'До',
            value: EFilterRuleOperation.LT,
        },
        {
            label: 'После',
            value: EFilterRuleOperation.GT,
        },
    ];
    const DATE_MASK = { mask: Date, lazy: true, autofix: true };
    const TIME_MASK = {
        mask: 'HH{:}mm',
        lazy: true,
        blocks: {
            HH: {
                mask: IMask.MaskedRange,
                from: 0,
                to: 23,
            },
            mm: {
                mask: IMask.MaskedRange,
                from: 0,
                to: 59,
            },
        },
        autofix: true,
    };
    const selectValue = ruleFormValue.op !== EFilterRuleOperation.AND ? ruleFormValue.op : null;

    const changeConditionHandler = (value: string) => {
        const newFormVal = [...curFilterFormsValues];
        newFormVal[ruleIndex] = { ...newFormVal[ruleIndex], op: value as TFilterRuleOperation };
        setAllFormsValues({ ...allFormsValues, [currentKey]: newFormVal });
    };

    const changeDateHandler = (value: string) => {
        const newFormVal = [...curFilterFormsValues];
        const time = newFormVal[ruleIndex].value?.[1] || '';
        newFormVal[ruleIndex] = { ...newFormVal[ruleIndex], value: [value, time] };
        setAllFormsValues({ ...allFormsValues, [currentKey]: newFormVal });
    };

    const changeTimeHandler = (value: string) => {
        const newFormVal = [...curFilterFormsValues];
        const date = newFormVal[ruleIndex].value?.[0] || '';
        newFormVal[ruleIndex] = { ...newFormVal[ruleIndex], value: [date, value] };
        setAllFormsValues({ ...allFormsValues, [currentKey]: newFormVal });
    };

    return (
        <div className={s.datePeriodFilter}>
            <div className={s.datePeriodFilterCol}>
                <Select
                    placeholder="Условие"
                    options={PERIOD_VALUES}
                    value={selectValue}
                    onChange={changeConditionHandler}
                />
            </div>
            <div className={s.datePeriodFilterCol}>
                <MaskedInput
                    value={ruleFormValue.value?.[0] || ''}
                    onChange={changeDateHandler}
                    maskOptions={DATE_MASK}
                    placeholder="дд.мм.гггг"
                />
            </div>
            <div className={s.datePeriodFilterCol}>
                <MaskedInput
                    value={ruleFormValue.value?.[1] || ''}
                    onChange={changeTimeHandler}
                    maskOptions={TIME_MASK}
                    placeholder="00:00"
                />
            </div>
            {curFilterFormsValues?.length > 1 ? (
                <Button icon={<IconTrash />} type="dashed" onClick={() => deleteRuleHandler(ruleIndex)} />
            ) : null}
        </div>
    );
};

export default DatePeriodFilter;
