/* eslint-disable max-len */
import { GroupBy } from '@configs/group';

const minute = 60;
const hour = minute * 60;
const day = hour * 24;
const week = day * 7;
const ms = 1000;

const dateConstants = {
    DATE_FORMAT: 'YYYY-MM-DD',
    DATE_ISO_FORMAT: 'YYYY-MM-DD HH:mm:ss',
    DATE_VIEW: 'DD.MM.YYYY',
    DATETIME_VIEW: 'DD.MM.YYYY HH:mm',
    DATE_INPUT: 'DD.MM.YY',
    TIME_REGEXP: /^(\d+[:]?\d*[:]?\d*)$/,
    TIME_REGEXP_STRICT: /^(\d{2}:\d{2}(:\d{2})?)$/,
    threeMonthInSeconds: (60 * 60 * 24 * 365) / 4,
    DATE_VIEW_REGEXP:
        /^(?:(?:31(\/|-|\.)(?:0?[13578]|1[02]))\1|(?:(?:29|30)(\/|-|\.)(?:0?[1,3-9]|1[0-2])\2))(?:(?:1[6-9]|[2-9]\d)?\d{2})$|^(?:29(\/|-|\.)0?2\3(?:(?:(?:1[6-9]|[2-9]\d)?(?:0[48]|[2468][048]|[13579][26])|(?:(?:16|[2468][048]|[3579][26])00))))$|^(?:0?[1-9]|1\d|2[0-8])(\/|-|\.)(?:(?:0?[1-9])|(?:1[0-2]))\4(?:(?:1[6-9]|[2-9]\d)?\d{2})$/,
    DATE_SIMPLE: /[0-9][0-9].[0-9][0-9].[0-9][0-9][0-9][0-9]/,
    DEFAULT_DATE: 'today',
    LITERALS: ['today', 'yesterday', '7days', '30days'],
    LONG_PERIOD: 90, // days
    ALERT_MESSAGES: {
        longPeriod: 'Выбран слишком большой период, потребуется дополнительное время для загрузки отчёта.',
        notValidDateRange: 'Выбран неверный диапазон дат.',
        excludedLatency: 'За выбранный период отсутствуют данные, пожалуйста, выберете доступный интервал.',
    },
    TIME_INTERVALS: {
        [GroupBy.minute]: minute,
        [GroupBy.hour]: hour,
        [GroupBy.day]: day,
        [GroupBy.week]: week,
        ms,
    },
};

export default dateConstants;
