import dateConstants from '@constants/date';
import { DateType } from '@typings/date';
import DateUtils from '@utils/date';

const useValidDates = () => {
    const { DATE_FORMAT } = dateConstants;

    // TODO: Перенести сюда все методы по валидации дат из DateModal,
    //  мб перенести что-то из DateUtils, когда DateModal будет переписан
    //  на функциональный номпонент
    const api = {
        /**
         * Проверяем даты на корректность.
         * Если заменилась одна дата на today, то заменяем обе даты на сегодня.
         * Проверяем интервал на корректность, если неправильный, то ревертим.
         * TODO: разделить и унифицировать методы, разобраться с типизациями дат!
         */
        getValidIntervalDates(
            dates: {
                dateStart?: DateType;
                dateEnd?: DateType;
            } = {},
        ): Record<string, DateType> {
            let { dateStart, dateEnd } = dates;

            // Если хотя бы одна из дат является литеральной, проверяем
            if (DateUtils.isLiteralDate(String(dateStart)) || DateUtils.isLiteralDate(String(dateEnd))) {
                // Если одна дата заменилась на сегодня, то меняем обе даты на сегодня,
                // но игнорируем литералы
                if (
                    (dateStart === 'today' || dateEnd === 'today') &&
                    dateStart !== dateEnd &&
                    !String(dateStart).includes('days') &&
                    !String(dateEnd).includes('days')
                ) {
                    dateStart = dateConstants.DEFAULT_DATE;
                    dateEnd = dateConstants.DEFAULT_DATE;
                }
            } else {
                dateStart = DateUtils.getDate(dateStart).format(DATE_FORMAT);
                dateEnd = DateUtils.getDate(dateEnd).format(DATE_FORMAT);
            }

            // Проверяем валидность интервала, и если он некорректный, то ревертим
            const [start, end] = DateUtils.validAndRevertInterval([dateStart, dateEnd]);

            return {
                dateStart: start,
                dateEnd: end,
            };
        },

        /**
         * Для рейтинга заменяем даты на дефолтные,
         * если обе даты today.
         * Иначе вычислям период со сдвигом на 7 дней в прошлое.
         * Если не заданы queryParams, то выставляем даты по умолчанию.
         */
        getValidRatingDates(dates: DateType[], defaultStart: DateType, defaultEnd: DateType, stateDateStart: DateType) {
            let newDates: DateType[] = dates;

            if (DateUtils.isToday(dates[0]) && DateUtils.isToday(dates[1])) {
                newDates = [defaultStart, defaultEnd];
            } else if (DateUtils.isToday(dates[0]) || DateUtils.isToday(dates[1])) {
                const daysNumber = DateUtils.getDaysNumber(stateDateStart) || 7;
                newDates = [
                    DateUtils.someDaysAgo(daysNumber + 1).format(DATE_FORMAT),
                    DateUtils.getYesterday().format(DATE_FORMAT),
                ];
            }

            return newDates;
        },
    };

    return api;
};

export default useValidDates;
