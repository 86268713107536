import { Button } from '@adtech/ui';
import Form from '@components/Form';
import attributesData from '@configs/attributesData';
import React from 'react';
import s from './FormCreateCounter.pcss';
import Captcha from './fields/Captcha';
import Terms from './fields/Terms';
import Title from './fields/Title';
import Url from './fields/Url';

interface IProps {
    onSubmit: () => void;
    getDefaultTitle: (url: string) => void; // получение title сайта по url
    defaultTitle: string; // title сайта, полученный по url
    loading: boolean;
    invalid: boolean;
    needCaptcha: boolean;
    captchaLoading: boolean;
}

const FormCreateCounter: React.FC<IProps> = ({
    onSubmit,
    getDefaultTitle,
    defaultTitle = '',
    loading = false,
    invalid,
    needCaptcha = false,
    captchaLoading = true,
}) => (
    <Form onSubmit={onSubmit}>
        <Url getDefaultTitle={getDefaultTitle} />
        <Title defaultTitle={defaultTitle} />
        <Terms />
        {/* TODO: при добавлении каптчи, необходимо будет проверить и скорректировать (пока каптча отключено) */}
        {needCaptcha && <Captcha loading={captchaLoading} />}
        <Button
            loading={loading}
            disabled={invalid}
            htmlType="submit"
            className={s.createButton}
            data-create-counter={`${attributesData.createCounter.firstStep}::${attributesData.createCounter.createCounterBtn}`}
        >
            Создать счётчик
        </Button>
    </Form>
);

export default FormCreateCounter;
