import { imgSrc, imgSet } from '@images/error404';
import React from 'react';

export default {
    imgSrc,
    imgSet: `${imgSet[0]} 2x, ${imgSet[1]} 3x`,
    code: '404',
    title: 'Страница не найдена',
    getContent() {
        return (
            <div>
                <p>Кажется, вы заблудились.</p>
                <p>
                    По адресу <span>{window.location.href}</span> ничего нет.
                </p>
                <p>
                    Попробуйте начать заново или поискать нужный счётчик в&nbsp;
                    <a href="/stat/projects" target="_self">
                        Моих сайтах
                    </a>
                    .
                </p>
            </div>
        );
    },
};
