import { baseCategory } from '@constants/projects';
import { IProject, TProjectDifference } from '@typings/projects';
import arrayUtils from '@utils/array';
import urlUtils from '@utils/url';

const getPercentMetric = (metric) => {
    if (!metric.yesterday) return !metric.today ? 0 : 100;

    return ((metric.today - metric.yesterday) / metric.yesterday) * 100;
};

const getBlocker = (params, blockerName): boolean => {
    if (!params.rating_blockers) return false;

    return params.rating_blockers.includes(blockerName);
};

const getCategoryThemeId = (item) => {
    let result = item.id;

    if ('theme_id' in item) {
        result = item.theme_id;
    }

    return result;
};

const getCategories = (categories, withoutBaseCategory) => {
    if (!arrayUtils.isArray(categories)) return [];

    const newCategories = categories.map((item) => ({
        name: item.parent_title ? `${item.parent_title} / ${item.title}` : item.title,
        id: String(item.id),
        themeId: String(getCategoryThemeId(item)),
        position: item.positions && item.positions.popularity,
    }));

    if (!withoutBaseCategory) {
        newCategories.unshift(baseCategory);
    }

    return newCategories;
};

const getDiff = (views, viewsChange, visitors, visitorsChange): TProjectDifference => {
    if (!views && !visitors) {
        return [];
    }

    return [
        [viewsChange >= 0 ? '+' : '-', Math.abs(viewsChange) || '0', '%'],
        [visitorsChange >= 0 ? '+' : '-', Math.abs(visitorsChange) || '0', '%'],
    ];
};

export default function setProjectRecord(params: any = {}, options: any = {}): IProject {
    const { withoutBaseCategory } = options;

    const result = {
        categories: getCategories(params.categories, withoutBaseCategory),
        counter_settings: params.counter_settings || {
            block_analytics_attribute: '',
            use_block_analytics: false,
            color: '#313EFB',
            blacklisted_urls: [],
            whitelisted_urls: [],
            counter_type: null,
        },
        ununique: getBlocker(params, 'ununique'),
        blacklisted: getBlocker(params, 'blacklisted'),
        available_reports: params.available_reports || [],
        deleted: params.deleted || false,
        id: params.id || null,
        can_edit_project: params.can_edit_project || false,
        can_be_in_rate: params.can_be_in_rate ?? true,
        counter_is_set: params.counter_is_set || false,
        in_rating: params.in_rating || false,
        keywords: params.keywords || '',
        publicStats: params.public_stats || null,
        regions: params.regions || [],
        status: params.status || null,
        tags: params.tags || null,
        title: params.title || null,

        types: params.types || [],
        url: params.url || null,
        favicon: params.favicon || null,
        cleanedUrl: urlUtils.getDomain(params.url || ''),
        views: {
            count: params.views || 0,
            change: params.views_change || 0,
        },
        visitors: {
            count: params.visitors || 0,
            change: params.visitors_change || 0,
        },
        users: params.users || [],
        permissions: params.permissions || {},
    } as IProject;

    // расчитываем разницу в процентах между сегодня и вчера
    result.difference = [];

    if (params.metrics) {
        const { views, visitors } = params.metrics;

        const viewsChange = getPercentMetric(views);
        const visitorsChange = getPercentMetric(visitors);

        result.difference = getDiff(views.today, viewsChange, visitors.today, visitorsChange);
    }

    // prettier-ignore
    result.today = !params.views && !params.visitors
        ? false
        : {
            metrics: [params.views, params.visitors],
        };

    if (params.metrics) result.metrics = params.metrics;

    result.yesterday = !params.views && !params.visitors ? false : [];

    return result;
}
