import ContentWithSidebar from '@components/ContentWithSidebar';
import ProjectsTable from '@components/ProjectsTable';
import ProjectsTools from '@components/ProjectsTools';
import pages from '@configs/projects/pages';
import useGlobalLoading from '@hooks/useGlobalLoading';
import useProjects from '@hooks/useProjects';
import { globalActions } from '@redux/slices/global';
import { ProjectsPageName } from '@typings/projects';
import { IRootSlice } from '@typings/rootSlice';
import React, { useCallback, useEffect, useRef } from 'react';
import DocumentTitle from 'react-document-title';
import { useSelector, useDispatch } from 'react-redux';
import { useLocation } from 'react-router-dom';
import s from './Projects.pcss';

const Projects: React.FC = () => {
    useGlobalLoading();

    const { pathname } = useLocation();

    const {
        api: { init, getProjectsTable, getProjectsTableOffset, applyModalPopup, openModalPopup, getProjectsPageName },
    } = useProjects();

    const isInited = useRef(false);
    const isDeleted = pathname.includes('deleted');
    const pageName: ProjectsPageName = getProjectsPageName();
    const pageConfig = pages[pageName];
    const searchPlaceholder = pageConfig.search.placeholder;
    const title = pageConfig.title;
    const documentTitle = `${title} - Статистика от SberAds`;

    const dispatch = useDispatch();

    const setGlobalLoading = useCallback(
        (value: boolean) => dispatch(globalActions.setGlobalLoading(value)),
        [dispatch],
    );

    const globalLoading = useSelector((state: IRootSlice) => state.globalSlice.globalLoading);
    const userRole = useSelector((state: IRootSlice) => state.userSlice.roleId);
    const isUserProjectsExpanded = useSelector((state: IRootSlice) => state.userSlice.isUserProjectsExpanded);
    const tableRequest = useSelector((state: IRootSlice) => state.projectsSlice.tableRequest);
    const deleteRequest = useSelector((state: IRootSlice) => state.projectsSlice.deleteRequest);
    const tableData = useSelector((state: IRootSlice) => state.projectsSlice.tableData);
    const tableSearch = useSelector((state: IRootSlice) => state.projectsSlice.tableSearch);

    useEffect(() => {
        isInited.current = false;

        init().then(async () => {
            isInited.current = true;
            setGlobalLoading(false);
        });
    }, []);

    useEffect(() => {
        if (globalLoading || !isInited.current) return;
        getProjectsTable();
    }, [isDeleted]);

    useEffect(() => {
        if (globalLoading || !isInited.current) return;
        getProjectsTable({ search: tableSearch });
    }, [isUserProjectsExpanded]);

    return (
        <DocumentTitle title={documentTitle}>
            <>
                <h1 className={s.title}>{title}</h1>
                <ContentWithSidebar navigationType="projects">
                    <ProjectsTools
                        isDeleted={isDeleted}
                        searchPlaceholder={searchPlaceholder}
                        searchValue={tableSearch}
                        getProjectsTable={getProjectsTable}
                        userRole={userRole}
                        isUserProjectsExpanded={isUserProjectsExpanded}
                    />
                    <ProjectsTable
                        isDeleted={isDeleted}
                        tableRequest={tableRequest || deleteRequest}
                        getProjectsTableOffset={getProjectsTableOffset}
                        applyModalPopup={applyModalPopup}
                        openModalPopup={openModalPopup}
                        tableData={tableData}
                    />
                </ContentWithSidebar>
            </>
        </DocumentTitle>
    );
};

export default Projects;
