import { Textarea } from '@adtech/ui';
import fieldDecorator from '@decorators/fieldDecorator';
import cn from 'classnames';
import React, { useState } from 'react';

import s from './TextareaType.pcss';

interface IProps {
    options: {
        placeholder: string[];
    };
    field: {
        value: string;
        onBlur;
        onFocus;
    };
}

const renderPlaceholderItem = (str, key) => (
    <span key={`placeholder-${key}`}>
        {str} <br />
    </span>
);

const TextareaField: React.FC<IProps> = ({ options, field }) => {
    const [focused, setFocused] = useState(false);

    const handleFocus = () => {
        field.onFocus();
        setFocused(true);
    };

    const handleBlur = () => {
        field.onBlur();
        setFocused(false);
    };

    return (
        <div className={s.wrap}>
            <Textarea {...field} onFocus={handleFocus} onBlur={handleBlur} className={s.input} />
            {options.placeholder && field.value?.length === 0 && (
                <div
                    className={cn(s.placeholder, {
                        [s.focused]: focused,
                    })}
                >
                    {options.placeholder.map(renderPlaceholderItem)}
                </div>
            )}
        </div>
    );
};

export default fieldDecorator({
    ignoreHidden: true,
    name: 'value',
    mod: 'string',
})(TextareaField);
