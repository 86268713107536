import { Modal } from '@adtech/ui';
import React, { FC } from 'react';

import s from '../SavedFilters.pcss';

enum ETemplateType {
    PRIVATE = 'private',
    SHARED = 'shared',
}

interface IProps {
    accessLevel: boolean;
    modalData: {
        actionName: string;
        data: {
            id?: number;
            title?: string;
            type?: 'private' | 'shared';
        };
    };
    isOpen;
    onOkHandler;
    onCloseHandler: () => void;
}

const DeleteSavedFiltersModal: FC<IProps> = ({ modalData, isOpen, onOkHandler, onCloseHandler }: IProps) => {
    const { actionName, data } = modalData || {};

    if (!actionName) return null;

    const { id, title, type } = data || {};

    return (
        <Modal
            className={s.deleteModal}
            isOpened={isOpen}
            title="Удаление шаблона"
            okText="Удалить"
            cancelText="Отменить"
            onOk={() => onOkHandler(actionName, { id })}
            onClose={onCloseHandler}
            closeOnClickOutside={false}
            centered
            size="sm"
        >
            <div className={s.inner}>
                <p>
                    Вы&nbsp;уверены, что вам больше не&nbsp;нужен этот шаблон: {title}?
                    <br />
                    Его нельзя будет восстановить.
                </p>
                {type === ETemplateType.SHARED && (
                    <p>Обратите внимание, что публичный шаблон будет удален у всех участников проекта.</p>
                )}
            </div>
        </Modal>
    );
};

export default DeleteSavedFiltersModal;
