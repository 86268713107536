import { Button, IconChevronDown } from '@adtech/ui';
import cn from 'classnames';
import React, { MouseEvent, useEffect, useState } from 'react';

import s from './CollapseFilters.pcss';

interface IProps {
    height: number;
    onCollapsedFilters: (collapsed: boolean) => void;
    collapsedFilters: boolean;
}

const CollapseFilters: React.FC<IProps> = ({ height = 0, onCollapsedFilters, collapsedFilters = false }) => {
    const [hideButton, setHideButton] = useState(true);

    useEffect(() => {
        if (height !== 0 && height > 40) {
            setHideButton(false);
            return;
        }
        setHideButton(true);
    }, [height]);

    if (hideButton) return null;

    const handleClick = (e: MouseEvent) => {
        e.preventDefault();
        onCollapsedFilters(!collapsedFilters);
    };

    return (
        <Button
            onClick={handleClick}
            type="link"
            data-testid="collapseFilter-button"
            suffixIcon={<IconChevronDown />}
            className={cn(s.button, { [s.buttonCollapse]: !collapsedFilters })}
        >
            {collapsedFilters ? 'Развернуть' : 'Свернуть'}
        </Button>
    );
};

export default CollapseFilters;
