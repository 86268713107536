import { Button } from '@adtech/ui';
import attributesData from '@configs/attributesData';
import { Dimensions } from '@typings/dimensions';
import { Metrics } from '@typings/metrics';
import { ReportName } from '@typings/reports';
import React from 'react';

import s from '../../ReportTable.pcss';

interface IProps {
    metrics: Metrics[];
    dimensions: Dimensions[];
    onShowMoreClick: (parentId: string, currentRows: number) => void;
    onShowmoreTopClick: (offset: number, limit: number) => void;
    currentRows: number;
    totalRows: number;
    parentId: string;
    reportName: ReportName;
    isHeadButton?: boolean;
    currOffset?: number;
}

export default class TableShowMoreRow extends React.Component<IProps> {
    getWordWithEnding = (count: number, isHeadButton: boolean) => {
        const titles = ['строка', 'строки', 'строк', 'строку'];

        const cutCount = count % 100;

        if (cutCount > 4 && cutCount <= 20) return titles[2];

        switch (cutCount % 10) {
            // Верхняя кнопка - "Показать 21 строку"
            // нижняя - "Показано 21 строка из ..."
            case 1:
                return isHeadButton ? titles[3] : titles[0];
            case 2:
            case 3:
            case 4:
                return titles[1];
            default:
                return titles[2];
        }
    };

    getLabel = () => {
        const { isHeadButton, currentRows, totalRows } = this.props;
        // Текст зависит от расположения кнопки
        const show = isHeadButton ? 'Показать' : 'Показано';

        let countLabel = currentRows;
        let totalLabel = ` из ${totalRows}`;

        if (isHeadButton) {
            countLabel = totalRows > currentRows ? currentRows : totalRows;
            totalLabel = totalRows <= currentRows ? '' : totalLabel;
        }

        return `${show} ${countLabel} ${this.getWordWithEnding(countLabel, isHeadButton)}${totalLabel}`;
    };

    handleShowMore = () => {
        const { onShowMoreClick, parentId, currentRows, currOffset } = this.props;

        if (typeof onShowMoreClick === 'function') {
            onShowMoreClick(parentId, currOffset ? currOffset + currentRows : currentRows);
        }
    };

    handleShowMoreTop = () => {
        const { onShowmoreTopClick, currentRows, currOffset } = this.props;

        if (typeof onShowmoreTopClick === 'function') {
            onShowmoreTopClick(currentRows, currOffset);
        }
    };

    handleClick = () => {
        const { isHeadButton } = this.props;

        if (isHeadButton) {
            this.handleShowMoreTop();
        } else {
            this.handleShowMore();
        }
    };

    render() {
        const { metrics, dimensions, reportName } = this.props;

        const colSpan = metrics.length + dimensions.length + 1;
        const label = this.getLabel();
        const tableAttr = attributesData.newreport.table;

        return (
            <tr className={s.tableRow}>
                <td colSpan={colSpan}>
                    <div className={s.tableShowmore}>
                        <Button
                            className={s.tableShowmoreButton}
                            onClick={this.handleClick}
                            type="dashed"
                            size="small"
                            data-newreport={`${tableAttr.attrName}::${tableAttr.moreBtn}::${reportName}`}
                        >
                            Показать еще
                        </Button>
                        <span className={s.tableShowmoreLabel}>{label}</span>
                    </div>
                </td>
            </tr>
        );
    }
}
