import { Radio } from '@adtech/ui';
import { RadioChangeEvent } from 'antd/es/radio';
import * as React from 'react';

import FilterFormHeader from '../components/FilterFormHeader';
import { IFilterRuleFormValue, IFiltersCnfItem, TAllFormValues } from '../types/filters';
import s from './Radio.pcss';

interface IProps {
    filterCnf: IFiltersCnfItem;
    currentKey: string;
    allFormsValues: TAllFormValues;
    setAllFormsValues: (formsValues: TAllFormValues) => void;
    ruleFormValue: IFilterRuleFormValue;
    curFilterFormsValues: IFilterRuleFormValue[];
    ruleIndex: number;
}

const RadioFilter: React.FC<IProps> = ({
    filterCnf,
    currentKey,
    allFormsValues,
    setAllFormsValues,
    ruleFormValue,
    curFilterFormsValues,
    ruleIndex,
}) => {
    const changeRadioHandler = (value: string | null) => {
        const newFormVal = [...curFilterFormsValues];
        newFormVal[ruleIndex] = { ...newFormVal[ruleIndex], value };
        setAllFormsValues({ ...allFormsValues, [currentKey]: newFormVal });
    };

    const clearFilter = () => changeRadioHandler(null);

    return (
        <div className={s.radioFilter}>
            <FilterFormHeader
                label="Настройте условие"
                hint={filterCnf?.hint}
                clearFilter={ruleFormValue.value ? clearFilter : null}
                isLabelBold
            />
            {filterCnf.data.map((item) => (
                <Radio
                    key={item.value}
                    value={item.value}
                    checked={item.value === ruleFormValue.value}
                    onChange={(e: RadioChangeEvent) => changeRadioHandler(e.target.value)}
                    className={s.radioFilterOption}
                >
                    {item.label}
                </Radio>
            ))}
        </div>
    );
};

export default RadioFilter;
