import { ChartTypes } from '@configs/graph';
import { sortOrders } from '@constants/sort';
import { Dimensions } from '@typings/dimensions';
import { Metrics } from '@typings/metrics';
import { ISort } from '@typings/table';

export interface IWidgetsMedia {
    trafficSources: {
        hintTitle: string;
        heightChart: number;
        tabsNum: number;
        metrics: Metrics[];
        selected: Metrics;
        graphType: ChartTypes;
    },
    audience: {
        hintTitle: string;
        heightChart: number;
        tabsNum: number;
        defaultMetric: Metrics;
        dimensions: Dimensions[];
        selected: Dimensions;
        graphType: ChartTypes;
    },
    fullReadScroll: {
        hintTitle: string;
        tabsNum: number;
        defaultMetric: Metrics;
        dimensions: Dimensions[];
        selected: Dimensions;
        graphType: ChartTypes;
    },
    recirculationSources: {
        hintTitle: string;
        tableTitle: string;
        tableFooterTitle: string;
        metrics: Metrics[];
        dimensions: Dimensions[];
        selected: Metrics;
        sort: ISort;
        limit: number;
    }
}

export const widgetsMediaConfig: IWidgetsMedia = {
    trafficSources: {
        hintTitle: 'Из каких источников пришли пользователи на материал',
        heightChart: 350,
        tabsNum: 5, // количество активных табов-метрик
        metrics: [
            'visits',
            'page_views',
            'visitors',
            'avg_fullread_percent',
            'avg_fullscroll_percent',
        ],
        selected: 'visits',
        graphType: ChartTypes.bar,
    },
    audience: {
        hintTitle: 'Статистика по аудитории, которая посетила страницу',
        heightChart: 350,
        tabsNum: 4,
        defaultMetric: 'page_views', // метрика, по которой обсчитываются dimensions
        dimensions: ['gender', 'age_tns', 'geo_country'],
        selected: 'gender',
        graphType: ChartTypes.bar,
    },
    fullReadScroll: {
        hintTitle: 'Средний процент доскролла и дочтения материала, эти метрики рассчитываются по просмотрам страницы',
        tabsNum: 2,
        defaultMetric: 'page_views',
        dimensions: ['fullscroll_percent', 'fullread_percent'],
        selected: 'fullscroll_percent',
        graphType: ChartTypes.bar,
    },
    recirculationSources: {
        hintTitle: 'Какие материалы пользователи посетили далее, в таблице - название материала и количество переходов',
        tableTitle: 'Название материала',
        tableFooterTitle: 'Всего переходов',
        metrics: ['recirculation_count'],
        dimensions: ['media_id', 'media_title', 'media_url'],
        selected: 'recirculation_count',
        sort: {
            name: 'recirculation_count',
            order: sortOrders.DESC,
        },
        limit: 6,
    },
};
