import { Input } from '@adtech/ui';
import IMCore from 'inputmask-core';
import React, { KeyboardEvent, SyntheticEvent, useEffect, useRef, useState } from 'react';

interface IProps {
    mask: string;
    className?: string;
    size?: 'small' | 'middle' | 'large';
    value?: string;
    placeholder?: string;
    isNative?: boolean;
    onChange?: (e: SyntheticEvent) => void;
    onKeyPress?: (e: KeyboardEvent) => void;
    onKeyDown?: (e: KeyboardEvent) => void;
    dataTestId?: string;
}

const InputMask: React.FC<IProps> = ({
    mask = '',
    className,
    size,
    value = '',
    placeholder = '',
    isNative = true,
    onChange,
    onKeyDown,
    onKeyPress,
    dataTestId,
}) => {
    const inputRef = useRef();
    const [maskObj] = useState<Record<string, any>>(
        new IMCore({
            pattern: mask,
            value,
        }),
    );

    const KEYCODE_Z = 90;
    const KEYCODE_Y = 89;

    const isUndo = (e) => (e.ctrlKey || e.metaKey) && e.keyCode === (e.shiftKey ? KEYCODE_Y : KEYCODE_Z);

    const isRedo = (e) => (e.ctrlKey || e.metaKey) && e.keyCode === (e.shiftKey ? KEYCODE_Z : KEYCODE_Y);

    const getSelection = (el, selection) => {
        let start = selection.start;
        let end = selection.end;

        if (el.selectionStart !== undefined) {
            start = el.selectionStart;
            end = el.selectionEnd;
        } else {
            try {
                el.focus();
                const rangeEl = el.createTextRange();
                const clone = rangeEl.duplicate();

                // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                // @ts-ignore
                rangeEl.moveToBookmark(document.selection.createRange().getBookmark());
                clone.setEndPoint('EndToStart', rangeEl);

                start = clone.text.length;
                end = start + rangeEl.text.length;
            } catch (e) {
                /* not focused or not visible */
            }
        }

        return { start, end };
    };

    const setSelection = (el, selection) => {
        try {
            if (el.selectionStart !== undefined) {
                el.focus();
                el.setSelectionRange(selection.start, selection.end);
            } else {
                el.focus();
                const rangeEl = el.createTextRange();
                rangeEl.collapse(true);
                rangeEl.moveStart('character', selection.start);
                rangeEl.moveEnd('character', selection.end - selection.start);
                rangeEl.select();
            }
        } catch (e) {
            /* not focused or not visible */
        }
    };

    const updatePattern = () => {
        if (!inputRef.current) return;

        maskObj.setPattern(mask, {
            value: maskObj.getRawValue(),
            selection: getSelection(inputRef.current, maskObj.selection),
        });
    };

    const updateMaskSelection = (target?) => {
        maskObj.selection = getSelection(target, maskObj.selection);
    };

    const updateInputSelection = (target?) => {
        setSelection(target, maskObj.selection);
    };

    const getDisplayValue = () => {
        const val = maskObj.getValue();
        return val === maskObj.emptyValue ? '' : val;
    };

    // Нужен для динамической смены масок, но сейчас не используется
    useEffect(() => {
        updatePattern();

        // TODO: ломает тесты
        // if (maskObj.selection.start) {
        //     updateInputSelection(inputRef.current);
        // }
    }, [mask]);

    useEffect(() => {
        maskObj.setValue(value);
    }, [value]);

    const onChangeHandler = (e) => {
        const maskValue = maskObj.getValue();
        const incomingValue = e.target.value;
        if (incomingValue !== maskValue) {
            updateMaskSelection();
            maskObj.setValue(incomingValue);
            e.target.value = getDisplayValue();
            updateInputSelection(e.target);
        }

        if (onChange) onChange(e);
    };

    const onKeyDownHandler = (e) => {
        if (isUndo(e)) {
            e.preventDefault();
            if (maskObj.undo()) {
                e.target.value = getDisplayValue();
                updateInputSelection(e.target);
                if (onChange) {
                    onChange(e);
                }
            }
            return;
        }

        if (isRedo(e)) {
            e.preventDefault();
            if (maskObj.redo()) {
                e.target.value = getDisplayValue();
                updateInputSelection(e.target);
                if (onChange) onChange(e);
            }
            return;
        }

        if (e.key === 'Backspace') {
            e.preventDefault();
            updateMaskSelection(e.target);
            if (maskObj.backspace()) {
                const val = getDisplayValue();
                e.target.value = val;
                if (val) {
                    updateInputSelection(e.target);
                }
                if (onChange) onChange(e);
            }
        }

        if (onKeyDown) onKeyDown(e);
    };

    const onKeyPressHandler = (e) => {
        // Ignore modified key presses
        if (e.metaKey || e.altKey || e.ctrlKey) return;

        e.preventDefault();
        updateMaskSelection(e.target);
        if (maskObj.input(e.key)) {
            e.target.value = maskObj.getValue();
            updateInputSelection(e.target);
            if (onChange) onChange(e);
        }

        if (onKeyPress) onKeyPress(e);
    };

    const onPasteHandler = (e) => {
        e.preventDefault();
        updateMaskSelection(e.target);
        // getData value needed for IE also works in FF & Chrome
        if (maskObj.paste(e.clipboardData.getData('Text'))) {
            e.target.value = maskObj.getValue();
            updateInputSelection(e.target);
            if (onChange) onChange(e);
        }
    };

    const renderInput = () => {
        const inputProps = {
            className,
            value: getDisplayValue(),
            placeholder: placeholder ?? maskObj.emptyValue,
            maxLength: maskObj ? maskObj.pattern.length : 0,
            onChange: onChangeHandler,
            onKeyDown: onKeyDownHandler,
            onPaste: onPasteHandler,
            onKeyPress: onKeyPressHandler,
            ...(dataTestId ? { 'data-testid': dataTestId } : {}),
        };

        if (isNative) return <input {...inputProps} ref={inputRef} />;

        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        return <Input {...inputProps} size={size} inputRef={inputRef} />;
    };

    return renderInput();
};

export default InputMask;
