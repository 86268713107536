import { IUpdateReportParams } from '@typings/reports';
import { IReportsSlice } from '@typings/rootSlice';

const globalUtilsFunc = () => {
    const isSameParamValue = (
        paramName: string,
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        dataOld: Record<string, any>,
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        dataNew: Record<string, any>,
    ): boolean => paramName in dataOld && JSON.stringify(dataOld[paramName]) !== JSON.stringify(dataNew[paramName]);

    const compareParams = <T = IReportsSlice, K = IUpdateReportParams>(oldData: T, newData: K): boolean => {
        let result = false;

        Object.keys(newData).forEach((param) => {
            if (isSameParamValue(param, oldData, newData) || param === 'split') result = true;
        });

        return result;
    };

    const filterParamsForStore = <T = IUpdateReportParams, K = IReportsSlice>(params: T, store: K): Partial<T> => {
        const newParams = { ...params };

        Object.keys(newParams).forEach((param) => {
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            if (param in store) return;

            delete newParams[param];
        });

        return newParams;
    };

    return Object.freeze({
        isSameParamValue,
        compareParams,
        filterParamsForStore,
    });
};

export default globalUtilsFunc;
