import fieldDecorator from '@decorators/fieldDecorator';
import * as React from 'react';

import InputField from './InputField';

import s from './ParamsArrayType.pcss';

interface IProps {
    field: Record<string, any>;
    value: Record<string, any>;
}

class ParamsArrayType extends React.PureComponent<IProps> {
    renderItem = (item, index) => {
        const {
            field: { removeField },
            value: { params },
        } = this.props;

        return (
            <div key={index}>
                <div className={s.group}>
                    <div className={s.label}>Параметр {index + 1}</div>
                    {params.length > 1 && (
                        <div className={s.delete} onClick={() => removeField(index)}>
                            Удалить
                        </div>
                    )}
                </div>
                <InputField index={index} name="key" placeholder="Имя" />

                <InputField index={index} name="value" placeholder="Значение" />
            </div>
        );
    };

    render() {
        const {
            field: { value, addField },
        } = this.props;

        if (!value.length) {
            return null;
        }

        return (
            <div className={s.root}>
                {value.map(this.renderItem)}
                <div className={s.add} onClick={() => addField({})}>
                    Добавить параметр
                </div>
            </div>
        );
    }
}

export default fieldDecorator({
    ignoreHidden: true,
    name: 'params',
    type: 'array',
    mod: 'array',
})(ParamsArrayType);
