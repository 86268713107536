import { Select, Input, Button } from '@adtech/ui';
import Dropdown from '@components/Dropdown';
import { InputMask } from '@components/Input';
import { time as timeMask } from '@components/Input/masks';
import { Operations } from '@configs/operations';
import dateConstants from '@constants/date';
import IconTableFilter from '@images/svg/icons/icon-table-filter.svg';
import { IMetricFilter } from '@typings/filters';
import { Metrics } from '@typings/metrics';
import { ReportName } from '@typings/reports';
import { measuresUtils } from '@utils/index';
import numbersUtils from '@utils/numbersUtils';
import { isNumber } from '@utils/typesChecks';
import React from 'react';

import s from './FilterButton.pcss';

interface IProps {
    selectData: { value: string; label: string }[];
    className: string;
    onFilterSelect;
    total: number;
    metric: Metrics;
    operation: string;
    tableFilters: IMetricFilter[];
    dataAttrName?: string;
    dataAttr?: string | string[];
    reportName?: ReportName;
}

interface IState {
    filterValue: string | number;
    filterType: Operations;
    isTime: boolean;
    dropDownIsHidden: boolean;
}

export default class ReportFilterButton extends React.Component<IProps, IState> {
    static defaultProps = {
        selectData: [
            { value: Operations.gt, label: 'Больше, чем' },
            { value: Operations.lt, label: 'Меньше, чем' },
        ],
        operation: 'compare',
        metric: '',
        dataAttrName: '',
        dataAttr: '',
        reportName: '',
    };

    constructor(props: IProps) {
        super(props);

        this.state = {
            filterValue: '',
            filterType: Operations.gt,
            isTime: false,
            dropDownIsHidden: false,
        };
    }

    componentDidMount() {
        this.checkIsTime(this.props.metric);
    }

    componentDidUpdate(prevProps: IProps) {
        if (this.props.metric && this.props.metric !== prevProps.metric) {
            this.checkIsTime(this.props.metric);
        }

        if (this.state.dropDownIsHidden) this.setState({ dropDownIsHidden: false });
    }

    getPlaceholder = (): string => {
        const { total, metric } = this.props;
        const { isTime } = this.state;
        let placeholder = numbersUtils.numberFormat(total, false);

        if (isTime && metric) {
            placeholder = measuresUtils.prepareValue(metric, total);
        }

        return placeholder.toString();
    };

    checkIsTime = (metric: Metrics) => {
        const format = measuresUtils.getParamFromConfig(metric, 'format');

        this.setState({ isTime: format === 'time' });
    };

    handleChangeFilterType = (value: Operations) => {
        const { filterValue } = this.state;

        // Сбрасываем значение поля, если был "0" и выбрали "меньше, чем"
        // не можем выбрать фильтр < 0, он не работает корректно и не имеет смысла
        if (value === Operations.lt && filterValue === '0') {
            this.setState({ filterValue: '' });
        }

        this.setState({ filterType: value });
    };

    handleFilterKeyPress = (e: { key: string }) => {
        if (e.key === 'Enter') {
            return this.handleApply();
        }

        return false;
    };

    handleFilterKeyDown = (e: { keyCode: number }) => {
        // escape
        if (e.keyCode === 27) {
            return this.resetFilter();
        }

        return false;
    };

    handleFilterChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const { operation, metric } = this.props;
        const { isTime, filterType } = this.state;
        const valueType = measuresUtils.getParamFromConfig(metric, 'type');
        const isPercent = measuresUtils.getParamFromConfig(metric, 'format') === 'percent';
        const filterValue = e.target.value;

        let value: string | number = filterValue;
        const validate = () => /^(\s)+$/.test(String(value)) || (isNumber(value) && Number.isNaN(value));
        let isNotValid = validate();

        if (isTime && !dateConstants.TIME_REGEXP.test(filterValue)) return;

        switch (valueType) {
            case 'int': {
                // преобразовываем строку к числу (чтобы побороть 0000000)
                value = Number(value);

                if (Number.isNaN(value)) value = filterValue.slice(-1);

                // ограничиваем максимально безопасным числом
                value = Number(value) > Number.MAX_SAFE_INTEGER ? Number.MAX_SAFE_INTEGER : value;

                value = String(value);

                // "< 0" - не работает и не имеет смысла, не даем ввести 0
                // для > и пустого значения возвращаем то самое пустое значение
                // после конвертаций типов и промежуточных проверок
                if (
                    (filterType === Operations.lt && value === '0') ||
                    (filterType === Operations.gt && filterValue === '')
                )
                    value = '';

                isNotValid = !/^[0-9]*$/.test(value) || (isNumber(value) && Number.isNaN(value));
                break;
            }
            case 'float': {
                value = filterValue.replace(',', '.');
                isNotValid = !/^[0-9|.]*$/.test(value) || (isNumber(value) && Number.isNaN(value));
                break;
            }
            default:
                break;
        }

        // Процентные метрики ограничиваем 99%, потому что у некоторых метрик (доскроллы, дочтения)
        // значения в базе > 100% и они округляются до 100 и все равно выводятся уже после выборки,
        // что неверно
        if (isPercent && Number(value) > 99) value = 99;

        if (!isTime && operation !== 'equally' && isNotValid) {
            return;
        }

        this.setState({ filterValue: value });
    };

    handleApply = () => {
        const { operation, onFilterSelect, metric } = this.props;
        const { filterValue, filterType, isTime } = this.state;
        const isPercent = measuresUtils.getParamFromConfig(metric, 'formatterValue') === 'percent';

        let formValue: number | string = filterValue;
        let formSymbol = filterType;

        if (operation !== 'compare') {
            formSymbol = Operations.eq;
        }

        const stringFilterValue = filterValue.toString();

        if (stringFilterValue.includes(',')) {
            formValue = stringFilterValue.replace(',', '.');
        }

        // Некоторые процентные метрики (дочтение, доскролл ...),
        // которые должны передаваться в дробном, а не целом виде
        if (isPercent) {
            formValue = Number(formValue) / 100;
        }

        // форматируем входные данные для времени
        if (isTime) {
            formValue = numbersUtils.getSecondsFromTime(`${formValue}`, false);
        }

        // используем ограничение числа до максимально безопасного (Number.MAX_SAFE_INTEGER)
        // чтобы не было чисел типа 9e+29
        if (Number(formValue) > Number.MAX_SAFE_INTEGER) {
            formValue = Number.MAX_SAFE_INTEGER;
        }

        formValue = Number(formValue);

        // проверяем, что значение было не отрицательным
        if (formValue >= 0) {
            onFilterSelect(formSymbol, formValue);
        }
    };

    resetFilter = () =>
        this.setState({
            filterValue: '',
            filterType: Operations.gt,
        });

    handleCancel = () => {
        this.resetFilter();
        this.setState({
            dropDownIsHidden: true,
        });
    };

    renderInput = () => {
        const { isTime } = this.state;
        const props = {
            value: this.state.filterValue.toString(),
            placeholder: this.getPlaceholder(),
            onChange: this.handleFilterChange,
            onKeyPress: this.handleFilterKeyPress,
            onKeyDown: this.handleFilterKeyDown,
        };

        if (isTime) {
            return <InputMask {...props} isNative={false} {...timeMask} />;
        }

        return <Input {...props} />;
    };

    customContent = () => (
        <>
            <div className={s.filterFields}>
                <div className={s.filterFieldsItem}>
                    <Select
                        onChange={this.handleChangeFilterType}
                        options={this.props.selectData}
                        value={this.state.filterType}
                        getPopupContainer={(trigger) => trigger.parentElement}
                    />
                </div>
                <div className={s.filterFieldsItem}>{this.renderInput()}</div>
            </div>
            <div className={s.filterActions}>
                <Button onClick={this.handleApply} className={s.filterActionsItem}>
                    Применить
                </Button>
                <Button type="dashed" onClick={this.handleCancel} className={s.filterActionsItem}>
                    Отмена
                </Button>
            </div>
        </>
    );

    render() {
        const { className, dataAttr, dataAttrName, tableFilters, metric, reportName } = this.props;

        const isValue = tableFilters.filter((item) => item.key === metric && item.value !== '').length > 0;

        const { dropDownIsHidden } = this.state;

        return (
            <div className={className}>
                <Dropdown
                    isActive={isValue}
                    type="customContent"
                    contentClassName={s.filterContent}
                    anchorClassName={s.filterAnchor}
                    anchorPrefixIcon={<IconTableFilter />}
                    isAnchorButton={false}
                    isShowArrow={false}
                    customContent={this.customContent()}
                    reportName={reportName}
                    dataAttrName={dataAttrName}
                    dataAttrAnchor={dataAttr}
                    tooltip="Фильтровать"
                    onClose={this.resetFilter}
                    isHidden={dropDownIsHidden}
                />
            </div>
        );
    }
}
