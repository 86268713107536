interface IWidgetConfig {
    isShowSettings: boolean;
    isShowTabs: boolean;
}

export enum WidgetTypes {
    total = 'total',
    table = 'table',
    graph = 'graph',
    count = 'count',
}

export const widgetConfig: Record<WidgetTypes, IWidgetConfig> = {
    total: {
        isShowSettings: false,
        isShowTabs: false,
    },
    table: {
        isShowSettings: true,
        isShowTabs: true,
    },
    graph: {
        isShowSettings: true,
        isShowTabs: true,
    },
    count: {
        isShowSettings: false,
        isShowTabs: false,
    },
};
