import { ChartTypes } from '@configs/graph';
import { sortOrders } from '@constants/sort';
import { IReportConfig } from '@typings/reports';

const reportConfig: IReportConfig = {
    reportTitle: 'Страницы выхода',
    tableDataType: 'dynamic',
    tableTitle: 'URL страницы',
    defaultSort: {
        name: 'visits',
        order: sortOrders.DESC,
    },
    numberOfGraphs: 'two',
    defaultGraphs: [
        ChartTypes.line,
        ChartTypes.bar,
    ],
    graphDefaultMetrics: [
        'visits',
        'page_views',
    ],
    tableDefaultMetrics: [
        'visits',
        'page_views',
        'avg_view_depth',
    ],
    availableMetrics: [
        'visits',
        'avg_view_depth',
        'page_views',
        'visitors',
        'uid',
        'rambler_id',
        // 'bounces',
        // 'avg_industry_duration',
    ],
    isTableTitleSearch: true,
    isShowTotals: true,
    availableTools: {
        numberOfGraphs: true,
        periodPicker: true,
        sample: true,
        activeSample: true,
        filters: true,
        savedFilters: true,
        detail: true,
    },
    reportHint: {
        message: 'Страницы сайта, на которых заканчиваются визиты. Указывается полный URL. '
            + 'Есть возможность поиска по URL сайта. Доступные показатели: посетители, визиты, просмотры, '
            + 'отказы, время на сайте, глубина просмотра, время на странице.',
    },
    // isSessionTable: true,
};

export default reportConfig;
