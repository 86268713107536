import reportsConstants from '@constants/reports';
import { IDataItemType, ISelectedLine } from '@typings/graph';
import { IDict } from '@typings/table';
import { measuresUtils } from '@utils/index';

const graphReducerUtils = {
    processTitleGraph: (item: IDataItemType, dict: Record<string, IDict> = {}) => {
        let { id: node } = item;
        let titles = [];

        if (node in dict) {
            titles = [dict[node].title];

            while (dict[node].parentId !== reportsConstants.ROOT_NODE) {
                node = dict[node].parentId;
                titles.push(dict[node].title);
            }
        } else {
            return item;
        }

        return {
            ...item,
            title: titles.reverse().join(reportsConstants.LEGEND_DELIMITER),
        };
    },

    prepareMetricsValues: (values, metrics) => {
        const newValues = [];
        if (values?.length) {
            metrics.map((metric, key) => {
                const config = measuresUtils.getConfig(metric);
                let newValue = values[key];
                if (config?.formatterValue === 'percent' && newValue !== 0) newValue *= 100;
                newValues.push(newValue);
                return newValues;
            });
        }
        return newValues;
    },

    /**
     * Форматирует данные для передачи на клиент для графика
     */
    prepareGraphData: (response: any, dict: Record<string, IDict> = {}, ids?: any) => {
        const metrics = response.result.metrics;
        const meta = response.meta;
        const dataKeys = ids || Object.keys(response.result.data);

        const data = dataKeys.map((key) => ({
            data: response.result.data[key].map((item) => [
                key,
                ...graphReducerUtils.prepareMetricsValues(item.metrics, metrics),
            ]),
            title: key,
            id: key,
            publicStats: dict[key]?.publicStats ?? true,
        }));

        const totals = meta.totals
            ? dataKeys.map((key) => graphReducerUtils.prepareMetricsValues(meta.totals[key], metrics))
            : [];
        const currentGraphsTotals = graphReducerUtils.prepareMetricsValues(response?.meta?.common_total, metrics) ?? [];

        return {
            data,
            totals,
            currentGraphsTotals,
        };
    },

    filterLines: (lines: ISelectedLine[], newLines: ISelectedLine[]): ISelectedLine[] =>
        lines.filter((line) => newLines.find((newLine) => newLine.id !== line.id)),
};

export default graphReducerUtils;
