export const Op = {
    eq: '=',
    gt: '>',
    lt: '<',
    range: 'range',
    include: 'include',
};

export default {
    periodSelectOp: [
        { value: Op.gt, label: 'Больше, чем' },
        { value: Op.lt, label: 'Меньше, чем' },
        { value: Op.range, label: 'Интервал' },
    ],
    dateSelectOp: [
        { value: Op.gt, label: 'После' },
        { value: Op.lt, label: 'До' },
        { value: Op.range, label: 'Интервал' },
    ],
    defaultValues: {
        op: Op.gt,
        time: 'seconds',
        turning: 'include',
        entry: 'multilike',
        splitEntry: 'any',
    },
    periodSelectTime: [
        { value: 'seconds', label: 'Секунды' },
        { value: 'minutes', label: 'Минуты' },
    ],
    conditionType: {
        turning: [
            { label: 'Включить', value: 'include' },
            { label: 'Исключить', value: 'exclude' },
        ],
        entry: [
            { label: 'Любое из условий', value: 'multilike' },
            { label: 'Все условия', value: 'multilikeand' },
        ],
        splitEntry: [
            { label: 'Любой из сплитов', value: 'any' },
            { label: 'Все сплиты', value: 'all' },
        ],
    },
    glossary: {
        seconds: 'сек.',
        minutes: 'мин.',
    },
    validateErrorMsg: 'Ошибка фильтрации. Пожалуйста, проверьте корректность заполненных полей',
};
