import { ImageLikes } from '@images/svg/reportPlaceholder/likes';
import React from 'react';
import s from '../ReportPlaceholder.pcss';

export default {
    image: <ImageLikes />,
    link: 'https://developers.rambler.ru/likes/',
    title: 'Рамблер/лайки — кнопки социальных сетей для десктопных и мобильных сайтов',
    textBlocks(projectId) {
        return [
            'Установите Лайки на сайт, выбрав желаемые соцсети и дизайн виджета',
            'Смотрите подробную статистику по CTR кнопок на каждой странице в Статистике от SberAds',
            <span>
                Статистику по размеченным&nbsp;
                <a className={s.blockLink} href={`/stat/projects/${projectId}/new/report/utms`}>
                    UTM-меткам{' '}
                </a>
                вы найдете в одноименном отчёте
            </span>,
        ];
    },
};
