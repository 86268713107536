import { ChartColors, ChartColorsMin, ChartTypes } from '@configs/graph';
import { GroupBy } from '@configs/group';
import aliasesCnf from '@configs/widgets/aliases';
import { TChartPeriod } from '@typings/date';
import {
    CurrentGraphTotals,
    IGraphReport,
    IGraphColorsData,
    IGraphConf,
    IGraphs,
    IParamsGraphData,
    IParamsGraphArray,
    Totals,
    DataArrayType,
    IGraphData,
    ISelectedLine,
    TChartDataItem,
    TChartSeries,
} from '@typings/graph';
import { IMediaConfig } from '@typings/media';
import { Metrics } from '@typings/metrics';
import { IComputeGraphDataAndTotals, IReportConfig, IUpdateReportParams } from '@typings/reports';
import { IDataTable } from '@typings/table';
import DateUtils from '@utils/date';
import globalUtilsFunc from '@utils/global';
import { measuresUtils } from './index';

const graphUtils = () => {
    // Private

    /**
     * Получаем данные для bar
     */
    const getBarData = ({ data, dataTotals, measuresForGraphs, activeMeasure }: IParamsGraphData) => {
        let index = 1;

        // Из-за разных типов графиков у медиа виджетов данные приходят в totals
        const values = dataTotals || data;

        // Формат [name, value (, value,...)]
        // Если в данных одно значение, то искать по индексу выбранной метрики нет смысла
        if (values && values[0] && values[0].length > 2) {
            const ind = measuresForGraphs.indexOf(activeMeasure);
            if (ind >= 0) index = ind + 1;
        }

        return values.map((value) => ({
            name: value[0],
            data: [parseFloat(String(value[index]))],
        }));
    };

    /**
     * Получаем объект, обернутый в массив,
     * с данными о линии (имя и ее значения)
     */
    const getLineData = ({ data, measuresForGraphs, activeMeasure, title, publicStats = true }: IParamsGraphData) => {
        let index = 1;

        // Формат [name, value (, value,...)]
        // Если в данных одно значение, то искать по индексу выбранной метрики нет смысла
        if (data && data[0] && data[0].length > 2) {
            const ind = measuresForGraphs.indexOf(activeMeasure);
            if (ind >= 0) index = ind + 1;
        }

        return [
            {
                publicStats,
                name: title,
                data: data.map((item) => parseFloat(String(item[index]))),
            },
        ];
    };

    /**
     * Получаем коллекцию из линий и ее данных для построения графика
     */
    const getReportItemsData = ({ data, measuresForGraphs, activeMeasure }: IParamsGraphArray) => {
        const result = [];

        data.forEach((item) => {
            result.push(
                getLineData({
                    data: item.data,
                    title: item.title,
                    measuresForGraphs,
                    activeMeasure,
                    publicStats: item.publicStats,
                })[0],
            );
        });

        return result;
    };

    /**
     * Получаем тоталы с данными
     */
    const getReportTotalData = ({
        data,
        totals,
        measuresForGraphs,
        activeMeasure,
    }: {
        data: IGraphReport[];
        totals: Totals;
        measuresForGraphs: Metrics[];
        activeMeasure: Metrics;
    }) => {
        const index = measuresForGraphs.indexOf(activeMeasure);

        return totals.map((item, totalsIndex) => ({
            name: data[totalsIndex]?.title || '',
            data: [parseFloat(String(item[index]))],
        }));
    };

    /**
     * Возвращаем тоталы для "Остальное"
     */
    const getOtherData = (totals: Totals, currentGraphsTotals: CurrentGraphTotals, chartOrder: number): number => {
        // получаем индекс тоталов для определённого графика
        const index = totals[0].length > 1 ? chartOrder : 0;
        // получаем тоталы по индексу
        const projectNumbers = totals.map((item) => item[index]);
        // суммируем тоталы
        const sum = projectNumbers.reduce((prev, current) => prev + current);

        // возвращаем разницу
        return currentGraphsTotals[index] - sum;
    };

    /**
     * Получаем тоталы для pie
     */
    const getReportTotalDataPie = ({
        data,
        totals,
        measuresForGraphs,
        activeMeasure,
        currentGraphsTotals,
        chartOrder,
    }: {
        data: IGraphReport[];
        totals: Totals;
        measuresForGraphs: Metrics[];
        activeMeasure: Metrics;
        currentGraphsTotals: CurrentGraphTotals;
        chartOrder: number;
    }) => {
        const result = getReportTotalData({
            data,
            totals,
            measuresForGraphs,
            activeMeasure,
        });
        const otherData = getOtherData(totals, currentGraphsTotals, chartOrder);

        return [
            ...result,
            {
                name: 'Остальное',
                data: [otherData],
            },
        ];
    };

    // Public

    /**
     * Расчёт списка дат для оси X
     */
    const getDataDate = (period: TChartPeriod, group: GroupBy, count: number) => {
        switch (group) {
            case GroupBy.minute: {
                return DateUtils.getMinutesFromPeriod(period, count);
            }
            case GroupBy.fiveMinute: {
                return DateUtils.getFiveMinutesFromPeriod(period, count);
            }
            case GroupBy.hour: {
                return DateUtils.getHoursFromPeriod(period, count);
            }
            case GroupBy.week: {
                return DateUtils.getWeeksFromPeriod(period);
            }
            case GroupBy.month: {
                return DateUtils.getMonthsFromPeriod(period);
            }
            case GroupBy.day: {
                return DateUtils.getDaysFromPeriod(period);
            }
            default: {
                return DateUtils.getDaysFromPeriod(period);
            }
        }
    };

    /**
     * Адаптер данных для графиков, нужен для того
     * чтобы не переделывать формирование данных в статистике
     */
    const prepareChartData = (data: TChartDataItem[], period: TChartPeriod, group: GroupBy): TChartSeries[] => {
        if (!data?.length) return [] as TChartSeries[];

        const biggerItem = data.reduce((acc, item) => (item.data.length > acc.data.length ? item : acc));
        const newData = [];
        const date = getDataDate(period, group, biggerItem.data.length);

        data.forEach((item) => {
            for (let i = 0; i < biggerItem.data.length; i++) {
                // eslint-disable-next-line no-continue
                if (item.data[i] < 0) continue;

                if (!newData[i]) {
                    newData[i] = { date: date[i] };
                }

                newData[i][item.name] = item.data[i] || 0;
            }
        });

        return newData as TChartSeries[];
    };

    const getHiddenStats = (data: TChartDataItem[]): Record<string, boolean> => {
        if (!data || !data.length) return {} as Record<string, boolean>;

        return data.reduce(
            (acc, item) => {
                const publicStats = item.publicStats ?? true;
                if (!publicStats) {
                    acc[item.name] = !publicStats;
                }
                return acc;
            },
            {} as Record<string, boolean>,
        );
    };

    /**
     * Получаем уникальные имена графиков
     */
    const getMetrics = (graphs: IGraphs[] = []): Metrics[] => [...new Set(graphs.map((item) => item.key))];

    /**
     * Получаем типы графиков
     */
    const getTypes = (graphs: IGraphs[]): ChartTypes[] => [...new Set(graphs.map((item) => item.value))];

    /**
     * Заменяем лейблы алиасами из конфига
     */
    const applyLabelsAliases = (data: DataArrayType[], titleAliases: Record<string, string>): DataArrayType[] =>
        data.map((item) => {
            const [title, rest] = item;
            const newTitle = titleAliases[title] ? titleAliases[title] : title;

            return [newTitle, rest];
        });

    /**
     * Получаем данные для определённого типа графика
     */
    const getData = (graphType: ChartTypes, params: IParamsGraphData & IParamsGraphArray) => {
        if (!graphType || !params.activeMeasure) return [];

        const config = measuresUtils.getConfig(params.activeMeasure);
        // Данные для линейных графиков могут быть двух форматов (зависит от кол-ва линий) -
        // плоский массив (дашборд виджеты)
        // и условно двумерный массив (медиа виджеты)
        const isComplexData = params.data[0] && params.data[0].data;
        const types: Record<ChartTypes, (...any) => any> = {
            [ChartTypes.simpleLine]: isComplexData ? getReportItemsData : getLineData,
            [ChartTypes.line]: isComplexData ? getReportItemsData : getLineData,
            [ChartTypes.area]: isComplexData ? getReportItemsData : getLineData,
            [ChartTypes.bar]: getBarData,
            [ChartTypes.barStacked]: isComplexData ? getReportItemsData : getLineData,
            [ChartTypes.barSpecial]: getLineData, // только на дашборд виджетах
            [ChartTypes.pie]: getBarData,
            [ChartTypes.biaxialLine]: null,
            [ChartTypes.funnelCustom]: null,
        };

        if (!types[graphType]) return [];

        let result = types[graphType](params);

        if (config.needRevers) {
            result = result.map((item) => ({
                ...item,
                data: item.data.map((i) => i * -1),
            }));
        }

        return result;
    };

    /**
     * Получаем данные для отчётов и для определённого типа графика
     */
    const getReportData = (graphType: ChartTypes, params: IParamsGraphArray) => {
        const types = {
            line: getReportItemsData,
            area: getReportItemsData,
            bar: getReportTotalData,
            barSpecial: getReportTotalData,
            pie: getReportTotalDataPie,
        };

        const invalidType = () => 'Graph type is not allowed!';

        const getReportFn = types[graphType] || invalidType;

        return getReportFn(params);
    };

    /**
     * Возвращаем цвета для графиков
     * @data - массив данных
     * @config - конфиг цветов в виде объекта или массива,
     * если null, то используется дефолтный из configs/graph
     * @keys - массив ключей, по которому сопоставляем значения цветов
     * @return - массив данных с цветами
     */
    const getColors = (
        data: any,
        config?: IGraphConf | string[],
        keys?: string[], // массив значений, напр. [straight, adv, social...]
    ): IGraphColorsData[] => {
        let dataWithColors;
        const colors = data.length > ChartColorsMin.length ? ChartColors : ChartColorsMin;

        // Config - объект
        if (config && !Array.isArray(config)) {
            dataWithColors = data.map((item, i) => {
                // Используем ключи на латиннице из массива ключей,
                // если null, то берем значения из конфига алиасов
                // Важно - порядок keys и data должен совпадать
                const key = (keys ? keys[i] : aliasesCnf[item.name]) || item.name;
                const color = config[key]?.color || colors[i];

                return {
                    title: item.name,
                    color,
                    id: color,
                };
            });

            return dataWithColors;
        }

        const colorsConfig = config && Array.isArray(config) ? config : colors;

        dataWithColors = data.map((item, i) => ({
            title: item.name,
            color: colorsConfig[i],
            id: colorsConfig[i],
        }));

        return dataWithColors;
    };

    /**
     * Рассчитываем данные graphData и graphTotals, с учётом выбранных линий
     */
    const computeGraphDataAndTotals = (
        selectedLines: ISelectedLine[],
        graphData: IGraphData[],
        graphTotals: Totals,
        tableData: IDataTable,
        isSummary?: boolean,
    ): IComputeGraphDataAndTotals => {
        let newGraphData = [];
        let newGraphTotals = [];

        if (isSummary) {
            newGraphData = graphData;
            newGraphTotals = graphTotals;
        } else {
            const ids = selectedLines.map((line) => graphData.findIndex((item) => item.id === line.id));

            newGraphTotals = graphTotals.filter((item, index) => ids.includes(index));

            newGraphData = graphData.filter((item) => selectedLines.find((line) => line.id === item.id));

            // Если graphData еще не успела обновиться,
            // а selectedLines уже новые, сбрасываем graphData,
            // иначе графики будут рисоваться некорректно во время лоадинга
            if (newGraphData.length !== selectedLines.length) newGraphData = [];

            // Проверяем, что элементы есть в данных
            // и перепроверяем названия, которые могли не прийти
            // если элементов не было в данных
            if (Object.keys(tableData.dict).length) {
                newGraphData = newGraphData.filter((item) => {
                    const line = { ...item };
                    if (tableData.dict[item.id]) {
                        line.title = item.title || tableData.dict[line.id].title;
                        return line;
                    }
                    return false;
                });
            }
        }

        return {
            newGraphData,
            newGraphTotals,
        };
    };

    const getSelectedLines = (arr: string[] = []): ISelectedLine[] =>
        arr.reduce(
            (acc, current, i) => [
                ...acc,
                {
                    id: current,
                    colorIndex: i,
                },
            ],
            [],
        );

    /**
     * Проверяем и обновляем линии
     */
    const updateSelectedLines = (
        config: IReportConfig | IMediaConfig,
        useDefaultLines: boolean,
        listId: string[],
        selectedLines?: ISelectedLine[],
        count?: number,
    ): ISelectedLine[] => {
        let newSelectedLines = [];

        if (!config?.numberOfGraphs) {
            // Если у отчёта нет графиков, возвращаем пустой массив
            return newSelectedLines;
        }

        if (!useDefaultLines && selectedLines.length) {
            // Оставляем выбранные ноды, которые есть в данных
            newSelectedLines = selectedLines.filter((item) => listId.includes(item.id));
        } else {
            // Устанавливаем количество нод по дефолту (DEFAULT_LINES_COUNT)
            // Если количество по дефолту превышает количество id в таблице, ограничиваем
            const listIdLength = listId.length;
            const newCount = count > listIdLength ? listIdLength : count;
            newSelectedLines = getSelectedLines(listId.slice(0, newCount));
        }

        return newSelectedLines;
    };

    /**
     * Проверяем, чтобы количество линий в графике не превышало количество в таблице
     */
    const checkCountSelectedLines = (selectedLines: ISelectedLine[], listIdLength: number): ISelectedLine[] => {
        const newSelectedLines = selectedLines || [];
        const linesCount = selectedLines.length;

        if (linesCount <= listIdLength) return newSelectedLines;

        return selectedLines.slice(0, listIdLength);
    };

    const compareParams = (oldData: IUpdateReportParams, newData: IUpdateReportParams): boolean => {
        const { isSameParamValue } = globalUtilsFunc();
        let result = false;

        Object.keys(newData).forEach((param) => {
            if (param === 'graphs' && isSameParamValue('graphs', oldData, newData)) {
                const metrics = getMetrics(newData.graphs);
                const oldMetrics = getMetrics(oldData.graphs);
                const types = getTypes(newData.graphs);

                if (isSameParamValue('metrics', { metrics }, { metrics: oldMetrics })) {
                    result = true;
                } else if (
                    types.includes(ChartTypes.pie) &&
                    !(oldData.currentGraphsTotals && oldData.currentGraphsTotals.length)
                ) {
                    result = true;
                }

                return;
            }

            if (isSameParamValue(param, oldData, newData)) result = true;
        });

        return result;
    };

    return Object.freeze({
        prepareChartData,
        getHiddenStats,
        getMetrics,
        getTypes,
        applyLabelsAliases,
        getData,
        getReportData,
        getColors,
        computeGraphDataAndTotals,
        getSelectedLines,
        updateSelectedLines,
        checkCountSelectedLines,
        compareParams,
        // Private
        getLineData,
        getReportItemsData,
        getReportTotalData,
        getReportTotalDataPie,
        getOtherData,
    });
};

export default graphUtils();
