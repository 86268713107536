import GoalForm from '@components/GoalForm';
import { EntityAccess } from '@configs/entity';
import { goalFormTypesConfig, GoalFormType } from '@configs/goals/goalForm';
import { IGoalsConditionData } from '@typings/goals';
import React from 'react';

import s from '../ModalPopup.pcss';

interface IProps {
    type: GoalFormType;
    title?: string;
    access?: EntityAccess;
    isProtected?: boolean;
    isSendingTD?: boolean;
    conditionData?: IGoalsConditionData;
    closePopup: () => void;
    goalId?: number;
    onClose: () => void;
}

export default {
    getContent(props: IProps) {
        const popupContent = (
            <GoalForm
                title={props.title}
                accessProp={props.access}
                protectedProp={props.isProtected}
                sendingTDProp={props.isSendingTD}
                type={props.type}
                conditionData={props.conditionData}
                closePopup={props.closePopup}
                goalId={props.goalId}
            />
        );

        return {
            popupClassName: s.goalForm,
            popupWIdth: 'xl',
            title: goalFormTypesConfig[props.type].title,
            content: popupContent,
            closeOnClickInsideOnly: true,
            onClose: props.onClose,
        };
    },
};
