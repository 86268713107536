import React from 'react';
import s from '../ModalPopup.pcss';

interface IProps {
    modalContent;
    cancelButtonHandler;
    title: string;
    message: string;
}

export default {
    getContent(props: IProps) {
        const popupContent = (
            <div className={s.content}>
                <div dangerouslySetInnerHTML={{ __html: props.message }} />
                {props.modalContent()}
            </div>
        );

        const okButton = {
            type: 'default',
            children: 'Понятно',
            closePopupOnClick: true,
        };

        const cancelButton = {
            type: 'dashed',
            onClick: props.cancelButtonHandler,
            children: 'Больше не показывать',
            closePopupOnClick: true,
        };

        return {
            popupClassName: s.hintPopup,
            title: props.title,
            content: popupContent,
            buttonContainerClassName: s.buttons,
            okButton,
            cancelButton,
        };
    },
};
