import { Input, Loader } from '@adtech/ui';
import fieldDecorator from '@decorators/fieldDecorator';
import { IField, IMetaField } from '@typings/form';
import React, { FC } from 'react';

interface IProps {
    field: IField;
    meta: IMetaField;
    loading: boolean;
}

export const InputFieldComponent: FC<IProps> = ({ field, meta, loading }: IProps) => {
    const { invalid, focused, errorText, errorType } = meta || {};
    const { onBlur, onClick, onChange, onFocus, value, name } = field || {};
    const isError = invalid && !focused && errorType === 'email';

    return (
        <Input
            name={name}
            value={value}
            placeholder="Введите email"
            suffix={loading ? <Loader size="small" /> : <span />}
            onBlur={onBlur}
            onChange={onChange}
            onClick={onClick}
            onFocus={onFocus}
            status={isError ? 'error' : ''}
            errorText={isError ? errorText : ''}
            autoComplete="off"
            type="text"
        />
    );
};

export default fieldDecorator({
    name: 'email',
})(InputFieldComponent);
