export const fields = [
    'event_class',
    'event_name',
    'session_id',
    'session_number',
    'event_number_in_session',
    'version',
    'counter_type',
    'publisher_uid',
    'publisher_uid_scope'
]