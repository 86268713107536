import { IconCompanies, IconStatistics, IconGraph, IconDesign, IconAccountSettings, IconSettings } from '@adtech/ui';
import React, { ReactElement } from 'react';

export interface ISberAdsMenuItem {
    title: string;
    type: 'bottom' | 'top';
    prodLink?: string;
    rcLink?: string;
    icon?: ReactElement;
    className?: string;
    onlyKeyloak?: boolean;
    onlyRc?: boolean;
}

const sberAdsMenuConfig: ISberAdsMenuItem[] = [
    {
        title: 'Кампании',
        rcLink: 'https://manager.ift-mesh.ad-tech.ru/campaigns/',
        prodLink: 'https://ads.sber.ru/td/campaigns/',
        icon: <IconCompanies />,
        type: 'top',
    },
    {
        title: 'Отчеты',
        rcLink: 'https://manager.ift-mesh.ad-tech.ru/reports/',
        prodLink: 'https://ads.sber.ru/td/reports/',
        icon: <IconGraph />,
        type: 'top',
    },
    {
        title: 'Аудитории',
        rcLink: 'https://manager.ift-mesh.ad-tech.ru/audience/',
        prodLink: 'https://ads.sber.ru/td/audience/',
        icon: <IconDesign />,
        onlyRc: true,
        onlyKeyloak: true,
        type: 'top',
    },
    {
        title: 'Креативная студия',
        rcLink: 'https://manager.ift-mesh.ad-tech.ru/studio/',
        prodLink: 'https://ads.sber.ru/td/studio/',
        icon: <IconDesign />,
        onlyRc: true,
        onlyKeyloak: true,
        type: 'top',
    },
    {
        title: 'Статистика',
        rcLink: '/stat/',
        prodLink: '/stat/',
        icon: <IconStatistics />,
        type: 'top',
    },
    {
        title: 'Рекламный аккаунт',
        rcLink: 'https://manager.ift-mesh.ad-tech.ru/advAccount/edit/',
        prodLink: 'https://ads.sber.ru/td/advAccount/edit/',
        icon: <IconAccountSettings />,
        onlyKeyloak: true,
        type: 'bottom',
    },
    {
        title: 'Профиль',
        rcLink: 'https://manager.ift-mesh.ad-tech.ru/profile/',
        prodLink: 'https://ads.sber.ru/td/profile/',
        icon: <IconSettings />,
        onlyKeyloak: true,
        type: 'bottom',
    },
];

export default sberAdsMenuConfig;
