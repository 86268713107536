import { Button, Pagination, Loader } from '@adtech/ui';
import attributesData from '@configs/attributesData';
import cn from 'classnames';
import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { CounterRow } from './CounterRow';

import s from './RestoreCounterStep.pcss';

interface ICounterItem {
    id: number;
    url: string;
    title: string;
    created: string;
    inRating: boolean;
    favicon: string;
}

interface IProps {
    getProjectsWithSameUrl;
    restoreCounter;
    openModalPopup;
    isLoading: boolean;
    countersData: ICounterItem[];
    limit: number;
    offset: number;
    total: number;
    originUrl: string;
    goToCreateCounter: (e: React.MouseEvent) => void;
    userEmail: string;
}

export const RestoreCounterStep: React.FC<IProps> = ({
    getProjectsWithSameUrl,
    restoreCounter,
    isLoading = false,
    countersData = [],
    limit,
    offset,
    total,
    originUrl,
    goToCreateCounter,
    userEmail,
    openModalPopup,
}) => {
    const [selectedCounter, setSelected] = useState(countersData[0]?.id);
    const navigate = useNavigate();

    const getNextPageCounters = (page: number) => {
        const newOffset = (page - 1) * limit;

        getProjectsWithSameUrl({
            url: originUrl,
            limit,
            offset: newOffset,
        });
    };

    const pagesCount = Math.ceil(total / limit);
    const currentPage = Math.ceil(offset / limit) + 1;

    if (!countersData.length) return null;

    return (
        <div className={s.restoreCounterStep}>
            <Loader loading={isLoading}>
                <div className={s.table}>
                    <div className={s.head}>
                        <div className={cn(s.title, s.titleHead)}>Сайт</div>
                        <div className={s.date}>Дата создания</div>
                    </div>
                    <div className={s.body}>
                        {countersData.map((counterItem: ICounterItem) => (
                            <CounterRow
                                key={counterItem.id}
                                counterItem={counterItem}
                                onChange={(value) => setSelected(value)}
                                selectedCounter={selectedCounter}
                            />
                        ))}
                    </div>
                    {pagesCount > 1 && (
                        <div className={s.footer}>
                            <Pagination pageSize={pagesCount} current={currentPage} onChange={getNextPageCounters} />
                        </div>
                    )}
                </div>
                <div className={s.buttons}>
                    <Button
                        type="dashed"
                        onClick={goToCreateCounter}
                        data-create-counter={
                            `${attributesData.createCounter.restoreCounterStep}` +
                            `::${attributesData.createCounter.createCounterBtn}`
                        }
                    >
                        Создать новый счётчик
                    </Button>
                    <Button
                        onClick={() => {
                            restoreCounter(selectedCounter, navigate);
                            openModalPopup('RestoreAccessFinal', {
                                userEmail,
                            });
                        }}
                        data-create-counter={
                            `${attributesData.createCounter.restoreCounterStep}` +
                            `::${attributesData.createCounter.restoreCounterBtn}`
                        }
                    >
                        Восстановить доступ
                    </Button>
                </div>
            </Loader>
        </div>
    );
};
