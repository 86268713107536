const reportsParamsConfig = {
    groupBy: [
        'day',
        'week',
        'month',
    ],
    numberOfGraphs: [
        'one',
        'two',
        // 'widgets'
    ],
    behaviorReports: [
        'usability',
        'likes',
        'chapters',
        'custom_vars',
    ],
};

export default reportsParamsConfig;
