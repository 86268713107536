const PREFIX = 't100';

class StorageUtils {
    __private: any;

    constructor() {
        const self = StorageUtils;

        return Object.freeze({
            get: this.get,
            set: this.set,
            getStorageData: this.getStorageData,
            __private: {
                getRowName: self.getRowName,
            },
        });
    }

    get = (name: number | string, userId?: number) => {
        const rowName = StorageUtils.getRowName(PREFIX, userId);
        const data = this.getStorageData(rowName);

        if (data) return data[name];

        return null;
    };

    set = (name: number | string, value: number | string, userId?: number, widgetName?: string, projectId?: number) => {
        const rowName = StorageUtils.getRowName(PREFIX, userId);
        const data = this.getStorageData(rowName) || {};
        let newData;

        if (widgetName && projectId) {
            newData = {
                ...data,
                [projectId]: {
                    [name]: {
                        ...[name][widgetName],
                        [widgetName]: {
                            category: value,
                        },
                    },
                },
            };
        } else {
            newData = { ...data, [name]: value };
        }

        const resultData = JSON.stringify(newData);

        localStorage.setItem(rowName, resultData);
    };

    getStorageData = (storageName: string) => {
        const data = localStorage.getItem(storageName);

        return (data && JSON.parse(data)) || null;
    };

    private static getRowName(prefix: string, userId?: number) {
        const result = [];

        if (prefix) {
            result.push(prefix);
        }

        if (userId) {
            result.push(`u${userId}`);
        }

        return result.join('_');
    }
}

export default new StorageUtils();
