import { ImageMedia } from '@images/svg/reportPlaceholder/media';
import React from 'react';

export default {
    image: <ImageMedia />,
    link: 'https://ads.sber.ru/help/razmetka-schemaorg-dlya-otchetov-po-media',
    title: 'Медиаотчёты помогут оценить качество контента вашего сайта',
    textBlocks() {
        return [
            '«Отчёт по материалам» — просмотры, дочтения, доскролы и рециркуляция для каждой публикации на сайте',
            '«Топ материалов» — отчёт по материалам с частотой обновления 30 секунд',
            '«Отчёт по авторам» — статистика по авторам контента на сайте',
        ];
    },
    buttonTitle: 'Как подключить',
    demoBtnTitle: 'Демо отчётов',
    demoBtnLink: 'https://ads.sber.ru/stat/projects/7596044/new/media/materials_summary',
};
