export enum OperationsLiterals {
    gt = 'больше, чем',
    lt = 'меньше, чем',
    eq = 'равно',
}

export enum Operations {
    gt = '>',
    lt = '<',
    eq = '=',
}

export const operationsCnf: Record<OperationsLiterals, string> = {
    [OperationsLiterals.gt]: '>',
    [OperationsLiterals.lt]: '<',
    [OperationsLiterals.eq]: '=',
};

export const operationsReverseCnf: Record<string, OperationsLiterals> = {
    '>': OperationsLiterals.gt,
    '<': OperationsLiterals.lt,
    '=': OperationsLiterals.eq,
};
