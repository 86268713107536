/**
 * Общий конфиг-группировка статических фильтров для медиа и отчётов,
 * объединяет в себе все фильтры из обоих конфигов без дублирования
 * используется в global reducer для получения параметров конкретного выбранного фильтра
 */
import { filtersCnf } from '@configs/filters/filters';

const {
    view_depth,
    visit_time,
    geo_country,
    geo_area,
    geo_city,
    sex,
    age,
    segments,
    device,
    browser,
    os,
    traffic_sources,
    ad_systems,
    ad_campaign,
    utms,
    openstat,
    utm_eventbase,
    openstat_eventbase,
    sites,
    search_phrase_system,
    search_system_phrase,
    page_urls,
    url,
    url_no_args,
    recom_system,
    message_system,
    exchange_system,
    qr_system,
    aggrnews_system,
    social_network,
    entrance_pages,
    exit_pages,
    page_titles,
    recirculation,
    time_on_material,
    author_media,
    title_media,
    publish_datetime,
    publish_date,
    publish_time,
    counter_type,
    event_class,
    event_name,
    session_number,
    split,
    auth_top100,
    auth_rambler,
    app_version,
    ub_block_list,
    customer_params_key,
} = filtersCnf;

export const staticFilters = [
    {
        title: 'Аудитория',
        name: 'audience',
        children: [
            sex,
            age,
            segments,
            {
                title: 'География',
                name: 'geo',
                children: [
                    geo_country,
                    geo_area,
                    geo_city,
                ],
            },
            session_number,
            view_depth,
            visit_time,
        ],
    },
    {
        title: 'Технологии',
        name: 'technology',
        children: [
            device,
            browser,
            os,
            counter_type,
            app_version,
        ],
    },
    {
        title: 'Источники',
        name: 'sources',
        children: [
            traffic_sources,
            ad_systems,
            ad_campaign,
            utms,
            openstat,
            utm_eventbase,
            openstat_eventbase,
            sites,
            search_phrase_system,
            search_system_phrase,
            aggrnews_system,
            exchange_system,
            recom_system,
            social_network,
            message_system,
            qr_system,
        ],
    },
    {
        title: 'Содержание',
        name: 'content',
        children: [
            page_urls,
            url,
            url_no_args,
            entrance_pages,
            exit_pages,
            page_titles,
            author_media,
            title_media,
            recirculation,
            time_on_material,
            publish_datetime,
            publish_date,
            publish_time,
        ],
    },
    {
        title: 'События',
        name: 'events',
        children: [
            event_class,
            event_name,
            {
                title: 'Параметры события',
                name: 'events_params',
                children: [
                    ub_block_list,
                    customer_params_key,
                ],
            },
        ],
    },
    {
        title: 'Тип авторизации',
        name: 'auth',
        children: [
            auth_top100,
            auth_rambler,
        ],
    },
    {
        title: 'Эксперименты',
        name: 'experiments',
        children: [
            split,
        ],
    },
];
