const navigationUtils = () => {
    // Определение name активной ссылки
    const getActive = (pathname: string, projectId: number): string => {
        const arrayPath = pathname.split('/');
        let active = '';

        if (projectId) {
            active = arrayPath[arrayPath.indexOf(String(projectId)) + 1];

            switch (active) {
                case 'settings': {
                    const settingsIndex = arrayPath.findIndex((item) => item === 'settings');
                    active = arrayPath[settingsIndex + 1];
                    break;
                }
                case 'new': {
                    const reportIndex = arrayPath.findIndex((item) => item === 'report' || item === 'media');
                    active = arrayPath[reportIndex + 1];
                    break;
                }
                default:
                    break;
            }
        } else {
            active = arrayPath.at(-1);
        }

        return active;
    };

    return Object.freeze({
        getActive,
    });
};

export default navigationUtils();
