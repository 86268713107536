import { IconSearch } from '@adtech/ui';
import IconCloseThin from '@images/svg/icons/icon-close-thin.svg';
import cn from 'classnames';
import React, { RefObject, SyntheticEvent, useState, useEffect, useRef, HTMLInputTypeAttribute } from 'react';
import { useLocation } from 'react-router-dom';

import s from './Input.pcss';

interface IProps {
    value: string;
    placeholder: string;
    maxLength?: number;
    id?: number;
    withIcon?: boolean;
    disabled?: boolean;
    type?: HTMLInputTypeAttribute;
    autoComplete?: boolean;
    classNameRoot?: string;
    className?: string;
    classNameIconSearch?: string;
    onChange?: (value: string, clear?: boolean) => void;
    onKeyDown?: (event: SyntheticEvent) => void;
    onClear?: () => void;
    onBlur?: (event: SyntheticEvent) => void;
    onPathChange?: () => void;
    focusHandler?: () => void;
    focus?: boolean;
    dataAttrName?: string;
    dataAttr?: string;
}

const Input: React.FC<IProps> = ({
    value = '',
    placeholder = '',
    withIcon = true,
    type = 'text',
    autoComplete,
    maxLength,
    id,
    disabled = false,
    classNameRoot = '',
    className = '',
    classNameIconSearch = '',
    onChange,
    onKeyDown,
    onClear,
    onBlur,
    onPathChange,
    focusHandler, // TODO: добавлено преимущественно для тестов
    focus,
    dataAttrName = '',
    dataAttr = '',
}) => {
    const [inputValue, setInputValue] = useState<string>(value);
    const [timer, setTimer] = useState<number>();
    const { pathname } = useLocation();
    const input: RefObject<HTMLInputElement> = useRef(null);

    // Сбрасываем значение при переходе на другие страницы
    useEffect(() => {
        if (value) setInputValue('');
        if (typeof onPathChange === 'function') onPathChange();
    }, [pathname]);

    useEffect(() => {
        const el = input.current;
        if (focus) {
            el?.focus();
        } else {
            el?.blur();
        }
    }, [focus]);

    const onChangeHandler = (event) => {
        const newValue = event?.target?.value || '';
        setInputValue(newValue);

        if (timer) clearTimeout(timer);
        setTimer(
            window.setTimeout(() => {
                if (newValue === value) return;
                onChange(newValue, event);
            }, 600),
        );
    };

    const defaultFocusInput = () => {
        input?.current?.focus();
    };
    const focusInput = focusHandler || defaultFocusInput;

    const clear = () => {
        if (!value) return;
        setInputValue('');
        // Keep focus on clear for good usability
        focusInput();
        onClear();
    };

    const renderCounter = () => {
        const counterClass = cn(s.input_counter, value.length > maxLength && s.input_counter_invalid);

        const text = `Осталось символов: ${maxLength - value.length}`;

        return <div className={counterClass}>{text}</div>;
    };

    const classNamesInput = cn(s.input, className, {
        [s.inputWithClearIcon]: withIcon,
    });

    const classNamesIconSearch = cn(s.inputIconSearch, classNameIconSearch);

    return (
        <div className={classNameRoot}>
            <input
                // eslint-disable-next-line
                autoFocus={focus}
                ref={input}
                onChange={onChangeHandler}
                onKeyDown={onKeyDown}
                onBlur={onBlur}
                className={classNamesInput}
                type={type}
                data-testid="inputElement"
                value={inputValue}
                placeholder={placeholder}
                disabled={disabled}
                autoComplete={autoComplete ? 'on' : 'off'}
                id={String(id) || null}
                {...(dataAttrName && dataAttr
                    ? {
                          [`data-${dataAttrName}`]: dataAttr,
                      }
                    : {})}
            />

            {withIcon && (
                <div className={s.inputIcons}>
                    <span className={classNamesIconSearch}>
                        <IconSearch />
                    </span>
                    {value && (
                        <span data-testid="inputIconClear" className={s.inputIconClear} onClick={clear}>
                            <IconCloseThin />
                        </span>
                    )}
                </div>
            )}

            {maxLength && renderCounter()}
        </div>
    );
};

export default Input;
