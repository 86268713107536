import { Cookies } from '@adtech/cookies';
import cookieName from '@constants/cookieName';
import { createAsyncThunk } from '@reduxjs/toolkit';
import { ISearchParams } from '@typings/search';
import fetchApi from '@utils/fetchApi';
import { urlUtils } from '@utils/index';

export const getUser = createAsyncThunk('userSlice/getUser', async () => {
    const authType = Cookies.get(cookieName.authType);
    // eslint-disable-next-line no-return-await
    return await fetchApi({
        url: '/api/v1.0/user/current',
        headers: {
            'X-Cerber-Auth-Type': authType,
        },
    });
});

export const getUserProjects = createAsyncThunk('userSlice/getUserProjects', async (params: ISearchParams) => {
    let url = '/api/v1.0/user/current/projects';
    const query = urlUtils.objectToQuery(params);
    url = query ? `${url}?${query}` : url;
    // eslint-disable-next-line no-return-await
    return await fetchApi({ url });
});

export const login = createAsyncThunk('userSlice/login', async (params: any) => {
    const authType = Cookies.get(cookieName.authType);
    // eslint-disable-next-line no-return-await
    return await fetchApi({
        url: '/api/v1.0/user/login',
        method: 'POST',
        body: params,
        headers: {
            'X-Cerber-Auth-Type': authType,
        },
    });
});

export const logoutKeycloak = createAsyncThunk(
    'userSlice/logoutKeycloak',
    async () =>
        // eslint-disable-next-line no-return-await
        await fetchApi({
            url: '/api/v1.0/user/logout',
            method: 'GET',
            headers: {
                'X-Cerber-Auth-Type': 'keycloak',
            },
        }),
);

export const logoutId = createAsyncThunk(
    'userSlice/logoutId',
    async () =>
        // eslint-disable-next-line no-return-await
        await fetchApi({
            url: 'https://id.rambler.ru/jsonrpc',
            method: 'POST',
            credentials: 'include',
            body: {
                method: 'Rambler::Id::destroy_session',
                params: [{}],
                rpc: '2.0',
            },
        }),
);

export const getUserExists = createAsyncThunk(
    'userSlice/getUserExists',
    async (email: string) =>
        // eslint-disable-next-line no-return-await
        await fetchApi({
            url: '/api/v1.0/user/exists',
            method: 'POST',
            body: { email },
        }),
);

export const refreshToken = createAsyncThunk(
    'userSlice/refreshToken',
    async () =>
        // eslint-disable-next-line no-return-await
        await fetchApi({
            url: '/api/v1.0/user/refresh_token',
            method: 'GET',
            metaInfo: {
                ignoreAllErrors: true,
            },
        }),
);
