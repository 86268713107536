import { Button, Checkbox, IconPlus, TableSearch } from '@adtech/ui';
import { Hint } from '@components/Hint';
import React, { useRef, useState, useEffect } from 'react';

import s from './TableTools.pcss';

interface IProps {
    onSearchChange: (value: string) => void;
    searchPlaceholder?: string;
    searchWord?: string;
    prefixButtonText?: string;
    testId?: string;
    onClickPrefixButton?: () => void;
    switcherText?: string;
    onChangeSwitcher?: (checked: boolean) => void;
    switcherHintText?: string;
    switcherChecked?: boolean;
}

const TableTools: React.FC<IProps> = ({
    prefixButtonText = '',
    testId = '',
    onClickPrefixButton,
    searchPlaceholder = 'Искать',
    searchWord = '',
    onSearchChange,
    switcherText,
    switcherHintText,
    onChangeSwitcher,
    switcherChecked = false,
}) => {
    const timer = useRef(null);
    const checked = useRef<boolean>(switcherChecked);
    const searchWordRef = useRef<string>('');
    const [searchValue, setSearchValue] = useState<string>('');

    useEffect(() => {
        if (searchWordRef.current === '' && searchWord === '') return;
        if (searchWordRef.current !== searchWord) setSearchValue(searchWord);
        searchWordRef.current = searchWord;
    }, [searchWord]);

    // Поиск
    const handleSearchChange = (value: string) => {
        const newValue = value.trim();
        setSearchValue(newValue);
        onSearchChange(newValue);
    };

    // Сброс значения в поиске
    const handleSearchClear = () => {
        handleSearchChange('');
    };

    // Ввод значения в поиске
    const handleSearchInput = (e) => {
        const newValue = e?.target?.value || '';
        if (searchValue === newValue) return;
        setSearchValue(newValue);
        if (timer.current) clearTimeout(timer.current);
        timer.current = setTimeout(() => handleSearchChange(newValue), 500);
    };

    // Изменение чекбокса
    const handleChangeSwitcher = (e) => {
        const newValue = e?.target?.checked;
        checked.current = newValue;
        onChangeSwitcher(newValue);
    };

    const renderPrefixButton = prefixButtonText && (
        <Button data-testid={testId} prefixIcon={<IconPlus />} onClick={onClickPrefixButton}>
            {prefixButtonText}
        </Button>
    );

    return (
        <div className={s.root}>
            <TableSearch
                prefixButton={renderPrefixButton}
                onSearch={handleSearchChange}
                onClearIconClick={handleSearchClear}
                onInput={handleSearchInput}
                value={searchValue}
                searchPlaceholder={searchPlaceholder}
            />
            {switcherText && (
                <div className={s.switcher} data-testid="showHide-container">
                    <Checkbox onChange={handleChangeSwitcher} checked={checked.current}>
                        {switcherText}
                    </Checkbox>
                    {switcherHintText && <Hint className={s.switcherHint}>{switcherHintText}</Hint>}
                </div>
            )}
        </div>
    );
};

export default TableTools;
