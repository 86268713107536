import { GraphsDict } from '@typings/graph';
import { Metrics } from '@typings/metrics';

interface IAliases {
    title?: string;
    tooltip?: string;
}

type ExtraTooltip = Partial<Record<'tooltipAfter' | 'tooltipBefore', string>>

export interface IMetric {
    name?: Metrics;
    title?: string;
    parentTitle?: string; // название родителя, для вывода в конструкторе
    format?: 'number' | 'float' | 'percent' | 'time';
    type?: 'int' | 'float';
    tooltip?: string;
    linkToDoc?: string; // отображается в подсказке
    minValue?: number | null;
    diffFormat?: 'percent';
    hidePercent?: boolean;
    hideSort?: boolean;
    needRevers?: boolean;
    formatterValue?: 'percent'; // процентные метрики, которые на бэке обрабатываются в виде дробных значений
    dimension?: string;
    disabledGraphs?: GraphsDict;
    // По отчётам
    disabledGraphsByReports?: Record<string, GraphsDict>; // недоступные типы графиков
    aliasesByReports?: Record<string, IAliases>; // название и подсказка
    extraTooltipByReports?: Record<string, ExtraTooltip>; // дополнительный текст для подсказки
}

/**
 * Матчинг только для summary (нужно расширять)
 */
export const oldMetrics: Record<string, string> = {
    bounces: 'bounce_rate',
    avg_view_depth: 'browsing_depth',
    avg_industry_duration: 'avg_time',
    uid: 'uid_visitors',
    rambler_id: 'rccid_visitors',
};

export const oldMetricsReverse: Record<string, string> = {
    bounce_rate: 'bounces',
    browsing_depth: 'avg_view_depth',
    avg_time: 'avg_industry_duration',
    uid_visitors: 'uid',
    rccid_visitors: 'rambler_id',
};

export const metricsDict: Record<string, IMetric> = {
    visits: {
        name: 'visits',
        title: 'Визиты',
        format: 'number',
        type: 'int',
        diffFormat: 'percent',
        tooltip: 'Последовательность действий одного посетителя на сайте. Тайм-аут визита — 30 минут',
        minValue: 10,
    },
    bounces: {
        name: 'bounces',
        title: 'Отказы',
        hidePercent: true,
        format: 'percent',
        type: 'int',
        disabledGraphs: {
            pie: true,
        },
        tooltip: 'Доля визитов, закончившихся после просмотра одной страницы',
        linkToDoc: 'https://ads.sber.ru/help/vychislyaemye-metriki',
        minValue: null,
    },
    visitors: {
        name: 'visitors',
        title: 'Посетители',
        format: 'number',
        diffFormat: 'percent',
        type: 'int',
        hidePercent: true,
        disabledGraphs: {
            pie: true,
        },
        tooltip: 'Посетители сайта, обладающие не повторяющимся набором характеристик',
        extraTooltipByReports: {
            rating: {
                tooltipAfter: '. \n\rЗначение на начало выбранного периода',
            },
        },
        minValue: 10,
    },
    page_views: {
        name: 'page_views',
        title: 'Просмотры',
        format: 'number',
        diffFormat: 'percent',
        type: 'int',
        tooltip: 'Загрузка страницы сайта при переходе посетителя на нее',
        extraTooltipByReports: {
            rating: {
                tooltipAfter: '. Значение на начало выбранного периода',
            },
        },
        minValue: 10,
    },
    popularity: {
        name: 'popularity',
        title: 'Популярность',
        format: 'number',
        type: 'int',
        hidePercent: true,
        disabledGraphs: {
            pie: true,
        },
        tooltip: (`
            Популярность рассчитана на дату начала выбранного периода.
            Формула расчёта популярности учитывает визиты за вычётом
            отказов, посетителей сайта и глубину просмотра.
            Наибольший вес имеют визиты за вычётом отказов
        `),
        minValue: 10,
    },
    position: {
        name: 'position',
        title: 'Позиция',
        format: 'number',
        type: 'int',
        hidePercent: true,
        hideSort: true,
        disabledGraphs: {
            pie: true,
        },
        tooltip: 'Позиция проекта в рейтинге',
        minValue: 10,
        needRevers: true,
    },
    avg_industry_duration: {
        name: 'avg_industry_duration',
        title: 'Время на сайте',
        hidePercent: true,
        format: 'time',
        type: 'float',
        disabledGraphs: {
            pie: true,
        },
        tooltip: 'Разница по времени между первым и последним событием в визите',
        minValue: 10,
    },
    avg_view_duration: {
        name: 'avg_view_duration',
        title: 'Время на странице',
        hidePercent: true,
        format: 'time',
        type: 'float',
        disabledGraphs: {
            pie: true,
        },
        tooltip: 'Среднее время активности посетителей на странице',
        aliasesByReports: {
            chapters: {
                title: 'Время в разделе',
                tooltip: 'Среднее время активности посетителей в разделе',
            },
            top: {
                title: 'Время на материале',
                tooltip: 'Среднее время активности посетителей на материале',
            },
            materials_summary: {
                title: 'Время на материале',
                tooltip: 'Среднее время активности посетителей на материале',
            },
            material: {
                title: 'Время на материале',
                tooltip: 'Среднее время активности посетителей на материале',
            },
            authors_summary: {
                title: 'Время на материале',
                tooltip: 'Среднее время активности посетителей на материале',
            },
            author: {
                title: 'Время на материале',
                tooltip: 'Среднее время активности посетителей на материале',
            },
        },
        minValue: 10,
    },
    avg_sessions_duration: {
        name: 'avg_sessions_duration',
        title: 'Средняя длительность сессии',
        hidePercent: true,
        format: 'time',
        type: 'float',
        disabledGraphs: {
            pie: true,
        },
        tooltip: 'Суммарная длительность всех сессий, деленная на их количество',
        minValue: 0,
    },
    avg_view_depth: {
        name: 'avg_view_depth',
        title: 'Глубина',
        format: 'float',
        diffFormat: 'percent',
        type: 'float',
        hidePercent: true,
        disabledGraphs: {
            pie: true,
        },
        tooltip: 'Количество просмотров страниц сайта в рамках одного визита',
        minValue: 3,
    },
    usability_views: {
        name: 'usability_views',
        title: 'Показы',
        format: 'number',
        type: 'int',
        hidePercent: true,
        disabledGraphs: {
            pie: true,
        },
        tooltip: 'Показы размеченных дата-атрибутами блоков',
        minValue: 10,
    },
    usability_clicks: {
        name: 'usability_clicks',
        title: 'Клики',
        format: 'number',
        type: 'int',
        hidePercent: true,
        disabledGraphs: {
            pie: true,
        },
        tooltip: 'Клики по размеченным дата-атрибутами блокам',
        minValue: 10,
    },
    usability_ctr: {
        name: 'usability_ctr',
        title: 'CTR',
        hidePercent: true,
        format: 'percent',
        type: 'float',
        disabledGraphs: {
            pie: true,
            area: true,
        },
        tooltip: 'Отношение кликов к показам блоков',
        minValue: null,
    },
    uid: {
        name: 'uid',
        title: 'Авторизованные пользователи',
        format: 'number',
        type: 'int',
        hidePercent: true,
        disabledGraphs: {
            pie: true,
        },
        tooltip: 'Авторизованные пользователи, которые площадка передает через параметр user_id',
        minValue: 10,
    },
    rambler_id: {
        name: 'rambler_id',
        title: 'Пользователи Рамблер ID',
        format: 'number',
        type: 'int',
        hidePercent: true,
        disabledGraphs: {
            pie: true,
        },
        tooltip: 'Авторизованные пользователи через Рамблер ID',
        minValue: 10,
    },
    avg_recirculation: {
        name: 'avg_recirculation',
        title: 'Рециркуляция',
        type: 'int',
        tooltip: 'Доля пользователей, которые просмотрели более одного материала на сайте',
        minValue: 0,
        hidePercent: true,
        format: 'percent',
        formatterValue: 'percent',
        disabledGraphsByReports: {
            authors_summary: {
                pie: true,
            },
        },
    },
    avg_fullread_percent: {
        name: 'avg_fullread_percent',
        title: 'Дочтение',
        type: 'float',
        tooltip: 'Средняя доля просмотров, при которых посетители провели на материале достаточно времени, чтобы полностью его прочитать',
        minValue: 0,
        hidePercent: true,
        format: 'percent',
    },
    avg_fullscroll_percent: {
        name: 'avg_fullscroll_percent',
        title: 'Доскролл',
        type: 'float',
        tooltip: 'Процент доскролла',
        minValue: 0,
        hidePercent: true,
        format: 'percent',
        disabledGraphsByReports: {
            authors_summary: {
                pie: true,
            },
        },
    },
    recirculation_count: {
        name: 'recirculation_count',
        title: 'Переходы',
        type: 'int',
        tooltip: 'Количество переходов',
        minValue: 10,
        hidePercent: true,
        format: 'number',
    },
    materials: {
        name: 'materials',
        title: 'Всего материалов',
        type: 'int',
        tooltip: 'Всего материалов',
        minValue: 0,
        hidePercent: true,
        format: 'number',
    },
    events_count: {
        name: 'events_count',
        title: 'Количество событий',
        type: 'int',
        minValue: 0,
        hidePercent: true,
        format: 'number',
    },
    // SEO (отчет убрали, но метрика есть)
    ctr: {
        name: 'ctr',
        title: 'CTR',
        format: 'percent',
        type: 'float',
        tooltip: 'Отношение кликов к показам ссылок',
        minValue: null,
    },
    // Воронки
    visitors_all: {
        name: 'visitors_all',
        title: 'Посетители',
        format: 'number',
        type: 'int',
        minValue: 0,
    },
    visitors_left_step: {
        name: 'visitors_left_step',
        title: 'Ушли с шага (отток)',
        format: 'number',
        type: 'int',
        minValue: 0,
    },
    visitors_left_step_share: {
        name: 'visitors_left_step_share',
        title: 'Ушли с шага (отток), %',
        format: 'percent',
        type: 'float',
        minValue: 0,
    },
    conversion_first_step: {
        name: 'conversion_first_step',
        title: 'Конверсия от первого шага, %',
        format: 'percent',
        type: 'float',
        minValue: 0,
    },
    conversion_previous_step: {
        name: 'conversion_previous_step',
        title: 'Конверсия от предыдущего шага, %',
        format: 'percent',
        type: 'float',
        minValue: 0,
    },
};
