import { globalActions } from '@redux/slices/global';
import { projectsActions } from '@redux/slices/projects';
import { AppDispatch } from '@redux/store';
import { connect } from 'react-redux';
import { NavigateFunction } from 'react-router-dom';
import { RestoreCounterStep } from './RestoreCounterStep';

const mapStateToProps = ({
    projectsSlice: { projectsWithSameUrlRequest, projectsWithSameUrl },
    userSlice: { email },
}) => ({
    isLoading: projectsWithSameUrlRequest,
    countersData: projectsWithSameUrl.data,
    limit: projectsWithSameUrl.limit,
    offset: projectsWithSameUrl.offset,
    total: projectsWithSameUrl.total,
    originUrl: projectsWithSameUrl.originUrl,
    userEmail: email,
});

const mapDispatchToProps = (dispatch: AppDispatch) => ({
    getProjectsWithSameUrl(params: { url: string; limit: number; offset: number }) {
        dispatch(projectsActions.getProjectsWithSameUrl(params));
    },

    restoreCounter(projectId: number, navigate: NavigateFunction) {
        dispatch(projectsActions.restoreCounter(projectId)).then(({ payload }) => {
            if (payload.responseStatus === 204) {
                navigate('/stat/projects');
                dispatch(globalActions.openModalPopup({ modalPopupId: 'SuccessRestoreCounter' }));
            }
        });
    },

    openModalPopup(modalPopupId, modalPopupParams = {}) {
        dispatch(globalActions.openModalPopup({ modalPopupId, modalPopupParams }));
    },
});

export default connect(mapStateToProps, mapDispatchToProps)(RestoreCounterStep);
