import attributesData from '@configs/attributesData';
import { useAppDispatch } from '@hooks/useStore';
import IconSettings from '@images/svg/icons/icon-settings.svg';
import { userActions } from '@redux/slices/users';
import { IRootSlice } from '@typings/rootSlice';
import { ISearchParams } from '@typings/search';
import cn from 'classnames';
import React from 'react';
import { useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { ProjectSearch } from '..';

import s from './LinksPanel.pcss';

interface IUrlLinkProps {
    url: string;
    text: string;
}

interface ICounterLinkProps {
    id: number;
    accessLevel: boolean;
}

interface ISettingsLinkProps {
    id: number;
    accessLevel: boolean;
}

interface ISearchLinkProps {
    id: number;
    title: string;
    getUserProjects: (params: ISearchParams) => void;
    userProjects: any; // FIXME
    userProjectsRequest: boolean;
    mode: string;
    disabled: boolean;
}

const toplineAttr = attributesData.topline.attrName;

export function UrlLink({ url, text }: IUrlLinkProps) {
    const urlAttr = attributesData.topline.url;

    return (
        <a href={url} className={cn(s.navLink, s.navUrl)} target="_blank" data-topline={urlAttr} rel="noopener">
            <span className={s.navLinkText}>{text}</span>
        </a>
    );
}

export function CounterLink({ id, accessLevel }: ICounterLinkProps) {
    const href = accessLevel ? `/stat/projects/${id}/settings/counter` : `/stat/projects/${id}/dashboard`;

    const counterLinkAttr = attributesData.topline.counterLink;

    return (
        <a href={href} className={cn(s.navLink, s.navCounter)} target="_self" data-topline={counterLinkAttr}>
            <span className={s.navLinkText}>{id}</span>
        </a>
    );
}

export function SettingsLink({ id, accessLevel }: ISettingsLinkProps) {
    const counterSettingsAttr = attributesData.topline.counterSettings;

    return (
        accessLevel && (
            <a
                href={`/stat/projects/${id}/settings/counter`}
                className={cn(s.navLink, s.navSettings)}
                target="_self"
                data-topline={counterSettingsAttr}
            >
                <IconSettings />
            </a>
        )
    );
}

export function SearchLink({
    id,
    title,
    getUserProjects,
    userProjects,
    userProjectsRequest,
    mode,
    disabled,
}: ISearchLinkProps) {
    if (disabled) {
        return <div className={cn(s.navLink, s.navProject, s.navSimple)}>{title}</div>;
    }

    const projectSearchAttr = attributesData.topline.projectSearch;

    return (
        <ProjectSearch
            currentProjectId={id}
            className={cn(s.navLink, s.navProject)}
            textClassName={s.navLinkText}
            name={title}
            getUserProjects={getUserProjects}
            userProjects={userProjects}
            userProjectsRequest={userProjectsRequest}
            mode={mode}
            dataAttrName={toplineAttr}
            dataAttr={projectSearchAttr}
        />
    );
}

export const LinksPanel: React.FC = () => {
    const { pathname } = useLocation();
    const dispatch = useAppDispatch();

    const permissions = useSelector((state: IRootSlice) => state.projectsSlice.current.permissions);
    const cleanUrl = useSelector((state: IRootSlice) => state.projectsSlice.current.cleanedUrl);
    const projectTitle = useSelector((state: IRootSlice) => state.projectsSlice.current.title);
    const projectId = useSelector((state: IRootSlice) => state.projectsSlice.current.id);
    const projectUrl = useSelector((state: IRootSlice) => state.projectsSlice.current.url);
    const userId = useSelector((state: IRootSlice) => state.userSlice.id);
    const userProjects = useSelector((state: IRootSlice) => state.userSlice.userProjects);
    const userProjectsRequest = useSelector((state: IRootSlice) => state.userSlice.userProjectsRequest);

    const getUserProjects = (params: ISearchParams) => dispatch(userActions.getUserProjects(params));

    let mode = 'default';

    if (pathname.includes('projects') && !pathname.includes(String(projectId))) {
        mode = 'projects';
    }

    if (pathname.includes('settings')) {
        mode = 'settings';
    }

    if (pathname.includes('create')) {
        mode = 'create';
    }
    const showProjectInfo = projectTitle && (mode === 'default' || mode === 'settings');

    return (
        <div>
            {showProjectInfo ? (
                <div className={s.root}>
                    <SearchLink
                        disabled={userId === null}
                        id={projectId}
                        title={projectTitle}
                        getUserProjects={getUserProjects}
                        userProjects={userProjects}
                        userProjectsRequest={userProjectsRequest}
                        mode={mode}
                    />
                    <UrlLink url={projectUrl} text={cleanUrl} />
                    <CounterLink id={projectId} accessLevel={permissions.can_edit_project} />
                    <SettingsLink id={projectId} accessLevel={permissions.can_edit_project} />
                </div>
            ) : null}
        </div>
    );
};
