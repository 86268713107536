import { filtersCnf } from '@components/CommonFilters/configs/filters'; // FIXME path
import { IDynamicFilterItem } from '@components/CommonFilters/types/filters';
import dimensionsValues, { dimensionEmptyValue } from '@configs/dimensionsValues';
import { GroupBy } from '@configs/group';
import dateConst from '@constants/date';
import { EPeriodDetail } from '@constants/periodDetail';
import { createSlice } from '@reduxjs/toolkit';
import { IGlobalSlice } from '@typings/rootSlice';
import { formatDynamicData, formatFilters } from '@utils/filtersUtils';
import listDataUtils from '@utils/listDataUtils';
import {
    getServerTime,
    getRegionsConfig,
    getTypesConfig,
    getThemesConfig,
    getSavedFilters,
    saveFilter,
    deleteSavedFilter,
    updateSavedFilter,
    getDynamicFilters,
} from './api';

export const initialState: IGlobalSlice = {
    error: {},
    snackbar: { message: null, type: null },
    modalPopup: {
        modalPopupId: null,
        modalPopupParams: null,
        isShowPopup: false,
    },
    globalFilters: {},
    dynamicFiltersData: {},
    dynamicFiltersDataNew: {}, // FIXME
    savedFiltersRequest: false,
    savedFiltersData: [],
    saveFilterRequest: false,
    savedFilter: {},
    dateStart: dateConst.DEFAULT_DATE,
    dateEnd: dateConst.DEFAULT_DATE,
    groupBy: GroupBy.fiveMinute,
    periodDetail: EPeriodDetail.thirtyMinutes,
    globalLoading: true,
    serverTime: new Date().getTime(),
    timeOffset: 0,
    projectConfig: {
        regions: {
            loaded: false,
            loading: false,
            data: null,
        },
        types: {
            loaded: false,
            loading: false,
            data: null,
        },
        categories: {
            loaded: false,
            loading: false,
            data: null,
        },
    },
    notification: null,
    modalNotification: null,
    categoryId: null,
    isNavigationOpen: true,
};

export const setServerTimeAndOffset = (state: IGlobalSlice, action) => {
    const { result } = action.payload;

    const date = new Date().getTime();
    let timeOffset = date - result; // ms
    // Если разница меньше минуты - не учитываем
    timeOffset = Math.abs(timeOffset) > 60 * 1000 ? timeOffset : 0;

    state.serverTime = result;
    state.timeOffset = timeOffset;
};

/**
 * Запись полученного конфига для создания счётчика
 */
export const setProjectConfigData = (type: string, state: IGlobalSlice, action) => {
    state.projectConfig[type].data = listDataUtils.toFlatConfigData(action.payload.data);
    state.projectConfig[type].loading = false;
    state.projectConfig[type].loaded = true;
};

/**
 * Устанавливаем запрос конфига
 */
export const setProjectConfigRequest = (type: string, state: IGlobalSlice) => {
    state.projectConfig[type].loading = true;
    state.projectConfig[type].loaded = false;
};

/**
 * Включаем режим загрузки сохраненных фильтров
 */
export const setSavedFiltersRequest = (state: IGlobalSlice) => {
    state.savedFiltersRequest = true;
};

/**
 * Устанавливаем полученные сохраненные фильтры в список
 */
export const setSavedFilters = (state: IGlobalSlice, action) => {
    const { result } = action.payload;
    const { projectId } = action.payload.metaInfo;

    // Преобразовываем сохраненные фильтры под формат globalFilters
    // и отбрасываем испорченные фильтры (null)
    // prettier-ignore
    const savedFiltersData = result.length ? result.map((filter) => {
        const filters = [...(filter.body.dimension_filters || []),
            ...(filter.body.metric_filters || [])];
        const formatFilter = formatFilters(filters, true, projectId);

        if (!formatFilter) return null;

        return {
            ...formatFilter,
            id: filter.id,
            title: filter.title,
            type: filter.type,
        };
    }).filter(filter => filter) : [];

    state.savedFiltersRequest = false;
    state.savedFiltersData = savedFiltersData;
};

/**
 * Eсли получение сохраненных фильтров не удалось
 */
export const failureSavedFilters = (state: IGlobalSlice, action) => {
    const {
        payload: { code },
    } = action;

    state.savedFiltersRequest = false;
    state.errorCode = code;
};

/**
 *  Включаем режим процесса сохранения фильтра
 */
export const setSaveFilterRequest = (state: IGlobalSlice) => {
    state.saveFilterRequest = true;
};

/**
 * Сохраняем полученный фильтр
 */
export const setFilter = (state: IGlobalSlice, action) => {
    const { result } = action.payload;
    const { projectId } = action.payload.metaInfo;
    const filters = [...(result.body.dimension_filters || []), ...(result.body.metric_filters || [])];

    const savedFilter = {
        ...(formatFilters(filters, true, projectId) || {}),
        id: result.id,
        title: result.title,
        type: result.type,
    };

    state.savedFiltersRequest = false;
    state.savedFiltersData = [...state.savedFiltersData, savedFilter];
    state.savedFilter = savedFilter;
};

/**
 * Eсли сохранение фильтра не удалось
 */
export const failureSaveFilter = (state: IGlobalSlice, action) => {
    const {
        payload: { code },
    } = action;

    state.savedFiltersRequest = false;
    state.errorCode = code;
};

/**
 * Включаем режим запроса на выполнение удаления сохраненного фильтра
 */
export const setDeleteSavedFilterRequest = (state: IGlobalSlice) => {
    state.deleteFilterRequest = true;
};

/**
 * Устанавливаем успешное выполнение удаления фильтра
 */
export const deleteSavedFilterHandler = (state: IGlobalSlice, action) => {
    const data = state.savedFiltersData.filter((item) => Number(item.id) !== action.payload?.metaInfo.id);

    state.deleteFilterRequest = false;
    state.savedFiltersData = data;
};

/**
 * Eсли удаление фильтра не удалось
 */
export const failureDeleteFilter = (state: IGlobalSlice, action) => {
    const {
        payload: { code },
    } = action;

    state.deleteFilterRequest = false;
    state.errorCode = code;
};

/**
 * Включаем режим запроса на выполнение обновления сохраненного фильтра
 */
export const setUpdateSavedFilterRequest = (state: IGlobalSlice) => {
    state.updateFilterRequest = true;
};

/**
 * Обновление сохраненного фильтра
 */
export const updateSavedFilterHandler = (state: IGlobalSlice, action) => {
    const { result } = action.payload;
    const { projectId } = action.payload.metaInfo;
    const filters = [...(result.body.dimension_filters || []), ...(result.body.metric_filters || [])];

    const savedFilter = {
        ...(formatFilters(filters, true, projectId) || {}),
        id: result.id,
        title: result.title,
        type: result.type,
    };

    state.savedFiltersData = state.savedFiltersData.map((item) => {
        if (item.id === savedFilter.id) return savedFilter;
        return item;
    });

    state.updateFilterRequest = false;
    state.savedFilter = savedFilter;
    state.savedFilter.savedFilterOptions.isEdited = false;
};

/**
 * Eсли обновление фильтра не удалось
 */
export const failureUpdateFilter = (state: IGlobalSlice, action) => {
    const {
        payload: { code },
    } = action;

    state.updateFilterRequest = false;
    state.errorCode = code;
};

/**
 * Включаем режим загрузки данных для фильтрации
 */
export const setRequestFilters = (state: IGlobalSlice, action) => {
    const { filterName } = action.meta?.arg || {};

    if (!state.dynamicFiltersData[filterName]) {
        state.dynamicFiltersData[filterName] = {
            loaded: [],
            data: [],
            metrics: [],
        };
    }
    state.dynamicFiltersData[filterName].isLoading = true;

    state.dynamicFiltersDataNew[filterName] = {
        ...(state.dynamicFiltersDataNew[filterName] || {}),
        loading: true,
    } as IDynamicFilterItem;
};

/**
 * Устанавливаем полученные данные для динамических фильтров
 */
export const setDynamicFilters = (state: IGlobalSlice, action) => {
    const {
        params: { parents },
        filterName,
    } = action.meta?.arg || {};
    const { metaInfo } = action.payload || {};

    state.dynamicFiltersData = formatDynamicData(
        filterName,
        action.payload,
        parents,
        metaInfo,
        state.dynamicFiltersData,
    );

    // FIXME: new filters
    const dynamicFiltersDataNew = action.payload?.result?.data.map((item) => {
        let value;
        let label = item.id;

        if (filterName === 'events') {
            value = item.event_name;

            return {
                label: value,
                value,
                key: `${item.id}::${value}`,
            };
        }

        value = String(item.geo_country_id || item.geo_area_id || item.geo_city_id || item.category_1_id || item.id);

        // Конвертируем некоторые названия в кириллицу по словарю
        if (!item.geo_country_id && !item.geo_area_id && !item.geo_city_id && !item.category_1_id) {
            const dimension = Object.keys(filtersCnf).find((key) => filtersCnf[key].dynamicFilterAlias === filterName);
            label = dimensionsValues[dimension]?.[item.id]?.title || item.id || dimensionEmptyValue;
        }

        return {
            label,
            value,
            key: value,
        };
    });

    state.dynamicFiltersDataNew[filterName] = {
        ...(state.dynamicFiltersDataNew[filterName] || {}),
        data: [...(state.dynamicFiltersDataNew[filterName]?.data || []), ...dynamicFiltersDataNew],
        loading: false,
        totals: action?.payload?.meta?.total || 0,
    };
};

const globalSlice = createSlice({
    name: 'globalSlice',
    initialState,
    reducers: {
        updateParams(state, action) {
            Object.assign(state, action.payload);
        },
        setError(state, action) {
            state.error = action.payload;
        },
        clearError(state) {
            state.error = {};
        },
        setSnackbar(state, action) {
            state.snackbar = action.payload;
        },
        clearSnackbar(state) {
            state.snackbar.message = null;
        },
        setNotification(state, action) {
            state.notification = action.payload;
        },
        clearNotification(state) {
            state.notification = null;
        },
        setModalNotification(state, action) {
            state.modalNotification = action.payload;
        },
        clearModalNotification(state) {
            state.modalNotification = null;
        },
        openModalPopup(state, action) {
            state.modalPopup = {
                modalPopupId: action.payload.modalPopupId,
                modalPopupParams: action.payload.modalPopupParams || {},
                isShowPopup: true,
            };
        },
        clearModalPopup(state) {
            state.modalPopup = {
                modalPopupId: null,
                modalPopupParams: null,
                isShowPopup: false,
            };
        },
        setGlobalLoading(state, action) {
            state.globalLoading = action.payload;
        },
    },
    extraReducers: (builder) => {
        builder
            .addCase(getServerTime.fulfilled, (state, action) => setServerTimeAndOffset(state, action))

            .addCase(getRegionsConfig.pending, (state) => setProjectConfigRequest('regions', state))
            .addCase(getRegionsConfig.fulfilled, (state, action) => setProjectConfigData('regions', state, action))

            .addCase(getTypesConfig.pending, (state) => setProjectConfigRequest('types', state))
            .addCase(getTypesConfig.fulfilled, (state, action) => setProjectConfigData('types', state, action))

            .addCase(getThemesConfig.pending, (state) => setProjectConfigRequest('categories', state))
            .addCase(getThemesConfig.fulfilled, (state, action) => setProjectConfigData('categories', state, action))

            .addCase(getSavedFilters.pending, (state) => setSavedFiltersRequest(state))
            .addCase(getSavedFilters.fulfilled, (state, action) => setSavedFilters(state, action))
            .addCase(getSavedFilters.rejected, (state, action) => failureSavedFilters(state, action))

            .addCase(saveFilter.pending, (state) => setSaveFilterRequest(state))
            .addCase(saveFilter.fulfilled, (state, action) => setFilter(state, action))
            .addCase(saveFilter.rejected, (state, action) => failureSaveFilter(state, action))

            .addCase(deleteSavedFilter.pending, (state) => setDeleteSavedFilterRequest(state))
            .addCase(deleteSavedFilter.fulfilled, (state, action) => deleteSavedFilterHandler(state, action))
            .addCase(deleteSavedFilter.rejected, (state, action) => failureDeleteFilter(state, action))

            .addCase(updateSavedFilter.pending, (state) => setUpdateSavedFilterRequest(state))
            .addCase(updateSavedFilter.fulfilled, (state, action) => updateSavedFilterHandler(state, action))
            .addCase(updateSavedFilter.rejected, (state, action) => failureUpdateFilter(state, action))

            .addCase(getDynamicFilters.pending, (state, action) => setRequestFilters(state, action))
            .addCase(getDynamicFilters.fulfilled, (state, action) => setDynamicFilters(state, action));
    },
});

export const {
    updateParams,
    setError,
    clearError,
    setSnackbar,
    clearSnackbar,
    setNotification,
    clearNotification,
    setModalNotification,
    clearModalNotification,
    openModalPopup,
    clearModalPopup,
    setGlobalLoading,
} = globalSlice.actions;

export const globalActions = {
    getServerTime,
    getRegionsConfig,
    getTypesConfig,
    getThemesConfig,
    getSavedFilters,
    saveFilter,
    deleteSavedFilter,
    updateSavedFilter,
    getDynamicFilters,
    updateParams,
    setError,
    clearError,
    setSnackbar,
    clearSnackbar,
    setNotification,
    clearNotification,
    setModalNotification,
    clearModalNotification,
    openModalPopup,
    clearModalPopup,
    setGlobalLoading,
};

export default globalSlice.reducer;
