import { ITableConstants } from '@typings/table';

const tableConstants: ITableConstants = {
    ROOT_NODE: 'DATA_ROOT_NODE',
    MAX_SELECTED_ROWS: 10,
    DEFAULT_LINES_COUNT: 5,
    PARENTS_DELIMITER: '-:-',
    PARAMS_DELIMITER: '➝',
    COUNTER_ROWS_REPORT_SUMMARY: 90,
    METRICS_MAX_LIMIT: 8,
    METRICS_BIG_LIMIT: 7,
    METRICS_LIMIT: 5,
    TABLE_ANIMATION_PARAMS: {
        stiffness: 140,
        damping: 18,
        precision: 10,
    },
    TABLE_ROW_HEIGHT: 65,
};

export default tableConstants;
