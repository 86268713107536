import React, { ReactNode } from 'react';

import s from '../ModalPopup.pcss';

interface IProps {
    title: string;
    image?: ReactNode;
}

export const PopupHeader: React.FC<IProps> = ({ title, image }) => (
    <div className={s.header}>
        {image && <div className={s.headerImage}>{image}</div>}
        <div className={s.headerTitle}>{title}</div>
    </div>
);
