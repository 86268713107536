import tableConstants from '@constants/table';
import mediaUtils from '@redux/slices/media/utils';
import {
    getReportTable,
    getMediaTable,
    getConstructorTable,
    updateTableHeadOffset,
    updateTableOffset,
    updateMediaTableOffset,
    updateTableExpand,
} from '@redux/slices/table/api';
import { createSlice } from '@reduxjs/toolkit';
import { ITableSlice } from '@typings/rootSlice';
import eventBasedUtils from './eventBasedUtils';
import tableUtils from './utils';

export const initialState: ITableSlice = {
    tableRequest: false,
    tableData: {
        dict: {},
        map: {},
    },
    totals: [],
    metricsWithStates: [],
    orderBy: null,
    titleFilter: '',
    tableFilters: [],
    totalRows: 0,
    offset: null,
    limit: null,
    counterTableRowsReport: tableConstants.COUNTER_ROWS_REPORT_SUMMARY,
    errorCode: null,
    allMetrics: [],
    metrics: [],
    dimensions: [],
    allDimensions: [],
    sample: null,
    currentPage: 1,
    trafficLabels: {},
    lastRequestId: 0,
};

/**
 * Включаем режим загрузки данных у таблицы отчёта
 */
export const setRequestTableFunc = (state: ITableSlice, action?) => {
    state.tableRequest = !action?.meta?.arg.isAutoUpdate;
    state.errorCode = null;
};

/**
 * Устанавливаем полученные данные в таблицу отчётов
 * @returns {*}
 */
export const setTable = (state: ITableSlice, action) => {
    const { sort, reportName, body, lastRequestId } = action.payload?.metaInfo || {};

    // Отбрасываем результаты старых запросов
    if (lastRequestId < state.lastRequestId) return state;

    const { data, metrics, allMetrics, sample, total, totals, offsetHead } = tableUtils.prepareTableData(
        action.payload,
        { ...body, reportName },
    );

    const oldMetrics = state.metricsWithStates;
    const metricsWithStates = tableUtils.prepareMetricsWithState(metrics, oldMetrics);
    const sortName = tableUtils.getSortName(state.orderBy?.key, sort.name);
    const errorCode = null;

    const newMetrics = metrics.length > 0 ? metrics : state.metrics;
    const newMetricsWithStates = metricsWithStates.length > 0 ? metricsWithStates : oldMetrics;

    Object.assign(state, {
        orderBy: { key: sortName, value: sort.order, op: ':' },
        tableRequest: false,
        tableData: {
            dict: data.dict,
            map: {
                [tableConstants.ROOT_NODE]: {
                    list: data.list,
                    total,
                },
            },
            ...(offsetHead ? { offsetHead } : {}),
        },
        sample,
        metrics: newMetrics,
        metricsWithStates: newMetricsWithStates,
        allMetrics,
        totals,
        totalRows: total,
        errorCode,
    });
};

/**
 * Если запрос данных для таблицы выполнился с ошибкой
 */
export const setTableRequestFailure = (state: ITableSlice, action) => {
    const {
        payload: { status },
    } = action;

    state.tableRequest = false;
    state.errorCode = status;
};

/**
 * Обнуляем данные в таблице отчётов
 */
export const resetTableFunc = (state: ITableSlice) => {
    state.tableData = { dict: {}, map: {} };
    state.totals = [];
    state.errorCode = null;
};

/**
 * Обновление данных в таблице при раскрытии узлов
 * @returns {{} & State & {tableData: any[], tableRequest: boolean}}
 */
export const updateTableExpandFunc = (state: ITableSlice, action) => {
    const { parents } = action.payload?.metaInfo || {};

    const { data, total } = tableUtils.prepareTableData(action.payload, action.payload?.metaInfo);

    const currId = parents ? parents.join(tableConstants.PARENTS_DELIMITER) : tableConstants.ROOT_NODE;

    Object.assign(state.tableData.dict, data.dict);
    state.tableData.map[currId] = { list: data.list, total };
    state.tableRequest = false;
};

/**
 * Обновление данных в верхней части таблицы, после 1-ого элемента.
 * @returns {{} & State & {tableData: any[], tableRequest: boolean}}
 */
export const updateTableHeadOffsetFunc = (state: ITableSlice, action) => {
    const { map } = state.tableData;
    const { data, offsetHead } = tableUtils.prepareTableData(action.payload, action.payload?.metaInfo);
    const currId = tableConstants.ROOT_NODE;
    const mapItem = map[currId];

    mapItem.list = [mapItem.list[0], ...data.list, ...mapItem.list.slice(1)];

    Object.assign(state.tableData.dict, data.dict);
    state.tableData.map[currId] = mapItem;
    state.tableData.offsetHead = offsetHead;
    state.tableRequest = false;
};

/**
 * Обновление данных в таблице
 * @returns {{} & State & {tableData: any[], tableRequest: boolean}}
 */
export const updateTableOffsetFunc = (state: ITableSlice, action) => {
    const { map, offsetHead } = state.tableData;
    const { parents } = action.payload?.metaInfo || {};

    const { data } = tableUtils.prepareTableData(action.payload, action.payload?.metaInfo, state);

    const currId =
        parents && parents.length > 0 ? parents.join(tableConstants.PARENTS_DELIMITER) : tableConstants.ROOT_NODE;
    const mapItem = map[currId];

    mapItem.list = mapItem.list.concat(data.list);

    Object.assign(state.tableData.dict, data.dict);
    state.tableData.map[currId] = mapItem;
    state.tableRequest = false;

    if (offsetHead) state.tableData.offsetHead = offsetHead;
};

/**
 * Конструктор
 */
export const setTableConstructor = (state: ITableSlice, action) => {
    const { sort, expandedNode, lastRequestId } = action.payload?.metaInfo || {};

    // Отбрасываем результаты старых запросов
    if (lastRequestId < state.lastRequestId) return state;

    const { result, meta } = action.payload;
    const { metrics } = result;

    const { list, dict } = eventBasedUtils.prepareTableConstructorData(
        result,
        state.dimensions,
        expandedNode,
        state.tableData,
    );

    const oldMetrics = state.metricsWithStates;
    const metricsWithStates = tableUtils.prepareMetricsWithState(metrics, oldMetrics);
    // В случае с title, который используется для сортировки дат
    // и которого нет в metricsDict сортировка не изменится
    const sortName = tableUtils.getSortName(state.orderBy?.key, sort.name);
    const sortOrder = sortName === sort.name ? sort.order : state.orderBy?.value;

    const newMetrics = metrics.length ? metrics : state.metrics;
    const newMetricsWithStates = metricsWithStates.length ? metricsWithStates : oldMetrics;

    if (expandedNode) {
        Object.assign(state.tableData.dict, dict);
        const newList = state.tableData.map[expandedNode]?.list?.concat(list) || list;
        state.tableData.map[expandedNode] = { list: newList, total: meta?.totals || 0 };
    } else {
        Object.assign(state, {
            orderBy: { key: sortName, value: sortOrder, op: ':' },
            tableData: {
                dict,
                map: {
                    [tableConstants.ROOT_NODE]: { list },
                },
            },
            sample: meta.sample,
            metrics: newMetrics,
            metricsWithStates: newMetricsWithStates,
            allMetrics: result.available_metrics,
            allDimensions: result.available_dimensions,
            totals: result.totals,
            errorCode: null,
        });
    }

    state.tableRequest = false;
};

/**
 * Медиа таблицы
 */
export const setRequestMediaTopTable = (state: ITableSlice) => {
    const { currentPage } = state;
    const { page } = state.tableData;

    // Оставляем прелоадер для переключения постранички
    state.tableRequest = !page || page !== currentPage;
    state.errorCode = null;
};

export const updateMediaTableFunc = (state: ITableSlice, action) => {
    const { reportName, projectId, result, meta } = action.payload;
    const { currentPage } = initialState;

    const { metrics } = result;
    const oldMetrics = state.metricsWithStates;
    const metricsWithStates = tableUtils.prepareMetricsWithState(metrics, oldMetrics);

    const newMetrics = metrics.length > 0 ? metrics : state.metrics;
    const newMetricsWithStates = metricsWithStates.length > 0 ? metricsWithStates : oldMetrics;

    const sortName = tableUtils.getSortName(state.orderBy?.key, result.sort.name);

    const { list, dict } = mediaUtils().prepareTableData(result.data, metrics, result.totals, projectId, reportName);

    Object.assign(state, {
        tableRequest: false,
        allMetrics: result.available_metrics,
        metrics: newMetrics,
        metricsWithStates: newMetricsWithStates,
        tableData: {
            dict,
            map: {
                [tableConstants.ROOT_NODE]: {
                    list,
                    total: meta.total,
                },
            },
            page: currentPage,
            animate: false,
        },
        totalRows: meta.total,
        trafficLabels: result.labels,
        orderBy: { key: sortName, value: result.sort.order, op: ':' },
        errorCode: null,
        currentPage,
    });
};

export const updateMediaTableOffsetFunc = (state: ITableSlice, action) => {
    const { map } = state.tableData;
    const { result } = action.payload;
    const { parents, projectId, reportName } = action.payload?.metaInfo || {};

    const { list: newList, dict: newDict } = mediaUtils().prepareTableData(
        result.data,
        result.metrics,
        result.totals,
        projectId,
        reportName,
    );

    const currId =
        parents && parents.length > 0 ? parents.join(tableConstants.PARENTS_DELIMITER) : tableConstants.ROOT_NODE;
    const mapItem = map[currId];

    mapItem.list = mapItem.list.concat(newList);

    Object.assign(state.tableData.dict, newDict);
    Object.assign(state.trafficLabels, result.labels);
    state.tableData.map[currId] = mapItem;
    state.tableRequest = false;
    state.errorCode = null;
};

/**
 * Обновление данных в таблице Топ материалов
 */
export const updateMediaTopTable = (state: ITableSlice, action) => {
    const { map, dict, page } = state.tableData;
    const { currentPage } = state;
    const { result, meta } = action.payload;
    const { projectId, reportName } = action.payload?.metaInfo || {};
    const isSamePage = page && currentPage === page;
    const currId = tableConstants.ROOT_NODE;
    const mapItem = map[currId];

    const { list: newList, dict: newDict } = mediaUtils().prepareTableData(
        result.data,
        result.metrics,
        result.totals,
        projectId,
        reportName,
    );

    newList.forEach((item, index) => {
        const oldItem = dict[item];
        let diff = 0;

        if (oldItem) {
            const oldIndex = map[currId].list.indexOf(item);

            diff = oldIndex - index;
        } else if (isSamePage) {
            // Все новые элементы считаем относительно текущего оффсета
            // и только если мы на той же страничке
            diff = newList.length - index;
        }

        if (Math.abs(diff) > 1) newDict[item].diff = diff;
    });

    mapItem.list = newList;

    Object.assign(state, {
        tableData: {
            dict: { ...newDict },
            map: { ...map, ...{ [currId]: mapItem } },
            page: currentPage, // сохраняем страничку, для которой запрашивали данные
            animate: !!isSamePage,
        },
        totalRows: meta.total,
        tableRequest: false,
        errorCode: null,
    });
};

const mediaTableRequestReducerHandler = (state: ITableSlice, action) => {
    if (action.meta?.arg.isTopTable) {
        setRequestMediaTopTable(state);
    } else {
        setRequestTableFunc(state, action);
    }
};

const mediaTableReceiveReducerHandler = (state: ITableSlice, action) => {
    if (action.payload?.metaInfo?.isTopTable) {
        updateMediaTopTable(state, action);
    } else {
        updateMediaTableOffsetFunc(state, action);
    }
};

// TODO: упростить под immerjs
export const expandTableNodes = (state: ITableSlice, action) => {
    const { dict, map } = state.tableData;
    const { id, options } = action.payload;

    let newTableData;
    const item = dict[id];

    // Если не нашли ноду в словаре при открытии ранее выбранных нод
    // после перерисовки таблицы, не открываем вложенность
    if (!item && options?.needToOpenNode) {
        newTableData = { ...state.tableData };
    } else {
        const list = [];

        // Динамически добавляем информацию в tableData -> map о вложенности узлов
        Object.keys(dict).forEach((key) => {
            if (dict[key].parentId !== id) return;

            list.push(key);
        });

        newTableData = {
            dict: {
                ...dict,
                [id]: { ...item, expand: !item?.expand },
            },
            map: {
                ...map,
                [id]: { ...map[id], list },
            },
        };
    }

    state.tableData = newTableData;
};

export const resetRequestTableFunc = (state: ITableSlice) => {
    state.tableRequest = false;
};

export const addTableRowsSummaryFunc = (state: ITableSlice, action) => {
    state.counterTableRowsReport += action.payload;
};

export const removeTableRowsSummaryFunc = (state: ITableSlice) => {
    state.counterTableRowsReport = tableConstants.COUNTER_ROWS_REPORT_SUMMARY;
};

export const toggleMetric = (state: ITableSlice, action) => {
    const { metricsWithStates, metric } = action.payload;
    state.metricsWithStates = metricsWithStates.map((item: { name: string; state: string }) => {
        let result = item;

        if (item.name === metric) {
            result = {
                name: item.name,
                state: item.state === 'normal' ? 'percent' : 'normal',
            };
        }

        return result;
    });
};

export const updateParamsFunc = (state: ITableSlice, action) => {
    const { payload } = action;
    const newState = { ...state };
    const newParams = { ...payload };

    if (newParams.metrics) {
        // определяем metricsWithStates
        const oldMetrics = newState.metricsWithStates;
        newState.metricsWithStates = tableUtils.prepareMetricsWithState(newParams.metrics, oldMetrics);
    }

    return {
        ...newState,
        ...newParams,
    };
};

export const resetParamFunc = (state: ITableSlice, action) => {
    const param = action.payload;

    if (!(param in initialState)) return state;

    state[param] = initialState[param];
};

export const updateSampleFunc = (state: ITableSlice, action) => {
    state.sample = action.payload;
};

const tableRequestReducerHandler = (state: ITableSlice, action) => {
    if (action.meta?.arg.isNotClearTable) {
        setRequestTableFunc(state, action);
    } else {
        setRequestTableFunc(state, action);
        resetTableFunc(state);
    }
};

const tableSlice = createSlice({
    name: 'tableSlice',
    initialState,
    reducers: {
        allReset() {
            return initialState;
        },
        setRequestTable: (state) => setRequestTableFunc(state),
        resetTable: (state) => resetTableFunc(state),
        updateMediaTable: (state, action) => updateMediaTableFunc(state, action),
        toggleTableNode: (state, action) => expandTableNodes(state, action),
        resetRequestTable: (state) => resetRequestTableFunc(state),
        addTableRowsSummary: (state, action) => addTableRowsSummaryFunc(state, action),
        removeTableRowsSummary: (state) => removeTableRowsSummaryFunc(state),
        toggleMetricWithState: (state, action) => toggleMetric(state, action),
        updateParams: (state, action) => updateParamsFunc(state, action),
        resetParam: (state, action) => resetParamFunc(state, action),
        updateSample: (state, action) => updateSampleFunc(state, action),
    },
    extraReducers: (builder) => {
        builder
            .addCase(getReportTable.pending, (state, action) => tableRequestReducerHandler(state, action))
            .addCase(getReportTable.fulfilled, (state, action) => setTable(state, action))
            .addCase(getReportTable.rejected, (state, action) => setTableRequestFailure(state, action))

            .addCase(getMediaTable.pending, (state, action) => tableRequestReducerHandler(state, action))
            .addCase(getMediaTable.rejected, (state, action) => setTableRequestFailure(state, action))

            .addCase(getConstructorTable.pending, (state, action) => setRequestTableFunc(state, action))
            .addCase(getConstructorTable.fulfilled, (state, action) => setTableConstructor(state, action))

            .addCase(updateTableHeadOffset.pending, (state, action) => setRequestTableFunc(state, action))
            .addCase(updateTableHeadOffset.fulfilled, (state, action) => updateTableHeadOffsetFunc(state, action))

            .addCase(updateTableOffset.pending, (state, action) => setRequestTableFunc(state, action))
            .addCase(updateTableOffset.fulfilled, (state, action) => updateTableOffsetFunc(state, action))

            .addCase(updateMediaTableOffset.pending, (state, action) => mediaTableRequestReducerHandler(state, action))
            .addCase(updateMediaTableOffset.fulfilled, (state, action) =>
                mediaTableReceiveReducerHandler(state, action),
            )

            .addCase(updateTableExpand.pending, (state, action) => setRequestTableFunc(state, action))
            .addCase(updateTableExpand.fulfilled, (state, action) => updateTableExpandFunc(state, action));
    },
});

export const {
    allReset,
    setRequestTable,
    resetTable,
    updateMediaTable,
    toggleTableNode,
    resetRequestTable,
    addTableRowsSummary,
    removeTableRowsSummary,
    toggleMetricWithState,
    updateParams,
    resetParam,
    updateSample,
} = tableSlice.actions;

export const tableActions = {
    allReset,
    setRequestTable,
    resetTable,
    updateMediaTable,
    toggleTableNode,
    resetRequestTable,
    addTableRowsSummary,
    removeTableRowsSummary,
    toggleMetricWithState,
    updateParams,
    resetParam,
    updateSample,
    getReportTable,
    getMediaTable,
    getConstructorTable,
    updateTableHeadOffset,
    updateTableOffset,
    updateMediaTableOffset,
    updateTableExpand,
};

export default tableSlice.reducer;
