import IconShedule from '@images/svg/icons/icon-schedule.svg';
import classNames from 'classnames';
import * as React from 'react';

import s from './ReportMessage.pcss';

interface IProps {
    // todo - если в будущем потребуются различные виды вывода сообщений
    // type: 'info',
    message: string;
    className?: string;
}

export const ReportMessage: React.FC<IProps> = (props) => {
    const { className = '', message } = props;

    const rootClassName = classNames(s.root, className);
    const contentClassName = classNames(s.content);
    const wrapperClassName = classNames(s.wrapper);
    const iconWrapClassName = classNames(s.iconWrap);
    const iconSvgClassName = classNames(s.iconSvg);
    const messageClassName = classNames(s.message);

    return (
        <div className={rootClassName}>
            <div className={contentClassName}>
                <div className={wrapperClassName}>
                    <div className={iconWrapClassName}>
                        <IconShedule className={iconSvgClassName} />
                    </div>
                    <span className={messageClassName}>{message}</span>
                </div>
            </div>
        </div>
    );
};

export default ReportMessage;
