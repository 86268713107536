import { FormItemType, IFieldParams, ValidationReturnType } from '@typings/form';
import isArray from 'lodash/isArray';
import isObject from 'lodash/isObject';
import isString from 'lodash/isString';

class FormUtils {
    public static defaultRules = {
        required: (value: string): ValidationReturnType =>
            value && !/^[\s]+$/.test(value) ? true : 'Поле не заполнено',

        // prettier-ignore
        arrayRequired: (value: any[]): ValidationReturnType => (
            value && (value.length > 0)
                ? true
                : 'Обязательное поле'
        ),

        // prettier-ignore
        maxLength: (max: number) => (value: any[] | string): ValidationReturnType => (
            value && value.length > max
                ? `Максимально ${max} символа(ов)`
                : true
        ),

        // prettier-ignore
        minLength: (min: number) => (value: string): ValidationReturnType => (
            value && value.trim().length < min
                ? `Минимально ${min} символа(ов)`
                : true
        ),

        // prettier-ignore
        number: (value: string): ValidationReturnType => (value && Number.isNaN(Number(value))
            ? 'Введите число'
            : true),

        // prettier-ignore
        integer: (value: string): ValidationReturnType => (value && !((/^\d*$/).test(value))
            ? 'Введите целое число'
            : true),

        // prettier-ignore
        minValue: (min: number) => (value: number): ValidationReturnType => (
            value && value < min
                ? `Минимально ${min} символа(ов)`
                : true
        ),

        // prettier-ignore
        email: (value: string): ValidationReturnType => (
            value && !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(value)
                ? 'Некорректный формат эл. почты'
                : true
        ),
        // prettier-ignore
        url: (value: string): ValidationReturnType => (
            value && !/^((http|https):\/\/)?([a-zа-яё0-9]+([\-\.]{1}[a-zа-яё0-9]+)*\.[a-zа-яё]{2,5}|localhost|((25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)(\.|)){4})(:[0-9]{1,5})?(\/.*)?$/.test(value) // eslint-disable-line
                ? 'Введен неправильный URL'
                : true),
        // prettier-ignore
        urlWithDomainZone: (value: string): ValidationReturnType => (
            value && !/^((http|https):\/\/)?(([a-zа-яё0-9]{1,}[a-zа-яё0-9\-]*[a-zа-яё0-9]*\.)+(xn--[a-z0-9]+|[a-zа-яё]{2,}))(:[0-9]{1,5})?(\/.*)?$/.test(value) // eslint-disable-line
                ? 'Введен неправильный URL'
                : true
        ),
        // prettier-ignore
        urlWithProtocolAndParams: (value: string): ValidationReturnType => (
            value && !/^((http|https):\/\/)(([a-zа-яё0-9\-]{1,}[a-zа-яё0-9]?\.)+(xn--[a-z0-9]+|[a-zа-яё]{2,63}|localhost|((25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)(\.|)){4}))(:[0-9]{1,5})?(\/.*)?$/.test(value) // eslint-disable-line
                ? 'Не указан протокол http или https'
                : true
        ),

        isTrue: (value: boolean): ValidationReturnType => (value !== true ? 'Значение не равно true' : true),
    };

    /**
     * Получаем чистый объект со значениями формы (без мета данных)
     */
    public static getPureValues = (data: Record<string, IFieldParams>, takeHidden: boolean = true) => {
        if (!data) return data;

        let values;

        const recursion = (item: FormItemType) => {
            let value;

            if (isObject(item.value) && !isArray(item.value)) {
                value = {};

                const keys = Object.keys(item.value);
                keys.forEach((subKey) => {
                    const data2 = item.value[subKey];
                    if (!isString(data2.value)) {
                        value[subKey] = recursion(data2.value);
                    } else {
                        value[subKey] = data2.value;
                    }
                });
            } else if (isArray(item.value)) {
                value = item.value.map((subItem) => recursion(subItem));
            } else if (isString(item.value)) {
                value = item.value;
            } else if (isArray(item)) {
                const findInnerField = isArray(item) ? item.filter((itm) => itm.value !== undefined).length > 0 : false;
                // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                // @ts-ignore
                value = findInnerField ? FormUtils.getPureValues(item) : item;
            } else {
                // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                // @ts-ignore
                value = item.value ? item.value : item;
            }

            return value;
        };

        if (isObject(data) && !isArray(data)) {
            values = {};
            Object.keys(data).forEach((key) => {
                if (!data[key].hidden || !takeHidden) {
                    if (data[key].mod === 'array' || data[key].mod === 'object') {
                        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                        // @ts-ignore
                        values[key] = recursion(data[key]);
                    } else {
                        values[key] = data[key].value;
                    }
                }
            });
        } else if (isArray(data)) {
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            values = data.map((item) => recursion(item));
        } else {
            values = data;
        }

        return values;
    };

    public static decomposeServerValidationErrors = (errors: Record<string, string>) => {
        const keys = Object.keys(errors);
        const result = {};

        keys.forEach((key) => {
            const curError = errors[key];
            result[key] = isArray(curError) && curError.length > 0 ? curError[0] : curError;
        });

        return result;
    };

    static getInvalidFields = (fields: Record<string, any>) => {
        const invalidFields = {};

        Object.keys(fields).forEach((key) => {
            const field = fields[key];

            if (field.invalid && !field.hidden) {
                invalidFields[key] = field;
            }
        });

        return invalidFields;
    };
}

export default FormUtils;
