import { getEmptyType } from '@redux/slices/forms';
import { IMetaField } from '@typings/form';
import { domUtils, formUtils } from '@utils/index';
import isArray from 'lodash/isArray';
import isEqual from 'lodash/isEqual';
import isObject from 'lodash/isObject';
import React from 'react';

import { FieldDecoratorContext } from './context';
import { IProps, IState } from './types';

class FieldDecorator extends React.Component<IProps, IState> {
    static defaultProps = {
        index: -1,
        rules: [],
        asyncRules: [],
        mod: 'default',
        type: 'string',
        initialValue: '',
        hidden: false,
        onlyHidden: false,
        ignoreHidden: false,
    };

    state = {
        value: '',
        isChanged: false,
    };

    componentDidMount() {
        const { onlyHidden, initialValue, type } = this.props;

        // проверяем, было ли создано поле раньше, если да и поле !onlyHidden,
        // делаем флаг hidden=false
        // то есть учитываем все валидации и значения в общем форме
        const data = this.getFieldData();
        const currentFieldName = this.getCurrentFieldName();

        // если есть дата, устанавливаем value в стейт
        this.setState({
            value: data ? data.value : '',
        });

        const emptyValue = getEmptyType(type);

        if (data && !onlyHidden && !currentFieldName.length) {
            this.changeField('SHOW', { hidden: false });
        } else if (!data) {
            // или создаем новое поле
            this.wrapperFieldAction(this.props.createField);
        } else if (data && initialValue && data.value === emptyValue) {
            // или устанавливаем начальное значение
            this.changeField('SHOW', { value: initialValue });
        }
    }

    componentDidUpdate(prevProps: IProps, prevState) {
        const prevFieldData = this.getFieldData(prevProps);
        const fieldData = this.getFieldData(this.props);
        const { shouldReValidateReceive, name, createField } = this.props;

        // Не обновляем, если в стейте не изменилось значение
        if (isEqual(this.state.value, prevState.value) && name === prevProps.name) return;

        if (fieldData && prevFieldData) {
            const { value } = fieldData;
            const sanitizeValue = formUtils.getPureValues(value);

            // меняем значения и прогоняем валидацию, если извне изменилось значение
            if (!isEqual(prevFieldData.value, fieldData.value) && !isEqual(this.state.value, sanitizeValue)) {
                const meta = this.getMetaData(sanitizeValue);
                this.changeField('CHANGE', { value: sanitizeValue, ...meta });
                this.setState({ value: sanitizeValue });
            }

            // меняем значения и прогоняем валидацию, если нужно провести повторную валидацию
            if (shouldReValidateReceive) {
                const isReValidate = shouldReValidateReceive(prevProps, this.props);

                if (isReValidate) {
                    const meta = this.getMetaData(sanitizeValue, true, false, this.props);

                    this.changeField('CHANGE', { ...meta });
                }
            }
        }

        // если динамически изменилось название и нет данных
        if (prevProps.name !== name && !fieldData) {
            // создаем новое поле
            this.wrapperFieldAction(createField, false, this.props);
        }
    }

    componentWillUnmount() {
        const {
            formContext: { checkUnmounting },
        } = this.props;
        const { ignoreHidden, onlyHidden, index } = this.props;

        if (!ignoreHidden && !onlyHidden && !checkUnmounting() && index === -1) {
            this.changeField('HIDE', { hidden: true });
        }
    }

    getContextData = () => {
        const {
            fieldContext: { currentFieldName },
        } = this.props;
        const { name, mod } = this.props;

        if (mod === 'default') {
            return { currentFieldName: [] };
        }

        const resCurrentFieldName = currentFieldName ? [...currentFieldName] : [];
        resCurrentFieldName.push(name);

        return { currentFieldName: resCurrentFieldName };
    };

    /**
     * Сбрасываем поле
     */
    reset = () => this.wrapperFieldAction(this.props.resetField, true);

    /**
     * Изменяем поле
     */
    changeField = (
        prefix: string,
        fieldData: Record<string, any>,
        customFieldName: (string | number)[] | boolean = false,
        customName: string = '',
        isPush: boolean = false,
    ) => {
        const {
            formContext: { currentFormName },
        } = this.props;
        const { changeField, name, mod } = this.props;

        const cb = () =>
            changeField(
                prefix,
                currentFormName,
                customFieldName || this.getCurrentFieldName(),
                customName || name,
                mod,
                fieldData,
                isPush,
            );

        if (fieldData.value !== undefined) {
            this.setState(
                {
                    value: fieldData.value,
                    isChanged: true,
                },
                cb,
            );
            return Promise.resolve();
        }

        return cb();
    };

    /**
     * Добавляем новую строку в поле
     */
    addFieldValue = (value: any, fieldName: string = '') => {
        const {
            formContext: { currentFormName },
        } = this.props;
        const { forms, name } = this.props;

        const curName = fieldName || name;
        const curFieldName = fieldName ? [] : this.getCurrentFieldName();
        // todo: в будущем проверить,
        // todo: почему проверка по fieldName,
        // todo: так как может быть кейс, когда fieldName === name,
        // todo: и теоретически могут быть разные fieldData.
        const fieldData = fieldName ? this.getWrapperData(value) : { value, ...this.getMetaData(value) };

        const currentForm = forms[currentFormName];
        const currentField = !fieldName ? this.getFieldData() : currentForm.fields[curName];

        if (currentField.mod !== 'array') {
            return Promise.resolve();
        }

        return this.changeField('ADD_FIELD_VALUE', fieldData, curFieldName, curName, true);
    };

    /**
     * Удаляем строку из поля
     */
    removeFieldValue = (index: number, fieldName: string = '') => {
        const {
            formContext: { currentFormName },
        } = this.props;
        const { forms, name, removeFieldValue, mod } = this.props;

        const currentForm = forms[currentFormName];
        const curFieldName = fieldName ? [] : this.getCurrentFieldName();
        const curName = fieldName || name;
        const currentField = !fieldName ? this.getFieldData() : currentForm.fields[curName];

        if (currentField.mod !== 'array') {
            return false;
        }

        return removeFieldValue(currentFormName, curFieldName, curName, mod, index);
    };

    /**
     * Обертка для запуска экшена
     */
    wrapperFieldAction = (action, reset: boolean = false, props: IProps = this.props) => {
        const {
            formContext: { currentFormName },
        } = props;
        const { name, label, initialValue, type, mod, hidden, forms, onlyHidden } = props;

        const currentForm = forms[currentFormName];

        if (!currentForm) {
            return;
        }

        let value: any = '';

        if (!reset) {
            const { initialValues } = currentForm;

            value = initialValue || (initialValues ? initialValues[name] : '') || '';

            if (mod === 'array' && !value) {
                value = [value];
            }
        } else {
            const fieldData = this.getFieldData();
            if (fieldData) {
                const data = this.resetValuesData(fieldData);
                if (data.value) {
                    value = data.value;
                }
            }
        }

        const meta = this.getMetaData(value);

        this.setState({ value }, () => {
            action(currentFormName, this.getCurrentFieldName(), name, mod, {
                type,
                hidden: onlyHidden || hidden,
                value,
                label,
                meta,
            });
        });
    };

    /**
     * При сбрасывание, если есть вложенные поля, сохраняем структуру, но с пустыми значениями
     * @param data
     * @returns {*}
     */
    resetValuesData = (data: Record<string, any>) => {
        const value = data.value;

        if (!value) {
            return '';
        }

        if (isObject(value) && !isArray(value)) {
            const copyValue = { ...value };

            Object.keys(value).forEach((key) => {
                copyValue[key] = this.resetValuesData(copyValue[key]);
            });

            return copyValue;
        }

        if (isArray(value)) {
            return {
                value: value.map((item) => this.resetValuesData(item)),
            };
        }

        return '';
    };

    /**
     * Получение значений формы
     * @returns {*}
     */
    getValues = () => {
        const {
            formContext: { currentFormName },
        } = this.props;
        const { forms } = this.props;

        const currentForm = forms[currentFormName];

        if (!currentForm) {
            return false;
        }

        return formUtils.getPureValues(currentForm.fields);
    };

    /**
     * Получаем чистое поле value
     */
    getValue = () => {
        const data = this.getFieldData();

        if (!data) {
            return null;
        }

        return formUtils.getPureValues(data.value, false);
    };

    /**
     * Получаем данные поля
     */
    getFieldData = (props: IProps = this.props) => {
        const {
            formContext: { currentFormName },
        } = this.props;
        const { forms, name } = props || this.props;

        const currentForm = forms[currentFormName];

        if (!currentForm) {
            return false;
        }

        let currentField;

        const currentFieldName = this.getCurrentFieldName();

        if (currentFieldName.length) {
            currentField = { ...currentForm.fields };
            currentFieldName.forEach((item) => {
                if (typeof item === 'string') {
                    currentField =
                        !currentField[item] && currentField.value ? currentField.value[item] : currentField[item];
                } else if (typeof item === 'number') {
                    currentField = currentField.value[item];
                }
            });
            currentField = currentField?.value[name];
        } else {
            currentField = currentForm?.fields[name];
        }

        if (!currentField) {
            return false;
        }

        return currentField;
    };

    /**
     * Получаем текущую позицию в стейте (нужно для вложенности)
     */
    getCurrentFieldName = () => {
        const {
            fieldContext: { currentFieldName },
        } = this.props;
        const { index } = this.props;

        if (!currentFieldName) {
            return [];
        }

        const result = [...currentFieldName];
        if (index > -1) {
            result.push(index);
        }

        return result;
    };

    /**
     * Обертка для сырых данных формы
     */
    getWrapperData = (value: any) => {
        if (isObject(value) && !isArray(value)) {
            const copyValue = { ...value };

            Object.keys(value).forEach((key) => {
                copyValue[key] = { ...this.getMetaItem(copyValue[key], true, true) };
            });

            return {
                value: copyValue,
                ...this.getMetaItem(copyValue, true, false),
            };
        }

        if (isArray(value)) {
            return {
                value: value.map((item) => this.getWrapperData(item)),
            };
        }

        return {
            value,
            ...this.getMetaItem(value, true, false),
        };
    };

    /**
     * Получаем объект с мета параметрами
     */
    getMetaItem = (
        value: any,
        isBeforeReset: boolean = true,
        isSetValue: boolean = false,
        props: IProps = this.props,
    ) => {
        const { rules, mod } = props;

        let meta: IMetaField = {};

        if (isBeforeReset) {
            meta = {
                valid: true,
                invalid: false,
                errorText: '',
                errorType: '',
            };
        }

        if (isArray(value) && mod === 'array') {
            const isInvalid =
                value
                    .map((item) => this.getMetaItem(item, isBeforeReset, isSetValue, props))
                    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                    // @ts-ignore
                    .filter((invalid) => invalid === true).length > 0;

            meta.valid = !isInvalid;
            meta.invalid = isInvalid;
        } else if (isObject(value) && !isArray(value)) {
            const keys = Object.keys(value);
            const isInvalid =
                keys
                    .map((key) => value[key].invalid)
                    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                    // @ts-ignore
                    .filter((invalid) => invalid === true).length > 0;

            meta.valid = !isInvalid;
            meta.invalid = isInvalid;
        } else {
            const values = this.getValues();
            const rule = rules.find((curRule: Record<string, any>) => curRule.validate(value, values, props) !== true);
            if (rule) {
                const errorText = rule.validate(value, values, props);
                if (errorText !== true) {
                    meta = {
                        valid: false,
                        invalid: true,
                        errorText: rule.customErrorText || errorText,
                        errorType: rule.errorType || '',
                    };
                }
            }
        }

        return { ...meta, ...(isSetValue ? { value } : {}) };
    };

    /**
     * Получаем мета-данные исходя из значения
     */
    getMetaData = (
        value: any,
        isBeforeReset: boolean = true,
        isSetValue: boolean = false,
        props: IProps = this.props,
    ) => {
        const { mod } = props;
        let result;

        if (isArray(value) && mod === 'array') {
            const newValue = value.map((item) => this.getMetaData(item, isBeforeReset, true, props));
            result = {
                value: newValue,
                ...this.getMetaItem(newValue, isBeforeReset, false, props),
            };
        } else if (isObject(value) && !isArray(value)) {
            result = { ...value };
            const keys = Object.keys(result);
            keys.forEach((key) => {
                result[key] = this.getMetaData(result[key], isBeforeReset, true);
            });
            result = { value: result, ...this.getMetaItem(result, isBeforeReset, false, props) };
        } else {
            result = this.getMetaItem(value, isBeforeReset, isSetValue, props);
        }

        return result;
    };

    /**
     * Асинсхронная валидация
     * @param value
     */
    asyncValidating = (value: string) => {
        const { asyncRules } = this.props;

        const fieldData = this.getFieldData();

        if (!asyncRules.length || fieldData.asyncValidating || fieldData.invalid) {
            return;
        }

        const promises = asyncRules.map((item) => item.validate(value));

        this.changeField('ASYNC_VALIDATING', {
            asyncValidating: true,
        });

        Promise.all(promises).then(
            () => {
                this.changeField('ASYNC_VALIDATING', {
                    asyncValidating: false,
                    valid: true,
                    invalid: false,
                    errorText: '',
                    errorType: '',
                });
            },
            (errorText) => {
                this.changeField('ASYNC_VALIDATING', {
                    errorText,
                    errorType: 'async',
                    asyncValidating: false,
                    valid: false,
                    invalid: true,
                });
            },
        );
    };

    /**
     * Обработка изменения инпутов разных типов
     * (в том числе самописных)
     */
    handleChange = (e: any, val: unknown) => {
        const target = e ? e.target : false;
        const value = val !== undefined ? val : e.target.value;
        let caret: { start: number; end: number } | false = false;

        if (target && domUtils.checkInputText(target)) {
            caret = domUtils.getCaretPosition(target);
        }

        const meta = this.getMetaData(value);
        return this.changeField('CHANGE', { value, ...meta }).then(() => {
            if (caret) {
                domUtils.setCaretPosition(target, caret.start, caret.end);
            }
        });
    };

    handleBlur = () => {
        const { value } = this.state;

        const meta = this.getMetaData(value, false);

        this.asyncValidating(value);

        return this.changeField('BLUR', { value, focused: false, ...meta });
    };

    handleFocus = () => this.changeField('FOCUS', { focused: true });

    handleClick = () => {
        const {
            formContext: { currentFormName },
        } = this.props;
        const { changeField, name, mod } = this.props;

        const currentField = this.getFieldData();

        if (!currentField || (currentField && currentField.touched)) {
            return;
        }

        changeField('TOUCH', currentFormName, this.getCurrentFieldName(), name, mod, {
            touched: true,
        });
    };

    render() {
        const { WrappedComponent, name, mod } = this.props;

        const data = this.getFieldData();

        if (!data) {
            return null;
        }

        // prettier-ignore
        const fieldsApi = mod === 'array' ? {
            addField: this.addFieldValue,
            removeField: this.removeFieldValue,
        } : {};

        const value = this.getValue();
        const isChanged = this.state.isChanged;

        const componentProps = {
            ...this.props,
            reset: this.reset,
            // набор для конкретного поля
            field: {
                name,
                onChange: this.handleChange,
                onBlur: this.handleBlur,
                onClick: this.handleClick,
                onFocus: this.handleFocus,
                value,
                ...fieldsApi,
            },
            // мета данные конкретного поля
            meta: {
                touched: data.touched,
                focused: data.focused,
                changed: isChanged,
                invalid: data.invalid,
                valid: data.valid,
                errorText: data.errorText,
                errorType: data.errorType,
                asyncValidating: data.asyncValidating,
            },
            // общее api (позволяет взаимодействовать с соседними полями формы)
            api: {
                addField: this.addFieldValue,
                removeField: this.removeFieldValue,
                getFieldData: (fieldName: string) => this.getFieldData({ ...this.props, name: fieldName }),
            },
        };

        return (
            <FieldDecoratorContext.Provider value={this.getContextData()}>
                <WrappedComponent {...componentProps} />
            </FieldDecoratorContext.Provider>
        );
    }
}

export default FieldDecorator;
