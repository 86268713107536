import { sortOrders } from '@constants/sort';
import { IMediaConfig } from '@typings/media';
import { IReportConfig } from '@typings/reports';

// TODO: попробовать вынести тип конфига глобально
const config: Partial<IReportConfig & IMediaConfig> = {
    reportTitle: 'Дашборд',
    tableDataType: 'dynamic',
    isTableTitleSearch: true,
    isShowTotals: true,
    availableTools: {
        periodPicker: true,
        sample: true,
        activeSample: true,
        detail: true,
        periodDetail: true,
        filters: true,
        savedFilters: true,
    },
    sampleDefault: 1,
    isOnlyToday: true,
    tableLimit: 50,
    tableDefaultMetrics: [
        'visitors',
        'visits',
    ],
    availableMetrics: [
        'events_count',
        'visitors',
        'visits',
    ],
    defaultSort: {
        name: 'visitors',
        order: sortOrders.DESC,
    },
    disabledSortBy: ['title'],
    isDisabledCheckbox: true,
    reportHint: {
        message: 'Дашборд&nbsp;&mdash; инструмент для мониторинга самых важных метрик проекта.<br/>'
        + 'Отчет по&nbsp;умолчанию работает в&nbsp;режиме реального времени&nbsp;&mdash; данные в&nbsp;виджетах обновляются каждые 30&nbsp;секунд, но&nbsp;при выборе другой даты или периода данные в&nbsp;отчете становятся статичными.<br />'
        + 'Для настройки отчета можно использовать Фильтры по&nbsp;условиям, Семпл, Период и&nbsp;Детализацию.<br/>',
    },
};

export default config;
