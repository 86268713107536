// import metricsList from '@configs/constructor/metrics';
import { IDataListItem } from '@components/DataList';
import dimensionsList from '@configs/constructor/dimensions';

export enum GoalFormType {
    add = 'add',
    edit = 'edit',
}

export interface IConditionConfig {
    name: string;
    title: string;
    text: string;
    searchPlaceholder?: string;
    list?: IDataListItem;
}

export enum GoalFormConditionTypes {
    event = 'event',
    // metric = 'metric',
    parameter = 'parameter',
}

export interface ITypeConfig {
    title: string;
    buttonTitle: string;
}

export const goalFormTypesConfig: Record<GoalFormType, ITypeConfig> = {
    add: {
        title: 'Добавить целевое событие',
        buttonTitle: 'Добавить',
    },
    edit: {
        title: 'Редактировать целевое событие',
        buttonTitle: 'Сохранить',
    },
};

export const goalFormConditionsConfig: Record<GoalFormConditionTypes, IConditionConfig> = {
    event: {
        name: 'event',
        title: 'Событие',
        text: 'Выберите из списка / введите название события, которое будет считаться достижением цели',
        searchPlaceholder: 'Искать по событиям',
    },
    // FIXME: скрываем пока что
    /* metric: {
        name: 'metric',
        title: 'Метрика',
        text: 'Выберите метрику, которая будет считаться достижением цели',
        searchPlaceholder: 'Искать по метрикам',
        list: metricsList,
    }, */
    parameter: {
        name: 'parameter',
        title: 'Параметр',
        text: 'Выберите параметр, который будет считаться достижением цели',
        searchPlaceholder: 'Искать по параметрам',
        list: dimensionsList,
    },
};
