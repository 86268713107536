import { IReportConfig } from '@typings/reports';

const config: Partial<IReportConfig> = {
    reportTitle: 'Конверсии',
    tableDataType: 'dynamic',
    reportHint: {
        message: '<p>Конверсии нужны для понимания эффективности ваших целевых действий&nbsp;&mdash; какой процент нужных действий произошел во&nbsp;всех визитах, у&nbsp;всех посетителей или относительно другого целевого действия. '
            + 'Конверсию можно отредактировать, добавить в&nbsp;избранное, удалить и&nbsp;сделать общей для всего проекта.</p>'
            + '<p>При создании конверсия доступна только ее&nbsp;создателю, но&nbsp;ее&nbsp;можно сделать общей для всех аккаунтов, у&nbsp;которых есть доступ к&nbsp;проекту.</p>',
        link: 'https://ads.sber.ru/help/konversii',
        isMore: false,
    },
    availableTools: {
        periodPicker: true,
        detail: true,
        sample: true,
        activeSample: true,
    },
    sampleDefault: 0.1,
};

export default config;
