import { Loader } from '@adtech/ui';
import { Hint } from '@components/Hint';
import ReportMessage from '@components/ReportMessage';
import { Metrics } from '@typings/metrics';
import { ReportName } from '@typings/reports';
import { measuresUtils } from '@utils/index';
import React from 'react';

import s from './KeyMetrics.pcss';

interface IProps {
    data: Partial<Record<Metrics, number>>;
    title?: string;
    hintTitle: string;
    totalsRequest: boolean;
    reportName?: ReportName;
}

const KeyMetrics: React.FC<IProps> = ({
    data,
    title = 'Ключевые метрики',
    hintTitle,
    totalsRequest,
    reportName,
}: IProps) => {
    const errorMessage = <ReportMessage className={s.message} message="Нет данных для отображения" />;

    return (
        <div className={s.keyMetrics}>
            <Loader loading={totalsRequest}>
                <h2 className={s.title}>
                    {title}
                    {hintTitle && <Hint>{hintTitle}</Hint>}
                </h2>
                {!totalsRequest && (!data || !Object.keys(data).length) ? (
                    errorMessage
                ) : (
                    <div className={s.list}>
                        {Object.keys(data).map((key: Metrics) => (
                            <div className={s.item} key={key}>
                                <div className={s.itemTitle}>{measuresUtils.getTitle(key, reportName)}</div>
                                <div className={s.itemValue}>
                                    {measuresUtils.prepareValue(key, data[key]).replace('.', ',')}
                                </div>
                            </div>
                        ))}
                    </div>
                )}
            </Loader>
        </div>
    );
};

export default KeyMetrics;
