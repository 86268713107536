import { ImageRating } from '@images/svg/reportPlaceholder/rating';
import React from 'react';

export default {
    image: <ImageRating />,
    link: 'https://ads.sber.ru/help/uchastie-v-reitinge',
    title: 'Рейтинг — способ увидеть популярность сайта и его позицию в рейтинге',
    textBlocks() {
        return [
            'Сравните популярность своего сайта с другими в тематическом рейтинге',
            'Добавьте свой сайт в несколько тематических категорий и следите за всеми конкурентами',
            'Открытая статистика других сайтов в рейтинге поможет вам наметить точки роста для себя',
        ];
    },
};
