import { GroupBy } from '@configs/group';
import mediaConfig from '@configs/media';
import containersConfig from '@configs/media/containers';
import { widgetsMediaConfig } from '@configs/media/widgets';
import dateConstants from '@constants/date';
import { LatencyKeys } from '@constants/latency';
import mediaConstants from '@constants/media';
import { EPeriodDetail } from '@constants/periodDetail';
import useFilters from '@hooks/useFilters';
import useGlobalParams from '@hooks/useGlobalParams';
import { useGraphMedia } from '@hooks/useGraph';
import useQuery from '@hooks/useQuery';
import { useRequestParamsMedia } from '@hooks/useRequestParams';
import { useAppDispatch } from '@hooks/useStore';
import { useTableMedia } from '@hooks/useTable';
import { graphActions } from '@redux/slices/graph';
import { mediaActions, initialState as initialMediaState } from '@redux/slices/media';
import mediaUtils from '@redux/slices/media/utils';
import { tableActions } from '@redux/slices/table';
import * as Sentry from '@sentry/react';

import { DateLiteral } from '@typings/date';
import { Dimensions } from '@typings/dimensions';
import { IGlobalFilterItem } from '@typings/filters';
import {
    IAudienceReqResponse,
    IAuthorsReqResponse,
    IFullReadScrollReqResponse,
    IMaterialInfoReqResponse,
    IMediaContainer,
    IMediaRequestParams,
    IMediaUpdateAuthorsParams,
    IMediaUpdateWidgetOptions,
    IMediaWidgetOptions,
    IRecirculationSourcesReqResponse,
    ITotalsReqResponse,
    ITrafficSourcesReqResponse,
    IUpdateMediaParams,
    ReportNameMedia,
} from '@typings/media';
import { Metrics } from '@typings/metrics';
import { IQuery } from '@typings/query';
import { IUpdateReportOptions } from '@typings/reports';
import { IRootSlice } from '@typings/rootSlice';
import { ISearchParams } from '@typings/search';
import DateUtils from '@utils/date';
import globalUtilsFunc from '@utils/global';
import { graphUtils, urlUtils } from '@utils/index';
import reportsUtilsFunc from '@utils/reports';
import { useSelector } from 'react-redux';
import { useLocation, useParams } from 'react-router-dom';

const useMedia = () => {
    const params: IUrlParams<ReportNameMedia> = useParams();
    const location = useLocation();
    const dispatch = useAppDispatch();

    const globalSlice = useSelector((state: IRootSlice) => state.globalSlice);
    const tableSlice = useSelector((state: IRootSlice) => state.tableSlice);
    const graphSlice = useSelector((state: IRootSlice) => state.graphSlice);
    const mediaSlice = useSelector((state: IRootSlice) => state.mediaSlice);
    const latencySlice = useSelector((state: IRootSlice) => state.latencySlice);

    const { search } = location;
    const query: IQuery = urlUtils.parseQuery(search);

    const reportsUtils = reportsUtilsFunc({
        reportsConfig: mediaConfig,
    });

    const { getParamsFromQuery, checkQueryParams } = useQuery(mediaConfig);

    const {
        api: { getParamsForRequest, getStoreParams },
    } = useRequestParamsMedia();

    const {
        api: { getTable, updateTableParams },
    } = useTableMedia();

    const {
        api: { getGraph, clearGraphs, updateGraphParams, toggleUseDefaultLines },
    } = useGraphMedia();

    const { updateApplyedFilterParams } = useFilters();

    const { updateGlobalParams } = useGlobalParams();

    const api = {
        /**
         * Получаем нужный контейнер по названию отчёта медиа
         * @param reportName
         */
        getContainer: (reportName: ReportNameMedia): React.FC<IMediaContainer> => containersConfig[reportName],

        /**
         * Инициализация репорта
         */
        async initReport(latencyKey?: LatencyKeys): Promise<unknown> {
            const { reportName, projectId } = params;
            const reportConfig = reportsUtils.getConfig(reportName);
            let checkedParams = api.getRequestParams(reportName);
            const requestParams = getParamsForRequest(
                reportName,
                {
                    ...globalSlice,
                    projectId,
                },
                'table',
            );
            checkedParams = await updateApplyedFilterParams(checkedParams, requestParams, true);

            updateTableParams(checkedParams);
            updateGraphParams(checkedParams);
            updateGlobalParams(checkedParams);
            api.updateMediaParams(checkedParams);

            // TODO: Костыль - добавляем к значениям null,
            //  иначе при переходах между media не будут выполняться запросы
            //  Плохо тем, что даже при неправильном диапазоне дат
            //  запрос будет выполняться (кроме инициализации отчета)
            if (!latencySlice[latencyKey]?.isExcludedLatency) {
                await api[reportConfig.dataRequestFunction]('all init', checkedParams, reportName);
            }

            return Promise.resolve();
        },

        getRequestParams(reportName: ReportNameMedia): IUpdateMediaParams {
            const defaultParams = reportsUtils.getDefaultParams(reportName);
            const defaultParamsMedia: IUpdateMediaParams = {
                dateStart: defaultParams.dateStart,
                dateEnd: defaultParams.dateEnd,
                groupBy: defaultParams.groupBy,
                orderBy: defaultParams.orderBy,
                metrics: defaultParams.metrics,
                globalFilters: defaultParams.globalFilters,
                ...(defaultParams.graphs ? { graphs: defaultParams.graphs } : { graphs: undefined }),
            };
            const queryParams = getParamsFromQuery(query, reportName);
            const checkedParams = checkQueryParams(
                queryParams,
                defaultParamsMedia,
                { ...mediaSlice, ...tableSlice, ...graphSlice },
                api.resetParam,
                reportName,
            );
            return api.correctParams(checkedParams, defaultParams);
        },

        /**
         * Коррекция параметров отчётов
         */
        correctParams(customParams: IUpdateMediaParams, defaultParams: IUpdateMediaParams): IUpdateMediaParams {
            const { reportName } = params;
            const isOnlyToday: boolean = reportsUtils.getParamFromConfig(reportName, 'isOnlyToday');
            const { isToday } = DateUtils;
            let correctedParams = { ...customParams };

            // Для отчётов с отображением данных только за сегодня
            // принудительно сбрасываем даты на дефолтные today и группировку
            if (isOnlyToday && !(isToday(customParams.dateStart) && isToday(customParams.dateEnd))) {
                correctedParams = {
                    ...correctedParams,
                    dateStart: defaultParams.dateStart,
                    dateEnd: defaultParams.dateEnd,
                    groupBy: GroupBy.fiveMinute,
                };
            }

            // Для топа всегда фиксируем сортировку по просмотрам и сбрасываем periodDetail
            if (reportName === 'top') {
                const sort = reportsUtils.getParamFromConfig(reportName, 'defaultSort');
                correctedParams = {
                    ...correctedParams,
                    orderBy: { key: sort.name, value: sort.order, op: ':' },
                    periodDetail: EPeriodDetail.thirtyMinutes,
                };
            }

            return correctedParams;
        },

        resetParam(param: string): void {
            dispatch(mediaActions.resetParam(param));
            dispatch(tableActions.resetParam(param));
            dispatch(graphActions.resetParam(param));
        },

        /**
         * Запрос новых данных для отчётов
         */
        async updateReport(
            type: string = 'all',
            { updateParams, options = {}, meta = {} }: IUpdateReportOptions<IUpdateMediaParams, ReportNameMedia>,
        ): Promise<void> {
            const { reportName, projectId } = params;
            const reportConfig = reportsUtils.getConfig(reportName);
            const { withoutRequest, withoutCompare } = options;

            const requestParams = getParamsForRequest(
                reportName,
                {
                    ...globalSlice,
                    projectId,
                },
                'table',
            );
            const paramsWithFilters = await updateApplyedFilterParams(updateParams, requestParams, true);

            const { newParams: tableParams, noParamsUpdate: noUpdateTable } = updateTableParams(
                updateParams,
                withoutCompare,
            );
            const { newParams: graphParams, noParamsUpdate: noUpdateGraph } = updateGraphParams(
                updateParams,
                withoutCompare,
                meta,
            );
            const { newParams: globalParams, noParamsUpdate: noUpdateGlobal } = updateGlobalParams(
                paramsWithFilters,
                withoutCompare,
            );
            const { newParams: mediaParams, noParamsUpdate } = api.updateMediaParams(updateParams, withoutCompare);

            const newParams = {
                ...tableParams,
                ...graphParams,
                ...globalParams,
                ...mediaParams,
            };

            if (withoutRequest || (noParamsUpdate && noUpdateTable && noUpdateGraph && noUpdateGlobal)) return;

            api[reportConfig.dataRequestFunction](type, getStoreParams(newParams), reportName, meta);
        },

        updateMediaParams(updateParams: IUpdateMediaParams, withoutCompare?: boolean) {
            let newParams = { ...updateParams };
            const { compareParams, filterParamsForStore } = globalUtilsFunc();

            newParams = filterParamsForStore(newParams, initialMediaState);

            const noParamsUpdate =
                !withoutCompare &&
                !compareParams(
                    {
                        ...mediaSlice,
                    },
                    newParams,
                );

            if (Object.keys(newParams).length) {
                dispatch(mediaActions.updateRequestParams(newParams));
            }

            return { newParams, noParamsUpdate };
        },

        /**
         * Выполняем запрос за данными отчёта по всем материалам
         */
        getMaterialsSummaryRequest(type: string, newParams: IUpdateMediaParams, reportName: ReportNameMedia): void {
            const { projectId } = params;
            const requestParams = getParamsForRequest(reportName, {
                ...newParams,
                projectId,
            });

            switch (type) {
                case 'table': {
                    getTable(projectId, reportName, {
                        ...requestParams,
                        sample: tableSlice.sample || 1,
                    });
                    break;
                }
                case 'lineGraphWidget': {
                    api.getTrafficSources(projectId, { ...requestParams, metrics: null });
                    api.getAudience(projectId, { ...requestParams });
                    break;
                }
                default: {
                    api.getTotals(projectId, reportName, requestParams).then((sample) => {
                        requestParams.sample = sample;
                        updateTableParams({ sample });
                        getTable(projectId, reportName, requestParams);
                        // Чтобы метрики для таблицы не затирали метрики виджета обнуляем их
                        api.getTrafficSources(projectId, { ...requestParams, metrics: null });
                        api.getAudience(projectId, { ...requestParams });
                    });
                    break;
                }
            }
        },

        /**
         * Выполняем запрос за данными одного материала
         */
        getMaterialRequest(type: string = 'all', newParams: IUpdateMediaParams, reportName: ReportNameMedia): void {
            const { projectId, mediaId } = params;

            const requestParams = getParamsForRequest(reportName, {
                ...newParams,
                projectId,
                mediaId,
            });

            if (type === 'all init') {
                api.getMaterialInfo(projectId, reportName, requestParams);
            }

            switch (type) {
                case 'lineGraphWidget': {
                    api.getTrafficSources(projectId, { ...requestParams, metrics: null });
                    api.getAudience(projectId, { ...requestParams });
                    break;
                }
                default: {
                    api.getTotals(projectId, reportName, requestParams).then((sample) => {
                        requestParams.sample = sample;
                        updateTableParams({ sample });
                        api.getTrafficSources(projectId, { ...requestParams, metrics: null });
                        api.getAudience(projectId, { ...requestParams });
                        api.getFullReadScroll(projectId, { ...requestParams });
                        api.getRecirculationSources(projectId, { ...requestParams, sort: null });
                    });
                    break;
                }
            }
        },

        /**
         * Выполняем запрос за данными топа материалов
         */
        getTopRequest(type: string, newParams: IUpdateMediaParams, reportName: ReportNameMedia): void {
            const { projectId, mediaId } = params;

            const requestParams = getParamsForRequest(reportName, {
                ...newParams,
                projectId,
                mediaId,
            });

            api.getTotals(projectId, reportName, requestParams).then((sample) => {
                const periodDetail = newParams?.periodDetail || globalSlice.periodDetail;
                const [dateStart, dateEnd] = DateUtils.getDatesByPeriodDetail(periodDetail, globalSlice.timeOffset);
                requestParams.sample = sample;
                // Фиксируем на топе сортировку по просмотрам, потому что ее нельзя изменить
                requestParams.sort = reportsUtils.getParamFromConfig(reportName, 'defaultSort');
                updateTableParams({ sample });

                if (dateStart && dateEnd) {
                    const periodFilterKey = periodDetail === EPeriodDetail.thirtyMinutes ? 'five_minute' : 'hour';

                    requestParams.dimension_filters = [
                        ...(requestParams.dimension_filters || []),
                        { key: periodFilterKey, op: 'range', value: [dateStart, dateEnd] },
                    ];
                }

                getTable(projectId, reportName, requestParams);
            });
        },

        /**
         * Выполняем запрос за данными всех авторов
         */
        getAuthorsSummaryRequest(
            type: string,
            newParams: IUpdateMediaParams,
            reportName: ReportNameMedia,
            meta?: any,
        ): void {
            const { projectId } = params;
            const requestParams = getParamsForRequest(
                reportName,
                {
                    ...newParams,
                    projectId,
                },
                type,
            );

            switch (type) {
                case 'all':
                case 'all init': {
                    getTable(projectId, reportName, requestParams).then((response) => {
                        const isTypeInit = type.includes('init');
                        const { data, metrics, totals } = response.result || {};

                        if (isTypeInit) graphActions.resetGraph(true);

                        if (!data?.length) {
                            if (!isTypeInit) clearGraphs();
                            return;
                        }

                        const { selectedLines, useDefaultLines } = graphSlice;
                        let listIds = data.map((item) => item.author_id);

                        // Обновляем список, чтобы при передаче одинаковых id
                        // остались только уникальные
                        listIds = [...new Set(listIds)];

                        let newSelectedLines = graphUtils.updateSelectedLines(
                            reportsUtils.getConfig(reportName),
                            useDefaultLines,
                            listIds,
                            selectedLines,
                            mediaConstants.DEFAULT_LINES_COUNT,
                        );

                        newSelectedLines = newSelectedLines.filter((item) => listIds.includes(item.id));

                        updateGraphParams({ selectedLines: newSelectedLines }, null, { ...meta });

                        if (newSelectedLines.length) {
                            const graphRequestParams = getParamsForRequest(
                                reportName,
                                {
                                    ...newParams,
                                    selectedLines: newSelectedLines,
                                    projectId,
                                },
                                'graph',
                            );

                            const tableData = mediaUtils().prepareTableData(
                                data,
                                metrics,
                                totals,
                                projectId,
                                reportName,
                            );

                            getGraph(projectId, reportName, graphRequestParams, meta, tableData);
                        } else {
                            dispatch(graphActions.resetGraph(false));
                        }
                    });
                    break;
                }
                case 'graph': {
                    if (!requestParams.selected_lines.length) {
                        dispatch(graphActions.resetGraph(false));
                    } else {
                        getGraph(projectId, reportName, requestParams, meta);
                    }
                    break;
                }
                case 'table':
                default: {
                    getTable(projectId, reportName, requestParams).then((response) => {
                        const { data } = response.result || {};
                        const { selectedLines } = graphSlice;
                        const newSelectedLines = graphUtils.checkCountSelectedLines(selectedLines, data.length);

                        updateGraphParams({ selectedLines: newSelectedLines });
                    });
                    break;
                }
            }
        },

        /**
         * Выполняем запрос за данными одного автора
         */
        getAuthorRequest(type: string, newParams: IUpdateMediaParams, reportName: ReportNameMedia): void {
            const { projectId, mediaId } = params;

            const requestParams = getParamsForRequest(reportName, {
                ...newParams,
                projectId,
                mediaId,
            });

            // Запрашивается информация по id текущего автора
            if (type === 'all init') {
                api.getAuthors(
                    {
                        dimensionFilters: [{ key: 'author_id', op: '=', value: mediaId }],
                        limit: 1,
                    },
                    true,
                );
            }

            switch (type) {
                case 'table': {
                    getTable(projectId, reportName, { ...requestParams });
                    break;
                }
                case 'lineGraphWidget': {
                    api.getTrafficSources(projectId, { ...requestParams, metrics: null });
                    api.getAudience(projectId, { ...requestParams });
                    break;
                }
                default: {
                    api.getTotals(projectId, reportName, requestParams).then((sample) => {
                        requestParams.sample = sample;
                        updateTableParams({ sample });
                        getTable(projectId, reportName, requestParams);
                        // Чтобы метрики для таблицы не затирали метрики виджета обнуляем их
                        api.getTrafficSources(projectId, { ...requestParams, metrics: null });
                        api.getAudience(projectId, { ...requestParams });
                        api.getFullReadScroll(projectId, { ...requestParams });
                    });
                    break;
                }
            }
        },

        /**
         * Запрашиваем данные по авторам
         */
        getAuthors(requestParams: IMediaUpdateAuthorsParams, isInfo: boolean = false): void {
            const { projectId } = params;
            const dateStart = DateUtils.getDate(latencySlice[LatencyKeys.base].start).format(dateConstants.DATE_FORMAT);

            const newParams = {
                date_start: dateStart,
                date_end: 'today',
                dimensions: ['author_name', 'author_id'],
                sort: { name: 'author_name', order: 'asc' },
                dimension_filters: requestParams.dimensionFilters || [],
                offset: requestParams.offset || 0,
                limit: requestParams.limit || 10,
            } as IMediaRequestParams;

            dispatch(
                mediaActions.getAuthors({
                    projectId,
                    params: newParams,
                }),
            )
                .unwrap()
                .then((res: IAuthorsReqResponse & { responseStatus: number }) => {
                    if (res?.responseStatus !== 200) return;

                    if (isInfo) {
                        dispatch(mediaActions.updateAuthorInfo(res));
                        return;
                    }

                    const isLoadMore = newParams.offset > 0;

                    dispatch(mediaActions.updateAuthors({ data: res, isLoadMore }));
                    // В том числе, чтобы в юнит тестах не зависали промисы
                })
                .catch((err) => {
                    Sentry.captureException(err);
                });
        },

        /**
         * Обновляем результаты поиска по авторам
         */
        updateSearchAuthors(newParams: ISearchParams): void {
            const searchValue = newParams?.search || '';

            if (searchValue === '') {
                dispatch(mediaActions.resetParam('authors'));
                return;
            }

            if (searchValue.length < 3) return;

            api.getAuthors({
                dimensionFilters: [
                    { key: 'author_name', op: 'ilike', value: `%${searchValue}%` },
                    { key: 'author_id', op: '!=', value: '' },
                ],
                offset: newParams.offset,
                limit: newParams.limit,
            });
        },

        /**
         * Выполняем обновление параметров в стейте без запроса
         */
        updateReportWithoutRequest(updateParams: Record<string, string>): void {
            api.updateReport('all', { updateParams, options: { withoutRequest: true } });
        },

        /**
         * Запрашиваем данные для "Ключевых метрик"
         */
        getTotals(
            projectId: string,
            reportName: ReportNameMedia,
            requestParams: IMediaRequestParams<Metrics | Dimensions>,
        ): Promise<number> {
            return new Promise((resolve, reject) => {
                const mediaIdParamName = api.getMediaIdParamName(reportName);
                const mediaIdParamReq = requestParams[mediaIdParamName];
                const newBody = {};
                const newParams = {
                    date_start: requestParams.date_start,
                    date_end: requestParams.date_end,
                    ...(requestParams.metric_filters ? { metric_filters: requestParams.metric_filters } : {}),
                    ...(requestParams.dimension_filters ? { dimension_filters: requestParams.dimension_filters } : {}),
                };

                if (mediaIdParamReq) {
                    newParams[mediaIdParamName] = mediaIdParamReq;
                    newBody[mediaIdParamName] = `${projectId}|${mediaIdParamReq}`;
                }

                dispatch(
                    mediaActions.getTotals({
                        projectId,
                        params: newParams,
                        body: newBody,
                    }),
                )
                    .unwrap()
                    .then((res: ITotalsReqResponse & { responseStatus: number }) => {
                        if (res?.responseStatus !== 200) return;

                        dispatch(
                            mediaActions.updateTotals({
                                data: res,
                                reportName,
                            }),
                        );

                        resolve(res.sample);
                        // В том числе, чтобы в юнит тестах не зависали промисы
                    })
                    .catch((err) => {
                        reject();
                        Sentry.captureException(err);
                    });
            });
        },

        updateTotals(): void {
            const { reportName, projectId, mediaId } = params;
            const mediaIdParamName = api.getMediaIdParamName(reportName);
            const checkedParams = api.getRequestParams(reportName);
            const requestParams = getParamsForRequest(reportName, {
                ...checkedParams,
                projectId,
            });

            const newParams = {
                date_start: requestParams.date_start,
                date_end: requestParams.date_end,
                dimension_filters: requestParams.dimension_filters,
                metric_filters: requestParams.metric_filters,
            };
            if (mediaId) newParams[mediaIdParamName] = mediaId;

            api.getTotals(projectId, reportName, newParams);
        },

        getTrafficSources(projectId: string, requestParams: IMediaRequestParams<Metrics>): void {
            const { metrics, selected } = widgetsMediaConfig.trafficSources;
            const { reportName } = params;
            const mediaIdParamName = api.getMediaIdParamName(reportName);
            // prettier-ignore
            const mediaIdFilter: IGlobalFilterItem[] = requestParams[mediaIdParamName] ? [{
                key: mediaIdParamName,
                op: '=',
                value: requestParams[mediaIdParamName],
            }] : [];

            const dimensionFilters = [...(requestParams.dimension_filters || []), ...mediaIdFilter];

            const newParams = {
                date_start: requestParams.date_start,
                date_end: requestParams.date_end,
                group: requestParams.group,
                metric: requestParams.selected || selected,
                sample: requestParams.sample,
                ...(requestParams.metric_filters ? { metric_filters: requestParams.metric_filters } : {}),
                ...(dimensionFilters?.length ? { dimension_filters: dimensionFilters } : {}),
            };

            dispatch(
                mediaActions.getTrafficSources({
                    projectId,
                    params: newParams,
                }),
            )
                .unwrap()
                .then((res: ITrafficSourcesReqResponse & { responseStatus: number }) => {
                    if (res?.responseStatus !== 200) return;

                    dispatch(
                        mediaActions.updateTrafficSources({
                            ...res,
                            metrics: requestParams.metrics || metrics,
                            selected: newParams.metric,
                        }),
                    );
                    // В том числе, чтобы в юнит тестах не зависали промисы
                })
                .catch((err) => {
                    Sentry.captureException(err);
                });
        },

        updateTrafficSources(widgetName: string, options: IMediaWidgetOptions<Metrics>): void {
            const { reportName, projectId, mediaId } = params;
            const mediaIdParamName = api.getMediaIdParamName(reportName);
            const checkedParams = api.getRequestParams(reportName);
            const requestParams = getParamsForRequest(reportName, {
                ...checkedParams,
                projectId,
            });

            const newParams = {
                date_start: requestParams.date_start,
                date_end: requestParams.date_end,
                group: requestParams.group,
                selected: options.selected,
                dimension_filters: requestParams.dimension_filters,
                metric_filters: requestParams.metric_filters,
                metrics: options.measuresActive,
                sample: requestParams.sample,
            };
            if (mediaId) newParams[mediaIdParamName] = mediaId;

            api.getTrafficSources(projectId, newParams);
        },

        getAudience(projectId: string, requestParams: IMediaRequestParams<Dimensions>): void {
            const { reportName } = params;
            const { defaultMetric, dimensions, selected } = widgetsMediaConfig.audience;
            const mediaIdParamName = api.getMediaIdParamName(reportName);
            // prettier-ignore
            const mediaIdFilter: IGlobalFilterItem[] = requestParams[mediaIdParamName] ? [{
                key: mediaIdParamName,
                op: '=',
                value: requestParams[mediaIdParamName],
            }] : [];

            const dimensionFilters = [...(requestParams.dimension_filters || []), ...mediaIdFilter];

            const newParams = {
                date_start: requestParams.date_start,
                date_end: requestParams.date_end,
                group: requestParams.group,
                // указываем что обсчитывать по переданному dimension
                metric: defaultMetric,
                dimension: requestParams.selected || selected,
                sample: requestParams.sample,
                ...(requestParams.metric_filters ? { metric_filters: requestParams.metric_filters } : {}),
                ...(dimensionFilters?.length ? { dimension_filters: dimensionFilters } : {}),
            };

            dispatch(
                mediaActions.getAudience({
                    projectId,
                    params: newParams,
                }),
            )
                .unwrap()
                .then((res: IAudienceReqResponse & { responseStatus: number }) => {
                    if (res?.responseStatus !== 200) return;

                    dispatch(
                        mediaActions.updateAudience({
                            ...res,
                            dimensions: requestParams.dimensions || dimensions,
                            selected: newParams.dimension,
                        }),
                    );
                    // В том числе, чтобы в юнит тестах не зависали промисы
                })
                .catch((err) => {
                    Sentry.captureException(err);
                });
        },

        updateAudience(widgetName: string, options: IMediaWidgetOptions<Dimensions>): void {
            const { reportName, projectId, mediaId } = params;
            const mediaIdParamName = api.getMediaIdParamName(reportName);
            const checkedParams = api.getRequestParams(reportName);
            const requestParams = getParamsForRequest(reportName, {
                ...checkedParams,
                projectId,
            });

            const newParams = {
                date_start: requestParams.date_start,
                date_end: requestParams.date_end,
                group: requestParams.group,
                selected: options.selected,
                metric_filters: requestParams.metric_filters,
                dimension_filters: requestParams.dimension_filters,
                dimensions: options.measuresActive,
                sample: requestParams.sample,
            };
            if (mediaId) newParams[mediaIdParamName] = mediaId;

            api.getAudience(projectId, newParams);
        },

        getFullReadScroll(projectId: string, requestParams: IMediaRequestParams<Dimensions>): void {
            const { reportName } = params;
            const { defaultMetric, dimensions, selected } = widgetsMediaConfig.fullReadScroll;
            const mediaIdParamName = api.getMediaIdParamName(reportName);
            const mediaIdParamReq = requestParams[mediaIdParamName];
            const newParams = {
                date_start: requestParams.date_start,
                date_end: requestParams.date_end,
                group: requestParams.group,
                // указываем что обсчитывать по переданному dimension
                metric: defaultMetric,
                dimension: requestParams.selected || selected,
                sample: requestParams.sample,
                ...(requestParams.metric_filters ? { metric_filters: requestParams.metric_filters } : {}),
                ...(requestParams.dimension_filters ? { dimension_filters: requestParams.dimension_filters } : {}),
            };

            if (mediaIdParamReq) newParams[mediaIdParamName] = mediaIdParamReq;

            dispatch(
                mediaActions.getFullReadScroll({
                    projectId,
                    params: newParams,
                }),
            )
                .unwrap()
                .then((res: IFullReadScrollReqResponse & { responseStatus: number }) => {
                    if (res?.responseStatus !== 200) return;

                    dispatch(
                        mediaActions.updateFullReadScroll({
                            ...res,
                            dimensions: requestParams.dimensions || dimensions,
                            selected: newParams.dimension,
                        }),
                    );
                    // В том числе, чтобы в юнит тестах не зависали промисы
                })
                .catch((err) => {
                    Sentry.captureException(err);
                });
        },

        updateFullReadScroll(widgetName: string, options: IMediaWidgetOptions<Dimensions>): void {
            const { reportName, projectId, mediaId } = params;
            const mediaIdParamName = api.getMediaIdParamName(reportName);
            const checkedParams = api.getRequestParams(reportName);
            const requestParams = getParamsForRequest(reportName, {
                ...checkedParams,
                projectId,
            });

            const newParams = {
                date_start: requestParams.date_start,
                date_end: requestParams.date_end,
                group: requestParams.group,
                selected: options.selected,
                metric_filters: requestParams.metric_filters,
                dimension_filters: requestParams.dimension_filters,
                dimensions: options.measuresActive,
                sample: requestParams.sample,
            };
            if (mediaId) newParams[mediaIdParamName] = mediaId;

            api.getFullReadScroll(projectId, newParams);
        },

        getRecirculationSources(projectId: string, requestParams: IMediaRequestParams<Metrics>): void {
            const { dimensions, selected, sort, limit } = widgetsMediaConfig.recirculationSources;

            const newParams = {
                date_start: requestParams.date_start,
                date_end: requestParams.date_end,
                group: requestParams.group,
                dimensions,
                metric: requestParams.selected || selected,
                sorts: requestParams.sort ? [requestParams.sort] : [sort],
                sample: requestParams.sample,
                limit,
                ...(requestParams.metric_filters ? { metric_filters: requestParams.metric_filters } : {}),
                ...(requestParams.dimension_filters ? { dimension_filters: requestParams.dimension_filters } : {}),
                ...(requestParams.media_id
                    ? {
                          media_id: requestParams.media_id,
                      }
                    : {}),
            };

            dispatch(
                mediaActions.getRecirculationSources({
                    projectId,
                    params: newParams,
                }),
            )
                .unwrap()
                .then((res: IRecirculationSourcesReqResponse & { responseStatus: number }) => {
                    if (res?.responseStatus !== 200) return;

                    dispatch(
                        mediaActions.updateRecirculationSources({
                            ...res,
                            selected: newParams.metric,
                        }),
                    );
                    // В том числе, чтобы в юнит тестах не зависали промисы
                })
                .catch((err) => {
                    Sentry.captureException(err);
                });
        },

        updateRecirculationSources(widgetName: string, options: IMediaWidgetOptions<Metrics>): void {
            const { reportName, projectId, mediaId } = params;
            const checkedParams = api.getRequestParams(reportName);
            const requestParams = getParamsForRequest(reportName, {
                ...checkedParams,
                projectId,
            });

            const newParams: IMediaRequestParams<Metrics> = {
                date_start: requestParams.date_start,
                date_end: requestParams.date_end,
                group: requestParams.group,
                selected: options.selected,
                metric_filters: requestParams.metric_filters,
                dimension_filters: requestParams.dimension_filters,
                metrics: options.measuresActive,
                media_id: mediaId,
                sort: options.sort,
                sample: requestParams.sample,
            };

            api.getRecirculationSources(projectId, newParams);
        },

        /* Обновление параметров виджета в store */
        updateWidgetParams(widgetName: string, options: IMediaUpdateWidgetOptions): void {
            dispatch(mediaActions.updateWidgetParams({ widgetName, options }));
        },

        /**
         * Запрашиваем данные для информации о материале
         */
        getMaterialInfo(
            projectId: string,
            reportName: ReportNameMedia,
            requestParams: IMediaRequestParams<Metrics | Dimensions>,
        ): void {
            const { media_id } = requestParams;

            const newParams = {
                media_id,
                dimensions: reportsUtils.getParamFromConfig(reportName, 'materialInfoDimensions'),
            };

            dispatch(
                mediaActions.getMaterialInfo({
                    projectId,
                    params: newParams,
                }),
            )
                .unwrap()
                .then((res: IMaterialInfoReqResponse & { responseStatus: number }) => {
                    if (res?.responseStatus !== 200) return;

                    dispatch(
                        mediaActions.updateMaterialInfo({
                            reportName,
                            data: res,
                            dimensions: newParams.dimensions,
                        }),
                    );
                    // В том числе, чтобы в юнит тестах не зависали промисы
                })
                .catch((err) => {
                    Sentry.captureException(err);
                });
        },

        /**
         * Получаем название параметра media_id для запроса
         */
        getMediaIdParamName(reportName: ReportNameMedia): Dimensions {
            return reportsUtils.getParamFromConfig(reportName, 'mediaIdParamReq', 'media_id');
        },

        handleChangeDate(date: string[], relativeDate: DateLiteral): void {
            const { reportName } = params;
            const [dateStart, dateEnd] = date;
            const groupBy = globalSlice.groupBy;

            const updateParams = {
                ...(relativeDate ? DateUtils.getRelativeDates(relativeDate) : { dateStart, dateEnd }),
                groupBy,
            };

            updateParams.groupBy = DateUtils.checkCurrentGroup(
                [DateUtils.getDate(dateStart), DateUtils.getDate(dateEnd)],
                groupBy,
            );

            const type = api.getTypeRequestReport(reportName);

            toggleUseDefaultLines(true);

            api.updateReport(type, {
                updateParams,
            });
        },

        /**
         * Определяем, что обновлять в отчёте по обновленному параметру
         * (детализация, дата, семпл, сплит, ...)
         */
        getTypeRequestReport(reportName: ReportNameMedia, updateParam?: string): string {
            let type;

            switch (updateParam) {
                case 'groupBy':
                    // Если у отчёта есть график, то type 'graph', иначе 'lineGraphWidget'
                    type = reportsUtils.isGraphsNotUsed(reportName) ? 'lineGraphWidget' : 'graph';
                    break;
                default:
                    type = 'all';
                    break;
            }

            return type;
        },

        /**
         * Установка параметра, определяющего необходимость в автообновлении
         */
        setIsAutoUpdate(isAutoUpdate: boolean): void {
            dispatch(mediaActions.setIsAutoUpdate(isAutoUpdate));
        },
    };

    return {
        api,
        reportsUtils,
    };
};

export default useMedia;
