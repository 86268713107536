import loadProjectConfigs from '@decorators/loadProjectConfigs';
import projectRequired from '@decorators/project';
import { compose } from '@decorators/tools';
import { formsActions } from '@redux/slices/forms';
import { globalActions } from '@redux/slices/global';
import { projectsActions } from '@redux/slices/projects';
import * as React from 'react';
import { connect } from 'react-redux';

import SettingsCounter from './SettingsCounter';

interface IProps {
    removeForm: (forms: Array<string>) => void;
    clearCurrentProject: () => void;
    globalLoading: boolean;
    setGlobalLoading: (param: boolean) => void;
}

interface IState {
    isMounted: boolean;
}

// todo: исправить этот костыль.
// todo: сброс форм должен происходить по другому методу,
// todo: не через mounting
const resetOldDataDecorator = (Component: any) => {
    const mapStateToProps = ({ globalSlice: { globalLoading } }) => ({
        globalLoading,
    });
    const mapDispatchToProps = (dispatch) => ({
        removeForm: (forms: Array<string>) => dispatch(formsActions.removeForm(forms)),
        clearCurrentProject: () => dispatch(projectsActions.clearCurrentProject()),
        setGlobalLoading(param) {
            dispatch(globalActions.setGlobalLoading(param));
        },
    });

    class Container extends React.PureComponent<IProps, IState> {
        state = {
            isMounted: false,
        };

        componentDidMount() {
            const { removeForm, clearCurrentProject, globalLoading, setGlobalLoading } = this.props;

            // перед началом работы, очищаем все формы и текущий проект
            Promise.all([
                removeForm(['ratingSettings', 'counterSettings', 'accessSettings']),
                clearCurrentProject(),
            ]).then(() => {
                this.setState({ isMounted: true });
            });

            if (globalLoading) {
                setGlobalLoading(false);
            }
        }

        render() {
            const { isMounted } = this.state;

            if (!isMounted) {
                return null;
            }

            return <Component {...this.props} />;
        }
    }

    return connect(mapStateToProps, mapDispatchToProps)(Container);
};

export default compose(resetOldDataDecorator, projectRequired, loadProjectConfigs)(SettingsCounter);
