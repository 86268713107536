import useGlobalLoading from '@hooks/useGlobalLoading';
import { globalActions } from '@redux/slices/global';
import { IRootSlice } from '@typings/rootSlice';
import React, { useEffect, FC } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import s from './Error.pcss';
import errorContent from './ErrorContent';

const Error: FC = () => {
    useGlobalLoading();

    const dispatch = useDispatch();
    const errorCode = useSelector((state: IRootSlice) => state.globalSlice.error?.code);
    const config = errorContent[`error${errorCode}`];
    const { imgSrc, imgSet, title, getContent } = config || {};
    const setGlobalLoading = (param: boolean) => dispatch(globalActions.setGlobalLoading(param));
    const clearError = () => dispatch(globalActions.clearError());

    useEffect(() => {
        setGlobalLoading(false);
        return () => {
            clearError();
        };
    }, []);

    if (!config) return null;

    return (
        <div className={s.root} data-testid={`error-${errorCode}`}>
            <img alt={`Error: ${errorCode}`} src={imgSrc} srcSet={imgSet} />
            <div className={s.text}>
                <h2>{title}</h2>
                {getContent()}
            </div>
        </div>
    );
};

export default Error;
