import { Loader } from '@adtech/ui';
import cn from 'classnames';
import React, { FC } from 'react';
import s from '../../PostClick.pcss';

interface CounterCreatingProps {
    isSync?: boolean;
}

const CounterCreating: FC<CounterCreatingProps> = ({ isSync = false }) => (
    <div className={cn(s.linking, isSync && s.linkingSync)}>
        <Loader data-testid="loader" className={s.linkingLoader} size="small" />
        <span>{isSync ? 'Синхронизация' : 'Cоздание'} счётчика...</span>
    </div>
);

export default CounterCreating;
