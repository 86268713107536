import { EPeriodDetail } from '@constants/periodDetail';
import { sortOrders } from '@constants/sort';
import { IMediaConfig } from '@typings/media';


const config: IMediaConfig = {
    reportTitle: 'Топ материалов',
    tableDataType: 'dynamic',
    relativeLinkToReport: 'material',
    isShowTotals: false,
    isShowTableButtons: false,
    availableTools: {
        periodPicker: true,
        activeSample: true,
        sample: true,
        detail: false,
        filters: true,
        savedFilters: true,
        periodDetail: true, // Показать за ...
    },
    isOnlyToday: true,
    useAutoUpdate: true,
    tableDefaultMetrics: [
        'page_views',
        'visitors',
    ],
    availableMetrics: [
        'page_views',
        'visitors',
        'visits',
        'avg_fullread_percent',
        'avg_fullscroll_percent',
        'avg_recirculation',
        'avg_view_duration',
        'recirculation_count',
        'uid',
        'rambler_id',
    ],
    // Уходит в параметрах запроса
    availableDimensions: [
        'traffic_type',
        'media_title',
        'media_url',
        'published_datetime',
    ],
    tableFixedDimensions: ['traffic_type', 'published_datetime'], // те дименшины, что отображаются в колонках
    tableFixedMetrics: ['page_views'], // "прибитые" метрики
    tableLimit: 30,
    tableUpdateDelay: 20, // секунды
    defaultSort: {
        name: 'page_views',
        order: sortOrders.DESC,
    },
    isDisabledSort: true, // сортировка в таблице
    isDisabledCheckbox: true,
    reportHint: {
        message: '<p>Отчёты по медиа &mdash; это аналитика по&nbsp;контенту на&nbsp;сайте. '
            + 'Мы считаем посещаемость, источники переходов и специальные метрики для контента: '
            + 'рециркуляция, процент дочтения и время на&nbsp;материале.</p>'
            + '<p>Для корректной работы отчётов необходимо:'
            + '<br/>&mdash;&nbsp;сделать разметку по стандарту schema.org '
            + '(если на вашей площадке уже есть данные, проверьте корректность)'
            + '<br/>&mdash;&nbsp;отправить письмо на&nbsp;'
            + '<a href="mailto:counter@ads.sber.ru">counter@ads.sber.ru</a> '
            + 'с&nbsp;текстом &laquo;Подключите медиаотчёты&raquo; '
            + '(мы&nbsp;включим вашу площадку в&nbsp;специальный список '
            + 'и&nbsp;проверим, что все работает хорошо)</p>',
    },
    keyMetricsHint: 'Основной раздел отчёта, демонстрирующий целевые метрики по всем материалам сайта за выбранный период (по умолчанию показаны метрики за сегодня)',
    isShowLinkWithTitle: true,
    dataRequestFunction: 'getTopRequest',
};

export const periodDetailConfig = [
    {
        title: 'за 30 минут',
        value: EPeriodDetail.thirtyMinutes,
    },
    {
        title: 'за 1 час',
        value: EPeriodDetail.oneHour,
    },
    {
        title: 'за 4 часа',
        value: EPeriodDetail.fourHours,
    },
    {
        title: 'с начала текущего дня',
        value: EPeriodDetail.today,
    },
];

export default config;
