import { Tooltip } from '@adtech/ui';
import MaterialPosition from '@components/MaterialPosition';
import TrafficSource from '@components/TrafficSource';
import attributesData from '@configs/attributesData';
import { ChartColors } from '@configs/graph';
import dateConst from '@constants/date';
import IconCircleMinus from '@images/svg/icons/icon-minus.svg';
import IconCirclePlus from '@images/svg/icons/icon-plus.svg';
import { Dimensions, DimensionsData } from '@typings/dimensions';
import { IMetricsTableData, IMetricsWithStates } from '@typings/metrics';
import { ReportName } from '@typings/reports';
import { IOrderBy } from '@typings/table';
import DateUtils from '@utils/date';
import { measuresUtils, numbersUtils } from '@utils/index';
import cn from 'classnames';
import React from 'react';
import Linkify from 'react-linkify';
import { Link, useParams } from 'react-router-dom';

import s from '../../ReportTable.pcss';

interface IProps {
    withParentsId: string;
    url: string;
    id: string;
    reportName: ReportName;
    canBeExpanded: boolean;
    expandPadding: boolean;
    withoutExpandPadding: boolean;
    isRating?: boolean;
    expand: boolean;
    firstPosition?: boolean;
    title: string | number;
    level: number;
    onSelectLine;
    onExpandClick: (parents: string, options?: { needToOpenNode: boolean }) => void;
    metricsWithStates: IMetricsWithStates[];
    dimensions?: Dimensions[];
    indicators: string[];
    metricsData: IMetricsTableData[];
    dimensionsData?: DimensionsData[];
    isDisabled: boolean;
    isSelected: boolean;
    colorIndex: number;
    orderBy: IOrderBy;
    utils: TAPI;
    labels?: Record<string, string>;
    relativeTitleLink?: string;
    position?: number;
    diff?: number;
    style?: Record<string, string>;
}

const TableRow: React.FC<IProps> = ({
    withParentsId = '',
    url,
    id,
    reportName,
    canBeExpanded = false,
    expandPadding = false,
    withoutExpandPadding = false,
    isRating,
    expand = false,
    firstPosition = false,
    title = '',
    level = 1,
    onSelectLine = () => {},
    onExpandClick = () => {},
    metricsWithStates,
    dimensions,
    indicators,
    metricsData,
    dimensionsData,
    isDisabled = false,
    isSelected = false,
    colorIndex = -1,
    orderBy,
    utils = {},
    labels = {},
    relativeTitleLink = '',
    position,
    diff,
    style,
}) => {
    const params = useParams();

    const checkIsBold = () => id === params.projectId && isRating;

    const checkboxClickHandler = () => {
        if (!isDisabled) onSelectLine(withParentsId);
    };

    const handleExpand = () => {
        onExpandClick(withParentsId);
    };

    const renderCheckbox = () => {
        let inlineStyle = {};

        if (isSelected) {
            const checkboxColorConfig = ChartColors[colorIndex];

            inlineStyle = {
                backgroundColor: checkboxColorConfig || '#fff',
            };
        }

        const className = cn(s.tableNameOnGraph, {
            [s.tableNameOnGraphSelected]: isSelected,
            [s.tableNameOnGraphNoExpand]: !expandPadding,
            [s.tableNameOnGraphChildSelected]: false,
            [s.tableNameOnGraphDisabled]: isDisabled,
        });

        const isActive = isSelected ? 'active' : 'inactive';
        const tableAttr = attributesData.newreport.table;
        const selectLineAttr = tableAttr.selectLine;

        return (
            <div
                className={className}
                style={inlineStyle}
                onClick={checkboxClickHandler}
                data-newreport={`${tableAttr.attrName}::${selectLineAttr}::${isActive}::${reportName}`}
            />
        );
    };

    const renderExpandButton = () => {
        if (!canBeExpanded) {
            if (withoutExpandPadding) return null;

            return <div className={s.tableNameExpandPadding} />;
        }

        const className = cn(s.tableNameExpand, {
            [s.tableNameExpandEmpty]: !canBeExpanded,
            [s.tableNameExpandShow]: expand,
        });

        const tableAttr = attributesData.newreport.table;

        return (
            <button
                className={className}
                onClick={handleExpand}
                type="button"
                data-newreport={`${tableAttr.attrName}::${tableAttr.expandBtn}::${reportName}`}
            >
                {expand ? <IconCircleMinus /> : <IconCirclePlus />}
            </button>
        );
    };

    const renderCell = (indicator: IMetricsWithStates, index: number) => {
        const format = measuresUtils.getParamFromConfig(indicator.name, 'format');
        const className = cn(s.tableCell, {
            [s.tableCellOrdered]: measuresUtils.isOrderingBy(indicator.name, orderBy),
        });

        if (utils.canHideIndicator(reportName, indicator.name)) {
            return null;
        }

        let originValue = metricsData && metricsData[index] ? metricsData[index][indicator.state] : 0;

        let value = originValue;
        const { state } = metricsWithStates[index];
        const currentMetric = metricsData[index];

        if (currentMetric && state !== 'percent') {
            value = measuresUtils.prepareValue(indicator.name, originValue);
        }

        if (originValue) {
            originValue = numbersUtils.numberWithSpaces(originValue);
        }

        if (format === 'time') {
            originValue = value;
        }

        return (
            <td className={className} key={index}>
                <Tooltip title={originValue}>{value}</Tooltip>
            </td>
        );
    };

    const renderCellDimensions = (indicator: Dimensions, index: number) => {
        if (!dimensionsData) return null;

        let originValue = dimensionsData[index];
        let el;

        switch (indicator) {
            case 'traffic_type': {
                originValue = originValue || {};

                if (typeof originValue === 'object') {
                    const { totals, ...trafficTypeData } = originValue;
                    el = (
                        <td className={s.tableCell} key={index}>
                            {totals && <TrafficSource data={trafficTypeData} labels={labels} total={totals} />}
                        </td>
                    );
                }
                break;
            }
            case 'published_datetime': {
                const time = originValue?.toString()?.split(' ')[1] || ''; // чч:мм:сс
                let date = originValue?.toString()?.split(' ')[0]; // гггг-мм-дд
                date = (date && DateUtils.reformatDate(date, dateConst.DATE_FORMAT, dateConst.DATE_VIEW)) || '';
                el = (
                    <td className={s.tableCell} key={index}>
                        <div className={s.tableCellTextTime}>{time.slice(0, -3)}</div>
                        <div>{date}</div>
                    </td>
                );
                break;
            }
            default:
                el = (
                    <td className={s.tableCell} key={index}>
                        {String(originValue) || ''}
                    </td>
                );
        }

        return el;
    };

    const renderPosition = () =>
        position ? (
            <>
                <div className={s.tableNameTitlePositionNum}>{position}</div>
                <MaterialPosition positionDiff={diff || 0} />
            </>
        ) : null;

    const renderTitle = () => {
        const isShowLinkWithTitle = utils.getParamFromConfig(reportName, 'isShowLinkWithTitle');
        const isNotAutodetectTitleLink = utils.getParamFromConfig(reportName, 'isNotAutodetectTitleLink');
        const isBold = checkIsBold();

        const classNames = cn(s.tableNameTitle, {
            [s.tableNameTitleShowUrl]: isShowLinkWithTitle,
            [s.bold]: isBold,
        });

        let positionIndex = null;
        let showPosition = false;

        if (isShowLinkWithTitle) {
            indicators.forEach((item, index) => {
                if (item === 'position') {
                    positionIndex = index;
                }
            });
            showPosition = !!(positionIndex !== null && metricsData.length);
        }

        return (
            <Tooltip overlayClassName={s.tableNameTitleTooltip} title={title}>
                <div
                    className={classNames}
                    data-atst={`${attributesData.newreport.table.tableRowTitle}::${reportName}`}
                >
                    {showPosition && (
                        <div className={s.tableNameTitlePosition}>{`${metricsData[positionIndex].normal}.`}</div>
                    )}
                    <div className={s.tableNameTitleText}>
                        {(!relativeTitleLink || isShowLinkWithTitle) && (
                            <span className={s.tableNameTitleTextBlock}>
                                {!isNotAutodetectTitleLink ? (
                                    <Linkify
                                        componentDecorator={(decoratedHref, decoratedText, key) => (
                                            <a
                                                target="_blank"
                                                rel="noopener noreferrer"
                                                href={decoratedHref}
                                                className={s.tableNameTitleLink}
                                                key={key}
                                            >
                                                {decoratedText}
                                            </a>
                                        )}
                                    >
                                        {isShowLinkWithTitle ? url : title}
                                    </Linkify>
                                ) : (
                                    title
                                )}
                            </span>
                        )}
                        <span className={s.tableNameTitleTextBlock}>
                            {/* Если isShowLinkWithTitle отображение в 2 строки:
                                блок ниже отображается сверху.
                                Если !isShowLinkWithTitle и adblock скрыл текст ссылки:
                                отображается вместо нее.
                                Реализация через стили, класс завязан на isShowLinkWithTitle!
                            */}
                            {relativeTitleLink ? (
                                <Link
                                    to={relativeTitleLink}
                                    className={s.tableNameTitleLink}
                                    key={relativeTitleLink}
                                    target="_blank"
                                    rel="noopener noreferrer"
                                >
                                    {title}
                                </Link>
                            ) : (
                                title
                            )}
                        </span>
                    </div>
                </div>
            </Tooltip>
        );
    };

    const isBold = checkIsBold();
    const canBeChecked = !utils.isDisabledCheckbox(reportName);
    const isShowLinkWithTitle = utils.getParamFromConfig(reportName, 'isShowLinkWithTitle');

    const classNameTitle = cn(s.tableName, s.tableNameLevel, {
        [s.tableNameNoCheckbox]: !canBeChecked,
        [s.tableNameNoExpand]: !expandPadding,
        [s[`tableNameLevel_${level}`]]: level,
        [s.tableNameWithUrl]: isShowLinkWithTitle,
    });

    const classNameRow = cn(s.tableRow, {
        [s.tableRowAverage]: firstPosition || isBold,
        [s.tableExpandChildren]: level > 1,
        [s.bigHeight]: isShowLinkWithTitle,
        [s.animated]: diff > 2 && style,
    });

    return (
        <tr className={classNameRow} style={{ ...style }} data-testid="table-row">
            <td className={cn(s.tableCell, s.tableCellName)}>
                <div className={classNameTitle}>
                    {renderExpandButton()}
                    {canBeChecked && renderCheckbox()}
                    <div className={s.tableNameTitleWrapper}>
                        {renderPosition()}
                        {renderTitle()}
                    </div>
                </div>
            </td>
            {dimensions.map(renderCellDimensions)}
            {metricsWithStates.map(renderCell)}
        </tr>
    );
};

export default TableRow;
