import React from 'react';
import { NavigateFunction } from 'react-router-dom';
import s from '../ModalPopup.pcss';

interface IProps {
    userEmail: string;
}

export default {
    getContent(props: IProps, navigate: NavigateFunction) {
        const popupContent = (
            <div className={s.content}>
                <p>
                    Мы пришлем письмо с дальнейшими инструкциями на почту{' '}
                    <span className={s.csvUserEmail}>{props.userEmail}</span>. Письмо будет действительно в течение семи
                    дней.
                </p>
            </div>
        );

        const goToProjects = () => navigate('/stat/projects');

        const okButton = {
            type: 'default',
            children: 'Продолжить работу',
            onClick: goToProjects,
            closePopupOnClick: true,
            className: s.btnOk,
        };

        return {
            popupClassName: s.csv,
            width: 'md',
            title: 'Восстановление доступа',
            content: popupContent,
            buttonContainerClassName: s.buttons,
            okButton,
        };
    },
};
