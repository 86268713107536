import { createAsyncThunk } from '@reduxjs/toolkit';
import { IFunnelDTO, IFunnelTableRequestData } from '@typings/funnels';
import fetchApi from '@utils/fetchApi';

export const getFunnelTable = createAsyncThunk(
    'funnelsSlice/getTable',
    async ({ projectId, data }: { projectId: number; data: IFunnelTableRequestData }) =>
        // eslint-disable-next-line no-return-await
        await fetchApi({
            url: `/api/report/v1.0/eventbase/${projectId}/funnel`,
            method: 'POST',
            body: data,
        }),
);

export const getFunnelsList = createAsyncThunk(
    'funnelsSlice/getFunnelsList',
    async ({
        projectId,
        limit,
        search,
        offset,
    }: {
        projectId: number;
        limit?: number;
        search?: string;
        offset?: number;
    }) => {
        // eslint-disable-next-line no-return-await
        let queryParams = limit ? `&limit=${limit}` : '';
        queryParams += offset ? `&offset=${offset}` : '';
        queryParams += search ? `&search=${search}` : '';

        if (queryParams) queryParams = queryParams.replace('&', '?');

        // eslint-disable-next-line no-return-await
        return await fetchApi({ url: `/api/v1.0/${projectId}/funnels${queryParams}` });
    },
);

export const getFunnel = createAsyncThunk(
    'funnelsSlice/getFunnel',
    async ({ id, projectId }: { id: number; projectId: number }) =>
        // eslint-disable-next-line no-return-await
        await fetchApi({ url: `/api/v1.0/${projectId}/funnel/${id}` }),
);

export const createFunnel = createAsyncThunk(
    'funnelsSlice/createFunnel',
    async ({ projectId, data }: { projectId: number; data: IFunnelDTO }) =>
        // eslint-disable-next-line no-return-await
        await fetchApi({
            url: `/api/v1.0/${projectId}/funnel`,
            method: 'POST',
            body: data,
        }),
);

export const updateFunnel = createAsyncThunk(
    'funnelsSlice/updateFunnel',
    async ({ id, data, projectId }: { id: number; projectId: number; data: IFunnelDTO }) =>
        // eslint-disable-next-line no-return-await
        await fetchApi({
            url: `/api/v1.0/${projectId}/funnel/${id}`,
            method: 'PUT',
            body: data,
        }),
);

export const deleteFunnel = createAsyncThunk(
    'funnelsSlice/deleteFunnel',
    async ({ id, projectId }: { id: number; projectId: number }) =>
        // eslint-disable-next-line no-return-await
        await fetchApi({
            url: `/api/v1.0/${projectId}/funnel/${id}`,
            method: 'DELETE',
            metaInfo: { id },
        }),
);
