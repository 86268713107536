// Entity - цели и конверсии, шаблоны
export enum EntityActions {
    add = 'add',
    addToCommon = 'addToCommon',
    edit = 'edit',
    copy = 'copy',
    delete = 'delete',
}

export interface IEntityAction {
    id: EntityActions;
    title: string;
    onClick?: () => void;
}

export enum EntityAccess {
    own = 'own',
    common = 'common',
}
