import IconCloseLight from '@images/svg/icons/icon-close-light.svg';
import IconSimpleArrow from '@images/svg/icons/icon-simple-arrow.svg';
import { listDataUtils } from '@utils/index';
import classNames from 'classnames';

import sum from 'lodash/sum';
import * as React from 'react';

import s from './ListType.pcss';

interface IProps {
    values?: Record<string, any>;
    initialValues?: Record<string, any>;
    data?: Record<string, any>;
    filterData;
    onSetTab?;
    onClear?: (item: Record<string, any>) => void;
    onSelectListItem?;
    getListChildId?;
    getFilterAttr?;
    selectedList?: number[];
}

export default class ListType extends React.PureComponent<IProps> {
    handleClick = (item: Record<string, any>, isChildren: boolean, parentOffsets: number[], dataAttr?: string[]) => {
        const { onSetTab, onSelectListItem } = this.props;

        if (item.tab && item.navigation) {
            onSetTab(item.tab, item.navigation, {}, dataAttr);
            return;
        }

        onSelectListItem(item.id, isChildren, parentOffsets, dataAttr);
    };

    handleClickClear = (e: React.MouseEvent<SVGElement, MouseEvent>, item: Record<string, any>) => {
        const { onClear } = this.props;
        onClear({ [item.name]: this.getEmptyValue(item.type) });
    };

    getValue = (name: string) => {
        const { values, initialValues } = this.props;

        return values[name] || initialValues[name];
    };

    /**
     * Получаем количество выбранных элементов
     */
    getCountSelected = (item: Record<string, any>): number => {
        const { data } = this.props;
        const value = this.getValue(item.name);

        const filteredChildData = data.data.filter(
            (subItem) => subItem.parent_id === item.id && subItem.main === false,
        );

        const subSumCount = sum(filteredChildData.map((subItem) => this.getCountSelected(subItem)));

        if (!value) return subSumCount;

        const result = listDataUtils.getCountSelected({ data: value, filter: item });

        return result;
    };

    getLabelSelected = (item: Record<string, any>): string => {
        const count = this.getCountSelected(item);

        if (!count) {
            return '';
        }

        let result = '';

        switch (item.type) {
            case 'period':
                result = 'Выбрано';
                break;
            default:
                result = `Выбрано ${count}`;
                break;
        }

        return result;
    };

    /**
     * Получает пустое значение для фильтра
     */
    getEmptyValue = (type: string) => {
        switch (type) {
            case 'checkbox':
                return { checkboxes: [] };
            case 'paramsArray':
                return { params: [] };
            default:
                return { value: '' };
        }
    };

    renderItem = ({ item, indexMain, parentOffsets = [], key }: Record<string, any>) => {
        const { data, selectedList, getListChildId, getFilterAttr } = this.props;

        const filteredChildData = data.data.filter(
            (subItem) => subItem.parent_id === item.id && subItem.main === false,
        );
        const isChildren = filteredChildData.length > 0;
        const isSelectedList = selectedList.includes(item.id);
        const isActive = getListChildId() === item.id;
        const isArrowIcon = isChildren;

        const countSelected = this.getCountSelected(item);
        const labelSelected = this.getLabelSelected(item);

        const itemClassName = classNames(s.item, {
            [s.active]: (isChildren && isSelectedList && isActive) || (!isChildren && isActive),
            [s.selected]: isSelectedList,
        });

        const dataAttr = getFilterAttr(item.name);

        // Компонент элемента
        const ItemComponent = () => (
            <div className={itemClassName}>
                <div
                    className={s.itemTitle}
                    // eslint-disable-next-line react/no-this-in-sfc
                    onClick={() => this.handleClick(item, isChildren, parentOffsets, dataAttr)}
                    data-report-filters={dataAttr.join('::')}
                >
                    <span className={s.itemTitleText}>{item.title}</span>
                    {countSelected > 0 && <span className={s.itemTitleCount}>{labelSelected}</span>}
                </div>
                <div className={s.itemIconList}>
                    {countSelected > 0 && !isChildren && isActive && (
                        <IconCloseLight
                            className={s.itemIconClose}
                            // eslint-disable-next-line react/no-this-in-sfc
                            onClick={(e) => this.handleClickClear(e, item)}
                        />
                    )}
                    {isArrowIcon && <IconSimpleArrow className={s.itemIconArrow} />}
                </div>
            </div>
        );

        if (isChildren) {
            return (
                <div className={s.group} key={key}>
                    <ItemComponent />
                    {isSelectedList && (
                        <div className={s.groupSub}>
                            {filteredChildData.map((child, indexChild) =>
                                this.renderItem({
                                    item: child,
                                    indexMain,
                                    parentOffsets: [...parentOffsets, child.id],
                                    key: `${key}-child-${indexChild}`,
                                }),
                            )}
                        </div>
                    )}
                </div>
            );
        }

        return <ItemComponent key={key} />;
    };

    render() {
        const { data, filterData } = this.props;

        const computeData = filterData ? data.data.filter(filterData) : data.data;

        return (
            <div>
                {computeData.map((item, index) =>
                    this.renderItem({
                        item,
                        key: `main-${index}`,
                        indexMain: index,
                    }),
                )}
            </div>
        );
    }
}
