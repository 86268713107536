import Anchor from '@components/GlobalFiltersList/Anchor';

import attributesData from '@configs/attributesData';
import listDataConstants from '@constants/listData';

import { IWrappedComponentProps } from '@decorators/formDecorator/types';
import { GetFiltersFunction, IDynamicFiltersData } from '@typings/filters';
import { ReportName } from '@typings/reports';
import { listDataUtils } from '@utils/index';
import isEmpty from 'lodash/isEmpty';
import isEqual from 'lodash/isEqual';
import * as React from 'react';

import Dropdown from './Dropdown';
import dropdownStyle from './Dropdown/Dropdown.pcss';

interface IComputeData {
    map?: any;
    data?: any;
}

interface IProps extends IWrappedComponentProps {
    // данные
    dynamicData: IDynamicFiltersData; // динамические данные из стейта
    data: Record<string, any>; // все данные, имеем свободный формат
    allFiltersData: any[]; // все фильтра, нужны для формирования анкора и данных для запроса
    selectedData: Record<string, any>; // данные, которые были выбраны
    reportName: ReportName; // название отчёта
    isSavedFilterApplied: boolean; // если применен сохраненный фильтр
    isFiltersListOnly?: boolean; // отобразить только список фильтров
    isSearchDisabled?: boolean; // отобразить без поиска

    // функции
    initializeValues: any;
    getFilterData: any; // функция, которая формирует нужные данные для отрисовки
    getDynamicData: GetFiltersFunction; // функция, которая получает динамические данные
    onApply: any; // применяем данные фильтрации
    setSnackbar?: (string) => void;

    // форма
    initialValues: Record<string, any>;
    values: Record<string, any>;
    removeField: any;
    changeValueFields: any;
}

interface IComputeData {
    map?: any;
    data?: any;
}

interface IState {
    computeData: IComputeData;
    isOpened: boolean;
    searchValue: string;
    currentTab: string;
    selectedList: number[];
    values: Record<string, any>;
    activeListElement: number[];
}

export class GlobalFiltersList extends React.PureComponent<IProps, IState> {
    state = {
        computeData: {
            map: {},
            data: [],
        },
        isOpened: false,
        searchValue: '',
        currentTab: '',
        selectedList: [],
        values: {},
        activeListElement: [],
    };

    componentDidMount() {
        this.setState({ values: this.props.selectedData }, this.setComputeData);

        // Инициализируем значения фильтров которые пришли из query
        if (Object.keys(this.props.selectedData).length) {
            this.initializeValues(this.props.selectedData, true, true);
        }
    }

    componentDidUpdate(prevProps: IProps) {
        if (this.props.reportName !== prevProps.reportName) {
            this.setState({ isOpened: false });
        }

        if (
            !isEqual(prevProps.values, this.props.values) &&
            this.props.values &&
            !this.state.isOpened &&
            !this.props.isSavedFilterApplied
        ) {
            this.setState({ values: this.props.values });
            this.initializeValues(this.props.values);
        }

        // Обновлять фильтры, только если пришел новый список selectedData
        if (!isEqual(prevProps.selectedData, this.props.selectedData)) {
            this.setState({ values: this.props.selectedData }, () =>
                this.initializeValues(this.props.selectedData, true, true),
            );
        }
    }

    /**
     * Инициализация формы
     */
    initializeValues = (
        values: Record<string, any>,
        isChangeFields: boolean = true,
        isForceUpdate: boolean = false,
    ) => {
        const { initializeValues } = this.props;
        initializeValues(values, isChangeFields, isForceUpdate);
    };

    /**
     * Очищаем выбранные, но не примененные значения фильтра в форме
     */
    removeNotAppliedValues = () => {
        const { removeField } = this.props;
        const appliedValues = Object.keys(this.state.values);
        const selectedValues = Object.keys(this.props.values);

        selectedValues.forEach((selectedValue) => {
            if (!appliedValues.includes(selectedValue)) {
                removeField(selectedValue);
            }
        });
    };

    /**
     * Закрываем модального окна с фильтрами
     * Если значения в форме были изменены, но не сохранены, тогда:
     * очищаем добавленные значения.
     */
    handleClose = () => {
        const { changeValueFields } = this.props;

        if (!this.state.isOpened) {
            return;
        }

        this.setState(
            {
                isOpened: false,
                activeListElement: [],
                selectedList: [],
                searchValue: '',
                currentTab: '',
            },
            () =>
                this.setComputeData(() => {
                    if (!isEqual(this.state.values, this.props.values)) {
                        if (!isEmpty(this.state.values) && !isEmpty(this.props.values)) {
                            this.removeNotAppliedValues();
                            changeValueFields(this.state.values);
                        } else {
                            if (!isEmpty(this.props.values)) this.removeNotAppliedValues();
                            changeValueFields(this.props.values);
                        }
                    }
                }),
        );
    };

    /**
     * Открытие модального окна с фильтрами
     */
    handleOpen = (e: any, activeItemName: string = '') => {
        const { computeData } = this.state;
        let state = {};

        if (activeItemName) {
            const ids = this.getIdsByName(activeItemName, computeData);
            const curItem = computeData.map[ids.length - 1];

            state = {
                currentTab: curItem.tab,
                selectedList: ids.slice(0, ids.length - 1),
                activeListElement: ids.slice(ids.length - 1, ids.length),
            };
        }

        this.setState(
            {
                isOpened: true,
                ...state,
            },
            this.setComputeData,
        );
    };

    /**
     * Применяем данные, переносим их в стейт
     */
    handleApply = (cb = () => {}, isOnlyState: boolean = false, filterName: string = '') => {
        const { values: stateValues } = this.state;
        const { initializeValues, onApply, values: propsValues, allFiltersData, setSnackbar } = this.props;

        let values = isOnlyState ? stateValues : propsValues;

        if (!isOnlyState) {
            values = {
                ...stateValues,
                ...propsValues,
            };
        }

        if (filterName) delete values[filterName];

        const notEmptyValues = listDataUtils.removeEmptyData(values);

        // первоначально, конвертируем в массив, добавляя значения из конфига фильтров
        const filtersValues = listDataUtils.convertValuesToArray(notEmptyValues, allFiltersData);
        const isNotValid = listDataUtils.checkIsNotValid(filtersValues);

        if (isNotValid) {
            setSnackbar(listDataConstants.validateErrorMsg);
            return;
        }

        const globalFilters = Object.keys(notEmptyValues).length
            ? {
                  filtersRequest: listDataUtils.filteredValues(filtersValues, true),
                  selectedData: notEmptyValues,
              }
            : {};

        onApply(globalFilters);

        initializeValues(values, false, true);

        this.setState({ values }, cb);
    };

    /**
     * Устанавливаем текущий таб
     */
    handleSetTab = (currentTab: string, navigation: string[] = [], options: Record<string, any> = {}) => {
        this.setState(
            {
                currentTab,
                searchValue: options.withResetSearchValue ? this.state.searchValue : '',
                selectedList: [],
                activeListElement: [],
            },
            () =>
                this.setComputeData(() => {
                    const ids = this.getNavigationIds(navigation);

                    this.setState({
                        selectedList: ids.slice(0, ids.length - 1),
                        activeListElement: ids,
                    });
                }),
        );
    };

    /**
     * Выбираем элемент из списка
     */
    handleSelectListItem = (id: number, isToggle: boolean, parentOffsets: number[]) => {
        let selectedList = [...this.state.selectedList];
        let activeListElement = parentOffsets;

        if (isToggle) {
            const isSelected = selectedList.includes(id);
            selectedList = isSelected ? selectedList.filter((i) => i !== id) : [...selectedList, id];
            activeListElement = isSelected ? [] : activeListElement;
        }

        this.setState({
            selectedList,
            activeListElement,
        });
    };

    /**
     * Меняем поисковый запрос
     */
    handleChangeSearch = (e: Record<string, any>, searchValue: string) => {
        this.setState({ searchValue }, () =>
            this.setComputeData(() => {
                const { computeData } = this.state;

                const selectedList = searchValue
                    ? computeData.data.filter((item) => !item.parent_id).map((item) => item.id)
                    : [];

                this.setState({ selectedList, activeListElement: [] });
            }),
        );
    };

    /**
     * Очищаем значение активного элемента
     */
    handleClear = (item: Record<string, any>) => {
        const { changeValueFields, values } = this.props;

        if (Object.keys(values).filter((key) => item[key] === true)) {
            changeValueFields({ ...values, ...item });
        }
    };

    /**
     * Удаляем выбранный элемент
     */
    handleRemoveValue = (filterName: string = '') => {
        const { handleApply } = this;
        const { removeField, isSavedFilterApplied } = this.props;

        const isOnlyState = isSavedFilterApplied;

        removeField(filterName);
        handleApply(() => {}, isOnlyState, filterName);
    };

    /**
     * Поиск ID для выбора элемента методов навигации
     */
    getNavigationIds = (navigation: string[] = []): number[] => {
        const { computeData } = this.state;

        let parentId = null;

        return navigation.map((name) => {
            const findItem = computeData.data.find(
                (dataItem) => dataItem.name === name && dataItem.parent_id === parentId,
            );

            if (findItem) {
                parentId = findItem.id;
            }
            return findItem.id;
        });
    };

    /**
     * Поиск цепочки (родитель, дети) из IDs по имени элемента
     */
    getIdsByName = (nameOrId: string | number | boolean, computeData: IComputeData): number[] => {
        const result = [];

        const recursion = (curNameOrId) => {
            const curItem = computeData.data.find((item) => item.name === curNameOrId || item.id === curNameOrId);

            result.push(curItem.id);

            if (curItem.parent_id) {
                recursion(curItem.parent_id);
            }
        };

        recursion(nameOrId);

        return result.reverse();
    };

    getFilterAttr = (filterName: string) => {
        const { currentTab } = this.state;
        const listFiltersAttr = attributesData.filters.listFilters;

        return [listFiltersAttr.attrName, filterName, listFiltersAttr[currentTab]];
    };

    /**
     * Высчитываем данные для рендера
     */
    setComputeData = (cb = () => {}) => {
        const { searchValue } = this.state;
        const { getFilterData, data } = this.props;

        let computeData = getFilterData ? getFilterData(this.props, this.state) : data;

        computeData = listDataUtils.filterBySearch(searchValue, computeData);

        this.setState({ computeData }, cb);
    };

    renderFiltersContent = () => {
        const {
            getDynamicData,
            values: actualValues,
            initialValues,
            dynamicData,
            reportName,
            isFiltersListOnly,
            isSearchDisabled,
        } = this.props;
        const { selectedList, activeListElement, computeData, searchValue } = this.state;
        const filtersAttr = attributesData.filters;

        return (
            <div className={dropdownStyle.listWrapper}>
                {!isSearchDisabled ? (
                    <Dropdown.Search
                        className={isFiltersListOnly ? dropdownStyle.searchWide : ''}
                        searchValue={searchValue}
                        onChangeSearch={this.handleChangeSearch}
                        dataAttrName={filtersAttr.attrName}
                        dataAttr={filtersAttr.searchInput}
                    />
                ) : null}
                <Dropdown.Content
                    data={computeData}
                    onSetTab={this.handleSetTab}
                    onSelectListItem={this.handleSelectListItem}
                    getFilterAttr={this.getFilterAttr}
                    selectedList={selectedList}
                    activeListElement={activeListElement}
                    values={actualValues}
                    initialValues={initialValues}
                    dynamicData={dynamicData}
                    getDynamicData={getDynamicData}
                    onClear={this.handleClear}
                    leftType="list"
                    rightType="custom"
                    reportName={reportName}
                    className={isFiltersListOnly ? dropdownStyle.listWithoutWrap : ''}
                />
            </div>
        );
    };

    renderActions = () => {
        const { reportName } = this.props;
        const filtersAttr = attributesData.filters;

        return (
            <Dropdown.Actions
                onClose={this.handleClose}
                onApply={this.handleApply}
                reportName={reportName}
                dataAttrName={filtersAttr.attrName}
                cancelBtnAttr={filtersAttr.cancelBtn}
                applyBtnAttr={filtersAttr.applyBtn}
            />
        );
    };

    render() {
        const { allFiltersData, reportName, isFiltersListOnly } = this.props;
        const { isOpened, currentTab, searchValue, values: applyValues } = this.state;
        const filtersAttr = attributesData.filters;

        return isFiltersListOnly ? (
            this.renderFiltersContent()
        ) : (
            <>
                <Anchor
                    allFiltersData={allFiltersData}
                    onOpen={this.handleOpen}
                    onRemoveValue={this.handleRemoveValue}
                    values={{ ...applyValues }}
                    reportName={reportName}
                />
                <Dropdown.Container isOpened={isOpened} onClose={this.handleClose} actions={this.renderActions()}>
                    <Dropdown.Tabs
                        onClose={this.handleClose}
                        onSetTab={this.handleSetTab}
                        searchValue={searchValue}
                        currentTab={currentTab}
                        tabs={[
                            {
                                key: 'popularity',
                                label: 'Популярные условия',
                                dataAttrName: filtersAttr.attrName,
                                dataAttr: filtersAttr.tabs,
                            },
                            {
                                key: 'all',
                                label: 'Все условия',
                                search: true,
                                initial: true,
                                dataAttrName: filtersAttr.attrName,
                                dataAttr: filtersAttr.tabs,
                            },
                        ]}
                    />
                    {this.renderFiltersContent()}
                </Dropdown.Container>
            </>
        );
    }
}

export default GlobalFiltersList;
