import { OrderIcon } from '@components/index';
import { sortOrders } from '@constants/sort';
import { ISort } from '@typings/table';
import cn from 'classnames';
import React, { FC, ReactNode } from 'react';
import s from '../../WidgetTable.pcss';

interface IProps {
    children: ReactNode;
    isHead?: boolean;
    notHiddenHeads?: boolean;
    className?: string;
    isBold?: boolean;
    sort?: ISort;
    dataAttrName?: string;
    dataAttrList?: string[];
    onChangeSort?: () => void;
}

const WidgetTableCell: FC<IProps> = ({
    children,
    isHead = false,
    notHiddenHeads = false,
    className = '',
    isBold = false,
    sort,
    dataAttrName,
    dataAttrList,
    onChangeSort,
}) => {
    const tag = isHead ? 'th' : 'td';
    const classNameCell = cn(className, s.cell, {
        [s.cellWithNotHiddenHeads]: notHiddenHeads,
        [s.cellBold]: isBold,
        [s.cellClickable]: typeof onChangeSort === 'function' || sort,
    });

    const orders = sort
        ? {
              asc: sort.order === sortOrders.ASC || false,
              desc: sort.order === sortOrders.DESC || false,
          }
        : null;

    const handleClickSort = () => {
        if (typeof onChangeSort === 'function') {
            onChangeSort();
        }
    };

    const renderChildren = (
        <div
            className={s.cellInner}
            onClick={handleClickSort}
            {...(dataAttrName && dataAttrList.length
                ? {
                      [`data-${dataAttrName}`]: dataAttrList.join('::'),
                  }
                : {})}
        >
            <div
                className={cn({
                    [s.cellContent]: !notHiddenHeads,
                })}
            >
                {children}
            </div>
            {sort && <OrderIcon orders={orders} onClick={handleClickSort} className={s.sort} />}
        </div>
    );

    return React.createElement(tag, { className: classNameCell }, renderChildren);
};

export default WidgetTableCell;
