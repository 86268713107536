import { Tooltip, IconArrowDown } from '@adtech/ui';
import cn from 'classnames';
import React from 'react';
import s from './MaterialPosition.pcss';

interface IProps {
    positionPrev?: number;
    positionCurrent?: number;
    positionDiff?: number;
}

const MaterialPosition: React.FC<IProps> = ({ positionPrev, positionCurrent, positionDiff }) => {
    const diff = positionDiff || positionCurrent - positionPrev;
    if (!diff) return <div className={s.materialPosition} />;

    const isUpPosition = diff > 0 || positionCurrent > positionPrev;
    const arrowClassNames = cn(s.arrow, {
        [s.arrowUp]: isUpPosition,
        [s.arrowDown]: !isUpPosition,
    });

    const content = diff > 0 ? `+${diff}` : String(diff);

    return (
        <div className={s.root}>
            <Tooltip title={content} placement="top">
                <span className={arrowClassNames}>
                    <IconArrowDown />
                </span>
            </Tooltip>
        </div>
    );
};

export default MaterialPosition;
