import { Alert, Button } from '@adtech/ui';
import useNotification from '@hooks/useNotification';
import { INotification } from '@typings/notification';
import { IRootSlice } from '@typings/rootSlice';
import { storagesUtils } from '@utils/index';
import React, { useEffect, useState, useRef } from 'react';
import { useSelector } from 'react-redux';

import s from './PopupNotification.pcss';

interface IProps {
    notificationProp?: INotification | null;
}

const PopupNotification: React.FC<IProps> = ({ notificationProp }) => {
    const [isOpened, setOpenNotify] = useState(false);
    const isFirstRender = useRef(true);

    let notification = useSelector((state: IRootSlice) => state.globalSlice.notification);
    const userId = useSelector((state: IRootSlice) => state.userSlice.id);

    if (notificationProp) notification = notificationProp;

    const { clearNotification } = useNotification();

    const { storageKey } = notification || {};

    /**
     * useEffect следит за изменениями notification и
     * чтобы контент не просто подменился, а сработала
     * анимация, добавляем setTimeout.
     */
    useEffect(() => {
        let timer;

        if (notification?.body && !isFirstRender.current) {
            setOpenNotify(false);

            timer = setTimeout(() => {
                setOpenNotify(true);
            }, 500);
        } else {
            setOpenNotify(false);
            isFirstRender.current = false;
        }

        return () => clearTimeout(timer);
    }, [notification]);

    /**
     * Если нужен колбек именно только по закрытию на крестик
     */
    const closeNotification = () => {
        clearNotification();

        // Если указан параметр-ключ в localStorage, то используем его для скрытия уведомления
        if (storageKey) {
            storagesUtils.set(storageKey, 'true', userId);
        }
    };

    const renderMessage = (
        <>
            {notification?.body && <div className={s.content}>{notification.body}</div>}
            {notification?.actionButton && (
                <Button type="link" onClick={notification?.onAction} className={s.button}>
                    {notification.actionButton}
                </Button>
            )}
        </>
    );

    /**
     * Добавлен к видимым компонентам isOpened чтобы
     * во время анимации закрытия не было видно переходящего контента
     */
    return (
        (notification?.body && isOpened && (
            <Alert
                className={s.root}
                message={renderMessage}
                closable={notification.showClose}
                onClose={closeNotification}
            />
        )) ||
        null
    );
};

export default PopupNotification;
