import { IItemCustom } from '@typings/dropdown';
import React from 'react';
import { List, ListItemNested } from '../components/List';
import { ListCustomItem } from './ListCustomItem';

interface IProps {
    data: IItemCustom[];
    dataAttrName?: string;
    onSelect?: () => void;
}

export const ListCustom: React.FC<IProps> = ({ data, dataAttrName = '', onSelect }) => {
    const renderListItem = (item) => {
        const { depth, children, title } = item;

        if (children && children.length) {
            return <ListItemNested data={children} title={title} depth={depth} renderItem={renderListItem} />;
        }

        return <ListCustomItem {...item} dataAttrName={dataAttrName} onSelectItem={onSelect} />;
    };

    return <List data={data} renderItem={renderListItem} dataAttrName={dataAttrName} />;
};

export default ListCustom;
