import { IMetric } from '@configs/metrics';
import { Metrics } from '@typings/metrics';

export const metricsDict: Record<string, IMetric> = {
    views: {
        title: 'Просмотры',
        tooltip: 'Количество просмотров за последние 24 часа',
    },
    visitors: {
        title: 'Посетители',
        tooltip: 'Количество уникальных посетителей за последние 24 часа',
    },
};

export const availableMetrics: Metrics[] = ['views', 'visitors'];
