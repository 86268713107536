import * as React from 'react';

declare const window: IWindow;
declare const grecaptcha;

interface IWindow extends Window {
    grecaptcha: {
        render;
        reset;
    };
}

interface IProps {
    elementID: string;
    siteKey: string;
    callback;
    onloadCallback?;
    theme: 'light' | 'dark';
    hl: string;
    size: 'normal' | 'compact';
}

interface IState {
    ready: boolean;
}

function loadCaptcha() {
    const script = document.createElement('script');
    script.async = true;
    script.defer = true;
    script.src = 'https://www.google.com/recaptcha/api.js';
    document.body.appendChild(script);
}

const isReady = () =>
    typeof window !== 'undefined' &&
    typeof window.grecaptcha !== 'undefined' &&
    typeof window.grecaptcha.render === 'function';

export class GReCaptcha extends React.Component<IProps, IState> {
    static defaultProps = {
        sitekey: '',
        callback: () => {
            /**/
        },
        onloadCallback: undefined,
        theme: 'light',
        hl: 'ru',
        size: 'normal',
        elementID: 'g-recaptcha',
    };

    readyCheckInterval = null;

    captcha = null;

    constructor(props) {
        super(props);

        this.state = {
            ready: isReady(),
        };
    }

    componentDidMount() {
        const { ready } = this.state;
        if (ready) {
            this.renderCaptcha();
        } else {
            loadCaptcha();
        }

        if (!ready && window !== undefined) {
            this.readyCheckInterval = setInterval(this.updateReadyState, 1000);
        }
    }

    componentDidUpdate(prevProps, prevState) {
        const { ready } = this.state;

        if (ready && !prevState.ready) {
            this.renderCaptcha();
        }
    }

    componentWillUnmount() {
        if (this.readyCheckInterval) {
            clearInterval(this.readyCheckInterval);
        }
    }

    updateReadyState = () => {
        if (isReady()) {
            this.setState({
                ready: true,
            });

            clearInterval(this.readyCheckInterval);
        }
    };

    reset = () => {
        const { ready } = this.state;
        if (ready && this.captcha !== null) {
            grecaptcha.reset(this.captcha);
        }
    };

    renderCaptcha() {
        const { hl, size, theme, siteKey, callback, elementID, onloadCallback } = this.props;

        this.captcha = grecaptcha.render(elementID, {
            sitekey: siteKey,
            callback,
            theme,
            hl,
            size,
        });

        if (onloadCallback) {
            onloadCallback();
        }
    }

    render() {
        const { elementID } = this.props;

        return <div id={elementID} />;
    }
}
