import { Chart } from '@adtech/charts';
import Dropdown from '@components/Dropdown';
import attributesData from '@configs/attributesData';
import { ChartColors, ChartTypes } from '@configs/graph';
import { GroupBy } from '@configs/group';
import dateConstants from '@constants/date';
import { DateType } from '@typings/date';
import { DataArrayType } from '@typings/graph';
import { Metrics } from '@typings/metrics';
import { ICategories } from '@typings/projects';
import { Difference } from '@typings/widgets';
import { graphUtils, measuresUtils, widgetUtils, dateUtils } from '@utils/index';

import cn from 'classnames';
import React, { useState } from 'react';
import { Cell, Row } from '../Table';

import s from './Widget.pcss';

interface IProps {
    data?: DataArrayType | DataArrayType[];
    title?: string;
    metrics?: Metrics[];
    graph?: DataArrayType[];
    categories?: ICategories[];
    onChangeCategory: (newValue: string) => void;
    updateCategory?: (categoryId: string) => void;
    name?: string;
    ratingCategoryId?: string;
    onlyDiff?: boolean;
    difference?: Difference[];
    period?: DateType[];
    groupBy?: GroupBy;
}

const Total: React.FC<IProps> = ({
    data = [],
    title = '',
    metrics = [],
    graph = [],
    categories = [],
    ratingCategoryId = null,
    updateCategory = () => {},
    onChangeCategory,
    onlyDiff = false,
    difference = [],
    period,
    groupBy,
}) => {
    const [categoryId, setCategoryId] = useState(null);

    if (categories.length && ratingCategoryId !== null && ratingCategoryId !== categoryId) {
        setCategoryId(ratingCategoryId);
    }

    const handleChangeCategory = async (value) => {
        if (value === categoryId) return;
        await updateCategory(value);
        onChangeCategory(value);
    };

    const getChartData = (metricName: Metrics) =>
        graphUtils.getData(ChartTypes.line, {
            data: graph,
            measuresForGraphs: metrics,
            activeMeasure: metricName,
            title: 'line',
        });

    const getGraphColor = (diff: string) => [diff === 'up' ? ChartColors[3] : ChartColors[8]];

    // Рендер выпадающего списка с категориями
    const renderSelect = () => {
        if (!categories?.length) return null;

        const dropdownData = categories.map((item) => ({
            title: item.name,
            value: item.themeId,
        }));

        const dashboardAttr = attributesData.dashboard;
        const dataAttrAnchor = [dashboardAttr.totals.attrName, dashboardAttr.totals.ratingCategory];
        const dataAttrList = [dashboardAttr.totals.attrName, dashboardAttr.totals.setRatingCategory];

        return (
            <Dropdown
                type="selectSingle"
                data={dropdownData}
                dataInitialSelected={categoryId}
                onApply={handleChangeCategory}
                dataAttrName={dashboardAttr.attrName}
                dataAttrAnchor={dataAttrAnchor}
                dataAttrList={dataAttrList}
            />
        );
    };

    // Рендер заголовка колонки
    const renderTitle = (metric: Metrics) => {
        switch (metric) {
            case 'position':
                return renderSelect();
            default:
                return measuresUtils.getTitle(metric, 'dashboard');
        }
    };

    // Рендер разницы
    const renderDiff = (key: number) => {
        let diff: (string | number)[] = difference.length ? difference[key] : [];
        let result = null;

        if (diff.length) {
            const direction = widgetUtils.getDiffDirection(diff);
            const className = cn(s.topRowItem, {
                [s.difference]: diff.length,
                [s.up]: direction === 'up',
                [s.down]: direction === 'down',
            });

            diff = diff.map((item) => {
                let resultOfMap = item;

                if (Number(item) === item) {
                    resultOfMap = widgetUtils.prepareDiffData(item, metrics[key]);
                }

                return resultOfMap;
            });

            result = <div className={className}>{diff.join('')}</div>;
        }

        return result;
    };

    // Рендер числа
    const renderValue = (metric: DataArrayType, key: number) => {
        const num = metric as unknown as number;
        const value = widgetUtils.digitalsStringify(num, metrics[key]);

        return (
            <Cell key={key} className={cn(s.topRow, s.totalContentItem)}>
                {value}
            </Cell>
        );
    };

    // Рендер графика
    const renderGraph = (metric: DataArrayType, key: number) => {
        const diff = difference.length ? difference[key] : [];
        const metricName = metrics[key];

        const direction = widgetUtils.getDiffDirection(diff);

        const customColors = getGraphColor(direction);
        const chartData = getChartData(metricName);

        // TODO: вынести отдельно выше widgets, чтобы не дублировать
        let [dateStart, dateEnd] = period;
        const { isLiteralDate } = dateUtils;
        // TODO: приводим literal дату к формату YYYY-MM-DD
        if (isLiteralDate(String(dateStart)) || isLiteralDate(String(dateEnd))) {
            [dateStart, dateEnd] = dateUtils.convertDates(dateStart, dateEnd);
        }
        // TODO: приводим дату к iso формату, чтобы у highcharts не было проблем с таймзоной
        const periodFormatted: [string, string] = [
            dateUtils.getDateWithFormat(dateStart, dateConstants.DATE_ISO_FORMAT),
            dateUtils.getDateWithFormat(dateEnd, dateConstants.DATE_ISO_FORMAT),
        ];
        const graphData = graphUtils.prepareChartData(chartData, periodFormatted, groupBy);

        const chart = (
            <div className={cn(s.topRowItem, s.topRowGraph)}>
                <Chart
                    type={ChartTypes.simpleLine}
                    data={graphData}
                    sizes={[60, 20]}
                    colors={customColors}
                    dataKey="date"
                    tooltipLargeData
                />
            </div>
        );

        return (
            <Cell className={cn(s.topRow, s.totalContentItem)} key={key}>
                <div className={s.totalGraph}>
                    {!onlyDiff ? chart : null}
                    {renderDiff(key)}
                </div>
            </Cell>
        );
    };

    // Рендер ячейки
    const renderCell = (metric: DataArrayType, key: number) => (
        <Cell key={key} className={s.totalContentItem}>
            <div className={s.totalContentGroup}>
                <Row size="normal" extraNarrow className={s.totalContentTitle}>
                    {renderTitle(metrics[key])}
                </Row>
                <Row size="big" responsiveSize className={s.totalContentValue}>
                    {renderValue(metric, key)}
                </Row>
            </div>
            <div className={s.totalContentGroup}>
                {graph.length > 0 && <Row className={s.totalContentGraph}>{renderGraph(metric, key)}</Row>}
            </div>
        </Cell>
    );

    return (
        <div className={s.totalContent} key={title}>
            <div className={s.totalContentColumns}>{data.map((metric, key) => renderCell(metric, key))}</div>
        </div>
    );
};

export default Total;
