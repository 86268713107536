import { Tooltip, Loader, Table } from '@adtech/ui';
import Dropdown from '@components/Dropdown';
import TableShowMoreRow from '@components/ProjectsTable/components/TableShowMoreRow';
import ReportMessage from '@components/ReportMessage';

import { dimensionsDict } from '@configs/dimensions';
import { GoalFormConditionTypes } from '@configs/goals/goalForm';
import { goalTableActionsConfig } from '@configs/goals/goalTable';
import { operationsReverseCnf } from '@configs/operations';
import useConversions from '@hooks/useConversions';
import IconCircleMinus from '@images/svg/icons/icon-minus.svg';
import IconCirclePlus from '@images/svg/icons/icon-plus.svg';
import IconSettings from '@images/svg/icons/icon-settings.svg';

// import { metricsDict } from '@configs/metrics';
import { IConditionFilter, IGoalsConditionData, IGoalsRelatedConversion, IGoalsTableData } from '@typings/goals';
import React, { useState } from 'react';

import s from './GoalsTable.pcss';

interface IProps {
    tableRequest: boolean;
    deleteRequest: boolean;
    tableData: IGoalsTableData[];
    totals: number;
    api: TAPI;
}

enum ENodes {
    conversions = 'conversions',
    conditions = 'conditions',
}

const GoalsTable: React.FC<IProps> = ({ tableRequest, deleteRequest, tableData, totals, api }) => {
    const tableRows = tableData?.length || 0;
    const tableLimit = 10;

    const [expandedConditions, setExpandedConditions] = useState({});
    const [expandedConversions, setExpandedConversions] = useState({});

    const { api: conversionsAPI } = useConversions();

    const isDemoProject = api?.isDemoProject() ?? false;

    const expandButtonHandler = (id: number, key: string) => {
        const func = key === ENodes.conversions ? setExpandedConversions : setExpandedConditions;
        const stateNodes = key === ENodes.conversions ? expandedConversions : expandedConditions;

        func((nodes) => ({ ...nodes, [id]: !stateNodes[id] }));
    };

    const renderExpandButton = (nodeId: number, key: string) => {
        const stateNodes = key === ENodes.conversions ? expandedConversions : expandedConditions;
        const expand = stateNodes[nodeId];

        return (
            <button
                className={s.tableConditionExpand}
                onClick={() => expandButtonHandler(nodeId, key)}
                type="button"
                data-testid="showHide-icon"
            >
                {expand ? <IconCircleMinus /> : <IconCirclePlus />}
            </button>
        );
    };

    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const renderFilter = (filter: IConditionFilter, type: GoalFormConditionTypes) => {
        const { key, op, value, valuesTitles } = filter;
        // const dict = (type === GoalFormConditionTypes.metric) ? metricsDict : dimensionsDict;
        const dict = dimensionsDict;
        const isEq = ['=', 'multilike', 'include'].includes(op);
        const isRange = op === 'range';

        let val = '';
        if (valuesTitles?.length) {
            // Конвертация названий дименшинов
            val = valuesTitles.join(', ');
        } else if (Array.isArray(value)) {
            // Конвертация значения, если это массив (напр., URl)
            val = value.join(isRange ? '-' : ', ');
        } else {
            val = value;
        }

        // Обрезка длинных значений для подсказки
        const tooltipVal = val.length > 300 ? `${val.slice(0, 300).trim()}...` : val;
        const operationsText = operationsReverseCnf[op] ?? '';

        return (
            <div className={s.tableConditionItem} key={key}>
                <span className={s.tableFilterLabel}>
                    {dict[key]?.title}: {isEq ? '' : `${operationsText} `}
                </span>
                <Tooltip className={s.tableFilterText} title={tooltipVal}>
                    <span className={s.tableFilterTextInner}>{val}</span>
                </Tooltip>
            </div>
        );
    };

    const renderFilters = (filters: IConditionFilter[], type: GoalFormConditionTypes): React.ReactNode => (
        <div className={s.tableCondition}>{filters.map((filter) => renderFilter(filter, type))}</div>
    );

    const renderRelatedConversion = (conversion: IGoalsRelatedConversion) => (
        <a className={s.tableLink} href={conversionsAPI.getLinkToConversion(conversion.id)}>
            {conversion.title}
        </a>
    );

    const renderRelatedConversions = (conversions: IGoalsRelatedConversion[], nodeId: number) => {
        if (!conversions?.length) return null;

        if (conversions.length === 1) return renderRelatedConversion(conversions[0]);

        return (
            <>
                <div className={s.tableConditionTitle}>
                    {renderExpandButton(nodeId, ENodes.conversions)}
                    <span>Показать все конверсии&nbsp;({conversions.length})</span>
                </div>
                {expandedConversions[nodeId]
                    ? conversions.map((conversion) => renderRelatedConversion(conversion))
                    : null}
            </>
        );
    };

    const renderConditions = (conditions: IGoalsConditionData, nodeId: number): React.ReactNode => {
        if (!conditions) return null;

        const { filters, type } = conditions;

        if (filters.length <= 2) return renderFilters(filters, type);

        return (
            <>
                <div className={s.tableConditionTitle}>
                    {renderExpandButton(nodeId, ENodes.conditions)}
                    <span>Показать все условия&nbsp;({filters.length})</span>
                </div>
                {expandedConditions[nodeId] ? renderFilters(filters, type) : null}
            </>
        );
    };

    // Рендер значения в ячейке с тултипом
    const renderCell = (text: string, withTooltip: boolean = true) => {
        const cellContent = <div className={s.tableText}>{text}</div>;
        if (!withTooltip) return cellContent;
        return (
            <Tooltip key={text} title={text}>
                {cellContent}
            </Tooltip>
        );
    };

    // TODO: после определения с дизайном заменить на компонент ZeroState @adtech/ui
    const renderEmptyText = <ReportMessage className={s.tableNoResults} message="Нет данных для отображения" />;

    // Рендер ячейки с настройками
    const renderSettings = (id, is_protected, access, email) => {
        if (isDemoProject) return null;

        return (
            <Dropdown
                dataTestId="dropdown-container"
                type="listCustom"
                isShowArrow={false}
                anchorPrefixIcon={<IconSettings />}
                isAnchorButton={false}
                anchorClassName={s.tableSettingsIcon}
                data={api.getGoalsTableActionsList(
                    goalTableActionsConfig,
                    tableData,
                    id,
                    is_protected,
                    access,
                    email,
                    conversionsAPI,
                )}
            />
        );
    };

    // Рендер футера таблицы
    const renderFooter = () =>
        tableRows < totals ? (
            <TableShowMoreRow
                limit={tableLimit}
                viewedTableRows={tableRows}
                tableRowsTotal={totals}
                getProjectsTableOffset={api.getTableOffset}
            />
        ) : null;

    const tableColumns = [
        {
            title: 'Название',
            dataIndex: 'title',
            className: s.tableTitle,
            render: (title) => renderCell(title),
        },
        {
            title: 'Связанные конверсии',
            dataIndex: 'conversions',
            render: ({ conversions, id }) => renderRelatedConversions(conversions, id),
        },
        {
            title: 'Условия',
            dataIndex: 'conditions',
            render: ({ conditions, id }) => renderConditions(conditions, id),
        },
        {
            title: 'Возможность редактирования',
            dataIndex: 'is_protected',
            className: s.tableProtected,
            render: (is_protected) => renderCell(is_protected, false),
        },
        {
            title: 'Тип доступа',
            dataIndex: 'access',
            render: (access) => renderCell(access, false),
        },
        {
            title: 'Владелец',
            dataIndex: 'email',
            className: s.tableEmail,
            render: (email) => renderCell(email),
        },
        {
            title: '',
            dataIndex: 'settings',
            className: s.tableSettings,
            render: ({ id, is_protected, access, email }) => renderSettings(id, is_protected, access, email),
        },
    ];

    const prepareDataForRender = (item: IGoalsTableData) => {
        const { id, title, conditions, conversions, is_protected, access, email } = item || {};
        const protectedText = is_protected ? 'Нет' : 'Да';
        const accessText = access === 'own' ? 'Личный' : 'Публичный';

        return {
            key: id,
            title,
            conversions: { conversions, id },
            conditions: { conditions, id },
            is_protected: protectedText,
            access: accessText,
            email,
            settings: {
                id,
                is_protected,
                access,
                email,
            },
        };
    };

    return (
        <div className={s.root}>
            <Loader loading={tableRequest || deleteRequest} className={s.loader}>
                {tableData && (
                    <Table
                        data-testid="table-container"
                        className={s.table}
                        columns={tableColumns}
                        dataSource={tableData.map((item) => prepareDataForRender(item))}
                        locale={{ emptyText: renderEmptyText }}
                        pagination={false}
                        {...(tableRows < totals ? { footer: renderFooter } : {})}
                    />
                )}
            </Loader>
        </div>
    );
};

export default GoalsTable;
