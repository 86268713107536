import { publisherUId } from '@hydra/top100-components/helpers';

class Top100Counter {
    setAuthId = (userId: number, userEmail: string) => {
        window.top100Counter?.updateOptions({
            email: userEmail || null,
        });
        window.top100CounterSber?.updateOptions({
            email: userEmail || null,
        });

        window.top100Counter?.syncUserId(userId || null);
        window.top100CounterSber?.syncUserId(userId || null);
    };

    setPubId = () => {
        const pubId = publisherUId('');

        window.top100Counter?.updateOptions({
            pub_scope: 'stat.top100.rambler.ru',
            pub_id: pubId || '',
        });

        window.top100CounterSber?.updateOptions({
            pub_scope: 'stat.top100.rambler.ru',
            pub_id: pubId || '',
        });
    };

    trackEvent = (...args) => {
        if (typeof window.top100Counter?.trackEvent === 'function') {
            window.top100Counter.trackEvent(...args);
        }

        if (typeof window.top100CounterSber?.trackEvent === 'function') {
            window.top100CounterSber.trackEvent(...args);
        }
    };
}

export default new Top100Counter();
