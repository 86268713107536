import { Input, IconSearch } from '@adtech/ui';
import cn from 'classnames';
import React, { ChangeEvent, SyntheticEvent, useEffect, useState } from 'react';

import s from './Dropdown.pcss';

interface IProps {
    searchValue?: string;
    placeholder?: string;
    dataAttr?: string;
    dataAttrName?: string;
    className?: string;
    onChangeSearch?: (e: SyntheticEvent, value: string) => void;
}

const Search: React.FC<IProps> = ({
    searchValue,
    placeholder = '',
    dataAttr = '',
    dataAttrName = '',
    className = '',
    onChangeSearch = () => {},
}) => {
    const [searchVal, setSearchValue] = useState('');
    const searchClassName = cn(s.search, className);

    useEffect(() => {
        setSearchValue(searchValue);
    }, [searchValue]);

    const handleChangeSearch = (event: ChangeEvent<HTMLInputElement>) => {
        const value = event.target.value;
        setSearchValue(value);

        if (onChangeSearch) onChangeSearch(null, value);
    };

    return (
        <div className={searchClassName}>
            <Input
                suffix={<IconSearch />}
                value={searchVal || ''}
                onChange={handleChangeSearch}
                placeholder={placeholder || 'Искать по разделам'}
                {...(dataAttrName && dataAttr ? { [`data-${dataAttrName}`]: dataAttr } : {})}
                data-testid="search-input"
            />
        </div>
    );
};

export default Search;
