import { ChartTypes } from '@configs/graph';
import { sortOrders } from '@constants/sort';
import { IReportConfig } from '@typings/reports';

const reportConfig: IReportConfig = {
    reportTitle: 'Собственные события',
    tableDataType: 'dynamic',
    defaultSort: {
        name: 'events_count',
        order: sortOrders.DESC,
    },
    numberOfGraphs: 'two',
    defaultGraphs: [
        ChartTypes.line,
        ChartTypes.line,
    ],
    graphDefaultMetrics: [
        'events_count',
        'visits',
    ],
    tableDefaultMetrics: [
        'events_count',
        'visits',
        'visitors',
    ],
    availableMetrics: [
        'events_count',
        'visits',
        'visitors',
        'uid',
        'rambler_id',
    ],
    isShowTotals: true,
    availableTools: {
        numberOfGraphs: true,
        periodPicker: true,
        sample: true,
        activeSample: true,
        filters: true,
        savedFilters: true,
        detail: true,
    },
    reportHint: {
        message: 'Для формирования отчёта необходимо передавать собственные события вашего сайта в счётчик Статистики от SberAds с '
            + 'помощью JavaScript API (метод \'trackEvent\'). <br />'
            + 'Собственные события могут использоваться для проведения различных исследований, '
            + 'к примеру для а/б или мультивариантных тестирований, проверки сценариев поведения, '
            + 'разметки ключевых действий на сайте.',
        link: 'https://ads.sber.ru/help/otpravka-sobstvennykh-sobytii',
    },
};

export default reportConfig;
