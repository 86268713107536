import attributesData from '@configs/attributesData';
import useModalPopup from '@hooks/useModalPopup';
import IconDownload from '@images/svg/icons/icon-download.svg';
import { ReportName } from '@typings/reports';

import cn from 'classnames';
import React from 'react';
import { useParams } from 'react-router-dom';

import s from './Csv.pcss';

interface IProps {
    requestReportCsv: (reportName: ReportName, params: IUrlParams) => void;
    reportName: ReportName;
    userEmail: string;
    isDisabledCsv?: boolean;
}

const Csv: React.FC<IProps> = ({ requestReportCsv, reportName, userEmail, isDisabledCsv }) => {
    const { openModalPopup } = useModalPopup();
    const params: IUrlParams = useParams();

    const requestCsv = () => {
        if (isDisabledCsv) return;

        requestReportCsv(reportName, params);

        openModalPopup('Csv', {
            userEmail,
        });
    };

    return (
        <div className={s.csvWrapper}>
            <span
                onClick={requestCsv}
                className={cn(s.csv, { [s.csvDisabled]: isDisabledCsv })}
                data-newreport={`${attributesData.newreport.csvBtn}::${reportName}`}
            >
                <IconDownload className={s.csvIcon} />
                Скачать отчёт
            </span>
        </div>
    );
};

export default Csv;
