import { Loader } from '@adtech/ui';
import Conversion from '@components/Conversion';
import TableShowMoreRow from '@components/ProjectsTable/components/TableShowMoreRow';
import ReportMessage from '@components/ReportMessage';

import { EConversionTitles } from '@configs/conversions';
import { ConversionFormType } from '@configs/conversions/conversionForm';
import { GroupBy } from '@configs/group';
import useModalPopup from '@hooks/useModalPopup';
import { IConversionDictData, IConversionTableData } from '@typings/conversions';
import { DateType } from '@typings/date';
import { TChartDataItem } from '@typings/graph';
import React from 'react';

import s from './ConversionsTable.pcss';

interface IProps {
    tableRequest: boolean;
    deleteRequest: boolean;
    tableData: IConversionTableData[];
    getTableOffset: (offset: number) => void;
    totals: number;
    conversionDict: Record<string, IConversionDictData>;
    api: TAPI;
    dateStart: DateType;
    dateEnd: DateType;
    groupBy: GroupBy;
}

const ConversionsTable: React.FC<IProps> = ({
    tableRequest,
    deleteRequest,
    tableData,
    getTableOffset,
    totals,
    conversionDict,
    api,
    dateStart,
    dateEnd,
    groupBy,
}) => {
    const { updateConversion, formatConversionGraphData } = api;
    const tableRows = tableData?.length || 0;
    const tableLimit = 10;

    const { openModalPopup, closeModalPopup } = useModalPopup();

    const editConversion = (id: number) => {
        const conversionData = tableData.find((item) => item.id === id);

        openModalPopup('ConversionForm', {
            type: ConversionFormType.edit,
            closePopup: closeModalPopup,
            data: conversionData,
            api,
        });
    };

    const renderConversion = (item: IConversionTableData) => {
        const { id, title, description, metric, is_favorite, access, email, numerator_goal_id, denominator_goal_id } =
            item;
        const conversionData = conversionDict && conversionDict[id];
        const conversionTotals = [
            {
                title: EConversionTitles.conversion,
                value: conversionData?.conversion?.totals,
                format: 'percent',
            },
            {
                title: EConversionTitles.goal,
                value: conversionData?.goal?.totals,
                format: 'number',
            },
        ];
        const graphData = formatConversionGraphData(conversionData) as TChartDataItem[];

        return (
            <Conversion
                id={id}
                key={id}
                title={title}
                description={description}
                graphData={graphData}
                totals={conversionTotals}
                loading={conversionData?.request}
                metric={metric}
                dateStart={dateStart}
                dateEnd={dateEnd}
                groupBy={groupBy}
                isFavorites={is_favorite}
                onClickFavorites={() => updateConversion(id, { is_favorite: !is_favorite }, true)}
                onClickEdit={editConversion}
                ownerEmail={email}
                access={access}
                goalsIds={[numerator_goal_id, denominator_goal_id]}
                api={api}
            />
        );
    };

    return (
        <div className={s.root}>
            <Loader loading={tableRequest || deleteRequest} className={s.loader}>
                {tableRows ? (
                    <>
                        {tableData.map((item) => renderConversion(item))}
                        {tableRows < totals ? (
                            <div>
                                <TableShowMoreRow
                                    limit={tableLimit}
                                    viewedTableRows={tableRows}
                                    tableRowsTotal={totals}
                                    getProjectsTableOffset={getTableOffset}
                                />
                            </div>
                        ) : null}
                    </>
                ) : (
                    <ReportMessage className={s.tableNoResults} message="Нет данных для отображения" />
                )}
            </Loader>
        </div>
    );
};

export default ConversionsTable;
