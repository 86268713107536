import { Tabs, Tooltip, Loader } from '@adtech/ui';
import ChartSwitcher from '@components/ChartSwitcher';
import Dropdown from '@components/Dropdown';
import attributesData from '@configs/attributesData';
import { ChartFormatterValues, ChartTypes } from '@configs/graph';
import { GroupBy } from '@configs/group';
import { widgetConfig, WidgetTypes } from '@configs/widget';
import widgetMessages from '@configs/widgetMessages';
import { sortOrders } from '@constants/sort';
import IconSettings from '@images/svg/icons/icon-settings.svg';
import { DateType } from '@typings/date';
import { Dimensions } from '@typings/dimensions';
import { DataArrayType, DataArrayTypeComplex, GraphsDict, IDataItemType } from '@typings/graph';
import { Metrics } from '@typings/metrics';
import { ICategories } from '@typings/projects';
import { ReportName } from '@typings/reports';
import { ISort } from '@typings/table';
import { IUpdateWidgetParams, WidgetSize, Difference } from '@typings/widgets';
import { measuresUtils } from '@utils/index';
import cn from 'classnames';
import React, { useState } from 'react';

import Count from './Count';
import Graph from './Graph';
import Header from './Header';
import Table from './Table';
import Total from './Total';

import s from './Widget.pcss';

type Measures = Metrics | Dimensions;

interface IErrorDataItem {
    text: string;
    linkText?: string;
    needAccess?: boolean;
    target?: string;
    url?;
}

export type HeightType = 'small' | 'large';

interface IProps {
    reportName?: ReportName;
    projectId?: number;
    type?: WidgetTypes;
    name?: string;
    title?: string;
    hintTitle?: string;
    className?: string;
    status?: string; // для определения текста сообщения при ошибке
    accessLevel?: number; // для определения текста сообщения при ошибке
    loading?: boolean;
    error?: boolean;
    reqError?: boolean;
    size?: WidgetSize; // указывает в Grids ширину колонки, которую займет виджет
    attrCnfName?: string;
    onUpdate?: (widgetName: string, updateParams: IUpdateWidgetParams) => void; // вызывается при обновлении виджета
    titleClickHandler?: () => void; // вызывается при клике на заголовок виджета
    dimensions?: Dimensions[]; // активные дименшины
    metrics?: Metrics[]; // активные метрики
    allMetrics?: Metrics[]; // все метрики
    allDimensions?: Dimensions[]; // все дименшины
    data?: DataArrayType | DataArrayType[] | DataArrayTypeComplex[] | IDataItemType[];
    dataTotals?: [string, number][];
    selected?: Measures;
    isDisabledSettings?: boolean; // не отображать кнопку настроек 'шестеренка'
    isDisabledTabs?: boolean; // не отображать вкладки
    tabsNum?: number; // максимальное количество вкладок
    inRating?: boolean; // для определения текста сообщения при ошибке
    heightType?: HeightType; // минимальная высота
    formatterValues?: ChartFormatterValues; // формат значений (для графиков)
    widgetCustomSettingsElement?: () => JSX.Element; // рендер кастомных настроек/элементов

    // Total
    difference?: Difference[];
    graph?: DataArrayType[];
    ratingCategoryId?: string;
    updateCategory?: (categoryId: string) => void;
    categories?: ICategories[];
    onlyDiff?: boolean;

    // Count
    count?: number;
    label?: string;

    // Graph
    hideLegend?: boolean;
    dataKeys?: string[];
    titleAliases?: Record<string, string>;
    heightChart?: number;
    period?: DateType[];
    defaultMetric?: Metrics; // метрика по кот. обсчитывается дименшин в графиках
    isShowChartSwitcher?: boolean;
    graphType?: ChartTypes;
    groupBy?: GroupBy;
    disabledGraphs?: GraphsDict;
    enableAllGraphTypes?: boolean;
    handleGraphSelect?: (widgetName: string, options: { graphType: ChartTypes }) => void;

    // Table
    isShowOneMetric?: boolean;
    isShowAllMetrics?: boolean;
    labels?: string[];
    isDisabledSort?: boolean;
    tableTitle?: string;
    notHiddenHeads?: boolean; // если параметр true - не скрывать длинные заголовки за многоточием
    emptyTableTitleAlias?: string;
    sort?: ISort;
}

const Widget: React.FC<IProps> = ({
    className,
    title,
    tableTitle,
    notHiddenHeads,
    emptyTableTitleAlias,
    type,
    metrics = null,
    defaultMetric,
    dimensions = null,
    allMetrics = null,
    allDimensions = null,
    sort,
    hideLegend,
    data,
    dataTotals,
    selected = null,
    loading,
    error,
    projectId,
    name,
    status,
    onUpdate,
    groupBy,
    isDisabledSettings,
    isDisabledTabs,
    isDisabledSort,
    period,
    count,
    label,
    hintTitle,
    heightChart,
    ratingCategoryId,
    updateCategory,
    categories,
    onlyDiff,
    reportName,
    isShowChartSwitcher,
    graphType,
    disabledGraphs,
    enableAllGraphTypes,
    handleGraphSelect,
    labels = [],
    tabsNum = 3,
    reqError,
    accessLevel,
    titleClickHandler,
    titleAliases,
    isShowAllMetrics,
    attrCnfName = 'media',
    inRating = false,
    difference,
    graph,
    dataKeys,
    heightType = null,
    isShowOneMetric = false,
    widgetCustomSettingsElement,
    formatterValues,
}) => {
    const [measuresActive, setMeasuresActive] = useState([]);
    const [selectedMeasure, setSelectedMeasure] = useState(null);

    const isDataExists = data?.length || dataTotals?.length;
    // Отображение текста в случае ошибки, отсутствия данных или доступа для просмотра (рейтинг)
    const isShowError = !loading && (error || (!isDataExists && type !== WidgetTypes.count));

    if ((metrics?.length || dimensions?.length) && selected !== selectedMeasure) {
        setMeasuresActive(metrics || dimensions);
        setSelectedMeasure(selected);
    }

    const attrCnf = attributesData[attrCnfName];
    const attrName = attrCnf.attrName;
    const widgetsCnf = attrCnf.widgets[name] || {};
    const widgetsAttr = attrCnf.widgets.attrName;
    const configWidgetType = widgetConfig[type];
    const measureType = metrics ? 'metrics' : 'dimensions';
    const allMeasures = metrics ? allMetrics : allDimensions;
    const allMeasuresLength = allMeasures?.length || 0;

    // Отображение кнопки с настройками ('шестеренка')
    const isShowSettings = configWidgetType.isShowSettings && allMeasuresLength > tabsNum && !isDisabledSettings;

    // Отображение вкладок
    const isShowTabs = configWidgetType.isShowTabs && allMeasuresLength > 1 && !isDisabledTabs;

    const widgetClassNames = cn(s.widget, className, s[`widget_${type}`], {
        [s[`widget_${heightType}`]]: heightType,
    });

    // Выполняем при обновлении виджета
    const handleUpdate = (params: IUpdateWidgetParams) => {
        if (typeof onUpdate === 'function') onUpdate(name, params);
    };

    // Переключение вкладки
    const onChangeTab = (newValue: Metrics) => {
        if (selectedMeasure === newValue) return;

        setSelectedMeasure(newValue);

        handleUpdate({
            [measureType]: [newValue],
            measuresActive,
            selected: newValue,
            sort: { name: newValue, order: sortOrders.DESC },
            groupBy,
        });
    };

    // Изменение категории
    const onChangeCategory = (newValue: string) => {
        handleUpdate({ category: newValue });
    };

    // Выполняется после выбора метрик или дименшинов в настройках
    const onSubmitDrop = (measures) => {
        const newSelected = !measures.includes(selectedMeasure) ? measures[0] : selectedMeasure;

        setSelectedMeasure(newSelected);
        setMeasuresActive(measures);

        handleUpdate({
            [measureType]: measures,
            measuresActive: measures,
            selected: newSelected,
            sort: { name: newSelected, order: sortOrders.DESC },
        });
    };

    // Изменение сортировки
    const handleChangeSort = (visibleMetrics: Metrics[], activeMetric: Metrics, activeSort: ISort) => {
        const newValue = {
            asc: sortOrders.DESC,
            desc: sortOrders.ASC,
        };

        const resultOrder = activeMetric !== activeSort.name ? sortOrders.DESC : newValue[activeSort.order];

        handleUpdate({
            [measureType]: visibleMetrics,
            selected: activeMetric,
            sort: { name: activeMetric, order: resultOrder },
        });
    };

    const handlerGraphSelect = (activeGraph: ChartTypes) => {
        if (typeof handleGraphSelect === 'function') {
            handleGraphSelect(name, { graphType: activeGraph });
        }
    };

    // Рендеринг вкладок
    const tabsOptions = measuresActive.map((measure) => {
        const measureTitle = measuresUtils.getTitle(measure, reportName);
        const widgetName = widgetsCnf?.widgetName;
        const dataAttr = `${widgetsAttr}::${widgetName}::${measure}`;
        const attrProp = {
            [`data-${attrName}`]: dataAttr,
        };

        return {
            value: measure,
            label: (
                <div key={measure} data-testid="segmented-button" {...attrProp} className={s.tabsItemWrapper}>
                    <Tooltip title={measureTitle} className={s.tabsItemText}>
                        {measureTitle}
                    </Tooltip>
                </div>
            ),
        };
    });

    // Рендеринг переключения типов графика
    const renderChartSwitcher = () => (
        <ChartSwitcher
            reportName={reportName}
            selectedGraph={graphType}
            activeMeasure={selected}
            disabledGraphs={disabledGraphs}
            onSelectGraph={handlerGraphSelect}
            enableAllGraphTypes={enableAllGraphTypes}
        />
    );

    // Рендеринг выпадающего списка с метриками или дименшинами
    const renderSettings = () => {
        const dataSettings = allMeasures.map((measure) => ({
            title: measuresUtils.getTitle(measure, null),
            value: measure,
        }));

        const widgetName = widgetsCnf?.widgetName || '';
        const settingsMetrics = attrCnf.widgets.settingsMetrics;
        const setMetricAttr = attrCnf.widgets.setMetricAttr;

        return (
            <Dropdown
                type="selectMultiple"
                data={dataSettings}
                dataInitialSelected={measuresActive}
                onApply={onSubmitDrop}
                anchorPrefixIcon={<IconSettings className={s.headerSettingsIcon} />}
                isShowArrow={false}
                anchorSize="small"
                params={{
                    limit: tabsNum,
                }}
                dataAttrList={[widgetsAttr, widgetName, setMetricAttr]}
                dataAttrName={attrCnf.attrName}
                dataAttrAnchor={[widgetsAttr, widgetName, settingsMetrics]}
                isAnchorButton
                isShowSelected={false}
            />
        );
    };

    // Рендеринг виджета
    const renderChildren = () => {
        switch (type) {
            case WidgetTypes.total:
                return (
                    <Total
                        onChangeCategory={onChangeCategory}
                        data={data as DataArrayType | DataArrayType[]} // FIXME: проверить тип
                        title={title}
                        metrics={metrics}
                        graph={graph}
                        categories={categories}
                        ratingCategoryId={ratingCategoryId}
                        updateCategory={updateCategory}
                        onlyDiff={onlyDiff}
                        difference={difference}
                        name={name}
                        period={period}
                        groupBy={groupBy}
                    />
                );
            case WidgetTypes.table:
                return (
                    <Table
                        data={data as DataArrayType | DataArrayType[]} // FIXME: проверить тип
                        tableTitle={tableTitle}
                        notHiddenHeads={notHiddenHeads}
                        emptyTableTitleAlias={emptyTableTitleAlias}
                        selected={selectedMeasure}
                        name={name}
                        metrics={metrics}
                        isDisabledSort={isDisabledSort}
                        onChangeSort={handleChangeSort}
                        sort={sort}
                        labels={labels}
                        dataTotals={dataTotals}
                        attrCnfName={attrCnfName}
                        reqError={reqError}
                        isShowAllMetrics={isShowAllMetrics}
                        isDisabledTabs={isDisabledTabs}
                        isShowOneMetric={isShowOneMetric}
                    />
                );
            case WidgetTypes.graph:
                return (
                    <Graph
                        graphType={graphType}
                        title={title}
                        data={data}
                        dataTotals={dataTotals}
                        dataKeys={dataKeys}
                        metrics={metrics}
                        dimensions={dimensions}
                        defaultMetric={defaultMetric}
                        period={period}
                        hideLegend={hideLegend}
                        groupBy={groupBy}
                        heightChart={heightChart}
                        name={name}
                        reqError={reqError}
                        selected={selectedMeasure}
                        titleAliases={titleAliases}
                        formatterValues={formatterValues}
                    />
                );
            case WidgetTypes.count: {
                return <Count count={count} label={label} hintTitle={hintTitle || ''} />;
            }
            default:
                return null;
        }
    };

    // Рендер ошибки виджета
    const renderError = () => {
        let dataError: IErrorDataItem = null;

        switch (name) {
            case 'rating': {
                const ratingStatus = status in widgetMessages.rating ? status : 'default';
                if (inRating && status !== 'dropped_out') {
                    dataError = widgetMessages.default;
                } else {
                    dataError = widgetMessages.rating[ratingStatus];
                }
                break;
            }
            case 'blockPageAnalytics': {
                dataError = widgetMessages.analytics;
                break;
            }
            default: {
                dataError = widgetMessages.default;
            }
        }

        const { url, text, target, linkText, needAccess } = dataError;
        const linkUrl = typeof url === 'function' ? url(projectId) : url;

        return (
            <div className={s.error}>
                {text && <span className={s.errorText}>{text}</span>}
                {linkUrl && (!needAccess || accessLevel === 0) && (
                    <a href={linkUrl} target={target || '_blank'} rel="noopener noreferrer" className={s.errorLink}>
                        {linkText}
                    </a>
                )}
            </div>
        );
    };

    return (
        <div className={widgetClassNames} data-testid={name && `${name}-container`}>
            <Loader loading={loading}>
                <div className={s.widgetInner}>
                    {title && (
                        <Header title={title} hintTitle={hintTitle || ''} titleClickHandler={titleClickHandler}>
                            <div className={s.headerButtons}>
                                {isShowChartSwitcher ? renderChartSwitcher() : null}
                                {isShowSettings ? renderSettings() : null}
                                {widgetCustomSettingsElement ? widgetCustomSettingsElement() : null}
                            </div>
                        </Header>
                    )}

                    {!isShowError ? (
                        <>
                            {isShowTabs && (
                                <Tabs
                                    type="default"
                                    data-testid="segmented-container"
                                    onChange={(value: Metrics) => onChangeTab(value)}
                                    options={tabsOptions}
                                />
                            )}
                            {renderChildren()}
                        </>
                    ) : (
                        renderError()
                    )}
                </div>
            </Loader>
        </div>
    );
};

export default Widget;
