/* eslint-disable max-len */
import { ChartTypes } from '@configs/graph';
import { sortOrders } from '@constants/sort';
import { IReportConfig } from '@typings/reports';

const reportConfig: IReportConfig = {
    reportTitle: 'Заголовки страниц',
    tableDataType: 'dynamic',
    tableTitle: 'Заголовок',
    defaultSort: {
        name: 'visits',
        order: sortOrders.DESC,
    },
    numberOfGraphs: 'two',
    defaultGraphs: [
        ChartTypes.line,
        ChartTypes.bar,
    ],
    graphDefaultMetrics: [
        'page_views',
        'visits',
    ],
    tableDefaultMetrics: [
        'page_views',
        'visits',
        'visitors',
    ],
    availableMetrics: [
        'visits',
        'page_views',
        'visitors',
        'uid',
        'rambler_id',
        // 'bounces',
        // 'avg_industry_duration',
    ],
    titleAliases: {
        none: 'Без заголовка',
        '': 'Без заголовка',
    },
    isTableTitleSearch: true,
    isShowTotals: true,
    availableTools: {
        numberOfGraphs: true,
        periodPicker: true,
        sample: true,
        activeSample: true,
        filters: true,
        savedFilters: true,
        detail: true,
    },
    reportHint: {
        message: 'Заголовки страниц, которые были посещены за выбранный вами период. Указываются полные заголовки страниц. '
            + 'Есть возможность поиска по подстроке. Доступные показатели: посетители, визиты, просмотры, отказы, время на сайте,'
            + ' глубина просмотра, время на странице.',
    },
};

export default reportConfig;

