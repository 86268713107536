import modalNotifications from '@constants/modalNotifications';
import { checkAdBlock } from '@hydra/top100-components/helpers';
import { INotification } from '@typings/notification';
import { top100Counter } from '@utils/index';

export const setAdBlockNotification = (setNotification: (notification: INotification) => void) => {
    const check = async () => {
        const hasAdblock: boolean = await checkAdBlock();

        top100Counter.trackEvent('adblock', { status: hasAdblock ? 'yes' : 'no' });

        if (hasAdblock) setNotification(modalNotifications.adBlock);
    };

    check();
};
