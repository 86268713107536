import { PageHeader, Stepper } from '@adtech/ui';
import { steps } from '@constants/counterSteps';
import React, { FC } from 'react';
import s from './Header.pcss';

interface IProps {
    counterId?: number;
    step: number;
}

export const Header: FC<IProps> = ({ counterId, step }) => {
    const dataSteps = steps.map((item) => ({ title: item.title }));
    const isRestoreCounterStep = step === 3;
    let currentTitle;

    if (isRestoreCounterStep) {
        currentTitle = 'Восстановление доступа к счётчику';
    } else {
        currentTitle = counterId ? `Счётчик ${counterId} создан` : 'Создание нового счётчика';
    }

    return (
        <div className={s.root}>
            <div className={s.content}>
                <PageHeader
                    className={s.title}
                    title={currentTitle}
                    back={{
                        to: '/stat/projects',
                        title: 'Мои сайты',
                    }}
                />
                {isRestoreCounterStep ? (
                    <div className={s.description}>
                        Выберете счётчик, к которому вы хотите восстановить доступ.
                        <br />
                        Если вам нужен доступ к еще одному счётчику, пройдите процедуру восстановления заново.
                    </div>
                ) : (
                    <Stepper className={s.stepper} current={step - 1} items={dataSteps} />
                )}
            </div>
        </div>
    );
};
