import { Input } from '@adtech/ui';
import fieldDecorator from '@decorators/fieldDecorator';
import { IField, IMetaField } from '@typings/form';
import formUtils from '@utils/formUtils';
import React from 'react';
import s from '../FormCreateCounter.pcss';

interface IProps {
    field: IField;
    meta: IMetaField;
    getDefaultTitle: (url: string) => void;
}

const UrlComponent: React.FC<IProps> = ({ field, meta, getDefaultTitle }) => {
    const { onBlur, onClick, onChange, onFocus, value, name } = field || {};
    const isError = meta.invalid && meta.touched && !meta.focused;

    const handleBlur = (e) => {
        if (meta.valid) getDefaultTitle(e.target.value);
        onBlur(e);
    };

    return (
        <div className={s.formRow}>
            <span>Адрес сайта</span>
            <Input
                value={value}
                name={name}
                placeholder="http://"
                type="text"
                errorText={meta.errorText}
                onBlur={handleBlur}
                maxLength={200}
                onChange={onChange}
                onClick={onClick}
                onFocus={onFocus}
                status={isError ? 'error' : ''}
            />
        </div>
    );
};

const Url = fieldDecorator({
    rules: [
        { validate: formUtils.defaultRules.required },
        {
            validate: formUtils.defaultRules.maxLength(200),
            customErrorText: 'URL не должен превышать 200 символов',
        },
        { validate: formUtils.defaultRules.urlWithDomainZone },
        { validate: formUtils.defaultRules.urlWithProtocolAndParams },
    ],
    name: 'url',
})(UrlComponent);

export default Url;
