import { ImageCustomVars } from '@images/svg/reportPlaceholder/custom-vars';
import React from 'react';

export default {
    image: <ImageCustomVars />,
    // eslint-disable-next-line max-len
    link: 'https://ads.sber.ru/help/otpravka-sobstvennykh-sobytii',
    title: 'Собственные события — инструмент для разметки действий посетителей сайта',
    textBlocks() {
        return [
            'Определите целевые действия посетителей или условия А/Б-теста',
            'Настройте отправку параметров при совершении целевых действий в счётчик Статистики от SberAds',
            'Анализируйте поведение пользователей с помощью отчёта «Параметры визитов»',
        ];
    },
};
