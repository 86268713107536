import { IItemCustom } from '@typings/dropdown';
import cn from 'classnames';
import React from 'react';
import { Link } from 'react-router-dom';
import s from '../Dropdown.pcss';

interface IProps extends IItemCustom {
    dataAttrName?: string;
    onSelectItem?: () => void;
}

export const ListCustomItem: React.FC<IProps> = ({
    title = '',
    name,
    href,
    to,
    target = '_blank',
    isActive = false,
    onClick = () => {},
    dataAttrName = '',
    onSelectItem,
}) => {
    const classNames = cn(s.item, { [s.active]: isActive });

    const getDataAttrListItem = (id?: string) => {
        if (!dataAttrName) return null;
        return { [`data-${dataAttrName}`]: id };
    };

    const handleClick = () => {
        onClick();
        if (onSelectItem) onSelectItem();
    };

    if (href) {
        return (
            <a
                href={href}
                target={target || '_blank'}
                rel="noopener noreferrer"
                className={classNames}
                onClick={handleClick}
                {...getDataAttrListItem(name)}
            >
                {title}
            </a>
        );
    }
    if (to) {
        return (
            <Link to={to} className={classNames} onClick={handleClick} {...getDataAttrListItem(name)}>
                {title}
            </Link>
        );
    }
    return (
        <span className={classNames} onClick={handleClick} {...getDataAttrListItem(name)}>
            {title}
        </span>
    );
};

export default ListCustomItem;
