import reportsParamsConfig from '@configs/reportsParams';
import reportsConstants from '@constants/reports';
import useMedia from '@hooks/useMedia';
import useReport from '@hooks/useReport';
import { IAvailableTools } from '@typings/availableTools';
import { ReportName } from '@typings/reports';
import { IRootSlice } from '@typings/rootSlice';
import { useSelector } from 'react-redux';

export const useAvailableTools = (reportName: ReportName, baseAvailableTools: IAvailableTools) => {
    const projectId = useSelector((state: IRootSlice) => state.projectsSlice.current.id);
    const logged = useSelector((state: IRootSlice) => state.userSlice.logged);
    const fullAccess = useSelector((state: IRootSlice) => state.projectsSlice.current.permissions.can_edit_project);

    const availableTools = { ...baseAvailableTools };

    // Если пользователь не залогинен и у него нет полного доступа к отчёту
    // Отключаем фильтры
    if (!logged && !fullAccess) {
        availableTools.filters = false;
        availableTools.savedFilters = false;
    }

    // Отключаем сплиты для неавторизованных юзеров
    if (!logged) {
        availableTools.splits = false;
    }

    // Отключаем для демо-сайта сплиты и сохраненные фильтры
    if (projectId === reportsConstants.DEMO_PROJECT_ID) {
        if (baseAvailableTools.filters) {
            // включаем фильтры для демосайта (если они доступны для отчёта)
            availableTools.filters = true;
        }

        if (baseAvailableTools.splits) {
            availableTools.splits = true;
        }

        availableTools.savedFilters = false;
    }

    return availableTools;
};

export const useAvailableToolsReport = (reportName: ReportName) => {
    const {
        reportsUtils: { getParamFromConfig },
        api: { checkRatingAccess },
    } = useReport();

    const tableData = useSelector((state: IRootSlice) => state.tableSlice.tableData);
    const tableRequest = useSelector((state: IRootSlice) => state.tableSlice.tableRequest);
    const globalLoading = useSelector((state: IRootSlice) => state.globalSlice.globalLoading);
    const projectStatus = useSelector((state: IRootSlice) => state.projectsSlice.current.status);

    const baseAvailableTools = getParamFromConfig(reportName, 'availableTools');
    const isRating = reportName === 'rating';

    const noDataLoaded = !Object.keys(tableData.dict).length && !tableRequest;

    const availableTools = useAvailableTools(reportName, baseAvailableTools);

    const needsPlaceholder =
        !globalLoading &&
        ((reportsParamsConfig.behaviorReports.includes(reportName) && noDataLoaded) ||
            (isRating && !checkRatingAccess(projectStatus)));

    // Если нет данных и существует заглушка у отчёта, то скрываем
    // количество графиков и детализацию
    if (needsPlaceholder) {
        availableTools.numberOfGraphs = false;
        availableTools.detail = false;
    }

    return availableTools;
};

export const useAvailableToolsMedia = (reportName: ReportName) => {
    const {
        reportsUtils: { getParamFromConfig },
    } = useMedia();
    const baseAvailableTools = getParamFromConfig(reportName, 'availableTools');

    return useAvailableTools(reportName, baseAvailableTools);
};
