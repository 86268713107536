import KeyMetrics from '@components/KeyMetrics';
import ReportPlaceholder from '@components/ReportPlaceholder';
import ReportTable from '@components/ReportTable';
import mediaConstants from '@constants/media';
import useMedia from '@hooks/useMedia';
import { useTableMedia } from '@hooks/useTable';
import { IMediaContainer, ReportNameMedia, TRenderReportTools } from '@typings/media';
import { IRootSlice } from '@typings/rootSlice';
import { IDataTable } from '@typings/table';
import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';

interface IProps {
    reportName: ReportNameMedia;
    projectId: number;
    renderReportTools: TRenderReportTools;
    onChangeUpdater?: (newParams) => void;
}

const MediaTop: React.FC<IMediaContainer> = ({ reportName, projectId, renderReportTools, onChangeUpdater }: IProps) => {
    const { api: apiMedia, reportsUtils } = useMedia();

    const { api: apiTable, tableUtils } = useTableMedia();

    const api = { ...apiMedia, ...apiTable };
    const utils = { ...reportsUtils, ...tableUtils };

    const [isPageActive, setPageActive] = useState(true);
    const [isResetTimer, setResetTimerValue] = useState(false);

    const { getParamFromConfig } = reportsUtils;

    const totals = useSelector((state: IRootSlice) => state.mediaSlice.totals);
    const totalsRequest = useSelector((state: IRootSlice) => state.mediaSlice.totalsRequest);
    const isAutoUpdate = useSelector((state: IRootSlice) => state.mediaSlice.isAutoUpdate);

    const tableRequest = useSelector((state: IRootSlice) => state.tableSlice.tableRequest);
    const tableData = useSelector((state: IRootSlice) => state.tableSlice.tableData);
    const allMetrics = useSelector((state: IRootSlice) => state.tableSlice.allMetrics);
    const metrics = useSelector((state: IRootSlice) => state.tableSlice.metrics);
    const metricsWithStates = useSelector((state: IRootSlice) => state.tableSlice.metricsWithStates);
    const tableFilters = useSelector((state: IRootSlice) => state.tableSlice.tableFilters);
    const totalRows = useSelector((state: IRootSlice) => state.tableSlice.totalRows);
    const orderBy = useSelector((state: IRootSlice) => state.tableSlice.orderBy);
    const currentPage = useSelector((state: IRootSlice) => state.tableSlice.currentPage);
    const trafficLabels = useSelector((state: IRootSlice) => state.tableSlice.trafficLabels);

    const globalFilters = useSelector((state: IRootSlice) => state.globalSlice.globalFilters);
    const periodDetail = useSelector((state: IRootSlice) => state.globalSlice.periodDetail);

    const keyMetricsHint = getParamFromConfig(reportName, 'keyMetricsHint');
    const showTotals = getParamFromConfig(reportName, 'isShowTotals', true);
    const tableFixedDimensions = getParamFromConfig(reportName, 'tableFixedDimensions');
    const isShowTableButtons = getParamFromConfig(reportName, 'isShowTableButtons');
    const tableLimit = getParamFromConfig(reportName, 'tableLimit');

    const needsPlaceholder = !totalsRequest && !Object.keys(totals).length;

    // Обогащаем данные элементов таблицы позициями
    const prepareTableData = (data: IDataTable) => {
        const newData = JSON.parse(JSON.stringify(data));
        const { dict, map } = newData;
        const currId = mediaConstants.ROOT_NODE;

        if (!map[currId]) return newData;

        const { list } = map[currId];

        list.forEach((item, index) => {
            dict[item].position = (currentPage - 1) * tableLimit + index + 1;
        });

        return newData;
    };

    const updateMediaTop = () => {
        api.updateMediaTopTable((currentPage - 1) * tableLimit);
        api.updateTotals();
    };

    const handleChangePagination = (page: number) => {
        api.updateMediaTopTable(tableLimit * (page - 1));
        api.updateTotals();
        api.updateTableParams({ currentPage: page });
    };

    // Следим за видимостью страницы
    useEffect(() => {
        window.addEventListener('visibilitychange', () => setPageActive(!document.hidden));
        return () => {
            window.removeEventListener('visibilitychange', () => setPageActive(!document.hidden));
        };
    }, []);

    useEffect(() => {
        setResetTimerValue(!isResetTimer);
    }, [currentPage, globalFilters, metrics, periodDetail]);

    // Сбрасываем на 1 страничку если по текущей нет данных
    // На топе кол-во материалов может уменьшаться в реал тайме,
    // так как детализация все время смещается
    useEffect(() => {
        if (!tableData) return;

        const { dict, page } = tableData;

        if (!Object.keys(dict)?.length && page > 1) {
            api.updateTableParams({ currentPage: 1 });
            api.updateMediaTopTable(0);
        }
    }, [tableData]);

    useEffect(() => {
        onChangeUpdater({
            run: isAutoUpdate && isPageActive && !needsPlaceholder,
            disabled: needsPlaceholder,
            isResetTimer,
            onUpdate: updateMediaTop,
        });
    }, [isAutoUpdate, isResetTimer, isPageActive, needsPlaceholder]);

    return (
        <>
            {renderReportTools({ periodDetail })}
            {needsPlaceholder ? (
                <ReportPlaceholder reportName={reportName} projectId={projectId} />
            ) : (
                <>
                    <KeyMetrics
                        data={totals}
                        hintTitle={keyMetricsHint}
                        totalsRequest={(!Object.keys(totals).length && totalsRequest) || false}
                        reportName={reportName}
                    />
                    {tableData && (
                        <ReportTable
                            reportName={reportName}
                            api={api}
                            utils={utils}
                            metrics={metrics}
                            allMetrics={allMetrics}
                            metricsWithStates={metricsWithStates}
                            tableTitle="Рейтинг"
                            orderBy={orderBy}
                            totals={[]}
                            tableData={prepareTableData(tableData)}
                            titleFilter=""
                            tableFilters={tableFilters}
                            totalRows={totalRows}
                            tableRequest={tableRequest}
                            showTotals={showTotals}
                            isShowTableButtons={isShowTableButtons}
                            isPagination
                            labels={trafficLabels}
                            useSelectedLines={false}
                            constants={mediaConstants}
                            dimensions={tableFixedDimensions}
                            limit={tableLimit}
                            currentPage={currentPage}
                            isAnimated
                            onChangePagination={handleChangePagination}
                        />
                    )}
                </>
            )}
        </>
    );
};

export default MediaTop;
