import { compose } from '@decorators/tools';
import { globalActions } from '@redux/slices/global';
import { ISnackbar } from '@typings/snackbar';
import { connect } from 'react-redux';

import { SnackbarNotification } from './SnackbarNotification';

interface IStateProps {
    snackbar: ISnackbar | null;
}

const mapStateToProps = ({ globalSlice }): IStateProps => ({
    snackbar: globalSlice.snackbar || null,
});

const mapDispatchToProps = (dispatch): Record<string, () => void> => ({
    clearSnackbar() {
        dispatch(globalActions.clearSnackbar());
    },
});

export default compose(connect(mapStateToProps, mapDispatchToProps))(SnackbarNotification);
