interface ICounterCode {
    useAnalytic: boolean;
    attrAnalytic: string;
    type: number;
    id: string;
    useUserId: boolean;
    useHashes: boolean;
}

export class SettingsPageUtils {
    constructor() {
        const self = SettingsPageUtils;

        return Object.freeze({
            getCounterCode: self.getCounterCode,
            __private: {},
        });
    }

    public static getCounterCode({ useAnalytic, attrAnalytic, type, id, useUserId, useHashes }: ICounterCode): string {
        let additionalCodePart = '';
        let divForBanner = '';
        let element = '';

        if (useAnalytic && attrAnalytic !== '') {
            additionalCodePart = `,
            attributes_dataset: [ "${attrAnalytic}" ]`;
        }

        if (useHashes) {
            additionalCodePart += `,
            trackHashes: true`;
        }

        if (useUserId) {
            additionalCodePart += `,
            user_id: null`;
        }

        if (type === 1) {
            divForBanner = `<!-- Top100 (Kraken) Widget -->
<span id="top100_widget"></span>
<!-- END Top100 (Kraken) Widget -->

`;
            element = `,
            element: 'top100_widget'`;
        }

        return `${divForBanner}<!-- Top100 (Kraken) Counter -->
<script>
    (function (w, d, c) {
    (w[c] = w[c] || []).push(function() {
        var options = {
            project: ${id}${element}${additionalCodePart},
        };
        try {
            w.top100Counter = new top100(options);
        } catch(e) { }
    });
    var n = d.getElementsByTagName("script")[0],
    s = d.createElement("script"),
    f = function () { n.parentNode.insertBefore(s, n); };
    s.type = "text/javascript";
    s.async = true;
    s.src =
    (d.location.protocol == "https:" ? "https:" : "http:") +
    "//st.top100.ru/top100/top100.js";

    if (w.opera == "[object Opera]") {
    d.addEventListener("DOMContentLoaded", f, false);
} else { f(); }
})(window, document, "_top100q");
</script>
<noscript>
  <img src="//counter.rambler.ru/top100.cnt?pid=${id}" alt="Топ-100" />
</noscript>
<!-- END Top100 (Kraken) Counter -->`;
    }
}

export default new SettingsPageUtils();
