import { Switch, Alert } from '@adtech/ui';
import CounterBlocks from '@components/CounterBlocks';
import fieldDecorator from '@decorators/fieldDecorator';

import React from 'react';

import s from './SettingsCounter.pcss';

interface IProps {
    field: any;
    counterType: boolean;
}

export const SettingsCounter = ({ field, counterType }: IProps) => (
    <CounterBlocks.Wrapper rootClassName={s.root} withoutDelimiter>
        <div className={s.switch}>
            <Switch checked={field.value} onChange={(value) => field.onChange(null, value)} />
            <div className={s.switchLabel}>Отображать виджет</div>
        </div>
        <Alert type="warning" message="При отключении функции плашка не будет отображаться на сайте" showIcon />
        {counterType && <CounterBlocks.LogoColorChooser />}
    </CounterBlocks.Wrapper>
);

export default fieldDecorator({
    type: 'boolean',
    name: 'counterType',
})(SettingsCounter);
