import Grid from '@components/Grid';
import KeyMetrics from '@components/KeyMetrics';
import ReportPlaceholder from '@components/ReportPlaceholder';
import ReportTable from '@components/ReportTable';
import Subheader from '@components/Subheader';
import Widget from '@components/Widgets';
import { widgetsMediaConfig } from '@configs/media/widgets';
import { WidgetTypes } from '@configs/widget';
import mediaConstants from '@constants/media';
import useMedia from '@hooks/useMedia';
import useModalPopup from '@hooks/useModalPopup';
import useNotification from '@hooks/useNotification';
import { useTableMedia } from '@hooks/useTable';
import { IMediaContainer, ReportNameMedia, TRenderReportTools } from '@typings/media';
import { IRootSlice } from '@typings/rootSlice';
import React from 'react';
import { useSelector } from 'react-redux';

interface IProps {
    reportName: ReportNameMedia;
    projectId: number;
    userId: number;
    renderReportTools: TRenderReportTools;
}

const MediaMaterialsSummary: React.FC<IMediaContainer> = ({
    reportName,
    projectId,
    userId,
    renderReportTools,
}: IProps) => {
    const { api: apiMedia, reportsUtils } = useMedia();

    const { api: apiTable, tableUtils } = useTableMedia();

    const api = { ...apiMedia, ...apiTable };
    const utils = { ...reportsUtils, ...tableUtils };

    const { getParamFromConfig } = reportsUtils;

    const totalsRequest = useSelector((state: IRootSlice) => state.mediaSlice.totalsRequest);
    const totals = useSelector((state: IRootSlice) => state.mediaSlice.totals);
    const trafficSources = useSelector((state: IRootSlice) => state.mediaSlice.trafficSources);
    const audience = useSelector((state: IRootSlice) => state.mediaSlice.audience);

    const groupBy = useSelector((state: IRootSlice) => state.globalSlice.groupBy);
    const dateStart = useSelector((state: IRootSlice) => state.globalSlice.dateStart);
    const dateEnd = useSelector((state: IRootSlice) => state.globalSlice.dateEnd);

    const tableRequest = useSelector((state: IRootSlice) => state.tableSlice.tableRequest);
    const tableData = useSelector((state: IRootSlice) => state.tableSlice.tableData);
    const allMetrics = useSelector((state: IRootSlice) => state.tableSlice.allMetrics);
    const metrics = useSelector((state: IRootSlice) => state.tableSlice.metrics);
    const metricsWithStates = useSelector((state: IRootSlice) => state.tableSlice.metricsWithStates);
    const tableFilters = useSelector((state: IRootSlice) => state.tableSlice.tableFilters);
    const totalRows = useSelector((state: IRootSlice) => state.tableSlice.totalRows);
    const orderBy = useSelector((state: IRootSlice) => state.tableSlice.orderBy);
    const trafficLabels = useSelector((state: IRootSlice) => state.tableSlice.trafficLabels);

    const tableHintContent = getParamFromConfig(reportName, 'tableHint');
    const keyMetricsHint = getParamFromConfig(reportName, 'keyMetricsHint');
    const showTotals = getParamFromConfig(reportName, 'isShowTotals', true);
    const tableFixedDimensions = getParamFromConfig(reportName, 'tableFixedDimensions');

    const { setNotification } = useNotification();
    const { openModalPopup } = useModalPopup();

    const { trafficSources: trafficSourcesDefaults, audience: audienceDefaults } = widgetsMediaConfig;

    const needsPlaceholder = !totalsRequest && !Object.keys(totals).length;

    return (
        <>
            {renderReportTools()}
            {needsPlaceholder ? (
                <ReportPlaceholder reportName={reportName} projectId={projectId} />
            ) : (
                <>
                    <KeyMetrics
                        data={totals}
                        hintTitle={keyMetricsHint}
                        totalsRequest={totalsRequest}
                        reportName={reportName}
                    />
                    <Grid>
                        <Widget
                            reportName={reportName}
                            period={[dateStart, dateEnd]}
                            groupBy={groupBy}
                            type={WidgetTypes.graph}
                            title="Источники переходов"
                            name="trafficSources"
                            isShowChartSwitcher
                            onUpdate={api.updateTrafficSources}
                            handleGraphSelect={api.updateWidgetParams}
                            size={2}
                            heightType="large"
                            {...trafficSourcesDefaults}
                            {...trafficSources}
                        />
                        <Widget
                            reportName={reportName}
                            period={[dateStart, dateEnd]}
                            groupBy={groupBy}
                            type={WidgetTypes.graph}
                            title="Аудитория"
                            name="audience"
                            isShowChartSwitcher
                            onUpdate={api.updateAudience}
                            handleGraphSelect={api.updateWidgetParams}
                            size={2}
                            heightType="large"
                            {...audienceDefaults}
                            {...audience}
                        />
                    </Grid>
                    <Subheader
                        reportName={reportName}
                        reportTitle="Рейтинг материалов"
                        projectId={projectId}
                        userId={userId}
                        hintContent={tableHintContent}
                        openModalPopup={openModalPopup}
                        setNotification={setNotification}
                    />
                    {tableData && (
                        <ReportTable
                            reportName={reportName}
                            api={api}
                            utils={utils}
                            metrics={metrics}
                            allMetrics={allMetrics}
                            metricsWithStates={metricsWithStates}
                            tableTitle="Название материала, URL"
                            orderBy={orderBy}
                            totals={[]}
                            tableData={tableData}
                            titleFilter=""
                            tableFilters={tableFilters}
                            totalRows={totalRows}
                            tableRequest={tableRequest}
                            showTotals={showTotals}
                            labels={trafficLabels}
                            useSelectedLines={false}
                            constants={mediaConstants}
                            dimensions={tableFixedDimensions}
                        />
                    )}
                </>
            )}
        </>
    );
};

export default MediaMaterialsSummary;
