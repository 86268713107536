import { createAsyncThunk } from '@reduxjs/toolkit';
import { IUpdateReportParams } from '@typings/reports';
import { ISavedTemplate } from '@typings/templates';
import fetchApi from '@utils/fetchApi';

export const getTemplates = createAsyncThunk('templatesSlice/getTemplates', async (params?: IUpdateReportParams) => {
    const { titleFilter: searchWord } = params || {};
    const queryParams = searchWord ? `?search=${searchWord}` : '';

    // eslint-disable-next-line no-return-await
    return await fetchApi({
        url: `/api/v1.0/user/constructor_templates${queryParams}`,
    });
});

export const updateTemplatesTable = createAsyncThunk(
    'templatesSlice/updateTemplatesTable',
    async ({ offset, searchWord }: { offset: number; searchWord?: string }) => {
        let queryParams = offset ? `?offset=${offset}` : '';
        queryParams += searchWord ? `&search=${searchWord}` : '';

        // eslint-disable-next-line no-return-await
        return await fetchApi({
            url: `/api/v1.0/user/constructor_templates${queryParams}`,
        });
    },
);

export const getSavedTemplate = createAsyncThunk(
    'templatesSlice/getSavedTemplate',
    async (id: number) =>
        // eslint-disable-next-line no-return-await
        await fetchApi({
            url: `/api/v1.0/constructor_template/${id}`,
        }),
);

export const saveTemplate = createAsyncThunk(
    'templatesSlice/saveTemplate',
    async (templateParams: ISavedTemplate) =>
        // eslint-disable-next-line no-return-await
        await fetchApi({
            url: '/api/v1.0/constructor_template',
            method: 'POST',
            body: { ...templateParams },
            metaInfo: { template: templateParams },
        }),
);

export const updateTemplate = createAsyncThunk(
    'templatesSlice/updateTemplate',
    async (templateParams: ISavedTemplate) =>
        // eslint-disable-next-line no-return-await
        await fetchApi({
            url: `/api/v1.0/constructor_template/${templateParams.id}`,
            method: 'PUT',
            body: { ...templateParams },
            metaInfo: { template: templateParams, isEdited: true },
        }),
);

export const deleteTemplate = createAsyncThunk(
    'templatesSlice/deleteTemplate',
    async (id: number) =>
        // eslint-disable-next-line no-return-await
        await fetchApi({
            url: `/api/v1.0/constructor_template/${id}`,
            method: 'DELETE',
            metaInfo: { id },
        }),
);
