import snackbarTypes from '@constants/snackbarTypes';
import useGoals from '@hooks/useGoals';
import useSnackbar from '@hooks/useSnackbar';
import { useAppDispatch } from '@hooks/useStore';
import { postClickActions } from '@redux/slices/postClick';
import { IRootSlice } from '@typings/rootSlice';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';

const usePostClick = () => {
    const params: IUrlParams = useParams();
    const projectId = params.projectId;
    const dispatch = useAppDispatch();
    const postClickSlice = useSelector((state: IRootSlice) => state.postClickSlice);
    const goalsSlice = useSelector((state: IRootSlice) => state.goalsSlice);
    const { getTable: getGoalsTable } = useGoals();
    const { setSnackbar } = useSnackbar();

    const api = {
        // Инициализация
        async init(): Promise<void> {
            if (!postClickSlice.counterIsCreated) api.getCounter();
            return Promise.resolve();
        },

        // Сброс
        resetCounterData(): void {
            dispatch(postClickActions.resetCounterData());
        },

        // Создать счетчик
        createCounter(): void {
            dispatch(postClickActions.createCounter(Number(projectId)))
                .unwrap()
                .then((res) => {
                    if (res?.errors || res?.yandex_errors) {
                        setSnackbar('Возникла непредвиденная ошибка. Мы уже работаем над этим', snackbarTypes.ERROR);
                    } else {
                        setSnackbar('Счётчик успешно подключён', snackbarTypes.SUCCESS);
                    }
                });
        },

        // Получить счетчик
        getCounter(): void {
            dispatch(postClickActions.getCounter(Number(projectId)));
        },

        // Удалить счетчик
        deleteCounter(): void {
            dispatch(postClickActions.deleteCounter(Number(projectId)))
                .unwrap()
                .then((res) => {
                    if (res?.errors || res?.yandex_errors) {
                        setSnackbar('Возникла непредвиденная ошибка. Мы уже работаем над этим', snackbarTypes.ERROR);
                    } else {
                        setSnackbar('Счётчик успешно отключён', snackbarTypes.SUCCESS);
                    }
                });
        },

        linkCounter(id: number, token?: string): void {
            dispatch(postClickActions.linkCounter({ projectId, params: { id, token } }))
                .unwrap()
                .then((res) => {
                    if (res?.errors) {
                        setSnackbar('Возникла непредвиденная ошибка. Мы уже работаем над этим', snackbarTypes.ERROR);
                    } else if (res?.yandex_errors) {
                        setSnackbar('Права отсутствуют', snackbarTypes.ERROR);
                    } else {
                        setSnackbar('Права подтверждены', snackbarTypes.SUCCESS);
                    }
                });
        },

        // Обновление привязки целей
        updateGoals(): void {
            const goalsTableData = goalsSlice.tableData;
            const selectedGoalsIds = postClickSlice.selectedGoalsIds;
            const result = [];

            goalsTableData.forEach((item) => {
                // Проверяем по id на наличие в спике выбранных
                const isSelected = selectedGoalsIds.find((id) => id === item.id);

                result.push({
                    id: item.id,
                    send: !!isSelected,
                });

                return result;
            });

            dispatch(postClickActions.updateGoals({ projectId, params: { goals: result } }))
                .unwrap()
                .then((res) => {
                    if (res?.errors?.length) {
                        const errorMessage = res.errors[0].error;
                        setSnackbar(errorMessage, snackbarTypes.ERROR);
                        return;
                    }

                    getGoalsTable({ showCommonGoals: true });
                });
        },

        // Обновление списка выбранных целей в store
        updateSelectedGoals(listIds: number[] = []): void {
            dispatch(postClickActions.updateSelectedGoals({ listIds }));
        },
    };

    return api;
};

export default usePostClick;
