import ReportChart from '@components/ReportChart';

import { ChartTypes } from '@configs/graph';
import dateConstants from '@constants/date';
import { Metrics } from '@typings/metrics';
import { IRootSlice } from '@typings/rootSlice';
import DateUtils from '@utils/date';
import { measuresUtils } from '@utils/index';

import React from 'react';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';

import s from './Chart.pcss';

interface IProps {
    api;
    utils;
    isForceEmpty?: boolean;
}

const Chart: React.FC<IProps> = ({ api, utils, isForceEmpty }) => {
    const params: IUrlParams = useParams();
    const { reportName } = params;

    const { updateReport } = api;

    const graphs = useSelector((state: IRootSlice) => state.graphSlice.graphs);
    const graphRequest = useSelector((state: IRootSlice) => state.graphSlice.graphRequest);
    const graphRequests = useSelector((state: IRootSlice) => state.graphSlice.graphRequests);
    const selectedLines = useSelector((state: IRootSlice) => state.graphSlice.selectedLines);
    const graphData = useSelector((state: IRootSlice) => state.graphSlice.graphData);
    const graphTotals = useSelector((state: IRootSlice) => state.graphSlice.graphTotals);
    const currentGraphsTotals = useSelector((state: IRootSlice) => state.graphSlice.currentGraphsTotals);
    const graphCustomTitle = useSelector((state: IRootSlice) => state.graphSlice.graphCustomTitle);

    const allMetrics = useSelector((state: IRootSlice) => state.tableSlice.allMetrics);
    const metrics = useSelector((state: IRootSlice) => state.tableSlice.metrics);
    const tableData = useSelector((state: IRootSlice) => state.tableSlice.tableData);

    const groupBy = useSelector((state: IRootSlice) => state.globalSlice.groupBy);
    let dateStart = useSelector((state: IRootSlice) => state.globalSlice.dateStart);
    let dateEnd = useSelector((state: IRootSlice) => state.globalSlice.dateEnd);

    const { isLiteralDate } = DateUtils;
    // TODO: приводим literal дату к формату YYYY-MM-DD
    if (isLiteralDate(String(dateStart)) || isLiteralDate(String(dateEnd))) {
        [dateStart, dateEnd] = DateUtils.convertDates(dateStart, dateEnd);
    }
    // TODO: приводим дату к iso формату, чтобы у highcharts не было проблем с таймзоной
    const dates: [string, string] = [
        DateUtils.getDateWithFormat(dateStart, dateConstants.DATE_ISO_FORMAT),
        DateUtils.getDateWithFormat(dateEnd, dateConstants.DATE_ISO_FORMAT),
    ];

    const simpleGraph =
        (utils.isSummary && utils.isSummary(reportName)) || (utils.isConstructor && utils.isConstructor(reportName));

    const { newGraphData, newGraphTotals } = utils.computeGraphDataAndTotals(
        selectedLines,
        graphData,
        graphTotals,
        tableData,
        simpleGraph,
    );

    const disabledGraphsFromReportConfig = utils.getParamFromConfig(reportName, 'disabledGraphs');

    const showMetricsSwitcher = utils.getParamFromConfig(reportName, 'showMetricsSwitcher', true);

    const onChangeActiveChartParam = (
        activeMetricParam: Partial<Metrics>,
        activeGraphParam: Partial<ChartTypes>,
        chartOrder: number,
    ) => {
        const newGraphs = [...graphs];
        const oldGraph = graphs[chartOrder];
        const newGraph: { key: Partial<Metrics>; value: Partial<ChartTypes> } = { ...oldGraph };

        if (activeMetricParam) {
            newGraph.key = activeMetricParam;

            const availableGraphs = measuresUtils.getAvailableGraphs(
                activeMetricParam,
                disabledGraphsFromReportConfig,
                reportName,
            );

            if (!availableGraphs.find((item) => item === newGraph.value)) {
                newGraph.value = availableGraphs[0];
            }
        }

        if (activeGraphParam) {
            newGraph.value = activeGraphParam;
        }

        newGraphs[chartOrder] = newGraph;

        updateReport('graph', {
            updateParams: { graphs: newGraphs },
            meta: { chartOrder, excessActionPrefix: `graph_${chartOrder}` },
        });
    };

    return (
        <ReportChart
            reportName={reportName}
            graphData={newGraphData}
            graphTotals={newGraphTotals}
            graphs={graphs}
            graphCustomTitle={graphCustomTitle}
            currentGraphsTotals={currentGraphsTotals}
            availableMetrics={allMetrics}
            className={s.chart}
            period={dates}
            metrics={metrics}
            groupBy={groupBy}
            onChangeActiveChartParam={onChangeActiveChartParam}
            graphRequest={graphRequest}
            graphRequests={graphRequests}
            selectedLines={selectedLines}
            disabledGraphs={disabledGraphsFromReportConfig}
            utils={utils}
            showMetricsSwitcher={showMetricsSwitcher}
            isForceEmpty={isForceEmpty}
        />
    );
};

export default Chart;
