import { ReportType } from '@typings/reports';

interface IItem {
    path?: string;
    name: string;
    title?: string;
    id?: string;
}

class UrlUtils {
    parseQuery = (search: string) => {
        const query = search.replace('?', '');
        let result = {};

        if (!query) return result;

        try {
            result = JSON.parse(`{"${query.replace(/&/g, '","').replace(/=/g, '":"')}"}`, (key, value) =>
                key === '' ? value : decodeURIComponent(value),
            );
        } catch (e) {} // eslint-disable-line no-empty

        return result;
    };

    objectToQuery = (object: Record<string, any>) => {
        const encode = encodeURIComponent;
        const result = [];

        Object.keys(object).forEach((key) => {
            const item = object[key];

            switch (Object.prototype.toString.call(item)) {
                case '[object String]':
                case '[object Number]': {
                    return result.push(`${encode(key)}=${encode(item)}`);
                }
                // TODO: в данный момент нет параметров такого типа
                case '[object Array]': {
                    if (!item.length) return null;

                    const value = item.reduce((prev, val) => (prev ? `${prev},${val}` : `${val}`), '');

                    return result.push(`${encode(key)}=${value}`);
                }

                default:
                    return '';
            }
        });

        return result.join('&');
    };

    getDomain = (url: string) => {
        let domain;

        if (url.indexOf('://') > -1) {
            domain = url.split('/')[2];
        } else {
            domain = url.split('/')[0];
        }

        domain = domain.split(':')[0];

        return domain;
    };

    generateUrl = (projectId: number, item: IItem, reportType?: ReportType): string => {
        const name = item.name;
        const path = item.path;
        const id = item.id;
        const parentReportPath = reportType || 'report';

        // /projects
        const result = ['/stat/projects'];

        // /projects/{projectId}
        if (projectId) {
            result.push(String(projectId));
        }

        // /projects/{projectId}/{path} или /projects/{path}
        if (path) {
            result.push(path);
        }

        if (!path && projectId) {
            // /projects/{projectId}/new/{parentReportPath}/{name}
            if (projectId) result.push(`new/${parentReportPath}/${name}`);
        }

        if (id) result.push(id);

        return result.join('/');
    };
}

export default new UrlUtils();
