import React from 'react';
import s from '../ModalPopup.pcss';
import { PopupContent } from '../components/PopupContent';

export default {
    getContent(modalPopupParams: Record<string, () => void>) {
        const popupContent = (
            <PopupContent
                className={s.content}
                content="После восстановления счётчика статистика будет доступна.
                    Счётчик можно удалить из раздела «Мои сайты»"
            />
        );

        const okButton = {
            className: s.btnOk,
            type: 'default',
            children: 'Восстановить',
            onClick: modalPopupParams.okButtonHandler,
            closePopupOnClick: true,
        };

        const cancelButton = {
            className: s.btnCancel,
            type: 'dashed',
            children: 'Отмена',
            closePopupOnClick: true,
        };

        return {
            popupClassName: s.restoreCounter,
            title: 'Восстановить счётчик',
            content: popupContent,
            buttonContainerClassName: s.buttons,
            okButton,
            cancelButton,
        };
    },
};
