import { Tooltip, Button, IconCirclePlus, Chip } from '@adtech/ui';
import attributesData from '@configs/attributesData';
import { Operations, OperationsLiterals } from '@configs/operations';
import listDataConstants, { Op } from '@constants/listData';
import { ReportName } from '@typings/reports';
import { listDataUtils } from '@utils/index';
import React, { MouseEvent } from 'react';

import s from './Anchor.pcss';

interface IProps {
    reportName?: ReportName;
    allFiltersData?: any;
    values?: any;
    onOpen?: (e?: React.SyntheticEvent, activeItemName?: string) => void;
    onRemoveValue?: (filterName?: string) => void;
}

const Anchor: React.FC<IProps> = ({ reportName, allFiltersData, values, onOpen, onRemoveValue }) => {
    const showFilterAttr = attributesData.filters.showFilter;
    const filterAttr = `${showFilterAttr}::${reportName}`;
    // Конвертируем в массив, добавляя значения из конфига фильтров
    const valuesInArray = listDataUtils.convertValuesToArray(values, allFiltersData);
    // Фильтруем значения по их заполненности
    const filtersValues = listDataUtils.filteredValues(valuesInArray);

    // Клик по чипсу открывает форму с фильтрами
    const handleClick = (e: MouseEvent, filterName?: string) => {
        e.preventDefault();
        const target = e.target as Element;

        // Проверяем, что клик был не по иконке крестика
        if (target.closest('.SBA-close-button')) return;

        onOpen(e, filterName);
    };

    // Рендер отдельного значения фильтра и условия (и, или, не), если нужно
    const renderValue = ({ title, index, condition }: Record<string, any>) => {
        const textCondition = condition === 'exclude' || condition === 'multilikeand' ? ', ' : ' или ';

        return (
            <span key={title} className={s.filterValueTitle}>
                {index === 0 && condition === 'exclude' ? 'искл. ' : ''}
                {index > 0 && textCondition && textCondition}
                {title}
            </span>
        );
    };

    // Рендер значений с периодом
    const renderPeriodValues = (data: Record<string, any>, parentItem: Record<string, any>) => {
        const title = (
            <>
                {data.op === Operations.gt ? `${OperationsLiterals.gt} ` : ''}
                {data.op === Operations.lt ? `${OperationsLiterals.lt} ` : ''}
                {data.op === Op.range ? `от ${data.from} до ${data.to}` : data.value}&nbsp;
                {data.time ? listDataConstants.glossary[data.time] : ''}
            </>
        );

        return renderValue({
            title,
            index: 0,
            condition: data.condition,
            parentItem,
        });
    };

    // Рендер значений дат/времени
    const renderDateValues = (data: Record<string, any>, parentItem: Record<string, any>) => {
        const { op, from, to, value, fromTime, toTime, valueTime } = data;
        const isDataTime = fromTime || valueTime;

        const _from = isDataTime ? `${from} ${fromTime}` : from;
        const _to = isDataTime ? `${to} ${toTime}` : to;
        const _value = isDataTime ? `${value} ${valueTime}` : value;

        const title = (
            <>
                {op === Operations.gt ? 'после ' : ''}
                {op === Operations.lt ? 'до ' : ''}
                {op === Op.range ? `с ${_from} до ${_to}` : _value}
            </>
        );

        return renderValue({
            title,
            index: 0,
            condition: data.condition,
            parentItem,
        });
    };

    // Рендер значений с текстовыми данными
    const renderTextareaValues = (data: Record<string, any>, parentItem: Record<string, any>) => {
        const textareaData = data.value.split('\n').filter((item) => !!item);

        return textareaData.map((title, index) =>
            renderValue({
                title,
                index,
                condition: data.condition,
                parentItem,
            }),
        );
    };

    const renderValueItem = (item: Record<string, any>) => {
        const { filter, data } = item;

        switch (filter.type) {
            case 'checkbox':
                // Значения с чекбоксами
                return data.checkboxes.map((checkbox, index) =>
                    renderValue({
                        title: checkbox.title,
                        index,
                        condition: data.condition,
                        parentItem: item,
                    }),
                );
            case 'paramsArray':
                // Значения с параметрами (ключ=значение)
                return data.params.map((param, index) =>
                    renderValue({
                        title: `${param.key} = ${param.value}`,
                        index,
                        condition: data.condition,
                        parentItem: item,
                    }),
                );
            case 'textarea':
                // Значения с текстовыми данными
                return renderTextareaValues(data, item);
            case 'period':
                // Значение с периодом
                return renderPeriodValues(data, item);
            case 'date':
            case 'time':
            case 'dateTime':
                // Значение даты/времени
                return renderDateValues(data, item);
            default:
                return null;
        }
    };

    const renderItem = (item: Record<string, any>) => {
        const { filter } = item;
        const filterName = filter.name;

        const node = (
            <>
                {`${filter.title}: `}
                {renderValueItem(item)}
            </>
        );

        return (
            <div
                onClick={(e) => handleClick(e, filterName)}
                className={s.item}
                data-filter={filterName}
                data-report-filters={filterAttr}
                key={filterName}
            >
                <Tooltip title={node} overlayClassName={s.filterValueTooltip}>
                    <Chip
                        className={s.filter}
                        type="default"
                        data-testid="pickFilter-button"
                        onClose={() => onRemoveValue(filterName)}
                        fixedWidth
                    >
                        {node}
                    </Chip>
                </Tooltip>
            </div>
        );
    };

    return (
        <div className={s.root}>
            <Button
                onClick={onOpen}
                className={s.anchorButton}
                type="link"
                prefixIcon={<IconCirclePlus />}
                data-test-id="filter-button"
            >
                Фильтры
            </Button>
            {filtersValues.reverse().map(renderItem)}
        </div>
    );
};

export default Anchor;
