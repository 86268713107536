import React from 'react';

export const IconGreater = () => (
    <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
        <g id="icon greater 20*20">
            <path
                id="Union"
                d="M6.16147 5.45544C6.46229 4.99227 7.08162 4.86065 7.54479 5.16147L13.4569 9.00123C14.1862 9.47488 14.1853 10.5428 13.4551 11.0151L7.5433 14.8397C7.07959 15.1397 6.46049 15.007 6.1605 14.5433C5.86051 14.0796 5.99323 13.4605 6.45694 13.1605L11.3325 10.0063L6.45544 6.83876C5.99227 6.53795 5.86066 5.91861 6.16147 5.45544Z"
                fill="#83868D"
            />
        </g>
    </svg>
);
