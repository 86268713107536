import AttributionModel, { EAttributionModel } from '@components/AttributionModel';
import AttributionWindow from '@components/AttributionWindow/';
import ClearFilters from '@components/ClearFilters';
import CollapseFilters from '@components/CollapseFilters';
import NumberOfGraphs from '@components/NumberOfGraphs';
import ReportFilters from '@components/ReportFilters';
import SampleField from '@components/SampleField';
import SavedFilters from '@components/SavedFilters';
import { GroupBy } from '@configs/group';
import reportsParamsConfig from '@configs/reportsParams';
import { EPeriodDetail } from '@constants/periodDetail';
import { IAvailableTools, IPeriodDetailData } from '@typings/availableTools';
import { DateType, DateLiteral } from '@typings/date';
import { GetFiltersFunction, IDynamicFiltersData, IFiltersConfig, IGlobalFilters } from '@typings/filters';
import { IGraphs } from '@typings/graph';
import { IUpdateMediaParams } from '@typings/media';
import { Metrics } from '@typings/metrics';
import { IUpdateReportOptions, IUpdateReportParams, ReportName } from '@typings/reports';
import { listDataUtils } from '@utils/index';
import cn from 'classnames';
import React, { useRef, useState } from 'react';

import PeriodPicker from './PeriodPicker';

import s from './Tools.pcss';

interface IProps {
    className?: string;
    title?: string;
    reportsUtils: Record<string, TCallback<unknown, any>>;
    getTypeRequestReport: (reportName: ReportName, updateParam?: string) => string;
    reportName: ReportName;
    projectId: number;
    updateReport: (
        type: string,
        options: IUpdateReportOptions<IUpdateReportParams | IUpdateMediaParams, ReportName>,
    ) => void;
    updateReportWithoutRequest?: (updateParams: Record<string, string>) => void;
    getLatency: () => void;
    serverTime: number;
    isOnlyToday?: boolean;
    maxDate: DateType;
    minDate: DateType;
    dateStart: DateType;
    dateEnd: DateType;
    nowDateDiff?: number;
    isExcludedLatency: boolean;
    handleChangeDate?: (date: DateType[], relativeDate: DateLiteral) => void;
    sample: number | null;
    onChangeSample?: (newSample: number) => void;
    periodDetail?: EPeriodDetail;
    periodDetailData?: IPeriodDetailData[];
    changePeriodDetailHandler?: (value: EPeriodDetail, group?: GroupBy) => GroupBy;
    isSampleDisabled?: boolean;
    isPeriodDetailDisabled?: boolean;
    attributionModel?: string;
    attributionWindow?: string;
    groupBy: GroupBy;
    metrics?: Metrics[];
    graphs?: IGraphs[];
    toggleUseDefaultLines?: (useDefaultLines: boolean) => void;
    accessLevel?: boolean;
    disableNumberOfGraphs?: boolean;
    availableTools: IAvailableTools;
    dynamicFiltersData: IDynamicFiltersData;
    globalFilters: IGlobalFilters;
    getDynamicFilters?: GetFiltersFunction;
    clearFilters?: () => void;
    filtersConfig: IFiltersConfig;
}

const ReportTools: React.FC<IProps> = ({
    className = '',
    title,
    reportsUtils,
    getTypeRequestReport,
    reportName,
    updateReport,
    updateReportWithoutRequest,
    getLatency,
    serverTime,
    isOnlyToday,
    maxDate,
    minDate,
    dateStart,
    dateEnd,
    nowDateDiff,
    isExcludedLatency,
    handleChangeDate,
    sample,
    onChangeSample,
    periodDetail,
    periodDetailData,
    changePeriodDetailHandler,
    isSampleDisabled,
    isPeriodDetailDisabled,
    attributionModel,
    attributionWindow,
    groupBy,
    metrics,
    graphs,
    accessLevel,
    disableNumberOfGraphs,
    availableTools,
    dynamicFiltersData,
    globalFilters,
    getDynamicFilters,
    clearFilters = () => {},
    filtersConfig,
}) => {
    const filterParamsRef = useRef(null);
    const filterParamsInnerRef = useRef(null);
    const [collapsedFilterParams, setCollapsedFilterParams] = useState<boolean>(true);

    const getFilterParamsHeight = (): number => {
        if (!filterParamsInnerRef.current) return 0;

        return filterParamsInnerRef.current.getBoundingClientRect().height;
    };

    const handleCollapsedFilter = (collapsed: boolean) => {
        setCollapsedFilterParams(collapsed);
    };

    const onChangeGraphNumber = (value: string) => {
        const selectedNumber = reportsParamsConfig.numberOfGraphs.indexOf(value) + 1;

        if (graphs && graphs.length === selectedNumber) return;

        const newGraphs = reportsUtils.getDefaultGraphs(reportName, graphs, value, metrics);

        if (value === 'one') {
            updateReportWithoutRequest({ graphs: newGraphs });
        } else if (value === 'two') {
            updateReport('graph', {
                updateParams: { graphs: newGraphs },
            });
        }
    };

    const onChangeAttributionModel = (value: string) => {
        updateReport('all', {
            updateParams: { attributionModel: value },
        });
    };

    const onChangeAttributionWindow = (value: string) => {
        updateReport('all', {
            updateParams: { attributionWindow: value },
        });
    };

    /**
     * Получение динамических данных
     */
    const getDynamicData = (name: string, parents: number[] = [], offset: number = 0) =>
        getDynamicFilters(name, parents, offset);

    /**
     * Устанавливаем выбранный фильтр
     */
    const handleApplyFilters = (filtersList: IGlobalFilters = {}) => {
        // Обновляем только если фильтры изменились и имеют корректное значение
        if (!globalFilters?.filtersRequest && !filtersList?.filtersRequest?.length) return;

        updateReport('all', {
            updateParams: { globalFilters: filtersList },
            options: { withoutCompare: true },
        });
    };

    /**
     * Сбрасываем фильтры, перерисовываем отчёт заново
     */
    const handleClearFilters = () => {
        clearFilters();
        updateReport('all', {
            updateParams: {
                globalFilters: {},
            },
            options: {
                withoutCompare: true,
            },
        });
    };

    /**
     * Собираем данные для компонента фильтров ListData
     */
    // TODO: вытащить всю логику по фильтрам в отдельное место
    const { listData, selectedData, isSavedFilterApplied } =
        (availableTools.filters && listDataUtils.getFiltersData(filtersConfig, globalFilters)) || {};

    return (
        <div className={cn(s.root, className)}>
            {title && <div>{title}</div>}
            <div className={s.params}>
                <div className={s.mainParams}>
                    {availableTools.numberOfGraphs && (
                        <NumberOfGraphs
                            reportName={reportName}
                            onChangeGraphNumber={onChangeGraphNumber}
                            isDisabled={disableNumberOfGraphs}
                            selected={graphs && graphs.length}
                        />
                    )}
                    <PeriodPicker
                        maxDate={maxDate}
                        minDate={minDate}
                        dateStart={dateStart}
                        dateEnd={dateEnd}
                        nowDateDiff={nowDateDiff}
                        isExcludedLatency={isExcludedLatency}
                        groupBy={groupBy}
                        reportName={reportName}
                        updateReport={updateReport}
                        handleChangeDate={handleChangeDate}
                        availableTools={availableTools}
                        getLatency={getLatency}
                        serverTime={serverTime}
                        isOnlyToday={isOnlyToday}
                        getTypeRequestReport={getTypeRequestReport}
                        periodDetail={periodDetail}
                        periodDetailData={periodDetailData}
                        isPeriodDetailDisabled={isPeriodDetailDisabled}
                        changePeriodDetailHandler={changePeriodDetailHandler}
                    />
                    {availableTools.sample && sample !== null && sample >= 0 && (
                        <SampleField
                            sample={sample}
                            activeSample={!isSampleDisabled && availableTools.activeSample}
                            updateReport={updateReport}
                            onChangeSample={onChangeSample}
                        />
                    )}
                    {availableTools.attributionModel && (
                        <AttributionModel
                            reportName={reportName}
                            onChangeAttributionModel={onChangeAttributionModel}
                            selected={attributionModel}
                        />
                    )}
                    {availableTools.attributionWindow && (
                        <AttributionWindow
                            reportName={reportName}
                            onChangeAttributionWindow={onChangeAttributionWindow}
                            isDisabled={!attributionModel || attributionModel === EAttributionModel.lastClick}
                            selected={attributionWindow}
                        />
                    )}
                </div>
                {availableTools.filters && (
                    <>
                        <div className={s.filterActions}>
                            {availableTools.savedFilters && (
                                <div className={s.filterActionsLeft}>
                                    <SavedFilters
                                        accessLevel={accessLevel}
                                        reportName={reportName}
                                        staticFilters={filtersConfig.staticFilters}
                                        updateReport={updateReport}
                                    />
                                </div>
                            )}
                            <div className={s.filterActionsRight}>
                                <ClearFilters
                                    onClearFilters={handleClearFilters}
                                    reportName={reportName}
                                    disabled={!globalFilters?.filtersRequest}
                                />
                                <CollapseFilters
                                    height={getFilterParamsHeight()}
                                    onCollapsedFilters={handleCollapsedFilter}
                                    collapsedFilters={collapsedFilterParams}
                                />
                            </div>
                        </div>
                        <div
                            className={cn(s.filterParams, { [s.collapsed]: collapsedFilterParams })}
                            ref={filterParamsRef}
                        >
                            <div className={s.filterParamsInner} ref={filterParamsInnerRef}>
                                <ReportFilters
                                    reportName={reportName}
                                    onApply={handleApplyFilters}
                                    selectedData={selectedData}
                                    data={listData}
                                    dynamicData={dynamicFiltersData}
                                    getDynamicData={getDynamicData}
                                    getFilterData={(props, state) =>
                                        state.currentTab === 'popularity'
                                            ? props.data.popularityFilters
                                            : props.data.staticFilters
                                    }
                                    allFiltersData={listData.staticFilters.data}
                                    isSavedFilterApplied={isSavedFilterApplied}
                                />
                            </div>
                        </div>
                    </>
                )}
            </div>
        </div>
    );
};

export default ReportTools;
