import { Pagination, Loader } from '@adtech/ui';
import ReportMessage from '@components/ReportMessage';
import { OrdersType, sortOrders } from '@constants/sort';
import { Dimensions } from '@typings/dimensions';
import { IMetricFilter } from '@typings/filters';
import { IDataItemType, GraphLines, SelectedLines, ISelectedLine } from '@typings/graph';
import { Metrics, IMetricsWithStates } from '@typings/metrics';
import { ReportName } from '@typings/reports';
import { IOrderBy, ITableConstants, IDataTable } from '@typings/table';
import { arrayUtils, measuresUtils } from '@utils/index';
import cn from 'classnames';
import React from 'react';

import s from './ReportTable.pcss';
import TableBody from './components/TableBody';
import TableHead from './components/TableHead';

interface IProps {
    reportName: ReportName;
    utils: TAPI;
    api: TAPI;
    tableTitle: string;
    orderBy: IOrderBy;
    graphData?: IDataItemType[];
    allMetrics: Metrics[];
    metrics: Metrics[];
    metricsWithStates: IMetricsWithStates[];
    dimensions?: Dimensions[];
    totals: number[];
    tableData: IDataTable;
    selectedLines?: ISelectedLine[];
    titleFilter: string;
    tableFilters: IMetricFilter[];
    totalRows: number;
    tableRequest: boolean;
    graphRequest?: boolean;
    useDefaultLines?: boolean; // режим используются линии по умолчанию или пользовательские
    useSelectedLines?: boolean; // нужно ли использовать выбранные линии
    counterTableRowsReport?: number; // используется для отчета Посещаемость
    isRating?: boolean;
    isSummary?: boolean;
    showTotals: boolean;
    isShowTableButtons?: boolean;
    isPagination?: boolean;
    labels?: Record<string, string>;
    constants: ITableConstants;
    limit?: number;
    currentPage?: number;
    isAnimated?: boolean;
    isHideMetricsDropdown?: boolean;
    isForceEmpty?: boolean; // принудительная заглушка "нет данных"
    onChangePagination?: (page: number) => void;
    isStickyTableHead?: boolean;
    isSmallWidthTable?: boolean;
    forceDisableSort?: boolean;
}

export const Table: React.FC<IProps> = (props) => {
    const {
        reportName,
        utils,
        api,
        tableTitle,
        orderBy,
        graphData,
        allMetrics,
        metrics,
        metricsWithStates,
        dimensions,
        totals,
        tableData,
        selectedLines,
        titleFilter,
        tableFilters,
        tableRequest,
        useDefaultLines,
        useSelectedLines = true,
        counterTableRowsReport,
        isRating,
        isSummary,
        showTotals,
        isShowTableButtons,
        isPagination,
        labels,
        constants,
        limit,
        currentPage,
        totalRows,
        isAnimated,
        isHideMetricsDropdown,
        isForceEmpty,
        onChangePagination,
        isStickyTableHead,
        isSmallWidthTable,
        forceDisableSort,
    } = props;

    const {
        updateReport,
        toggleUseDefaultLines,
        toggleMetricWithState,
        extendTableNode,
        updateTableSort,
        clearGraphs,
        removeLines,
        recoveryRemovedLines,
        updateTableOffset,
        updateTableHeadOffset,
    } = api;

    const isOnlyTableUpdate = utils.isGraphsNotUsed(reportName);

    const handlePercentSelect = (metric: string) => {
        toggleMetricWithState(metric, metricsWithStates);
    };

    const handleChangeMetrics = (newMetrics: Metrics[]) => {
        const { key } = orderBy;

        // если есть фильтра в стейте, но их нет в выбранных метриках,
        // полностью обновляем графики, и таблицу
        const isRemovedMetrics = tableFilters.filter((filter) => !metrics.includes(filter.key)).length > 0;

        let updateType = metrics.includes(key) && !isRemovedMetrics ? 'table' : 'all';

        if (isSummary) {
            updateType = 'tableWithGraph';
        }

        // Для отчётов без графиков, обновляем только таблицу
        if (isOnlyTableUpdate) {
            updateType = 'table';
        }

        updateReport(updateType, {
            updateParams: { metrics: newMetrics },
            meta: { reportName },
            options: { ...(isRating ? { pinLines: selectedLines } : {}) },
        });
    };

    const handleOrderClick = (metric: Metrics, order: OrdersType) => {
        const tableDataType = utils.getParamFromConfig(reportName, 'tableDataType');
        const defaultSort = {
            key: metric,
            value: metric === 'title' ? sortOrders.ASC : sortOrders.DESC,
        };

        if (order) {
            defaultSort.value = order;
        } else {
            const isOrdering = measuresUtils.isOrderingBy(metric, orderBy);
            const orderParams = measuresUtils.checkOrderDirection(orderBy);

            if (isOrdering) {
                defaultSort.value = orderParams.asc ? sortOrders.DESC : sortOrders.ASC;
            }

            if (isRating) {
                defaultSort.value = sortOrders.DESC;
            }
        }

        if (useSelectedLines) toggleUseDefaultLines(true);

        // Сортируем имеющиеся данные таблицы сами без новых запросов к бэку
        // Полезно для маленьких таблиц с кол-вом строк < лимита
        if (tableDataType === 'static' || isSummary) {
            updateTableSort({
                orderBy: defaultSort,
                data: tableData,
                metrics,
                reportName,
            }).then((newLines) => {
                if (isSummary) return;

                updateReport('graph', {
                    updateParams: {
                        orderBy: defaultSort,
                        selectedLines: newLines,
                    },
                    options: { forceResetSelectedLines: true },
                });
            });
        } else {
            // новый запрос за таблицей и графиками
            // Для отчётов без графиков, обновляем только таблицу
            const updateType = isOnlyTableUpdate ? 'table' : 'all';

            updateReport(updateType, {
                updateParams: { orderBy: defaultSort },
                options: {
                    forceResetSelectedLines: true,
                    ...(isRating ? { pinLines: selectedLines } : {}),
                },
            });
        }
    };

    const handleFilterSelect = (metric: string, op: string, value: string) => {
        const newFilter = [];
        let newMetric = true;

        tableFilters.forEach((item) => {
            if (item.key === metric) {
                newMetric = false;
                if (value !== '' && op) {
                    newFilter.push({ key: metric, op, value });
                }
            } else {
                newFilter.push(item);
            }
        });

        if (newMetric) newFilter.push({ key: metric, op, value });

        if (useSelectedLines && !useDefaultLines && !isRating) toggleUseDefaultLines(true);

        const type = newMetric || isOnlyTableUpdate ? 'table' : 'all';

        updateReport(type, {
            updateParams: {
                tableFilters: newFilter,
            },
            meta: { reportName },
            options: {
                forceResetSelectedLines: true,
                ...(isRating ? { pinLines: selectedLines } : {}),
            },
        });
    };

    const handleTitleFilterSelect = (newTitleFilter: string) => {
        if (useSelectedLines && !useDefaultLines) toggleUseDefaultLines(true);

        // TODO: в будущем возможно лучше более универсально сделать,
        // чтобы не создавать отдельное условие для медиа
        const type = isOnlyTableUpdate ? 'table' : 'all';

        updateReport(type, {
            updateParams: {
                titleFilter: newTitleFilter,
                ...(isRating ? { limit: 50 } : {}),
            },
            options: {
                forceResetSelectedLines: true,
                ...(isRating ? { pinLines: selectedLines } : {}),
            },
        });
    };

    const getMaxIndex = (arr: { id: string; colorIndex: number }[]) => {
        const indexes = arr.map((item) => item.colorIndex);
        return indexes.length ? Math.max(...indexes) : 0;
    };

    const getSelectedLines = (hash: string, curSelectedLines: SelectedLines, deletedLines: SelectedLines) => {
        let newSelectedLines = [];
        let newDeletedLines = [];
        let currentLine = {};

        // определяем, добавить или удалить линию
        const isRemoveLine = curSelectedLines.find((item) => item.id === hash);

        if (isRemoveLine) {
            // Если удаляем линию
            currentLine = isRemoveLine;
            newSelectedLines = curSelectedLines.filter((item) => item.id !== hash);
            newDeletedLines = [...deletedLines, currentLine];
        } else if (deletedLines.find((item) => item.id === hash)) {
            // Если возвращаем удалённую линию
            currentLine = deletedLines.find((item) => item.id === hash);
            newSelectedLines = [...curSelectedLines, currentLine];
            newDeletedLines = deletedLines.filter((item) => item.id !== hash);
        } else {
            // Если добавляем новую линию
            const indexSelectedItems = getMaxIndex(curSelectedLines);
            const indexDeletedItems = getMaxIndex(deletedLines);
            let index = indexSelectedItems > indexDeletedItems ? indexSelectedItems : indexDeletedItems;

            newDeletedLines = [...deletedLines];
            if (index === constants.MAX_SELECTED_ROWS - 1 && curSelectedLines.length <= constants.MAX_SELECTED_ROWS) {
                // Если нужно перезаписать дефолтные линии
                index = indexDeletedItems % constants.MAX_SELECTED_ROWS;

                currentLine = { id: hash, colorIndex: index };
                newDeletedLines = deletedLines.filter((item) => item.colorIndex !== index);
                newSelectedLines = [...curSelectedLines, currentLine];
            } else {
                // Иначе добавляем линию со следующим  по возрастанию индексом
                // При сбросе нод необходимо начать отсчёт индексов с нулевого элемента
                currentLine = { id: hash, colorIndex: index + 1 };
                newSelectedLines = [...curSelectedLines, currentLine];
            }
        }

        return { newSelectedLines, newDeletedLines, currentLine, isRemoveLine };
    };

    const findRemovedLines = (lines: GraphLines) =>
        lines.filter((line) => graphData.find((item) => item.id === line.id));

    /**
     * Обработчик для добавления новых линий
     * @param lines
     * @param isRemove
     */
    const handleUploadLine = (lines: GraphLines, isRemove: boolean) => {
        if (isRemove) return;
        const removedLines = findRemovedLines(lines);

        if (removedLines.length) return;

        const uniqLines = arrayUtils.uniqBy(lines, 'id');
        const keys = uniqLines.map((line) => line.id);

        updateReport('graph', {
            updateParams: { selectedLines: uniqLines },
            meta: {
                isNewSelectedLines: true,
                excessActionPrefix: `graph_lines_${JSON.stringify(keys)}`,
            },
        });
    };

    /**
     * Обработчик для добавления/удаления линий которые уже загружены
     * @param lines
     * @param isRemove
     */
    const handleToggleLine = (lines: GraphLines, isRemove: boolean) => {
        if (!isRemove) {
            const removedLines = findRemovedLines(lines);

            if (removedLines.length) {
                recoveryRemovedLines(removedLines);
            }
        } else {
            removeLines(lines);
        }
    };

    const handleResetSelectLines = () => {
        if (!selectedLines.length) return;

        clearGraphs();
    };

    const handleOffsetUpdateTable = (parentId: string, currentRows: number) => {
        const { list } = tableData.map[parentId];
        const id = parentId.split(constants.PARENTS_DELIMITER);
        const offset = isSummary || isRating ? currentRows : list.length;

        updateTableOffset(offset, id);
    };

    const handleHeadOffsetUpdateTable = (currentRows: number, totalTopRows: number) => {
        let offset = totalTopRows - currentRows;
        let tableLimit = 50;

        if (offset < 1) {
            offset = 1;
            tableLimit = totalTopRows - 1;
        }

        updateTableHeadOffset(offset, tableLimit);
    };

    const renderEmptyTable = (className?) => (
        <ReportMessage className={cn(s.tableMessage, className)} message="Нет данных для отображения" />
    );

    const root =
        typeof tableData === 'object' && typeof tableData.map === 'object' && tableData.map[constants.ROOT_NODE];
    const showMessage = (!root || !root.list.length) && !tableRequest;

    return (
        <div className={s.root}>
            {isForceEmpty && renderEmptyTable(s.tableEmpty)}
            {!isForceEmpty && (
                <div
                    className={cn(s.table, {
                        [s.tableAnimated]: isAnimated,
                        [s.tableDropdownMetricsHidden]: !!isHideMetricsDropdown,
                        [s.tableSmallWidth]: isSmallWidthTable,
                    })}
                >
                    <Loader loading={tableRequest}>
                        <table
                            className={cn(s.tableInner, {
                                [s.tableLoading]: tableRequest,
                            })}
                        >
                            <TableHead
                                utils={utils}
                                tableTitle={tableTitle}
                                onPercentSelect={handlePercentSelect}
                                onNewMetricSelect={handleChangeMetrics}
                                onIndicatorClick={handleOrderClick}
                                onFilterSelect={handleFilterSelect}
                                onTitleFilterSelect={handleTitleFilterSelect}
                                onResetSelectedLines={handleResetSelectLines}
                                reportName={reportName}
                                orderBy={orderBy}
                                titleFilter={titleFilter}
                                totals={totals}
                                tableFilters={tableFilters}
                                allMetrics={allMetrics}
                                metrics={metrics}
                                metricsWithStates={metricsWithStates}
                                dimensions={dimensions}
                                tableRequest={tableRequest}
                                showTotals={showTotals}
                                isShowTableButtons={isShowTableButtons}
                                visible
                                constants={constants}
                                isHideMetricsDropdown={isHideMetricsDropdown}
                                isStickyTableHead={isStickyTableHead}
                                forceDisableSort={forceDisableSort}
                            />
                            <TableBody
                                getSelectedLines={getSelectedLines}
                                onUploadLine={handleUploadLine}
                                onToggleLine={handleToggleLine}
                                onShowmoreClick={handleOffsetUpdateTable}
                                onShowmoreTopClick={handleHeadOffsetUpdateTable}
                                onExpandClick={extendTableNode}
                                toggleUseDefaultLines={toggleUseDefaultLines}
                                reportName={reportName}
                                data={tableData}
                                indicators={metrics}
                                metrics={metrics}
                                dimensions={dimensions}
                                selectedLines={selectedLines}
                                metricsWithStates={metricsWithStates}
                                useDefaultLines={useDefaultLines}
                                counterTableRowsReport={counterTableRowsReport}
                                isRating={isRating}
                                isSummary={isSummary}
                                isPagination={isPagination}
                                orderBy={orderBy}
                                utils={utils}
                                labels={labels}
                                constants={constants}
                                isAnimated={isAnimated}
                            />
                        </table>
                        {!showMessage && isPagination && currentPage && (
                            <div className={s.pagination}>
                                <Pagination
                                    total={totalRows}
                                    defaultPageSize={limit}
                                    current={currentPage}
                                    onChange={onChangePagination}
                                    showSizeChanger={false}
                                />
                            </div>
                        )}
                        {showMessage && renderEmptyTable()}
                    </Loader>
                </div>
            )}
        </div>
    );
};

export default Table;
