import Grid from '@components/Grid';
import KeyMetrics from '@components/KeyMetrics';
import MaterialInfo from '@components/MaterialInfo';
import Widget from '@components/Widgets';
import { widgetsMediaConfig } from '@configs/media/widgets';
import { WidgetTypes } from '@configs/widget';
import useMedia from '@hooks/useMedia';
import { IMediaContainer, ReportNameMedia, TRenderReportTools } from '@typings/media';
import { IRootSlice } from '@typings/rootSlice';
import React from 'react';
import { useSelector } from 'react-redux';

interface IProps {
    reportName: ReportNameMedia;
    renderReportTools: TRenderReportTools;
}

const MediaMaterial: React.FC<IMediaContainer> = ({ reportName, renderReportTools }: IProps) => {
    const { reportsUtils, api } = useMedia();
    const { getParamFromConfig } = reportsUtils;

    const totals = useSelector((state: IRootSlice) => state.mediaSlice.totals);
    const groupBy = useSelector((state: IRootSlice) => state.globalSlice.groupBy);
    const dateStart = useSelector((state: IRootSlice) => state.globalSlice.dateStart);
    const dateEnd = useSelector((state: IRootSlice) => state.globalSlice.dateEnd);
    const trafficSources = useSelector((state: IRootSlice) => state.mediaSlice.trafficSources);
    const audience = useSelector((state: IRootSlice) => state.mediaSlice.audience);
    const fullReadScrollMedia = useSelector((state: IRootSlice) => state.mediaSlice.fullReadScrollMedia);
    const recirculationSourcesMedia = useSelector((state: IRootSlice) => state.mediaSlice.recirculationSourcesMedia);
    const totalsRequest = useSelector((state: IRootSlice) => state.mediaSlice.totalsRequest);
    const materialInfo = useSelector((state: IRootSlice) => state.mediaSlice.materialInfo);

    const keyMetricsHint = getParamFromConfig(reportName, 'keyMetricsHint');

    const {
        trafficSources: trafficSourcesDefaults,
        audience: audienceDefaults,
        fullReadScroll: fullReadScrollDefaults,
        recirculationSources: recirculationSourcesDefaults,
    } = widgetsMediaConfig;

    return (
        <>
            <MaterialInfo {...materialInfo} />
            {renderReportTools()}
            <KeyMetrics
                data={totals}
                hintTitle={keyMetricsHint}
                totalsRequest={totalsRequest}
                reportName={reportName}
            />
            <Grid>
                <Widget
                    reportName={reportName}
                    period={[dateStart, dateEnd]}
                    groupBy={groupBy}
                    type={WidgetTypes.graph}
                    title="Источники переходов"
                    name="trafficSources"
                    isShowChartSwitcher
                    onUpdate={api.updateTrafficSources}
                    handleGraphSelect={api.updateWidgetParams}
                    size={2}
                    heightType="large"
                    {...trafficSourcesDefaults}
                    {...trafficSources}
                />
                <Widget
                    reportName={reportName}
                    period={[dateStart, dateEnd]}
                    groupBy={groupBy}
                    type={WidgetTypes.graph}
                    title="Аудитория"
                    name="audience"
                    isShowChartSwitcher
                    onUpdate={api.updateAudience}
                    handleGraphSelect={api.updateWidgetParams}
                    size={2}
                    heightType="large"
                    {...audienceDefaults}
                    {...audience}
                />
            </Grid>
            <Grid>
                <Widget
                    reportName={reportName}
                    period={[dateStart, dateEnd]}
                    groupBy={groupBy}
                    type={WidgetTypes.graph}
                    title="Доскроллы и дочтения"
                    name="fullReadScroll"
                    isDisabledSettings
                    onUpdate={api.updateFullReadScroll}
                    {...fullReadScrollDefaults}
                    {...fullReadScrollMedia}
                />
                <Widget
                    reportName={reportName}
                    period={[dateStart, dateEnd]}
                    groupBy={groupBy}
                    type={WidgetTypes.table}
                    title="Переход на другие материалы"
                    name="recirculationSources"
                    isDisabledSettings
                    isDisabledTabs
                    isShowOneMetric
                    onUpdate={api.updateRecirculationSources}
                    {...recirculationSourcesDefaults}
                    {...recirculationSourcesMedia}
                />
            </Grid>
        </>
    );
};

export default MediaMaterial;
