import ContentWithSidebar from '@components/ContentWithSidebar';
import GoalsTable from '@components/GoalsTable';
import PageHead from '@components/PageHead';
import TableTools from '@components/TableTools';

import { GoalFormType } from '@configs/goals/goalForm';
import goalsConfig from '@configs/goals/goals';
import reportsConstants from '@constants/reports';

import useGlobalLoading from '@hooks/useGlobalLoading';
import useGoals from '@hooks/useGoals';
import useModalPopup from '@hooks/useModalPopup';
import useProjectRequired from '@hooks/useProjectRequired';
import { useTable } from '@hooks/useTable';

import { globalActions } from '@redux/slices/global';
import { IRootSlice } from '@typings/rootSlice';
import { storagesUtils } from '@utils/index';

import React, { FC, useEffect } from 'react';
import DocumentTitle from 'react-document-title';
import { useSelector, useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';

const Goals: FC = () => {
    useGlobalLoading();

    const params: IUrlParams = useParams();
    const projectId = Number(params.projectId);

    const isProjectRequired = useProjectRequired();

    const reportName = 'goals';
    const storageKey = 'showCommonGoals';

    const api = useGoals();
    const {
        api: { resetTable },
    } = useTable();

    const { openModalPopup, closeModalPopup } = useModalPopup();

    const userId = useSelector((state: IRootSlice) => state.userSlice.id);

    const tableData = useSelector((state: IRootSlice) => state.goalsSlice.tableData);
    const tableRequest = useSelector((state: IRootSlice) => state.goalsSlice.tableRequest);
    const deleteRequest = useSelector((state: IRootSlice) => state.goalsSlice.deleteRequest);
    const totals = useSelector((state: IRootSlice) => state.goalsSlice.totals);
    let showCommonGoals = useSelector((state: IRootSlice) => state.goalsSlice.showCommonGoals);

    const globalLoading = useSelector((state: IRootSlice) => state.globalSlice.globalLoading);

    const titleFilter = useSelector((state: IRootSlice) => state.tableSlice.titleFilter);

    const projectURL = useSelector((state: IRootSlice) => state.projectsSlice.current.cleanedUrl);

    const dispatch = useDispatch();
    const setGlobalLoading = (param: boolean) => dispatch(globalActions.setGlobalLoading(param));

    if (projectId === reportsConstants.DEMO_PROJECT_ID && showCommonGoals === null) {
        showCommonGoals = true;
    }
    const isShowAllGoals = !!storagesUtils.get(storageKey, userId) || showCommonGoals;

    const getPageTitle = () => {
        const url = projectURL ? `(${projectURL}) ` : '';
        return `Целевые события ${url} – Статистика от SberAds / ${projectId}`;
    };

    useEffect(
        () => () => {
            resetTable();
            setGlobalLoading(false);
        },
        [],
    );

    useEffect(() => {
        if (isProjectRequired) {
            setGlobalLoading(false);
        }
    }, [isProjectRequired]);

    useEffect(() => {
        api.init({
            showCommonGoals: isShowAllGoals,
            titleFilter: '',
        });
    }, [projectId]);

    const handleClickAddGoal = () => {
        openModalPopup('GoalForm', {
            type: GoalFormType.add,
            closePopup: closeModalPopup,
        });
    };

    const handleChangeShowAllGoals = (checked?: boolean) => {
        api.updateParams({ showCommonGoals: checked });
        api.getTable({ showCommonGoals: checked });
        storagesUtils.set(storageKey, checked ? 1 : 0, userId);
    };

    const handleSearchGoals = (value: string) => {
        if (tableRequest || deleteRequest) return;
        api.updateTable({ titleFilter: value });
    };

    return (
        <DocumentTitle title={getPageTitle()}>
            {!globalLoading && (
                <>
                    <PageHead
                        reportName={reportName}
                        projectId={projectId}
                        userId={userId}
                        title={goalsConfig.reportTitle}
                        hintContent={goalsConfig.reportHint}
                    />
                    <ContentWithSidebar>
                        {tableData && (
                            <>
                                <TableTools
                                    onSearchChange={handleSearchGoals}
                                    searchPlaceholder="Искать по названию целевого события"
                                    searchWord={titleFilter}
                                    prefixButtonText="Новое целевое событие"
                                    testId="add-button"
                                    onClickPrefixButton={handleClickAddGoal}
                                    switcherText="Показать публичные целевые события"
                                    onChangeSwitcher={handleChangeShowAllGoals}
                                    switcherHintText="Показать все целевые события, включая публичные. По умолчанию показываются только личные."
                                    switcherChecked={isShowAllGoals}
                                />
                                <GoalsTable
                                    tableRequest={tableRequest}
                                    deleteRequest={deleteRequest}
                                    tableData={tableData}
                                    totals={totals}
                                    api={api}
                                />
                            </>
                        )}
                    </ContentWithSidebar>
                </>
            )}
        </DocumentTitle>
    );
};

export default Goals;
