import { DropDownList, IconArrowDown, Button } from '@adtech/ui';

import { projectsStatus, projectsTextStatus } from '@constants/projects';
import { ICategories } from '@typings/projects';
import cn from 'classnames';
import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import s from './TablePosition.pcss';

interface IProps {
    projectId: number;
    ratingStatus: string;
    categories: ICategories[];
    isCanEdit: boolean;
}

const TablePosition: React.FC<IProps> = ({ projectId, ratingStatus, categories = [], isCanEdit }) => {
    const [selectedCategory, setSelectedCategory] = useState(categories[0]?.id || null);
    const navigate = useNavigate();
    const handleCategoryClick = (id: string) => setSelectedCategory(id);

    // Выпадающий список с категориями
    const generateCategoriesDataItems = categories.map((item: ICategories) => ({
        dropDownItemProps: {
            className: cn(s.categoriesItem, {
                [s.categoriesItemSelected]: item.id && item.id === selectedCategory,
            }),
            text: item.name,
            onClick: () => handleCategoryClick(item.id),
        },
    }));

    const renderForStatusIncluded = () => {
        if (!categories?.length) return <span>&mdash;</span>;
        const category = categories.find((item) => item.id === selectedCategory);
        const label = categories.length === 0 ? '&mdash' : category.name;

        return (
            <>
                {categories.length <= 1 ? (
                    <div className={s.category}>{label}</div>
                ) : (
                    <DropDownList
                        menuItems={generateCategoriesDataItems}
                        customButton={
                            <button type="button" className={s.categoriesButton}>
                                <span className={s.categoriesButtonText}>{category.name}</span>
                                <IconArrowDown />
                            </button>
                        }
                    />
                )}
                <div className={s.position}>{category.position}</div>
            </>
        );
    };

    const renderForStatusUnincluded = isCanEdit ? (
        <Button
            size="small"
            type="dashed"
            onClick={() => navigate(`/stat/projects/${projectId}/settings/rating`)}
            className={s.buttonRating}
        >
            Участвовать
        </Button>
    ) : (
        <div className={s.message}>{projectsTextStatus[ratingStatus]}</div>
    );

    return (
        <div className={s.root}>
            {ratingStatus === projectsStatus.INCLUDED && renderForStatusIncluded()}
            {ratingStatus === projectsStatus.UNINCLUDED && renderForStatusUnincluded}
        </div>
    );
};

export default TablePosition;
