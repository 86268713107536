import Navigation from '@components/Navigation';
import cn from 'classnames';
import React, { ReactNode } from 'react';
import s from './ContentWithSidebar.pcss';

interface IProps {
    children: ReactNode;
    className?: string;
    sidebarClassName?: string;
    contentClassName?: string;
    sidebarAddition?: ReactNode;
    navigationType?: 'report' | 'projects' | 'settings';
}

const ContentWithSidebar: React.FC<IProps> = ({
    children = null,
    className = '',
    sidebarClassName = '',
    contentClassName = '',
    sidebarAddition = null,
    navigationType = 'report',
}) => {
    const rootClassNames = cn(s.root, className);
    const sidebarClassNames = cn(s.sidebar, sidebarClassName);
    const contentClassNames = cn(s.content, contentClassName);

    return (
        <div className={rootClassNames}>
            <div className={sidebarClassNames}>
                <Navigation type={navigationType} />
                {sidebarAddition}
            </div>
            <div className={contentClassNames}>{children}</div>
        </div>
    );
};

export default ContentWithSidebar;
