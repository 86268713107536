import AccessUsers from './AccessUsers';
import CounterCode from './CounterCode';
import { DisplayStatistics } from './DisplayStatistics';
import InputData from './InputData';
import KeywordsData from './KeywordsData';
import ListData from './ListData';
import LogoColorChooser from './LogoColorChooser';
import MultiInputData from './MultiInputData';
import Rating from './Rating';
import SettingsCounter from './SettingsCounter';
import SettingsDomain from './SettingsDomain';
import SettingsOther from './SettingsOther';
import Wrapper from './Wrapper';

export default {
    InputData,
    ListData,
    KeywordsData,
    MultiInputData,
    DisplayStatistics,
    Wrapper,
    LogoColorChooser,
    CounterCode,
    SettingsCounter,
    SettingsDomain,
    SettingsOther,
    Rating,
    AccessUsers,
};
