import { globalActions } from '@redux/slices/global';
import { connect } from 'react-redux';

import { ModalPopup } from './ModalPopup';

const mapStateToProps = ({ globalSlice: { modalPopup } }) => ({ modalPopup });

const mapDispatchToProps = (dispatch) => ({
    clearModalPopup() {
        dispatch(globalActions.clearModalPopup());
    },
});

export default connect(mapStateToProps, mapDispatchToProps)(ModalPopup);
