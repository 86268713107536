import React from 'react';

interface IProps {
    children: React.ReactNode;
    onSubmit;
    className?: string;
}

export default class Form extends React.Component<IProps> {
    static defaultProps = {
        className: '',
    };

    handleSubmit = (e: React.FormEvent) => {
        const { onSubmit } = this.props;

        e.preventDefault();

        onSubmit(e);
    };

    render() {
        return (
            <form className={this.props.className} onSubmit={this.handleSubmit}>
                {this.props.children}
            </form>
        );
    }
}
