import { Switch, Tabs, Input, ContextAlertMark, Loader } from '@adtech/ui';
import DataList, { IDataListItem } from '@components/DataList';
import Dropdown from '@components/Dropdown';
import GoalFilters from '@components/GoalForm/components/GoalFilters';
import { Hint } from '@components/Hint';

import dimensionsValues from '@configs/dimensionsValues';
import { goalFormConditionsConfig, GoalFormConditionTypes } from '@configs/goals/goalForm';
import useFilters from '@hooks/useFilters';
import useGoals from '@hooks/useGoals';

import { IFilter } from '@typings/filters';
import { IFieldStatus } from '@typings/form';
import { IGoalsConditionData } from '@typings/goals';
import { IRootSlice } from '@typings/rootSlice';
import React, { ChangeEvent, FC, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';

import s from '../../GoalForm.pcss';

interface IProps {
    data: IGoalsConditionData;
    onChangeType: (type: string) => void;
    updateCondition: (type: GoalFormConditionTypes, filters: IFilter[], isCustomName?: boolean) => void;
    resetCondition: (type: GoalFormConditionTypes) => void;
    isShowError: boolean;
    conditionStatus?: IFieldStatus;
}

export const GoalFormCondition: FC<IProps> = ({
    data,
    onChangeType,
    updateCondition,
    resetCondition,
    isShowError,
    conditionStatus,
}) => {
    const { type, filters, custom, globalFiltersSelectedData } = data;
    let selectedInitial; // выбранный элемент списка (только значение)
    let listItemInitial; // выбранный элемент списка (объект)
    let customNameInitial; // введенное пользователем название события
    let metricOpInitial;
    let metricValueInitial;

    const concatEventClassWithName = (eventClass: string, eventName: string) => {
        const eventClassTitle = eventClass && dimensionsValues.event_class[eventClass]?.title;

        const result = eventName ? `${eventClassTitle}: ${eventName}` : '';

        return { result, eventClassTitle };
    };

    // Заполняем поля формы в зависимости от данных фильтров при редактировании цели
    switch (type) {
        case GoalFormConditionTypes.event: {
            const eventClassFilter = filters[0];
            const eventNameFilter = filters[1];
            const eventClass = eventClassFilter?.value as string;
            const eventName = eventNameFilter?.value as string;
            const { result, eventClassTitle } = concatEventClassWithName(eventClass, eventName);

            if (custom) {
                selectedInitial = eventClassTitle;
                customNameInitial = eventName;
                listItemInitial = { name: eventClass };
            } else {
                selectedInitial = result;
                listItemInitial = { name: eventName, parent: eventClass };
            }
            break;
        }
        /* case GoalFormConditionTypes.metric:
            const filter = filters[0];
            const metric = filter?.key;

            selectedInitial = metric && metricsDict[metric]?.title;
            listItemInitial = { name: metric };
            metricOpInitial = operationsReverseCnf[filter?.op];
            metricValueInitial = filter?.value;
            break; */
        default:
            break;
    }

    const eventsList = useSelector((state: IRootSlice) => state.goalsSlice.eventsList);
    const eventsListRequest = useSelector((state: IRootSlice) => state.goalsSlice.eventsListRequest);
    const dynamicFiltersData = useSelector((state: IRootSlice) => state.globalSlice.dynamicFiltersData);

    const { getDataForGlobalFiltersList, resetFormState } = useGoals();
    const { getDynamicFiltersEventBased } = useFilters();

    const [conditionType, setConditionType] = useState(type);
    const [selected, setSelected] = useState<string>(selectedInitial || null);
    const [listItem, setListItem] = useState(listItemInitial || null); // выбранный элемент списка
    const [isDropdownHidden, setIsDropdownHidden] = useState(false);
    // Название события задается пользователем
    const [isCustomName, setCustomName] = useState(custom || false);
    // Значения input полей
    const [eventCustomName, setEventCustomName] = useState(customNameInitial || '');
    const [metricOperation, setMetricOperation] = useState(metricOpInitial || '');
    const [metricValue, setMetricValue] = useState(Number(metricValueInitial) || '');

    useEffect(() => {
        setIsDropdownHidden(false);
    });

    if (type !== conditionType) setConditionType(type);

    const setFilters = (item: IDataListItem) => {
        let filtersData = [];

        switch (conditionType) {
            case GoalFormConditionTypes.event: {
                if (!item.name) break;
                const titlesDict = dimensionsValues.event_class;

                if (isCustomName) {
                    const title = titlesDict[item.name]?.title;
                    filtersData = [
                        { key: 'event_class', op: '=', value: item.name, valuesTitles: [title] },
                        { key: 'event_name', op: '=', value: eventCustomName },
                    ];
                } else {
                    const title = titlesDict[item.parent]?.title;
                    filtersData = [
                        { key: 'event_class', op: '=', value: item.parent, valuesTitles: [title] },
                        { key: 'event_name', op: '=', value: item.name },
                    ];
                }
                break;
            }
            /* case GoalFormConditionTypes.metric:
                filtersData = [
                    { key: item.name, op: operationsCnf[metricOperation], value: metricValue },
                ];
                break; */
            case GoalFormConditionTypes.parameter: {
                // Логика инициализации фильтров вынесена выше в GoalForm,
                // потому что слишком много вычислений для обработки здесь каждого изменения,
                // в отличие от событий мы не зависим от локального стейта, используя formsSlice
                break;
            }
            default:
                break;
        }

        if (!updateCondition) return;
        updateCondition(conditionType, filtersData, isCustomName);
    };

    const resetFilters = () => {
        if (resetCondition) resetCondition(conditionType);
    };

    useEffect(() => {
        if (listItem) setFilters(listItem);
    }, [selected, eventCustomName, metricOperation, metricValue]);

    // Переключение типа
    const handleChangeType = (value: string) => {
        if (conditionType === value) return;

        onChangeType(value);
        setSelected(null);
        setEventCustomName('');
        setMetricOperation('');
        setMetricValue('');
        resetFormState();
    };

    const handleInputChange = (e: ChangeEvent<HTMLInputElement>) => {
        const value = e.target.value;
        setEventCustomName(value);
    };

    // Получаем простой хардкод список всех классов событий
    const getSimpleEventsList = () => {
        const eventClasses = { ...dimensionsValues.event_class };
        delete eventClasses.tech;
        delete eventClasses.rec;

        return Object.entries(eventClasses).map(([key, value]) => ({ title: value.title, name: key }));
    };

    const listItemClickHandler = (item: IDataListItem) => {
        if (conditionType === GoalFormConditionTypes.event && item.parent) {
            const { result } = concatEventClassWithName(item.parent, item.title);
            setSelected(result);
        } else {
            setSelected(item.title);
        }
        setIsDropdownHidden(true);
        setListItem(item);
    };

    /* const onChangeMetricValue = (e: ChangeEvent<HTMLInputElement>) => {
        const value = e.target.value;
        if (!(/^\d*$/.test(value))) return; // проверка на число
        setMetricValue(value);
    }; */

    const renderList = () => {
        let list = [];

        switch (conditionType) {
            // case GoalFormConditionTypes.metric:
            case GoalFormConditionTypes.parameter:
                list = [goalFormConditionsConfig[conditionType].list];
                break;
            case GoalFormConditionTypes.event:
                list = isCustomName ? getSimpleEventsList() : eventsList || [];
                break;
            default:
                break;
        }

        return (
            <Loader
                loading={eventsListRequest}
                // blurContent // FIXME: после обновления @adtech-ui
            >
                <DataList
                    list={list}
                    isSearchEnabled
                    searchPlaceholder={goalFormConditionsConfig[conditionType].searchPlaceholder}
                    classNameListScroller={s.fieldsDropdownListScroller}
                    onListItemClick={listItemClickHandler}
                />
            </Loader>
        );
    };

    // Отрисовка полей
    const renderFields = () => {
        const { listData, selectedData } = getDataForGlobalFiltersList(filters, globalFiltersSelectedData);

        return (
            <div className={s.fields}>
                {conditionType === GoalFormConditionTypes.event ? (
                    <>
                        <div className={s.fieldsItem}>
                            <Dropdown
                                type="customContent"
                                anchorText={selected || 'Не выбрано'}
                                customContent={renderList()}
                                anchorClassName={s.fieldsDropdownAnchor}
                                contentClassName={s.fieldsDropdownContent}
                                isHidden={isDropdownHidden}
                                onClose={() => setIsDropdownHidden(false)}
                                btnDataTestId="dropDown-button"
                            />
                        </div>
                        <div className={s.fieldsItem}>
                            <Input
                                disabled={!isCustomName}
                                size="small"
                                value={eventCustomName}
                                name="eventName"
                                placeholder="Название события"
                                type="text"
                                onChange={handleInputChange}
                                data-testid="eventName-input"
                            />
                        </div>
                    </>
                ) : null}
                {/* { conditionType === GoalFormConditionTypes.metric ? (
                    <>
                        <div className={s.fieldsItem}>
                            <Select
                                size="small"
                                placeholder="Не выбрано"
                                value={metricOperation}
                                options={Object.keys(operationsCnf).map(key => ({ value: key, label: key })}
                                onChange={setMetricOperation}
                            />
                        </div>
                        <div className={s.fieldsItem}>
                            <Input
                                size="small"
                                value={String(metricValue)}
                                name="metricValue"
                                placeholder="0"
                                type="text"
                                onChange={onChangeMetricValue}
                            />
                        </div>
                    </>
                ) : null } */}
                {conditionType === GoalFormConditionTypes.parameter ? (
                    <GoalFilters
                        selectedData={selectedData}
                        data={listData}
                        dynamicData={dynamicFiltersData}
                        getDynamicData={getDynamicFiltersEventBased}
                    />
                ) : null}
            </div>
        );
    };

    const switcherChangeHandler = () => {
        setCustomName(!isCustomName);
        setSelected(null);
        resetFilters();
    };

    const renderConditionText = () => {
        if (conditionType === 'event') {
            return (
                <div className={s.switch} data-testid="slider-container">
                    <Switch checked={isCustomName} onChange={switcherChangeHandler} data-testid="slider-button" />
                    <div className={s.switchLabel} data-testid="slider-text">
                        {goalFormConditionsConfig[conditionType].text}
                    </div>
                </div>
            );
        }

        return <div className={s.itemText}>{goalFormConditionsConfig[conditionType].text}</div>;
    };

    const tabsOptions = Object.keys(GoalFormConditionTypes).map((key: GoalFormConditionTypes) => ({
        value: goalFormConditionsConfig[key].name,
        label: goalFormConditionsConfig[key].title,
    }));

    return (
        <>
            <div className={s.item}>
                <div className={s.itemBody}>
                    <div className={s.itemWrap}>
                        <div className={s.itemType}>Тип условия</div>
                        <Hint className={s.itemHint}>
                            <div>
                                <p>
                                    Выбор{' '}
                                    <a target="_blank" href="https://ads.sber.ru/help/tipy-sobytii" rel="noopener">
                                        события
                                    </a>
                                    , которое станет целевым.
                                </p>
                                <p>
                                    <strong>Событие</strong> — выбрать только событие без дополнительных параметров или
                                    ввести его название.
                                </p>
                                <p>
                                    <strong>Параметр</strong> — выбрать событие с параметрами. В событии нужно выбрать
                                    класс события и ввести его название, после чего выбрать один или несколько
                                    параметров этого события.
                                </p>
                            </div>
                        </Hint>
                    </div>
                    <Tabs
                        type="labeled"
                        value={conditionType}
                        onChange={(value: string) => handleChangeType(value)}
                        options={tabsOptions}
                        data-testid="segmented-container"
                    />
                    <div className={s.itemFields}>
                        {renderConditionText()}
                        {renderFields()}
                    </div>
                </div>
            </div>
            {(isShowError || !conditionStatus.isValid) && (
                <ContextAlertMark
                    status={!isShowError || conditionStatus.isValid ? null : 'error'}
                    message={isShowError ? conditionStatus.message : ''}
                />
            )}
        </>
    );
};

export default GoalFormCondition;
