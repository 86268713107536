// Конфиг ограничения доступа к отчетам для юзеров по email
// reports - список отчетов
// constructorDimensions - список дименшинов конструктора в виде полного пути до дименшина в конфиге
// index1-index2-index3.., где index - индекс элемента в children массиве
// сделано так для оптимизации поиска по дереву-конфигу
const userRestrictAccessSettings = {
    '@sberseller.ru': {
        reports: ['usability'],
        constructorDimensions: ['1-2-0'], // events -> events_params -> ub_block_list
    },
};

export default userRestrictAccessSettings;
