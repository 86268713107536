import { Button, Textarea } from '@adtech/ui';
import snackbarsConstants from '@constants/snackbars';
import { SettingsPageUtils } from '@utils/settingsPageUtils';
import cn from 'classnames';
import React from 'react';

import s from './CounterCode.pcss';

interface IProps {
    mode: 'default' | 'create';
    id: string;
    counterType: number;
    blockAnalyticsAttribute: string;
    useBlockAnalytics: boolean;
    useHashes: boolean;
    useUserId: boolean;
    wrapperWithoutDelimiter: boolean;
    setSnackbar;
    subWrapCounter?: boolean;
}

export default class CounterCode extends React.Component<IProps> {
    static defaultProps = {
        mode: 'default',
        wrapperWithoutDelimiter: false,
        subWrapCounter: false,
    };

    counterCode = React.createRef<HTMLDivElement>();

    timeoutId: null | ReturnType<typeof setTimeout> = null;

    constructor(props) {
        super(props);

        this.counterCode = React.createRef();
    }

    componentDidMount() {
        // setTimeout - костыль.
        // Проблема работы с хешами, страница может проскролиться на какое-то расстояние,
        // но не для нужного элемента
        this.timeoutId = setTimeout(() => {
            const { current } = this.counterCode;
            if (current && window.location.hash === '#counter-code') {
                current.scrollIntoView({
                    block: 'start',
                    inline: 'center',
                });
            }
        }, 0);
    }

    componentWillUnmount() {
        clearTimeout(this.timeoutId);
    }

    handleClickButton = (event: React.MouseEvent<HTMLButtonElement | HTMLTextAreaElement>) => {
        event.preventDefault();
        const { setSnackbar } = this.props;

        const el = document.getElementById('code') as HTMLTextAreaElement;
        el.focus();
        el.select();
        document.execCommand('copy');

        setSnackbar(snackbarsConstants.COPY_CODE);
    };

    renderCounterCode = () => (
        <div className={s.counterCode}>
            <Textarea
                readOnly
                className={s.counterCodeTextarea}
                id="code"
                value={SettingsPageUtils.getCounterCode({
                    id: this.props.id,
                    type: this.props.counterType,
                    attrAnalytic: this.props.blockAnalyticsAttribute,
                    useAnalytic: this.props.useBlockAnalytics,
                    useHashes: this.props.useHashes,
                    useUserId: this.props.useUserId,
                })}
                onDoubleClick={this.handleClickButton}
            />
        </div>
    );

    renderCopyButton = () => {
        const { mode } = this.props;
        const type = mode === 'default' || mode === 'create' ? 'default' : 'dashed';

        return (
            <Button type={type} onClick={this.handleClickButton} className={s.button}>
                Скопировать код
            </Button>
        );
    };

    renderDefaultDescription = () => {
        const { mode } = this.props;

        const defaultModeText =
            'Для корректного подсчёта статистики необходимо установить код на все страницы сайта. Код можно встроить в любое место HTML-кода, желательно в начало страницы, чтобы счётчик успел загрузиться при коротких визитах.'; // eslint-disable-line max-len

        const createModeText = 'Код также доступен на странице настроек.';

        const description = mode === 'default' ? defaultModeText : createModeText;

        return <div className={cn(s.description, { [s.modeDefault]: mode === 'default' })}>{description}</div>;
    };

    render() {
        const { mode } = this.props;

        const defaultModeText =
            'Установите код счётчика на все страницы сайта, ' +
            'по которым должна собираться статистика. Код также доступен на странице настроек.';

        const createModeText = (
            <>
                Для подсчёта статистики добавьте код счётчика на все страницы сайта.
                <br />
                Его можно встроить в любое место HTML-кода страницы, желательно в начало, чтобы счётчик успел
                загрузиться при коротких визитах.
            </>
        );

        const description = mode === 'default' ? defaultModeText : createModeText;

        return (
            <div className={s.root} ref={this.counterCode}>
                <div className={s.description}>{description}</div>
                {this.renderCopyButton()}
                {this.renderCounterCode()}
                {this.renderDefaultDescription()}
            </div>
        );
    }
}
