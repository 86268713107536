import { ITemplate, templates } from '@configs/constructor/templates';
import reportsConfig from '@configs/reports';
import dateConstants from '@constants/date';
import tableConstants from '@constants/table';
import useModalPopup from '@hooks/useModalPopup';
import useQuery from '@hooks/useQuery';
import { useRequestParams } from '@hooks/useRequestParams';
import useSnackbar from '@hooks/useSnackbar';
import { useAppDispatch } from '@hooks/useStore';
import { useTable } from '@hooks/useTable';
import { templatesActions } from '@redux/slices/templates';

import { IQuery } from '@typings/query';
import { IReportRequestParams, IUpdateReportParams } from '@typings/reports';
import { IRootSlice } from '@typings/rootSlice';
import { ISavedTemplate, ITemplatesTableData } from '@typings/templates';
import DateUtils from '@utils/date';
import { queryUtils, urlUtils } from '@utils/index';
import { isNumber } from '@utils/typesChecks';
import { useSelector } from 'react-redux';
import { useLocation, useNavigate, useParams } from 'react-router-dom';

const useTemplates = () => {
    const dispatch = useAppDispatch();
    const navigate = useNavigate();

    const params: IUrlParams = useParams();
    const location = useLocation();
    const { search } = location;
    const query: IQuery = urlUtils.parseQuery(search);
    const { projectId, reportName } = params;

    const savedTemplate = useSelector((state: IRootSlice) => state.templatesSlice.savedTemplate);
    const searchWord = useSelector((state: IRootSlice) => state.tableSlice.titleFilter);

    const isEditTemplate = savedTemplate?.id ?? false;

    const { getParamsFromQuery } = useQuery(reportsConfig);

    const { openModalPopup, closeModalPopup } = useModalPopup();
    const {
        api: { updateTableParams },
    } = useTable();

    const {
        api: { getParamsForRequest },
    } = useRequestParams();

    const { setSnackbar } = useSnackbar();

    const api = {
        init(): void {
            api.getSavedTemplates();
        },

        updateParams(newParams: Record<string, any>): void {
            dispatch(templatesActions.updateParams(newParams));
        },

        resetTemplate(): void {
            api.updateParams({ savedTemplate: null });
        },

        getTemplates(): ITemplate[] {
            const data = templates.map((template) => ({
                ...template,
                children: template.children.map((templateLink) => {
                    const dateStart = templateLink.dateStart
                        ? templateLink.dateStart
                        : DateUtils.getToday()
                              .subtract(templateLink.daysInRange.range, templateLink.daysInRange.period)
                              .startOf(templateLink.daysInRange.period)
                              .format(dateConstants.DATE_FORMAT);
                    const dateEnd = templateLink.dateEnd
                        ? templateLink.dateEnd
                        : DateUtils.getToday().format(dateConstants.DATE_FORMAT);
                    const dateStartQuery = `dateStart=${dateStart}`;
                    const dateEndQuery = `dateEnd=${dateEnd}`;
                    const groupBy = `groupBy=${templateLink.groupBy}`;
                    const metrics = `metrics=${templateLink.metrics.join(',')}`;
                    const dimensions = `dimensions=${templateLink.dimensions.join(',')}`;
                    const order = `orderBy=${templateLink.order || templateLink.metrics[0]}`;
                    const viewType = templateLink.viewType ? `&viewType=${templateLink.viewType}` : '';
                    const globalFilters = templateLink.globalFilters?.length
                        ? `&globalFilters=${queryUtils.arrayToQueryString(
                              templateLink.globalFilters.map((item) => queryUtils.filterObjToQueryString(item)),
                              tableConstants.PARENTS_DELIMITER,
                          )}`
                        : '';

                    const urlParams =
                        `${dateStartQuery}&${dateEndQuery}&${groupBy}&${order}&${metrics}&${dimensions}` +
                        `${viewType}${globalFilters}`;
                    return {
                        ...templateLink,
                        link: `/stat/projects/${projectId}${templateLink.url}?${urlParams}`,
                    };
                }),
            }));
            return data;
        },

        getSavedTemplates(reqParams?: IUpdateReportParams): void {
            dispatch(templatesActions.getTemplates(reqParams));
        },

        getSavedTemplatesOffset(offset: number = 0): void {
            dispatch(templatesActions.updateTemplatesTable({ offset, searchWord }));
        },

        // Обновление таблицы шаблонов, например, при поиске
        updateTable(updateParams: IUpdateReportParams): void {
            updateTableParams(updateParams);
            api.getSavedTemplates(updateParams);
        },

        prepareSavedTemplates(data: ITemplatesTableData[]): ITemplatesTableData[] {
            // TODO: обновить когда появятся норм уровни доступов
            const tableData = data.map((item) => ({
                ...item,
                update_date: DateUtils.readableDay(DateUtils.getDate(item.update_date)),
            }));

            return tableData;
        },

        saveTemplate(templateParams: ISavedTemplate): void {
            dispatch(templatesActions.saveTemplate(templateParams))
                .unwrap()
                .then((res) => {
                    if (res?.responseStatus === 200) {
                        setSnackbar('Отчёт сохранён');
                    }
                });
        },

        updateTemplate(templateParams: ISavedTemplate): void {
            dispatch(templatesActions.updateTemplate(templateParams))
                .unwrap()
                .then((res) => {
                    if (res?.responseStatus === 200) {
                        setSnackbar('Отчёт изменён');
                    }
                });
        },

        getParamsToTemplate(templateName: string, isEdited: boolean = false, id?: number): void {
            const queryParams = getParamsFromQuery(query, reportName);
            const reportParams = getParamsForRequest(reportName, `${queryParams.viewType}Constructor`, {
                ...queryParams,
                projectId,
            });

            const dateEnd = DateUtils.getDate(query.dateEnd);
            const dateStart = DateUtils.getDate(query.dateStart);

            let templateParams: ISavedTemplate = {
                title: templateName,
                body: {
                    ...reportParams,
                    viewType: queryParams.viewType,
                    ...(queryParams.graphs ? { graphs: queryParams.graphs } : {}),
                },
                days_in_range: dateEnd.diff(dateStart, 'day'),
            };

            if (isEdited) {
                templateParams = { ...templateParams, id };
                api.updateTemplate(templateParams);
            } else {
                api.saveTemplate(templateParams);
            }
        },

        deleteTemplate(id: number): void {
            dispatch(templatesActions.deleteTemplate(id))
                .unwrap()
                .then((res) => {
                    if (res?.responseStatus === 200) {
                        setSnackbar('Отчёт удалён');
                    }
                });
        },

        getTemplateActions(actions: Record<string, string>[], template: ISavedTemplate): Record<string, any>[] {
            return actions.map((item) => {
                if (item.id === 'delete') {
                    return {
                        ...item,
                        onClick: () => api.openDeleteTemplatePopup(template.id),
                    };
                }
                if (item.id === 'rename') {
                    return {
                        ...item,
                        onClick: () => api.openRenameTemplatePopup(template.id, template.title),
                    };
                }
                return item;
            });
        },

        getSavedTemplate(id: number): void {
            dispatch(templatesActions.getSavedTemplate(id))
                .unwrap()
                .then((res) => {
                    if (res?.responseStatus === 200) {
                        api.openSavedTemplate(res.data);
                    }
                });
        },

        getLinkToTemplate(linkParams: IReportRequestParams, days: number): string {
            const metrics = `metrics=${linkParams.metrics.join(',')}`;
            const dimensions = `dimensions=${linkParams.dimensions.join(',')}`;
            const order = `orderBy=${linkParams.sort.name}:${linkParams.sort.order}`;
            const dateEnd = `dateEnd=${DateUtils.getToday().format(dateConstants.DATE_FORMAT)}`;
            const dateStart = `dateStart=${DateUtils.getToday().add(-days, 'day').format(dateConstants.DATE_FORMAT)}`;
            const groupBy = `groupBy=${linkParams.group}`;
            const viewType = `viewType=${linkParams.viewType}`;
            const sample = `sample=${linkParams.sample}`;

            // не обязательные
            const graphs = linkParams.graphs?.length
                ? `&graphs=${queryUtils.objectToQueryString(linkParams.graphs[0])}`
                : '';
            const globalFilters = linkParams.dimension_filters?.length
                ? `&globalFilters=${queryUtils.arrayToQueryString(
                      linkParams.dimension_filters.map((item) => queryUtils.filterObjToQueryString(item)),
                      tableConstants.PARENTS_DELIMITER,
                  )}`
                : '';
            const tableFilters = linkParams.metric_filters?.length
                ? `&tableFilters=${queryUtils.arrayToQueryString(
                      linkParams.metric_filters.map((item) => queryUtils.objectToQueryString(item)),
                  )}`
                : '';
            const limit = linkParams.limit ? `&limit=${linkParams.limit}` : '';
            const offset = linkParams.offset ? `&offset=${linkParams.offset}` : '';

            const urlParams =
                `${dateStart}&${dateEnd}&${groupBy}&${order}` +
                `&${metrics}&${dimensions}&${viewType}&${sample}` +
                // не обязательные
                `${graphs}${globalFilters}${tableFilters}${limit}${offset}`;
            return `/stat/projects/${projectId}/new/report/constructor?${urlParams}`;
        },

        openSavedTemplate(templateData: ISavedTemplate): void {
            const linkToTemplate = api.getLinkToTemplate(templateData.body, templateData.days_in_range);

            navigate(linkToTemplate);
        },

        openEditTemplatePopup(): void {
            openModalPopup('SaveTemplate', {
                name: `${savedTemplate.title} (изменён)`,
                type: 'shared',
                isEdited: isEditTemplate,
                saveTemplate: api.getParamsToTemplate,
                closePopup: closeModalPopup,
            });
        },

        openSaveOrEditPopup(): void {
            openModalPopup('SaveOrEdit', {
                saveNewTemplate: api.openEditTemplatePopup,
                saveTemplate: () => api.getParamsToTemplate(savedTemplate.title, true, savedTemplate.id),
                closePopup: closeModalPopup,
            });
        },

        openSaveTemplatePopup(): void {
            openModalPopup('SaveTemplate', {
                name: '',
                type: 'shared',
                isEdited: isEditTemplate,
                saveTemplate: api.getParamsToTemplate,
                closePopup: closeModalPopup,
            });
        },

        openRenameTemplatePopup(templateId?: number, templateTitle?: string): void {
            openModalPopup('SaveTemplate', {
                name: templateTitle || savedTemplate?.title,
                type: 'shared',
                isEdited: isNumber(templateId) ? templateId : savedTemplate?.id,
                title: 'Переименовать отчёт',
                saveTemplate: (newTemplateTitle) =>
                    api.updateTemplate({
                        title: newTemplateTitle,
                        id: isNumber(templateId) ? templateId : savedTemplate?.id,
                    } as ISavedTemplate),
                closePopup: closeModalPopup,
            });
        },

        openDeleteTemplatePopup(templateId: number): void {
            openModalPopup('DeleteTemplate', {
                deleteTemplate: () => api.deleteTemplate(templateId),
                closePopup: closeModalPopup,
            });
        },
    };

    return { api };
};

export default useTemplates;
