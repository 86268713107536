import { Hint } from '@components/Hint';
import numbersUtils from '@utils/numbersUtils';
import React, { FC, useState, useRef, useEffect } from 'react';

import s from './Widget.pcss';

interface IProps {
    count: number;
    label: string;
    hintTitle: string;
}

const Count: FC<IProps> = ({ count = 0, label = '', hintTitle = '' }) => {
    const duration = 1000;
    const [counter, setCounter] = useState<number>(0);
    const previousCount = useRef<number>(0);

    const counterAnimation = (start: number, end: number, isReverse: boolean) => {
        let currentCounter = start;
        const range = Math.abs(end - start);
        const stepTime = Math.floor(duration / range);
        const numberOfSteps = Math.floor(range / (duration / 4)) || 1;

        // В зависимости от направления счетчика
        const countStep = isReverse ? -numberOfSteps : numberOfSteps;
        const checkCounter = isReverse ? (value) => value < end : (value) => value > end;

        const timer = setInterval(() => {
            const newCounter = currentCounter + countStep;
            currentCounter = checkCounter(newCounter) ? end : newCounter;

            // Если новое значение счетчика с учетом шага больше,
            // чем конечное значение, счетчик останавливается
            if (currentCounter === end) clearInterval(timer);

            setCounter(currentCounter);
        }, stepTime);
    };

    useEffect(() => {
        if (count === 0 && previousCount.current === 0) return;

        // Если предыдущее значение count не равно новому, запускается счетчик
        if (previousCount.current !== count) {
            const isReverse = count < previousCount.current;
            counterAnimation(previousCount.current, count, isReverse);
        }

        previousCount.current = count;
    }, [count]);

    return (
        <div className={s.count}>
            <span className={s.countNumber}>{numbersUtils.numberWithSpaces(counter || 0)}</span>
            <span className={s.countLabel}>
                {label}
                {hintTitle && <Hint>{hintTitle}</Hint>}
            </span>
        </div>
    );
};

export default Count;
