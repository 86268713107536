import ContentWithSidebar from '@components/ContentWithSidebar';
import Dropdown from '@components/Dropdown';
import PageHead from '@components/PageHead';
import ReportCards from '@components/ReportCards';
import SimpleTable from '@components/SimpleTable';
import Subheader from '@components/Subheader';

import { templatesConfig } from '@configs/constructor/templates';
import useGlobalLoading from '@hooks/useGlobalLoading';
import useProjectRequired from '@hooks/useProjectRequired';
import useTemplates from '@hooks/useTemplates';
import IconSettings from '@images/svg/icons/icon-settings.svg';
import { globalActions } from '@redux/slices/global';
import { IRootSlice } from '@typings/rootSlice';
import { ISavedTemplate } from '@typings/templates';
import React, { useCallback, useEffect } from 'react';
import DocumentTitle from 'react-document-title';
import { useSelector, useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';

import s from './Templates.pcss';

const Templates: React.FC = () => {
    useGlobalLoading();

    const params: IUrlParams = useParams();
    const projectId = Number(params.projectId);
    const title = templatesConfig.title;
    const reportName = templatesConfig.reportName;

    const { api } = useTemplates();

    const isProjectRequired = useProjectRequired();
    const globalLoading = useSelector((state: IRootSlice) => state.globalSlice.globalLoading);
    const projectURL = useSelector((state: IRootSlice) => state.projectsSlice.current.cleanedUrl);

    const userId = useSelector((state: IRootSlice) => state.userSlice.id);

    const tableData = useSelector((state: IRootSlice) => state.templatesSlice.tableData);
    const totals = useSelector((state: IRootSlice) => state.templatesSlice.totals);
    const templatesRequest = useSelector((state: IRootSlice) => state.templatesSlice.templatesRequest);
    const deleteTemplateRequest = useSelector((state: IRootSlice) => state.templatesSlice.deleteTemplateRequest);

    const titleFilter = useSelector((state: IRootSlice) => state.tableSlice.titleFilter);

    const dispatch = useDispatch();
    const setGlobalLoading = useCallback(
        (value: boolean) => dispatch(globalActions.setGlobalLoading(value)),
        [dispatch],
    );

    const getPageTitle = () => {
        const url = projectURL ? `(${projectURL}) ` : '';
        return `${title} ${url} – Статистика от SberAds / ${projectId}`;
    };

    useEffect(() => {
        setGlobalLoading(false);
        api.resetTemplate();
    }, []);

    useEffect(() => {
        if (isProjectRequired) {
            setGlobalLoading(false);
        }
    }, [isProjectRequired]);

    useEffect(() => {
        if (userId !== null) api.init();
    }, [projectId]);

    const getActions = (template: ISavedTemplate) => (
        <Dropdown
            type="listCustom"
            isShowArrow={false}
            anchorPrefixIcon={<IconSettings />}
            data={api.getTemplateActions(templatesConfig.templateActions, template)}
            anchorClassName={s.settingsAnchor}
            isAnchorButton={false}
        />
    );

    const searchHandler = (value: string) => {
        api.updateTable({ titleFilter: value });
    };

    return (
        <DocumentTitle title={getPageTitle()}>
            {!globalLoading && (
                <>
                    <PageHead
                        reportName={reportName}
                        projectId={projectId}
                        userId={userId}
                        title={title}
                        hintContent={templatesConfig.reportHint}
                    />
                    <ContentWithSidebar>
                        <ReportCards
                            data={api.getTemplates()}
                            createLink={`/stat/projects/${projectId}/new/report/constructor`}
                        />
                        <Subheader
                            reportName={reportName}
                            reportTitle="Мои отчёты"
                            projectId={projectId}
                            userId={userId}
                        />
                        <SimpleTable
                            loading={templatesRequest || deleteTemplateRequest}
                            tableData={api.prepareSavedTemplates(tableData)}
                            getTableOffset={api.getSavedTemplatesOffset}
                            headTitles={templatesConfig.tableHeadTitles}
                            totals={totals}
                            getActions={getActions}
                            onClickRow={api.getSavedTemplate}
                            onTitleFilter={searchHandler}
                            titleFilter={titleFilter}
                        />
                    </ContentWithSidebar>
                </>
            )}
        </DocumentTitle>
    );
};

export default Templates;
