import { formDecorator } from '@decorators/index';
import { globalActions } from '@redux/slices/global';
import { projectsActions } from '@redux/slices/projects';
import { INotification } from '@typings/notification';
import { listDataUtils } from '@utils/index';
import { Dispatch } from 'react';

import SecondStep from './SecondStep';

const mapStateToProps = ({ globalSlice, projectsSlice }) => ({
    config: globalSlice.projectConfig,
    currentProject: projectsSlice.current,
    createdCounter: projectsSlice.createdCounter,
    saveCounterRequest: projectsSlice.saveCounterRequest,
});
const mapDispatchToProps = (dispatch: Dispatch<any>) => ({
    saveCounter: (id: number, data: Record<string, any>) => dispatch(projectsActions.save({ id, data })),
    setSnackbar(message: any, type: string) {
        dispatch(globalActions.setSnackbar({ message, type }));
    },
    onSubmit(values, invalid, saveCounter, setFieldErrors, setServerError, createdCounter, config, openModalPopup) {
        if (invalid) {
            return;
        }

        const categories = values.categories
            ? listDataUtils.getThemeIdsFromCategories(values.categories, config.categories.data)
            : [];

        const id = createdCounter.project_id;
        const data = {
            counter_settings: {
                block_analytics_attribute: values.blockAnalyticsAttribute,
                merge_subdomains: values.mergeSubdomains,
                track_hashes: values.useHashes,
                track_user_id: values.useUserId,
                use_block_analytics: values.useBlockAnalytics,
                blacklisted_urls: values.blacklistedUrls,
                merge_www: !values.mergeWww,
                use_whitelist: values.useWhitelist,
                whitelisted_urls: values.whitelistedUrls,
                color: values.color,
                counter_type: Number(values.counterType),
            },
            public_stats: values.publicStats === 'opened',
            title: values.title,
            in_rating: values.inRating,
            regions: values.regions ? values.regions : [],
            types: values.types ? values.types : [],
            categories,
            keywords: values.keywords,
        };

        saveCounter(id, data).then(({ payload }) => {
            if (payload.responseStatus === 200) {
                openModalPopup('CreateCounterFinal', { projectId: id });
            } else if (payload.errors) {
                if (Object.keys(payload.errors).length > 1) {
                    setFieldErrors(payload.errors);
                } else if (payload.errors.message) {
                    setServerError(payload.errors.message);
                }
            }
        });
    },

    openModalPopup(modalPopupId, projectId) {
        dispatch(globalActions.openModalPopup({ modalPopupId, modalPopupParams: projectId }));
    },

    setNotification(notification: INotification) {
        dispatch(globalActions.setNotification(notification));
    },

    clearNotification() {
        dispatch(globalActions.clearNotification());
    },
});

export default formDecorator({
    formName: 'counterSecondStep',
    isRemoveForm: false,
    initializeValues: ({ formsSlice }) => {
        const firstFormFields = formsSlice.forms.counterFirstStep.fields;
        return {
            url: firstFormFields.url.value,
            title: firstFormFields.title.value,
            color: '#313EFB',
            publicStats: 'opened',
            blockAnalyticsAttribute: 'your-attribute',
        };
    },
    mapStateToProps,
    mapDispatchToProps,
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
})(SecondStep);
