import WidgetTable from '@components/WidgetTable';
import { DataArrayType } from '@typings/graph';
import { Metrics } from '@typings/metrics';
import { ISort } from '@typings/table';
import React from 'react';

import s from './Widget.pcss';

interface IProps {
    data: DataArrayType | DataArrayType[];
    isDisabledTabs?: boolean;
    tableTitle: string;
    notHiddenHeads: boolean;
    emptyTableTitleAlias: string;
    selected: Metrics;
    sort: ISort;
    onChangeSort: (visibleMetrics: Metrics[], selectedMetric: Metrics, sort: { name: string; order: string }) => void;
    name: string;
    metrics: Metrics[];
    labels: string[];
    dataTotals: [string, number][];
    isDisabledSort: boolean;
    isShowAllMetrics?: boolean;
    attrCnfName?: string;
    reqError?: boolean;
    isShowOneMetric?: boolean;
}

const Table: React.FC<IProps> = ({
    data,
    selected,
    metrics,
    isDisabledTabs,
    sort,
    name,
    onChangeSort,
    tableTitle = 'URL страницы',
    notHiddenHeads,
    emptyTableTitleAlias = 'Нет названия',
    labels = [],
    dataTotals = [],
    isDisabledSort,
    isShowAllMetrics,
    attrCnfName,
    reqError = false, // ошибка запроса данных виджета
    isShowOneMetric,
}) => {
    let visibleMetrics = !isDisabledTabs || isShowOneMetric ? [selected] : metrics;

    if (isShowAllMetrics) visibleMetrics = metrics;

    // !reqError - первичный рендер,
    // так как selected не определён при первичном рендере и ошибке запроса данных
    if (!selected && !reqError) return null;

    return (
        <div className={s.table}>
            <WidgetTable
                visibleMetrics={visibleMetrics}
                selectedMetric={selected}
                tableTitle={tableTitle}
                notHiddenHeads={notHiddenHeads}
                emptyTableTitleAlias={emptyTableTitleAlias}
                dataTable={data}
                dataTotals={dataTotals}
                isDisabledTabs={isDisabledTabs}
                onChangeSort={onChangeSort}
                isDisabledSort={isDisabledSort}
                sort={sort}
                name={name}
                labels={labels}
                attrCnfName={attrCnfName}
            />
        </div>
    );
};

export default Table;
