import * as React from 'react';

import TextareaField from './TextareaField';

import s from './TextareaType.pcss';

interface IProps {
    options: Record<string, any>;
}

const renderItem = (item, index) => (
    <div className={s.rulesItem} key={index}>
        <div className={s.rulesMark}>{item.mark}</div>
        <div className={s.rulesText}>{item.text}</div>
    </div>
);

export default ({ options }: IProps) => (
    <div className={s.textarea}>
        <TextareaField options={options} />

        {options.descr && <div className={s.descr}>{options.descr}</div>}

        {options.rules && <div className={s.rules}>{options.rules.map(renderItem)}</div>}
    </div>
);
