import { Tooltip } from '@adtech/ui';
import { IconNoFavicon } from '@images/svg/icon-no-favicon';
import IconCounterStatus from '@images/svg/icons/icon-counter-status.svg';
import { top100Counter } from '@utils/index';
import cn from 'classnames';
import React from 'react';
import { Link } from 'react-router-dom';
import s from './Table.pcss';

interface IProps {
    isDeleted: boolean;
    status: boolean;
    title: string;
    url: string;
    id: number;
    favicon: string;
}

const Table: React.FC<IProps> = ({ status, title, url, id, favicon, isDeleted }) => {
    const className = cn(s.project, {
        [s.projectDelete]: isDeleted,
    });

    // TODO: status сейчас не приходит, нужно будет доработать
    const statusClassName = cn(s.projectStatus, {
        [s.projectStatusOn]: status,
    });
    const statusMessage = status ? 'Счётчик корректно установлен' : 'Счётчик не установлен';

    const handleClick = () => {
        top100Counter.trackEvent('click_project_in_my_sites', { projectId: id });
    };

    return (
        <div className={className}>
            {!isDeleted && (
                <div className={statusClassName}>
                    <Tooltip title={statusMessage} overlayClassName={s.projectStatusTooltip}>
                        <IconCounterStatus />
                    </Tooltip>
                </div>
            )}
            <div className={s.projectLogo}>
                {favicon ? <img src={`data:image/png;base64, ${favicon}`} alt="" /> : <IconNoFavicon />}
            </div>
            <div className={s.projectBody}>
                <Link to={`/stat/projects/${id}/dashboard`} className={s.title} onClick={handleClick}>
                    <div className={s.titleText}>{title}</div>
                </Link>
                <a href={url} className={s.projectUrl} target="_blank" rel="noopener">
                    <div className={s.projectUrlText}>{url}</div>
                </a>
                <div className={s.projectId}>{id}</div>
            </div>
        </div>
    );
};

export default Table;
