import { imgSrc, imgSet } from '@images/error500';
import React from 'react';

export default {
    imgSrc,
    imgSet: `${imgSet[0]} 2x, ${imgSet[1]} 3x`,
    code: '500',
    title: 'Ошибка сервера',
    getContent() {
        return (
            <div>
                <p>Сервер немного отвлекся.</p>
                <p>
                    Попробуйте перезагрузить страницу или поискать нужный счётчик в&nbsp;
                    <a href="/stat/projects" target="_self">
                        Моих сайтах
                    </a>
                    .
                </p>
            </div>
        );
    },
};
