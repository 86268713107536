import { Radio } from '@adtech/ui';
import listDataConstants from '@constants/listData';
import fieldDecorator from '@decorators/fieldDecorator';
import React, { FC } from 'react';

import s from './ConditionType.pcss';

interface IProps {
    activeItem: Record<string, any>;
    field: Record<string, any>;
}

const ConditionType: FC<IProps> = ({ activeItem = {}, field = {} }) => {
    const { onChange, value } = field;
    const renderItem = (item: Record<string, any>, key: number) => (
        <Radio key={key} value={item.value} checked={item.value === value} onChange={onChange} className={s.radio}>
            {item.label}
        </Radio>
    );

    return <div className={s.root}>{listDataConstants.conditionType[activeItem.condition].map(renderItem)}</div>;
};

export default fieldDecorator({
    ignoreHidden: true,
    name: 'condition',
    type: 'string',
})(ConditionType);
