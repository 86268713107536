import { Slider } from '@adtech/ui';
import Dropdown from '@components/Dropdown';
import { IUpdateMediaParams } from '@typings/media';
import { IUpdateReportOptions, IUpdateReportParams, ReportName } from '@typings/reports';
import { top100Counter } from '@utils/index';
import numbersUtils from '@utils/numbersUtils';
import React, { useEffect, useState } from 'react';

import s from './SampleField.pcss';

enum ESample {
    step1 = 0.01,
    step2 = 0.1,
    step3 = 1,
}

interface IProps {
    sample: number;
    activeSample: boolean;
    updateReport: (
        type: string,
        options: IUpdateReportOptions<IUpdateReportParams | IUpdateMediaParams, ReportName>,
    ) => void;
    onChangeSample?: (newSample: number) => void;
}

const SampleField: React.FC<IProps> = ({ sample = 0, activeSample = false, updateReport, onChangeSample }) => {
    // Преобразуем sample в красивое название
    const getPercentSample = (value) => {
        const percentSample = numbersUtils.convertPercent(value);
        const sampleValue = percentSample < 1 ? 'меньше 1%' : `${percentSample}%`;

        const data = [
            {
                value: 'sampleId',
                title: sampleValue,
            },
        ];

        return data;
    };

    // Делаем в слайдере шаг 1%-10%-100%
    // Преобразуем реальное значение sample в шаг
    const sampleToStep = (realSample) => {
        if (realSample < ESample.step2) {
            return 1;
        }

        if (realSample < ESample.step3) {
            return 2;
        }

        return 3;
    };

    // Преобразуем шаг в sample
    const stepToSample = (step: number): ESample => {
        switch (step) {
            case 1:
                return ESample.step1;
            case 2:
                return ESample.step2;
            default:
                return ESample.step3;
        }
    };

    const [sampleStep, setSampleStep] = useState(sampleToStep(sample));
    const [sampleReal, setSampleReal] = useState(sample);
    const sampleView = getPercentSample(sample);

    useEffect(() => {
        setSampleStep(sampleToStep(sample));
        setSampleReal(sample);
    }, [sample]);

    const onChange = (newValue) => {
        let eventValue = '';
        const newSample = stepToSample(newValue);
        setSampleStep(newValue);
        setSampleReal(newSample);

        switch (newSample) {
            case ESample.step1:
                eventValue = '1_min';
                break;
            case ESample.step2:
                eventValue = '10_mid';
                break;
            default:
                eventValue = '100_max';
        }
        top100Counter.trackEvent(`stat_sample_chose_${eventValue}`);
    };

    const onClose = () => {
        if (sampleReal === sample) return;
        const newSample = stepToSample(sampleStep);

        updateReport('all', {
            updateParams: {
                sample: newSample,
            },
            options: {
                withoutCompare: true,
            },
        });

        if (onChangeSample) onChangeSample(newSample);

        top100Counter.trackEvent('stat_sample_submit');
    };

    const renderSlider = () => {
        if (!activeSample) return null;

        return (
            <div className={s.sampleInner}>
                <div className={s.label}>
                    <div className={s.labelLeft}>Быстрее</div>
                    <div className={s.labelCenter}>10%</div>
                    <div className={s.labelRight}>Точнее</div>
                </div>
                <Slider
                    min={1}
                    max={3}
                    step={1}
                    value={sampleStep}
                    startPoint={2}
                    onChange={onChange}
                    tooltip={{
                        open: false,
                    }}
                />
            </div>
        );
    };

    return (
        <div className={s.root}>
            <Dropdown
                contentClassName={s.sample}
                type="customContent"
                data={sampleView}
                dataInitialSelected={sampleView[0].value}
                anchorText="Точность: "
                anchorTextOutside
                isDisabled={!activeSample}
                customContent={renderSlider()}
                onClick={() => top100Counter.trackEvent('stat_sample_open_btn')}
                onClose={onClose}
                dataTestId="sample-field"
                btnDataTestId="sample-button"
            />
        </div>
    );
};

export default SampleField;
