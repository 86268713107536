export default {
    default: {
        text: 'Нет данных для отображения',
    },
    rating: {
        default: {
            text: 'Cайт еще не участвует в рейтинге.',
            linkText: 'Участвовать',
            needAccess: true,
            url: (projectId: number) => `/stat/projects/${projectId}/settings/rating`,
            target: '_self',
        },
        dropped_out: {
            text: 'Сайт был исключен из рейтинга. Чтобы вернуть обратитесь в',
            linkText: 'службу поддержки',
            url: 'mailto:counter@ads.sber.ru',
        },
        excluded: {
            text: 'Сайт был исключен из рейтинга. Чтобы вернуть обратитесь в',
            linkText: 'службу поддержки',
            url: 'mailto:counter@ads.sber.ru',
        },
        deleted: {
            text: 'Для возвращения сайта в рейтинг его нужно убрать из ',
            linkText: 'списка удаленных ',
            url: 'https://ads.sber.ru/stat/projects/deleted/?page=1',
        },
    },
    analytics: {
        text: 'Нет данных.',
        linkText: 'Настроить счётчик можно тут',
        url: 'https://ads.sber.ru/help/nastroika-sbora-i-otpravki-dannykh',
    },
};
