import reportsConfig from '@configs/reports';
import { getReportGraph, getMediaGraph, getConstructorGraph } from '@redux/slices/graph/api';
import { createSlice } from '@reduxjs/toolkit';
import { ISelectedLine } from '@typings/graph';
import { IGraphSlice } from '@typings/rootSlice';
import eventBasedUtils from '../table/eventBasedUtils';
import graphUtils from './utils';

export const initialState: IGraphSlice = {
    graphRequest: true,
    graphRequests: [],
    loadingSelectedLines: [],
    graphData: [],
    graphTotals: [],
    currentGraphsTotals: [],
    graphs: [],
    selectedLines: [],
    useDefaultLines: true,
    graphCustomTitle: null,
    lastRequestId: 0,
    errorCode: null,
};

/**
 * Включаем режим загрузки данных у графика
 */
export const setRequestGraphFunc = (state: IGraphSlice, action) => {
    const { chartOrder } = action.meta?.arg?.meta || {};
    state.errorCode = null;

    if (chartOrder !== undefined && !state.graphRequests.includes(chartOrder)) {
        state.graphRequests.push(chartOrder);
    } else {
        state.graphRequest = true;
    }
};

/**
 * Устанавливаем полученные данные в график отчётов
 */
export const setGraph = (state: IGraphSlice, action) => {
    const { chartOrder, isNewSelectedLines, lineIds, tableData, lastRequestId } = action.payload?.metaInfo || {};

    // Отбрасываем результаты старых запросов
    if (lastRequestId < state.lastRequestId) return state;

    const { dict } = tableData || {};
    const { totals, currentGraphsTotals, data } = graphUtils.prepareGraphData(action.payload, dict, lineIds);

    const errorCode = null;

    const graphRequests: number[] =
        chartOrder !== undefined && state.graphRequests.length && state.graphRequests.includes(chartOrder)
            ? state.graphRequests.filter((item) => item !== chartOrder)
            : [];

    let graphData = [];
    let graphTotals = [];
    let newCurrentGraphsTotals = [];
    let selectedLines: ISelectedLine[] = [...state.selectedLines];
    let loadingSelectedLines: ISelectedLine[] = [...state.loadingSelectedLines];

    // при добавлении новой линии на график
    if (isNewSelectedLines) {
        // функция фильтрации ненужных данных
        const filterFn = (item) =>
            loadingSelectedLines.find((line) => line.id === item.id) &&
            !state.graphData.find((curItem) => curItem.id === item.id);

        const getColorIndex = (lineId) => {
            const findLine = loadingSelectedLines.find((item) => item.id === lineId);
            return findLine ? findLine.colorIndex : 0;
        };

        // получаем индексы отфильтрованных данных
        const filteredDataIndex = data
            .map((item, index) => ({ id: item.id, index }))
            .filter(filterFn)
            .map((item) => item.index);

        // фильтруем данные для графика
        const filteredData = data.filter(filterFn);

        const filteredTotals = totals.filter((item, index) => filteredDataIndex.includes(index));

        // подмерживаем к результату
        graphData = [...state.graphData, ...filteredData];
        graphTotals = [...state.graphTotals, ...filteredTotals];
        newCurrentGraphsTotals = [...state.currentGraphsTotals];

        const newLines = filteredData.map((item) => ({
            id: item.id,
            colorIndex: getColorIndex(item.id),
        }));

        selectedLines = [...selectedLines, ...newLines];
        loadingSelectedLines = loadingSelectedLines.filter((item) => newLines.find((line) => line.id !== item.id));
        // или же перезаписываем новыми данными
    } else {
        graphData = [...data];
        graphTotals = [...totals];
        newCurrentGraphsTotals = currentGraphsTotals ? [...currentGraphsTotals] : [];
        selectedLines = selectedLines.filter((line) => graphData.find((item) => item.id === line.id));
    }

    graphData = graphData.map((item) => graphUtils.processTitleGraph(item, dict));

    Object.assign(state, {
        selectedLines,
        loadingSelectedLines,
        graphData,
        graphTotals,
        currentGraphsTotals: newCurrentGraphsTotals,
        graphRequest: false,
        graphRequests,
        errorCode,
    });
};

export const resetRequestGraphFunc = (state: IGraphSlice) => {
    state.graphRequest = false;
};

/**
 * Устанавливаем полученные данные в график в отчёте Summary
 */
export const setGraphSummaryFunc = (state: IGraphSlice, action) => {
    let { data: graphData } = action.payload?.result || {};

    if (graphData.length > 0) {
        graphData = [
            {
                id: 'summary',
                title: 'summary',
                data: graphData.map((item) => [item.id, ...item.metrics]),
            },
        ];
    }

    state.graphRequest = false;
    state.graphData = graphData;
};

/**
 * Обнуляем данные в графике отчётов
 */
export const clearGraph = (state: IGraphSlice, action?) => {
    Object.assign(state, {
        graphData: [],
        graphTotals: [],
        loadingSelectedLines: [],
        graphRequest: action?.payload || false,
        graphCustomTitle: null,
    });
};

/**
 * Удаляем определённые линии с графика
 */
export const removeLinesFunc = (state: IGraphSlice, action) => {
    const lines = action.payload;

    state.selectedLines = graphUtils.filterLines(state.selectedLines, lines);
    state.loadingSelectedLines = graphUtils.filterLines(state.loadingSelectedLines, lines);
};

/**
 * Возвращаем удаленные линии назад
 * @returns {*}
 */
export const recoveryRemovedLinesFunc = (state: IGraphSlice, action) => {
    const lines = action.payload;

    state.selectedLines.push(...lines.filter((line) => !state.selectedLines.includes(line)));
};

/**
 * Установка параметра, определяющего необходимость в использовании дефолтного количества линий
 */
export const toggleUseDefaultLinesFunc = (state: IGraphSlice, action) => {
    state.useDefaultLines = action.payload;
};

export const updateParamsFunc = (state: IGraphSlice, action) => {
    const newState = { ...state };
    const newParams = { ...action.payload?.params };

    const isNewSelectedLines = action.payload?.meta?.isNewSelectedLines || false;

    // если нам приходит массив с selectedLines
    // добавляем его в стейт, или оставляем то что есть
    const selectedLines =
        newParams.selectedLines && !isNewSelectedLines ? [...newParams.selectedLines] : [...newState.selectedLines];

    // а если мы добавляем новую линию на графике,
    // добавляем ее в список loadingSelectedLines
    // (после получения данных, она переместится в selectedLines)
    let loadingSelectedLines = [...newState.loadingSelectedLines];
    if (isNewSelectedLines) {
        // фильтруем новые линии, если они уже есть в списке loadingSelectedLines
        const newLoadingSelectedLines = newParams.selectedLines.filter((line) => !loadingSelectedLines.includes(line));

        loadingSelectedLines = [...loadingSelectedLines, ...newLoadingSelectedLines];
    }

    Object.assign(state, newParams);
    state.selectedLines = selectedLines;
    state.loadingSelectedLines = loadingSelectedLines;
};

export const resetParamFunc = (state: IGraphSlice, action) => {
    const param = action.payload;

    if (!(param in initialState)) return state;

    state[param] = initialState[param];
};

/**
 * Конструктор
 */
export const setRequestGraphConstructor = (state: IGraphSlice, action) => {
    const { lastRequestId } = action.meta?.arg || {};

    // Отбрасываем результаты старых запросов
    if (lastRequestId < state.lastRequestId) return state;

    state.graphRequest = true;
    state.errorCode = null;
};

export const setGraphConstructor = (state: IGraphSlice, action) => {
    const { metrics, dimensions, reportName, tableData, lastRequestId } = action.payload?.metaInfo || {};

    // Отбрасываем результаты старых запросов
    if (lastRequestId < state.lastRequestId) return state;

    const defaultGraphs = reportsConfig[reportName].defaultGraphs;
    const { graphCustomTitle, graphTotals, selectedLines } = eventBasedUtils.prepareSelectedLines(
        dimensions,
        tableData,
    );
    const graphs = eventBasedUtils.updateGraph(metrics, state.graphs, defaultGraphs);
    const data = eventBasedUtils.prepareGraphConstructorData(action.payload, selectedLines);
    const graphData = data.map((item) => graphUtils.processTitleGraph(item, tableData.dict));

    Object.assign(state, {
        selectedLines,
        graphs,
        graphData,
        graphCustomTitle,
        currentGraphsTotals: tableData.totals,
        graphTotals,
        graphRequest: false,
        errorCode: null,
    });
};

const graphSlice = createSlice({
    name: 'graphSlice',
    initialState,
    reducers: {
        setRequestGraph: (state, action) => setRequestGraphFunc(state, action),
        setGraphSummary: (state, action) => setGraphSummaryFunc(state, action),
        resetRequestGraph: (state) => resetRequestGraphFunc(state),
        resetGraph: (state, action?) => clearGraph(state, action),
        toggleUseDefaultLines: (state, action) => toggleUseDefaultLinesFunc(state, action),
        removeLines: (state, action) => removeLinesFunc(state, action),
        recoveryRemovedLines: (state, action) => recoveryRemovedLinesFunc(state, action),
        updateParams: (state, action) => updateParamsFunc(state, action),
        resetParam: (state, action) => resetParamFunc(state, action),
    },
    extraReducers: (builder) => {
        builder
            .addCase(getReportGraph.pending, (state, action) => setRequestGraphFunc(state, action))
            .addCase(getReportGraph.fulfilled, (state, action) => setGraph(state, action))

            .addCase(getMediaGraph.pending, (state, action) => setRequestGraphFunc(state, action))
            .addCase(getMediaGraph.fulfilled, (state, action) => setGraph(state, action))

            .addCase(getConstructorGraph.pending, (state, action) => setRequestGraphConstructor(state, action))
            .addCase(getConstructorGraph.fulfilled, (state, action) => setGraphConstructor(state, action));
    },
});

export const {
    setRequestGraph,
    setGraphSummary,
    resetRequestGraph,
    resetGraph,
    toggleUseDefaultLines,
    removeLines,
    recoveryRemovedLines,
    updateParams,
    resetParam,
} = graphSlice.actions;

export const graphActions = {
    setRequestGraph,
    setGraphSummary,
    resetRequestGraph,
    resetGraph,
    toggleUseDefaultLines,
    removeLines,
    recoveryRemovedLines,
    updateParams,
    resetParam,
    getReportGraph,
    getMediaGraph,
    getConstructorGraph,
};

export default graphSlice.reducer;
