import { globalActions } from '@redux/slices/global';
import { AppDispatch } from '@redux/store';
import React from 'react';
import { connect } from 'react-redux';

interface IProps {
    dispatch: AppDispatch;
    children: any;
}

/**
 * Запрашиваем конфигурационные данные проекта
 */
export default function loadProjectConfigs(Component: any) {
    class LoadProjectConfigsHOC extends React.Component<IProps> {
        componentDidMount() {
            this.getConfigs();
        }

        getConfigs = () => {
            const { dispatch } = this.props;

            dispatch(globalActions.getRegionsConfig());
            dispatch(globalActions.getTypesConfig());
            dispatch(globalActions.getThemesConfig());
        };

        render() {
            const { children } = this.props;

            const component = Component ? <Component /> : children;

            return React.cloneElement(component, this.props);
        }
    }

    return connect()(LoadProjectConfigsHOC);
}
