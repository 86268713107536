import { Checkbox } from '@adtech/ui';
import { IDataListItem } from '@components/DataList';
import WidgetFilter from '@components/WidgetFilter';
import { isFunction } from '@utils/typesChecks';
import React, { FC, useRef, useState } from 'react';

interface IProps {
    eventsList: IDataListItem[];
    eventsListRequest: boolean;
    onClick: () => void;
    onApply: (value: Record<string, string[]>) => void;
}

const EventsWidgetFilter: FC<IProps> = ({ eventsList, eventsListRequest = false, onClick, onApply }) => {
    const [eventsChecked, setEventsChecked] = useState<Record<string, string[]>>({});
    const [eventsCheckedCount, setEventsCheckedCount] = useState(0);
    const prevEvents = useRef<string>('{}');

    const onCheckEvent = (checked: boolean, item: IDataListItem) => {
        const { name, parent: eventClass } = item;
        let eventsObj = { ...eventsChecked };

        if (checked) {
            setEventsCheckedCount(eventsCheckedCount + 1);
            const isClassExists = !!eventsChecked[eventClass];

            if (isClassExists) {
                eventsObj = {
                    ...eventsObj,
                    [eventClass]: [...eventsChecked[eventClass], name],
                };
            } else {
                eventsObj = { ...eventsObj, [eventClass]: [name] };
            }
        } else {
            setEventsCheckedCount(eventsCheckedCount - 1);
            const eventsNamesArray = eventsChecked[eventClass];

            if (eventsNamesArray?.length > 1) {
                const filteredEvents = eventsNamesArray.filter((en) => en !== name);
                eventsObj = { ...eventsObj, [eventClass]: filteredEvents };
            } else {
                delete eventsObj[eventClass];
            }
        }

        setEventsChecked(eventsObj);
    };

    const renderListItem = (item: IDataListItem) => {
        const { name, title, parent: eventClass } = item;
        const checkedStatus = eventsChecked[eventClass]?.includes(name) || false;

        return (
            <Checkbox
                key={name}
                name={name}
                checked={checkedStatus}
                disabled={!checkedStatus && eventsCheckedCount >= 5}
                onChange={(e) => onCheckEvent(e?.target?.checked, item)}
            >
                {title}
            </Checkbox>
        );
    };

    const onCloseDropdown = () => {
        if (prevEvents.current === JSON.stringify(eventsChecked)) return;

        prevEvents.current = JSON.stringify(eventsChecked);
        if (isFunction(onApply)) onApply(eventsChecked);
    };

    return (
        <WidgetFilter
            list={eventsList}
            listRequest={eventsListRequest}
            renderListItemHandler={renderListItem}
            onClick={onClick}
            onClose={onCloseDropdown}
        />
    );
};

export default EventsWidgetFilter;
