import { Alert, Input, Loader } from '@adtech/ui';
import { Hint } from '@components/Hint';
import fieldDecorator from '@decorators/fieldDecorator';
import { IProps as FieldProps } from '@decorators/fieldDecorator/types';
import { IField, IMetaField } from '@typings/form';
import { formUtils } from '@utils/index';
import cn from 'classnames';
import React, { ReactNode } from 'react';

import s from './InputData.pcss';

interface IProps {
    messageUrl?: ReactNode | string;
    messageTitle?: ReactNode | string;
    disabledUrl?: boolean;
    disabledTitle?: boolean;
    isShowMaxLength?: boolean;
    maxTitleLength: number;
    getDefaultTitle?;
    onChangeSiteName?;
    inRating?: boolean;
}

interface IInputType extends FieldProps {
    meta: IMetaField;
    field: IField;
    maxTitleLength: number;
    isShowMaxLength: boolean;
    inRating: boolean;
    disabled: boolean;
    placeholder: string;
    label: string;
    message: string;
    showHint: boolean;
    hint: string;
    getDefaultTitle;
    onChangeSiteName;
}

export const InputComponent = ({
    meta,
    field,
    label,
    maxTitleLength = 0,
    inRating = false,
    isShowMaxLength = true,
    disabled = false,
    placeholder = '',
    message = '',
    showHint = false,
    hint = '',
    getDefaultTitle = () => {},
    onChangeSiteName = () => {},
}: IInputType) => {
    const isError = inRating ? meta.invalid : meta.invalid && meta.touched && !meta.focused;
    const value = field?.value as string;
    const { name, onChange, onClick, onFocus } = field;

    if (field.name === 'title' && meta.changed) {
        onChangeSiteName();
    }

    return (
        <div className={cn(s.inputWrapper, { [s.disabled]: disabled })}>
            <div className={s.label}>{label}</div>
            {showHint && <Hint className={s.formGroupHint}>{hint}</Hint>}
            <Input
                name={name}
                value={value}
                onChange={onChange}
                onClick={onClick}
                onFocus={onFocus}
                onBlur={(e) => {
                    field.onBlur(e);
                    if (meta.valid) getDefaultTitle(field.value);
                }}
                disabled={disabled}
                placeholder={placeholder}
                type="text"
                status={isError ? 'error' : null}
                errorText={isError ? meta.errorText : ''}
                suffix={meta.asyncValidating ? <Loader size="small" /> : <span />}
                className={s.input}
                maxLength={isShowMaxLength ? maxTitleLength : null}
            />
            {message && <Alert type="info" message={message} showIcon className={s.alert} />}
        </div>
    );
};

export const TitleField = fieldDecorator({
    rules: [
        { validate: formUtils.defaultRules.required, errorType: 'required' },
        {
            validate: (value, values, props) => formUtils.defaultRules.maxLength(props.maxTitleLength)(value),
            errorType: 'maxLength',
            customErrorText: 'Превышен лимит символов',
        },
        {
            validate: formUtils.defaultRules.minLength(3),
            errorType: 'minLength',
            customErrorText: 'Минимум 3 символа',
        },
    ],
    name: 'title',
    label: 'Название сайта',
    placeholder: 'Будет отображаться в списке сайтов',
    shouldReValidateReceive: (prevProps, nextProps) => prevProps.inRating !== nextProps.inRating,
})(InputComponent);

export const UrlField = fieldDecorator({
    rules: [
        { validate: formUtils.defaultRules.required },
        {
            validate: formUtils.defaultRules.maxLength(200),
            customErrorText: 'URL не должен превышать 200 символов',
        },
        { validate: formUtils.defaultRules.urlWithDomainZone },
        { validate: formUtils.defaultRules.urlWithProtocolAndParams },
    ],
    name: 'url',
    label: 'Адрес сайта',
    placeholder: 'http://',
    isShowMaxLength: false,
})(InputComponent);

const RootCom: React.FC<IProps> = ({
    messageUrl,
    messageTitle,
    disabledUrl,
    disabledTitle,
    isShowMaxLength,
    maxTitleLength,
    getDefaultTitle = () => {},
    onChangeSiteName = () => {},
    inRating = false,
}) => (
    <>
        <UrlField disabled={disabledUrl} getDefaultTitle={getDefaultTitle} message={messageUrl} />
        <TitleField
            disabled={disabledTitle}
            isShowMaxLength={isShowMaxLength}
            maxTitleLength={maxTitleLength}
            inRating={inRating}
            message={messageTitle}
            onChangeSiteName={onChangeSiteName}
        />
    </>
);
export default RootCom;
