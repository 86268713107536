/* eslint-disable max-len */
import { ChartTypes } from '@configs/graph';
import { sortOrders } from '@constants/sort';
import { IReportConfig } from '@typings/reports';

const reportConfig: IReportConfig = {
    reportTitle: 'Аналитика блоков страниц',
    tableDataType: 'dynamic',
    tableTitle: 'Параметры data-атрибутов',
    defaultSort: {
        name: 'usability_views',
        order: sortOrders.DESC,
    },
    numberOfGraphs: 'two',
    defaultGraphs: [
        ChartTypes.line,
        ChartTypes.bar,
    ],
    graphDefaultMetrics: [
        'usability_views',
        'usability_ctr',
    ],
    tableDefaultMetrics: [
        'usability_views',
        'usability_clicks',
        'usability_ctr',
    ],
    availableMetrics: [
        'usability_views',
        'usability_clicks',
        'usability_ctr',
        'rambler_id',
    ],
    sampleDefault: 1,
    isShowTotals: true,
    availableTools: {
        filters: true,
        savedFilters: true,
        numberOfGraphs: true,
        periodPicker: true,
        sample: true,
        activeSample: true,
        detail: true,
    },
    reportHint: {
        message: 'Для формирования отчёта необходимо передавать список отслеживаемых data-атрибутов в коде счётчика '
            + 'Статистики от SberAds для каждой страницы вашего сайта (параметр \'data-attributes\'), '
            + 'а также разметить смысловые блоки на странице data-атрибутами.',
        link: 'https://ads.sber.ru/help/prakticheskoe-rukovodstvo-po-analitike-blokov',
    },
};

export default reportConfig;
