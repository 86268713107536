export default {
    userCount: 'Количество уникальных пользователей, от которых поступил хотя бы один просмотр за выбранный период.',
    header: {
        description: 'Введите точный URL либо используйте специальные символы:',
        rules: [
            {
                mark: '*',
                text: 'заменяет любое количество любых символов',
            },
        ],
    },
};
