import { metricsDict } from '@configs/metrics';

const {
    page_views,
    visits,
    visitors,
    uid,
    rambler_id,
    events_count,
    avg_view_depth,
    // bounces,
    // avg_sessions_duration,
} = metricsDict;

const metricsList = {
    title: 'Метрики',
    name: 'metrics',
    main: true,
    children: [
        page_views,
        visits,
        visitors,
        uid,
        rambler_id,
        events_count,
        avg_view_depth,
        // bounces,
        // avg_sessions_duration, TODO: https://sberworks.ru/jira/browse/ADSHYDRA-2307
    ],
};

export default metricsList;
