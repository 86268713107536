import { type IMaskInputProps } from 'react-imask';

export type TDimension = string;

enum EBaseOperation {
    GT = '>',
    GTOE = '>=',
    LT = '<',
    LTOE = '<=',
    EQ = '=',
    NOTEQ = '!=',
}

export const EFilterOperation = {
    ...EBaseOperation,
    INCLUDE: 'include',
    EXCLUDE: 'exclude',
    LIKE: 'like',
    MULTILIKE: 'multilike',
    MULTILIKEAND: 'multilikeand',
    NOTLIKE: 'nlike',
    NOTMULTILIKE: 'nmultilike',
    RANGE: 'range',
};
export type TFilterOperation = (typeof EFilterOperation)[keyof typeof EFilterOperation];

export interface IFilterItem<T = string | string[]> {
    op: TFilterOperation; // 'include', 'multilike' ...
    key: TDimension;
    value: T;
}

export type TFilters = IFilterItem[];

export enum EFilterType {
    checkbox = 'checkbox',
    textarea = 'textarea',
    radio = 'radio',
    period = 'period',
    datePeriod = 'datePeriod',
    event = 'event',
}

export interface IFiltersCnfItem {
    label: string;
    key: TDimension;
    type: EFilterType;
    children?: IFiltersCnfItem[];
    isDynamic?: boolean;
    dynamicFilterAlias?: string; // название, которое используется в ручке динамических фильтров
    isMetricFilter?: boolean;
    data?: { value: string; label: string }[];
    defaultValue?: string;
    hint?: string;
    inputPlaceholder?: string; // для period фильтров
    inputMaskOptions?: IMaskInputProps<HTMLInputElement>; // для period фильтров
}

export type TFiltersListCnf = IFiltersCnfItem[];
export type TFiltersFormCnf = Record<TDimension, IFiltersCnfItem>;

// Основное меню фильтров
export interface IFiltersMenuItem<T = string> {
    label: T;
    key: TDimension;
    children?: IFiltersMenuItem<T>[];
}

export type TFiltersMenu<T = string> = IFiltersMenuItem<T>[];

export const EFilterRuleOperation = {
    ...EBaseOperation,
    AND: 'AND',
    OR: 'OR',
    NOT: 'NOT',
};
export type TFilterRuleOperation = (typeof EFilterRuleOperation)[keyof typeof EFilterRuleOperation];

export interface IFilterRuleFormValue<T = string | string[]> {
    op: TFilterRuleOperation;
    value: T | null;
    extra?: Record<string, string>;
}

export type TAllFormValues = Record<TDimension, IFilterRuleFormValue[]>;
export type TAllFormChangeStatus = Record<TDimension, boolean>;

export interface IDynamicFilterItem {
    data: {
        label: string;
        value: string;
        key: string;
    }[];
    loading: boolean;
    totals?: number;
}

export type TDynamicFilterData = Record<TDimension, IDynamicFilterItem>;
