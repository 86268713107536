class NumberUtils {
    __private: any;

    constructor() {
        const self = NumberUtils;

        return Object.freeze({
            roundDiff: this.roundDiff,
            formatValue: this.formatValue,
            numberFormat: this.numberFormat,
            numberWithSpaces: this.numberWithSpaces,
            getSecondsFromTime: this.getSecondsFromTime,
            toNumber: this.toNumber,
            getPercentValueByNumber: this.getPercentValueByNumber,
            convertPercent: this.convertPercent,
            percentFormat: this.percentFormat,
            timeTrimSeconds: this.timeTrimSeconds,
            __private: {
                isNumber: self.isNumber,
                isInteger: self.isInteger,
                getDecimals: self.getDecimals,
                leadingZero: self.leadingZero,
                timeFormat: self.timeFormat,
                percentWithFloatFormat: self.percentWithFloatFormat,
            },
        });
    }

    // TODO: подумать над более простым алгоритмом (HYDRA-5225)
    roundDiff = (value: number): string | number => {
        let result = value;

        if (!result || NumberUtils.isInteger(result)) {
            return result;
        }

        result = parseFloat(String(value));

        const decimals = NumberUtils.getDecimals(result);

        const number = result <= 10 ? result.toFixed(2) : result.toFixed();

        const newValue = this.toNumber(decimals[0]) !== 0 ? result.toFixed(1) : number;

        return newValue.replace(/-/g, '');
    };

    formatValue = (
        value: string | number,
        format: string,
        optPreciseness: number = null,
        showPercent?: boolean,
    ): string | number => {
        let result = value;

        // если с сервера прилетает "NA"
        // не форматируем никак значения
        if (result === 'NA') return result;

        switch (format) {
            case 'number':
            case 'float':
                result = this.numberFormat(String(value));
                break;
            case 'time':
                result = NumberUtils.timeFormat(Number(value));
                break;
            case 'timeFormatted':
                result = this.timeTrimSeconds(String(value));
                break;
            case 'percent':
                result = this.percentFormat(Number(value), optPreciseness, showPercent);
                break;
            case 'percentWithFloat':
                result = NumberUtils.percentWithFloatFormat(value);
                break;
            default:
                break;
        }

        return result;
    };

    timeTrimSeconds = (value: string) => String(value).split(':').slice(0, 2).join(':');

    numberFormat = (number: number | string, postfix: boolean = true): string => {
        let value = parseFloat(String(number));
        const negative = value < 0;
        value = Math.abs(value);
        let result = number;

        if (value === 0) {
            result = String(value);
        } else if (value < 1) {
            result = value.toFixed(1);
        } else if (value < 10 ** 6 || !postfix) {
            // eslint-disable-line
            result = this.numberWithSpaces(value);
        } else {
            /* eslint-disable */
            if (value < Math.pow(10, 9)) {
                result = (value / Math.pow(10, 6)).toFixed(2) + ' млн';
            } else if (value < Math.pow(10, 12)) {
                result = (value / Math.pow(10, 9)).toFixed(2) + ' млрд';
            } else if (value < Math.pow(10, 15)) {
                result = (value / Math.pow(10, 12)).toFixed(2) + ' трлн';
            }
            /* eslint-enable */
        }

        result = result.toString().replace('.', ',');

        return negative ? `-${result}` : result;
    };

    numberWithSpaces = (number: number | string): string => {
        const tmp = number.toString().split('.');
        tmp[0] = tmp[0].replace(/\B(?=(\d{3})+(?!\d))/g, ' ');

        return tmp.join(',');
    };

    getSecondsFromTime = (string: string, returnString: boolean = true): string | number => {
        const time = string.split(':');

        let result = 0;

        if (time.length === 3) {
            result += this.toNumber(time[2]);
            result += this.toNumber(time[1]) * 60;
            result += this.toNumber(time[0]) * 60 * 60;
        } else if (time.length === 2) {
            result += this.toNumber(time[1]);
            result += this.toNumber(time[0]) * 60;
        } else {
            result += this.toNumber(time[0]);
        }

        return returnString ? `${result}` : result;
    };

    toNumber = (value: number | string): number => {
        const num = parseInt(String(value), 10);

        return /[0-9]/g.test(`${num}`) ? num : 0;
    };

    percentFormat = (value: number, optPreciseness?: number, showPercent: boolean = true): string => {
        const preciseness = NumberUtils.isNumber(optPreciseness) ? optPreciseness : 2;
        const percent = showPercent ? ' %' : '';

        return `${parseFloat(String(value)).toFixed(preciseness)}${percent}`.replace('.', ',');
    };

    /**
     * Конвертируем проценты из долей от 1 в показатель от 100%
     */
    convertPercent = (sample: number): number => {
        const percentSample = sample * 100 < 1 ? 0 : Math.round(sample * 100);

        return Number.isFinite(percentSample) ? percentSample : -1;
    };

    getPercentValueByNumber = (total: number, partValue: number): number => {
        let result = 0;

        if (total !== 0) {
            result = (partValue * 100) / total;
        }

        return +result.toFixed(2);
    };

    private static isNumber = (obj: any): boolean => typeof obj === 'number';

    private static isInteger = (n: any): boolean => Number(n) === n && n % 1 === 0;

    private static getDecimals(value: number): number | string {
        if (!value) {
            return value;
        }

        if (NumberUtils.isInteger(value)) {
            return null;
        }

        return value.toString().split('.')[1];
    }

    private static leadingZero(value: number | string): string {
        let result = `${value}`;

        if (Number(value) < 10) result = `0${value}`;

        return result;
    }

    private static timeFormat(value: number): string {
        const result = [];
        let hours = 0;
        let minutes = Math.floor(value / 60);
        const seconds = value - minutes * 60;

        if (minutes > 60) {
            hours = Math.floor(minutes / 60);
            minutes -= hours * 60;

            result.push(NumberUtils.leadingZero(hours));
        }

        // Получаем минуты
        result.push(NumberUtils.leadingZero(minutes));

        // Получаем оставшиеся секунды
        result.push(NumberUtils.leadingZero(seconds.toFixed(0)));

        return result.join(':');
    }

    private static percentWithFloatFormat(value): string {
        return `${parseFloat(value).toFixed(2)} %`.replace('.', ',');
    }
}

export default new NumberUtils();
