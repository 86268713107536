import { ChartGroupBy } from '@adtech/charts/dist/config';
import ContentWithSidebar from '@components/ContentWithSidebar';
import Funnel from '@components/Funnel';
import PageHead from '@components/PageHead';
import ReportTools from '@components/ReportTools';
import { popularityFilters, staticFilters } from '@configs/filters/filtersConfigEventBased';
import funnelsConfig from '@configs/funnels/funnels';

import { LatencyKeys } from '@constants/latency';
import { Error507 } from '@containers/Error';
import { useAvailableTools } from '@hooks/useAvailableTools';
import useCheckDates from '@hooks/useCheckDates';
import useFilters from '@hooks/useFilters';
import useFunnels from '@hooks/useFunnels';
import useGlobalLoading from '@hooks/useGlobalLoading';
import useLatency from '@hooks/useLatency';
import useProjectRequired from '@hooks/useProjectRequired';
import useQuery from '@hooks/useQuery';

import { globalActions } from '@redux/slices/global';
import { IRootSlice } from '@typings/rootSlice';
import DateUtils from '@utils/date';
import { top100Counter } from '@utils/index';
import React, { FC, useEffect, useRef } from 'react';
import DocumentTitle from 'react-document-title';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';

const Funnels: FC = () => {
    useGlobalLoading();

    const dispatch = useDispatch();
    const params: IUrlParams = useParams();
    const projectId = Number(params.projectId);

    const isProjectRequired = useProjectRequired();
    const isReportInited = useRef(false);

    const reportName = 'funnels';

    const { api, reportsUtils } = useFunnels();

    const { clearFilters, getDynamicFiltersEventBased } = useFilters();
    const { updateQuery } = useQuery();

    const userId = useSelector((state: IRootSlice) => state.userSlice.id);

    const errorCode = useSelector((state: IRootSlice) => state.funnelsSlice.errorCode);

    const globalLoading = useSelector((state: IRootSlice) => state.globalSlice.globalLoading);
    const serverTime = useSelector((state: IRootSlice) => state.globalSlice.serverTime);
    const dateStart = useSelector((state: IRootSlice) => state.globalSlice.dateStart);
    const dateEnd = useSelector((state: IRootSlice) => state.globalSlice.dateEnd);
    const globalFilters = useSelector((state: IRootSlice) => state.globalSlice.globalFilters);
    const dynamicFiltersData = useSelector((state: IRootSlice) => state.globalSlice.dynamicFiltersData);
    const sample = useSelector((state: IRootSlice) => state.tableSlice.sample);

    const projectURL = useSelector((state: IRootSlice) => state.projectsSlice.current.cleanedUrl);
    const permissions = useSelector((state: IRootSlice) => state.projectsSlice.current.permissions);

    const { getParamFromConfig } = reportsUtils;
    const baseAvailableTools = getParamFromConfig(reportName, 'availableTools');
    const availableTools = useAvailableTools(reportName, baseAvailableTools);

    const setGlobalLoading = (param: boolean) => dispatch(globalActions.setGlobalLoading(param));
    const clearError = () => dispatch(globalActions.clearError());
    const showErrorPage = errorCode === 507;

    const latencyConfig = useSelector((state: IRootSlice) => state.latencySlice[LatencyKeys.base]);
    const isExcludedLatency = latencyConfig?.isExcludedLatency;
    const minCalendarDate = latencyConfig?.start;
    const maxCalendarDate = latencyConfig?.end;

    const getLatency = useLatency(LatencyKeys.base);
    const checkedDates = useCheckDates(LatencyKeys.base);

    const getPageTitle = () => {
        const url = projectURL ? `(${projectURL}) ` : '';
        return `Воронки ${url} – Статистика от SberAds / ${projectId}`;
    };

    useEffect(
        () => () => {
            clearFilters();
            setGlobalLoading(false);
        },
        [],
    );

    useEffect(() => {
        if (!checkedDates || !isProjectRequired) {
            setGlobalLoading(true);
            return;
        }

        isReportInited.current = false;

        api.init().then(() => {
            isReportInited.current = true;
            setGlobalLoading(false);
        });
    }, [checkedDates, isProjectRequired]);

    useEffect(() => {
        if (!isReportInited.current) return;

        updateQuery({
            dateEnd,
            dateStart,
            sample,
            globalFilters: globalFilters.filtersRequest,
        });
    }, [isReportInited.current, dateEnd, dateStart, sample, globalFilters.filtersRequest]);

    useEffect(() => {
        // Чтобы updateQuery не отрабатывал в момент перехода между проектами на старом компоненте
        isReportInited.current = false;
        dispatch(
            globalActions.updateParams({
                globalFilters: {},
                dynamicFiltersData: {},
            }),
        );
    }, [projectId]);

    const handleChangeDate507 = () => {
        clearError();
    };

    const renderError = () => {
        const dates = DateUtils.convertDates(dateStart, dateEnd);

        return (
            <Error507
                dateStart={dates[0]}
                dateEnd={dates[1]}
                handleChangeDate={handleChangeDate507}
                minCalendarDate={minCalendarDate}
                maxCalendarDate={maxCalendarDate}
                serverTime={serverTime}
                getLatency={getLatency}
                isExcludedLatency={isExcludedLatency}
            />
        );
    };

    return (
        <DocumentTitle title={getPageTitle()}>
            <div>
                {showErrorPage && renderError()}
                {!showErrorPage && !globalLoading && (
                    <>
                        <PageHead
                            reportName={reportName}
                            projectId={projectId}
                            userId={userId}
                            title={funnelsConfig.reportTitle}
                            hintContent={funnelsConfig.reportHint}
                            labelText="Новое"
                            onOpenHintChange={(open) =>
                                open && top100Counter.trackEvent('stat_funnel_header_toolbar_open_btn')
                            }
                            closeHintHandler={() => top100Counter.trackEvent('stat_funnel_header_toolbar_close_btn')}
                            clickHintLinkHandler={() => top100Counter.trackEvent('stat_funnel_documentation_btn')}
                        />
                        <ContentWithSidebar>
                            <ReportTools
                                reportName={reportName}
                                reportsUtils={reportsUtils}
                                projectId={projectId}
                                updateReport={api.updateReport}
                                getLatency={getLatency}
                                serverTime={serverTime}
                                minDate={minCalendarDate}
                                maxDate={maxCalendarDate}
                                dateStart={dateStart}
                                dateEnd={dateEnd}
                                isExcludedLatency={isExcludedLatency}
                                handleChangeDate={api.handleChangeDate}
                                sample={sample || 1}
                                groupBy={ChartGroupBy.hour}
                                getTypeRequestReport={() => 'all'}
                                accessLevel={permissions.can_edit_project}
                                availableTools={availableTools}
                                globalFilters={globalFilters}
                                dynamicFiltersData={dynamicFiltersData}
                                getDynamicFilters={getDynamicFiltersEventBased}
                                clearFilters={clearFilters}
                                filtersConfig={{
                                    staticFilters,
                                    popularityFilters,
                                }}
                                graphs={null}
                            />
                            <Funnel />
                        </ContentWithSidebar>
                    </>
                )}
            </div>
        </DocumentTitle>
    );
};

export default Funnels;
