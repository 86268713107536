import { createAsyncThunk } from '@reduxjs/toolkit';
import fetchApi from '@utils/fetchApi';

/**
 * Получение границ start и end
 * для выбора периода из календаря
 */
export const getLatency = createAsyncThunk(
    'latencySlice/getLatency',
    async () =>
        // eslint-disable-next-line no-return-await
        await fetchApi({ url: '/api/report/v1.0/service/eventbase_latency' }),
);
