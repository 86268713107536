import listDataConstants, { Op } from '@constants/listData';
import * as React from 'react';

import SelectField from '../PeriodType/SelectField';
import InputField from './InputField';
import s from './PeriodType.pcss';

enum EDateType {
    date = 'date',
    time = 'time',
    dateTime = 'dateTime',
}

interface IProps {
    value: Record<string, any>;
    type: EDateType;
}

export default class DateType extends React.PureComponent<IProps> {
    getInputProps = (type: EDateType) => {
        let props;

        switch (type) {
            case EDateType.dateTime:
            case EDateType.date:
                props = {
                    type,
                    placeholder: 'дд.мм.гггг',
                    mask: '11.11.1111',
                };
                break;
            case EDateType.time:
                props = {
                    type,
                    placeholder: 'чч:мм',
                    mask: '11:11',
                };
                break;
            default:
                props = {};
        }
        return props;
    };

    renderInput = (name, type: EDateType) => <InputField name={name} {...this.getInputProps(type)} />;

    render() {
        const { value, type } = this.props;
        const isRange = value.op === Op.range;
        const isDataTime = type === EDateType.dateTime;

        return (
            <div className={s.period}>
                <div className={s.content}>
                    <div className={s.op}>
                        <SelectField
                            className={s.selectOp}
                            name="op"
                            options={listDataConstants.dateSelectOp}
                            initialValue={listDataConstants.defaultValues.op}
                        />
                    </div>

                    <div className={s.inputsWrap}>
                        {!isRange ? (
                            <div className={s.inputs}>{this.renderInput('value', type)}</div>
                        ) : (
                            <div className={s.inputs}>
                                {this.renderInput('from', type)}
                                <span className={s.delimiter}>&mdash;</span>
                                {this.renderInput('to', type)}
                            </div>
                        )}
                        {!isRange && isDataTime && (
                            <div className={s.inputs}>{this.renderInput('valueTime', EDateType.time)}</div>
                        )}
                        {isRange && isDataTime && (
                            <div className={s.inputs}>
                                {this.renderInput('fromTime', EDateType.time)}
                                <span className={s.delimiter}>&mdash;</span>
                                {this.renderInput('toTime', EDateType.time)}
                            </div>
                        )}
                    </div>
                </div>
            </div>
        );
    }
}
