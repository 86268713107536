import { useAppDispatch } from '@hooks/useStore';
import { projectsActions } from '@redux/slices/projects';
import * as Sentry from '@sentry/react';
import { IRootSlice } from '@typings/rootSlice';
import { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';

const useProjectRequired = () => {
    const dispatch = useAppDispatch();
    const { projectId } = useParams();

    const userId = useSelector(({ userSlice }: IRootSlice) => userSlice.id);
    const projectCurrentId = useSelector(({ projectsSlice }: IRootSlice) => projectsSlice.current.id);

    const getProject = (pId: string, uId: number) =>
        dispatch(projectsActions.getProject({ projectId: pId, userId: uId })).unwrap();

    useEffect(() => {
        if (projectCurrentId !== Number(projectId)) {
            getProject(projectId, userId).catch((err) => {
                // В том числе, чтобы в юнит тестах не зависали промисы
                Sentry.captureException(err);
            });
        }
    }, [projectId]);

    return !!projectCurrentId;
};

export default useProjectRequired;
