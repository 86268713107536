import SaveOrEdit from '@components/TemplateForms/SaveOrEdit';
import React from 'react';
import s from '../ModalPopup.pcss';

interface IProps {
    saveNewTemplate: () => void;
    saveTemplate: () => void;
    closePopup: () => void;
}

export default {
    getContent(props: IProps) {
        const popupContent = (
            <SaveOrEdit
                saveNewTemplate={props.saveNewTemplate}
                saveTemplate={props.saveTemplate}
                closePopup={props.closePopup}
            />
        );

        return {
            popupClassName: s.saveOrEdit,
            width: 'md',
            title: 'Сохранить отчёт',
            content: popupContent,
            buttonContainerClassName: s.buttons,
        };
    },
};
