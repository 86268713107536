import IconProjectsNoSearchData from '@images/svg/icons/icon-projects-no-search-data.svg';
import React from 'react';
import s from './TableNoSearchData.pcss';

interface IProps {
    hideContent: boolean;
}

export const TableNoSearchData: React.FC<IProps> = ({ hideContent }) => (
    <div className={s.noData}>
        {!hideContent && (
            <div className={s.noDataContainer}>
                <div className={s.ovalIcon}>
                    <IconProjectsNoSearchData className={s.svgSort} />
                </div>
                <div className={s.textBlock}>
                    <div className={s.headNoDataText}>По вашему запросу ничего не найдено</div>
                    <div className={s.noDataText}>Проверьте правильность написания или попробуйте другой запрос</div>
                </div>
            </div>
        )}
    </div>
);

export default TableNoSearchData;
