import { DropDownListItem, DropDownButton, DropDownList, Button, IconDots, IconTrash, IconEdit } from '@adtech/ui';
import attributesData from '@configs/attributesData';
import { IGlobalFilters } from '@typings/filters';
import { ReportName } from '@typings/reports';
import cn from 'classnames';
import React, { FC, useState, useEffect } from 'react';
import s from './SavedFiltersList.pcss';

const actionsList = [
    {
        title: 'Редактировать',
        icon: <IconEdit />,
        value: 'edit',
    },
    {
        title: 'Удалить',
        icon: <IconTrash />,
        value: 'delete',
    },
];

interface IProps {
    reportName: ReportName;
    isLoading: boolean;
    isEdited: boolean;
    list: IGlobalFilters[];
    selectedId: number;
    onOpen: () => void;
    onSelect: (id: number) => void;
    onClickAction: (actionName: string, data?: IGlobalFilters) => void;
}

const SavedFiltersList: FC<IProps> = ({
    reportName,
    isLoading = false,
    isEdited = false,
    list = [],
    selectedId = null,
    onOpen,
    onSelect,
    onClickAction,
}) => {
    const [isOpen, setIsOpen] = useState(false);
    const [idOpenActionList, setIdOpenActionList] = useState(null);
    const dataAttrName = attributesData.newreport.attrName;
    const dataAttr = attributesData.newreport.savedFilters;
    let selectedTitle = list.find((item) => item.id === selectedId)?.title || '';

    if (isEdited) selectedTitle = `${selectedTitle} (изменён)`;

    useEffect(() => {
        if (!isOpen) setIdOpenActionList(null);
    }, [isOpen]);

    const handleClickTemplate = (id: number) => {
        if (idOpenActionList !== null) {
            setIdOpenActionList(null);
            return;
        }

        if (selectedId === id) return;

        onSelect(id);
        setIdOpenActionList(null);
        setIsOpen(false);
    };

    const handleClickAction = (name, itemData: IGlobalFilters = {}) => {
        setIsOpen(false);
        onClickAction(name, itemData);
    };

    const handleOpenList = (open: boolean) => {
        if (idOpenActionList !== null) {
            setIdOpenActionList(null);
            return;
        }

        if (!open) setIsOpen(false);

        onOpen();
    };

    const getItemActions = (itemData: IGlobalFilters) =>
        actionsList.map((item) => (
            <DropDownListItem
                onClick={() => handleClickAction(item.value, itemData)}
                key={item.value}
                text={
                    <span className={s.actionsItem}>
                        <span className={s.actionsItemIcon}>{item.icon}</span> {item.title}
                    </span>
                }
            />
        ));

    const getItem = (itemData: IGlobalFilters = {}) => {
        const { title, id, type } = itemData;
        const isSelected = selectedId === id;
        const isOpenActions = idOpenActionList === id;
        const displayTitle = isSelected ? selectedTitle : title;
        const className = cn(s.item, { [s.selected]: isSelected, [s.openActions]: isOpenActions });

        return {
            dropDownItemProps: {
                className,
                onClick: () => handleClickTemplate(id),
                text: (
                    <div className={s.itemTitle}>
                        {displayTitle}
                        <div className={cn(s.itemType, s[`itemType_${type}`])}>
                            {type === 'shared' ? 'Публичный' : 'Личный'}
                        </div>
                    </div>
                ),
                icon:
                    !isSelected || (isSelected && !isEdited) ? (
                        <>
                            <Button
                                className={s.actionsAnchor}
                                onClick={() => setIdOpenActionList(idOpenActionList !== id ? id : null)}
                                size="small"
                                round
                                prefixIcon={<IconDots />}
                                type="dashed"
                            />
                            {isOpenActions && <div className={s.actions}>{getItemActions(itemData)}</div>}
                        </>
                    ) : null,
            },
        };
    };

    const renderLoading = [
        {
            dropDownItemProps: {
                text: null,
                className: s.item,
            },
        },
    ];

    return (
        <DropDownList
            open={isOpen}
            className={cn(s.list, 'drop-down-list', { [s.overlayVisible]: idOpenActionList !== null })}
            menuItems={isLoading ? renderLoading : list.map(getItem)}
            onOpenChange={handleOpenList}
            customButton={
                <DropDownButton
                    size="small"
                    type="dashed"
                    onClick={() => setIsOpen(!isOpen)}
                    className={s.listAnchor}
                    {...(dataAttrName && dataAttr ? { [`data-${dataAttrName}`]: `${dataAttr}::${reportName}` } : {})}
                >
                    <span className={s.listAnchorText}>{selectedTitle || 'Шаблоны'}</span>
                </DropDownButton>
            }
        />
    );
};

export default SavedFiltersList;
