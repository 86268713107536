import { sortOrders } from '@constants/sort';
import tableConstants from '@constants/table';
import { Dimensions } from '@typings/dimensions';
import { IMediaConfig } from '@typings/media';
import { Metrics } from '@typings/metrics';
import { IReportConfig, ReportName } from '@typings/reports';
import { IDataTable, IDict, IOrderBy, ISort } from '@typings/table';

const tableUtilsFunc = ({ reportsConfig }) => {
    const getConfig = (reportName: ReportName): IReportConfig | IMediaConfig => reportsConfig[reportName];

    /**
     * В случае, если поле сортировки отсутствует в выбранных метриках,
     * выбираем дефолтную сортировку из конфига
     * или первое поле значение из метрик, которые не отключены
     */
    const checkSort = (
        reportName: ReportName,
        orderBy: IOrderBy,
        defaultSort: ISort,
        disabledSortBy: Metrics[],
        metrics: Metrics[],
    ): ISort<Metrics | Dimensions> => {
        let sort = {
            name: orderBy?.key as Metrics | Dimensions,
            order: orderBy?.value,
        };

        // Сортировка по названию
        if (orderBy?.key === 'title' && !disabledSortBy?.includes('title')) {
            // Если отчёт по медиа, используем вместо 'title'
            // значение из конфига (tableTitleDimension)
            const cnf = getConfig(reportName);
            sort.name = ('tableTitleDimension' in cnf && cnf.tableTitleDimension) || 'title';

            return sort;
        }

        // Сортировка по метрикам
        if (metrics && !metrics.includes(orderBy?.key)) {
            if (defaultSort && metrics.includes(defaultSort.name)) {
                sort = { ...defaultSort };
            } else {
                let allowedMetrics = metrics;

                if (disabledSortBy) {
                    allowedMetrics = metrics.filter((metric) => !disabledSortBy.includes(metric));
                }

                sort = {
                    name: allowedMetrics[0],
                    order: sortOrders.DESC,
                };
            }
        }

        return sort;
    };

    const getTableTitle = (reportName: ReportName): string => {
        const cnf = getConfig(reportName);
        const tableTitle = cnf?.tableTitle;
        const title = cnf?.reportTitle;

        switch ('string') {
            case typeof tableTitle:
                return tableTitle;
            case typeof title:
                return title;
            default:
                return '';
        }
    };

    const prepareDataForRenderSummary = (
        data: IDataTable,
        parentId: string,
        counterTableRowsReport?: number,
    ): string[] => {
        const pieceTable = counterTableRowsReport || tableConstants.COUNTER_ROWS_REPORT_SUMMARY;
        const root = data.map[parentId];
        const list = (root && root.list.slice(0, pieceTable)) || [];
        const result = [];

        list.forEach((id, index) => {
            const item = data.dict[id];

            result.push(item);

            if (index + 1 === list.length && list.length < root.total) {
                result.push({
                    currLength: list.length,
                    totalLength: root.total,
                    showmore: true,
                    level: item.level,
                    parentId,
                });
            }
        });

        return result;
    };

    const prepareDataForRender = (
        data: IDataTable,
        parentId: string,
        limit: number = 10,
        isPagination?: boolean,
    ): IDict[] => {
        const root = data.map[parentId];
        const list = root?.list || [];
        const { length: listLen } = list;
        const result = [];

        if (typeof root === 'undefined' || !listLen) return [];

        list.forEach((id, index) => {
            const item = data.dict[id];
            const { offsetHead } = data;

            result.push(item);

            // При активной пагинации отключаем "Показать еще"
            if (isPagination) return;

            // Добавляем верхнюю кнопку "Показать еще" под лидером
            if (item?.isLeader && offsetHead && offsetHead - 1) {
                // формируем число проектов, которые будут показаны по клику на кнопку
                const currLength = listLen > limit || offsetHead > listLen ? limit : listLen;

                result.push({
                    currLength,
                    totalLength: offsetHead - 1,
                    showmore: true,
                    level: item.level,
                    parentId,
                    isHeadButton: true,
                    currOffset: offsetHead,
                });
            }

            // Добавляем нижнюю кнопку "Показать еще" после всех строк
            if (listLen >= limit && listLen < root.total && listLen === index + 1) {
                result.push({
                    currLength: listLen,
                    totalLength: root.total,
                    showmore: true,
                    level: item.level,
                    parentId,
                    ...(offsetHead ? { currOffset: offsetHead - 1 } : {}),
                });
            }
        });

        return result;
    };

    const updateFromSort = ({
        sort,
        data,
        metrics,
    }: {
        sort: IOrderBy;
        data: IDataTable;
        metrics: Metrics[];
    }): IDataTable => {
        const newData = JSON.parse(JSON.stringify(data));

        Object.keys(data.map).forEach((key) => {
            const currentDataList = [...data.map[key].list];

            if (sort.key === 'title') {
                newData.map[key].list = currentDataList.sort((key1, key2) =>
                    sort.value === 'asc'
                        ? `${key1}`.localeCompare(`${key2}`, 'kn', { numeric: true })
                        : `${key2}`.localeCompare(`${key1}`, 'kn', { numeric: true }),
                );
            } else {
                const metricIndex = metrics.indexOf(sort.key);

                newData.map[key].list = currentDataList.sort((key1, key2) => {
                    const metric1 = data.dict[key1].metricsData[metricIndex].normal;
                    const metric2 = data.dict[key2].metricsData[metricIndex].normal;

                    return sort.value === 'asc'
                        ? `${metric1}`.localeCompare(`${metric2}`, undefined, { numeric: true })
                        : `${metric2}`.localeCompare(`${metric1}`, undefined, { numeric: true });
                });
            }
        });

        return newData;
    };

    return Object.freeze({
        getTableTitle,
        prepareDataForRender,
        prepareDataForRenderSummary,
        checkSort,
        updateFromSort,
    });
};

export default tableUtilsFunc;
