import { GroupBy } from '@configs/group';
import dateConstants from '@constants/date';
import { useAppDispatch } from '@hooks/useStore';
import { globalActions } from '@redux/slices/global';
import { IGlobalFilters } from '@typings/filters';
import { IUpdateReportParams } from '@typings/reports';
import { IRootSlice } from '@typings/rootSlice';
import DateUtils from '@utils/date';
import globalUtilsFunc from '@utils/global';
import { useSelector } from 'react-redux';

const useGlobalParams = () => {
    const globalSlice = useSelector((state: IRootSlice) => state.globalSlice);
    const dispatch = useAppDispatch();

    const globalUtils = globalUtilsFunc();

    const api = {
        updateGlobalParams(updateParams: IUpdateReportParams, withoutCompare?: boolean) {
            let newParams = { ...updateParams };

            if (newParams.globalFilters && newParams.globalFilters.filtersRequest) {
                newParams.globalFilters = api.updateSavedFilters(newParams.globalFilters);
            }

            newParams = globalUtils.filterParamsForStore(newParams, globalSlice);

            const noParamsUpdate =
                !withoutCompare &&
                !globalUtils.compareParams(
                    {
                        ...globalSlice,
                    },
                    newParams,
                );

            if (Object.keys(newParams).length) dispatch(globalActions.updateParams(newParams));

            return { newParams, noParamsUpdate };
        },

        /**
         * Собираем обновленный объект globalFilters
         * Мерджим фильтры только если к уже сохраненному фильтру прилетают новые условия,
         * иначе просто возвращаем новые параметры.
         * Если мерджим фильтры, то добавляем параметр isEdited: true.
         */
        updateSavedFilters(newParamsFilters: IGlobalFilters): IGlobalFilters {
            const currentSavedFilterOptions = globalSlice.globalFilters.savedFilterOptions;
            let updateSavedFilters = newParamsFilters;

            const isSavedFilter = currentSavedFilterOptions && currentSavedFilterOptions.isSaved;
            const isSavedNewParamsFilter =
                newParamsFilters.savedFilterOptions && newParamsFilters.savedFilterOptions.isSaved;

            if (isSavedFilter && !isSavedNewParamsFilter) {
                const savedDimensionFilters = globalSlice.globalFilters;

                updateSavedFilters = {
                    ...newParamsFilters,
                    title: savedDimensionFilters.title,
                    id: savedDimensionFilters.id,
                    savedFilterOptions: {
                        ...currentSavedFilterOptions,
                        isEdited: true,
                    },
                };
            }

            return updateSavedFilters;
        },

        /**
         * Сбрасываем данные на сегодня
         */
        resetDatesToToday(groupBy?: GroupBy): void {
            const today = DateUtils.getDate().format(dateConstants.DATE_FORMAT);

            dispatch(
                globalActions.updateParams({
                    dateStart: today,
                    dateEnd: today,
                    groupBy: groupBy || GroupBy.fiveMinute,
                }),
            );
        },

        /**
         * Установить состояние навигации
         */
        setIsNavigationOpen(isOpen?: boolean): void {
            dispatch(
                globalActions.updateParams({
                    isNavigationOpen: isOpen,
                }),
            );
        },
    };

    return api;
};

export default useGlobalParams;
