import {
    getTemplates,
    getSavedTemplate,
    updateTemplatesTable,
    saveTemplate,
    updateTemplate,
    deleteTemplate,
} from '@redux/slices/templates/api';
import { createSlice } from '@reduxjs/toolkit';
import { ITemplatesSlice } from '@typings/rootSlice';

export const initialState: ITemplatesSlice = {
    deleteTemplateRequest: false,
    templatesRequest: false,
    tableData: [],
    totals: null,
    saveTemplateRequest: false,
    savedTemplate: null,
    savedTemplateRequest: false,
};

export const setRequestTemplates = (state: ITemplatesSlice) => {
    state.templatesRequest = true;
};

export const updateTable = (state: ITemplatesSlice, action, isUpdate?: boolean) => {
    const { constructor_template, totals } = action.payload?.data || {};

    const tableData = isUpdate ? [...state.tableData, ...constructor_template] : constructor_template;

    state.templatesRequest = false;
    state.tableData = tableData;
    state.totals = totals;
};

export const setRequestSavedTemplate = (state: ITemplatesSlice) => {
    state.savedTemplateRequest = true;
};

export const setSavedTemplate = (state: ITemplatesSlice, action) => {
    state.savedTemplateRequest = false;
    state.savedTemplate = action.payload.data;
};

export const setRequestSaveTemplate = (state: ITemplatesSlice) => {
    state.saveTemplateRequest = true;
};

export const saveTemplateFunc = (state: ITemplatesSlice, action) => {
    const { id } = action.payload;
    const { template, isEdited } = action.payload?.metaInfo || {};

    let newTableData = state.tableData;
    if (isEdited) {
        newTableData = state.tableData.map((item) => {
            if (item.id === id) {
                return {
                    ...item,
                    title: template.title,
                    update_date: +new Date(),
                };
            }
            return item;
        });
    }

    Object.assign(state, {
        saveTemplateRequest: false,
        savedTemplate: {
            ...template,
            id,
        },
        tableData: newTableData,
    });
};

export const setRequestDeleteTemplate = (state: ITemplatesSlice) => {
    state.deleteTemplateRequest = true;
};

export const deleteTemplateFunc = (state: ITemplatesSlice, action) => {
    const { id } = action.payload?.metaInfo || {};

    const data = state.tableData.filter((item) => item.id !== id);

    state.deleteTemplateRequest = false;
    state.tableData = data;
    state.totals -= 1;
};

export const clearSavedTemplateFunc = (state: ITemplatesSlice) => {
    state.savedTemplate = null;
};

export const updateParamsFunc = (state: ITemplatesSlice, action) => {
    Object.assign(state, action.payload);
};

const templatesSlice = createSlice({
    name: 'templatesSlice',
    initialState,
    reducers: {
        updateParams: (state, action) => updateParamsFunc(state, action),
        clearSavedTemplate: (state) => clearSavedTemplateFunc(state),
    },
    extraReducers: (builder) => {
        builder
            .addCase(getTemplates.pending, (state) => setRequestTemplates(state))
            .addCase(getTemplates.fulfilled, (state, action) => updateTable(state, action))

            .addCase(getSavedTemplate.pending, (state) => setRequestSavedTemplate(state))
            .addCase(getSavedTemplate.fulfilled, (state, action) => setSavedTemplate(state, action))

            .addCase(updateTemplatesTable.pending, (state) => setRequestTemplates(state))
            .addCase(updateTemplatesTable.fulfilled, (state, action) => updateTable(state, action, true))

            .addCase(saveTemplate.pending, (state) => setRequestSaveTemplate(state))
            .addCase(saveTemplate.fulfilled, (state, action) => saveTemplateFunc(state, action))

            .addCase(updateTemplate.pending, (state) => setRequestSaveTemplate(state))
            .addCase(updateTemplate.fulfilled, (state, action) => saveTemplateFunc(state, action))

            .addCase(deleteTemplate.pending, (state) => setRequestDeleteTemplate(state))
            .addCase(deleteTemplate.fulfilled, (state, action) => deleteTemplateFunc(state, action));
    },
});

export const { updateParams, clearSavedTemplate } = templatesSlice.actions;

export const templatesActions = {
    updateParams,
    clearSavedTemplate,
    getTemplates,
    getSavedTemplate,
    updateTemplatesTable,
    saveTemplate,
    updateTemplate,
    deleteTemplate,
};

export default templatesSlice.reducer;
