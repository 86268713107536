import { IDataListItem } from '@components/DataList';
import dimensionsValues from '@configs/dimensionsValues';
import { ChartTypes } from '@configs/graph';
import { ESpecialEventsClasses } from '@constants/events';
import { graphConstants } from '@constants/graph';
import { getEventBasedDashBoardWidgets, getEventsList } from '@redux/slices/dashboard/api';
import { createSlice } from '@reduxjs/toolkit';
import { IDashboardSlice } from '@typings/rootSlice';
import DateUtils from '@utils/date';
import { widgetUtils } from '@utils/index';

export const initialState: IDashboardSlice = {
    userCount: {
        count: 0,
        metrics: [],
        allMetrics: [],
        error: false,
        loading: false,
    },
    activePages: {
        data: [],
        metrics: [],
        allMetrics: [],
        sort: { name: null, order: null },
        error: false,
        loading: false,
    },
    pageViews: {
        data: [],
        metrics: [],
        allMetrics: [],
        period: [],
        sort: { name: null, order: null },
        graphType: ChartTypes.bar,
        error: false,
        loading: false,
    },
    trafficSources: {
        data: [],
        metrics: [],
        allMetrics: [],
        sort: { name: null, order: null },
        graphType: ChartTypes.bar,
        error: false,
        loading: false,
    },
    audience: {
        data: [],
        allMetrics: [],
        period: [],
        sort: { name: null, order: null },
        graphType: ChartTypes.line,
        error: false,
        loading: false,
    },
    platforms: {
        data: [],
        metrics: [],
        allMetrics: [],
        sort: { name: null, order: null },
        graphType: ChartTypes.pie,
        error: false,
        loading: false,
    },
    events: {
        data: [],
        metrics: [],
        allMetrics: [],
        sort: { name: null, order: null },
        selected: 'visitors',
        error: false,
        loading: false,
    },
    popularUtm: {
        data: [],
        metrics: [],
        allMetrics: [],
        sort: { name: null, order: null },
        selected: 'visitors',
        error: false,
        loading: false,
    },
    eventsListRequest: false,
    isAutoUpdate: true,
    eventsList: null,
    lastRequestId: 0,
    errorCode: null,
};

export const setDashboardWidgetRequest = (state: IDashboardSlice, action) => {
    const { isAutoUpdate } = action?.meta?.arg || {};
    const loading = !isAutoUpdate;

    state.userCount.loading = loading;
    state.activePages.loading = loading;
    state.pageViews.loading = loading;
    state.trafficSources.loading = loading;
    state.audience.loading = loading;
    state.popularUtm.loading = loading;
    state.platforms.loading = loading;
    state.events.loading = loading;
};

export const setWidgetDashboardError = (state: IDashboardSlice) => {
    const restWidgets = {
        activePages: { ...state.activePages, loading: false },
        pageViews: { ...state.pageViews, loading: false },
        trafficSources: { ...state.trafficSources, loading: false },
        audience: { ...state.audience, loading: false },
        popularUtm: { ...state.popularUtm, loading: false },
        platforms: { ...state.platforms, loading: false },
        events: { ...state.events, loading: false },
    };

    state.userCount.loading = false;
    state.userCount.error = !state.userCount.count;
    Object.assign(state, widgetUtils.dataTransform(restWidgets));
};

/**
 * Трансформируем данные для виджета таблицы
 */
const transformData = (data: (string | number)[][] = [], metricsQuantity: number = 0) =>
    data.map((item) => {
        const titles = item.slice(0, -metricsQuantity);
        const metricsData = item.slice(-metricsQuantity);
        return [titles, ...metricsData];
    });

const transformWidgetGraphData = (payload) => {
    const data = [];
    const dataTotals = [];
    const dataKeys = Object.keys(payload?.data || {});
    let dataLength = 0;
    let biggestDataKey; // ключ данных с большим количеством точек
    const isLabelsInData = !!payload?.labels;

    if (!dataKeys.length) return { data, dataTotals };

    dataKeys.forEach((key) => {
        // удаляем "неопределенный" срез аудитории
        if (key === graphConstants.EMPTY_GRAPH_DATA_VALUE) return;

        const itemLabel = isLabelsInData ? payload.labels[key] : key;
        const itemData = payload.data[key];
        const itemDataTotal = !!payload.totals[key]?.length && payload.totals[key][0];
        // line, area данные
        data.push({
            title: itemLabel,
            data: itemData.map((item) => [itemLabel, item[1]]),
        });
        // bar, pie данные
        dataTotals.push([itemLabel, itemDataTotal]);

        if (itemData.length > dataLength) {
            dataLength = itemData.length;
            biggestDataKey = key;
        }
    });

    if (!data.length) return { data, dataTotals };

    // Вычисление периода на основе данных бэка (они могут быть неполные)
    const bigItem = payload.data[biggestDataKey];
    const start = bigItem[0][0];
    const end = bigItem[bigItem.length - 1][0];
    const isPeriod = start.includes(' - '); // YYYY-MM-DD - YYYY-MM-DD

    const period = isPeriod ? [start.split(' - ')[0], end.split(' - ')[1]] : [+DateUtils.getDate(start)];

    return {
        ...payload,
        data,
        dataTotals,
        period,
        totals: null,
    };
};

const getPageViewsPeriod = (data: [string, number][]): (string | number)[] => {
    if (!data?.length) return [];

    // Вычисление периода на основе данных бэка (они могут быть неполные)
    const start = data[0][0];
    const end = data[data.length - 1][0];
    const isPeriod = start.includes(' - '); // YYYY-MM-DD - YYYY-MM-DD

    return isPeriod ? [start.split(' - ')[0], end.split(' - ')[1]] : [+DateUtils.getDate(start)];
};

export const setWidgetDashboardReceive = (state: IDashboardSlice, action) => {
    const { result } = action.payload;
    const { requestId } = action.payload?.metaInfo || {};

    // Отбрасываем результаты старых запросов
    if (requestId < state.lastRequestId || !result) return state;

    const count = result.current_user_count.data[0] ? result.current_user_count.data[0][1] : 0;

    const restWidgets = {
        activePages: { ...result.active_pages, loading: false },
        trafficSources: {
            ...transformWidgetGraphData(result.traffic_sources),
            loading: false,
        },
        pageViews: {
            ...result.page_views,
            period: getPageViewsPeriod(result.page_views.data),
            loading: false,
        },
        audience: {
            ...transformWidgetGraphData(result.audience),
            loading: false,
        },
        platforms: {
            ...transformWidgetGraphData(result.platforms),
            loading: false,
        },
        events: {
            ...result.events,
            data: transformData(result.events?.data, result.events?.metrics?.length),
            loading: false,
        },
        popularUtm: {
            ...result.utms,
            data: transformData(result.utms?.data, result.utms?.metrics?.length),
            loading: false,
        },
    };

    return {
        ...state,
        userCount: {
            metrics: result.current_user_count.metrics,
            allMetrics: result.current_user_count.all_metrics,
            count,
            error: !count,
            loading: false,
        },
        ...widgetUtils.dataTransform(restWidgets, state),
    };
};

export const setEventsListRequest = (state: IDashboardSlice) => {
    state.eventsListRequest = true;
};

export const setEventsListFailure = (state: IDashboardSlice) => {
    state.eventsListRequest = false;
};

export const setEventsList = (state: IDashboardSlice, action) => {
    const { data } = action.payload?.result || {};

    const transformList = (item: string, isEventClass?: boolean, parent?: string) => {
        const title = isEventClass && dimensionsValues.event_class[item]?.title;

        return {
            title: title || item,
            name: item,
            main: isEventClass,
            ...(!isEventClass ? { parent } : {}),
        } as IDataListItem;
    };

    // Отфильтровываем рекомендации и тех. события
    const filteredData = data.filter(({ id }) => id !== ESpecialEventsClasses.tech && id !== ESpecialEventsClasses.rec);

    const eventClasses = [...new Set(filteredData.map((item) => item.id))] as string[];
    const list = eventClasses.map((item) => transformList(item, true));

    filteredData.forEach((item) => {
        const eventClass = item.id;
        const eventName = item.event_name;

        const index = list.findIndex((listItem) => listItem.name === eventClass);
        list[index].children = [...(list[index].children || []), transformList(eventName, false, eventClass)];
    });

    state.eventsList = list;
    state.eventsListRequest = false;
};

export const updateParamsFunc = (state: IDashboardSlice, action) => {
    const params: Record<string, unknown> = action.payload;

    Object.assign(state, params);
};

export const updateWidgetParamsFunc = (state: IDashboardSlice, action) => {
    const { widgetName, options } = action.payload;

    Object.assign(state[widgetName], options);
};

export const resetParamFunc = (state: IDashboardSlice, action) => {
    const param = action.payload;

    if (!(param in initialState)) return state;

    state[param] = initialState[param];
};

/**
 * Установка параметра, определяющего необходимость в автообновлении
 */
export const setIsAutoUpdateFunc = (state: IDashboardSlice, action) => {
    state.isAutoUpdate = action.payload;
};

const dashboardSlice = createSlice({
    name: 'dashboardSlice',
    initialState,
    reducers: {
        allReset: () => initialState,
        resetParam: (state, action) => resetParamFunc(state, action),
        updateParams: (state, action) => updateParamsFunc(state, action),
        updateWidgetParams: (state, action) => updateWidgetParamsFunc(state, action),
        setIsAutoUpdate: (state, action) => setIsAutoUpdateFunc(state, action),
    },
    extraReducers: (builder) => {
        builder
            .addCase(getEventBasedDashBoardWidgets.pending, (state, action) => setDashboardWidgetRequest(state, action))
            .addCase(getEventBasedDashBoardWidgets.fulfilled, (state, action) =>
                setWidgetDashboardReceive(state, action),
            )
            .addCase(getEventBasedDashBoardWidgets.rejected, (state) => setWidgetDashboardError(state))

            .addCase(getEventsList.pending, (state) => setEventsListRequest(state))
            .addCase(getEventsList.fulfilled, (state, action) => setEventsList(state, action))
            .addCase(getEventsList.rejected, (state) => setEventsListFailure(state));
    },
});

// prettier-ignore
export const {
    allReset,
    resetParam,
    updateParams,
    updateWidgetParams,
    setIsAutoUpdate,
} = dashboardSlice.actions;

export const dashboardActions = {
    allReset,
    resetParam,
    updateParams,
    setIsAutoUpdate,
    updateWidgetParams,
    getEventBasedDashBoardWidgets,
    getEventsList,
};

export default dashboardSlice.reducer;
