import { ChipButton, IconCross } from '@adtech/ui';
import { Hint } from '@components/Hint';
import fieldDecorator from '@decorators/fieldDecorator';
import classNames from 'classnames';

import React from 'react';

import InputField from './InputField';
import s from './MultiInputData.pcss';

interface IProps {
    field: any;
    className: string;
    name: string;
    title: string;
    hint: string;
    button: string;
    placeholder: string;
    rulesInput: any[];
    ignoreShowErrors: string[];
}

export class MultiInputData extends React.Component<IProps> {
    static defaultProps = {
        ignoreShowErrors: [],
    };

    handleClick = (field: any, meta: any, reset) => {
        if (meta.invalid) {
            return;
        }

        const newValues = [...this.props.field.value, field.value];

        this.props.field.onChange(null, newValues);

        reset();
    };

    handleChange = (e: Event, currentValue: string) => {
        this.props.field.onChange(null, currentValue);
    };

    handleKeyPress = (e: any, field: any, meta: any, reset) => {
        if (e.key === 'Enter') {
            this.handleClick(field, meta, reset);
        }
    };

    changeValues = (values?: string[]) => {
        this.props.field.onChange(null, values);
    };

    renderSelectedItem = (value: string, key: number) => (
        <ChipButton
            key={key}
            className={s.chipButton}
            fixedWidth
            icon={<IconCross className={s.removeIcon} />}
            data-id={value}
            checked={false}
        >
            {value}
        </ChipButton>
    );

    removeRemainingElements = (id: string) => {
        const { field } = this.props;
        if (Array.isArray(field.value)) {
            this.changeValues(field.value.filter((elem) => elem !== id));
        } else if (field.value === id) {
            this.changeValues();
        }
    };

    deleteElement = (e: React.MouseEvent<HTMLDivElement>) => {
        const target = e.target as Element;
        if (target.closest('svg')) {
            const span = target.closest('span');
            const id = span?.dataset?.id;
            if (id) {
                this.removeRemainingElements(id);
            }
        }
    };

    renderSelected() {
        const { field } = this.props;

        if (!field.value?.length) {
            return null;
        }

        return (
            <div className={s.selected} onClick={this.deleteElement}>
                {field.value.map((item, key) => this.renderSelectedItem(item, key))}
            </div>
        );
    }

    renderInput() {
        const { name, button, hint, title, placeholder, rulesInput, ignoreShowErrors } = this.props;
        return (
            <InputField
                name={`${name}_input`}
                button={button}
                placeholder={placeholder}
                title={title}
                hint={hint}
                rules={rulesInput}
                onPressKey={this.handleKeyPress}
                onButtonClick={this.handleClick}
                ignoreShowErrors={ignoreShowErrors}
                onlyHidden
            />
        );
    }

    render() {
        const { title, hint, className } = this.props;

        const label =
            title && hint ? (
                <div className={s.label}>
                    {title && <span>{title}</span>}
                    {hint && <Hint className={s.hint}>{hint}</Hint>}
                </div>
            ) : null;

        return (
            <div className={classNames(s.inputWrapper, className)}>
                <div className={s.label}>{label}</div>
                {this.renderInput()}
                {this.renderSelected()}
            </div>
        );
    }
}

export default fieldDecorator({
    type: 'array',
})(MultiInputData);
