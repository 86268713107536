import { Button, Textarea, Input, ContextAlertMark, Loader } from '@adtech/ui';
import DataList, { IDataListItem } from '@components/DataList';
import Dropdown from '@components/Dropdown';
import Form from '@components/Form';
import { Hint } from '@components/Hint';
import {
    ConversionFormType,
    conversionFormTypesConfig,
    conversionMetricsList,
} from '@configs/conversions/conversionForm';
import useGoals from '@hooks/useGoals';
import { IConversionTableData } from '@typings/conversions';
import { IFieldStatus } from '@typings/form';
import { IRootSlice } from '@typings/rootSlice';
import { formUtils } from '@utils/index';
import React, { ChangeEvent, ChangeEventHandler, FC, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';

import s from './ConversionForm.pcss';

interface IProps {
    type: ConversionFormType;
    closePopup: () => void;
    data: Partial<IConversionTableData>;
    api: TAPI; // апишка хуков конверсий
    isCalledFromGoalsReport?: boolean; // форму вызвали на отчете Цели
}

const ConversionForm: FC<IProps> = ({
    type,
    closePopup,
    data: {
        id: conversionId,
        title,
        description,
        numerator_goal_id: numeratorProp,
        denominator_goal_id: denominatorProp,
        metric: metricProp,
    },
    api,
    isCalledFromGoalsReport = false,
}: IProps) => {
    const GOALS_LIST_LIMIT = 100;
    const metricInitial = metricProp && conversionMetricsList.find((item) => item.name === metricProp)?.title;
    const metricValueAll = { title: 'Все', name: 'all' }; // значение "Все"
    const statusValue = { isValid: true, message: null } as IFieldStatus; // статус валидации поля

    const goalsList = useSelector((state: IRootSlice) => state.goalsSlice.tableData) || [];
    const goalsListRequest = useSelector((state: IRootSlice) => state.goalsSlice.tableRequest);
    const goalsTotals = useSelector((state: IRootSlice) => state.goalsSlice.totals);

    const [conversionName, setConversionName] = useState(title || '');
    const [conversionDescription, setConversionDescription] = useState(description || '');
    const [numeratorId, setNumeratorId] = useState<number>(numeratorProp || null);
    const [denominatorId, setDenominatorId] = useState<number>(denominatorProp || null);
    const [metric, setMetric] = useState(metricInitial || conversionMetricsList[0].title);
    const [isDropdownHidden, setIsDropdownHidden] = useState(false);
    const [conversionStatus, setConversionStatus] = useState({
        name: { ...statusValue },
        description: { ...statusValue },
        numerator: { ...statusValue },
    });
    const [isShowErrors, setShowErrors] = useState(false);

    const { getTable: getGoalsList, getTableOffset: getGoalsListOffset } = useGoals();
    const { createConversion, updateConversion, transformConversionDataForRequest, isDemoProject } = api;

    useEffect(() => {
        setIsDropdownHidden(false);
    });

    useEffect(() => {
        getGoalsList({
            limit: GOALS_LIST_LIMIT,
            showCommonGoals: true,
            showDeletedGoals: type === ConversionFormType.edit,
        });
    }, []);

    const validateName = (value: string) => {
        let status = {
            isValid: true,
            message: null,
        };

        const trimValue = value.trim();
        const validRequired = formUtils.defaultRules.required(trimValue);

        if (validRequired !== true) {
            status = {
                isValid: false,
                message: validRequired,
            };
        } else {
            const validMaxLength = formUtils.defaultRules.maxLength(255)(trimValue);

            if (validMaxLength !== true) {
                status = {
                    isValid: false,
                    message: validMaxLength,
                };
            }

            const validMinLength = formUtils.defaultRules.minLength(3)(trimValue);

            if (validMinLength !== true) {
                status = {
                    isValid: false,
                    message: validMinLength,
                };
            }
        }

        setConversionStatus((currentStatus) => ({ ...currentStatus, name: status }));
        return status.isValid;
    };

    const validateDescription = (value: string) => {
        let status = {
            isValid: true,
            message: null,
        };

        const trimValue = value.trim();
        const validRequired = formUtils.defaultRules.maxLength(500)(trimValue);

        if (validRequired !== true) {
            status = {
                isValid: false,
                message: validRequired,
            };
        }

        setConversionStatus((currentStatus) => ({ ...currentStatus, description: status }));
        return status.isValid;
    };

    const validateNumerator = (value: number | null) => {
        let status = {
            isValid: true,
            message: null,
        };

        const trimValue = value ? String(value).trim() : '';
        const validRequired = formUtils.defaultRules.required(trimValue);

        if (validRequired !== true) {
            status = {
                isValid: false,
                message: validRequired,
            };
        }

        setConversionStatus((currentStatus) => ({ ...currentStatus, numerator: status }));
        return status.isValid;
    };

    const validateForm = (): boolean => {
        const isValidName = validateName(conversionName);
        const isValidDescription = validateDescription(conversionDescription);
        const isValidNumerator = validateNumerator(numeratorId);
        const isValidForm = isValidName && isValidDescription && isValidNumerator;

        setShowErrors(!isValidForm);
        return isValidForm;
    };

    const createConversionHandler = () => {
        const isValidForm = validateForm();

        if (!isValidForm) return;

        createConversion(
            transformConversionDataForRequest(
                conversionName,
                numeratorId,
                denominatorId,
                metric,
                conversionDescription,
            ),
            isCalledFromGoalsReport,
        );
        closePopup();
    };

    const editConversionHandler = () => {
        const isValidForm = validateForm();

        if (!isValidForm) return;

        updateConversion(
            conversionId,
            transformConversionDataForRequest(
                conversionName,
                numeratorId,
                denominatorId,
                metric,
                conversionDescription,
            ),
        );
        closePopup();
    };

    const getFlatGoalsList = () =>
        goalsList
            .filter((item) => !item.is_deleted)
            .map((item) => ({
                title: item.title,
                name: String(item.id),
            }));

    const loadMoreHandler = () => {
        if (goalsList.length < goalsTotals) {
            getGoalsListOffset(goalsList.length, {
                limit: GOALS_LIST_LIMIT,
                showCommonGoals: true,
                showDeletedGoals: type === ConversionFormType.edit,
            });
        }
    };

    const renderList = (isDenominator?: boolean) => {
        let list = getFlatGoalsList();

        if (isDenominator) {
            list = [metricValueAll, ...list];
        }

        return (
            <Loader loading={goalsListRequest}>
                <DataList
                    list={list}
                    isSearchEnabled
                    searchPlaceholder="Искать по целям"
                    classNameListScroller={s.fieldsDropdownListScroller}
                    onListItemClick={(item: IDataListItem) => {
                        const func = isDenominator ? setDenominatorId : setNumeratorId;
                        func(item.name !== 'all' ? Number(item.name) : null);
                        setIsDropdownHidden(true);
                    }}
                    onScrollToListEnd={loadMoreHandler}
                />
            </Loader>
        );
    };

    const renderMetricsList = () => (
        <DataList
            list={conversionMetricsList}
            classNameListScroller={s.fieldsDropdownListScroller}
            onListItemClick={(item: IDataListItem) => {
                setMetric(item.title);
                setIsDropdownHidden(true);
            }}
        />
    );

    const handleTextareaChange: ChangeEventHandler<HTMLTextAreaElement> = (event) => {
        const value = event.target.value;
        setConversionDescription(value);
    };

    const handleInputChange = (event: ChangeEvent<HTMLInputElement>) => {
        const value = event.target.value;
        setConversionName(value);
    };

    const renderSelect = (isDenominator?: boolean) => {
        const selectedGoalId = isDenominator ? denominatorId : numeratorId;
        const defaultValue = isDenominator ? metricValueAll.title : 'Не выбрано';

        const selected = goalsList?.length && goalsList.find((goal) => goal.id === selectedGoalId)?.title;

        return (
            <Dropdown
                dataTestId="dropdown-container"
                btnDataTestId={isDenominator ? 'denominator-button' : 'numerator-button'}
                type="customContent"
                anchorText={selected || defaultValue}
                customContent={renderList(isDenominator)}
                anchorClassName={s.fieldsDropdownAnchor}
                contentClassName={s.fieldsDropdownContent}
                isHidden={isDropdownHidden}
                onClose={() => setIsDropdownHidden(false)}
            />
        );
    };

    return (
        <Form onSubmit={() => {}} className={s.addConversionForm}>
            <Input
                value={conversionName}
                name="name"
                onChange={(event) => handleInputChange(event)}
                placeholder="Название"
                type="text"
                status={!isShowErrors || conversionStatus.name.isValid ? null : 'error'}
                errorText={isShowErrors ? conversionStatus.name.message : ''}
                onBlur={() => {
                    validateName(conversionName);
                }}
                data-testid="title-input"
            />
            <Textarea
                className={s.textarea}
                value={conversionDescription}
                placeholder="Описание"
                onChange={(event) => handleTextareaChange(event)}
                status={!isShowErrors || conversionStatus.description.isValid ? null : 'error'}
                errorText={isShowErrors ? conversionStatus.description.message : ''}
                data-testid="description-input"
            />
            <div className={s.fieldsLabelWrap}>
                <div className={s.fieldsLabel}>Целевые события и метрика конверсии:</div>
                <Hint className={s.fieldsHint}>
                    <>
                        <p>Выберите в числителе целевое событие, по которому хотите построить конверсию.</p>
                        <p>
                            По умолчанию конверсия задается по отношению ко всем визитам,
                            {/* eslint-disable-next-line max-len */}
                            про более гибкую настройку можно почитать в{' '}
                            <a target="_blank" href="https://ads.sber.ru/help/konversii" rel="noopener">
                                документации
                            </a>
                        </p>
                    </>
                </Hint>
            </div>
            <Loader
                loading={goalsListRequest}
                // blurContent // FIXME: после обновления @adtech-ui
            >
                <div className={s.fields}>
                    <div className={s.fieldsGoals}>
                        {renderSelect()}
                        {renderSelect(true)}
                    </div>
                    <div className={s.fieldsMetrics}>
                        <Dropdown
                            dataTestId="dropdown-container"
                            btnDataTestId="multiplier-button"
                            type="customContent"
                            anchorText={metric || conversionMetricsList[0].title}
                            customContent={renderMetricsList()}
                            anchorClassName={s.fieldsDropdownAnchor}
                            contentClassName={s.fieldsDropdownContent}
                            isHidden={isDropdownHidden}
                            onClose={() => setIsDropdownHidden(false)}
                        />
                    </div>
                    <div className={s.fieldsConversion}>
                        <div className={s.fieldsItemEqual}>=</div>
                        <div className={s.fieldsItemText}>Конверсия</div>
                    </div>
                </div>
                {(isShowErrors || !conversionStatus.numerator.isValid) && (
                    <ContextAlertMark status="error" message={conversionStatus.numerator.message || ''} />
                )}
            </Loader>
            <div className={s.btnWrapper}>
                <Button
                    data-testid="ок-button"
                    className={s.btn}
                    disabled={isDemoProject()}
                    onClick={type === ConversionFormType.edit ? editConversionHandler : createConversionHandler}
                >
                    {conversionFormTypesConfig[type].buttonTitle}
                </Button>
                <Button data-testid="cancel-button" type="dashed" onClick={closePopup}>
                    Отмена
                </Button>
            </div>
        </Form>
    );
};

export default ConversionForm;
