import { useEffect, useRef, useState } from 'react';

const useIntersectionObserver = (options?: IntersectionObserverInit) => {
    const elemRef = useRef(null);
    const observer = useRef<IntersectionObserver>(null);
    const [isIntersecting, setIsIntersecting] = useState(false);

    const callbackFunction = (entries: IntersectionObserverEntry[]) => {
        // Работаем с одним элементом, так как ref один, но можно доработать
        const [entry] = entries;
        // Проверяем также на наличие высоты у элемента,
        // чтобы исключить ложные срабатывания при первом рендере
        setIsIntersecting(entry.isIntersecting && entry.boundingClientRect.height > 0);
    };

    useEffect(() => {
        observer.current = new IntersectionObserver(callbackFunction, options);
        return () => {
            observer.current?.disconnect();
        };
    }, [JSON.stringify(options)]);

    useEffect(() => {
        if (elemRef.current) observer.current?.observe(elemRef.current);

        return () => {
            if (elemRef.current && observer.current) observer.current.unobserve(elemRef.current);
        };
    }, [elemRef]);

    return { elemRef, isIntersecting };
};

export default useIntersectionObserver;
