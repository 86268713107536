import { DropDownList, Button, Tooltip, Loader } from '@adtech/ui';

import { availableMetrics, metricsDict } from '@configs/projects/metrics';
import { settingsMenuConfig, ISettingsMenuItem } from '@configs/projects/settingsMenu';
import IconSettingsAccess from '@images/svg/icons/icon-settings-access.svg';
import IconSettingsCode from '@images/svg/icons/icon-settings-code.svg';
import IconSettingsCounter from '@images/svg/icons/icon-settings-counter.svg';
import IconSettingsRating from '@images/svg/icons/icon-settings-rating.svg';
import IconSettingsRemove from '@images/svg/icons/icon-settings-remove.svg';
import IconSettings from '@images/svg/icons/icon-settings.svg';
import { Metrics } from '@typings/metrics';
import { IProject, TProjectDifference } from '@typings/projects';
import { numbersUtils, widgetUtils } from '@utils/index';
import cn from 'classnames';
import React, { useState, useRef } from 'react';
import { Link } from 'react-router-dom';
import s from './ProjectsTable.pcss';
import Table from './components/Table';
import TableNoSearchData from './components/TableNoSearchData';
import TablePosition from './components/TablePosition';
import TableShowMoreRow from './components/TableShowMoreRow';

interface IProps {
    isDeleted: boolean;
    tableRequest: boolean;
    tableData: any;
    getProjectsTableOffset: (offset: number) => void;
    applyModalPopup: (projectId: number) => Promise<void>;
    openModalPopup: (modalPopupParams) => void;
}

const ProjectsTable: React.FC<IProps> = ({
    isDeleted,
    tableRequest,
    tableData,
    getProjectsTableOffset,
    applyModalPopup,
    openModalPopup,
}) => {
    const [isLoadingPopup, setIsLoadingPopup] = useState(false);
    const popupProjectId = useRef(null);
    const { data, meta } = tableData;
    const viewedTableRows = data?.length || 0;
    const tableRowsTotal = meta?.total_rows || 0;
    const limit = meta?.limit || 0;

    const handleApplyOkButtonPopup = () => {
        setIsLoadingPopup(true);
        applyModalPopup(popupProjectId.current).then(() => {
            setIsLoadingPopup(false);
            popupProjectId.current = 0;
        });
    };

    const handleOpenPopup = (id: number) => {
        if (isLoadingPopup) return;
        popupProjectId.current = id;
        openModalPopup({
            okButtonHandler: handleApplyOkButtonPopup,
        });
    };

    const renderRowDiff = (difference: TProjectDifference, index: number) => {
        let diff = difference.length ? difference[index] : [];

        if (!diff.length) return null;

        diff = diff.map((item) => {
            let resultOfMap = item;

            if (Number(item) === item) {
                resultOfMap = widgetUtils.prepareDiffData(item, availableMetrics[index] as Metrics);
            }

            return resultOfMap;
        });

        const diffClassName = cn(s.tableMetricDiff, {
            [s.tableMetricDiffDown]: widgetUtils.getDiffDirection(diff) === 'down',
        });

        return (
            <Tooltip className={diffClassName} title="По сравнению с данными за предыдущие 24 часа" placement="top">
                {diff.join('')}
            </Tooltip>
        );
    };

    const renderRowMetrics = (metric: string, index: number, project: IProject) => {
        const value = project.metrics[metric] ? numbersUtils.numberFormat(project.metrics[metric].today) : 0;

        return (
            <div key={index} className={cn(s.tableMetric, s.tableMetricResult)}>
                <div className={s.tableMetricValue}>{value}</div>
                {renderRowDiff(project.difference, index)}
            </div>
        );
    };

    const renderHeadMetrics = (): React.ReactElement[] =>
        availableMetrics.map((metric: Metrics): React.ReactElement => {
            const { tooltip, title } = metricsDict[metric];

            return (
                <Tooltip className={s.tableMetric} key={metric} title={tooltip} placement="top">
                    {title}
                </Tooltip>
            );
        });

    const renderRows = (item: IProject) => {
        const {
            counter_is_set: counterIsSet,
            can_edit_project: canEditProject,
            title,
            url,
            id,
            categories,
            status,
            favicon,
        } = item;

        const positionClassName = cn(s.tablePosition, s.tablePositionResult);

        const Icon = ({ name }: { name: string }) => {
            switch (name) {
                case 'counterCode':
                    return <IconSettingsCode className={s.settingsItemIcon} />;
                case 'counter':
                    return <IconSettingsCounter className={s.settingsItemIcon} />;
                case 'rating':
                    return <IconSettingsRating className={s.settingsItemIcon} />;
                case 'access':
                    return <IconSettingsAccess className={s.settingsItemIcon} />;
                default:
                    return <IconSettingsRemove className={s.settingsItemIcon} />;
            }
        };

        // Выпадающий список с настройками счетчика
        const generateSettingDataItems = settingsMenuConfig.map((settingsItem: ISettingsMenuItem) => {
            const link = settingsItem.to;
            const content = (
                <span className={s.settingsItemLabel}>
                    <Icon name={settingsItem.id} />
                    {settingsItem.title}
                </span>
            );
            const itemProps = {
                className: s.settingsItem,
                // Если передан to, выводим Link со ссылкой на соответствующую страницу в настройках
                text: link ? <Link to={`/stat/projects/${id}/settings/${link}`}>{content}</Link> : content,
                // Иначе, по клику открывается popup
                ...(!link ? { onClick: () => handleOpenPopup(id) } : {}),
            };

            return { dropDownItemProps: itemProps };
        });

        return (
            <div className={s.tableRow} key={id}>
                <Table isDeleted={isDeleted} status={counterIsSet} title={title} url={url} id={id} favicon={favicon} />
                {isDeleted ? (
                    <div className={s.tableRecovery}>
                        <Button
                            onClick={() => handleOpenPopup(id)}
                            type="dashed"
                            size="small"
                            className={s.tableRecoveryBtn}
                        >
                            Восстановить счётчик
                        </Button>
                    </div>
                ) : (
                    <>
                        {availableMetrics.map((metric, index) => renderRowMetrics(metric, index, item))}
                        <div className={positionClassName}>
                            <TablePosition
                                isCanEdit={canEditProject}
                                ratingStatus={status}
                                categories={categories}
                                projectId={id}
                            />
                        </div>
                        <div className={s.settings}>
                            {canEditProject && (
                                <DropDownList
                                    menuItems={generateSettingDataItems}
                                    customButton={
                                        <button type="button" className={s.settingsButton}>
                                            <IconSettings />
                                        </button>
                                    }
                                    placement="bottomRight"
                                />
                            )}
                        </div>
                    </>
                )}
            </div>
        );
    };

    return (
        <div className={s.root}>
            <Loader loading={tableRequest || isLoadingPopup}>
                <div className={s.table}>
                    {viewedTableRows ? (
                        <>
                            {!isDeleted && (
                                <div className={s.tableHead}>
                                    <div className={cn(s.tableTitle, s.tableTitleHead)}>Сайт</div>
                                    {renderHeadMetrics()}
                                    <div className={s.tablePosition}>Позиция в рейтинге</div>
                                </div>
                            )}
                            <div className={s.tableBody}>
                                {data.map((item) => renderRows(item))}
                                {viewedTableRows && viewedTableRows !== tableRowsTotal ? (
                                    <div className={s.tableRow}>
                                        <TableShowMoreRow
                                            limit={limit}
                                            viewedTableRows={viewedTableRows}
                                            tableRowsTotal={tableRowsTotal}
                                            getProjectsTableOffset={getProjectsTableOffset}
                                        />
                                    </div>
                                ) : null}
                            </div>
                        </>
                    ) : (
                        <TableNoSearchData hideContent={tableRequest} />
                    )}
                </div>
            </Loader>
        </div>
    );
};

export default ProjectsTable;
