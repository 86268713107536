import { createAsyncThunk } from '@reduxjs/toolkit';
import { IGoalData } from '@typings/goals';
import { IReportRequestParams, IUpdateReportParams } from '@typings/reports';
import fetchApi from '@utils/fetchApi';

const tableLimitDefault = 20;
const offsetDefault = 0;

export const getTable = createAsyncThunk(
    'goalsSlice/getTable',
    async ({
        projectId,
        params,
    }: {
        projectId: string;
        params?: IUpdateReportParams & {
            showCommonGoals?: boolean;
            showDeletedGoals?: boolean;
        };
    }) => {
        const {
            limit = tableLimitDefault,
            offset = offsetDefault,
            titleFilter: searchWord,
            showCommonGoals,
            showDeletedGoals,
        } = params || {};
        let queryParams = limit ? `&limit=${limit}` : '';
        queryParams += offset ? `&offset=${offset}` : '';
        queryParams += searchWord ? `&search=${searchWord}` : '';
        queryParams += showCommonGoals ? '&show_common=true' : '';
        queryParams += showDeletedGoals ? '&show_deleted=true' : '';

        // eslint-disable-next-line no-return-await
        return await fetchApi({ url: `/api/v1.0/user/goals?project_id=${projectId}${queryParams}` });
    },
);

export const updateTable = createAsyncThunk(
    'goalsSlice/updateTable',
    async ({
        projectId,
        offset,
        limit = tableLimitDefault,
        searchWord,
        showCommonGoals,
        showDeletedGoals,
    }: {
        projectId: string;
        offset: number;
        limit?: number;
        searchWord?: string;
        showCommonGoals?: boolean;
        showDeletedGoals?: boolean;
    }) => {
        let queryParams = limit ? `&limit=${limit}` : '';
        queryParams += offset ? `&offset=${offset}` : '';
        queryParams += searchWord ? `&search=${searchWord}` : '';
        queryParams += showCommonGoals ? '&show_common=true' : '';
        queryParams += showDeletedGoals ? '&show_deleted=true' : '';

        // eslint-disable-next-line no-return-await
        return await fetchApi({ url: `/api/v1.0/user/goals?project_id=${projectId}${queryParams}` });
    },
);

/**
 * Получение целей по списку id` шников
 */
export const getGoalsByIds = createAsyncThunk(
    'goalsSlice/getGoalsByIds',
    async (ids: number[]) =>
        // eslint-disable-next-line no-return-await
        await fetchApi({
            url: '/api/v1.0/goals',
            method: 'POST',
            body: { ids },
        }),
);

export const createGoal = createAsyncThunk(
    'goalsSlice/createGoal',
    async (data: IGoalData) =>
        // eslint-disable-next-line no-return-await
        await fetchApi({
            url: '/api/v1.0/goal',
            method: 'POST',
            body: { ...data },
            metaInfo: {
                ignoredErrors: [400],
            },
        }),
);

export const updateGoal = createAsyncThunk(
    'goalsSlice/updateGoal',
    async ({ id, data }: { id: number; data: Partial<IGoalData> }) =>
        // eslint-disable-next-line no-return-await
        await fetchApi({
            url: `/api/v1.0/goal/${id}`,
            method: 'PUT',
            body: { ...data },
        }),
);

export const deleteGoal = createAsyncThunk(
    'goalsSlice/deleteGoal',
    async (id: number) =>
        // eslint-disable-next-line no-return-await
        await fetchApi({
            url: `/api/v1.0/goal/${id}`,
            method: 'DELETE',
            metaInfo: { id },
        }),
);

/**
 * Получение списка событий за последний месяц
 */
export const getEventsList = createAsyncThunk(
    'goalsSlice/getEventsList',
    async ({ projectId, params }: { projectId: string; params?: IReportRequestParams }) =>
        // eslint-disable-next-line no-return-await
        await fetchApi({
            url: `/api/report/v1.0/eventbase/${projectId}/constructor`,
            method: 'POST',
            body: params,
        }),
);
