import React from 'react';

export const IconExclude = () => (
    <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
        <g id="Minus circle (Default) 20px">
            <circle id="Ellipse 55" cx="10" cy="10" r="10" fill="#333F48" fillOpacity="0.25" />
            <path
                id="Vector"
                d="M14.2857 9.28577C14.6802 9.28577 15 9.60556 15 10.0001C15 10.3945 14.6802 10.7143 14.2857 10.7143L5.71429 10.7143C5.3198 10.7143 5 10.3945 5 10.0001C5 9.60556 5.3198 9.28577 5.71429 9.28577L14.2857 9.28577Z"
                fill="#83868D"
            />
        </g>
    </svg>
);
