import { InputMask } from '@components/Input';
import fieldDecorator from '@decorators/fieldDecorator';
import React, { HTMLInputTypeAttribute } from 'react';

interface IInputProps {
    field: any;
    type: HTMLInputTypeAttribute;
    placeholder: string;
    mask: string;
}

export default fieldDecorator({
    ignoreHidden: true,
})(({ field, placeholder, type, mask }: IInputProps) => (
    <InputMask {...field} type={type} placeholder={placeholder} mask={mask} isNative={false} />
));
