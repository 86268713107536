import { Metrics } from '@typings/metrics';
import html2canvas, { Options } from 'html2canvas';

export const exportToPNG = (element: HTMLElement, filename = 'export', options?: Partial<Options>) => {
    const htmlElement = element ?? document.body;

    html2canvas(htmlElement, options).then((canvas) => {
        const dataUrl = canvas.toDataURL('image/png', 1.0);
        const link = document.createElement('a');
        link.download = `${filename}.png`;
        link.href = dataUrl;
        link.click();
    });
};

// Преобразование табличных данных (массива) в строку формата csv
export const arrayToCsvString = (array: (string | number | Metrics)[][]): string =>
    array
        .map((row) =>
            row
                .map((val) => {
                    const isNumber = typeof val === 'number';
                    // форматируем пустые значения и инвертируем "" внутри данных
                    let value = String(val ?? '').replaceAll('"', '""'); // форматируем пустые значения
                    value = isNumber ? value.replace('.', ',') : value; // форматируем число под формат с ","
                    return `"${value}"`;
                })
                .join(';'),
        )
        .join('\r\n');

// Используем UTF-16 little-endian (UTF-16LE) кодировку для совместимости на разных платформах.
// Также используем Byte Order Mark (BOM) в начале строки для указания кодировки.
export const exportToCSV = (csv: string, filename = 'export', contentType = 'text/csv;charset=utf-16le'): void => {
    const BOM = '\uFEFF'; // Byte Order Mark
    const blob = new Blob([BOM + csv], { type: contentType });
    const url = URL.createObjectURL(blob);

    const link = document.createElement('a');
    link.href = url;
    link.download = `${filename}.csv`;
    link.click();
    URL.revokeObjectURL(link.href);
};
