import audience from '@configs/widgets/audience';
import technology from '@configs/widgets/technology';
import trafficType from '@configs/widgets/trafficType';

// Конфиг цветов для виджетов,
// для каждого виджет-графика можно задать произвольный набор цветов,
// некоторые виджеты имеют одинаковые конфиги, но сменить их нейминг на одинаковый проблематично
export default {
    trafficSources: trafficType,
    traffic_sources: trafficType,
    technology,
    platforms: technology,
    audience,
};
