import attributesData from '@configs/attributesData';
import IconCloseSmall from '@images/svg/icons/icon-close-small.svg';
import IconTableReload from '@images/svg/icons/icon-table-reload.svg';

import { Dimensions } from '@typings/dimensions';
import { IMetricFilter } from '@typings/filters';
import { IMetricsWithStates, Metrics } from '@typings/metrics';
import { ReportName } from '@typings/reports';
import { measuresUtils } from '@utils/index';
import cn from 'classnames';
import React, { ReactNode } from 'react';

import s from '../../ReportTable.pcss';
import TableTitleFilter from '../TableTitleFilter';
import FilterButton from './FilterButton';
import PercentButton from './PercentButton';

interface IProps {
    metrics: Metrics[];
    dimensions?: Dimensions[];
    reportName: ReportName;
    titleFilter: string;
    totalLines: number[];
    metricsWithStates: IMetricsWithStates[];
    onFilterSelect: (name: string, operation?: string, value?: string) => void;
    onTitleFilterSelect: (newTitleFilter: string) => void;
    tableRequest: boolean;
    onResetSelectedLines: () => void;
    onPercentSelect: (metric: string) => void;
    tableFilters: IMetricFilter[];
    utils: TAPI;
}

const TableButtons: React.FC<IProps> = (props) => {
    const {
        metricsWithStates,
        titleFilter,
        reportName,
        tableRequest,
        onTitleFilterSelect,
        onResetSelectedLines,
        totalLines,
        metrics,
        dimensions,
        tableFilters,
        onFilterSelect,
        onPercentSelect,
        utils,
    } = props;
    const newreportAttr = attributesData.newreport;

    const renderFilterValue = (metric: string) => {
        let result = null;
        const onClick = () => onFilterSelect(metric);

        if (metric === 'title') return null;

        tableFilters.map((filter) => {
            const { key: filterMetric, value, op } = filter;
            const operationSymbol = op === '=' ? '' : op;
            let resultValue = measuresUtils.prepareValue(filterMetric, value);

            resultValue = [operationSymbol, resultValue].join(' ');

            if (filterMetric === metric) {
                result = (
                    <div className={s.filterResult} key={filterMetric}>
                        <span className={s.filterResultValue}>{resultValue}</span>
                        <span className={s.filterResultIcon} onClick={onClick}>
                            <IconCloseSmall />
                        </span>
                    </div>
                );
            }
            return filter;
        });

        return result;
    };

    const renderButtonCell = (metricWithState: IMetricsWithStates, index: number): ReactNode => {
        const percent = metricWithState.state === 'percent';
        const buttonsList = ['percent', 'filter'];
        const buttons = [];

        if (utils.canHideIndicator(reportName, metricWithState.name)) {
            return null;
        }

        buttonsList.map((item) => {
            if (item === 'percent') {
                if (measuresUtils.canShowInPercents(metricWithState.name)) {
                    const className = cn(s.tableFiltersItem, {
                        [s.tableFiltersItemActive]: percent,
                    });
                    const dataAttr = [newreportAttr.table.attrName, newreportAttr.table.percentBtn, metrics[index]];

                    buttons.push(
                        <PercentButton
                            className={className}
                            iconClassName={s.tableFiltersIcon}
                            key="0"
                            onPercentSelect={() => onPercentSelect(metricWithState.name)}
                            metric={metrics[index]}
                            isActive={percent}
                            dataAttrName={newreportAttr.attrName}
                            dataAttr={dataAttr}
                            reportName={reportName}
                        />,
                    );
                }
            } else if (item === 'filter') {
                if (measuresUtils.canShowSort(metricWithState.name)) {
                    const dataAttr = [newreportAttr.table.attrName, newreportAttr.table.filterBtn, metrics[index]];
                    const filterValue = renderFilterValue(metrics[index]);

                    buttons.push(
                        <React.Fragment key={item}>
                            <FilterButton
                                className={s.tableFiltersItem}
                                key={item}
                                total={totalLines[index] || 0}
                                metric={metrics[index]}
                                onFilterSelect={(operation, value) =>
                                    onFilterSelect(metricWithState.name, operation, value)
                                }
                                dataAttr={dataAttr}
                                dataAttrName={newreportAttr.attrName}
                                tableFilters={tableFilters}
                                reportName={reportName}
                            />
                            {filterValue}
                        </React.Fragment>,
                    );
                }
            }
            return item;
        });

        return (
            <th className={cn(s.tableCell, s.tableFiltersCell)} key={index}>
                <div className={s.tableFilters} key={index}>
                    {buttons.map((item) => item)}
                </div>
            </th>
        );
    };

    const renderCells = (): ReactNode[] | null => {
        if (!Array.isArray(metricsWithStates)) return null;

        return metricsWithStates.map(renderButtonCell);
    };

    const tableAttr = newreportAttr.table;

    const handleReset = () => {
        onResetSelectedLines();
    };

    const rowStyles = cn(s.tableRow, s.tableRowFilters);
    const cellStyles = cn(s.tableCell, s.tableCellTitleFilter, s.tableCellName);
    const classNameIcon = cn(s.tableFiltersIcon, s.resetSelectedLines);

    const isTableTitleSearch = utils.getParamFromConfig(reportName, 'isTableTitleSearch');

    return (
        <tr className={rowStyles}>
            <th className={cellStyles}>
                <div className={s.tableFiltersLeft}>
                    {isTableTitleSearch ? (
                        <TableTitleFilter
                            titleFilter={titleFilter}
                            reportName={reportName}
                            tableRequest={tableRequest}
                            onFilter={onTitleFilterSelect}
                        />
                    ) : null}
                    {!utils.isDisabledCheckbox(reportName) && (
                        <div
                            className={classNameIcon}
                            onClick={handleReset}
                            data-newreport={`${tableAttr.attrName}::${tableAttr.resetBtn}::${reportName}`}
                        >
                            <IconTableReload />

                            <span className={s.resetSelectedLinesText}>
                                Сбросить <span>выбранные</span>
                            </span>
                        </div>
                    )}
                </div>
            </th>
            {dimensions &&
                dimensions.map((item) => (
                    <th className={cellStyles} key={`d_${item}`}>
                        {' '}
                    </th>
                ))}
            {renderCells()}
        </tr>
    );
};

export default TableButtons;
