import { Loader } from '@adtech/ui';
import { GReCaptcha } from '@components/GReCaptcha';
import captсhaConfig from '@configs/captcha';
import fieldDecorator from '@decorators/fieldDecorator';
import { IField, IMetaField } from '@typings/form';
import React, { useState, useEffect, useRef } from 'react';
import s from '../FormCreateCounter.pcss';

interface IProps {
    field: IField;
    loading: boolean;
    meta: IMetaField;
}

const CaptchaComponent: React.FC<IProps> = ({ field, meta, loading }) => {
    const captcha = useRef(null);
    const [invalid, setInvalid] = useState(meta.invalid);
    const [isLoadCaptcha, setIsLoadCaptcha] = useState(true);

    useEffect(() => {
        setInvalid(meta.invalid);

        if (meta.invalid) captcha.current.reset();
    }, [meta.invalid]);

    const setCaptchaField = (value: string) => {
        field.onChange(null, value);
    };

    const onLoadCaptcha = () => {
        setIsLoadCaptcha(false);
    };

    return (
        <div className={s.captchaRow}>
            <Loader loading={loading || isLoadCaptcha}>
                <GReCaptcha
                    ref={captcha}
                    elementID="g-recaptcha"
                    siteKey={captсhaConfig.publicKey}
                    hl={captсhaConfig.hl}
                    callback={setCaptchaField}
                    theme="light"
                    size="normal"
                    onloadCallback={onLoadCaptcha}
                />
                {invalid && <span className={s.captchaError}>{meta.errorText}</span>}
            </Loader>
        </div>
    );
};

const Captcha = fieldDecorator({
    name: 'captcha_response',
})(CaptchaComponent);

export default Captcha;
