import ReportPlaceholder from '@components/ReportPlaceholder';
import ReportTable from '@components/ReportTable';
import mediaConstants from '@constants/media';
import ChartContainer from '@containers/Chart';
import { useGraphMedia } from '@hooks/useGraph';
import useMedia from '@hooks/useMedia';
import { useTableMedia } from '@hooks/useTable';
import { IMediaContainer, ReportNameMedia, TRenderReportTools } from '@typings/media';
import { IRootSlice } from '@typings/rootSlice';
import React from 'react';
import { useSelector } from 'react-redux';

interface IProps {
    reportName: ReportNameMedia;
    projectId: number;
    renderReportTools: TRenderReportTools;
}

const MediaAuthorsSummary: React.FC<IMediaContainer> = ({ reportName, projectId, renderReportTools }: IProps) => {
    const { api: apiMedia, reportsUtils } = useMedia();

    const { api: apiTable, tableUtils } = useTableMedia();

    const { api: apiGraph, graphUtils } = useGraphMedia();

    const api = { ...apiMedia, ...apiTable, ...apiGraph };
    const utils = { ...reportsUtils, ...tableUtils };

    const { getParamFromConfig } = reportsUtils;

    const tableData = useSelector((state: IRootSlice) => state.tableSlice.tableData);
    const allMetrics = useSelector((state: IRootSlice) => state.tableSlice.allMetrics);
    const metrics = useSelector((state: IRootSlice) => state.tableSlice.metrics);
    const metricsWithStates = useSelector((state: IRootSlice) => state.tableSlice.metricsWithStates);
    const tableFilters = useSelector((state: IRootSlice) => state.tableSlice.tableFilters);
    const totalRows = useSelector((state: IRootSlice) => state.tableSlice.totalRows);
    const orderBy = useSelector((state: IRootSlice) => state.tableSlice.orderBy);
    const tableRequest = useSelector((state: IRootSlice) => state.tableSlice.tableRequest);
    const trafficLabels = useSelector((state: IRootSlice) => state.tableSlice.trafficLabels);
    const titleFilter = useSelector((state: IRootSlice) => state.tableSlice.titleFilter);
    const appliedFilters = useSelector((state: IRootSlice) => state.tableSlice.tableFilters);

    const graphRequest = useSelector((state: IRootSlice) => state.graphSlice.graphRequest);
    const graphData = useSelector((state: IRootSlice) => state.graphSlice.graphData);
    const useDefaultLines = useSelector((state: IRootSlice) => state.graphSlice.useDefaultLines);
    const selectedLines = useSelector((state: IRootSlice) => state.graphSlice.selectedLines);

    const showTotals = getParamFromConfig(reportName, 'isShowTotals', true);
    const tableFixedDimensions = getParamFromConfig(reportName, 'tableFixedDimensions');

    const needsPlaceholder = !Object.keys(tableData.dict).length && !tableRequest && !appliedFilters.length;

    return (
        <>
            {renderReportTools()}
            {needsPlaceholder ? (
                <ReportPlaceholder reportName={reportName} projectId={projectId} />
            ) : (
                <>
                    <ChartContainer api={apiMedia} utils={{ ...reportsUtils, ...graphUtils }} />
                    {tableData && (
                        <ReportTable
                            reportName={reportName}
                            api={api}
                            utils={utils}
                            metrics={metrics}
                            allMetrics={allMetrics}
                            metricsWithStates={metricsWithStates}
                            tableTitle="Автор"
                            orderBy={orderBy}
                            totals={[]}
                            tableData={tableData}
                            titleFilter={titleFilter}
                            tableFilters={tableFilters}
                            totalRows={totalRows}
                            tableRequest={tableRequest}
                            showTotals={showTotals}
                            labels={trafficLabels}
                            useSelectedLines={false}
                            constants={mediaConstants}
                            dimensions={tableFixedDimensions}
                            graphRequest={graphRequest}
                            graphData={graphData}
                            useDefaultLines={useDefaultLines}
                            selectedLines={selectedLines}
                        />
                    )}
                </>
            )}
        </>
    );
};

export default MediaAuthorsSummary;
