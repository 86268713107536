import { Button, ContextAlertMark } from '@adtech/ui';
import CounterBlocks from '@components/CounterBlocks';
import Form from '@components/Form';
import { projectsStatus } from '@constants/projects';
import { ICurrentProject, IValues } from '@typings/projects';
import { formUtils } from '@utils/index';
import React from 'react';
import s from '../SettingsCounter.pcss';

interface IProps {
    config: Record<string, any>;
    saveCounterRequest: boolean;
    onSubmit;
    saveCounter;
    setServerError;
    values: IValues;
    invalid: boolean;
    currentProject: ICurrentProject;
    serverError: string;
    forms: any;
}

export const RatingFormComponent: React.FC<IProps> = ({
    config,
    saveCounterRequest,
    onSubmit,
    saveCounter,
    setServerError,
    values,
    invalid,
    currentProject,
    serverError,
    forms,
}) => {
    const inRating = values.inRating || false;
    const disableEdit =
        [projectsStatus.EDITING, projectsStatus.NEED_CHANGE, projectsStatus.DROPPED_OUT].indexOf(
            currentProject.status,
        ) > -1;

    let showSaveButton = false;
    if (
        (currentProject.status !== projectsStatus.UNINCLUDED || inRating) &&
        currentProject.status !== projectsStatus.EXCLUDED
    ) {
        showSaveButton = true;
    }

    const invalidFields =
        invalid && forms.ratingSettings.fields ? formUtils.getInvalidFields(forms.ratingSettings.fields) : {};

    const fieldsInvalidAlert = (
        <>
            Необходимо правильно заполнить поля:
            {Object.keys(invalidFields).map((key, index, array) => (
                <span className={s.invalidField} key={key}>
                    {invalidFields[key].label}
                    {array.length - 1 !== index && ', '}
                </span>
            ))}
        </>
    );

    const saveSettingsCounter = () => {
        onSubmit(values, invalid, saveCounter, currentProject, setServerError, config);
    };

    return (
        <Form onSubmit={saveSettingsCounter}>
            <div className={s.block}>
                <CounterBlocks.Rating
                    config={config}
                    inRating={inRating}
                    canRating={currentProject.can_be_in_rate}
                    status={currentProject.status}
                    disableEdit={disableEdit}
                    withoutDelimiter
                    mode="settings"
                    ratingBlocker={currentProject.blacklisted || currentProject.ununique}
                />
            </div>
            {showSaveButton && (
                <div className={s.actions}>
                    <Button
                        loading={saveCounterRequest}
                        disabled={invalid || saveCounterRequest}
                        htmlType="submit"
                        className={s.save}
                    >
                        Сохранить изменения
                    </Button>
                    {((saveCounterRequest && invalid && Object.keys(invalidFields).length) || serverError) && (
                        <ContextAlertMark message={serverError || fieldsInvalidAlert} status="error" />
                    )}
                </div>
            )}
        </Form>
    );
};
