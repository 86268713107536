import { Loader } from '@adtech/ui';
import TableShowMoreRow from '@components/ProjectsTable/components/TableShowMoreRow';
import ReportMessage from '@components/ReportMessage';
import TableTitleFilter from '@components/ReportTable/components/TableTitleFilter';
import { ReportName } from '@typings/reports';
import cn from 'classnames';
import React from 'react';

import s from './SimpleTable.pcss';

interface IProps {
    loading: boolean;
    tableData: Record<string, any>[];
    getTableOffset: (offset: number) => void;
    headTitles: Record<string, string>[];
    totals: number;
    limit?: number;
    getActions?: (template?: any) => React.ReactElement;
    titleFilter?: string;
    onTitleFilter?: (value: string) => void;
    reportName?: ReportName;
    onClickRow?: (id?: number | string) => void;
    rowRenders?: Record<string, (value: any) => JSX.Element>;
}

const SimpleTable: React.FC<IProps> = ({
    loading,
    tableData,
    getTableOffset,
    headTitles,
    totals,
    limit,
    getActions,
    onClickRow,
    titleFilter,
    onTitleFilter,
    reportName,
    rowRenders,
}) => {
    const tableRows = tableData?.length || 0;
    const tableLimit = limit || 10;

    const showTableSearch = typeof onTitleFilter === 'function';
    const showActions = typeof getActions === 'function';
    const isClickableRow = typeof onClickRow === 'function';

    // TODO: добавить типизацию
    const renderRow = (template: Record<string, any>) => (
        <div className={s.tableRow} key={template.id}>
            <div
                key={template.id}
                className={cn(s.tableRowData, { [s.hovered]: isClickableRow })}
                {...(isClickableRow ? { onClick: () => onClickRow(template.id) } : {})}
            >
                {headTitles.map((headElem, index) => (
                    <div
                        className={cn(s.tableCell, { [s.disabled]: !template[headElem.param] })}
                        key={template[headElem.param] || `${template.id}_cell_${index}`}
                    >
                        {rowRenders && rowRenders[headElem.param]
                            ? rowRenders[headElem.param](template)
                            : template[headElem.param] || 'Не определено'}
                    </div>
                ))}
            </div>
            {showActions && <div className={cn(s.tableCell, s.tableCellAction)}>{getActions(template)}</div>}
        </div>
    );

    const renderHeadCells = (): React.ReactElement[] =>
        headTitles.map(
            (headElem): React.ReactElement => (
                <div className={s.tableTitle} key={headElem.title}>
                    {headElem.title}
                </div>
            ),
        );

    return (
        <div className={s.root}>
            <Loader loading={loading}>
                <div className={s.table}>
                    <div className={s.tableHead}>
                        {renderHeadCells()}
                        {showActions && <div className={cn(s.tableTitle, s.tableCellAction)} />}
                    </div>
                    {showTableSearch && (
                        <div className={s.tableSearch}>
                            <TableTitleFilter
                                titleFilter={titleFilter}
                                reportName={reportName}
                                tableRequest={loading}
                                onFilter={onTitleFilter}
                            />
                        </div>
                    )}
                    {tableRows ? (
                        <div className={s.tableBody}>
                            {tableData.map((row) => renderRow(row))}
                            {tableRows < totals ? (
                                <div className={s.tableRow}>
                                    <TableShowMoreRow
                                        limit={tableLimit}
                                        viewedTableRows={tableRows}
                                        tableRowsTotal={totals}
                                        getProjectsTableOffset={getTableOffset}
                                    />
                                </div>
                            ) : null}
                        </div>
                    ) : (
                        <ReportMessage className={s.message} message="Нет данных для отображения" />
                    )}
                </div>
            </Loader>
        </div>
    );
};

export default SimpleTable;
