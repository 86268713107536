import { Loader } from '@adtech/ui';
import { ReportName } from '@typings/reports';
import cn from 'classnames';
import React, { FC, useEffect } from 'react';
import s from '../Dropdown.pcss';
import LeftColumn from './LeftColumn';
import RightColumn from './RightColumn';

export interface IProps {
    leftType: 'list' | 'checkbox';
    rightType: 'checkbox' | 'custom';
    data?: Record<string, any>;
    dynamicData?: Record<string, any>;
    getDynamicData?: any;
    onSetTab?: any;
    onClear?: (item: Record<string, any>) => void;
    onSelectListItem?: any;
    getFilterAttr?: any;
    selectedList?: number[];
    activeListElement?: number[];
    values?: Record<string, any>;
    reportName: ReportName;
    initialValues?: Record<string, any>;
    className?: string;
}

const Content: FC<IProps> = (props) => {
    const { data, dynamicData, getDynamicData, activeListElement, values, className } = props;

    /**
     * Получаем ID выбранного родителя
     */
    const getListParentId = () => (data.mode === 'three' ? activeListElement[0] || false : -1);

    /**
     * Выбираем крайнего ребенка
     */
    const getListChildId = () =>
        data.mode === 'three' ? activeListElement[activeListElement.length - 1] || false : -1;

    useEffect(() => {
        const childId = getListChildId();
        const activeItem = childId ? data.map[childId] : false;

        if (activeItem?.options) {
            const { isDynamic } = activeItem.options;

            if (isDynamic && dynamicData && !dynamicData[activeItem.name]) {
                getDynamicData(activeItem.name);
            }
        }
    }, [data, dynamicData, activeListElement]);

    const childId = getListChildId();
    const activeItem = childId ? data.map[childId] : false;
    const value = activeItem ? values[activeItem.name] || {} : {};

    if (data.mode === 'flat') {
        return (
            <div className={s.list}>
                <div className={s.listItem}>
                    <LeftColumn // TODO: прописать явно пропсы и сделать их обязательными
                        {...props}
                        className=""
                        getListParentId={getListParentId}
                        getListChildId={getListChildId}
                    />
                </div>
                <div className={s.listItem} />
            </div>
        );
    }

    const curDynamicData = activeItem ? dynamicData[activeItem.name] : false;

    // На этом уровне лоадер вызывается только в том случае, если данных вообще нет.
    // Дальше он может использоваться внутри компонентов
    const isLoading = !!(curDynamicData?.isLoading && !curDynamicData?.data.length);
    const isRequiredDynamic = activeItem?.options?.isDynamic;

    const conditionShowRightColumn = (isRequiredDynamic && !isLoading && curDynamicData) || !isRequiredDynamic;

    const isDataLength = data.data.length > 0;

    return (
        <div className={cn(s.list, { [className]: className })}>
            <div className={s.listItem}>
                {isDataLength ? (
                    <LeftColumn
                        {...props}
                        className=""
                        getListParentId={getListParentId}
                        getListChildId={getListChildId}
                    />
                ) : (
                    <div className={s.listEmpty}>Элементы не найдены, попробуйте ввести что-нибудь другое</div>
                )}
            </div>
            <div
                className={s.listItem}
                data-testid={activeItem?.type === 'textarea' ? 'textArea-container' : 'checkbox-container'}
            >
                {activeItem?.type ? (
                    <Loader loading={isLoading}>
                        {conditionShowRightColumn && (
                            <RightColumn
                                {...props}
                                className=""
                                dynamicData={curDynamicData}
                                key={activeItem.name}
                                name={activeItem.name}
                                activeItem={activeItem}
                                value={value}
                            />
                        )}
                    </Loader>
                ) : (
                    isDataLength && <div className={s.listEmpty}>Пока не выбрана ни одна группа параметров</div>
                )}
            </div>
        </div>
    );
};

export default Content;
