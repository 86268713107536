import { IconSearch } from '@adtech/ui';
import Input from '@components/Input';
import { INavigationItem } from '@typings/reports';
import domUtils from '@utils/domUtils';
import urlUtils from '@utils/url';
import cn from 'classnames';
import React, { RefObject, useEffect, useState, useRef } from 'react';
import { Link, useNavigate } from 'react-router-dom';

import s from './ReportSearch.pcss';

interface IProps {
    projectId?: number;
    searchWord?: boolean;
    inputOverlay?: boolean;
    customSearch?: boolean;
    className?: string;
    classNameRoot?: string;
    value?: string;
    onChange?: (value: string) => void;
    onClear?: () => void;
    placeholder?: string;
    classNameResult?: string;
    classNameRootActive?: string;
    reportsMenu?: INavigationItem[];
    dataAttrName?: string;
    dataAttr?: string[];
}

const regex = /[\w|а-яА-Я| ]+/u;

const ReportSearch: React.FC<IProps> = ({
    searchWord,
    className,
    inputOverlay,
    customSearch,
    placeholder,
    classNameRoot,
    classNameResult,
    classNameRootActive,
    value = '',
    onChange,
    onClear,
    reportsMenu,
    projectId,
    dataAttrName = '',
    dataAttr = [],
}) => {
    const [openResult, setOpenResult] = useState<boolean>(false);
    const [active, setActive] = useState<boolean>(false);
    const [searchValue, setSearchValue] = useState<string>('');
    const [searchResults, setSearchResults] = useState<INavigationItem[]>([]);
    const [placeholderIconActive, setPlaceholderIconActive] = useState<boolean>(false);
    const [selectedItem, setSelectedItem] = useState<string>('');
    const [counter, setCounter] = useState<number>(0);

    const navigate = useNavigate();

    const root: RefObject<HTMLElement> = useRef();

    useEffect(() => {
        if (root && dataAttrName && dataAttr.length) {
            domUtils.putDataAttr(root.current as HTMLElement, `${dataAttrName}=${dataAttr.join('::')}`);
        }
    }, []);

    const handleBlur = () => {
        setPlaceholderIconActive(false);

        if (searchValue) return;

        setActive(false);
    };

    // TODO: переписать поиск, это какая-то жуткая неоптимальная херня
    const getSearchResults = (item, result, newSearchValue: string = '') => {
        const newResult = [...result];
        const isMatch = newSearchValue.match(regex);
        const searchString = isMatch ? isMatch[0].toLowerCase() : '';

        const isFound = item.title.toLowerCase().includes(searchString) || item.name.includes(searchString);

        if (isFound) {
            newResult.push(item);
        }

        return newResult;
    };

    // TODO: переписать поиск, это какая-то жуткая неоптимальная херня
    const recursionSearch = (data, val, result = []) => {
        let newResult = [];
        data.forEach((item) => {
            if (item.children) {
                newResult = newResult.concat(recursionSearch(item.children, val, result));
            } else {
                newResult = newResult.concat(getSearchResults(item, result, val));
            }

            return item;
        });

        return newResult;
    };

    const getSearchItems = (val: string = '') => recursionSearch(reportsMenu, val);

    const closeResult = () => setOpenResult(false);

    // TODO: изменить на другое имя (?)
    const handleChange = (newSearchValue: string, isClear: boolean) => {
        setSearchValue(newSearchValue);

        if (reportsMenu) {
            const newSearchResults = getSearchItems(newSearchValue);

            // Show search results only for long query words (> 2 symbols)
            setOpenResult(newSearchValue.length > 2 && !!newSearchResults.length);
            setSearchResults(newSearchResults);
        }

        if (isClear === true && typeof onClear === 'function') {
            onClear();
        } else if (typeof onChange === 'function') {
            onChange(newSearchValue);
        }
    };

    const handleSetActive = () => {
        setActive(true);
        setPlaceholderIconActive(true);
    };

    const clearData = (e: React.MouseEvent<HTMLElement>) => {
        e.stopPropagation();

        setActive(false);
        setPlaceholderIconActive(false);
        setOpenResult(false);
        setSearchValue('');
    };

    const handlePathChange = () => {
        setSearchValue('');
        setActive(false);
    };

    const isSelectedItem = (name: string) => name === (selectedItem || searchResults[0]?.name);

    const renderList = () =>
        getSearchItems(searchValue).map((item) => {
            // TODO: взято из рендера NavigationItem,
            // потому как у Дзена и Источников трафика одинаковые ключи (?)
            // ('item', item);
            const key = item.child || item.name;
            const itemClassName = cn(s.reportSearchResultItem, {
                [s.reportSearchResultItemSelected]: isSelectedItem(item.name),
            });

            return (
                <Link
                    key={key}
                    onClick={clearData}
                    to={urlUtils.generateUrl(projectId, item)}
                    className={itemClassName}
                >
                    {item.title}
                </Link>
            );
        });

    const switchResults = (event) => {
        if (!searchResults.length) return;

        const key = event.keyCode;
        let count = counter;

        if (key === 13) {
            const currentItem = selectedItem || searchResults[0].name;

            if (currentItem) {
                const selectedLink = searchResults.find((item) => item.name === currentItem);
                const url = urlUtils.generateUrl(projectId, selectedLink);

                navigate(url);
            }
        }

        const resultsLength = searchResults.length;

        if (resultsLength === 1) return;

        // Navigation on search results menu
        // Down key
        if (key === 40) {
            if (!selectedItem) {
                count = 2;
            } else if (count >= resultsLength) {
                count = resultsLength;
            } else {
                count++;
            }

            // Up key
        } else if (key === 38) {
            count = count <= 1 ? 1 : count - 1;
        }

        if (count !== counter) setCounter(count);

        const newActiveItem = searchResults[count - 1];
        if (newActiveItem && (key === 38 || key === 40)) {
            setSelectedItem(newActiveItem.name);
        }
    };

    const searchClass = cn(s.reportSearch, active && s.reportSearchActive, className);

    const inputRootClass = cn(classNameRoot, s.reportSearchInputBlock, {
        [s.reportSearchInputBlockOverlay]: inputOverlay,
        [s.reportSearchInputBlockActive]: active,
        [classNameRootActive]: active,
    });

    const classNameIconSearch = cn(s.reportSearchInputPlaceholderIcon, {
        [s.reportSearchInputPlaceholderIconActive]: placeholderIconActive,
    });

    const resultClassName = cn(s.reportSearchResult, classNameResult);

    return (
        <div className={searchClass}>
            <span className={s.reportSearchIcon} onClick={handleSetActive} ref={root}>
                <IconSearch />

                {searchWord && <span className={s.reportSearchWord}>Искать</span>}
            </span>

            <Input
                value={searchValue || value}
                focus={active}
                onBlur={handleBlur}
                onChange={handleChange}
                onKeyDown={switchResults}
                onClear={() => handleChange('', true)}
                className={s.reportSearchInput}
                classNameRoot={inputRootClass}
                classNameIconSearch={classNameIconSearch}
                placeholder={placeholder}
                onPathChange={handlePathChange}
            />

            {!customSearch && openResult && <ul className={resultClassName}>{renderList()}</ul>}

            {!customSearch && openResult && <div onClick={closeResult} className={s.reportSearchOverlay} />}
        </div>
    );
};

export default ReportSearch;
