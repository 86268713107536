import { Button } from '@adtech/ui';
import React, { FC } from 'react';

import s from './TemplateForms.pcss';

interface IProps {
    deleteTemplate: () => void;
    closePopup: () => void;
}

const DeleteTemplate: FC<IProps> = ({ deleteTemplate, closePopup }: IProps) => {
    // Удаление шаблона
    const handleDeleteTemplate = () => {
        closePopup();
        deleteTemplate();
    };

    return (
        <div className={s.deleteTemplate}>
            <div>Вы уверены, что хотите удалить сохранённый отчёт?</div>
            <div className={s.buttons}>
                <Button size="small" onClick={handleDeleteTemplate}>
                    Удалить
                </Button>
            </div>
        </div>
    );
};

export default DeleteTemplate;
