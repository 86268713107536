import IconStar from '@images/svg/icons/icon-star.svg';
import cn from 'classnames';
import React, { useState } from 'react';
import s from './StarsPoll.pcss';

interface IProps {
    onApply?: (id: number) => void;
}

const StarsPoll: React.FC<IProps> = ({ onApply }) => {
    const [data, setData] = useState([false, false, false, false, false]);
    const [pickedData, setPickedData] = useState([false, false, false, false, false]);

    const updateColoredIcons = (id: number) => {
        setData(Array.from({ length: 5 }, (v, k) => k <= id));
    };

    const handleMouseLeave = () => {
        setData(pickedData);
    };

    const handleMouseEnter = (id: number) => {
        updateColoredIcons(id);
    };

    const handleClick = (id: number) => {
        updateColoredIcons(id);
        setPickedData(Array.from({ length: 5 }, (v, k) => k <= id));
        if (onApply) onApply(id);
    };

    return (
        <div className={s.root}>
            {data.map((item, index) => (
                <div
                    id={`starPoll-${index}`}
                    // eslint-disable-next-line react/no-array-index-key
                    key={index}
                    className={s.item}
                    onMouseEnter={() => handleMouseEnter(index)}
                    onMouseLeave={handleMouseLeave}
                    onClick={() => handleClick(index)}
                >
                    <IconStar className={cn(s.itemIcon, { [s.itemIconActive]: item })} />
                </div>
            ))}
        </div>
    );
};

export default StarsPoll;
