import { Radio } from '@adtech/ui';
import { IDataListItem } from '@components/DataList';
import WidgetFilter from '@components/WidgetFilter';
import React, { FC, useState } from 'react';

interface IProps {
    metricsList: IDataListItem[];
    widgetName: string;
    onChange: any;
}

const MetricWidgetFilter: FC<IProps> = ({ metricsList, widgetName, onChange }) => {
    const [selectedMetric, setSelectedMetric] = useState('page_views');

    const onRadioCheck = (metric: string) => {
        onChange(widgetName, metric);
        setSelectedMetric(metric);
    };

    const renderListItem = (item: IDataListItem) => {
        const { title, name } = item;

        return (
            <Radio checked={name === selectedMetric} onChange={() => onRadioCheck(name)}>
                {title}
            </Radio>
        );
    };

    return <WidgetFilter list={metricsList} renderListItemHandler={renderListItem} />;
};

export default MetricWidgetFilter;
