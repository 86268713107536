import DateModal from '@components/DateModal';
import { imgSrc, imgSet } from '@images/error507';
import { globalActions } from '@redux/slices/global';
import { DateType, DateLiteral } from '@typings/date';
import React, { useEffect, FC, useState } from 'react';
import { useDispatch } from 'react-redux';

import s from './Error.pcss';

interface IProps {
    handleChangeDate: (date: string[], relativeDate: DateLiteral) => void;
    dateStart: DateType;
    dateEnd: DateType;
    minCalendarDate: DateType;
    maxCalendarDate: DateType;
    serverTime: number;
    getLatency: () => void;
    isExcludedLatency: boolean;
}

const Error507: FC<IProps> = ({
    handleChangeDate,
    dateStart,
    dateEnd,
    minCalendarDate,
    maxCalendarDate,
    serverTime,
    getLatency,
    isExcludedLatency,
}) => {
    const dispatch = useDispatch();

    const [isOpened, setIsOpened] = useState(false);
    const clearError = () => dispatch(globalActions.clearError());
    const handleDateClick = () => {
        setIsOpened(!isOpened);
    };

    useEffect(
        () => () => {
            clearError();
        },
        [],
    );

    return (
        <div className={s.root} data-testid="error-507">
            <img alt="Error: 507" src={imgSrc} srcSet={`${imgSet[0]} 2x, ${imgSet[1]} 3x`} />
            <div className={s.text}>
                <h2>Превышен лимит памяти</h2>
                <div>
                    <p>Кажется, вы поймали пролетающую мимо ошибку.</p>
                    <p>
                        Ваш запрос не может быть обработан. Вы запросили слишком большой объем данных. Попробуйте
                        уменьшить&nbsp;
                        <span className={s.button} onClick={handleDateClick}>
                            временной период
                        </span>
                        .
                    </p>

                    <DateModal
                        defaultDate={{ dateStart, dateEnd }}
                        onChange={handleChangeDate}
                        onClose={handleDateClick}
                        minDate={minCalendarDate}
                        maxDate={maxCalendarDate}
                        isOpened={isOpened}
                        serverTime={serverTime}
                        getLatency={getLatency}
                        isExcludedLatency={isExcludedLatency}
                    />
                </div>
            </div>
        </div>
    );
};

export default Error507;
