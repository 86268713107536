import { imgSrc, imgSet } from '@images/error400';
import React from 'react';

export default {
    imgSrc,
    imgSet: `${imgSet[0]} 2x, ${imgSet[1]} 3x`,
    code: '400',
    title: 'Ошибка запроса',
    getContent() {
        return (
            <div>
                <p>Кажется, вы поймали пролетающую мимо ошибку.</p>
                <p>
                    Попробуйте перезагрузить страницу или поискать нужный счётчик в&nbsp;
                    <a href="/stat/projects" target="_self">
                        Моих сайтах
                    </a>
                    .
                </p>
            </div>
        );
    },
};
