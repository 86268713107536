import { TProductFooterMenuItem } from "@adtech/ui";

export const footerMenuItems: TProductFooterMenuItem[] = [
    { id: 'contacts', title: 'Контакты', link: 'mailto:help@ads.sber.ru' },
    { id: 'support', title: 'Поддержка', link: 'mailto:counter@ads.sber.ru' },
    { id: 'help', title: 'Документация', link: 'https://ads.sber.ru/help/stat' },
    { id: 'offers', title: 'Пользовательское соглашение', link: 'https://ads.sber.ru/legal/termsofuse_stat' },
    { id: 'privacy-policy', title: 'Конфиденциальность', link: 'https://ads.sber.ru/legal/privacypolicy' },
];

export const specialHelpLinks = {
    usability: 'https://ads.sber.ru/help/prakticheskoe-rukovodstvo-po-analitike-blokov',
    custom_vars: 'https://ads.sber.ru/help/otpravka-sobstvennykh-sobytii',
};
