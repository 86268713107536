import { ChartTypes } from '@configs/graph';
import { sortOrders } from '@constants/sort';
import { IReportConfig } from '@typings/reports';

const reportConfig: IReportConfig = {
    reportTitle: 'Параметры URL',
    tableDataType: 'dynamic',
    defaultSort: {
        name: 'visits',
        order: sortOrders.DESC,
    },
    numberOfGraphs: 'two',
    defaultGraphs: [
        ChartTypes.line,
        ChartTypes.bar,
    ],
    graphDefaultMetrics: [
        'page_views',
        'visits',
    ],
    tableDefaultMetrics: [
        'page_views',
        'visits',
        'visitors',
    ],
    availableMetrics: [
        'visits',
        'page_views',
        'visitors',
        'uid',
        'rambler_id',
        // 'bounces',
        // 'avg_industry_duration',
    ],
    isShowTotals: true,
    availableTools: {
        numberOfGraphs: true,
        periodPicker: true,
        sample: true,
        activeSample: true,
        filters: true,
        savedFilters: true,
        detail: true,
    },
    reportHint: {
        message: 'Группировка по параметрам, указанным в адресах страниц. '
            + 'Параметром считается все, что идет в URL-е сайта после «/?» (за исключением хэшей).',
    },
};

export default reportConfig;
