import { Chart } from '@adtech/charts';
import { Tooltip, Loader } from '@adtech/ui';
import Dropdown from '@components/Dropdown';

import { conversionActionsConfig, EConversionTitles } from '@configs/conversions';
import { EntityAccess } from '@configs/entity';
import { ChartColorsMin, ChartTypes, ChartFormatterValues } from '@configs/graph';
import { GroupBy } from '@configs/group';
import { metricsDict } from '@configs/metrics';

import IconSettings from '@images/svg/icons/icon-settings.svg';
import IconStar from '@images/svg/icons/icon-star.svg';

import { DateType } from '@typings/date';
import { TChartDataItem } from '@typings/graph';
import { Metrics } from '@typings/metrics';
import { dateUtils, graphUtils } from '@utils/index';
import NumbersUtils from '@utils/numbersUtils';

import cn from 'classnames';
import React from 'react';
import { Link } from 'react-router-dom';

import s from './Conversion.pcss';

interface IProps {
    id: number;
    title: string;
    description: string;
    graphData: TChartDataItem[];
    totals: Record<string, number | string>[];
    dateStart: DateType;
    dateEnd: DateType;
    groupBy: GroupBy;
    isFavorites?: boolean;
    useFavorites?: boolean;
    loading?: boolean;
    metric?: Metrics;
    access?: EntityAccess;
    ownerEmail?: string;
    goalsIds?: (number | null)[];
    onClickEdit?: (id: number) => void;
    onClickFavorites?: () => void;
    api?: TAPI;
}

const Conversion: React.FC<IProps> = ({
    id,
    title,
    description,
    isFavorites = false,
    graphData = [],
    totals = [],
    dateStart,
    dateEnd,
    groupBy,
    useFavorites = true,
    loading = false,
    metric,
    access,
    ownerEmail,
    goalsIds,
    onClickEdit = () => {},
    onClickFavorites,
    api,
}) => {
    // Не передаем определённую ширину, чтобы график был по ширине контейнера
    const graphSizes = [null, 120];
    const period = dateUtils.transformAnyDateToISO([dateStart, dateEnd]);
    const data = graphUtils.prepareChartData(graphData, period, groupBy);
    const isDemoProject = api?.isDemoProject() ?? false;

    const renderTotals = (index) => {
        const value = NumbersUtils.formatValue(
            totals[index]?.value || 0,
            String(totals[index]?.format),
            Number(totals[index]?.value) < 1 ? 6 : 2,
        );

        return (
            <div
                key={totals[index]?.title}
                className={s.infoLegendItem}
                data-testid={index ? 'target-container' : 'conversion-container'}
            >
                <div className={s.infoLegendValue} data-testid="value-text">
                    <Tooltip title={value}>{value}</Tooltip>
                </div>
                <div className={s.infoLegendTitle} data-testid="meaning-text">
                    <div className={s.infoLegendTitleColor} style={{ backgroundColor: ChartColorsMin[index] }} />
                    {totals[index]?.title}
                </div>
            </div>
        );
    };

    return (
        <div className={s.root}>
            <Loader
                loading={loading}
                // blurContent // FIXME: после обновления @adtech-ui
            >
                <div className={s.conversion} key={id} data-testid="card-container">
                    <div className={s.header}>
                        <div className={s.title}>
                            {useFavorites && (
                                <IconStar
                                    data-testid="star-icon"
                                    className={cn(s.titleIcon, { [s.titleIconActive]: isFavorites })}
                                    onClick={onClickFavorites}
                                />
                            )}
                            {api ? (
                                <Link
                                    data-testid="title-text"
                                    className={s.titleLink}
                                    to={api?.getLinkToConversion(id)}
                                >
                                    {title}
                                </Link>
                            ) : (
                                <span className={s.titleText}>{title}</span>
                            )}
                        </div>
                        {!isDemoProject && (
                            <div className={s.settings}>
                                <Dropdown
                                    dataTestId="dropdown-container"
                                    type="listCustom"
                                    isShowArrow={false}
                                    anchorPrefixIcon={<IconSettings data-testid="setting-icon" />}
                                    anchorClassName={s.settingsAnchor}
                                    anchorSize="small"
                                    data={
                                        api?.getConversionActionsList(
                                            conversionActionsConfig,
                                            id,
                                            access,
                                            ownerEmail,
                                            goalsIds,
                                            onClickEdit,
                                        ) ?? null
                                    }
                                />
                            </div>
                        )}
                    </div>
                    <div className={s.infoWrapper}>
                        <div className={s.infoEmail} data-testid="owner-container">
                            <span>Владелец:</span> {ownerEmail}
                        </div>
                        <div className={s.infoAccess} data-testid="access-container">
                            <span>Тип доступа:</span> {access === EntityAccess.common ? 'Публичный' : 'Личный'}
                        </div>
                    </div>
                    <div className={s.body}>
                        <div className={s.bodyInfo}>
                            <div className={s.infoDescription} data-testid="description-text">
                                {description}
                            </div>
                            <div className={s.infoLegend}>{totals.map((item, i) => renderTotals(i))}</div>
                        </div>
                        <div className={s.bodyGraph} data-testid="graph-container">
                            <Chart
                                period={period}
                                type={ChartTypes.biaxialLine}
                                data={data}
                                dataKey="date"
                                leftYAxisSettings={{
                                    keys: [EConversionTitles.conversion],
                                    format: ChartFormatterValues.percent,
                                }}
                                rightYAxisSettings={{
                                    keys: [EConversionTitles.goal],
                                }}
                                colors={ChartColorsMin}
                                sizes={graphSizes}
                                group={groupBy}
                                metricsName={metricsDict[metric]?.title || ''}
                                tooltipLargeData
                                data-testid="graph-card"
                            />
                        </div>
                    </div>
                </div>
            </Loader>
        </div>
    );
};

export default Conversion;
