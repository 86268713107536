/* eslint-disable max-len */
export const projectsStatus = {
    DEFAULT: 'default',
    INCLUDED: 'included',
    MODERATION: 'moderation',
    EDITING: 'editing',
    EXCLUDED: 'excluded',
    UNINCLUDED: 'unincluded',
    NEED_CHANGE: 'need_change',
    DELETED: 'deleted',
    DROPPED_OUT: 'dropped_out',
};

export const baseCategory = {
    name: 'Все темы',
    id: '0',
    themeId: '0',
};

export const projectsTableConstants = {
    TABLE_LIMIT_ROW: 10,
};

export const projectsTextStatus = {
    [projectsStatus.UNINCLUDED]: 'Не участвует в рейтинге',
    [projectsStatus.MODERATION]: 'На модерации',
    [projectsStatus.EDITING]: 'На редакции',
    [projectsStatus.INCLUDED]: 'Участвует в рейтинге',
    [projectsStatus.NEED_CHANGE]: 'На редакции',

    [projectsStatus.EXCLUDED]: 'Исключен из рейтинга',
    [projectsStatus.DROPPED_OUT]: 'Удалён из рейтинга',
    [projectsStatus.DELETED]: 'Счётчик удалён',
};

export const ratingTitleStatus = {
    [projectsStatus.UNINCLUDED]: 'Сайт не участвует в рейтинге',
    [projectsStatus.MODERATION]: 'Сайт на модерации',
    [projectsStatus.EDITING]: 'На редакции',
    [projectsStatus.INCLUDED]: 'Участвует в рейтинге',
    [projectsStatus.NEED_CHANGE]: 'На редакции',

    [projectsStatus.EXCLUDED]: 'Сайт исключен из рейтинга',
    [projectsStatus.DROPPED_OUT]: 'Сайт удалён из рейтинга',
    [projectsStatus.DELETED]: 'Счётчик удалён',
};

// prettier-ignore
export const ratingTextStatus = {
    [projectsStatus.DEFAULT]: 'Сайт с таким URL уже есть в каталоге Топ-100.Если вы – владелец сайта, пожалуйста, обратитесь в',
    [projectsStatus.UNINCLUDED]: 'Участие в рейтинге Топ-100 позволяет сравнивать посещаемость и другие показатели вашего сайта с конкурентами.',
    [projectsStatus.MODERATION]: 'После подтверждения участия в рейтинге модераторами сайт появится в каталоге Топ-100.',
    [projectsStatus.EDITING]: 'После проверки редакторами Топ-100 метаданные могут быть изменены.',
    [projectsStatus.INCLUDED]: 'Вы можете изменить метаданные. После подтверждения редакторами Топ-100 изменения станут видны посетителям каталога.',
    [projectsStatus.NEED_CHANGE]: 'После проверки редакторами Топ-100 метаданные могут быть изменены.',

    [projectsStatus.EXCLUDED]: 'Сайт удалён из рейтинга администрацией Топ-100. Вы можете задать вопрос через ',
    [projectsStatus.DROPPED_OUT]: 'Сайт удалён из рейтинга за отсутствие посещаемости. Пожалуйста, убедитесь, что счётчик Топ-100 установлен на все страницы сайта.',
};
