import { ChartTypes } from '@configs/graph';
import { sortOrders } from '@constants/sort';
import { IReportConfig } from '@typings/reports';

const reportConfig: IReportConfig = {
    reportTitle: 'Доскроллы по источникам',
    tableDataType: 'static',
    tableTitle: 'URL, Источник трафика, Источник детально, Процент доскроллов',
    defaultSort: {
        name: 'page_views',
        order: sortOrders.DESC,
    },
    numberOfGraphs: 'two',
    defaultGraphs: [
        ChartTypes.line,
        ChartTypes.bar,
    ],
    graphDefaultMetrics: [
        'page_views',
        'visits',
    ],
    disabledGraphs: {
        pie: true,
    },
    tableDefaultMetrics: [
        'page_views',
        'visitors',
        'visits',
    ],
    titleAliases: {
        0: '0% - 10%',
        10: '10% - 20%',
        20: '20% - 30%',
        30: '30% - 40%',
        40: '40% - 50%',
        50: '50% - 60%',
        60: '60% - 70%',
        70: '70% - 80%',
        80: '80% - 90%',
        90: '90% - 100%',
        straight: 'Прямые заходы',
        site: 'По ссылкам на сайтах',
        social: 'Социальные сети',
        email: 'Почтовые рассылки',
        search: 'Поисковые системы',
        internal: 'Внутренние переходы',
        adv: 'Рекламные системы',
    },
    availableMetrics: [
        'visits',
        'avg_view_depth',
        'page_views',
        'visitors',
        'uid',
        'rambler_id',
    ],
    isShowTotals: true,
    availableTools: {
        numberOfGraphs: true,
        periodPicker: true,
        sample: true,
        activeSample: true,
        filters: true,
        savedFilters: true,
        detail: true,
    },
};

export default reportConfig;
