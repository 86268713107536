import { Tabs } from '@adtech/ui';
import React from 'react';

import s from './Dropdown.pcss';

interface IProps {
    onClose?: () => void;
    onSetTab?: (...rest: any) => void | Promise<void>;
    tabs: {
        key: string;
        label: string;
        search?: boolean;
        initial?: boolean;
        dataAttrName: string;
        dataAttr: string;
    }[];
    currentTab?: string;
    searchValue?: string;
}

let isCallWillMount = false;

export default class MyTabs extends React.PureComponent<IProps> {
    componentDidMount() {
        if (isCallWillMount) {
            return;
        }

        this.setInitialTab();

        isCallWillMount = true;
    }

    componentDidUpdate(prevProps: IProps) {
        if (prevProps.searchValue !== this.props.searchValue) {
            this.setSearchTab();
        }
    }

    componentWillUnmount() {
        isCallWillMount = false;
    }

    setSearchTab = () => {
        const { tabs, onSetTab, currentTab } = this.props;

        const findSearch = tabs.find((tab: Record<string, any>) => tab.search);
        if (findSearch && findSearch.key !== currentTab) {
            onSetTab(findSearch.key, [], { withResetSearchValue: true });
        }
    };

    handleSetTab = (value: string, ...rest) => {
        const { onSetTab } = this.props;

        onSetTab(value, rest);
    };

    setInitialTab = () => {
        const { tabs, onSetTab, currentTab } = this.props;

        const findInitital = tabs.find((tab: Record<string, any>) => tab.initial);
        if (!currentTab && findInitital) {
            onSetTab(findInitital.key);
        }
    };

    getTabs = (
        item: {
            key: string;
            label: string;
            dataAttrName?: string;
            dataAttr?: string;
        },
        key: number,
    ) => ({
        value: item.key,
        label: (
            <div
                key={key}
                className={s.tabsItemWrapper}
                {...(item.dataAttrName && item.dataAttr
                    ? { [`data-${item.dataAttrName}`]: `${item.dataAttr}::${item.key}` }
                    : {})}
            >
                {item.label}
            </div>
        ),
    });

    render() {
        const { tabs, currentTab } = this.props;

        return (
            <div className={s.tabs}>
                <Tabs value={currentTab || 'all'} options={tabs.map(this.getTabs)} onChange={this.handleSetTab} />
            </div>
        );
    }
}
