import { ImageCounter } from '@images/svg/counter';
import React from 'react';

import s from '../ModalPopup.pcss';
import { PopupContent } from '../components/PopupContent';
import { PopupHeader } from '../components/PopupHeader';

export default {
    getContent() {
        const popupHeader = <PopupHeader title="Восстановление доступа к счётчику" image={<ImageCounter />} />;

        const popupContent = (
            <PopupContent
                className={s.contentSuccess}
                content="Мы отправили файл на вашу почту.
                    Разместите этот файл на своем сайте в течение 7 дней.
                    После проверки вы получите доступ к существующему счётчику."
            />
        );

        const okButton = {
            className: s.btnOK,
            type: 'default',
            children: 'Ок',
            closePopupOnClick: true,
        };

        const cancelButton = null;

        return {
            popupClassName: s.restoreAccessCounter,
            title: popupHeader,
            content: popupContent,
            buttonContainerClassName: s.buttons,
            okButton,
            cancelButton,
        };
    },
};
