import { globalActions } from '@redux/slices/global';
import { useDispatch } from 'react-redux';

const useModalPopup = () => {
    const dispatch = useDispatch();

    const openModalPopup = (modalPopupId: string, modalPopupParams?: Record<string, unknown>): void => {
        dispatch(globalActions.openModalPopup({ modalPopupId, modalPopupParams }));
    };

    const closeModalPopup = (): void => {
        dispatch(globalActions.clearModalPopup());
    };

    return {
        openModalPopup,
        closeModalPopup,
    };
};

export default useModalPopup;
