import { Op } from '@constants/listData';
import snackbarsConstants from '@constants/snackbars';
import useSnackbar from '@hooks/useSnackbar';
import { useAppDispatch } from '@hooks/useStore';
import { formsActions } from '@redux/slices/forms';
import { globalActions } from '@redux/slices/global';
import { IGlobalFilterItem, ISaveFilterRequestParams } from '@typings/filters';
import { IRootSlice } from '@typings/rootSlice';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';

const { UPDATE_FILTER, SAVE_FILTER, DELETE_FILTER } = snackbarsConstants.filtersMessages;

export enum EFilterTemplateActions {
    delete = 'delete',
    save = 'save',
    create = 'create',
    edit = 'edit',
}

const useSavedFilters = () => {
    const params: IUrlParams = useParams();
    const { projectId } = params;
    const dispatch = useAppDispatch();
    const savedFiltersData = useSelector((state: IRootSlice) => state.globalSlice.savedFiltersData);
    const globalFilters = useSelector((state: IRootSlice) => state.globalSlice.globalFilters);
    const { setSnackbar } = useSnackbar();

    const api = {
        // Обновить значения в форме
        updateValuesInForm(selectedData): void {
            dispatch(
                formsActions.initializeValues({
                    formName: 'reportFilters',
                    values: { ...selectedData },
                    isForceUpdate: true,
                }),
            );
        },

        // Запросить список шаблонов фильтров
        getSavedFilters(): void {
            dispatch(globalActions.getSavedFilters(projectId));
        },

        // Удаление шаблона фильтров
        deleteSavedFilter(id: number): void {
            dispatch(globalActions.deleteSavedFilter({ projectId, id }))
                .unwrap()
                .then((res) => {
                    if (!res || res.responseStatus !== 200) return;
                    setSnackbar(DELETE_FILTER);
                });
        },

        // Создать новый шаблон фильтров
        createSavedFilter(title, type): void {
            const filtersRequest = globalFilters.filtersRequest;
            const filterParams = api.getParamsForRequest(title, type, filtersRequest);

            dispatch(globalActions.saveFilter({ projectId, filterParams }))
                .unwrap()
                .then((res) => {
                    if (!res || res.responseStatus !== 200) return;
                    setSnackbar(SAVE_FILTER);
                });
        },

        // Сохранить/редактировать шаблон фильтров
        saveSavedFilter(actionName, data): void {
            const { id } = data || {};
            const initialData = savedFiltersData.find((item) => item.id === id);

            // При редактировании шаблона: перезаписываем только то, что пришло из формы редактирования (заголовок и тип),
            // список фильтров шаблона при редактировании не меняется
            // При сохранении шаблона: перезаписываем список фильтров шаблона
            const title = data?.title || initialData.title;
            const type = data?.type || initialData.type;

            const filtersRequest =
                actionName === EFilterTemplateActions.edit ? initialData.filtersRequest : globalFilters.filtersRequest;
            const filterParams = api.getParamsForRequest(title, type, filtersRequest);

            dispatch(globalActions.updateSavedFilter({ projectId, id, filterParams }))
                .unwrap()
                .then((res) => {
                    if (!res || res.responseStatus !== 200) return;
                    setSnackbar(UPDATE_FILTER);
                });
        },

        applyAction(actionName, data): void {
            switch (actionName) {
                case EFilterTemplateActions.save:
                case EFilterTemplateActions.edit:
                    api.saveSavedFilter(actionName, data);
                    break;
                case EFilterTemplateActions.create:
                    api.createSavedFilter(data.title, data.type);
                    break;
                case EFilterTemplateActions.delete:
                    api.deleteSavedFilter(data.id);
                    break;
                default:
                    break;
            }
        },

        getParamsForRequest(
            title: string,
            type: string,
            filtersRequest?: IGlobalFilterItem[],
        ): ISaveFilterRequestParams {
            const metricFilters = [];
            const dimensionFilters = [];

            filtersRequest.forEach((filter) => {
                let formatOp = filter.op;

                if (filter.op === Op.range) {
                    formatOp = 'range';
                }
                if (filter.op === 'multilike' || filter.op === '=') {
                    formatOp = 'include';
                }

                const filterElem = {
                    key: filter.key,
                    value: filter.value,
                    op: filter.condition || formatOp,
                };

                if (filter.isMetricFilter) {
                    metricFilters.push(filterElem);
                } else {
                    dimensionFilters.push(filterElem);
                }
            });

            return {
                title,
                type,
                body: {
                    split: null,
                    ...(metricFilters.length ? { metric_filters: metricFilters } : {}),
                    ...(dimensionFilters.length ? { dimension_filters: dimensionFilters } : {}),
                },
            };
        },
    };

    return api;
};

export default useSavedFilters;
