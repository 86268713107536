import { Button, Accordion, AccordionItem, IconChevronUp, ContextAlertMark } from '@adtech/ui';
import CounterBlocks from '@components/CounterBlocks';
import Form from '@components/Form';
import attributesData from '@configs/attributesData';
import notifications from '@constants/notifications';
import useDesignForTD from '@hooks/useDesignForTD';
import { INotification } from '@typings/notification';
import { ICurrentProject, IValues } from '@typings/projects';
import { formUtils } from '@utils/index';
import React from 'react';
import { Link } from 'react-router-dom';

import s from '../CreateCounter.pcss';

interface IProps {
    onSubmit;
    config: Record<string, any>;
    createdCounter: {
        can_be_in_rate: boolean;
        project_id: string;
    };
    currentProject: ICurrentProject;
    invalid: boolean;
    values: IValues;
    serverError: string;
    saveCounter: () => void;
    setServerError: (serverError: string) => void;
    setFieldErrors: (errors: Record<string, any>) => void;
    openModalPopup: () => void;
    saveCounterRequest: boolean;
    setSnackbar: (message: any, type: string) => void;
    setNotification: (notification: INotification) => void;
    clearNotification: () => void;
    forms: any;
}

const SecondStep: React.FC<IProps> = (props) => {
    const {
        config,
        invalid,
        values,
        currentProject,
        createdCounter,
        serverError,
        saveCounter,
        setServerError,
        setFieldErrors,
        openModalPopup,
        saveCounterRequest,
        setSnackbar,
        onSubmit,
        setNotification,
        clearNotification,
        forms,
    } = props;

    const isFromTD = useDesignForTD();

    const invalidFields =
        invalid && forms.counterSecondStep.fields ? formUtils.getInvalidFields(forms.counterSecondStep.fields) : {};

    const showPopupNotification = () => {
        setNotification(notifications.settingsCounter);
    };

    const handleChangeAccordion = (key) => {
        if (key) {
            showPopupNotification();
        } else {
            clearNotification();
        }
    };

    const saveSettingsCounter = () => {
        onSubmit(values, invalid, saveCounter, setFieldErrors, setServerError, createdCounter, config, openModalPopup);
    };

    return (
        <div className={s.secondStep}>
            <Form onSubmit={saveSettingsCounter}>
                {!isFromTD && (
                    <CounterBlocks.Rating
                        config={config}
                        inRating={values.inRating || false}
                        canRating={createdCounter.can_be_in_rate}
                        status={currentProject.status}
                        rootClassName={s.secondStepRoot}
                        smallTitle
                        dataAttrName={attributesData.createCounter.attrName}
                        dataAttr={[
                            attributesData.createCounter.secondStep,
                            attributesData.createCounter.ratingSwitcher,
                        ]}
                    />
                )}
                <Accordion
                    expandIconPosition="end"
                    expandIcon={(panelProps) => <IconChevronUp isActive={panelProps.isActive} fill="currentColor" />}
                    defaultActiveKey={['settings_counter', 'info_counter', 'code_counter']}
                    onChange={handleChangeAccordion}
                    className={s.secondStepSettings}
                >
                    <AccordionItem key="settings_counter" header="Настройки счётчика">
                        <>
                            <CounterBlocks.SettingsCounter counterType={values.counterType || false} />
                            <CounterBlocks.SettingsDomain useDomainBlacklist={values.useDomainBlacklist} />
                            <CounterBlocks.SettingsOther />
                        </>
                    </AccordionItem>
                </Accordion>
                <CounterBlocks.CounterCode
                    wrapperWithoutDelimiter
                    id={createdCounter.project_id}
                    counterType={Number(values.counterType)}
                    blockAnalyticsAttribute={values.blockAnalyticsAttribute}
                    useBlockAnalytics={values.useBlockAnalytics}
                    useHashes={values.useHashes}
                    useUserId={values.useUserId}
                    setSnackbar={setSnackbar}
                    subWrapCounter
                    mode="create"
                />
                <div className={s.contentBlock}>
                    <div className={s.row}>
                        <div className={s.create}>
                            <Button
                                loading={saveCounterRequest}
                                disabled={invalid}
                                htmlType="submit"
                                data-create-counter={
                                    `${attributesData.createCounter.secondStep}` +
                                    `::${attributesData.createCounter.saveSettings}`
                                }
                            >
                                Сохранить настройки
                            </Button>
                            {isFromTD && (
                                <Link to={`/stat/projects/${createdCounter.project_id}/goals`}>
                                    <Button type="dashed" className={s.linkCreateButton}>
                                        Настроить конверсии
                                    </Button>
                                </Link>
                            )}
                            {invalid && (
                                <div className={s.invalidLabel}>
                                    <span>Необходимо правильно заполнить поля: </span>
                                    {Object.keys(invalidFields).map((key, index, array) => (
                                        <span className={s.invalidField} key={key}>
                                            {invalidFields[key].label}
                                            {array.length - 1 !== index && ', '}
                                        </span>
                                    ))}
                                </div>
                            )}
                        </div>
                        {serverError && <ContextAlertMark status="error" message={serverError} />}
                    </div>
                </div>
            </Form>
        </div>
    );
};

export default SecondStep;
