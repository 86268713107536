import { Button, Tabs, Select, Checkbox, Input } from '@adtech/ui';
import DataList, { IDataListItem } from '@components/DataList';
import DraggableList, { IDraggableListItem, OverType } from '@components/DraggableList';
import { Hint } from '@components/Hint';
import dimensionsList from '@configs/constructor/dimensions';
import metricsList from '@configs/constructor/metrics';
import { dimensionsDict } from '@configs/dimensions';
import { metricsDict } from '@configs/metrics';
import { ViewType } from '@constants/viewType';
import { useGraph } from '@hooks/useGraph';
import { useRestrictAccessByEmail } from '@hooks/useRestrictAccessByEmail';
import { useTable } from '@hooks/useTable';
import IconArrowRight from '@images/svg/icons/icon-arrow-right.svg';
import IconCloseThin from '@images/svg/icons/icon-close-thin.svg';
import IconGraph from '@images/svg/icons/icon-graph.svg';
import IconTable from '@images/svg/icons/icon-table.svg';
import IconToggleSettings from '@images/svg/icons/icon-toggle-settings.svg';
import IconTrash from '@images/svg/icons/icon-trash.svg';
import { Dimensions } from '@typings/dimensions';
import { Metrics } from '@typings/metrics';
import cn from 'classnames';
import React, { ChangeEvent, SyntheticEvent, useEffect, useRef, useState } from 'react';

import s from './ConstructorSettings.pcss';

interface IUpdateParams {
    metrics?: Metrics[];
    dimensions?: Dimensions[];
    offset?: number;
    limit?: number;
}

interface IProps {
    metrics: Metrics[];
    dimensions: Dimensions[];
    updateConstructor: (type: string, newParams: IUpdateParams, withoutRequest?: boolean) => void;
    viewType: ViewType;
    limit?: number;
    offset?: number;
}

export const ConstructorSettings: React.FC<IProps> = (props) => {
    const { metrics, dimensions, updateConstructor, viewType, limit = 10, offset = 0 } = props;

    const DIMENSIONS_LIMIT = 10;
    const [dataType, setDataType] = useState(viewType);
    const [limitValue, setLimitValue] = useState(limit);
    const [offsetValue, setOffsetValue] = useState(String(offset + 1));
    const [dimensionsLimit, setDimensionsLimit] = useState(DIMENSIONS_LIMIT - dimensions.length);
    const [isOpenedSettings, setIsOpenedSettings] = useState<boolean>(true);
    const limitList = [10, 25, 50, 100]; // ошибка на бэке при > 100
    const limitData = limitList.map((item) => ({ value: item, label: String(item) }));
    const dimensionsListTransformed = useRef<IDataListItem>(null);
    const timer = useRef(null);

    const { isAccessRestricted, constructorDimensions } = useRestrictAccessByEmail();

    const {
        api: { updateTableParams, resetTable },
    } = useTable();
    const {
        api: { resetGraph },
    } = useGraph();

    const rootClassNames = cn(s.root, {
        [s.opened]: isOpenedSettings,
    });

    useEffect(
        () => () => {
            resetTable();
            resetGraph();
        },
        [],
    );

    // Запрос к ручке конструктора с задержкой (для оптимизации запросов)
    const updateConstructorWithDelay = (type, params, delay) => {
        updateTableParams(params);

        if (timer.current) clearTimeout(timer.current);

        timer.current = setTimeout(() => updateConstructor(type, params), delay);
    };

    const addItem = (e, measure?: Metrics | Dimensions, measureType?: string) => {
        const item = measure || e.target.closest('[data-item]').dataset.item;
        const type = measureType || e.target.closest('[data-item]').dataset.type;

        if (type === 'dimensions') {
            if (dimensionsLimit < 1) return;
            setDimensionsLimit(dimensionsLimit - 1);
        }

        const newParams = {
            metrics,
            dimensions,
        };

        if (dataType === ViewType.graph) {
            newParams[type] = [item];
        } else {
            newParams[type] = [...newParams[type], item];
        }

        updateConstructorWithDelay(dataType, newParams, 1000);
    };

    const removeItem = (e, measure?: Metrics | Dimensions, measureType?: string) => {
        const item = measure || e.target.closest('[data-item]').dataset.item;
        const type = measureType || e.target.closest('[data-item]').dataset.type;

        const newParams = {
            metrics,
            dimensions,
        };

        if (!newParams[type]) return;
        newParams[type] = newParams[type].filter((param) => param !== item);

        if (type === 'dimensions') setDimensionsLimit(dimensionsLimit + 1);

        updateConstructorWithDelay(dataType, newParams, 1000);
    };

    const onCheckCheckboxHandler = (e) => {
        const measure = e.target.name;
        const checked = e?.target?.checked;
        const type = metricsDict[measure] ? 'metrics' : 'dimensions';

        if (checked) {
            addItem(e, measure, type);
        } else {
            removeItem(e, measure, type);
        }
    };

    const onChangeTabs = (type: ViewType) => {
        let newParams = {};
        if (type === ViewType.graph) {
            newParams = {
                metrics: metrics.length ? [metrics[0]] : [],
                dimensions: dimensions.length ? [dimensions[0]] : [],
            };
            setLimitValue(10);
        }
        setDataType(type);
        updateConstructor(type, newParams);
    };

    const renderMeasureList = (item: IDataListItem) => {
        const { name, title } = item;
        const measures = [...metrics, ...dimensions];
        const checked = measures.includes(name as Metrics | Dimensions);

        return (
            <div className={s.measuresItem} key={name}>
                <Checkbox name={name} checked={checked} onChange={onCheckCheckboxHandler}>
                    {title}
                </Checkbox>
            </div>
        );
    };

    const renderDraggableListItem = (item: IDraggableListItem) => {
        const { id } = item;
        const type = id in metricsDict ? 'metrics' : 'dimensions';
        const measure = type === 'metrics' ? metricsDict[item.id] : dimensionsDict[item.id];
        const title = measure?.parentTitle ? `${measure?.parentTitle}: ${measure.title}` : measure.title;

        return (
            <>
                <span className={s.measuresItemTitle}>{title}</span>
                <span onClick={removeItem} className={s.clearIcon} data-item={id} data-type={type}>
                    <IconCloseThin />
                </span>
            </>
        );
    };

    const renderRequestedMeasures = (list: Metrics[] | Dimensions[], type: string) => {
        const onDropMetricsHandler = (e: SyntheticEvent, item: IDraggableListItem, metricsArr: Metrics[]) => {
            updateConstructor(dataType, { metrics: metricsArr });
        };
        const onDropDimensionsHandler = (e: SyntheticEvent, item: IDraggableListItem, dimensionsArr: Dimensions[]) => {
            updateConstructor(dataType, { dimensions: dimensionsArr });
        };

        const onDropHandler = type === 'metrics' ? onDropMetricsHandler : onDropDimensionsHandler;

        return (
            <DraggableList
                list={list}
                stylesInstance={s}
                listItemClassName="measuresItem"
                renderItemHandler={renderDraggableListItem}
                onDrop={onDropHandler}
                overType={OverType.border}
            />
        );
    };

    const renderMeasuresNoSelected = <span className={s.measuresItemNoSelected}>Не выбрано</span>;

    const onChangeInputHandler = (event: ChangeEvent<HTMLInputElement>) => {
        const value = event.target.value;
        if (!value) {
            setOffsetValue(value);
            return;
        }

        if (!/^\d*$/.test(value) || value === '0') return;

        setOffsetValue(value);

        updateConstructorWithDelay(dataType, { offset: Number(value) - 1 }, 1500);
    };

    const onChangeSelectHandler = (value: number) => {
        setLimitValue(value);
        updateConstructor(dataType, { limit: value });
    };

    const resetMeasures = (measureType?: string) => {
        updateConstructor(dataType, { [measureType]: [] });
    };

    const toggleSettings = () => {
        setIsOpenedSettings((prev) => !prev);
    };

    // Удаляем из конфига дименшинов дименшины,
    // чтобы не выводить данные по отчетам, доступ к которым ограничен по email юзера
    const transformDimensionsList = (): IDataListItem => {
        if (dimensionsListTransformed.current) return dimensionsListTransformed.current;

        dimensionsListTransformed.current = JSON.parse(JSON.stringify(dimensionsList));
        let indexes = [];
        let depth = 0;

        const deleteDimensionFromCnf = (children: IDataListItem[], key: string): void => {
            const index = parseInt(key, 10);

            if (depth === indexes.length - 1) {
                children.splice(index, 1);
                return;
            }

            depth += 1;

            deleteDimensionFromCnf(children[index].children, indexes[depth]);
        };

        constructorDimensions.forEach((dimensionPath) => {
            indexes = dimensionPath.split('-');
            deleteDimensionFromCnf(dimensionsListTransformed.current?.children, indexes[0]);
        });

        return dimensionsListTransformed.current;
    };

    const dimensionsListFinal = isAccessRestricted ? transformDimensionsList() : dimensionsList;

    return (
        <div className={rootClassNames}>
            <Button round className={s.toggleButton} onClick={toggleSettings} size="small">
                {isOpenedSettings ? (
                    <IconArrowRight className={s.toggleButtonArrowIcon} />
                ) : (
                    <IconToggleSettings className={s.toggleButtonSettingsIcon} />
                )}
            </Button>
            <div className={s.inner}>
                <div className={s.title}>Настройки</div>
                <div className={s.scroller}>
                    <div className={s.tools}>
                        <div className={s.tabs}>
                            <Tabs
                                value={dataType}
                                type="labeled"
                                onChange={onChangeTabs}
                                options={[
                                    {
                                        value: ViewType.table,
                                        label: (
                                            <>
                                                <IconTable className={s.tabsItemIcon} />
                                                Таблица
                                            </>
                                        ),
                                    },
                                    {
                                        value: ViewType.graph,
                                        label: (
                                            <>
                                                <IconGraph className={s.tabsItemIcon} />
                                                Графики
                                            </>
                                        ),
                                    },
                                ]}
                            />
                        </div>
                        <div className={s.block}>
                            <div className={s.blockTitleWrap}>
                                <div className={s.blockTitle}>Столбцы</div>
                                <div className={s.blockClear} onClick={() => resetMeasures('metrics')}>
                                    <IconTrash />
                                </div>
                            </div>
                            {metrics.length ? renderRequestedMeasures(metrics, 'metrics') : renderMeasuresNoSelected}
                        </div>
                        <div className={s.block}>
                            <div className={s.blockTitleWrap}>
                                <div className={s.blockTitle}>
                                    Строки
                                    <Hint>{`Одновременно можно выбрать не более ${DIMENSIONS_LIMIT} параметров`}</Hint>
                                </div>
                                <div className={s.blockClear} onClick={() => resetMeasures('dimensions')}>
                                    <IconTrash />
                                </div>
                            </div>
                            {dimensions.length
                                ? renderRequestedMeasures(dimensions, 'dimensions')
                                : renderMeasuresNoSelected}
                        </div>
                        {dataType === ViewType.table && (
                            <div className={s.block}>
                                <div className={s.rowSettings}>
                                    <div className={s.rowSettingsItem}>
                                        Начальная строка
                                        <Input type="text" value={offsetValue} onChange={onChangeInputHandler} />
                                    </div>
                                    <div className={s.rowSettingsItem}>
                                        Количество строк
                                        <Select
                                            options={limitData}
                                            value={limitValue}
                                            onChange={onChangeSelectHandler}
                                        />
                                    </div>
                                </div>
                            </div>
                        )}
                    </div>
                    <DataList
                        list={[metricsList, dimensionsListFinal]}
                        isSearchEnabled
                        renderItemHandler={renderMeasureList}
                        classNameList={s.measuresList}
                        classNameListScroller={s.measuresListScroller}
                    />
                </div>
            </div>
        </div>
    );
};

export default ConstructorSettings;
