import ConversionForm from '@components/ConversionForm';
import { conversionFormTypesConfig, ConversionFormType } from '@configs/conversions/conversionForm';
import { IConversionTableData } from '@typings/conversions';
import React from 'react';

import s from '../ModalPopup.pcss';

interface IProps {
    type: ConversionFormType;
    closePopup: () => void;
    api: TAPI;
    data?: IConversionTableData;
    isCalledFromGoalsReport?: boolean; // форму вызвали на отчете Цели
}

export default {
    getContent(props: IProps) {
        const popupContent = (
            <ConversionForm
                type={props.type}
                closePopup={props.closePopup}
                api={props.api}
                data={props.data || {}}
                isCalledFromGoalsReport={props.isCalledFromGoalsReport}
            />
        );

        return {
            popupClassName: s.conversionForm,
            title: conversionFormTypesConfig[props.type].title,
            content: popupContent,
            closeOnClickInsideOnly: true,
        };
    },
};
