import { ProductFooter } from '@adtech/ui';
import { specialHelpLinks, footerMenuItems } from '@configs/footer/footer';
import { LogoSberads } from '@images/svg/logo-sberads';
import { LogoStat } from '@images/svg/logo-stat';
import { ReportName } from '@typings/reports';
import React, { FC } from 'react';
import s from './Footer.pcss';

interface IProps {
    reportName: ReportName;
}

const Footer: FC<IProps> = ({ reportName }: IProps) => {
    const renderLogo = (
        <div className={s.logo}>
            <a href="https://ads.sber.ru/stat/" className={s.logoLink}>
                <LogoStat />
            </a>
            <a href="https://ads.sber.ru/" className={s.logoLink}>
                <LogoSberads />
            </a>
        </div>
    );

    const getMenuItems = () => {
        if (Object.keys(specialHelpLinks).includes(reportName)) {
            return footerMenuItems.map((item) => {
                if (item.id !== 'help') return item;
                return { ...item, link: specialHelpLinks[reportName] };
            });
        }
        return footerMenuItems;
    };

    return (
        <ProductFooter
            renderLogo={renderLogo}
            menuItems={getMenuItems()}
            ageLimit="18+"
            additionalText="Отчёты созданы по московскому времени (GMT+3)"
            withDefaultMenuItems={false}
            className={s.footer}
        />
    );
};

export default Footer;
