import { Button } from '@adtech/ui';
import React from 'react';

import s from './TableShowMoreRow.pcss';

interface IProps {
    limit: number;
    tableRowsTotal: number;
    viewedTableRows: number;
    getProjectsTableOffset: (offset: number) => void;
}

const TableShowMoreRow: React.FC<IProps> = ({ limit, tableRowsTotal, viewedTableRows, getProjectsTableOffset }) => {
    const diff = tableRowsTotal - viewedTableRows;
    const text = `Показать еще ${diff >= limit ? limit : diff}`;

    const handleClick = () => {
        getProjectsTableOffset(viewedTableRows);
    };

    return (
        <div className={s.tableShowMoreRow}>
            <Button size="small" type="dashed" onClick={handleClick} className={s.buttonRating}>
                {text}
            </Button>
            <div className={s.tableShowMoreRowMessage}>
                Показано <span>{viewedTableRows}</span> из <span>{tableRowsTotal}</span>
            </div>
        </div>
    );
};

export default TableShowMoreRow;
