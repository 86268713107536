import { Tooltip, IconArrowDown } from '@adtech/ui';
import { OrdersType, sortOrders, sortDisabledMessage } from '@constants/sort';
import cn from 'classnames';
import React from 'react';

import s from './OrderIcon.pcss';

type Orders = Record<OrdersType, boolean>;

interface IProps {
    orders: Orders;
    allowedSortBy?: string[];
    onClick;
    className?: string;
}

const areEqual = (prevProps, nextProps) => {
    const { asc, desc } = nextProps.orders;
    const { asc: prevAsc, desc: prevDesc } = prevProps.orders;

    const { onClick } = nextProps;
    const { onClick: prevOnClick } = prevProps;

    return prevAsc === asc && prevDesc === desc && onClick === prevOnClick;
};

const OrderIcon: React.FC<IProps> = ({ onClick, allowedSortBy = null, className = '', orders: { asc, desc } }) => {
    const isAsc = !allowedSortBy || allowedSortBy.includes(sortOrders.ASC);
    const isDesc = !allowedSortBy || allowedSortBy.includes(sortOrders.DESC);

    const toggleActiveIcon = (curSort: string, ordersState: Orders) => {
        if ((curSort === sortOrders.ASC && !isAsc) || (curSort === sortOrders.DESC && !isDesc)) {
            return;
        }

        if ((curSort === sortOrders.ASC && asc) || (curSort === sortOrders.DESC && desc)) return;

        const order = Object.keys(ordersState).filter((key) => ordersState[key] === true)[0];

        onClick(order);
    };

    const iconUp = cn(s.iconUp, {
        [s.iconActive]: asc,
        [s.disabled]: !isAsc,
    });
    const iconDown = cn(s.iconDown, {
        [s.iconActive]: desc,
        [s.disabled]: !isDesc,
    });

    const sortAsc = () => (
        <span
            className={iconUp}
            onClick={() =>
                toggleActiveIcon(sortOrders.ASC, {
                    asc: !asc,
                    desc: asc,
                })
            }
        >
            <IconArrowDown />
        </span>
    );

    const sortDesc = () => (
        <span
            className={iconDown}
            onClick={() =>
                toggleActiveIcon(sortOrders.DESC, {
                    asc: desc,
                    desc: !desc,
                })
            }
        >
            <IconArrowDown />
        </span>
    );

    return (
        <div className={cn(s.icon, className)}>
            {!isAsc ? <Tooltip title={sortDisabledMessage.ASC}>{sortAsc()}</Tooltip> : sortAsc()}

            {!isDesc ? <Tooltip title={sortDisabledMessage.DESC}>{sortDesc()}</Tooltip> : sortDesc()}
        </div>
    );
};

export default React.memo(OrderIcon, areEqual);
