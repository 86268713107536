import { ChipButton, IconCross } from '@adtech/ui';
import React from 'react';

import s from '../ListData.pcss';

interface IProps {
    map: Record<string, any>;
    removeSelectedItem: (values?: number[]) => void;
    selectedValues: number[];
}

export default class List extends React.Component<IProps> {
    renderItem = (id: number, key: number) => {
        const { map } = this.props;
        const currentItem = map[id];

        const title = [];

        const pushParentTitles = (item: Record<string, any>) => {
            if (item.parent_id) {
                const parentItem = map[item.parent_id];
                if (parentItem) title.push(parentItem.title);

                pushParentTitles(parentItem);
            }
        };

        pushParentTitles(currentItem);

        title.reverse();
        title.push(currentItem.title);

        return (
            <ChipButton
                key={key}
                className={s.tagsListItem}
                fixedWidth
                icon={<IconCross className={s.removeIcon} />}
                data-id={id}
                checked={false}
            >
                {`${title.join(' / ')}`}
            </ChipButton>
        );
    };

    removeRemainingElements = (id: string) => {
        const { selectedValues, removeSelectedItem } = this.props;
        removeSelectedItem(selectedValues.filter((elem) => String(elem) !== id));
    };

    deleteElement = (e: React.MouseEvent<HTMLDivElement>) => {
        const target = e.target as Element;
        if (target.closest('svg')) {
            const span = target.closest('span');
            const id = span?.dataset?.id;
            if (id) {
                this.removeRemainingElements(id);
            }
        }
    };

    render() {
        const { selectedValues } = this.props;

        if (!selectedValues.length) {
            return null;
        }

        return (
            <div className={s.tagsList} onClick={this.deleteElement}>
                {selectedValues.map((id, key) => this.renderItem(id, key))}
            </div>
        );
    }
}
