import Dropdown from '@components/Dropdown';
import attributesData from '@configs/attributesData';
import { EPeriodDetail } from '@constants/periodDetail';
import { IPeriodDetailData } from '@typings/availableTools';
import { ReportName } from '@typings/reports';
import { isFunction } from '@utils/typesChecks';
import React, { useEffect, useRef } from 'react';

// Конфиг можно переопределить через параметр periodDetailConfig
const data = [
    {
        title: 'за 30 минут',
        value: EPeriodDetail.thirtyMinutes,
    },
    {
        title: 'за 1 час',
        value: EPeriodDetail.oneHour,
    },
    {
        title: 'за 2 часа',
        value: EPeriodDetail.twoHours,
    },
    {
        title: 'за 4 часа',
        value: EPeriodDetail.fourHours,
    },
    {
        title: 'с начала текущего дня',
        value: EPeriodDetail.today,
    },
];

interface IProps {
    onChange: (string) => void;
    selected: EPeriodDetail;
    reportName: ReportName;
    periodDetailData?: IPeriodDetailData[];
    disabled?: boolean;
}

const PeriodDetail: React.FC<IProps> = ({ onChange, selected, reportName, periodDetailData, disabled }) => {
    const configValues = useRef<EPeriodDetail[]>([]);
    const config = periodDetailData || data;
    let selectedValue = selected;

    useEffect(() => {
        // Приводим конфиг к массиву значений, чтобы быстрее делать проверки
        configValues.current = config.map((item) => item.value);
    }, [reportName]);

    if (!configValues.current.includes(selectedValue)) {
        selectedValue = config[0].value;
    }

    const reportTools = attributesData.media.tools;
    const dataAttrAnchor = [reportTools.attrName, reportTools.periodDetail];
    const dataAttrList = [reportTools.attrName, reportTools.setPeriodDetail];

    const onChangeHandler = (value: string) => {
        if (isFunction(onChange)) onChange(value);
    };

    return (
        <Dropdown
            type="selectSingle"
            anchorText="Показать: "
            anchorTextOutside
            data={periodDetailData || data}
            dataInitialSelected={selectedValue}
            onApply={onChangeHandler}
            reportName={reportName}
            isDisabled={disabled}
            dataAttrName={attributesData.newreport.attrName}
            dataAttrAnchor={dataAttrAnchor}
            dataAttrList={dataAttrList}
            dataTestId="period-detail"
        />
    );
};

export default PeriodDetail;
