import { Menu } from '@adtech/ui';
import sberAdsMenuConfig from '@configs/sberAdsMenu';
import authTypes from '@constants/authTypes';
import { AuthType } from '@typings/user';
import React from 'react';
import s from './Sidebar.pcss';

interface IProps {
    authType: AuthType;
}

const Sidebar: React.FC<IProps> = ({ authType }) => {
    const isRc = window.location.host.includes('ift');
    const isKeycloak = authType === authTypes.KEYCLOAK;
    let data = sberAdsMenuConfig;

    // Скрываем недоступные пункты меню
    if (!isRc) data = data.filter((item) => !item.onlyRc);
    if (!isKeycloak) data = data.filter((item) => !item.onlyKeyloak);

    const getDataByType = (type: 'top' | 'bottom') =>
        data
            .filter((item) => item.type === type)
            .map((item) => ({
                label: item.title,
                icon: item.icon,
                key: isRc ? item?.rcLink : item?.prodLink,
                className: item.className,
                reloadDocument: !item?.prodLink.startsWith('/'),
            }));

    return (
        <div className={s.root}>
            <div className={s.stickyTop}>
                <Menu items={getDataByType('top')} defaultSelectedKeys={['/stat/']} selectable={false} />
            </div>
            <div className={s.stickyBottom}>
                <Menu items={getDataByType('bottom')} selectable={false} />
            </div>
        </div>
    );
};

export default Sidebar;
