import { createAsyncThunk } from '@reduxjs/toolkit';
import { IReportRequestParams } from '@typings/reports';
import { IDataTable, IPreparedTableData } from '@typings/table';
import fetchApi from '@utils/fetchApi';

/**
 * Экшен получения данных для графиков
 */
export const getReportGraph = createAsyncThunk(
    'graphSlice/getReportGraph',
    async ({
        projectId,
        reportName,
        params,
        tableData,
        meta,
    }: {
        projectId: string;
        reportName: string;
        params: IReportRequestParams;
        tableData: Partial<IDataTable>;
        meta: any;
    }) =>
        // eslint-disable-next-line no-return-await
        await fetchApi({
            url: `/api/report/v1.0/report/${projectId}/${reportName}/graph`,
            method: 'POST',
            body: params,
            metaInfo: {
                ...meta,
                lineIds: params.selected_lines,
                tableData,
            },
        }),
);

export const getMediaGraph = createAsyncThunk(
    'graphSlice/getMediaGraph',
    async ({
        projectId,
        apiExtraPath = '',
        params,
        tableData,
        meta,
    }: {
        projectId: string;
        reportName: string;
        apiExtraPath: string;
        params: any;
        tableData: Partial<IDataTable>;
        meta: any;
    }) =>
        // eslint-disable-next-line no-return-await
        await fetchApi({
            url: `/api/report/v1.0/project/media/${projectId}/${apiExtraPath}graph`,
            method: 'POST',
            body: params,
            metaInfo: {
                ...meta,
                lineIds: params.selected_lines,
                tableData,
            },
        }),
);

export const getConstructorGraph = createAsyncThunk(
    'graphSlice/getConstructorGraph',
    async ({
        projectId,
        reportName,
        params,
        tableData,
        meta,
    }: {
        projectId: string;
        reportName: string;
        params: IReportRequestParams;
        tableData: IPreparedTableData;
        meta: any;
    }) =>
        // eslint-disable-next-line no-return-await
        await fetchApi({
            url: `/api/report/v1.0/eventbase/${projectId}/constructor`,
            method: 'POST',
            body: params,
            metaInfo: {
                body: params,
                ...params,
                ...meta,
                reportName,
                tableData,
            },
        }),
);
