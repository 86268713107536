import { requestReportCsv } from '@redux/slices/reports/api';
import { createSlice } from '@reduxjs/toolkit';
import { IReportsSlice } from '@typings/rootSlice';

export const initialState: IReportsSlice = {
    errorCode: null,
    pinnedProjects: null,
    viewType: null,
};

/**
 * Обновляем отчёт
 */
export const updateReportFunc = (state: IReportsSlice, action) => {
    Object.assign(state, action.payload);
};

/**
 * Обновляем pinnedProjects
 */
export const updatePinnedProjectsFunc = (state: IReportsSlice, action) => {
    state.pinnedProjects = action.payload;
};

/**
 * Обновляем параметры в стейте
 */
export const updateRequestParamsFunc = (state: IReportsSlice, action) => {
    Object.assign(state, action.payload);
};

export const resetParamFunc = (state: IReportsSlice, action) => {
    const param = action.payload;

    if (!(param in initialState)) return state;

    state[param] = initialState[param];
};

export const updateViewTypeFunc = (state: IReportsSlice, action) => {
    state.viewType = action.payload;
};

const reportsSlice = createSlice({
    name: 'reportsSlice',
    initialState,
    reducers: {
        allReset() {
            return initialState;
        },
        updateReport: (state, action) => updateReportFunc(state, action),
        updatePinnedProjects: (state, action) => updatePinnedProjectsFunc(state, action),
        updateRequestParams: (state, action) => updateRequestParamsFunc(state, action),
        resetParam: (state, action) => resetParamFunc(state, action),
        updateViewType: (state, action) => updateViewTypeFunc(state, action),
    },
});

export const { allReset, updateReport, updatePinnedProjects, updateRequestParams, resetParam, updateViewType } =
    reportsSlice.actions;

export const reportsActions = {
    allReset,
    updateReport,
    updatePinnedProjects,
    updateRequestParams,
    resetParam,
    updateViewType,
    requestReportCsv,
};

export default reportsSlice.reducer;
