/* eslint-disable max-len */
import { ChartTypes } from '@configs/graph';
import { sortOrders } from '@constants/sort';
import { IReportConfig } from '@typings/reports';

const reportConfig: IReportConfig = {
    reportTitle: 'Глубина просмотра',
    tableDataType: 'static',
    defaultSort: {
        name: 'visits',
        order: sortOrders.DESC,
    },
    numberOfGraphs: 'two',
    defaultGraphs: [
        ChartTypes.bar,
        ChartTypes.bar,
    ],
    graphDefaultMetrics: [
        'visits',
        'visitors',
    ],
    tableDefaultMetrics: [
        'visitors',
        'visits',
        'uid',
    ],
    availableMetrics: [
        'visits',
        'visitors',
        'uid',
        'rambler_id',
        // 'avg_industry_duration',
    ],
    titleAliases: {
        1: '1 страница',
        2: '2 страницы',
        3: '3 страницы',
        4: '4 страницы',
        5: '5 страниц',
        6: '6 страниц',
        7: '7 страниц',
        8: '8 страниц',
        9: '9 страниц',
        10: '10 страниц',
        11: 'Больше 10 страниц',
    },
    isShowTotals: true,
    availableTools: {
        numberOfGraphs: true,
        periodPicker: true,
        sample: true,
        activeSample: true,
        filters: true,
        savedFilters: true,
        detail: true,
    },
    reportHint: {
        message: 'Количество просмотров страниц сайта в рамках одного визита с группировкой по количеству страниц. Доступные показатели: посетители, визиты, время на сайте.',
    },
};

export default reportConfig;
