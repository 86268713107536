import { Radio } from '@adtech/ui';
import dateConstants from '@constants/date';
import { IconNoFavicon } from '@images/svg/icon-no-favicon';
import DateUtils from '@utils/date';
import { RadioChangeEvent } from 'antd/es/radio';
import React, { FC } from 'react';
import { Link } from 'react-router-dom';

import s from './RestoreCounterStep.pcss';

interface ICounterItem {
    id: number;
    url: string;
    title: string;
    created: string;
    inRating: boolean;
    favicon: string;
}

interface IProps {
    counterItem: ICounterItem;
    onChange: (value: number) => void;
    selectedCounter: number;
}

export const CounterRow: FC<IProps> = ({ counterItem, onChange = () => {}, selectedCounter = null }) => (
    <div className={s.row}>
        <Radio
            name="counters"
            className={s.wrapper}
            value={counterItem.id}
            key={counterItem.id}
            checked={counterItem.id === selectedCounter}
            onChange={(e: RadioChangeEvent) => onChange(e.target.value)}
        >
            <span className={s.counterItem}>
                <span className={s.counterLogo}>
                    {counterItem.favicon ? (
                        <img src={`data:image/png;base64, ${counterItem.favicon}`} alt="" />
                    ) : (
                        <IconNoFavicon />
                    )}
                </span>
                <span className={s.counterBody}>
                    <span className={s.counterParamRow}>
                        <Link to={`/stat/projects/${counterItem.id}`} className={s.counterTitle} target="_blank">
                            {counterItem.title}
                        </Link>
                    </span>
                    <span className={s.counterParamRow}>
                        <a href={counterItem.url} className={s.counterUrl} target="_blank" rel="noopener">
                            <span className={s.counterUrlText}>{counterItem.url}</span>
                        </a>
                    </span>
                    <span className={s.counterIdAndRating}>
                        <span className={s.counterId}>{counterItem.id}</span>
                        <span className={s.counterInRating}>
                            {counterItem.inRating ? 'Участвует в рейтинге' : 'Не участвует в рейтинге'}
                        </span>
                    </span>
                </span>
            </span>
            <span className={s.counterDate}>
                {DateUtils.getDateWithFormat(counterItem.created, dateConstants.DATE_VIEW)}
            </span>
        </Radio>
    </div>
);
