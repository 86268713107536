import { Modal } from '@adtech/ui';
import React, { ReactNode } from 'react';
import Actions from './Actions';
import Content from './Content';
import Search from './Search';
import Tabs from './Tabs';

interface IProps {
    children: ReactNode;
    actions: ReactNode;
    isOpened: boolean;
    onClose: () => void;
}

const Container: React.FC<IProps> = ({ children, actions, isOpened = false, onClose = () => {} }) => (
    <Modal
        isOpened={isOpened}
        closeOnClickOutside={false}
        onClose={onClose}
        customFooter={actions}
        size="lg"
        title="Фильтры по условиям"
    >
        {children}
    </Modal>
);

export default {
    Container,
    Search,
    Actions,
    Content,
    Tabs,
};
