import attributesData from '@configs/attributesData';
import { ImageCounter } from '@images/svg/counter';
import React from 'react';

import s from '../ModalPopup.pcss';
import { PopupContent } from '../components/PopupContent';
import { PopupHeader } from '../components/PopupHeader';

export default {
    getContent(modalPopupParams: Record<string, () => void>) {
        const popupHeader = <PopupHeader title="Восстановление доступа к счётчику" image={<ImageCounter />} />;

        const popupContent = (
            <PopupContent
                className={s.content}
                content="Для этого URL уже есть счётчик.
                    Вы хотите восстановить доступ к этому счётчику?"
            />
        );

        const okButton = {
            type: 'default',
            children: 'Восстановить доступ',
            onClick: modalPopupParams.goToRestoreCounter,
            closePopupOnClick: true,
            'data-create-counter':
                `${attributesData.createCounter.restoreCounterPopup}` +
                `::${attributesData.createCounter.restoreCounterBtn}`,
        };

        const cancelButton = {
            type: 'dashed',
            children: 'Создать новый счётчик',
            onClick: modalPopupParams.createCounter,
            closePopupOnClick: true,
            'data-create-counter':
                `${attributesData.createCounter.restoreCounterPopup}` +
                `::${attributesData.createCounter.createCounterBtn}`,
        };

        return {
            popupClassName: s.restoreAccessCounter,
            width: 'lg',
            title: popupHeader,
            content: popupContent,
            buttonContainerClassName: s.buttons,
            okButton,
            cancelButton,
        };
    },
};
