import serverErrorsConstants from '@constants/serverErrors';
import snackbarTypes from '@constants/snackbarTypes';
import snackbarsConstants from '@constants/snackbars';
import { formDecorator } from '@decorators/index';
import { globalActions } from '@redux/slices/global';
import { projectsActions } from '@redux/slices/projects';
import { userActions } from '@redux/slices/users';
import { AppDispatch } from '@redux/store';
import AccessForm from './AccessForm';

const mapStateToProps = (state) => ({
    currentProject: state.projectsSlice.current,
    saveCounterRequest: state.projectsSlice.saveCounterRequest,
    currentEmail: state.userSlice.email,
});

const mapDispatchToProps = (dispatch: AppDispatch) => ({
    getUserExists: (email) => dispatch(userActions.getUserExists(email)),
    saveCounter: (id: number, data: Record<string, any>) => dispatch(projectsActions.save({ id, data })),
    onSubmit(isAddUsers, isDeleteUsers) {
        const { values, invalid, saveCounter, currentProject, setServerError } = this;

        if (invalid) {
            return Promise.reject();
        }

        const users = values.addedUsers.map((user) => ({
            email: user.email,
            access_level: user.accessLevel === 'edit' ? 0 : 1,
            can_edit_project: user.accessLevel === 'edit',
        }));

        const data = { users };

        return saveCounter(currentProject.id, data).then(({ payload }) => {
            if (payload.responseStatus === 200) {
                let message;
                const type: string = snackbarTypes.WARNING;

                // Выводим сообщение об изменениях доступа.
                // Сообщение о выдаче доступа - в приоритете.
                if (isDeleteUsers) {
                    message = snackbarsConstants.ACCSSES_CHANGED;
                }
                if (isAddUsers) {
                    message = snackbarsConstants.ACCSSES_GRANTED;
                }

                dispatch(globalActions.setSnackbar({ message, type }));

                window.scrollTo(0, 0);
            } else if (payload.errors && payload.errors.message) {
                if (payload.errors.message === serverErrorsConstants.REQUEST_API_ERROR) {
                    setServerError('Произошла ошибка при запросе на сервер.');
                } else if (payload.errors.message === serverErrorsConstants.RATE_LIMIT_EXCEEDED) {
                    // prettier-ignore
                    // eslint-disable-next-line max-len
                    setServerError('В день вы можете добавить не более 20 пользователей. Чтобы дать доступ большему числу людей, вернитесь завтра или обратитесь в <a href="mailto:counter@ads.sber.ru">службу поддержки.</a>');
                }
            }
        });
    },
});

export default formDecorator({
    formName: 'accessSettings',
    isRemoveForm: false,
    initializeValues(state) {
        const currentProject = state.projectsSlice.current;

        const addedUsers = currentProject.users.map((user) => ({
            accessLevel: user.access_level ? 'view' : 'edit',
            email: user.user.email,
        }));

        return { addedUsers };
    },
    mapStateToProps,
    mapDispatchToProps,
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
})(AccessForm);
