import dashboardConfig from '@configs/dashboard';
import { GroupBy } from '@configs/group';
import dateConstants from '@constants/date';

import { EPeriodDetail } from '@constants/periodDetail';
import useFilters from '@hooks/useFilters';
import useGlobalParams from '@hooks/useGlobalParams';
import useQuery from '@hooks/useQuery';
import { useRequestParamsDashboard } from '@hooks/useRequestParams';
import { useAppDispatch } from '@hooks/useStore';
import { useTable } from '@hooks/useTable';
import { dashboardActions } from '@redux/slices/dashboard';
import { graphActions } from '@redux/slices/graph';
import { tableActions } from '@redux/slices/table';
import { IDashboardRequestWidgetParams, IDashboardWidgetParams } from '@typings/dashboard';
import { DateLiteral } from '@typings/date';
import { DataArrayTypeComplex } from '@typings/graph';
import { IQuery } from '@typings/query';
import { IReportRequestParams, IUpdateReportOptions, IUpdateReportParams, ReportNameBase } from '@typings/reports';
import { IRootSlice } from '@typings/rootSlice';
import { IUpdateWidgetParams } from '@typings/widgets';
import DateUtils from '@utils/date';
import { urlUtils } from '@utils/index';
import reportsUtilsFunc from '@utils/reports';
import { useState } from 'react';
import { useSelector } from 'react-redux';
import { useLocation, useParams } from 'react-router-dom';

const useDashboard = () => {
    const reportName = 'dashboard' as ReportNameBase;
    const dispatch = useAppDispatch();
    const paramsFromUrl = useParams();
    const { projectId } = paramsFromUrl;

    const location = useLocation();
    const { search } = location;
    const query: IQuery = urlUtils.parseQuery(search);

    const lastRequestId = useSelector((state: IRootSlice) => state.dashboardSlice.lastRequestId);

    const dateStart = useSelector((state: IRootSlice) => state.globalSlice.dateStart);
    const dateEnd = useSelector((state: IRootSlice) => state.globalSlice.dateEnd);
    const groupBy = useSelector((state: IRootSlice) => state.globalSlice.groupBy);
    const periodDetail = useSelector((state: IRootSlice) => state.globalSlice.periodDetail);
    const globalFilters = useSelector((state: IRootSlice) => state.globalSlice.globalFilters);

    const tableSlice = useSelector((state: IRootSlice) => state.tableSlice);
    const sample = useSelector((state: IRootSlice) => state.tableSlice.sample);

    const [widgetParams, setWidgetParams] = useState<Record<string, IDashboardRequestWidgetParams>>({});
    const [eventsFilterValues, setEventsFilterValues] = useState<Record<string, string[]>>({});

    const {
        api: { getParamsForRequest },
    } = useRequestParamsDashboard();

    const { getParamsFromQuery, checkQueryParams } = useQuery();

    const {
        api: { updateTableParams },
    } = useTable();
    const { updateGlobalParams } = useGlobalParams();
    const { updateApplyedFilterParams } = useFilters();

    const reportsUtils = reportsUtilsFunc({
        reportsConfig: dashboardConfig,
    });

    const api = {
        async init(): Promise<void> {
            const defaultParams = {
                dateStart,
                dateEnd,
                groupBy,
                sample,
                globalFilters: { ...globalFilters },
            };
            const queryParams = getParamsFromQuery(query);
            let checkedParams = checkQueryParams(queryParams, defaultParams, { ...tableSlice }, api.resetParam);
            checkedParams = await updateApplyedFilterParams(checkedParams, null);

            if (checkedParams.globalFilters?.filtersRequest) {
                updateGlobalParams({ globalFilters: checkedParams.globalFilters });
            }

            updateTableParams(checkedParams);
            updateGlobalParams(checkedParams);

            api.updateDashboard(checkedParams);

            return Promise.resolve();
        },

        updateDashboard(
            params?: IUpdateReportParams & IDashboardWidgetParams,
            isAutoUpdate?: boolean, // запрос был сделан автоматически в фоне
        ): void {
            const widgetReqParams = params?.widgetParams || widgetParams;

            const requestParams = getParamsForRequest(params);

            const requestBody = {
                ...requestParams,
                ...(Object.keys(widgetReqParams).length ? { widget_params: widgetReqParams } : {}),
            };

            dispatch(
                dashboardActions.getEventBasedDashBoardWidgets({
                    projectId: String(projectId),
                    requestId: api.addDashboardRequestId(),
                    isAutoUpdate,
                    body: requestBody,
                }),
            )
                .unwrap()
                .then((payload) => {
                    const sampleData = payload?.result?.sample;

                    if (sampleData) updateTableParams({ sample: sampleData });
                });
        },

        /**
         * Метод используется для однократного обновления параметров в сторе,
         * прокидывается в другие компоненты
         */
        updateReport(type: string, updateParams: IUpdateReportOptions<IUpdateReportParams, ReportNameBase>) {
            const { options } = updateParams;
            const { withoutCompare } = options || {};

            const { newParams: tableParams } = updateTableParams(updateParams.updateParams, withoutCompare);
            // Обогащаем фильтры данными динамических фильтров
            updateApplyedFilterParams(updateParams.updateParams, null).then((newParams) => {
                if (!newParams || !Object.keys(newParams)?.length) return;

                const { newParams: globalParams } = updateGlobalParams(newParams, withoutCompare);

                api.updateDashboard({ ...tableParams, ...globalParams });
            });
        },

        updateWidgetMetric(widgetName: string, metric: string): void {
            const newWidgetParams = {
                ...widgetParams,
                [`eventbase_${widgetName}`]: {
                    ...widgetParams[`eventbase_${widgetName}`],
                    metrics: [metric],
                    limit: 30,
                },
            };

            setWidgetParams(newWidgetParams);

            api.updateDashboard({ widgetParams: newWidgetParams });
        },

        updateAudienceWidget(widgetName: string, options: IUpdateWidgetParams): void {
            const { dimensions } = options || {};

            if (!dimensions) return;

            const [dimension] = dimensions;
            const newWidgetParams = {
                ...widgetParams,
                eventbase_audience: { ...widgetParams.eventbase_audience, dimension },
            };

            setWidgetParams(newWidgetParams);
            api.updateDashboard({ widgetParams: newWidgetParams });
            api.updateWidgetParams(widgetName, { selected: dimension });
        },

        /* Обновление параметров виджета в store */
        updateWidgetParams(widgetName: string, options: IUpdateWidgetParams): void {
            dispatch(dashboardActions.updateWidgetParams({ widgetName, options }));
        },

        resetDashboard(): void {
            dispatch(dashboardActions.allReset());
        },

        getEventsList(): void {
            const { DATE_FORMAT } = dateConstants;

            const startDate = DateUtils.someDaysAgo(7).format(DATE_FORMAT);

            const requestParams = {
                date_start: startDate,
                date_end: 'today',
                group: GroupBy.day,
                offset: 0,
                parents: [],
            } as Partial<IReportRequestParams>;

            dispatch(dashboardActions.getEventsList({ projectId, params: requestParams }));
        },

        // Собираем параметры на основе выбранных данных из попапа фильтра источников трафика
        transformSourcesDataToWidgetParams(
            trafficSource: string | null,
        ): Record<string, IDashboardRequestWidgetParams> {
            let newWidgetParams = { ...widgetParams };

            if (trafficSource) {
                newWidgetParams = {
                    ...newWidgetParams,
                    eventbase_traffic_sources_realtime: {
                        limit: 5,
                        traffic_type: trafficSource,
                    },
                };
            } else {
                delete newWidgetParams?.eventbase_traffic_sources_realtime;
            }

            setWidgetParams(newWidgetParams);
            return newWidgetParams;
        },

        // Собираем параметры на основе выбранных данных из попапа фильтра событий
        transformEventsDataToWidgetParams(
            eventsFilterObj: Record<string, string[]>,
        ): Record<string, IDashboardRequestWidgetParams> {
            let newWidgetParams = { ...widgetParams };
            const eventsClasses = Object.keys(eventsFilterObj);
            let eventsNames = [];

            if (eventsClasses.length) {
                eventsClasses.forEach((eventClass) => {
                    eventsNames = [...eventsNames, ...eventsFilterObj[eventClass]];
                });
                eventsNames = [...new Set(eventsNames)];

                newWidgetParams = {
                    ...newWidgetParams,
                    eventbase_events: {
                        limit: 20,
                        dimension_filters: [
                            { key: 'event_class', op: 'include', value: eventsClasses },
                            { key: 'event_name', op: 'include', value: eventsNames },
                        ],
                    },
                };
            } else {
                delete newWidgetParams?.eventbase_events;
            }

            setWidgetParams(newWidgetParams);
            setEventsFilterValues(eventsFilterObj);
            return newWidgetParams;
        },

        filterEventsWidgetData(eventsWidgetData: DataArrayTypeComplex[]): DataArrayTypeComplex[] {
            const data = eventsWidgetData;

            if (!data || !Object.keys(eventsFilterValues).length) return eventsWidgetData;

            return data.filter(([item]) => {
                const [eventClass, eventName] = item;

                if (eventClass in eventsFilterValues) {
                    return eventsFilterValues[eventClass].includes(eventName);
                }

                return false;
            });
        },

        resetParam(param: string): void {
            dispatch(dashboardActions.resetParam(param));
            dispatch(tableActions.resetParam(param));
            dispatch(graphActions.resetParam(param));
        },

        handleChangeDate(date: string[], relativeDate: DateLiteral): void {
            const [start, end] = date;

            const updateParams = {
                ...(relativeDate ? DateUtils.getRelativeDates(relativeDate) : { dateStart: start, dateEnd: end }),
                groupBy,
            };

            updateParams.groupBy = DateUtils.checkCurrentGroup(
                [DateUtils.getDate(start), DateUtils.getDate(end)],
                groupBy,
            );

            if (DateUtils.isPeriodToday(start, end) && periodDetail === EPeriodDetail.thirtyMinutes) {
                updateParams.groupBy = GroupBy.minute;
            }

            api.updateReport('all', { updateParams });
        },

        handleChangePeriodDetail(value: EPeriodDetail, group?: GroupBy): GroupBy {
            let groupParam = group;

            if (value === EPeriodDetail.thirtyMinutes) {
                groupParam = GroupBy.minute;
            }

            return groupParam;
        },

        updateParams(newParams: Record<string, any>): void {
            dispatch(dashboardActions.updateParams(newParams));
        },

        // Генерируем и обновляем id последнего отправленного запроса
        addDashboardRequestId(): number {
            api.updateParams({ lastRequestId: lastRequestId + 1 });

            return lastRequestId + 1;
        },

        setIsAutoUpdate(isAutoUpdate: boolean): void {
            dispatch(dashboardActions.setIsAutoUpdate(isAutoUpdate));
        },
    };

    return {
        api,
        reportsUtils,
        reportName,
    };
};

export default useDashboard;
