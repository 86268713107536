import listDataConstants, { Op } from '@constants/listData';
import classNames from 'classnames';
import * as React from 'react';

import InputField from './InputField';
import s from './PeriodType.pcss';

import SelectField from './SelectField';

interface IProps {
    value: Record<string, any>;
    options: Record<string, any>;
}

export default class PeriodType extends React.PureComponent<IProps> {
    render() {
        const { value, options } = this.props;

        const contentClassname = classNames(s.content, {
            [s.column]: options.isTime,
        });
        return (
            <div className={s.period}>
                <div className={contentClassname} data-testid="period-container">
                    <div className={s.op}>
                        <SelectField
                            className={s.selectOp}
                            name="op"
                            options={listDataConstants.periodSelectOp}
                            initialValue={listDataConstants.defaultValues.op}
                        />
                    </div>

                    <div className={s.inputsWrap}>
                        {value.op !== Op.range && (
                            <div className={s.inputs}>
                                <InputField name="value" type="number" placeholder="Введите число" />
                            </div>
                        )}
                        {value.op === Op.range && (
                            <div className={s.inputs}>
                                <InputField name="from" type="number" placeholder="от" />
                                <span className={s.delimiter}>&mdash;</span>
                                <InputField name="to" type="number" placeholder="до" />
                            </div>
                        )}
                        {options.isTime && (
                            <SelectField
                                className={s.selectTime}
                                name="time"
                                options={listDataConstants.periodSelectTime}
                                initialValue={listDataConstants.defaultValues.time}
                            />
                        )}
                    </div>
                </div>
            </div>
        );
    }
}
