import ContentWithSidebar from '@components/ContentWithSidebar';
import Conversion from '@components/Conversion';
import MarketingTable from '@components/Conversion/MarketingTable';
import Grid from '@components/Grid';
import PageHead from '@components/PageHead';
import ReportTools from '@components/ReportTools';
import TrafficSourcesWidgetFilter from '@components/WidgetFilter/TrafficSourcesWidgetFilter';
import { Widget } from '@components/index';

import { EConversionTitles, widgetsDefaultDimensions } from '@configs/conversions';
import conversionConfig from '@configs/conversions/conversion';
import { ConversionFormType } from '@configs/conversions/conversionForm';
import { ChartFormatterValues, ChartTypes } from '@configs/graph';
import { WidgetTypes } from '@configs/widget';
import { LatencyKeys } from '@constants/latency';
import { Error507 } from '@containers/Error';

import useCheckDates from '@hooks/useCheckDates';
import useConversions from '@hooks/useConversions';
import useGlobalLoading from '@hooks/useGlobalLoading';
import useLatency from '@hooks/useLatency';
import useModalPopup from '@hooks/useModalPopup';
import useProjectRequired from '@hooks/useProjectRequired';
import useQuery from '@hooks/useQuery';

import { globalActions } from '@redux/slices/global';
import { TChartDataItem } from '@typings/graph';
import { IRootSlice } from '@typings/rootSlice';
import DateUtils from '@utils/date';

import React, { FC, useEffect, useRef } from 'react';
import DocumentTitle from 'react-document-title';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';

const ConversionContainer: FC = () => {
    useGlobalLoading();

    const dispatch = useDispatch();
    const params: IUrlParams = useParams();
    const projectId = Number(params.projectId);
    const reportName = 'conversion';

    const isProjectRequired = useProjectRequired();
    const isReportInited = useRef(false);

    const { openModalPopup, closeModalPopup } = useModalPopup();
    const { api, reportsUtils } = useConversions();
    const { updateQuery } = useQuery();

    const userId = useSelector((state: IRootSlice) => state.userSlice.id);

    const errorCode = useSelector((state: IRootSlice) => state.conversionsSlice.errorCode);
    const currentConversion = useSelector((state: IRootSlice) => state.conversionsSlice.currentConversion);
    const conversionsDict = useSelector((state: IRootSlice) => state.conversionsSlice.conversionsDict);
    const attributionModel = useSelector((state: IRootSlice) => state.conversionsSlice.attributionModel);
    const attributionWindow = useSelector((state: IRootSlice) => state.conversionsSlice.attributionWindow);
    const trafficSources = useSelector((state: IRootSlice) => state.conversionsSlice.widgets?.traffic_sources);
    const audience = useSelector((state: IRootSlice) => state.conversionsSlice.widgets?.audience);
    const technology = useSelector((state: IRootSlice) => state.conversionsSlice.widgets?.technology);

    const allDimensions = useSelector((state: IRootSlice) => state.conversionsSlice.allDimensions);
    const data = useSelector((state: IRootSlice) => state.conversionsSlice.conversionTableData);

    const sample = useSelector((state: IRootSlice) => state.tableSlice.sample);

    const globalLoading = useSelector((state: IRootSlice) => state.globalSlice.globalLoading);
    const serverTime = useSelector((state: IRootSlice) => state.globalSlice.serverTime);
    const dateStart = useSelector((state: IRootSlice) => state.globalSlice.dateStart);
    const dateEnd = useSelector((state: IRootSlice) => state.globalSlice.dateEnd);
    const groupBy = useSelector((state: IRootSlice) => state.globalSlice.groupBy);

    const projectURL = useSelector((state: IRootSlice) => state.projectsSlice.current.cleanedUrl);
    const permissions = useSelector((state: IRootSlice) => state.projectsSlice.current.permissions);

    const setGlobalLoading = (param: boolean) => dispatch(globalActions.setGlobalLoading(param));
    const clearError = () => dispatch(globalActions.clearError());
    const showErrorPage = errorCode === 507;

    const latencyConfig = useSelector((state: IRootSlice) => state.latencySlice[LatencyKeys.base]);
    const isExcludedLatency = latencyConfig?.isExcludedLatency;
    const getLatency = useLatency(LatencyKeys.base);
    const checkedDates = useCheckDates(LatencyKeys.base);

    const minCalendarDate = latencyConfig?.start;
    const maxCalendarDate = latencyConfig?.end;

    const reportSample = sample || conversionConfig.sampleDefault;

    const getPageTitle = () => {
        const url = projectURL ? `(${projectURL}) ` : '';
        return `Конверсия ${url} – Статистика от SberAds / ${projectId}`;
    };

    useEffect(
        () => () => {
            setGlobalLoading(false);
        },
        [],
    );

    useEffect(() => {
        if (!checkedDates || !isProjectRequired) {
            setGlobalLoading(true);
            return;
        }

        isReportInited.current = false;

        api.initConversion().then(() => {
            isReportInited.current = true;
            setGlobalLoading(false);
        });
    }, [checkedDates, isProjectRequired]);

    useEffect(() => {
        if (!isReportInited.current) return;

        updateQuery({
            dateEnd,
            dateStart,
            groupBy,
            sample,
        });
    }, [isReportInited.current, dateEnd, dateStart, groupBy, sample]);

    useEffect(() => {
        isReportInited.current = false;
    }, [projectId]);

    const handleChangeDate507 = () => {
        clearError();
    };

    const renderError = () => {
        const dates = DateUtils.convertDates(dateStart, dateEnd);

        return (
            <Error507
                dateStart={dates[0]}
                dateEnd={dates[1]}
                handleChangeDate={handleChangeDate507}
                minCalendarDate={minCalendarDate}
                maxCalendarDate={maxCalendarDate}
                serverTime={serverTime}
                getLatency={getLatency}
                isExcludedLatency={isExcludedLatency}
            />
        );
    };

    const editConversion = () => {
        openModalPopup('ConversionForm', {
            type: ConversionFormType.edit,
            closePopup: closeModalPopup,
            data: currentConversion,
            api,
        });
    };

    const renderConversion = () => {
        if (!currentConversion) return null;

        const { id, description, metric, access, email, numerator_goal_id, denominator_goal_id } = currentConversion;
        const conversionData = conversionsDict && conversionsDict[id];
        const conversionTotals = [
            {
                title: EConversionTitles.conversion,
                value: conversionData?.conversion?.totals,
                format: 'percent',
            },
            {
                title: EConversionTitles.goal,
                value: conversionData?.goal?.totals,
                format: 'number',
            },
        ];

        const graphData = api.formatConversionGraphData(conversionData) as TChartDataItem[];

        return (
            <Conversion
                id={id}
                key={id}
                title="Ключевые метрики"
                description={description}
                graphData={graphData}
                totals={conversionTotals}
                loading={conversionData?.request}
                metric={metric}
                dateStart={dateStart}
                dateEnd={dateEnd}
                groupBy={groupBy}
                useFavorites={false}
                access={access}
                ownerEmail={email}
                goalsIds={[numerator_goal_id, denominator_goal_id]}
                onClickEdit={editConversion}
                api={api}
            />
        );
    };

    const reportTitle = `${conversionConfig.reportTitle}: ${currentConversion?.title}`;

    return (
        <DocumentTitle title={getPageTitle()}>
            <>
                {showErrorPage && renderError()}
                {!showErrorPage && !globalLoading && (
                    <>
                        <PageHead
                            reportName={reportName}
                            projectId={projectId}
                            userId={userId}
                            title={reportTitle}
                            hintContent={conversionConfig.reportHint}
                        />
                        <ContentWithSidebar>
                            <ReportTools
                                reportName={reportName}
                                reportsUtils={reportsUtils}
                                projectId={projectId}
                                updateReport={api.updateReport}
                                getLatency={getLatency}
                                serverTime={serverTime}
                                minDate={minCalendarDate}
                                maxDate={maxCalendarDate}
                                dateStart={dateStart}
                                dateEnd={dateEnd}
                                isExcludedLatency={isExcludedLatency}
                                handleChangeDate={api.handleChangeDate}
                                sample={reportSample}
                                groupBy={groupBy}
                                attributionModel={attributionModel}
                                attributionWindow={attributionWindow}
                                getTypeRequestReport={() => 'all'}
                                accessLevel={permissions.can_edit_project}
                                dynamicFiltersData={{}}
                                globalFilters={null}
                                availableTools={conversionConfig.availableTools}
                                filtersConfig={null}
                                graphs={null}
                            />
                            {renderConversion()}
                            <MarketingTable
                                data={data}
                                defaultColumn={currentConversion?.metric}
                                allDimensions={allDimensions}
                            />
                            <Grid>
                                <Widget
                                    reportName={reportName}
                                    data={[]} // Дефолтное значение
                                    period={[dateStart, dateEnd]}
                                    groupBy={groupBy}
                                    type={WidgetTypes.graph}
                                    graphType={ChartTypes.bar}
                                    title="Источники переходов"
                                    name="traffic_sources"
                                    heightChart={200}
                                    metrics={[currentConversion?.metric]}
                                    isShowChartSwitcher
                                    handleGraphSelect={api.updateWidgetParams}
                                    {...trafficSources}
                                    disabledGraphs={{ pie: true, area: true }}
                                    selected={currentConversion?.metric}
                                    formatterValues={ChartFormatterValues.percent}
                                    // attrCnfName='conversion'
                                    heightType="small"
                                    widgetCustomSettingsElement={() => (
                                        <TrafficSourcesWidgetFilter onApply={api.updateTrafficSourcesWidget} />
                                    )}
                                />
                                <Widget
                                    reportName={reportName}
                                    data={[]} // Дефолтное значение
                                    period={[dateStart, dateEnd]}
                                    groupBy={groupBy}
                                    type={WidgetTypes.graph}
                                    graphType={ChartTypes.bar}
                                    title="Аудитория"
                                    name="audience"
                                    heightChart={145}
                                    isShowChartSwitcher
                                    handleGraphSelect={api.updateWidgetParams}
                                    onUpdate={api.updateAudienceOrTechWidget}
                                    tabsNum={4}
                                    selected={widgetsDefaultDimensions.audience}
                                    dimensions={['gender', 'age_tns', 'geo_country', 'category_1']}
                                    allDimensions={['gender', 'age_tns', 'geo_country', 'category_1']}
                                    metrics={null}
                                    defaultMetric={currentConversion?.metric}
                                    formatterValues={ChartFormatterValues.percent}
                                    {...audience}
                                    disabledGraphs={{ pie: true, area: true }}
                                    // attrCnfName='conversion'
                                    heightType="small"
                                />
                                <Widget
                                    reportName={reportName}
                                    data={[]} // Дефолтное значение
                                    period={[dateStart, dateEnd]}
                                    groupBy={groupBy}
                                    type={WidgetTypes.graph}
                                    graphType={ChartTypes.bar}
                                    title="Технологии"
                                    name="technology"
                                    heightChart={145}
                                    isShowChartSwitcher
                                    handleGraphSelect={api.updateWidgetParams}
                                    onUpdate={api.updateAudienceOrTechWidget}
                                    tabsNum={3}
                                    selected={widgetsDefaultDimensions.technology}
                                    dimensions={['counter_type_eventbase', 'device_type', 'os_family']}
                                    allDimensions={['counter_type_eventbase', 'device_type', 'os_family']}
                                    defaultMetric={currentConversion?.metric}
                                    formatterValues={ChartFormatterValues.percent}
                                    {...technology}
                                    disabledGraphs={{ pie: true, area: true }}
                                    // attrCnfName='conversion'
                                    heightType="small"
                                />
                            </Grid>
                        </ContentWithSidebar>
                    </>
                )}
            </>
        </DocumentTitle>
    );
};

export default ConversionContainer;
