/* eslint-disable max-len */
import { formUtils } from '@utils/index';
import uniq from 'lodash/uniq';

const customMaxLength = (max) => (value, values, props) => {
    const map = props.map;

    // возвращаем лианы выбранных элементов и фильтруем, чтобы они были не пустыми
    // prettier-ignore
    const themesIds = value ? value.map((id) => {
        const item = map[id];

        return item ? item.theme_id : undefined;
    }).filter(themeId => themeId !== undefined) : [];

    // если лиан нету - проверяем длину значения по дефолту
    if (!themesIds.length) {
        return formUtils.defaultRules.maxLength(max)(value);
    }

    // если лианы есть - выбираем только уникальные лианы и проверяем на длину
    return formUtils.defaultRules.maxLength(max)(uniq(themesIds));
};

export default [
    {
        key: 'categories',
        title: 'Темы',
        label: 'Темы',
        titlePopup: 'Добавить темы',
        description:
            'Рекомендуем указывать тему точно подходящую для всего сайта. Сайты, привязанные к темам второго уровня, будут отображаться при выборе темы первого уровня.',
        labelButton: 'Добавить тему',
        labelSelected: 'Выберите от 1 до 7 тем',
        isRequired: true,
        rules: [
            { validate: formUtils.defaultRules.arrayRequired, errorType: 'required' },
            { validate: customMaxLength(7), errorType: 'length', customErrorText: 'Максимальное количество тем: 7' },
        ],
    },
    {
        key: 'types',
        title: 'Типы',
        label: 'Типы',
        titlePopup: 'Добавить типы',
        description: 'Определяет тип, характер сайта.',
        labelButton: 'Добавить тип',
        labelSelected: 'Выберите не более 2 типов',
        rules: [
            { validate: customMaxLength(2), errorType: 'length', customErrorText: 'Максимальное количество типов: 2' },
        ],
    },
    {
        key: 'regions',
        title: 'Регионы',
        label: 'Регионы',
        titlePopup: 'Добавить регионы',
        description:
            'Регион, информация о котором содержится на сайте, либо регион, в котором предоставляются услуги, описанные на сайте.',
        labelButton: 'Добавить регион',
        labelSelected: 'Выберите не более 10 регионов',
        rules: [
            {
                validate: customMaxLength(10),
                errorType: 'length',
                customErrorText: 'Максимальное количество регионов: 10',
            },
        ],
    },
];
