import { dimensionsDict, IDimensions } from '@configs/dimensions';
import { ChartTypes } from '@configs/graph';
import { metricsDict, IMetric } from '@configs/metrics';
import dateConstants from '@constants/date';
import { graphTypes } from '@constants/graph';
import { Dimensions } from '@typings/dimensions';
import { GraphsDict } from '@typings/graph';
import { Metrics } from '@typings/metrics';
import { ReportName } from '@typings/reports';
import { IOrderBy } from '@typings/table';
import DateUtils from '@utils/date';

import numbersUtils from './numbersUtils';

type Measures = Metrics | Dimensions;

class MeasuresUtils<T extends Measures> {
    constructor() {
        return Object.freeze({
            isDimension: this.isDimension,
            getConfig: this.getConfig,
            getParamFromConfig: this.getParamFromConfig,
            getTitle: this.getTitle,
            getFormat: this.getFormat,
            getTooltip: this.getTooltip,
            isOrderingBy: this.isOrderingBy,
            checkOrderDirection: this.checkOrderDirection,
            canShowInPercents: this.canShowInPercents,
            canShowSort: this.canShowSort,
            prepareValue: this.prepareValue,
            getDisabledGraphs: this.getDisabledGraphs,
            getAvailableGraphs: this.getAvailableGraphs,
        });
    }

    isDimension = (measureName: Measures): measureName is Dimensions =>
        !!(dimensionsDict && dimensionsDict[measureName]);

    getConfig = (measureName: T): IMetric | IDimensions => {
        const isDimension = this.isDimension(measureName);
        const dict = isDimension ? dimensionsDict : metricsDict;
        return (dict && dict[measureName]) || {};
    };

    getParamFromConfig = (
        measureName: T,
        paramName?: string,
        defaultResult: boolean | number | string | string[] | Record<string, boolean> = false,
    ) => {
        const config = this.getConfig(measureName);

        if (paramName in config) {
            return config[paramName];
        }

        return defaultResult;
    };

    getTitle = (measuresName: T[] | T, reportName?: ReportName): string | string[] => {
        const getTitleFromConfig = (measureName: T): string => {
            const config = this.getConfig(measureName);
            const title = this.getParamFromConfig(measureName, 'title', null);
            const alias = config?.aliasesByReports && config.aliasesByReports[reportName]?.title;

            return alias || title;
        };

        if (Array.isArray(measuresName)) {
            return measuresName.map(getTitleFromConfig);
        }

        return getTitleFromConfig(measuresName);
    };

    getFormat(measuresName: T): IMetric['format'] | IDimensions['format'];

    getFormat(measuresName: T[]): (IMetric['format'] | IDimensions['format'])[];

    getFormat(
        measuresName: T | T[],
    ): (IMetric['format'] | IDimensions['format']) | (IMetric['format'] | IDimensions['format'])[] {
        if (Array.isArray(measuresName)) {
            return measuresName.map((measure) => this.getFormat(measure));
        }

        const config = this.getConfig(measuresName);
        return config.format;
    }

    getTooltip = (measureName: T, reportName?: ReportName): string => {
        const config = this.getConfig(measureName);
        const result = [];

        if ('tooltip' in config) {
            const alias = config?.aliasesByReports && config.aliasesByReports[reportName]?.tooltip;

            result.push(alias || config.tooltip);

            if ('extraTooltipByReports' in config && reportName in config.extraTooltipByReports) {
                const indicatorsConfigByReport = config.extraTooltipByReports[reportName];

                if ('tooltipBefore' in indicatorsConfigByReport) {
                    result.unshift(indicatorsConfigByReport.tooltipBefore);
                }

                if ('tooltipAfter' in indicatorsConfigByReport) {
                    result.push(indicatorsConfigByReport.tooltipAfter);
                }
            }
        }

        if (result.length) {
            return result.join('');
        }
        return null;
    };

    isOrderingBy = (measureName: T, orderBy: IOrderBy) => {
        const orders = this.checkOrderDirection(orderBy);
        const activeMetric = orderBy?.key === measureName;

        return activeMetric && (orders.asc || orders.desc);
    };

    checkOrderDirection = (orderBy: IOrderBy): { asc: boolean; desc: boolean } => {
        const checkOrder = (name) => orderBy?.value === name;

        return {
            asc: checkOrder('asc'),
            desc: checkOrder('desc'),
        };
    };

    canShowInPercents = (measureName: T): boolean => !this.getParamFromConfig(measureName, 'hidePercent', null);

    canShowSort = (measureName: T): boolean => !this.getParamFromConfig(measureName, 'hideSort', null);

    // Получение недоступных типов графиков для метрики
    getDisabledGraphs = (
        measureName: T,
        disabledGraphsFromReportConfig: GraphsDict = {},
        reportName?: ReportName,
    ): GraphsDict => {
        const config = this.getConfig(measureName);
        const disabledGraphs = 'disabledGraphs' in config ? config.disabledGraphs : {};
        let disabledGraphsByReports = {};

        if ('disabledGraphsByReports' in config && reportName in config.disabledGraphsByReports) {
            disabledGraphsByReports = config.disabledGraphsByReports[reportName];
        }

        return {
            ...disabledGraphs,
            ...disabledGraphsByReports,
            ...disabledGraphsFromReportConfig,
        };
    };

    // Получение доступных типов графиков для метрики
    getAvailableGraphs = (
        measureName: T,
        disabledGraphsFromReportConfig: GraphsDict = {},
        reportName?: ReportName,
    ): Partial<ChartTypes>[] => {
        const disabledGraphs = this.getDisabledGraphs(measureName, disabledGraphsFromReportConfig, reportName);

        return graphTypes.filter((item) => !disabledGraphs[item]);
    };

    // Функция работает с метриками и дименшинами
    prepareValue = (measureName: T, value: number | string = 0): string => {
        const config = this.getConfig(measureName);

        let newValue = value;
        let newFormat = config?.format;

        // Если нет значения и есть defaultValue, возвращается defaultValue
        if (!value && 'defaultValue' in config && config.defaultValue) {
            return config.defaultValue;
        }

        switch (newFormat) {
            case 'date':
                return DateUtils.getDate(value).format(dateConstants.DATE_VIEW);
            default:
                if (!newFormat) newFormat = 'number';
                break;
        }

        if (config?.formatterValue === 'percent') {
            newValue = Number(value) * 100;
        }

        const result = [numbersUtils.formatValue(newValue, newFormat)];

        if ('dimension' in config && config.dimension) {
            result.push(config.dimension);
        }

        return result.join(' ');
    };
}

export default new MeasuresUtils();
