/* eslint-disable max-len */
import { ChartTypes } from '@configs/graph';
import { sortOrders } from '@constants/sort';
import { IReportConfig } from '@typings/reports';

const reportConfig: IReportConfig = {
    reportTitle: 'География',
    tableDataType: 'dynamic',
    tableTitle: 'Страна, регион, город',
    defaultSort: {
        name: 'visitors',
        order: sortOrders.DESC,
    },
    disabledSortBy: ['title'],
    numberOfGraphs: 'two',
    defaultGraphs: [
        ChartTypes.bar,
        ChartTypes.bar,
    ],
    graphDefaultMetrics: [
        'visitors',
        'page_views',
    ],
    tableDefaultMetrics: [
        'visitors',
        'page_views',
        'avg_view_depth',
    ],
    availableMetrics: [
        'visits',
        'avg_view_depth',
        'page_views',
        'visitors',
        'uid',
        'rambler_id',
        // 'bounces',
        // 'avg_industry_duration',
    ],
    isShowTotals: true,
    availableTools: {
        numberOfGraphs: true,
        periodPicker: true,
        sample: true,
        activeSample: true,
        filters: true,
        savedFilters: true,
        detail: true,
    },
    reportHint: {
        message: 'В данном отчёте можно проследить региональную принадлежность посетителей сайта с точностью до города, '
            + 'которая определяется по IP. '
            + 'Доступные показатели: посетители, визиты, '
            + '<a href="https://ads.sber.ru/help/metod-dlya-peredachi-identifikatora-polzovatelya" target="_blank">пользователи</a>, просмотры, отказы, время на сайте, глубина просмотра.',
    },
};

export default reportConfig;
