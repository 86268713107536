import { Modal } from '@adtech/ui';
import React from 'react';

import s from '../Funnel.pcss';

interface IProps {
    isOpen: boolean;
    onOkHandler: () => void;
    onCloseHandler: () => void;
}

const DeleteFunnelModal: React.FC<IProps> = ({ isOpen, onOkHandler, onCloseHandler }) => (
    <Modal
        isOpened={isOpen}
        title="Удаление воронки"
        okText="Удалить"
        cancelText="Отменить"
        onOk={onOkHandler}
        onClose={onCloseHandler}
        closeOnClickOutside={false}
        centered
        size="sm"
    >
        <div className={s.funnelsDeleteModalText} data-testid="description-text">
            <p>Вы уверены, что хотите удалить воронку?</p>
            <p>Чтобы восстановить удалённую воронку,</p>
            <p>
                обратитесь в&nbsp;
                <a href="mailto:counter@ads.sber.ru" target="_blank" rel="noopener" data-testid="link-text">
                    поддержку
                </a>
            </p>
        </div>
    </Modal>
);

export default DeleteFunnelModal;
