import { Alert, Tooltip } from '@adtech/ui';
import cn from 'classnames';
import React, { ReactNode } from 'react';

import s from './Wrapper.pcss';

interface IProps {
    rowClassName?: string;
    rootClassName?: string;
    descClassName?: string;
    titleClassName?: string;
    title?: string;
    description?: string | ReactNode;
    children?: ReactNode;
    withoutDelimiter?: boolean;
    subWrap?: boolean;
    fixPadding?: boolean;
    mainWrap?: boolean;
    smallPadding?: boolean;
    normalPadding?: boolean;
    smallTitle?: boolean;
    normalTitle?: boolean;
    isRequired?: boolean;
    isRating?: boolean;
}

const Wrapper: React.FC<IProps> = ({
    rowClassName = '',
    rootClassName = '',
    descClassName = '',
    titleClassName = '',
    title = '',
    description = '',
    children,
    withoutDelimiter = false,
    subWrap = false,
    fixPadding = false,
    mainWrap = false,
    smallPadding = false,
    normalPadding = false,
    smallTitle = false,
    normalTitle = false,
    isRequired = false,
    isRating = false,
}) => {
    const rootClass = cn(
        s.root,
        {
            [s.subWrap]: subWrap,
            [s.mainWrap]: mainWrap,
            [s.delimiter]: !withoutDelimiter,
            [s.smallPadding]: smallPadding,
            [s.normalPadding]: normalPadding,
            [s.fixPadding]: fixPadding,
        },
        rootClassName,
    );

    const titleClass = cn(
        s.title,
        {
            [s.small]: smallTitle,
            [s.normal]: normalTitle,
        },
        titleClassName,
    );

    return (
        <div className={rootClass}>
            {title && (
                <>
                    <div className={titleClass}>
                        {title}
                        {isRequired && (
                            <Tooltip title="Обязательно для заполнения" placement="right">
                                <span className={s.isRequired}>*</span>
                            </Tooltip>
                        )}
                    </div>
                    {isRating && (
                        <Alert
                            className={s.alert}
                            type="info"
                            showIcon
                            message="Название аналитики меняется – Топ-100 остается! Даже после ребрендинга мы сохранили привычное имя нашего рейтинга и ждем ваши заявки."
                        />
                    )}
                </>
            )}

            {description && <p className={cn(s.description, descClassName)}>{description}</p>}

            <div className={cn(s.row, rowClassName)}>{children}</div>
        </div>
    );
};

export default Wrapper;
